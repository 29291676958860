import { Toolbar } from '@mui/material'

const ToolbarContainer = ({ children, ...props }) => {
  const { sx } = props || {}

  return (
    <Toolbar
      sx={[
        (theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          backgroundColor: theme.palette.white.dark,
          padding: theme.spacing(),
          '& > *': {
            margin: theme.spacing(),
          },
        }),
        sx,
      ]}
    >
      {children}
    </Toolbar>
  )
}

export default ToolbarContainer
