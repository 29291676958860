import { useCallback, useState } from 'react'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'

import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

const initialState = {
  open: false,
  period: {},
}

const usePayrollSummaryFileModal = (period) => {
  const [state, setState] = useState({ ...initialState, period })
  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()
  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: {
      enabled: false,
    },
  })

  const openPayrollSummaryFileModal = useCallback(() => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      subscriptionMutation.mutate({
        mutationMethod: 'GET',
        crmEvent: 'downloaded_payroll_summary_file',
      })
      return
    }

    setState((previousState) => ({
      ...previousState,
      open: true,
      period,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  const closePayrollSummaryFileModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    payrollSummaryFileState: state,
    openPayrollSummaryFileModal,
    closePayrollSummaryFileModal,
  }
}

export default usePayrollSummaryFileModal
