import { useState } from 'react'
import { useQueryClient } from 'react-query'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Table from 'components/UI/Table/Table'

import { isOrganizer } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useCompanyPaymentService from 'utils/hooks/organizer/companyPaymentService'
import usePeriodService from 'utils/hooks/organizer/periodService'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { getAllPayments } from 'services/subscription/subscriptionService'

import { getColumnsData, getTableActions } from '../../helpers'
import useSubscription from '../../useSubscription'
import CurrentPaymentModal from '../Modals/CurrentPaymentModal'
import useCurrentPaymentModal from '../Modals/useCurrentPaymentModal'

const SubscriptionStatusPayments = ({ setIsLoading }) => {
  const [actionsDisabled, setActionsDisabled] = useState(false)
  const { subscription } = useSubscription()

  const {
    status,
    payment_category: paymentCategory,
    payment_status: paymentStatus,
    payment_methods: paymentMethods,
  } = subscription || {}

  const automaticDebitAvailable = paymentMethods?.automatic_debit

  const showPaymentRetryAction =
    (paymentCategory === 'credit_card' ||
      (paymentCategory === 'automatic_debit' && automaticDebitAvailable)) &&
    ['active_premium', 'active_premium_hr', 'premium_expired'].includes(
      status
    ) &&
    paymentStatus !== 'pending' &&
    isOrganizer()

  const {
    currentPaymentModalState,
    openCurrentPaymentModal,
    closeCurrentPaymentModal,
  } = useCurrentPaymentModal()

  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const companyId = getCompanyId()

  const refetchPayments = () =>
    queryClient.invalidateQueries(['getAllPayments', companyId], {
      exact: false,
    })

  const confirm = useConfirm()

  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: {
      enabled: false,
    },
  })

  const { companyPaymentMutation } = useCompanyPaymentService({
    queryOptions: {
      enabled: false,
    },
  })

  const { periodMutation } = usePeriodService()

  const fetchPayments = (pageIndex) => {
    return {
      queryKey: ['getAllPayments', companyId, pageIndex + 1],
      queryFunction: () => getAllPayments({ page: pageIndex + 1 }),
    }
  }

  const handleSendInvoice = (paymentId) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'POST',
        paymentId,
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const handleReInvoice = () => {
    setIsLoading(true)

    periodMutation.mutate(
      {
        mutationMethod: 'PATCH',
      },
      {
        onSuccess: () => {
          setActionsDisabled(true)
          showSuccessMessage('En unos minutos se generará la factura.')
          setIsLoading(false)
        },
        onError: (error) => {
          handleError(error, null, {
            notistackOptions: {
              preventDuplicate: true,
            },
          })
          setIsLoading(false)
        },
      }
    )
  }

  const handleRetryPayment = () => {
    companyPaymentMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'paymentRetry',
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const handleRemovePayment = (paymentId) => {
    companyPaymentMutation.mutate(
      {
        mutationMethod: 'DELETE',
        paymentId,
      },
      {
        onSuccess: ({ message }) => {
          queryClient.invalidateQueries(['getSubscription', companyId])
          showSuccessMessage(message)
          refetchPayments()
        },
      }
    )
  }

  const handleClickAction = (paymentId, action) => {
    if (action === 're-invoice') {
      handleReInvoice()
    } else {
      handleSendInvoice(paymentId)
    }
  }

  const submitModalCallback = (message) => {
    showSuccessMessage(message)

    refetchPayments()
  }

  const columnsData = getColumnsData(handleClickAction, actionsDisabled)

  const tableActions = getTableActions(
    openCurrentPaymentModal,
    handleRemovePayment,
    handleRetryPayment,
    showPaymentRetryAction,
    paymentCategory,
    confirm
  )

  return (
    <>
      <Table
        columns={columnsData}
        data={fetchPayments}
        actions={tableActions}
        options={{
          customActionsWidth: '9rem',
          toolbar: showPaymentRetryAction,
          search: false,
          version: {
            toolbar: 'v2',
          },
        }}
      />
      {currentPaymentModalState.open ? (
        <CurrentPaymentModal
          state={currentPaymentModalState}
          handleClose={closeCurrentPaymentModal}
          submitCallback={submitModalCallback}
        />
      ) : null}
    </>
  )
}

export default SubscriptionStatusPayments
