import { klona } from 'klona'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Typography } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import useSocialBenefitsService from 'utils/hooks/payroll/socialBenefits'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import {
  createSocialBenefitsWorker,
  getSocialBenefits,
  updateSocialBenefitsWorker,
} from 'services/payroll/socialBenefitsService'

import {
  getActions,
  getColumnsData,
  getMessage,
  socialBenefitAction,
  socialBenefitTitle,
  useSocialBenefitsPeriod,
} from './helpers'

const TableContent = () => {
  const {
    formattedSocialBenefits: { social_benefit_period: socialBenefitPeriod },
  } = useSocialBenefitsPeriod()

  const { socialBenefitsMutation } = useSocialBenefitsService({
    queryOptions: { enabled: false },
  })
  const { showInfoMessage, showSuccessMessage } = useNotifications()
  const { periodId, socialBenefitPeriodId } = useParams()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const confirm = useConfirm()

  const { label, category, action } = socialBenefitPeriod

  const socialBenefitsQueryKey = [
    'getSocialBenefits',
    companyId,
    periodId,
    socialBenefitPeriodId,
  ]
  const socialBenefitPeriodQueryKey = [
    'getSocialBenefitsPeriod',
    periodId,
    companyId,
  ]

  const fetchSocialBenefits = (pageIndex, search) => {
    return {
      queryKey: [
        'getSocialBenefits',
        companyId,
        periodId,
        socialBenefitPeriodId,
        pageIndex + 1,
        search,
      ],
      queryFunction: () =>
        getSocialBenefits(periodId, socialBenefitPeriodId, {
          page: pageIndex + 1,
          search,
          per_page: 20,
        }),
    }
  }

  const handleRecalculateIndividualSocialBenefit = (rowData) => {
    const { id, worker_name: workerName } = rowData
    showInfoMessage(`Los valores de ${workerName} están siendo recalculados.`)

    socialBenefitsMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'recalculateWorkerSocialBenefits',
        periodId,
        socialBenefitId: id,
        socialBenefitPeriodId,
        socialBenefits: { recalculate: true },
      },
      {
        onSuccess: ({ data: socialBenefit }) => {
          queryClient.setQueriesData(
            {
              queryKey: socialBenefitsQueryKey,
              exact: false,
              active: true,
            },
            (oldPeriod) => {
              const socialBenefitsCopy = klona(oldPeriod?.data)

              socialBenefitsCopy?.forEach((socialBenefitItem) => {
                if (socialBenefitItem.id === id) {
                  // this is required to do when the api returns item_value with a value
                  const paidValue =
                    socialBenefit.paid_value -
                    socialBenefitItem.initial_item_value

                  const newSocialBenefitsItem = {
                    ...socialBenefitItem,
                    ...socialBenefit,
                    balance:
                      socialBenefit.value -
                      paidValue -
                      socialBenefitItem.item_value,
                    item_value: socialBenefitItem.item_value,
                  }
                  delete newSocialBenefitsItem.label
                }
              })

              return {
                ...oldPeriod,
                data: socialBenefitsCopy,
              }
            }
          )

          showSuccessMessage(
            `Los valores de ${getMessage(
              category
            )} de ${workerName} fueron recalculados.`
          )
        },
        onError: (error) => {
          handleError(error)
        },
      }
    )
  }

  const onConfirmIndividualUpdate = (onUpdateRow, _, newData) => {
    const { id, worker_name: workerName, item_value: itemValue } = newData
    const actionMessage = id && itemValue === 0 ? 'Eliminar' : 'Liquidar'

    confirm({
      title: `Estás a punto de Liquidar ${getMessage(
        category
      )} de ${workerName}`,
      description: `Al realizar esta liquidación se incluirá el valor de ${getMessage(
        category
      )} ${
        category === 'severance'
          ? 'para el archivo de pago en el fondo de cesantías.'
          : 'en la colillas de pago y en el archivo de pago en bancos.'
      }`,
      okText: `Liquidar ${socialBenefitAction[category]}`,
      onOk: () =>
        onUpdateRow({
          data: {
            periodId,
            socialBenefitPeriodId,
            ...(id && { socialBenefitId: id }),
            socialBenefits: {
              base_value: newData.base_value,
              contract_id: newData.contract_id,
              days: newData.days,
              item_value: newData.item_value,
              ...(category === 'service_bonus' && {
                service_bonus_retention: newData.service_bonus_retention,
              }),
            },
          },
          mutationFunction: id
            ? updateSocialBenefitsWorker
            : createSocialBenefitsWorker,
          mutateOptions: {
            onSuccess: async ({ data: workerSocialBenefitNew }) => {
              queryClient.setQueriesData(
                {
                  queryKey: socialBenefitsQueryKey,
                  exact: false,
                  active: true,
                },
                (oldSocialBenefits) => {
                  const socialBenefitsCopy = oldSocialBenefits?.data?.map(
                    (socialBenefitItem) => {
                      const socialBenefitItemCopy = socialBenefitItem
                      if (
                        socialBenefitItemCopy.worker_id ===
                        workerSocialBenefitNew.worker_id
                      ) {
                        return workerSocialBenefitNew
                      }
                      return socialBenefitItemCopy
                    }
                  )

                  return {
                    ...oldSocialBenefits,
                    data: socialBenefitsCopy,
                  }
                }
              )

              await queryClient.invalidateQueries(socialBenefitPeriodQueryKey)

              showSuccessMessage(
                `Le acabas de ${actionMessage} ${getMessage(category)} a ${
                  newData.worker_name
                }`
              )
            },
          },
        }),
      adornment: {
        variant: 'signature',
        color: 'accent2.light',
        width: 155,
        height: 171,
        sx: { bottom: '0.94rem', right: '0.94rem' },
      },
    })
  }

  const endFreeSocialBenefitsModal = useEndFreeModals()

  const columns = getColumnsData(category)
  const actions = getActions({
    action,
    label,
    category,
    handleRecalculateIndividualSocialBenefit,
    socialBenefitPeriodId,
    endFreeSocialBenefitsModal,
  })

  return (
    <>
      <Typography
        variant="h2"
        sx={(theme) => ({ margin: theme.spacing(7, 0, 4) })}
      >{`${socialBenefitTitle[category]} ${label}`}</Typography>

      <Table
        columns={columns}
        data={fetchSocialBenefits}
        options={{
          version: { toolbar: 'v2' },
        }}
        actions={actions}
        editable={{
          hideDelete: true,
          hideEdit: true,
          onUpdateRowConditionally: onConfirmIndividualUpdate,
        }}
      />
    </>
  )
}

export default TableContent
