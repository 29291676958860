import { Navigate } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import SubscriptionPaywall from 'components/Subscription/Index/Index'

import { DASHBOARD } from 'config/routes'

const View = (props) => {
  const { isAPartnerChild } = useUser()

  if (isAPartnerChild) {
    return <Navigate to={DASHBOARD} />
  }

  return <SubscriptionPaywall {...props} />
}

export default View
