import { Box, Link, Typography } from '@mui/material'

import DropDownPeriod from '../Payroll/DropDownPeriod'

const ElectronicPayrollHeader = ({ title, period }) => {
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        })}
      >
        <Typography variant="h2">{title}</Typography>
        <Typography>
          ¿Dudas? Revisa{' '}
          <Link
            href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/preguntas-frecuentes-sobre-la-emisión-de-nómina-electrónica"
            target="_blank"
            underline="hover"
            color="accent4.main"
            fontWeight={700}
          >
            este artículo
          </Link>{' '}
          de preguntas frecuentes sobre la emisión de nómina electrónica.
        </Typography>
      </Box>
      {period ? <DropDownPeriod period={period} electronicPayroll /> : null}
    </Box>
  )
}

export default ElectronicPayrollHeader
