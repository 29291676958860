export const discardTitles = {
  affiliation: 'Afiliar persona',
  backgroundCheck: 'Antecedentes',
  electronicSignature: 'Firmar documentos',
}

export const discardText = {
  discardAffiliation: 'Afiliación a Seguridad Social',
  discardElectronicSignature: 'Firma de documentos',
  discardBackgroundCheck: 'Consulta de antecedentes',
}

export const discardedTitles = {
  affiliation: 'Afiliar a Seguridad Social',
  backgroundCheck: 'Consultar antecedentes',
  electronicSignature: 'Enviar documentos a firmar',
}

export const resumeProcessText = {
  affiliation: 'afiliación',
  backgroundCheck: 'validación de antedecendes',
  electronicSignature: 'firma de documentos',
}

export const getEmptyStateTitle = (affiliationActiveStatus) => {
  const status = {
    '': '',
    approved: 'aprobadas',
    rejected: 'rechazadas',
    pending_info: 'incompletas',
    waiting_response: 'pendientes',
  }

  return `No tienes afiliaciones a Seguridad Social ${status[affiliationActiveStatus]}`
}

export const noveltyDescription = {
  slow_response:
    'La afiliación a la entidad está tomando un tiempo de respuesta mayor al habitual.',
  auth_required:
    'Se requiere segundo factor de autenticación para realizar el ingreso a la plataforma.',
  in_person_signup: 'La entidad solicita afiliación presencial.',
}
