import { useTour } from '@reactour/tour'
import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@mui/material'

import useExtraWorkersModal from 'components/Subscription/Atoms/ExtraWorkersModal/useExtraWorkersModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Table from 'components/UI/Table/Table'
import EmptyState from 'components/Worker/Commons/EmptyState'
import { WorkerIndexContext } from 'components/Worker/Index/Index'

import { getCompanyId } from 'utils/company'
import { wait } from 'utils/general'

import { getCompanyOnboardings } from 'services/worker/onboardingService'

import BackgroundCheckInitialConsultModal from './BackgroundCheck'
import BackgroundCheckModal from './BackgroundCheck/BackgroundCheckModal'
import useBackgroundCheckInitialConsultModal from './BackgroundCheck/useBackgroundCheckInitialConsultModal'
import useBackgroundCheckModal from './BackgroundCheck/useBackgroundCheckModal'
import ElectronicSignatureModal from './ElectronicSignature'
import ElectronicSignatureConfirmationModal from './ElectronicSignature/ElectronicSignatureConfirmationModal'
import FinalSigningsStatusModal from './ElectronicSignature/FinalSigningsStatusModal'
import useElectronicSignatureConfirmationModal from './ElectronicSignature/useElectronicSignatureConfirmationModal'
import useElectronicSignatureModal from './ElectronicSignature/useElectronicSignatureModal'
import useFinalSigningsStatusModal from './ElectronicSignature/useFinalSigningStatusModal'
import HiringAlertModal from './HiringAlertModal'
import AffiliationModal from './SSAffiliation/AffiliationModal'
import CommentsModal from './SSAffiliation/CommentsModal'
import DiscardedModal from './SSAffiliation/DiscardedModal'
import AffiliationFilesModal from './SSAffiliation/FilesModal'
import InProcessModal from './SSAffiliation/InProcessModal'
import AffiliationNoveltyModal from './SSAffiliation/NoveltyModal'
import { discardedTitles } from './SSAffiliation/helpers'
import useAffiliationCredentialsModal from './SSAffiliation/useAffiliationCredentialsModal'
import useAffiliationFilesModal from './SSAffiliation/useAffiliationFilesModal'
import useAffiliationModal from './SSAffiliation/useAffiliationModal'
import useAffiliationNoveltyModal from './SSAffiliation/useAffiliationNoveltyModal'
import useCommentsModal from './SSAffiliation/useCommentsModal'
import useDiscardedModal from './SSAffiliation/useDiscardedModal'
import useInProcessModal from './SSAffiliation/useInProcessModal'
import { getActions, getColumns } from './helpers'
import useHiringAlertModal from './useHiringAlertModal'

const WorkersOnboarding = ({ openNewWorkerModal, isNewWorkerModalOpen }) => {
  const { subscription } = useSubscription()
  const extraWorkersModal = useExtraWorkersModal()
  const { handleAddWorkerManually } = useContext(WorkerIndexContext)
  const [onboardingStatus, setOnboardingStatus] = useState('')
  const { isOpen, setIsOpen, setCurrentStep } = useTour()
  const companyId = getCompanyId()
  const { state } = useLocation()
  const isTableEmpty = useRef(false)

  const { hiringAlertModalState, openHiringAlertModal, closeHiringAlertModal } =
    useHiringAlertModal()

  const {
    affiliationModalState,
    openAffiliationModal,
    closeAffiliationModal,
    resetAffiliationModalState,
  } = useAffiliationModal()

  const {
    backgroundCheckInitialConsultState,
    closeBackgroundCheckInitialConsultModal,
    openBackgroundCheckInitialConsultModal,
    resetBackgroundCheckInitialConsultModalState,
  } = useBackgroundCheckInitialConsultModal()

  const {
    backgroundCheckModalState,
    openBackgroundCheckModal,
    closeBackgroundCheckModal,
  } = useBackgroundCheckModal()

  const {
    openElectronicSignatureModal,
    closeElectronicSignatureModal,
    resetElectronicSignatureState,
    electronicSignatureModalState,
  } = useElectronicSignatureModal()

  const {
    closeElectronicSignatureConfirmationModal,
    openElectronicSignatureConfirmationModal,
    electronicSignatureConfirmationModalState,
  } = useElectronicSignatureConfirmationModal()

  const {
    openFinalSigningsStatusModal,
    closeFinalSigningsStatusModal,
    finalSigningsStatusModalState,
  } = useFinalSigningsStatusModal()

  const {
    discardedModalState,
    openDiscardedModal,
    closeDiscardedModal,
    reopenProcessModal,
    resetDiscardedModalState,
  } = useDiscardedModal({
    openAffiliationModal,
    openBackgroundCheckInitialConsultModal,
    openElectronicSignatureModal,
  })

  const {
    inProcessModalState,
    openInProcessModal,
    closeInProcessModal,
    resetInProcessModalState,
  } = useInProcessModal()

  const {
    affiliationFilesModalState,
    closeAffiliationFilesModal,
    openAffiliationFilesModal,
  } = useAffiliationFilesModal()

  const { openAffiliationCredentialsModal } = useAffiliationCredentialsModal()

  const {
    affiliationNoveltyModalState,
    closeAffiliationNoveltyModal,
    openAffiliationNoveltyModal,
  } = useAffiliationNoveltyModal()

  const { commentsModalState, openCommentsModal, closeCommentsModal } =
    useCommentsModal()

  const handleCloseTour = () => {
    if (isOpen) {
      setIsOpen(false)
      setCurrentStep(0)
    }
  }

  const columnsData = getColumns({
    openNewWorkerModal,
    openAffiliationModal,
    openDiscardedModal,
    openInProcessModal,
    openAffiliationFilesModal,
    openAffiliationCredentialsModal,
    openAffiliationNoveltyModal,
    openBackgroundCheckInitialConsultModal,
    openBackgroundCheckModal,
    openElectronicSignatureModal,
    openFinalSigningsStatusModal,
    openCommentsModal,
    handleCloseTour,
  })

  const hasToPayNewWorkers = !subscription?.additional_workers_info?.add_workers

  const handleAddWorker = () => {
    if (hasToPayNewWorkers) {
      extraWorkersModal.openModal()
    } else {
      openNewWorkerModal()
    }
  }

  const actions = getActions({
    openHiringAlertModal,
    onboardingStatus,
    setOnboardingStatus,
    handleAddWorker,
    handleCloseTour,
  })

  const fetchCompanyOnboardings = (pageIndex, search) => {
    return {
      queryKey: [
        'getCompanyOnboardings',
        companyId,
        pageIndex,
        onboardingStatus,
        search,
      ],
      queryFunction: () =>
        getCompanyOnboardings({
          page: pageIndex + 1,
          search,
          filter: onboardingStatus,
        }),
      queryOptions: {
        onSuccess: async ({ data }) => {
          if (data.length === 0) {
            isTableEmpty.current = true
          }

          const onboardingItem = data[0]

          if (
            data.length === 1 &&
            (!isNewWorkerModalOpen ||
              (isNewWorkerModalOpen &&
                onboardingItem.worker.finished_registration &&
                ((state?.addWorkerManually && isTableEmpty.current) ||
                  !state?.addWorkerManually)))
          ) {
            if (
              onboardingItem.worker.finished_registration &&
              onboardingItem.background_check.status === 'unstarted' &&
              onboardingItem.electronic_signature.status === 'unstarted' &&
              onboardingItem.affiliation.status === 'unstarted'
            ) {
              // Artificial delay to show the tour guide after the table is rendered
              await wait(1000)
              setIsOpen(true)
            }
          }
        },
      },
    }
  }

  useEffect(() => {
    return () => {
      setCurrentStep(0)
    }
  }, [setCurrentStep])

  return (
    <Box sx={{ gridColumn: '1/-1' }}>
      <Table
        data={fetchCompanyOnboardings}
        columns={columnsData}
        actions={actions}
        options={{
          version: {
            toolbar: 'v2',
          },
          alignActionsCell: 'center',
          emptyStateComponent: (
            <EmptyState
              title="¿Llegó una nueva persona al equipo?."
              description={
                <>
                  Agrégala para que puedas Validar sus antecedentes, enviarle
                  documentos para que firme virtualmente y{' '}
                  <b>Afíliala a Seguridad social en 1 clic.</b>
                </>
              }
              handleAddWorkerManually={handleAddWorkerManually}
              enableFileOption={false}
            />
          ),
        }}
      />
      {hiringAlertModalState.open ? (
        <HiringAlertModal
          state={hiringAlertModalState}
          handleClose={closeHiringAlertModal}
        />
      ) : null}
      {affiliationModalState.open ? (
        <AffiliationModal
          state={affiliationModalState}
          handleClose={closeAffiliationModal}
          resetAffiliationModalState={resetAffiliationModalState}
        />
      ) : null}
      {discardedModalState.open ? (
        <DiscardedModal
          state={discardedModalState}
          handleClose={closeDiscardedModal}
          discardedTitles={discardedTitles}
          reopenProcessModal={reopenProcessModal}
          resetDiscardedModalState={resetDiscardedModalState}
        />
      ) : null}
      {inProcessModalState.open ? (
        <InProcessModal
          state={inProcessModalState}
          handleClose={closeInProcessModal}
          resetInProcessModalState={resetInProcessModalState}
        />
      ) : null}
      {affiliationFilesModalState.open ? (
        <AffiliationFilesModal
          state={affiliationFilesModalState}
          handleClose={closeAffiliationFilesModal}
        />
      ) : null}

      {affiliationNoveltyModalState.open ? (
        <AffiliationNoveltyModal
          state={affiliationNoveltyModalState}
          handleClose={closeAffiliationNoveltyModal}
          openInProcessModal={openInProcessModal}
        />
      ) : null}
      {backgroundCheckInitialConsultState.open ? (
        <BackgroundCheckInitialConsultModal
          state={backgroundCheckInitialConsultState}
          handleClose={closeBackgroundCheckInitialConsultModal}
          resetBackgroundCheckModalState={
            resetBackgroundCheckInitialConsultModalState
          }
          openBackgroundCheckModal={openBackgroundCheckModal}
        />
      ) : null}
      {backgroundCheckModalState.open ? (
        <BackgroundCheckModal
          key={backgroundCheckModalState.key}
          state={backgroundCheckModalState}
          handleClose={closeBackgroundCheckModal}
        />
      ) : null}
      {electronicSignatureModalState.open ? (
        <ElectronicSignatureModal
          state={electronicSignatureModalState}
          handleClose={closeElectronicSignatureModal}
          openConfirmationModal={openElectronicSignatureConfirmationModal}
          resetElectronicSignatureState={resetElectronicSignatureState}
        />
      ) : null}
      {electronicSignatureConfirmationModalState.open ? (
        <ElectronicSignatureConfirmationModal
          state={electronicSignatureConfirmationModalState}
          handleClose={closeElectronicSignatureConfirmationModal}
        />
      ) : null}
      {finalSigningsStatusModalState.open ? (
        <FinalSigningsStatusModal
          state={finalSigningsStatusModalState}
          handleClose={closeFinalSigningsStatusModal}
        />
      ) : null}
      {commentsModalState.open ? (
        <CommentsModal
          state={commentsModalState}
          handleClose={closeCommentsModal}
          openAffiliationModal={openAffiliationModal}
        />
      ) : null}
    </Box>
  )
}

export default WorkersOnboarding
