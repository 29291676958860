import { useMutation } from 'react-query'

import {
  getIncomeCertificate,
  getLaborCertificate,
  getWorkerProfileIncomeCertificate,
  getWorkerProfileLaborCertificate,
  getWorkersIncomeCertificates,
} from 'services/worker/certificateService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, mutationKey, workerId, options } = mutationData

  if (mutationMethod === 'GET') {
    if (mutationKey === 'laborCertificate')
      return getLaborCertificate(workerId, options)
    if (mutationMethod === 'GET')
      if (mutationKey === 'workerProfileLaborCertificate')
        return getWorkerProfileLaborCertificate(workerId, options)
    if (mutationKey === 'incomeCertificate')
      return getIncomeCertificate(workerId, options)
    if (mutationKey === 'workerProfileIncomeCertificate')
      return getWorkerProfileIncomeCertificate(workerId, options)
    if (mutationKey === 'workersIncomesCertificates')
      return getWorkersIncomeCertificates(options)
  }

  return null
}

const useCertificateService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const certificateMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    certificateMutation,
  }
}

export default useCertificateService
