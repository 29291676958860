import http, { getApiURL } from '../httpService'

export const getHolidays = (workerId, { page }) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/holidays_history`,
    searchParams: { page },
  })

  return http.getV2(url)
}

export default { getHolidays }
