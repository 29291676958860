import FormField from 'components/UI/Formik/FormField/Index'

import useInstitutionsService from 'utils/hooks/institutionsService'

import messages from 'messages/contract'

const SeveranceProviderField = ({
  wageCategory,
  contractCategory,
  optional,
  ...restProps
}) => {
  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey: ['getInstitutionsByCategory', 'severance_provider'],
      category: 'severance_provider',
    },
  })

  return contractCategory &&
    wageCategory &&
    ['employee', 'part_time_contract'].includes(contractCategory) &&
    wageCategory !== 'integral_salary' ? (
    <FormField
      name="severance_provider"
      label="Fondo de cesantías"
      variant="autocomplete"
      placeholder="Seleccione un fondo de cesantías..."
      options={institutionsQuery?.data || []}
      tooltipContent={messages.severance_provider}
      optional={optional}
      {...restProps}
    />
  ) : null
}

export default SeveranceProviderField
