import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { Box, FormLabel, Typography, useMediaQuery } from '@mui/material'

import Dropzone from 'components/UI/Dropzone/Dropzone'
import FormField from 'components/UI/Formik/FormField/Index'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import { getCompanyId } from 'utils/company'
import { getBase64FromFile } from 'utils/general'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'
import { isDataCached } from 'utils/reactQuery'

import { electronicPayrollCertificateOptions } from '../helpers'

const ElectronicFileInformation = () => {
  const { values, setFieldValue, errors } = useFormikContext()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const electronicPayrollConfigurationCache =
    queryClient.getQueryData(['electronicPayrollStatus', companyId]) || {}

  useElectronicPayrollConfigurationService({
    serviceParams: {
      queryKey: ['electronicPayrollStatus', companyId],
    },
    queryOptions: {
      enabled: !isDataCached(electronicPayrollConfigurationCache),
      onSuccess: ({ data }) => {
        setFieldValue('company_certificate', data.company_certificate)
        setFieldValue('certificate_file_name', data.certificate_file_name)
      },
    },
  })

  useEffect(() => {
    if (electronicPayrollConfigurationCache?.data) {
      setFieldValue(
        'company_certificate',
        electronicPayrollConfigurationCache?.data.company_certificate
      )
      setFieldValue(
        'certificate_file_name',
        electronicPayrollConfigurationCache?.data.certificate_file_name
      )
    }
  }, [electronicPayrollConfigurationCache.data, setFieldValue])

  const handleUploadCertificate = async (file) => {
    if (file) {
      const fileBase64 = await getBase64FromFile(file)

      setFieldValue('certificate_file_name', file.name)
      setFieldValue('certificate_file', fileBase64)
    }
  }

  return (
    <>
      <Typography variant="h6" color="black" gutterBottom>
        Asignación certificados electrónicos
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        En este proceso debes asignar el certificado con el que vas a firmar
        Nómina Electrónica.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          marginTop: theme.spacing(4),
        })}
      >
        <Typography
          color="black"
          variant="h6"
          sx={(theme) => ({ marginRight: theme.spacing(0.5) })}
        >
          Certificados electrónicos
        </Typography>
        <TooltipInfoIcon
          title="Aleluya te proporciona todos los certificados electrónicos para que puedas emitir tu nómina electrónica.
            Sin embargo, también puedes cargar tus propios certificados electrónicos directamente"
          tooltipProps={{
            placement: xsDown ? 'top' : 'right',
            arrow: true,
          }}
        />
      </Box>
      <FormField
        name="company_certificate"
        variant="radio-group"
        row={lgUp}
        options={electronicPayrollCertificateOptions}
      />
      {values.company_certificate ? (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: theme.spacing(3),
            paddingTop: theme.spacing(3),
          })}
        >
          <div>
            <Dropzone
              value={values.certificate_file_name}
              type="inline"
              accept=".pfx"
              handleAdd={handleUploadCertificate}
              fileType="application/x-pkcs12"
            />
            {errors?.certificate_file_name ? (
              <FormLabel
                sx={(theme) => ({
                  color: theme.palette.error.main,
                  marginTop: theme.spacing(1),
                  marginBottom: 0,
                })}
              >
                {errors?.certificate_file_name}
              </FormLabel>
            ) : null}
          </div>
          <FormField
            name="certificate_password"
            label="Contraseña del certificado"
            optional={false}
          />
        </Box>
      ) : null}
    </>
  )
}

export default ElectronicFileInformation
