import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Navigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { usePremiumActions } from 'components/App/Premium/helpers'
import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'
import Steps from 'components/UI/Steps/Steps'

import { getCompanyId } from 'utils/company'
import { useCompanyService } from 'utils/hooks/company/companyService'

import { DASHBOARD, ONBOARDING_FIRST_STEPS } from 'config/routes'

import MainContainer from '../common/MainContainer'
import OnboardingCongratulations from './Congratulations/Congratulations'
import OnboardingPersonalize from './Personalize/Personalize'
import OnboardingRole from './Role/Role'
import { schemaValidation, stepNumber, stepsData } from './helpers'
import useOnboardingMutation from './useOnboardingMutation'

const stepsSize = stepsData.length

const Onboarding = () => {
  const queryClient = useQueryClient()
  const { company } = useUser()
  const { handleActivatePremiumTrial } = usePremiumActions()
  const currentOnboardingStep = company?.onboarding?.onboarding_step
  const onboardingFirstStepsFinished =
    company?.onboarding_first_steps?.finished_all_steps
  const [currentStep, setCurrentStep] = useState(
    () => stepNumber[currentOnboardingStep]
  )
  const companyQueryKey = ['updateOnboardingStatus', getCompanyId()]
  const { companyQuery } = useCompanyService({
    serviceParams: {
      queryKey: companyQueryKey,
      company: {
        /**
         * This helps to avoid the first step that is the welcome screen, and because
         * currently there is an experiment where all new companies are created with the
         * premium trial enabled by default, we need to set the start_freemium to true
         */
        onboarding_step: 'role',
        start_freemium: true,
      },
    },
    queryOptions: {
      enabled: company?.onboarding?.onboarding_step === 'welcome',
      onSuccess: async ({ data }) => {
        if (data?.onboarding?.onboarding_step !== 'welcome') {
          setCurrentStep(stepNumber[data?.onboarding?.onboarding_step])
        }

        await queryClient.invalidateQueries([
          'companyInformation',
          getCompanyId(),
        ])
      },
    },
  })
  const currentStepData = stepsData.find(({ step }) => step === currentStep)
  const currentStepPathname = currentStepData?.pathname

  const initialValues = {
    user_name: company?.phone ? company?.onboarding?.user_name : '',
    phone: company?.phone,
    name: company?.phone ? company?.name : '',
    document_type: company?.document_type || 'ni',
    id_number: company?.id_number,
    verification_digit: company?.verification_digit,
    workers_number: company?.onboarding?.workers_number,
    payroll_frequency: company?.onboarding?.payroll_frequency,
    user_role: company?.onboarding?.user_role,
    user_role_other_info: company?.onboarding?.user_role_other_info,
  }

  const handleNextStep = () => {
    if (currentStep < stepsSize) {
      setCurrentStep((previousValue) => previousValue + 1)
    }
  }

  const { handleUpdateOnboarding, isLoading } =
    useOnboardingMutation(handleNextStep)

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((previousValue) => previousValue - 1)
    }
  }

  const handleSubmit = (values) => {
    if (currentStep === 1) {
      const valuesToSend = { ...values, onboarding_step: 'select_action' }
      delete valuesToSend.user_role
      delete valuesToSend.user_role_other_info

      handleUpdateOnboarding({
        data: valuesToSend,
        callback: () => {
          handleActivatePremiumTrial()
          handleNextStep()
        },
      })
    }
  }

  /*
   * Because all the steps are in the same page, we need to change the url when the step changes,
   * so we use history to replace the url without navigating again to the same page
   */
  useEffect(() => {
    window.history.replaceState(null, '', currentStepPathname)
  }, [currentStep, currentStepPathname])

  if (currentOnboardingStep === 'finished') {
    return (
      <Navigate
        to={onboardingFirstStepsFinished ? DASHBOARD : ONBOARDING_FIRST_STEPS()}
      />
    )
  }

  return currentOnboardingStep !== 'finished' ? (
    <MainContainer>
      <Page
        documentTitle={stepsData[currentStep]?.title || ''}
        isLoading={companyQuery.isLoading}
        rootSx={{
          width: '100%',
        }}
        contentSx={(theme) => ({
          margin: theme.spacing(0, 4),
          height: 'calc(100% - 64px)',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              height: '4.75rem',
              margin: theme.spacing(11, 0, 5),
              maxWidth: '31.25rem',
              width: '100%',
              [theme.breakpoints.down('laptop')]: {
                margin: theme.spacing(5, 0, 5),
              },
            })}
          >
            <Steps
              arrows
              orientation="horizontal"
              stepsData={stepsData}
              current={currentStep}
              progress={currentStep}
              leftArrowProps={{
                onClick: handlePreviousStep,
                disabled: currentStep === 0,
              }}
              rightArrowProps={{
                onClick: handleNextStep,
                disabled: currentStep >= stepNumber[currentOnboardingStep],
              }}
            />
          </Box>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schemaValidation[currentStep]}
          >
            {({ values }) => {
              const isWithinWorkersSegment = [
                '20-39',
                '40-60',
                'over_61',
              ].includes(values?.workers_number)

              return (
                <>
                  {currentStep <= 2 ? (
                    <>
                      {isWithinWorkersSegment &&
                      currentStepData.step === 2 ? null : (
                        <Typography
                          variant="h3"
                          sx={(theme) => ({
                            marginBottom: theme.spacing(2),
                            textAlign: 'center',
                          })}
                        >
                          {currentStepData?.title}
                        </Typography>
                      )}
                      <Typography
                        variant="body1"
                        sx={(theme) => ({ color: theme.palette.black.dark })}
                      >
                        {currentStepData?.subtitle}
                      </Typography>
                    </>
                  ) : null}
                  <Form
                    style={{
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                      }}
                    >
                      {currentStep === 0 ? (
                        <OnboardingRole handleNextStep={handleNextStep} />
                      ) : null}
                      {currentStep === 1 ? (
                        <OnboardingPersonalize isLoading={isLoading} />
                      ) : null}
                      {currentStep === 2 ? <OnboardingCongratulations /> : null}
                    </Box>
                  </Form>
                </>
              )
            }}
          </Formik>
        </Box>
      </Page>
    </MainContainer>
  ) : null
}

export default Onboarding
