import { useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getHolisticPayrollCompany } from 'services/HolisticPayroll/CompanyService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getHolisticPayrollData')
    return getHolisticPayrollCompany()

  return null
}

const useHolisticPayrollCompany = ({
  serviceParams = {
    queryKey: 'getHolisticPayrollData',
  },
  queryOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restHolisticPayrollCompany } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  return {
    holisticPayrollCompanyDashboardQuery: {
      data,
      ...restHolisticPayrollCompany,
    },
  }
}

export default useHolisticPayrollCompany
