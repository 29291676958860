import { useState } from 'react'

const useEventDetailModal = () => {
  const [state, setState] = useState({
    open: false,
    title: null,
    description: null,
    items: [],
    eventType: null,
  })

  const openEventDetailModal = ({ title, description, items, eventType }) => {
    setState({
      open: true,
      title,
      description,
      items,
      eventType,
    })
  }

  const closeEventDetailModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    eventDetailModalState: state,
    openEventDetailModal,
    closeEventDetailModal,
  }
}

export default useEventDetailModal
