import http, { getApiURL } from '../httpService'

export function getHolisticPayrollCompany() {
  const url = getApiURL({
    pathname: '/financial_wellness/dashboard',
  })

  return http.getV2(url)
}

export default {
  getHolisticPayrollCompany,
}
