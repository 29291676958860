import { Box, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import EventItem from './EventItem'

const EventDetailModal = ({ state, handleClose }) => {
  return (
    <Modal
      open={state.open}
      header={state.title}
      onOk={handleClose}
      okText="Cerrar"
      onCancel={handleClose}
      hideCancelButton
      adornment={[
        {
          variant: 'explosion',
          color: 'complementary2',
          width: 144,
          height: 132,
          sx: { bottom: '0.94rem', right: '0.94rem' },
        },
      ]}
      paperSx={{
        maxWidth: {
          tablet: '40rem',
          width: '100%',
        },
      }}
    >
      <Typography
        variant="body1"
        color="black.dark"
        sx={(theme) => ({
          marginBottom: theme.spacing(4),
        })}
      >
        {state.description}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        {state.items.map((item, index) => {
          const avatarId = (index % 5) + 1
          const key = `${state.eventType}-${index}`

          return (
            <EventItem
              key={key}
              eventType={state.eventType}
              name={item.worker_name}
              date={item.date}
              years={item.years}
              workerId={item.worker_id}
              avatarId={avatarId}
              compact={false}
              picture={item.picture}
            />
          )
        })}
      </Box>
    </Modal>
  )
}

export default EventDetailModal
