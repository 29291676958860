import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Alert from 'components/UI/MaterialUI/Alert/Alert'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import CardTable from '../../common/CardGrid'
import GridRow from '../../common/CardGrid/GridRow'
import HeaderCell from '../../common/CardGrid/HeaderCell'
import HeaderRow from '../../common/CardGrid/HeaderRow'
import MobileCell from '../../common/CardGrid/MobileCell'

const UVTConfiguration = ({
  workerPayroll,
  uvtValueUpLimit,
  setUvtValueUpLimit,
  setArt206LimitValue,
  setArt336LimitValue,
}) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  const handleOnChange = (event) => {
    const {
      target: { rawValue, id },
    } = event

    if (id === 'art_206_uvt') {
      if (Number(rawValue) > workerPayroll.art_206_yearly_max_limit) {
        setUvtValueUpLimit(true)
      } else {
        setArt206LimitValue(rawValue)
        setUvtValueUpLimit(false)
      }
    }

    if (id === 'art_336_uvt') {
      if (Number(rawValue) > workerPayroll.art_336_yearly_max_limit) {
        setUvtValueUpLimit(true)
      } else {
        setArt336LimitValue(rawValue)
        setUvtValueUpLimit(false)
      }
    }
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
      })}
    >
      <Typography variant="h6" color="black.main">
        Configuración límite de UVTs en el mes
      </Typography>

      <CardTable gridColumns="1.3fr 0.5fr">
        <HeaderRow>
          <HeaderCell
            sx={{
              gridColumn: 1,
            }}
            desktopOnly
          >
            Concepto
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 2,
            }}
            desktopOnly
          >
            Valor
          </HeaderCell>
        </HeaderRow>

        <GridRow gutterBottom>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 1,
            }}
            mobileOnly
          >
            Concepto
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 2,
            }}
            mobileOnly
            alignY="center"
          >
            Valor
          </HeaderCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 1,
              },
            })}
          >
            <Typography
              variant="h6"
              color="black.dark"
              htmlFor="payrollRetentioArt206UVTLimitValueInput"
              component="label"
            >
              Renta exenta de acuerdo a numeral 10 artículo 206 ET (790 UVTs
              anuales)
            </Typography>
          </MobileCell>

          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            alignY="center"
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 2,
              },
            })}
          >
            <TextField
              sx={{ width: '12rem' }}
              id="art_206_uvt"
              value={workerPayroll.art_206_uvt_limit}
              onChange={handleOnChange}
              InputProps={{
                inputComponent: NumberFormatField,
                startAdornment: (
                  <InputAdornment position="start">UVT</InputAdornment>
                ),
                sx: {
                  maxWidth: '12rem',
                  height: '2rem',
                },
              }}
              data-cy="payroll_retention-input"
            />
          </MobileCell>
        </GridRow>

        <GridRow gutterBottom>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 1,
            }}
            mobileOnly
          >
            Concepto
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 2,
            }}
            mobileOnly
            alignY="center"
          >
            Valor
          </HeaderCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 1,
              },
            })}
          >
            <Typography
              variant="h6"
              color="black.dark"
              htmlFor="payrollRetentioArt366UVTLimitValueInput"
              component="label"
            >
              Valor sobre el límite del 40% en las deducciones y rentas exentas
              (1.340 UVTs anuales)
            </Typography>
          </MobileCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            alignY="center"
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 2,
              },
            })}
          >
            <TextField
              sx={{ width: '12rem' }}
              id="art_336_uvt"
              value={workerPayroll.art_336_uvt_limit}
              onChange={handleOnChange}
              InputProps={{
                inputComponent: NumberFormatField,
                startAdornment: (
                  <InputAdornment position="start">UVT</InputAdornment>
                ),
                sx: {
                  maxWidth: '12rem',
                  height: '2rem',
                },
              }}
              data-cy="payroll_retention-input"
            />
          </MobileCell>
        </GridRow>

        {uvtValueUpLimit ? (
          <Box
            sx={(theme) => ({
              paddingX: theme.spacing(3.5),
              marginBottom: theme.spacing(3),
            })}
          >
            <Alert
              severity="warning"
              header="Vas a superar el límite legal de UVTs"
              closable
            >
              <Typography variant="body2">
                Recuerda conservar los límites para que no tengas inconvenientes
                con la DIAN
              </Typography>
            </Alert>
          </Box>
        ) : null}
      </CardTable>
    </Box>
  )
}

export default UVTConfiguration
