import { Form, Formik } from 'formik'
import { useMemo } from 'react'

import Modal from 'components/UI/Modal/Modal'
import useRecurrentConcepts from 'components/Worker/Show/useRecurrentConcepts'
import useWorker from 'components/Worker/useWorker'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useLoansService from 'utils/hooks/worker/loans'
import useRecurrentConceptsService from 'utils/hooks/worker/recurrentConcepts'

import { getValidationSchema } from '../../RecurrentConcepts/Deductions/helpers'
import { validationSchema as incomesValidationSchema } from '../../RecurrentConcepts/Income/helpers'
import { getContent } from './helpers'

const RecurrentConceptsModal = ({ modalType, onCancel }) => {
  const { worker } = useWorker({ useCache: true })
  const { refreshRecurrentConcepts } = useRecurrentConcepts()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const { loansMutation } = useLoansService({
    queryOptions: {
      enabled: false,
    },
  })
  const { recurrentConceptsMutation } = useRecurrentConceptsService({
    queryOptions: {
      enabled: false,
    },
  })

  const deductionsValidationSchema = useMemo(
    () => getValidationSchema(worker.base_salary),
    [worker.base_salary]
  )

  const validationSchema =
    modalType === 'incomes'
      ? incomesValidationSchema
      : deductionsValidationSchema

  const {
    title,
    body,
    initialValues,
    handleSubmit: onSubmit,
  } = getContent(
    modalType,
    worker,
    onCancel,
    handleError,
    showSuccessMessage,
    refreshRecurrentConcepts,
    loansMutation,
    recurrentConceptsMutation
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Modal
            header={title}
            open
            onOk={handleSubmit}
            onCancel={onCancel}
            okText="Guardar"
            isLoading={isSubmitting}
            dialogProps={{ maxWidth: 'md', fullWidth: true }}
          >
            <Form>{body}</Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default RecurrentConceptsModal
