import { Box, Chip, Hidden, Typography } from '@mui/material'

import Page from 'components/UI/Page/Page'

import IlustrationsSitting from 'assets/images/views/common/aleluya_vibe.svg'

import Footer from './Atoms/FooterModal'
import { usePremiumActions } from './helpers'

function getMessagesByStatusCode(code) {
  const messagesByStatusCode = {
    '0006': {
      primaryButtonText: 'Activar mis 15 días gratis',
      secondaryButtonText: 'Pasarme a Premium',
      primaryText:
        'Estás entrando a una funcionalidad Premium. Actualiza tu plan y comienza a liquidar la nómina más rápida de tu vida',
      secondaryText:
        'Activa por 15 días Aleluya Premium y comienza a disfrutar del poder del ferrari de la nómina Colombiana.',
    },
    '0007': {
      primaryButtonText: 'Pasarme a Premium',
      secondaryButtonText: 'Hablar con ventas',
      primaryText:
        '¡Tus días para probar el poder de Aleluya Premium han terminado!',
      secondaryText:
        'Únete a más de 1.000 compañías que hoy están disfrutando de todo el poder del Ferrari de la Nómina Colombiana.',
    },
    '0008': {
      primaryButtonText: 'Renovar mi plan Premium',
      primaryText: '¡Tu plan de Aleluya Premium ha terminado!',
      secondaryText:
        'Actualiza tu plan y sigue disfrutando del ferrari de la nómina Colombiana',
    },
    '0009': {
      primaryButtonText: 'Activar mi plan Premium',
      primaryText:
        'Estás entrando a una funcionalidad Premium. Actualiza tu plan y comienza a liquidar la nómina más rápida de tu vida',
      secondaryText:
        'Activa tu plan y continua disfrutando del poder del ferrari de la nómina Colombiana.',
    },
  }

  return messagesByStatusCode[code]
}

const PageGetPremium = ({ code }) => {
  const { handleGetPremium } = usePremiumActions()

  const {
    primaryButtonText,
    secondaryButtonText = null,
    primaryText,
    secondaryText,
  } = getMessagesByStatusCode(code)

  return (
    <Page
      contentSx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          alignItems: 'center',
          gridAutoFlow: 'column',
          gap: theme.spacing(7.5),
        })}
      >
        <Hidden mdDown>
          <img
            src={IlustrationsSitting}
            alt="Relájate en la playa mientras tienes Aleluya Premium"
          />
        </Hidden>
        <Box
          sx={(theme) => ({
            maxWidth: 445,
            display: 'grid',
            gridAutoFlow: 'row',
            gap: theme.spacing(4.5),
          })}
        >
          <Chip
            sx={{ width: 'fit-content' }}
            label={<Typography>Aleluya Premium</Typography>}
            color="primary"
          />
          <Box>
            <Typography
              variant="lead1"
              sx={(theme) => ({ marginBottom: theme.spacing(2) })}
            >
              {primaryText}
            </Typography>
            <Typography variant="body1">{secondaryText}</Typography>
          </Box>
          <Footer
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            onClick={handleGetPremium}
            code={code}
            page
          />
        </Box>
      </Box>
    </Page>
  )
}

export default PageGetPremium
