import * as yup from 'yup'

import { Box, Button, Typography } from '@mui/material'

import LoadedFile from 'components/UI/Formik/CommonFields/LoadedFile'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'

yup.setLocale(yupLocaleES)

export const KINSHIP_LABEL = {
  spouse: 'Cónyuge o compañero(a)',
  child: 'Hijo(a)',
  progenitor: 'Madre o padre',
  stepchild: 'Hijastro(a)',
}

const beneficiaryAffiliationTypes = {
  health_provider: 'EPS',
  compensation_fund: 'Caja de compensación',
}

export const getAffiliationTypeLabel = (entitiesToAffiliate = []) => {
  if (entitiesToAffiliate.length === 2) return 'Ambas'

  return beneficiaryAffiliationTypes[entitiesToAffiliate[0]]
}

export const kinshipOptions = Object.entries(KINSHIP_LABEL).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const remainKinshipOptions = (beneficiaries, currentKinship) => {
  const canAddProgenitor =
    beneficiaries?.filter((beneficiary) => beneficiary.kinship === 'progenitor')
      ?.length < 2
  const canAddSpouse =
    beneficiaries?.filter((beneficiary) => beneficiary.kinship === 'spouse')
      ?.length < 1
  let options = [...kinshipOptions]

  if (!['spouse', 'progenitor'].includes(currentKinship)) {
    if (!canAddProgenitor) {
      options = options.filter((item) => item.value !== 'progenitor')
    }

    if (!canAddSpouse) {
      options = options.filter((item) => item.value !== 'spouse')
    }
  } else {
    if (currentKinship === 'progenitor') {
      if (!canAddSpouse) {
        options = options.filter((item) => item.value !== 'spouse')
      }
    }

    if (currentKinship === 'spouse') {
      if (!canAddProgenitor) {
        options = options.filter((item) => item.value !== 'progenitor')
      }
    }
  }

  return options
}

export const getBeneficiaryEntitiesToAffiliate = (
  selectedEntitiesToAffiliate = []
) => {
  const isHealthProviderAvailable =
    selectedEntitiesToAffiliate.includes('health_provider')

  const isCompensationFund =
    selectedEntitiesToAffiliate.includes('compensation_fund')

  return [
    {
      label: 'EPS',
      value: 'health_provider',
      disabled: !isHealthProviderAvailable,
    },
    {
      label: 'Caja de compensación',
      value: 'compensation_fund',
      disabled: !isCompensationFund,
    },
    {
      label: 'Ambas',
      value: 'all',
      disabled: !isHealthProviderAvailable || !isCompensationFund,
    },
  ]
}

export const getBeneficiaryEntitiesToAffiliateArray = (userSelection) => {
  return userSelection === 'all'
    ? ['health_provider', 'compensation_fund']
    : [userSelection]
}

export const DocumentSelector = ({ children, className }) => {
  return (
    <Button
      size="small"
      variant="text"
      className={className}
      startIcon={<Icon name="upload" basic />}
    >
      <Box component="span" sx={{ textAlign: 'left' }}>
        {children}
      </Box>
    </Button>
  )
}

const documentName = {
  spouse: {
    marriage_certificate: 'Certificado matrimonio / unión libre',
    identification_document: 'Documento de identificación',
    labor_certificate: 'Certificado laboral (si aplica)',
    dependency_declaration: 'Certificado de dependencia económica',
  },
  child: {
    identification_document: 'Documento de identificación',
    civil_registration: 'Registro civil de nacimiento',
    study_document: 'Certificado de estudios',
    dependency_declaration: 'Certificado de dependencia económica',
    disability_certificate: 'Certificado de discapacidad',
    adoption_certificate: 'Certificado de adopción',
  },
  progenitor: {
    identification_document: 'Documento de identificación',
    civil_registration: 'Registro civil del empleado',
    health_affiliation_certificate: 'Certificado afiliación EPS grupo familiar',
    dependency_declaration: 'Certificado de dependencia económica',
  },
  stepchild: {
    civil_registration: 'Registro civil de nacimiento',
    identification_document: 'Documento de identificación',
    parents_death_certificate: 'Registro defunción padres biológicos',
    dependency_declaration: 'Certificado de dependencia económica',
    health_affiliation_certificate: 'Certificado afiliación EPS grupo familiar',
    study_document: 'Certificado de estudios',
    disability_certificate: 'Certificado de discapacidad',
  },
}
export const getDocumentLabel = ({ kinship, documentType }) =>
  documentName?.[kinship]?.[documentType] || ''

export const getFilesName = (files) => {
  const names = {}

  Object.entries(files).forEach(([key, value]) => {
    const documentUrl = isValidUrl(value?.url ? value.url : value)

    const fileName = getFileNameFromUrl(
      documentUrl ? documentUrl.search : undefined
    )

    names[key] = fileName
  })

  return names
}

const getChildAgeInterval = (_childAge) => {
  const childAge = Number(_childAge)

  if (childAge >= 0 && childAge <= 7) return '0-7'
  if (childAge >= 8 && childAge <= 18) return '8-18'
  if (childAge >= 19 && childAge <= 25) return '19-25'
}

export const attachDocuments = ({
  kinship,
  entities_to_affiliate: entitiesToAffiliate,
  age: childAge,
  ...files
}) => {
  const childAgeInterval = getChildAgeInterval(childAge)
  const names = getFilesName(files)

  if (
    !kinship ||
    !entitiesToAffiliate ||
    (['child', 'stepchild'].includes(kinship) && !childAge)
  ) {
    return null
  }

  const renderFormField = (documentName) => (
    <FormField
      key={documentName}
      name={documentName}
      variant="file"
      fileType="application/pdf"
      accept="application/pdf"
      disableDropzone
      fullWidth={false}
      fileName={names[documentName]}
      label={
        files[documentName]
          ? getDocumentLabel({ kinship, documentType: documentName })
          : null
      }
      selector={
        <DocumentSelector>
          {getDocumentLabel({ kinship, documentType: documentName })}
        </DocumentSelector>
      }
      withoutBorder
      formControlSx={{
        '& .MuiOutlinedInput-root': {
          ...(!files[documentName] && { padding: 0 }),
        },
      }}
    />
  )

  const documents = [
    {
      condition:
        !['child', 'stepchild'].includes(kinship) || childAgeInterval !== '0-7',
      documentName: 'identification_document',
    },
    {
      condition: ['child', 'progenitor', 'stepchild'].includes(kinship),
      documentName: 'civil_registration',
    },
    {
      condition:
        ['child', 'stepchild'].includes(kinship) &&
        childAgeInterval === '19-25' &&
        entitiesToAffiliate !== 'health_provider',
      documentName: 'study_document',
    },
    {
      condition:
        ['child', 'stepchild'].includes(kinship) &&
        childAgeInterval === '19-25' &&
        entitiesToAffiliate !== 'health_provider',
      documentName: 'disability_certificate',
    },
    {
      condition: kinship === 'spouse',
      documentName: 'marriage_certificate',
    },
    {
      condition:
        kinship === 'spouse' && entitiesToAffiliate !== 'health_provider',
      documentName: 'labor_certificate',
    },
    {
      condition:
        (!['child', 'stepchild'].includes(kinship) ||
          childAgeInterval === '19-25') &&
        entitiesToAffiliate !== 'health_provider',
      documentName: 'dependency_declaration',
    },
    {
      condition:
        ['stepchild', 'progenitor'].includes(kinship) &&
        entitiesToAffiliate !== 'health_provider',
      documentName: 'health_affiliation_certificate',
    },
    {
      condition: kinship === 'child',
      documentName: 'adoption_certificate',
    },
    {
      condition: kinship === 'stepchild',
      documentName: 'parents_death_certificate',
    },
  ]

  return (
    <>
      {documents.map(({ condition, documentName }) =>
        condition ? renderFormField(documentName) : null
      )}
    </>
  )
}

const DownloadButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        padding: 0,
        minWidth: 'auto',
        width: 'fit-content',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      }}
    >
      <Typography
        variant="lead2"
        sx={(theme) => ({ color: theme.palette.black.dark })}
      >
        Ver
      </Typography>
    </Button>
  )
}

const AttachmentBox = ({ documentType, fileName, kinship, onClick }) => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      gap: theme.spacing(0.5),
    })}
  >
    <Typography variant="tiny">
      {getDocumentLabel({ kinship, documentType })}
    </Typography>
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
      })}
    >
      <LoadedFile fileName={fileName} hideRemoveButton />
      <DownloadButton onClick={onClick} />
    </Box>
  </Box>
)

export const getAttachedDocuments = ({
  kinship,
  files,
  downloadURI,
  childAge,
  entitiesToAffiliate,
}) => {
  const names = getFilesName(files)

  const handleClick = (documentType) => {
    downloadURI(files[documentType])
  }

  const childAgeInterval = getChildAgeInterval(childAge)

  const isHealthAffiliationOnly =
    entitiesToAffiliate.length === 1 &&
    entitiesToAffiliate[0] === 'health_provider'

  const documents = [
    {
      condition:
        names.identification_document &&
        (!['child', 'stepchild'].includes(kinship) ||
          childAgeInterval !== '0-7'),
      documentType: 'identification_document',
      fileName: names.identification_document,
    },
    {
      condition:
        names.civil_registration &&
        ['child', 'stepchild', 'progenitor'].includes(kinship),
      documentType: 'civil_registration',
      fileName: names.civil_registration,
    },
    {
      condition:
        names.study_document &&
        ['child', 'stepchild'].includes(kinship) &&
        childAgeInterval === '19-25' &&
        !isHealthAffiliationOnly,
      documentType: 'study_document',
      fileName: names.study_document,
    },
    {
      condition:
        names.disability_certificate &&
        ['child', 'stepchild'].includes(kinship) &&
        childAgeInterval === '19-25' &&
        !isHealthAffiliationOnly,
      documentType: 'disability_certificate',
      fileName: names.disability_certificate,
    },
    {
      condition: names.marriage_certificate && kinship === 'spouse',
      documentType: 'marriage_certificate',
      fileName: names.marriage_certificate,
    },
    {
      condition:
        names.labor_certificate &&
        kinship === 'spouse' &&
        !isHealthAffiliationOnly,
      documentType: 'labor_certificate',
      fileName: names.labor_certificate,
    },
    {
      condition:
        names.dependency_declaration &&
        (!['child', 'stepchild'].includes(kinship) ||
          childAgeInterval === '19-25') &&
        !isHealthAffiliationOnly,
      documentType: 'dependency_declaration',
      fileName: names.dependency_declaration,
    },
    {
      condition:
        names.health_affiliation_certificate &&
        ['progenitor', 'stepchild'].includes(kinship) &&
        !isHealthAffiliationOnly,
      documentType: 'health_affiliation_certificate',
      fileName: names.health_affiliation_certificate,
    },
    {
      condition: names.adoption_certificate && kinship === 'child',
      documentType: 'adoption_certificate',
      fileName: names.adoption_certificate,
    },
    {
      condition: names.parents_death_certificate && kinship === 'stepchild',
      documentType: 'parents_death_certificate',
      fileName: names.parents_death_certificate,
    },
  ]

  return (
    <>
      {documents.map(({ condition, documentType, fileName }) =>
        condition ? (
          <AttachmentBox
            key={documentType}
            documentType={documentType}
            fileName={fileName}
            kinship={kinship}
            onClick={() => handleClick(documentType)}
          />
        ) : null
      )}
    </>
  )
}

export const getInitialValues = (beneficiary = {}) => {
  const entitiesToAffiliate =
    beneficiary.entities_to_affiliate?.length > 0
      ? beneficiary.entities_to_affiliate?.length === 2
        ? 'all'
        : beneficiary.entities_to_affiliate?.[0]
      : null

  const {
    identification_document: identificationDocumentUrl,
    marriage_certificate: marriageCertificateUrl,
    labor_certificate: laborCertificateUrl,
    civil_registration: civilRegistrationUrl,
    study_document: studyDocumentUrl,
    dependency_declaration: dependencyDeclarationUrl,
    disability_certificate: disabilityCertificateUrl,
    health_affiliation_certificate: healthAffiliationCertificateUrl,
    parents_death_certificate: parentsDeathCertificateUrl,
    adoption_certificate: adoptionCertificateUrl,
  } = beneficiary.files || {}

  return {
    name: beneficiary.name || null,
    kinship: beneficiary.kinship || null,
    entities_to_affiliate: entitiesToAffiliate,
    age: beneficiary.age || null,
    identification_document: identificationDocumentUrl,
    marriage_certificate: marriageCertificateUrl,
    labor_certificate: laborCertificateUrl,
    civil_registration: civilRegistrationUrl,
    study_document: studyDocumentUrl,
    dependency_declaration: dependencyDeclarationUrl,
    disability_certificate: disabilityCertificateUrl,
    health_affiliation_certificate: healthAffiliationCertificateUrl,
    parents_death_certificate: parentsDeathCertificateUrl,
    adoption_certificate: adoptionCertificateUrl,
  }
}

const baseSchema = yup
  .mixed()
  .test('isPdf', 'El documento debe ser un PDF.', (value) => {
    if (value instanceof File) {
      return value.type === 'application/pdf'
    }
    return value ? typeof value === 'string' : true
  })
  .test(
    'fileSize',
    'El archivo que intentas subir no tiene contenido o se encuentra dañado.',
    (value) => {
      if (!value) return true

      if (value instanceof File) {
        return value.size > 0
      }

      return true
    }
  )

export const validationSchema = yup.object({
  name: yup.string().nullable().required(),
  kinship: yup.string().nullable().required(),
  entities_to_affiliate: yup.string().nullable().required(),
  age: yup.string().when('kinship', {
    is: (kinship) => ['child', 'stepchild'].includes(kinship),
    then: yup.string().nullable().required(),
    otherwise: yup.string().nullable(),
  }),
  identification_document: baseSchema.when(['kinship', 'age'], {
    is: (kinship, age) =>
      ['child', 'stepchild'].includes(kinship) &&
      getChildAgeInterval(age) === '0-7',
    then: yup.mixed().nullable(),
    otherwise: yup.mixed().required('Debes adjuntar este archivo.'),
  }),
  marriage_certificate: baseSchema.when('kinship', {
    is: (kinship) => kinship === 'spouse',
    then: yup.mixed().required('Debes adjuntar este archivo.'),
    otherwise: yup.mixed().nullable(),
  }),
  civil_registration: baseSchema.when('kinship', {
    is: (kinship) => ['child', 'progenitor', 'stepchild'].includes(kinship),
    then: yup.mixed().required('Debes adjuntar este archivo.'),
    otherwise: yup.mixed().nullable(),
  }),
  study_document: baseSchema.when(
    [
      'kinship',
      'age',
      'entities_to_affiliate',
      'disability_certificate',
      'dependency_declaration',
    ],
    {
      is: (
        kinship,
        age,
        entitiesToAffiliate,
        disabilityCertificate,
        dependencyDeclaration
      ) =>
        ['child', 'stepchild'].includes(kinship) &&
        getChildAgeInterval(age) === '19-25' &&
        entitiesToAffiliate !== 'health_provider' &&
        !disabilityCertificate &&
        !dependencyDeclaration,
      then: yup
        .mixed()
        .required(
          'El certificado de estudios es requerido si no has adjuntado declaración de dependencia o certificado de discapacidad.'
        ),
      otherwise: yup.mixed().nullable(),
    }
  ),
  labor_certificate: baseSchema.test(
    'eitherDependencyOrLaborFileAreRequired',
    null,
    function (value) {
      const { parent } = this
      const { dependency_declaration, kinship, entities_to_affiliate } = parent

      if (kinship === 'spouse' && entities_to_affiliate !== 'health_provider') {
        if (!value && !dependency_declaration) {
          return this.createError({
            path: 'labor_certificate',
            message:
              'El certificado laboral es requerido si no has adjuntado la declaración de dependencia.',
          })
        }
      }
      return true
    }
  ),
  dependency_declaration: baseSchema
    .test(
      'eitherDependencyOrLaborFileAreRequired',
      'El certificado laboral o la declaración de dependencia son requeridos.',
      function (value) {
        const { parent } = this
        const { labor_certificate, kinship, entities_to_affiliate } = parent

        if (
          kinship === 'spouse' &&
          entities_to_affiliate !== 'health_provider'
        ) {
          if (!value && !labor_certificate) {
            return this.createError({
              path: 'dependency_declaration',
              message:
                'La declaración de dependencia es requerida si no has adjuntado el certificado laboral.',
            })
          }
        }
        return true
      }
    )
    .test('dependencyDeclarationRequired', null, function (value) {
      const { parent } = this
      const {
        dependency_declaration,
        kinship,
        age,
        entities_to_affiliate: entitiesToAffiliate,
        study_document,
        disability_certificate,
      } = parent

      if (
        ((['child', 'stepchild'].includes(kinship) &&
          getChildAgeInterval(age) === '19-25' &&
          !study_document &&
          !disability_certificate) ||
          kinship === 'progenitor') &&
        entitiesToAffiliate !== 'health_provider' &&
        !value &&
        !dependency_declaration
      ) {
        return this.createError({
          path: 'dependency_declaration',
          message:
            kinship === 'progenitor'
              ? 'Debes adjuntar este archivo.'
              : 'La declaración de dependencia es requerida si no has adjuntado certificado de estudios o discapacidad.',
        })
      }

      return true
    }),
  health_affiliation_certificate: baseSchema.when(
    ['kinship', 'entities_to_affiliate'],
    {
      is: (kinship, entitiesToAffiliate) =>
        ['progenitor', 'stepchild'].includes(kinship) &&
        entitiesToAffiliate === 'compensation_fund',
      then: yup.mixed().required('Debes adjuntar este archivo.'),
      otherwise: yup.mixed().nullable(),
    }
  ),
  adoption_certificate: baseSchema.when('kinship', {
    is: (kinship) => kinship === 'child',
    then: yup.mixed().nullable(),
  }),
  parents_death_certificate: baseSchema.when('kinship', {
    is: (kinship) => kinship === 'stepchild',
    then: yup.mixed().nullable(),
  }),
  disability_certificate: baseSchema.when(
    ['kinship', 'age', 'entities_to_affiliate'],
    {
      is: (kinship, age, entitiesToAffiliate) =>
        ['child', 'stepchild'].includes(kinship) &&
        getChildAgeInterval(age) === '19-25' &&
        entitiesToAffiliate !== 'health_provider',
      then: yup.mixed().nullable(),
    }
  ),
})

export const FILES = [
  'identification_document',
  'marriage_certificate',
  'labor_certificate',
  'civil_registration',
  'study_document',
  'dependency_declaration',
  'disability_certificate',
  'health_affiliation_certificate',
  'parents_death_certificate',
  'adoption_certificate',
]

export const getBeneficiaryAffiliationEntitiesAlert = ({
  worker,
  selectedEntitiesToAffiliate,
  isTrainee,
}) => {
  const healtProviderUnavailable =
    !selectedEntitiesToAffiliate.includes('health_provider')

  const compensationFundUnavailable =
    !selectedEntitiesToAffiliate.includes('compensation_fund')

  if (!healtProviderUnavailable && !compensationFundUnavailable) {
    return { showBeneficiaryAffiliationEntitiesAlert: false, alertText: '' }
  }

  const alertText = (
    <>
      La opción{' '}
      <b>{healtProviderUnavailable ? 'EPS' : 'Caja de compensación'}</b> no
      estará disponible para los beneficiarios, porque{' '}
      {isTrainee
        ? 'el tipo de contrato no permite seleccionar'
        : 'no seleccionaste'}{' '}
      esta entidad como parte del proceso de afiliación a la seguridad social de{' '}
      <b>{worker.name}</b>.
    </>
  )

  return {
    showBeneficiaryAffiliationEntitiesAlert: true,
    alertText,
  }
}
