import { createContext, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Documents from 'components/Worker/Documents/Index'

export const DocumentsContext = createContext()
DocumentsContext.displayName = 'DocumentsContext'

const DocumentsContainer = () => {
  const [openFolder, setOpenFolder] = useState(null)

  const value = {
    setOpenFolder,
    openFolder,
  }

  const { isWorker } = useUser()

  return (
    <DocumentsContext.Provider value={value}>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 3, 6, 3),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
          })}
        >
          <Typography variant="h4">
            {openFolder?.name || 'Documentos compartidos'}
          </Typography>
        </Box>
        {!isWorker ? (
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginTop: theme.spacing(1) })}
          >
            ¡Aquí podrás agregar hasta 30 archivos por persona y distribuirlos
            en las carpetas que quieras! Por ahora, puedes cargar archivos en
            PDF.
          </Typography>
        ) : null}
        <Documents />
      </Box>
    </DocumentsContext.Provider>
  )
}

export default DocumentsContainer
