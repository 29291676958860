import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const BreadcrumbsContext = createContext()
BreadcrumbsContext.displayName = 'BreadcrumbsContext'

export const useBreadcrumbsConfig = () => {
  const context = useContext(BreadcrumbsContext)
  if (!context)
    throw new Error(
      'useBreadcrumbsConfig must be used within BreadcrumbsProvider'
    )
  return context
}

const BreadcrumbsProvider = ({ children }) => {
  const [config, setConfig] = useState({})

  const setBreadcrumbConfig = useCallback((path, configuration) => {
    setConfig((previousConfig) => ({
      ...previousConfig,
      [path]: { ...configuration },
    }))
  }, [])

  const contextValue = useMemo(
    () => ({ breadcrumbsConfig: config, setBreadcrumbConfig }),
    [config, setBreadcrumbConfig]
  )

  return (
    <BreadcrumbsContext.Provider value={contextValue}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export default BreadcrumbsProvider
