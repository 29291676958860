import {
  Box,
  Card,
  CardContent,
  Typography,
  cardContentClasses,
} from '@mui/material'

const OrganizerPartnerShowSummary = ({ data }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        justifyContent: 'flex-start',
        gridGap: theme.spacing(2),
        gridTemplateColumns: { mobile: '1fr', tablet: '1fr 1fr' },
        marginBottom: theme.spacing(2),
      })}
    >
      {data.map(({ id, Icon, label, value }) => {
        return (
          <Card key={id}>
            <CardContent
              sx={{
                [`& .${cardContentClasses.root}`]: {
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  justifyContent: 'center',
                },
              }}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                })}
              >
                {Icon}
                <Typography variant="lead2">{label}</Typography>
              </Box>
              <Typography variant="h5">{value}</Typography>
            </CardContent>
          </Card>
        )
      })}
    </Box>
  )
}

export default OrganizerPartnerShowSummary
