import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

export const validationSchema = yup.object({
  initial_day: yup.string().required(),
  end_day: yup.string().required(),
})

export const getContentByReportType = (reportType, companyId) => {
  const contentConfig = {
    paymentsReport: {
      headerTitle: 'Reporte consolidado por períodos',
      description: (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
          })}
        >
          <Typography>
            Con esta opción, puedes descargar resúmenes de nómina de varios
            períodos juntos o de forma individual.
          </Typography>
          <Typography>
            <b>Recuerda:</b> los contratistas y empleados liquidados aparecen en
            hojas diferentes dentro del reporte.
          </Typography>
        </Box>
      ),
      mutationKey: 'downloadConsolidatePayrollSummaryFile',
      downloadFileConfig: {
        name: `reporte consolidado del periodo`,
        fileCode: 'consolidated_report',
        pathname: `companies/${companyId}/payroll_summary`,
      },
    },
    noveltiesReport: {
      headerTitle: 'Reporte de novedades personalizado',
      description: (
        <Typography>
          Con esta opción, puedes descargar reportes de novedades de tus
          empleados durante el periodo que desees revisar.
        </Typography>
      ),
      mutationKey: 'downloadNoveltiesSummaryFile',
      downloadFileConfig: {
        name: `reporte de novedades`,
        fileCode: 'novelties_report',
        pathname: `companies/${companyId}/novelties_summary_file`,
      },
    },
  }

  return contentConfig[reportType]
}
