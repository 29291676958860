import { getCompanyId } from 'utils/company'

import http, { getApiURL } from '../../httpService'

export const deleteContract = (id) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/contracts/${id}`,
    withoutVersion: true,
  })

  return http.deleteV2(url)
}

export default {
  deleteContract,
}
