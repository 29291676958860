export const booleanLookup = { true: 'Sí', false: 'No' }

export const booleanLookupOptions = Object.keys(booleanLookup).map((key) => ({
  value: key === 'true',
  label: booleanLookup[key],
}))

export const plansLookupOptions = {
  workers_range_plan: 'Por rango de personas',
  per_worker_plan: 'Base fija + cantidad de personas',
}

export const plansOptions = Object.keys(plansLookupOptions).map(
  (plansOptionCode) => ({
    label: plansLookupOptions[plansOptionCode],
    value: plansOptionCode,
  })
)
