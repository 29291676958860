import { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import SignInForm from 'components/Auth/SignIn/SignIn'
import AleluyaPerson from 'components/UI/AleluyaPerson'
import Modal from 'components/UI/Modal/Modal'

import { isAuthenticated } from 'utils/auth'

import { LOGIN } from 'config/routes'

const stepsLoginFromRecovery = [
  {
    title: '¡La contraseña fue actualizada!',
    description:
      'Acabas de cambiar tu contraseña, puedes continuar usando el mejor software de nómina electrónica del país.',
  },
  {
    title: 'Ingresa a tu cuenta',
  },
]

const SignupModal = () => {
  const [step, setStep] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()

  const { state } = location
  const passwordRecoveredSuccessfully = state?.passwordRecoveredSuccessfully

  const handleSubmit = () => {
    if (step === 0) {
      setStep((previous) => previous + 1)
    }

    return null
  }

  const handleCloseModal = () => navigate(LOGIN)

  // Navigate when the path does not come from the password recovery
  useEffect(() => {
    if (!passwordRecoveredSuccessfully) {
      navigate(LOGIN)
    }
  }, [navigate, passwordRecoveredSuccessfully])

  // Navigate to inside app when the login is from modal
  if (isAuthenticated()) {
    const pathname = '/'
    return (
      <Navigate
        to={{
          pathname,
        }}
        state={{ fromLogin: true }}
      />
    )
  }

  return (
    <Modal
      open
      hideFooter={step === 1}
      header={stepsLoginFromRecovery[step].title}
      hideCancelButton
      hideCloseButton
      onOk={handleSubmit}
      okText={step === 0 ? 'Continuar' : null}
      onCloseModal={handleCloseModal}
      alignButtons="center"
      dialogProps={{ maxWidth: 'xs' }}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          rowGap: theme.spacing(2),
          justifyItems: 'center',
          textAlign: 'center',
        })}
      >
        {step === 0 ? (
          <AleluyaPerson
            color="primary.main"
            width={200}
            height={280}
            viewBox="0 0 330 400"
          />
        ) : null}
        <Typography variant="body1">
          {stepsLoginFromRecovery[step].description}
        </Typography>
        {step === 1 ? <SignInForm isFromRecoveryPassword /> : null}
      </Box>
    </Modal>
  )
}

export default SignupModal
