import { IconButton, Tooltip } from '@mui/material'

import Icon from 'components/UI/Icon'

const DeleteItemButton = ({
  name,
  values,
  arrayHelpers,
  onDelete,
  index,
  emptyValue,
  size,
  style,
  className,
}) => {
  const { deleteable } = values[name][index]

  return (
    <Tooltip
      title={deleteable === false ? 'No se puede eliminar' : 'Eliminar'}
      className={className}
    >
      {/* this <span> removes MUI warning when disabled button */}
      <span>
        <IconButton
          aria-label="delete"
          onClick={() => {
            if (deleteable !== false) {
              if (onDelete) {
                onDelete()
              }
              arrayHelpers.remove(index)
              if (values[name].length === 1 && emptyValue) {
                arrayHelpers.push(emptyValue)
              }
            }
          }}
          disabled={deleteable === false}
          style={style}
          size={size}
        >
          <Icon name="trash" basic />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default DeleteItemButton
