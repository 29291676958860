import FieldsAreas from './Areas'
import FieldsBasics from './Basics'
import FieldsCredentials from './Credentials/Credentials'
import FieldsLocations from './Locations/Locations'
import FieldsPaymentPreferences from './PaymentPreferences'
import FieldsPositions from './Positions'
import WorkCenters from './WorkCenters/Index'

export const switchRenderFields = (index, institutions, documentType) => {
  switch (index) {
    case 0:
      return <FieldsBasics documentType={documentType} />
    case 1:
      return <FieldsLocations />
    case 2:
      return <WorkCenters />
    case 3:
      return <FieldsAreas />
    case 4:
      return <FieldsPositions />
    case 5:
      return (
        <FieldsPaymentPreferences
          riskProviders={institutions.riskProviders}
          ssOperators={institutions.ssOperators}
        />
      )
    case 6:
      return <FieldsCredentials />
    default:
      return null
  }
}

export default { switchRenderFields }
