import { useInbox } from '@trycourier/react-hooks'
import { m } from 'framer-motion'
import { useEffect, useState } from 'react'

import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Popper,
  Typography,
  badgeClasses,
  tabClasses,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import LazyAnimation from 'components/UI/LazyAnimation'
import Link from 'components/UI/MaterialUI/Link'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'

import IconButton from '../IconButton'
import Messages from './Messages'

const bellVariants = {
  active: {
    rotate: [0, 10, -10, 10, -10, 10, -10, 0],
    transition: {
      duration: 1.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatDelay: 3,
    },
  },
  inactive: { rotate: 0 },
}

const clapperVariants = {
  active: {
    translateX: [0, -4, 4, -4, 4, -4, 4, 0],
    transition: {
      duration: 1.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatDelay: 3,
    },
  },
  inactive: { translateX: 0 },
}

const NotificationIcon = ({ showBadge }) => {
  return (
    <Badge
      invisible={!showBadge}
      variant="dot"
      color="primary"
      sx={(theme) => ({
        [`& .${badgeClasses.badge}`]: {
          right: 12,
          top: 16,
          height: '0.8rem',
          width: '0.8rem',
          borderRadius: '1rem',
          border: `2px solid ${theme.palette.white.main}}`,
        },
      })}
    >
      <LazyAnimation>
        <m.svg
          width="40"
          height="40"
          style={{
            transformOrigin: 'top',
          }}
          animate={showBadge ? 'active' : 'inactive'}
          variants={bellVariants}
        >
          <m.path
            d="M19.0236 36C16.889 36 15.1519 34.2629 15.1519 32.1282C15.1519 31.767 15.202 31.4092 15.2996 31.0661C15.3826 30.7774 15.6842 30.6098 15.9729 30.6927C16.2615 30.7757 16.4292 31.0773 16.3462 31.366C16.2762 31.6114 16.2399 31.8672 16.2399 32.1274C16.2399 33.6614 17.4879 34.9102 19.0228 34.9102C20.5576 34.9102 21.8056 33.6614 21.8056 32.1274C21.8056 31.8672 21.7701 31.6114 21.6993 31.366C21.6163 31.0773 21.784 30.7757 22.0726 30.6927C22.3621 30.6098 22.6629 30.7774 22.7459 31.0661C22.8444 31.4092 22.8936 31.7661 22.8936 32.1282C22.8954 34.2629 21.1583 36 19.0236 36Z"
            fill="#019452"
            stroke="#019452"
            variants={clapperVariants}
          />
          <m.path
            d="M6.54485 31.7618C6.3478 31.7618 6.16545 31.6546 6.06952 31.4827C5.91396 31.2035 6.01162 31.0428 6.24842 30.6539C6.35818 30.4741 6.51633 30.2157 6.71251 29.8959C7.10401 29.2573 7.64588 28.3757 8.24479 27.4035C9.33459 25.6335 10.1236 24.3311 10.3362 23.9604C10.3328 23.2932 10.319 20.2632 10.2999 16.407C10.2904 14.3467 11.114 12.3788 12.6213 10.8647C13.8528 9.6271 15.468 8.78015 17.2224 8.42322V6.50549C17.2224 5.66114 18.0495 5 19.1065 5C20.1634 5 20.9905 5.66373 20.9905 6.51154V8.41372C22.6273 8.73348 24.1337 9.4724 25.3497 10.57C27.0349 12.091 27.9631 14.1168 27.9631 16.2739V23.689C28.1576 24.0589 28.8922 25.4088 29.918 27.2695C31.0121 29.2547 31.8954 30.857 31.9412 30.9391C32.0319 31.0989 32.0371 31.2943 31.9515 31.4593C31.7994 31.7523 31.5428 31.7532 31.2939 31.7549C31.1781 31.7558 31.0078 31.7558 30.7892 31.7566C30.3518 31.7575 29.7184 31.7584 28.9371 31.7592C27.3737 31.7609 25.2149 31.7618 22.8313 31.7618L15.1975 31.7627C10.427 31.7618 6.55004 31.7618 6.54485 31.7618ZM19.1134 9.32202C16.9398 9.32202 14.8544 10.1647 13.393 11.633C12.0924 12.9397 11.3802 14.6336 11.3889 16.4018C11.4087 20.462 11.426 24.0563 11.426 24.0952C11.4269 24.294 11.4269 24.3147 9.17212 27.9756C8.46517 29.1233 7.89132 30.0575 7.51365 30.6737L22.8304 30.672C26.474 30.672 29.2111 30.6694 30.5472 30.6677C30.2516 30.131 29.765 29.2495 28.9639 27.7959C26.8742 24.0036 26.8742 24.0036 26.8742 23.8169V16.2739C26.8742 14.4288 26.0739 12.6908 24.6203 11.378C23.1511 10.0523 21.1953 9.32202 19.1134 9.32202ZM19.1065 6.08893C18.6285 6.08893 18.3114 6.33956 18.3114 6.50549V8.2668C18.8386 8.22186 19.3761 8.22186 19.9016 8.2642V6.51154C19.9016 6.33956 19.5922 6.08893 19.1065 6.08893Z"
            fill="black"
            stroke="black"
          />
        </m.svg>
      </LazyAnimation>
    </Badge>
  )
}

const Popover = () => {
  const { markAllAsRead, messages, fetchMessages } = useInbox()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const [activeTab, setActiveTab] = useState(0)

  let unreadMessageCount = 0
  messages?.forEach((message) => {
    if (!message.read) {
      unreadMessageCount += 1
    }
  })

  const filteredMessages =
    activeTab === 1 ? messages?.filter((message) => !message.read) : messages

  useEffect(() => {
    fetchMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  const isOpen = open && Boolean(anchorEl)
  const id = isOpen ? 'notifications-popper' : undefined

  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleChangeTab = (_, newTab) => {
    setActiveTab(newTab)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <IconButton
          onKeyDown={onKeyDown}
          aria-describedby={id}
          variant="outlined"
          onClick={handleClick}
          icon={<NotificationIcon showBadge={unreadMessageCount > 0} />}
        />
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={isDesktop ? 'bottom-end' : 'bottom-start'}
          sx={(theme) => ({
            zIndex: theme.zIndex.appBar + 100,
            overflow: 'hidden',
            borderRadius: '1rem',
            boxShadow: theme.shadows[5],
            top: '0.5rem !important',
            [theme.breakpoints.up('tablet')]: {
              maxHeight: '44.5rem',
              margin: '0 !important',
            },
          })}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '27.5rem',
                  width: '100%',
                  maxHeight: '44rem',
                  height: '100%',
                  overflow: 'hidden',
                  backgroundColor: theme.palette.white.main,
                  [theme.breakpoints.up('tablet')]: {
                    maxHeight: '44.5rem',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    padding: theme.spacing(2),
                    gap: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.white.dark}`,
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: theme.spacing(6.75),
                    })}
                  >
                    <Typography variant="h5">Notificaciones</Typography>
                    <Button
                      variant="text"
                      size="small"
                      endIcon={
                        <Icon name="approved-checked" basic fontSize="small" />
                      }
                      onClick={() => markAllAsRead(false)}
                      sx={(theme) => ({
                        [theme.breakpoints.down('tablet')]: {
                          textAlign: 'left',
                        },
                      })}
                    >
                      Marcar todo como leído
                    </Button>
                  </Box>
                  <RoundedTabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    scrollButtons={false}
                    tabsConfig={[
                      {
                        label: 'Todas',
                      },
                      {
                        label: 'No leídas',
                      },
                    ]}
                    sx={{
                      width: '12rem',
                      minHeight: 'auto',
                      [`& .${tabClasses.root}`]: {
                        padding: 0,
                        height: '1.8rem',
                        minHeight: 'auto',
                        minWidth: 'auto',
                      },
                    }}
                  />
                </Box>
                <Messages messages={filteredMessages} />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '3rem',
                    borderTop: `1px solid ${theme.palette.white.dark}`,
                  })}
                >
                  <Link
                    to="/notifications"
                    sx={(theme) => ({
                      color: theme.palette.black.main,
                      ...theme.typography.lead2,
                    })}
                    onClick={() => setOpen(false)}
                  >
                    Ver todas las notificaciones
                  </Link>
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export default Popover
