import { Box, Button, Card, Typography } from '@mui/material'

import { getMainPaymentMethod } from 'components/Subscription/Index/PaymentMethod/helpers'

import { formatDisplayDateString } from 'utils/dateTime'

import usePaymentModal from '../../PaymentModal/usePaymentModal'
import UpdatePaymentMethodInfoModal from '../../UpdatePaymentMethodInfoModal'
import useUpdatePaymentMethodInfoModal from '../../UpdatePaymentMethodInfoModal/useUpdatePaymentMethodInfoModal'
import PaymentMethod from './PaymentMethod'

const PaymentMethodCard = ({ subscriptionData }) => {
  const paymentModal = usePaymentModal()

  const {
    updatePaymentMethodInfoModalState,
    closeUpdatePaymentMethodInfoModal,
    openUpdatePaymentMethodInfoModal,
  } = useUpdatePaymentMethodInfoModal()

  const openPaymentModal = () =>
    paymentModal.openModal({
      isEditingPaymentMethod: true,
      openUpdatePaymentMethodInfoModal,
    })

  const { subscription_payment_methods_info: currentPaymentMethodsInfo } =
    subscriptionData

  const mainPaymentMethod = getMainPaymentMethod(currentPaymentMethodsInfo)

  return (
    <Card
      sx={(theme) => ({
        flexGrow: 1,
        width: '100%',
        padding: theme.spacing(2, 2, 5),
        '& p': {
          color: theme.palette.black.light,
        },
        '& b': {
          color: theme.palette.black.main,
        },
      })}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
        })}
      >
        Método de pago
      </Typography>
      {mainPaymentMethod ? (
        <PaymentMethod
          key={mainPaymentMethod.category}
          mainPaymentMethod={mainPaymentMethod}
          subscriptionData={subscriptionData}
          isAutomaticDebit={mainPaymentMethod.category === 'automatic_debit'}
          isCreditCard={mainPaymentMethod.category === 'credit_card'}
          isPse={mainPaymentMethod.category === 'pse'}
        />
      ) : null}
      <Button onClick={openPaymentModal} fullWidth>
        {!currentPaymentMethodsInfo ? 'Agregar' : 'Editar'} método de pago
      </Button>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: theme.spacing(2),
        })}
      >
        <Typography variant="h6">Próximo cobro</Typography>
        <Typography variant="h6">
          <b>{formatDisplayDateString(subscriptionData?.active_until)}</b>
        </Typography>
      </Box>
      {updatePaymentMethodInfoModalState.open ? (
        <UpdatePaymentMethodInfoModal
          state={updatePaymentMethodInfoModalState}
          handleClose={closeUpdatePaymentMethodInfoModal}
          openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
        />
      ) : null}
    </Card>
  )
}

export default PaymentMethodCard
