import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const LoansAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openDeductionsModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={payroll.details?.loans?.value}
      onClick={() => openDeductionsModal('loans')}
      editable={period.editable && !payroll.locked}
      dataCy="add-loans"
    />
  )
}

export default LoansAction
