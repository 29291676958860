import { getFileNameFromUrl, isValidUrl } from 'utils/general'

const useFileName = (fieldValue) => {
  const url = isValidUrl(fieldValue)
  const fileName = getFileNameFromUrl(url ? url.search : undefined)

  return fileName
}

export default useFileName
