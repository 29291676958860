import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Typography } from '@mui/material'

import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getAllPartners } from 'services/organizer/partnerService'

import Modal from './Modal'
import { columnsData, getTableActions } from './helpers'

const initModalState = {
  open: false,
  partner: {},
}

const OrganizerPartnerIndex = () => {
  const [modalState, setModalState] = useState(initModalState)
  const queryClient = useQueryClient()

  const fetchPartners = (pageIndex, search) => {
    return {
      queryKey: ['getAllPartners', pageIndex + 1, search],
      queryFunction: () => getAllPartners({ page: pageIndex + 1, search }),
    }
  }

  const modalSubmitCallback = () =>
    queryClient.invalidateQueries(['getAllPartners'])

  const handleCloseModal = () => setModalState(initModalState)

  const tableActions = getTableActions(setModalState)

  return (
    <Page header="Partners">
      <Typography>
        En esta sección encuentras todos los partners que usan Aleluya y las
        condiciones bajo las que se realiza su facturación. Acá puedes agregar
        un nuevo partner o editar las condiciones de los que están activos.
      </Typography>
      <Table
        columns={columnsData}
        data={fetchPartners}
        actions={tableActions}
      />
      {modalState.open ? (
        <Modal
          {...modalState}
          handleClose={handleCloseModal}
          submitCallback={modalSubmitCallback}
        />
      ) : null}
    </Page>
  )
}

export default OrganizerPartnerIndex
