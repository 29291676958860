import { useMutation } from 'react-query'

import { createRegistry } from 'services/auth/registryService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, registry } = mutationData

  if (mutationMethod === 'POST') return createRegistry(registry)

  return null
}

const useRegistryService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const registryMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    registryMutation,
  }
}

export default useRegistryService
