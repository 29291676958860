import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createAffiliation,
  getAffiliationById,
  getAffiliationCertificates,
  getAllAffiliations,
} from 'services/affiliations/affiliations'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, searchParams, workerId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getAllAffiliations')
    return getAllAffiliations({ searchParams })

  if (currentQueryKey === 'getAffiliationById')
    return getAffiliationById(workerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, affiliationData } = mutationData

  if (mutationMethod === 'PUT')
    return createAffiliation(workerId, affiliationData)

  if (mutationMethod === 'GET') return getAffiliationCertificates({ workerId })

  return null
}

const useAffiliationsService = ({
  serviceParams = { queryKey: 'getAllAffiliations' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restAffiliationsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const affiliationsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    affiliationsQuery: {
      data,
      ...restAffiliationsResponse,
    },
    affiliationsMutation,
  }
}

export default useAffiliationsService
