import cloneDeep from 'lodash/cloneDeep'

import { removeSuffix } from 'utils/general'

const getFormattedSubscription = (data = {}) => {
  const subscription = cloneDeep(data)
  subscription.plan_category_with_suffix = subscription.plan_category
  subscription.plan_category = removeSuffix(subscription.plan_category, -8)

  return subscription
}

export default getFormattedSubscription
