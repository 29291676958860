import { Box } from '@mui/material'

import AreaField from 'components/Worker/Form/Fields/Personal/Area/Area'
import CostCenterField from 'components/Worker/Form/Fields/Personal/CostCenter/CostCenter'
import LocationField from 'components/Worker/Form/Fields/Personal/Location/Location'
import PositionField from 'components/Worker/Form/Fields/Personal/Position/Position'

const JobPositionSection = () => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
      })}
    >
      <LocationField optional={false} />
      <AreaField optional={false} />
      <PositionField optional={false} />
      <CostCenterField optional={false} />
    </Box>
  )
}

export default JobPositionSection
