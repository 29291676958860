import { useFormikContext } from 'formik'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import useNotifications from 'utils/hooks/useNotifications'

import useSendElectronicPayrolls from '../../useSendElectronicPayrolls'

const SendElectronicPayrollButton = ({ disabledButton, periodId }) => {
  const { values: currentValues, resetForm } = useFormikContext()

  const { showWarningMessage } = useNotifications()
  const endFreeElectronicPayrollModal = useEndFreeModals()
  const { handleSendElectronicPayroll, webSocketState, payrollsToSend } =
    useSendElectronicPayrolls({ invalidateQueries: 'electronicPayrollPeriod' })

  const handleSubmit = () => {
    const { electronicPayrollFormatted: electronicPayrollsSend } = currentValues
    const { electronicPayrollToEmit } = payrollsToSend(electronicPayrollsSend)

    if (electronicPayrollToEmit) {
      handleSendElectronicPayroll(periodId, electronicPayrollsSend)
    } else {
      showWarningMessage(
        'Debes seleccionar por lo menos una persona para hacer la emisión de nómina electrónica'
      )
    }
    resetForm()
  }

  return (
    <Button
      onClick={
        endFreeElectronicPayrollModal.isNotValidCompany
          ? () =>
              endFreeElectronicPayrollModal.openEndFreeElectronicPayrollModal()
          : handleSubmit
      }
      disabled={disabledButton || webSocketState === 'inProgress'}
      startIcon={<Icon name="document" basic />}
    >
      Emitir
    </Button>
  )
}

export default SendElectronicPayrollButton
