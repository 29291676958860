import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { getAllHistoryActions } from 'services/settings/historyService'

import columnsData from './columnsData'

const SettingsHistory = () => {
  const fetchCompanyLogs = (page, search) => {
    return {
      queryKey: ['companyLogs', page, search],
      queryFunction: () => getAllHistoryActions({ page: page + 1, search }),
      queryOptions: {
        onSuccess: () =>
          trackEvent(integrationEvent.EDIT_SETTINGS, 'History Logs'),
      },
    }
  }

  return (
    <Page header="Historial de acciones">
      <Table columns={columnsData} data={fetchCompanyLogs} />
    </Page>
  )
}

export default SettingsHistory
