import { useMutation } from 'react-query'

import { uploadFiles } from 'services/company/companyService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, files } = mutationData

  if (mutationMethod === 'PATCH') return uploadFiles(files)

  return null
}

const useCompanyFilesService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const companyFilesMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    companyFilesMutation,
  }
}

export default useCompanyFilesService
