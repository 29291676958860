import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import { formatCurrency } from 'utils/format'

import ExtraChargeFields from './ExtraChargeFields'

const OrganizerPartnerShowPaymentFormSummary = ({ partnerInfo = {} }) => {
  const { errors, values } = useFormikContext()
  const formErrors = Object.keys(errors || {})

  const totalValue = () => {
    const { value, category } = values

    const categoryValue = {
      percentage: partnerInfo.subtotal * (value / 100),
      additional_value: -value,
      discount_value: +value,
    }

    const discountValue = categoryValue[category] || 0

    return formatCurrency(partnerInfo.subtotal - (discountValue || 0))
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '47rem',
      }}
    >
      {partnerInfo.base ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: theme.spacing(1),
          })}
        >
          <Typography variant="lead1">Base:</Typography>
          <Typography>{formatCurrency(partnerInfo.base)}</Typography>
        </Box>
      ) : null}

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: theme.spacing(1),
        })}
      >
        <Typography variant="lead1">Descuento Base:</Typography>
        <Typography>
          {partnerInfo.base_discount_type === 'value'
            ? formatCurrency(partnerInfo.base_discount)
            : `${partnerInfo.base_discount} %`}
        </Typography>
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: theme.spacing(1),
          alignItems: 'center',
          ...(formErrors && {
            alignItems: 'flex-start',
          }),
        })}
      >
        <Typography
          variant="lead1"
          sx={(theme) => ({
            marginTop: formErrors ? theme.spacing(1.5) : null,
          })}
        >
          Descuento/Recargo sobre la factura:
        </Typography>
        <ExtraChargeFields />
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: theme.spacing(1),
        })}
      >
        <Typography variant="lead1">Total:</Typography>
        <Typography variant="lead1">{totalValue() || 0}</Typography>
      </Box>
    </Box>
  )
}

export default OrganizerPartnerShowPaymentFormSummary
