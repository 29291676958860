import { useCallback } from 'react'

import { Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import SubscriptionCardsModal from 'components/Subscription/Index/Plan/SubscriptionCardsModal'
import useWorker from 'components/Worker/useWorker'

import { isOrganizer } from 'utils/auth'

import PartnerChildPremiumExpiredModal from '../../Subscription/Index/Plan/PartnerSubscription/PartnerChildPremiumExpiredModal'
import useModals from '../ModalsManager/useModals'
import { useUser } from '../UserContext/useUser'

const usePremiumFeature = () => {
  const modals = useModals()
  const { subscription } = useSubscription()
  const { worker } = useWorker({ useCache: true })
  const { isAPartnerChild } = useUser()

  const adminCompanyAccess = subscription?.company_access
  const workerCompanyAccess = worker?.company_access
  const companyAccess = adminCompanyAccess || workerCompanyAccess

  const showPremiumFeatures = ['premium', 'premium_hr', 'pro'].includes(
    companyAccess
  )

  const showHRFeatures = ['premium_hr', 'pro'].includes(companyAccess)

  const openPremiumFeatureModal = useCallback(() => {
    const isPlanBaseAliados =
      subscription.plan?.coded_name.includes('plan_base_aliados')

    if (isAPartnerChild && subscription?.status === 'premium_expired') {
      modals.openModal({
        id: 'partnerChildPremiumExpiredModal',
        content: <PartnerChildPremiumExpiredModal />,
        modalProps: {
          hideFooter: true,
          paperSx: {
            maxWidth: '49rem',
          },
        },
      })

      return
    }

    modals.openModal({
      id: 'subscriptionCardsModal',
      content: <SubscriptionCardsModal isPlanBaseAliados={isPlanBaseAliados} />,
      modalProps: {
        hideFooter: true,
        header: (
          <Typography variant="h3" color="black.main">
            Los superpodereses de tu plan
          </Typography>
        ),
        paperSx: {
          maxWidth: '75rem',
        },
      },
    })
  }, [
    isAPartnerChild,
    modals,
    subscription.plan?.coded_name,
    subscription?.status,
  ])

  return {
    openPremiumFeatureModal,
    showPremiumFeatures: showPremiumFeatures || isOrganizer(),
    showPremiumFeatureIcon: !showPremiumFeatures,
    showHRFeatures,
  }
}

export default usePremiumFeature
