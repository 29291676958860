import * as yup from 'yup'

import { formatDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { areObjectsEqual } from 'utils/general'
import { generateWageBaseSalaryValidationSchema } from 'utils/worker'

yup.setLocale(yupLocaleES)

export const stepsData = [
  {
    title: 'Paso 1',
    description: 'Datos de la persona',
    pageTitle: 'Datos de la persona',
  },
  {
    title: 'Paso 2',
    description: 'Datos de afiliación',
    pageTitle: 'Datos de afiliación',
  },
  {
    title: 'Paso 3',
    description: 'Adjuntar documentos',
    pageTitle: 'Adjuntar documentación',
  },
]

const getDateValue = (value) => {
  if (value && typeof value === 'string') return value

  if (value && value instanceof Date) return formatDate(value)

  return null
}

export const getEntitiesToAffiliate = ({
  worker = {},
  values = {},
  beneficiaries = [],
}) => {
  const isEntityUsedByBeneficiary = (entity) => {
    return beneficiaries.some((beneficiary) =>
      beneficiary.entities_to_affiliate.includes(entity)
    )
  }

  const {
    contract_category: contractCategory,
    document_type: documentType,
    contract_detail: { contributor_subtype: workerContributoSubtype } = {},
  } = worker

  const { contributor_subtype: valuesContributoSubtype } = values

  const contributorSubtype = valuesContributoSubtype || workerContributoSubtype

  const entities = []

  if (
    !['contractor', 'student_decree_055', 'part_time_contract'].includes(
      contractCategory
    )
  ) {
    entities.push({
      value: 'health_provider',
      label: 'EPS',
      disabled: isEntityUsedByBeneficiary('health_provider'),
    })
  }

  if (!['contractor', 'schooling_trainee'].includes(contractCategory)) {
    entities.push({
      value: 'risk_provider',
      label: 'ARL',
    })
  }

  if (
    (!['part_time_contract', 'employee'].includes(contractCategory) ||
      (['part_time_contract', 'employee'].includes(contractCategory) &&
        contributorSubtype === 'no_subtype')) &&
    !['ce_no_pension', 'pe_no_pension', 'pt_no_pension'].includes(
      documentType
    ) &&
    ['employee', 'student_law_789', 'part_time_contract'].includes(
      contractCategory
    )
  ) {
    entities.push({
      value: 'pension_provider',
      label: 'AFP Pensión',
    })
  }

  if (['employee', 'part_time_contract'].includes(contractCategory)) {
    entities.push({
      value: 'compensation_fund',
      label: 'CCF Caja de compensación familiar',
      disabled: isEntityUsedByBeneficiary('compensation_fund'),
    })
  }

  return entities
}

export const getInitialValues = ({
  worker,
  affiliation,
  bankCertificateDocumentFile,
}) => {
  const availableEntities = getEntitiesToAffiliate({ worker })

  const availableValues = new Set(
    availableEntities.map((entity) => entity.value)
  )

  /**
   * Compares selected entities for affiliation with the available entities
   * based on the worker's contract type and document type. This is particularly useful
   * in rare cases where a user begins an affiliation process with certain parameters, such as
   * contract type or document type, but then changes these values, which alters the available entities.
   * The goal is to prevent the completion of an affiliation with incorrect entities for the
   * specific contract and document type.
   */
  const filterSelectedEntities = Array.isArray(
    affiliation.entities_to_affiliate
  )
    ? affiliation.entities_to_affiliate.filter((entity) =>
        availableValues.has(entity)
      )
    : null

  return {
    // Step 1
    worker: worker.name
      ? { id: worker.id, name: `${worker?.name} ${worker?.last_name}` }
      : null,
    document_type: worker.document_type || null,
    id_number: worker.id_number || null,
    birthday: getDateValue(worker.birthday),
    email: worker.email || null,
    department: worker.department_address?.id
      ? worker.department_address
      : null,
    city: worker.city_address?.id ? worker.city_address : null,
    address: worker.address || null,

    // Step 2
    position: worker.position?.id ? worker.position : null,
    base_salary: worker.base_salary || null,
    initial_day: getDateValue(worker.initial_day),
    health_provider: worker.health_provider?.id ? worker.health_provider : null,
    pension_provider: worker.pension_provider?.id
      ? worker.pension_provider
      : null,
    severance_provider: worker.severance_provider?.id
      ? worker.severance_provider
      : null,
    work_center: worker.work_center?.id ? worker.work_center : null,
    location: worker.location?.id ? worker.location : null,
    contract_category: worker.contract_category || null,
    contributor_subtype: worker?.contract_detail?.contributor_subtype || null,
    wage_category: worker.wage_category || null,
    end_day: worker.end_day || null,
    entities_to_affiliate: filterSelectedEntities,

    // Step 3
    identification_document: affiliation.identification_document_file || null,
    contract_document: affiliation.contract_document_file || null,
    bank_certificate_document: bankCertificateDocumentFile || null,
    apprenticeship_contract_document:
      affiliation.apprenticeship_contract || null,
    comment: affiliation.comment || '',
    beneficiaries: affiliation.beneficiaries || [],
  }
}

export const getDirtyWorker = (values, currentStep) => {
  if (currentStep === 0) {
    return {
      document_type: values.document_type,
      id_number: values.id_number,
      birthday:
        typeof values.birthday === 'string'
          ? values.birthday
          : formatDate(values.birthday),
      email: values.email,
      department_address_id: values.department.id,
      city_address_id: values.city.id,
      address: values.address,
    }
  }

  if (currentStep === 1) {
    return {
      position_id: values.position.id,
      base_salary: values.base_salary,
      initial_day:
        typeof values.initial_day === 'string'
          ? values.initial_day
          : formatDate(values.initial_day),
      health_provider_id: [
        'contractor',
        'student_decree_055',
        'part_time_contract',
      ].includes(values.contract_category)
        ? null
        : values.health_provider.id,
      pension_provider_id:
        !['employee', 'student_law_789', 'part_time_contract'].includes(
          values.contract_category
        ) ||
        ['pe_no_pension', 'ce_no_pension', 'pt_no_pension'].includes(
          values.document_type
        ) ||
        values.contributor_subtype !== 'no_subtype'
          ? null
          : values.pension_provider.id,
      severance_provider_id:
        !['employee', 'part_time_contract'].includes(
          values.contract_category
        ) || values.wage_category === 'integral_salary'
          ? null
          : values.severance_provider.id,
      work_center_id: values.work_center?.id,
      location_id: values.location.id,
      contributor_subtype: values.contributor_subtype,
    }
  }

  return null
}

const baseSalaryValidationSchema = generateWageBaseSalaryValidationSchema()

const baseFileValidationSchema = yup
  .mixed()
  .test('isPdf', 'El documento debe ser un PDF.', (value) => {
    if (value instanceof File) {
      return value.type === 'application/pdf'
    }
    return value ? typeof value === 'string' : true
  })
  .test(
    'fileSize',
    'El archivo que intentas subir no tiene contenido o se encuentra dañado.',
    (value) => {
      if (!value) return true

      if (value instanceof File) {
        return value.size > 0
      }

      return true
    }
  )

export const validationSchema = {
  0: yup.object({
    worker: yup.object().nullable().required(),
    document_type: yup.string().nullable().required(),
    id_number: yup.string().trim().nullable().required(),
    birthday: yup.date().nullable().required(),
    email: yup.string().nullable(),
    department: yup.object().nullable().required(),
    city: yup.object().nullable().required(),
    address: yup.string().nullable().required(),
  }),
  1: yup.object({
    position: yup.object().nullable().required(),
    base_salary: baseSalaryValidationSchema,
    initial_day: yup.date().nullable().required(),
    contributor_subtype: yup
      .string()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory &&
          ['employee', 'part_time_contract'].includes(contractCategory),
        then: yup.string().nullable().required(),
      }),
    health_provider: yup
      .object()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory &&
          !['contractor', 'student_decree_055', 'part_time_contract'].includes(
            contractCategory
          ),
        then: yup.object().nullable().required(),
      }),
    pension_provider: yup
      .object()
      .nullable()
      .when(['contract_category', 'document_type', 'contributor_subtype'], {
        is: (contractCategory, documentType, contributorSubtype) =>
          ['ce_no_pension', 'pe_no_pension', 'pt_no_pension'].includes(
            documentType
          ) || contributorSubtype !== 'no_subtype'
            ? false
            : contractCategory &&
              ['employee', 'student_law_789', 'part_time_contract'].includes(
                contractCategory
              ),
        then: yup.object().nullable().required(),
      }),
    severance_provider: yup
      .object()
      .nullable()
      .when(['contract_category', 'wage_category'], {
        is: (contractCategory, wageCategory) =>
          contractCategory &&
          wageCategory &&
          ['employee', 'part_time_contract'].includes(contractCategory) &&
          wageCategory !== 'integral_salary',
        then: yup.object().nullable().required(),
      }),
    work_center: yup
      .object()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory &&
          contractCategory !== 'contractor' &&
          contractCategory !== 'schooling_trainee',
        then: yup.object().nullable().required(),
      }),
    location: yup.object().nullable().required(),
    entities_to_affiliate: yup
      .array()
      .nullable()
      .required('Debes seleccionar al menos una entidad a afiliar.'),
  }),
  2: yup.object({
    identification_document: baseFileValidationSchema.required(),
    contract_document: yup
      .mixed()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          [
            'schooling_trainee',
            'productive_trainee',
            'intern',
            'student_decree_055',
            'student_law_789',
          ].includes(contractCategory),
        then: baseFileValidationSchema.required(),
      }),
    bank_certificate_document: baseFileValidationSchema,
    comment: yup.string().nullable(),
  }),
}

export const getOptions = (workers) => {
  return workers.map((worker) => ({
    id: worker.id,
    name: `${worker.name} ${worker.last_name}`,
  }))
}

export const formHasChanged = (currentValues, initialValues, currentStep) => {
  let selectedInitialValues = {}
  let selectedCurrentValues = {}

  if (currentStep === 0) {
    selectedCurrentValues = {
      document_type: currentValues.document_type,
      id_number: currentValues.id_number,
      birthday: currentValues.birthday,
      email: currentValues.email,
      department: currentValues.department,
      city: currentValues.city,
      address: currentValues.address,
    }

    selectedInitialValues = {
      document_type: initialValues.document_type,
      id_number: initialValues.id_number,
      birthday: initialValues.birthday,
      email: initialValues.email,
      department: initialValues.department,
      city: initialValues.city,
      address: initialValues.address,
    }
  }

  if (currentStep === 1) {
    selectedCurrentValues = {
      position: currentValues.position,
      base_salary: currentValues.base_salary,
      initial_day: currentValues.initial_day,
      health_provider: currentValues.health_provider,
      pension_provider: currentValues.pension_provider,
      severance_provider: currentValues.severance_provider,
      work_center: currentValues.work_center,
      location: currentValues.location,
      contributor_subtype: currentValues.contributor_subtype,
    }

    selectedInitialValues = {
      ...selectedInitialValues,
      position: initialValues.position,
      base_salary: initialValues.base_salary,
      initial_day: initialValues.initial_day,
      health_provider: initialValues.health_provider,
      pension_provider: initialValues.pension_provider,
      severance_provider: initialValues.severance_provider,
      work_center: initialValues.work_center,
      location: initialValues.location,
      contributor_subtype: initialValues.contributor_subtype,
    }
  }

  if (currentStep === 2) {
    selectedCurrentValues = {
      identification_document: currentValues.identification_document,
      contract_document: currentValues.contract_document,
      bank_certificate_document: currentValues.bank_certificate_document,
      comment: currentValues.comment,
    }

    selectedInitialValues = {
      identification_document: initialValues.identification_document,
      contract_document: initialValues.contract_document,
      bank_certificate_document: initialValues.bank_certificate_document,
      comment: initialValues.comment,
    }
  }

  return !areObjectsEqual(selectedCurrentValues, selectedInitialValues)
}

export const getAffiliationEntitiesAlert = ({ worker, beneficiaries }) => {
  const healthProviderInUse = beneficiaries.some((beneficiary) =>
    beneficiary.entities_to_affiliate.includes('health_provider')
  )

  const compensationFundInUse = beneficiaries.some((beneficiary) =>
    beneficiary.entities_to_affiliate.includes('compensation_fund')
  )

  if (!healthProviderInUse && !compensationFundInUse) {
    return { showAffiliationEntitiesAlert: false, alertText: '' }
  }

  const bothEntitiesInUse = healthProviderInUse && compensationFundInUse

  const entityText = bothEntitiesInUse
    ? 'EPS y Caja de compensación'
    : healthProviderInUse
      ? 'la opción EPS'
      : 'la opción Caja de compensación'

  const alertText = `No puedes desmarcar ${entityText}, porque has creado beneficiarios para ${worker.name} con afiliaciones a ${
    bothEntitiesInUse ? 'estas entidades' : 'esta entidad'
  }. Si requieres ajustar la opción seleccionada, modifica o elimina los beneficiarios que contengan la entidad que deseas descartar.`

  return {
    showAffiliationEntitiesAlert: true,
    alertText,
  }
}

export const getEntitiesWithMissingCredentialsKey = (affililiationEntities) => {
  return affililiationEntities?.reduce((result, entity) => {
    result[entity] = {
      missing_credentials: false,
    }
    return result
  }, {})
}
