import { useMutation, useQuery } from 'react-query'

import {
  createSession,
  deleteSession,
  googleService,
} from 'services/auth/sessionService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, credentials } = mutationData

  if (mutationMethod === 'DELETE') return deleteSession()

  if (mutationMethod === 'POST') return createSession(credentials)

  if (mutationMethod === 'POST_GOOGLE') return googleService(credentials)

  return null
}

const useSessionService = ({
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  useQuery('deleteSession', deleteSession, {
    enabled: false,
    ...queryOptions,
  })

  const sessionMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error, null, { redirect: false })
      },
      ...mutationOptions,
    }
  )

  return {
    sessionMutation,
  }
}

export default useSessionService
