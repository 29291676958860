/**
 * Check if enviroment is development
 */
import isEqual from 'lodash/isEqual'

export const isDev = process.env.NODE_ENV === 'development'
export const isProduction = process.env.NODE_ENV === 'production'
export const isBuildForCI = process.env.REACT_APP_CI_BUILD === 'true'

/**
 * Check if enviroment is testing with Cypress
 */
export const isTest = !!window.Cypress

/**
 * Check if and object has not properties.
 * @param {Object} obj
 */
export function isObjectEmpty(obj) {
  return obj && Object.keys(obj).length === 0
}

/**
 * Wait for miliseconds.
 *
 * @param {Number} ms Milliseconds
 * @returns {Promise}
 */
export function wait(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms))
}
/**
 * Fill an array with values, at least one.
 *
 * @param {Array} array
 * @param {Number} max An integer representing the maximum number of ekements with which the array will be filled.
 * @param {*} value The value with which the array will be filled.
 */
export function fillArray(array, max, value) {
  let valueCopy
  if (typeof value === 'object') {
    if (value instanceof Array) valueCopy = [...value]
    else valueCopy = { ...value }
  }

  if (array.length < max) {
    for (let i = array.length; i < max; i += 1) {
      array.push(valueCopy)
    }
  } else {
    array.push(valueCopy)
  }
}

export function capitalize(string) {
  return typeof string === 'string'
    ? string[0]?.toUpperCase() + string?.slice(1)?.toLowerCase()
    : ''
}

export function removeAccents(string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Object version of Array.map()
 */
export function objectMap(object, callback) {
  return Object.keys(object).reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = callback(key)
    return result
  }, {})
}

/**
 * Compare 2 objects deeply and returns true is they are equal, if not it returns false
 * @param {*} firstObject
 * @param {*} secondObject
 * @returns {Boolean}
 */
export const areObjectsEqual = (firstObject, secondObject) =>
  isEqual(firstObject, secondObject)

/**
 * Converts a file to base64
 * @param {*} file
 * @returns {String}
 */
export const getBase64FromFile = (file) => {
  return new Promise((resolve) => {
    let baseURL = ''
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      baseURL = reader.result
      resolve(baseURL)
    }
  })
}

/**
 * Remove suffix from string
 * @param {string} value string to remove the suffix
 * @param {number} endIndex index to which the suffix should be removed
 * @returns {String} String value without the suffix
 */
export const removeSuffix = (value = '', endIndex) => {
  return value.slice(0, endIndex)
}

export const isValidUrl = (url) => {
  try {
    return new URL(url)
  } catch (e) {
    return false
  }
}

export const getFileNameFromUrl = (search) => {
  const params = new URLSearchParams(search)

  const fileName = params
    .get('response-content-disposition')
    ?.split(';')?.[1]
    ?.trim()
    ?.split('"')?.[1]

  return fileName && fileName?.length > 0 ? fileName : null
}

export function generateRandomNumber() {
  const currentTimestamp = new Date().getTime()
  const randomNumber = Math.floor(
    1000000000 + Math.random() * 9000000000
  ).toString()

  return +randomNumber + currentTimestamp
}
