import { FieldArray, useFormikContext } from 'formik'

import { Box } from '@mui/material'

import AddButton from 'components/UI/Button/AddButton'
import FormField from 'components/UI/Formik/FormField/Index'

import { generateEmptyAreaOrPosition } from 'utils/company'

import DeleteItemButton from './DeleteItemButton'

const CompanyFormFieldsPositions = () => {
  const form = useFormikContext()

  const { values } = form

  const { positions } = values

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(auto, 1fr))',
        },
      })}
    >
      <FieldArray name="positions">
        {(arrayHelpers) => {
          return (
            <>
              {positions.map((_, index) => {
                const key = `cargo_${index}`
                return (
                  <Box
                    key={key}
                    sx={(theme) => ({
                      display: 'grid',
                      gridTemplateColumns:
                        'minmax(9.5625rem, 24.375rem) min-content',
                      columnGap: theme.spacing(1),
                      alignItems: 'end',
                      alignSelf: 'center',
                    })}
                  >
                    <FormField
                      name={`positions[${index}].name`}
                      label={`Cargo ${index + 1}`}
                    />
                    <DeleteItemButton
                      name="positions"
                      values={values}
                      arrayHelpers={arrayHelpers}
                      index={index}
                      emptyValue={generateEmptyAreaOrPosition()}
                      style={{
                        marginLeft: 8,
                        marginBottom: 4, // to align with form control margin dense
                      }}
                    />
                  </Box>
                )
              })}
              <AddButton
                onClick={() => arrayHelpers.push(generateEmptyAreaOrPosition())}
                sx={(theme) => ({
                  gridColumnEnd: 'span 2',
                  justifyContent: 'flex-start',
                  marginTop: theme.spacing(1),
                })}
              >
                Añadir cargo
              </AddButton>
            </>
          )
        }}
      </FieldArray>
    </Box>
  )
}

export default CompanyFormFieldsPositions
