import http, { getApiURL } from '../httpService'

export const getImportingFile = (periodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/import_payroll_novelties`,
  })

  return http.getV2(url)
}

export const uploadImportFile = (periodId, data) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/import_payroll_novelties`,
    searchParams: { async: true },
  })

  return http.postV2(url, data)
}

export const updateBulkNovelties = (periodId, data) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/import_payroll_novelties`,
    searchParams: { async: true },
  })

  return http.patchV2(url, data)
}

export default {
  getImportingFile,
  uploadImportFile,
  updateBulkNovelties,
}
