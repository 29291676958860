import { useRef } from 'react'
import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'

import { getCompanyId } from 'utils/company'
import useElectronicSignatureService from 'utils/hooks/electronicSignature/electronicSignature'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

export const useDocumentDeletion = () => {
  const queryClient = useQueryClient()
  const { listenInBackground } = useUser()
  const { handleError } = useErrorHandler()
  const socketConnections = useRef([])
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()
  const companyId = getCompanyId()
  const { electronicSignatureMutation } = useElectronicSignatureService({
    queryOptions: { enabled: false },
  })

  const electronicSignatureSocketHandler = async (
    websocketResult,
    connectionRef
  ) => {
    socketConnections.current.push(connectionRef)

    if (websocketResult) {
      if (websocketResult.errors) {
        handleError(websocketResult.errors)
      } else {
        await queryClient.invalidateQueries('getCompanyOnboardings')
        showSuccessMessage(websocketResult.success)
      }
      connectionRef.off('value')
    }
  }

  const handleConfirmFileDeletion = (workerId, handleClose) => {
    confirm({
      title: 'Eliminar archivo',
      description:
        'Estás a punto de eliminar este documento. ¿Deseas continuar con esta acción?',
      okText: 'Eliminar',
      confirmCheckbox: true,
      onOk: async () => {
        try {
          await electronicSignatureMutation.mutateAsync(
            {
              mutationMethod: 'DELETE',
              workerId,
            },
            {
              onSuccess: async () => {
                await listenInBackground(
                  `workers/${workerId}/electronic_signatures`,
                  electronicSignatureSocketHandler
                )
                await queryClient.invalidateQueries([
                  'getCompanyOnboardings',
                  companyId,
                ])
                handleClose()
              },
            }
          )
        } catch (error) {
          handleError(error)
        }
      },
    })
  }

  return handleConfirmFileDeletion
}

export const moreThanOnePending = (signers) =>
  signers?.filter((signer) => signer?.status === 'Pendiente')?.length > 1
