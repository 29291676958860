import { parseISO } from 'date-fns'
import { klona } from 'klona'

import http, { getApiURL } from '../httpService'

export function formatSocialBenefits(socialBenefitsPeriod) {
  const socialBenefitPeriodClone = klona(socialBenefitsPeriod)

  if (socialBenefitPeriodClone.initial_day)
    socialBenefitPeriodClone.initial_day = parseISO(
      socialBenefitPeriodClone.initial_day
    )
  if (socialBenefitPeriodClone.end_day)
    socialBenefitPeriodClone.end_day = parseISO(
      socialBenefitPeriodClone.end_day
    )
  if (socialBenefitPeriodClone.payment_limit_day)
    socialBenefitPeriodClone.payment_limit_day = parseISO(
      socialBenefitPeriodClone.payment_limit_day
    )
  if (socialBenefitPeriodClone.period_initial_day)
    socialBenefitPeriodClone.period_initial_day = parseISO(
      socialBenefitPeriodClone.period_initial_day
    )
  if (socialBenefitPeriodClone.period_end_day)
    socialBenefitPeriodClone.period_end_day = parseISO(
      socialBenefitPeriodClone.period_end_day
    )

  return {
    social_benefit_period: socialBenefitPeriodClone,
  }
}

export const getSocialBenefits = async (
  periodId,
  socialBenefitPeriodId,
  searchParams = {}
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits`,
    searchParams,
  })

  const { data, filters } = await http.getV2(url)

  const socialBenefitsCopy = data?.map((socialBenefitItem) => {
    const socialBenefitItemCopy = socialBenefitItem
    return {
      ...socialBenefitItemCopy,
      initial_item_value: socialBenefitItemCopy.item_value,
      // this is required to do when the api returns item_value with a value
      paid_value:
        socialBenefitItemCopy.paid_value - socialBenefitItemCopy.item_value,
    }
  })

  return {
    data: socialBenefitsCopy,
    filters,
  }
}

export const getSocialBenefitsPeriod = (periodId, socialBenefitsPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitsPeriodId}`,
  })

  return http.getV2(url)
}

export const createSocialBenefitsWorker = ({
  periodId,
  socialBenefitPeriodId,
  socialBenefits,
}) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits`,
  })

  return http.postV2(url, socialBenefits)
}

export const updateSocialBenefitsWorker = ({
  periodId,
  socialBenefitPeriodId,
  socialBenefitId,
  socialBenefits,
}) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits/${socialBenefitId}`,
  })

  return http.putV2(url, socialBenefits)
}

export const updateSocialBenefits = (
  periodId,
  socialBenefitPeriodId,
  socialBenefits
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}`,
  })

  const data = { social_benefits: socialBenefits }

  return http.putV2(url, data)
}

export const deleteSocialBenefits = (
  periodId,
  socialBenefitPeriodId,
  socialBenefits
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits/bulk_destroy`,
  })

  return http.deleteV2(url, socialBenefits)
}

export const recalculateAllSocialBenefits = (
  periodId,
  socialBenefitPeriodId,
  socialBenefits
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits/bulk_update`,
  })

  return http.putV2(url, socialBenefits)
}

export const getSocialBenefitsFile = (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefits_file`,
    searchParams: {
      social_benefit_period_id: socialBenefitPeriodId,
      async: true,
    },
  })

  return http.getV2(url)
}

export const recalculateWorkerSocialBenefits = (
  periodId,
  socialBenefitPeriodId,
  socialBenefitId,
  socialBenefits
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits/${socialBenefitId}`,
  })

  return http.putV2(url, socialBenefits)
}

export default {
  getSocialBenefits,
  getSocialBenefitsPeriod,
  recalculateWorkerSocialBenefits,
  getSocialBenefitsFile,
  deleteSocialBenefits,
  updateSocialBenefits,
  recalculateAllSocialBenefits,
}
