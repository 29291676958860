import http, { getApiURL } from '../httpService'

export const getAlegraAccountingCodes = (category) => {
  const url = getApiURL({
    pathname: '/alegra_accounting',
    searchParams: { category },
  })

  return http.getV2(url)
}

export const updateAlegraAccountingCodes = ({ data, integrationId }) => {
  const url = getApiURL({
    pathname: `/integrations/${integrationId}/integration_codes`,
  })

  return http.putV2(url, data)
}

export default {
  getAlegraAccountingCodes,
  updateAlegraAccountingCodes,
}
