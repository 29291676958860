import * as yup from 'yup'

import { Tooltip } from '@mui/material'

import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({})

export const workCentersColumns = [
  {
    Header: 'Nombre',
    accessor: 'name',
  },
  {
    Header: 'Código',
    accessor: 'code',
  },
  {
    Header: 'Actividad (CIIU)',
    accessor: 'ciiu_code',
    Cell: ({ row }) => row.original.activity_code?.ciiu_code,
    customWidth: '12rem',
  },
  {
    Header: 'Nivel de riesgo',
    accessor: 'risk_type',
    Cell: ({ row }) => row.original.activity_code?.risk_type,
    customWidth: '12.5rem',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    Cell: ({ row }) => (
      <div>
        <Tooltip title={row.original.activity_code?.description} arrow>
          <p>
            {row.original.activity_code?.description.length > 100
              ? `${row.original.activity_code?.description.slice(0, 100)}...`
              : row.original.activity_code?.description}
          </p>
        </Tooltip>
      </div>
    ),
  },
]

export const getActions = (
  handleNewWorkCenter,
  handleShowHistory,
  handleDeleteWorkCenter,
  handleEditWorkCenter
) => {
  return [
    {
      id: 'addWorkCenter',
      tooltip: 'Agregar centro de trabajo',
      isFreeAction: true,
      onClick: () => {
        handleNewWorkCenter()
      },
    },
    (rowData) => ({
      id: 'editWorkCenter',
      tooltip: 'Editar centro de trabajo',
      icon: <Icon name="edit-pencil" basic />,
      onClick: () => {
        handleEditWorkCenter(rowData)
      },
    }),
    (rowData) => ({
      id: 'workCenterHistory',
      tooltip: 'Historial del centro de trabajo',
      icon: <Icon name="document" basic />,
      onClick: () => handleShowHistory(rowData.id),
    }),
    (rowData) => ({
      id: 'deleteWorkCenter',
      tooltip: 'Eliminar centro de trabajo',
      icon: <Icon name="trash" basic />,
      disabled: !rowData.deleteable,
      onClick: () => handleDeleteWorkCenter(rowData.id),
    }),
  ]
}

export const formatActivityData = (activityData) =>
  activityData?.map(({ id, ciiu_code: ciiuCode, description }) => ({
    id,
    name: `${ciiuCode} - ${description}`,
  }))

export const getNoOptionsText = (inputValue, companyWorkCentersQuery) => {
  if (inputValue === '') {
    return 'Introduce un código o palabra clave para ver y seleccionar opciones disponibles'
  }

  if (
    inputValue !== '' &&
    !companyWorkCentersQuery?.data?.length &&
    !companyWorkCentersQuery?.isLoading
  ) {
    return 'Sin resultados'
  }

  return ''
}
