import Password from 'components/Company/Form/Fields/Credentials/Password'
import UserName from 'components/Company/Form/Fields/Credentials/UserName'
import { affiliationProviders } from 'components/Company/Form/Fields/Credentials/helpers'

const institutionCategoryName = {
  risk_provider: 'Riesgos laborales (ARL)',
  health_provider: 'EPS',
  pension_provider: 'Fondo de pensiones',
  compensation_fund: 'Caja de compensación familiar',
  severance_provider: 'Fondo de cesantías',
}

export const employeeCredentialsColumns = [
  {
    Header: 'Tipo de entidad',
    accessor: 'category',
    Cell: ({ row }) => {
      const category = row?.original?.category
      return category
        ? affiliationProviders.find(({ value }) => category === value).label
        : ''
    },
  },
  {
    Header: 'Nombre entidad',
    accessor: 'name',
  },
  {
    Header: 'Usuario',
    accessor: 'username',
    Cell: ({ value }) => <UserName value={value} />,
    customMinWidth: '150px',
  },
  {
    Header: 'Contraseña',
    accessor: 'password',
    Cell: ({ value }) => <Password value={value} />,
    customMinWidth: '180px',
  },
]

export const companyCredentialsColumn = [
  {
    Header: 'Tipo de entidad',
    accessor: 'institution_category',
    Cell: ({ row }) =>
      institutionCategoryName[row?.original?.institution_category],
  },
  {
    Header: 'Nombre entidad',
    accessor: 'institution_name',
  },
  {
    Header: 'Usuario',
    accessor: 'username',
    Cell: ({ value }) => <UserName value={value} />,
    customMinWidth: '150px',
  },
  {
    Header: 'Contraseña',
    accessor: 'password',
    Cell: ({ value }) => <Password value={value} />,
    customMinWidth: '180px',
  },
]
