import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import {
  Box,
  Collapse,
  Divider,
  Link as MuiLink,
  Typography,
} from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import GoogleLoginButton from 'components/Auth/common/GoogleAuth'
import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import useSessionService from 'utils/hooks/auth/session'
import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import oauthService from 'services/auth/oauthService'

import {
  LOGIN_OAUTH,
  PASSWORD_RECOVERY,
  SUBSCRIPTION_STATUS,
} from 'config/routes'

import messages from 'messages/notification'

import AuthLogo from '../common/AuthLogo'
import LinkToSignup from '../common/LinkToSignup'
import MainContainer from '../common/MainContainer'
import { AlertMessage, getValidationSchema, initialValues } from './helpers'

const SignIn = ({ isFromRecoveryPassword }) => {
  const [otpAuthStep, setOtpAuthStep] = useState(1)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState({
    code: '',
    message: '',
  })

  const location = useLocation()
  const { handleError } = useErrorHandler()
  const { isAuthenticated, logIn } = useUser()
  const { showSuccessMessage } = useNotifications()

  const {
    client_id: clientId,
    subscription,
    redirect,
    unlock_token,
  } = queryString.parse(location.search)

  const fromOAuth = location.pathname === LOGIN_OAUTH

  if (fromOAuth && !clientId) {
    throw new Error('Error en la autenticación OAuth')
  }

  const { sessionMutation } = useSessionService()
  const { usersQuery } = useUsersService({
    serviceParams: {
      queryKey: 'unlockAccount',
      unlock_token,
    },
    queryOptions: {
      enabled: Boolean(unlock_token),
      onSuccess: () => {
        setShowMessage(true)
      },
    },
  })

  const { isLoading } = sessionMutation

  const validateRedirectAfterLogin = () => {
    let pathname = '/'
    if (subscription) {
      pathname = SUBSCRIPTION_STATUS()
      return {
        to: pathname,
        state: { fromLogin: true, toSubscription: subscription },
      }
    }

    if (redirect) {
      pathname = redirect
      return { to: pathname }
    }

    return { to: pathname }
  }

  useEffect(() => {
    if (unlock_token) {
      setMessage((previousMessage) => ({
        ...previousMessage,
        message: usersQuery.data.message,
      }))
    }
  }, [unlock_token, usersQuery.data.message])

  if (isAuthenticated()) return <Navigate {...validateRedirectAfterLogin()} />

  const handleSubmit = async (values, form) => {
    try {
      if (fromOAuth) {
        const { access_token: accessToken, token_type: tokenType } =
          await oauthService.login(values)

        const { redirect_uri: redirectUri } = await oauthService.requestAuth(
          clientId,
          tokenType,
          accessToken
        )

        form.setSubmitting(false)
        window.location.href = redirectUri
      } else {
        await sessionMutation.mutateAsync(
          {
            mutationMethod: 'POST',
            credentials: {
              ...values,
            },
          },
          {
            onSuccess: ({ data, otp_auth: otpAuth }) => {
              if (data) {
                showSuccessMessage(messages.LOGIN_OK)
                logIn(data)
              } else if (otpAuth) {
                setOtpAuthStep(2)
              }
            },
          }
        )
      }
    } catch (error) {
      const { errors } = error

      setMessage(errors[0])
      setShowMessage(true)
      handleError(error, form, { redirect: false })
    }
  }

  const handleCloseAlert = () => {
    setShowMessage(false)
  }

  return (
    <MainContainer isFromRecoveryPassword={isFromRecoveryPassword}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          overflow: 'auto',
          maxHeight: '100vh',
          ...(isFromRecoveryPassword && {
            minHeight: 'unset',
            paddingTop: 0,
          }),
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: theme.spacing(8, 2, 6, 2),
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacing(8, 8, 6, 8),
            },
            [theme.breakpoints.between('laptop', 'lg')]: {
              maxWidth: 'none',
              padding: theme.spacing(4, 6, 6, 6),
            },
            [theme.breakpoints.up('lg')]: {
              padding: theme.spacing(4, 13.5, 6, 13.5),
              maxWidth: 'none',
            },
            [theme.breakpoints.up('desktop')]: {
              padding: theme.spacing(4, 2, 6, 2),
              maxWidth: '30rem',
            },
            ...(isFromRecoveryPassword && {
              padding: `0 !important`,
            }),
          })}
        >
          {!isFromRecoveryPassword ? (
            <>
              <Box>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: theme.spacing(7.75),
                  })}
                >
                  <AuthLogo />
                </Box>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: theme.spacing(6.75),
                  })}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    {otpAuthStep === 1
                      ? `Ingresa a tu cuenta`
                      : 'Ingresa tu código de verificación'}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {otpAuthStep === 1 ? (
                  <GoogleLoginButton action="signin" />
                ) : null}
              </Box>
              <Box
                sx={(theme) => ({
                  width: '100%',
                  marginTop: theme.spacing(3),
                })}
              >
                <Divider
                  sx={(theme) => ({
                    '&::before, ::after': {
                      borderColor: theme.palette.black.light,
                    },
                  })}
                >
                  <Typography variant="h3" color="black.dark" translate="no">
                    O
                  </Typography>
                </Divider>
              </Box>
            </>
          ) : null}
          <Collapse
            in={showMessage}
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
              ...(isFromRecoveryPassword && {
                marginBottom: 0,
              }),
            })}
          >
            <AlertMessage
              message={message}
              otpAuthStep={otpAuthStep}
              handleCloseAlert={handleCloseAlert}
            />
          </Collapse>

          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={getValidationSchema(otpAuthStep)}
          >
            {({ resetForm }) => {
              return (
                <>
                  <Form>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        margin: theme.spacing(1, 0),
                      })}
                    >
                      <Box
                        sx={(theme) => ({
                          display: 'grid',
                          gridTemplateColumns: '1fr',
                          rowGap: theme.spacing(3),
                          marginBottom: theme.spacing(4),
                        })}
                      >
                        {otpAuthStep === 1 ? (
                          <>
                            <FormField
                              type="email"
                              name="email"
                              label="Tu correo electrónico"
                              placeholder="Ingresa tu correo electrónico"
                              autoComplete="email"
                              disabled={isLoading}
                              optional={false}
                            />
                            <PasswordField
                              name="password"
                              label="Tu contraseña"
                              placeholder="Ingresa tu contraseña"
                              autoComplete="password"
                              disabled={isLoading}
                              optional={false}
                            />
                          </>
                        ) : (
                          <FormField
                            type="name"
                            name="otp_code"
                            optional={false}
                            label="Código de autenticación"
                            inputProps={{
                              maxLength: 6,
                              autoComplete: 'one-time-code',
                            }}
                            disabled={isLoading}
                          />
                        )}
                      </Box>

                      <Button
                        type="submit"
                        loading={isLoading}
                        sx={(theme) => ({
                          width: '100%',
                          marginBottom: theme.spacing(4),
                          ...(isFromRecoveryPassword && {
                            marginBottom: 0,
                            width: 'max-content',
                          }),
                        })}
                        data-cy="sign_in_button"
                      >
                        Ingresar
                      </Button>
                    </Box>
                  </Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {!isFromRecoveryPassword ? (
                      <>
                        {otpAuthStep === 1 ? (
                          <Link
                            to={PASSWORD_RECOVERY}
                            fontWeight={600}
                            color="accent4.main"
                          >
                            ¿Olvidaste tu contraseña?
                          </Link>
                        ) : (
                          <MuiLink
                            component="button"
                            onClick={() => {
                              setOtpAuthStep(1)
                              resetForm()
                            }}
                            underline="hover"
                          >
                            Volver
                          </MuiLink>
                        )}
                      </>
                    ) : null}
                    {otpAuthStep === 1 && !isFromRecoveryPassword ? (
                      <Box
                        sx={(theme) => ({
                          marginTop: theme.spacing(2),
                        })}
                      >
                        <LinkToSignup />
                      </Box>
                    ) : null}
                  </Box>
                </>
              )
            }}
          </Formik>
        </Box>
      </Box>
    </MainContainer>
  )
}

export default SignIn
