import { Box, useMediaQuery } from '@mui/material'

import {
  generateArrayOfYears,
  getMonthsFullName,
  monthShortName,
} from 'utils/dateTime'

import { dataFormatted } from '../ElectronicPayrollHistory/helpers'
import DateField from './DateField'

const PayrollHistoryFilter = ({
  setSelectedMonth,
  selectedMonth,
  setSelectedYear,
  selectedYear,
  ...props
}) => {
  const allMonths = getMonthsFullName()
  const downTablet = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const onChangeMonth = (selectedValue) => {
    if (selectedValue === null) {
      setSelectedMonth(null)
    } else {
      setSelectedMonth({
        id: selectedValue.id,
        name: downTablet
          ? selectedValue.name
          : monthShortName[Number(selectedValue.id)],
      })
    }
  }

  const onChangeYear = (selectedValue) => {
    setSelectedYear(selectedValue)
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing(2),
      })}
    >
      <DateField
        id="month"
        sx={(theme) => ({
          '& .MuiInputBase-root': {
            alignContent: 'center',
            backgroundColor: 'transparent',
          },
          '& .MuiOutlinedInput': {
            '&-input': {
              minWidth: '2.5rem !important',
              color: theme.palette.black.main,
            },
            '&-notchedOutline': {
              border: `2px solid ${theme.palette.black.main}`,
              borderRadius: '6.25rem',
            },
          },
          [theme.breakpoints.up('tablet')]: {
            '&-input': {
              width: '12.5rem !important',
              color: theme.palette.black.main,
            },
          },
        })}
        options={dataFormatted(allMonths)}
        value={selectedMonth}
        onChange={onChangeMonth}
        placeholder="Mes"
        {...props}
      />
      <DateField
        id="year"
        sx={(theme) => ({
          '& .MuiInputBase-root': {
            alignContent: 'center',
            backgroundColor: 'transparent',
          },
          '& .MuiOutlinedInput': {
            '&-input': {
              minWidth: '2.5rem !important',
              color: theme.palette.black.main,
            },
            '&-notchedOutline': {
              border: `2px solid ${theme.palette.black.main}`,
              borderRadius: '6.25rem',
            },
          },
          [theme.breakpoints.up('tablet')]: {
            '&-input': {
              width: '12.5rem !important',
              color: theme.palette.black.main,
            },
          },
        })}
        options={generateArrayOfYears(13)}
        value={selectedYear}
        onChange={onChangeYear}
        placeholder="Año"
        {...props}
      />
    </Box>
  )
}

export default PayrollHistoryFilter
