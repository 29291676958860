import { useState } from 'react'

import { Button, ButtonGroup, Typography, useMediaQuery } from '@mui/material'

import { isArusDist } from 'utils/auth'

const SegmentedControl = ({
  segments = [],
  initialSegment = undefined,
  size = 'medium',
  color = 'primary',
  disabled = false,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const [currentSegment, setCurrentSegment] = useState(() => {
    if (initialSegment) return initialSegment
    if (segments.length > 0) {
      return segments[0]?.id
    }

    return null
  })

  const handleClick = (segmentId, onClick) => {
    if (onClick) onClick()

    setCurrentSegment(segmentId)
  }

  return (
    <ButtonGroup
      orientation={isMobile ? 'vertical' : 'horizontal'}
      color={color}
      sx={{
        '& .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)': {
          borderRight: 'none',
        },
        ...(isMobile && {
          '& .MuiButtonGroup-grouped:last-of-type': {
            borderBottomRightRadius: '1rem',
            borderBottomLeftRadius: '1rem',
          },
          '& .MuiButtonGroup-grouped:first-of-type': {
            borderTopRightRadius: '1rem',
            borderTopLeftRadius: '1rem',
          },
        }),
      }}
    >
      {segments.map((segment) => {
        const isCurrent = segment.id === currentSegment

        return (
          <Button
            key={segment.id}
            disableRipple
            disableFocusRipple
            onClick={() => handleClick(segment.id, segment.onClick)}
            variant="contained"
            disabled={disabled || segment.disabled}
            sx={(theme) => ({
              boxShadow: 'none',
              border: `1px solid ${theme.palette.black.light}`,
              backgroundColor: theme.palette.white.main,
              color: theme.palette.black.main,
              height: '3rem',
              '&:focus': {
                boxShadow: 'none',
              },
              '&:hover': {
                borderColor: theme.palette[color].main,
                boxShadow: 'none',
              },
              ...(isCurrent && {
                backgroundColor: theme.palette[color].main,
                borderColor: theme.palette[color].main,
                color: isArusDist()
                  ? theme.palette.white.main
                  : theme.palette.black.main,
              }),
              ...(size === 'small' && {
                height: '2.5rem',
              }),
              ...(size === 'large' && {
                height: '3.5rem',
              }),
              ...((disabled || segment.disabled) &&
                isCurrent && {
                  borderColor: theme.palette.black.light,
                }),
            })}
          >
            {(segment.Component && segment.Component(isCurrent)) || (
              <Typography variant="h6" whiteSpace="nowrap">
                {segment.label}
              </Typography>
            )}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export default SegmentedControl
