import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'

import messages from 'messages/CompanyConfiguration'

const ElectronicPayrollAdvanced = () => {
  const companyId = getCompanyId()
  const queryKey = ['getConfigurationOptions', companyId]
  const { electronicPayrollConfigurationQuery } =
    useElectronicPayrollConfigurationService({
      serviceParams: {
        queryKey,
      },
    })

  return (
    <div>
      <Typography
        variant="h5"
        color="black"
        sx={(theme) => ({ marginBottom: theme.spacing(1) })}
      >
        Configuración de emisión NE
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(3),
        })}
      >
        Acá puedes configurar cómo deseas emitir tu Nómina Electrónica.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(3),
        })}
      >
        <FormField
          name="social_benefits"
          label="Forma de hacer la emisión de las prestaciones sociales en Nómina Electrónica"
          variant="select"
          options={
            electronicPayrollConfigurationQuery.data?.social_benefits || []
          }
          optional={false}
          tooltipContent={messages.electronic_payroll_social_benefit}
        />
        <FormField
          name="holidays"
          label="Forma de hacer la emisión de las vacaciones en Nómina Electrónica"
          variant="select"
          options={electronicPayrollConfigurationQuery.data?.holidays || []}
          optional={false}
          tooltipContent={messages.electronic_payroll_holidays}
        />
        <FormField
          name="consecutive_number"
          label="Siguiente consecutivo de Nómina Electrónica"
          variant="number"
          optional={false}
        />
        <FormField
          name="canceled_consecutive_number"
          label="Siguiente consecutivo de anulación de Nómina Electrónica"
          variant="number"
          optional={false}
        />
      </Box>
    </div>
  )
}

export default ElectronicPayrollAdvanced
