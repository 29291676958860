import { useState } from 'react'

import { Paper, Typography } from '@mui/material'

import { accountingCategoryNames } from 'utils/company'

import CategoryAccordion from './Category'

const SettingsIntegrationsAccountingAccounts = () => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = (category) => (_, isExpanded) => {
    setExpanded(isExpanded ? category : false)
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Cuentas Contables
      </Typography>
      <Typography style={{ marginBottom: '1em' }}>
        En esta sección puedes seleccionar las cuentas de Alegra a las cuales
        quieres que se envíe la información de los conceptos de nómina que
        utiliza Aleluya para liquidar tu nómina.
      </Typography>
      <Paper>
        {Object.keys(accountingCategoryNames).map((category) => {
          return (
            <CategoryAccordion
              key={category}
              category={category}
              expanded={expanded}
              handleChange={handleChange}
            />
          )
        })}
      </Paper>
    </>
  )
}

export default SettingsIntegrationsAccountingAccounts
