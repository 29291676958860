import { useState } from 'react'

const useDownloadIncomesCertificatesModal = () => {
  const [state, setState] = useState({
    open: false,
  })

  const openDownloadIncomesCertificatesModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: true,
    }))
  }

  const closeDownloadIncomesCertificatesModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    openDownloadIncomesCertificatesModal,
    closeDownloadIncomesCertificatesModal,
    downloadIncomesCertificatesModalState: state,
  }
}

export default useDownloadIncomesCertificatesModal
