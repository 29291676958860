import { useFormikContext } from 'formik'

import useInstitutionsService from 'utils/hooks/institutionsService'

import FormField from '../FormField/Index'

/**
 * This component is intended to be used in conjunction with the <DepartmentsList /> component, since when changing the department
 * a query is executed to bring the compensation funds of that department.
 *
 * The prop "department" is to know to which department the compensation funds should be consulted. By default, the compensation funds of the department that has the key department
 * in the context of Formik will be consulted.
 */

const CompensationFundList = ({
  name = '',
  label = '',
  department,
  optional,
}) => {
  const { values } = useFormikContext()

  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey: [
        'getInstitutionsByCategory',
        'compensation_fund',
        department?.name || values?.department?.name,
      ],
      category: 'compensation_fund',
      departmentId: values?.department?.id || department?.id,
    },
    queryOptions: {
      staleTime: Infinity,
      enabled: Boolean(department?.id || values?.department?.id),
    },
  })

  return (
    <FormField
      disabled={!(values?.department || department)}
      variant="autocomplete"
      options={institutionsQuery?.data || []}
      name={name}
      data-cy={name}
      label={label}
      placeholder={
        values?.department || department
          ? 'Selecciona una caja de compensación...'
          : 'Selecciona primero un departamento'
      }
      optional={optional}
    />
  )
}

export default CompensationFundList
