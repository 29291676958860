import FilterButton from 'components/UI/Button/FilterButton'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

export const columnsData = [
  {
    accessor: 'number',
    Header: 'Pago #',
    Cell: ({ row }) => parseInt(row.id, 10) + 1,
    customWidth: '105px',
  },
  {
    Header: 'Fecha',
    accessor: 'date',
    Cell: ({ row }) =>
      `${formatDisplayDate(row.original.date)}${
        row.original.id === 'termination' ? ' (Liquidación)' : ''
      }`,
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    accessor: 'value',
    Header: 'Valor',
    Cell: ({ row }) => formatCurrency(row.original.value),
    alignHeader: 'center',
    alignCell: 'center',
  },
]

const optionsTypeData = [
  { value: '', label: 'Todas las prestaciones' },
  {
    value: 'service_bonus',
    label: 'Prima de servicios',
  },
  {
    value: 'severance_interests',
    label: 'Intereses a las cesantías',
  },
  {
    value: 'severance',
    label: 'Cesantías',
  },
]

export const getColumnsData = (worker, contractId) => {
  return [
    {
      Header: 'Prestación social',
      accessor: 'label',
      Cell: ({ row }) => (
        <Link
          to={routes.WORKER_CONTRACT_SOCIAL_BENEFITS_SHOW(
            worker?.id,
            contractId,
            row.original.id
          )}
          color="black.main"
        >
          {row.original.label}
        </Link>
      ),
    },
    {
      Header: 'Fecha límite de pago',
      accessor: 'payment_limit_day',
      Cell: ({ row }) => formatDisplayDate(row.original.payment_limit_day),
      customWidth: '250px',
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Días trabajados',
      accessor: 'days',
      customWidth: '200px',
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Base',
      accessor: 'base_value',
      Cell: ({ row }) => formatCurrency(row.original.base_value),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
      alignHeader: 'right',
      alignCell: 'right',
    },
    {
      Header: 'Saldo pendiente',
      accessor: 'balance',
      Cell: ({ row }) => formatCurrency(row.original.balance),
      alignHeader: 'right',
      alignCell: 'right',
    },
  ]
}

export const getTableAction = ({ handleChange, filter }) => {
  return [
    {
      id: 'filterSocialBenefitType',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          options={optionsTypeData}
          defaultLabel="Tipo de prestación"
          value={filter}
          onChange={handleChange}
        />
      ),
    },
  ]
}
