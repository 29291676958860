import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, FormHelperText, Typography } from '@mui/material'

import Dropzone from 'components/UI/Dropzone/Dropzone'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useNotifications from 'utils/hooks/useNotifications'
import useDocumentsService from 'utils/hooks/worker/documents'
import { closedQuestionOptions } from 'utils/worker'

import useWorker from '../useWorker'
import { getInitialValues, getValidationSchema } from './helpers'

const NewElementModal = ({ onClose, type, folderId }) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const companyId = getCompanyId()
  const [file, setFile] = useState(null)
  const [fileError, setFileError] = useState(false)
  const { worker } = useWorker({ useCache: true })
  const workerId = worker.id
  const isNewFolder = type === 'folder'

  const MAX_SIZE = 1024 * 1024

  const { documentsMutation } = useDocumentsService({
    queryOptions: {
      enabled: false,
    },
  })

  const onSubmit = (values) => {
    const formData = new FormData()

    if (isNewFolder) {
      formData.append('name', values.name)
      formData.append('worker_folder', values.worker_folder)
      formData.append('worker_access', values.worker_access)

      if (file instanceof File) {
        formData.append('document', file)
      }
    } else {
      if (!(file instanceof File)) {
        setFileError(true)
        return
      }

      formData.append('document', file)
      formData.append('document_name', values.document_name)
    }

    documentsMutation.mutate(
      {
        mutationMethod: isNewFolder ? 'POST_FOLDER' : 'POST_DOCUMENT',
        workerId,
        folderId,
        data: formData,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            isNewFolder ? 'getAllFolders' : 'getAllDocuments',
            companyId,
            workerId,
            folderId,
          ])
          await queryClient.invalidateQueries([
            'getAllFolders',
            companyId,
            worker.id,
          ])
          showSuccessMessage(
            `${
              isNewFolder ? 'La carpeta' : 'El archivo'
            } se ha creado correctamente`
          )
          onClose()
        },
      }
    )
  }

  const handleAdd = (value) => {
    if (value && fileError) {
      setFileError(false)
    }

    setFile(value)
  }

  return (
    <Formik
      initialValues={getInitialValues(type)}
      validationSchema={getValidationSchema(type)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <Modal
            open
            header={isNewFolder ? 'Crear carpeta' : 'Nuevo archivo '}
            okText={isNewFolder ? 'Crear carpeta' : 'Guardar'}
            disableOkButton={
              (file && file.size > MAX_SIZE) ||
              (file && file.size <= 0) ||
              (!isNewFolder && !file && fileError)
            }
            onCloseModal={onClose}
            onCancel={onClose}
            onOk={handleSubmit}
            isLoading={documentsMutation.isLoading}
            paperSx={{
              maxWidth: '45.5rem',
              width: '100%',
            }}
            adornment="spring"
          >
            <Form>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                })}
              >
                <FormField
                  name={isNewFolder ? 'name' : 'document_name'}
                  label={`Nombre ${
                    isNewFolder ? 'de la carpeta' : 'del archivo'
                  }`}
                  margin="none"
                  optional={false}
                />
                {isNewFolder ? (
                  <>
                    <FormField
                      name="worker_folder"
                      label="¿Esta carpeta se creará en el perfil de todas las personas?"
                      variant="radio-group"
                      row
                      options={closedQuestionOptions}
                    />
                    <FormField
                      name="worker_access"
                      label="¿La persona tendrá acceso a esta carpeta desde su perfil?"
                      variant="radio-group"
                      row
                      options={closedQuestionOptions}
                    />
                  </>
                ) : null}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant="lead2"
                    sx={(theme) => ({
                      marginBottom: theme.spacing(1),
                    })}
                  >
                    {isNewFolder
                      ? 'Agregar archivo a esta carpeta (opcional)'
                      : 'Archivo'}
                  </Typography>
                  <Dropzone
                    accept="application/pdf"
                    handleAdd={handleAdd}
                    value={file?.name}
                    fileType="application/pdf"
                  />
                  {file && file.size > MAX_SIZE ? (
                    <FormHelperText error>
                      El tamaño del archivo no debe superar 1 MB.
                    </FormHelperText>
                  ) : null}
                  {file && file.size <= 0 ? (
                    <FormHelperText error>
                      ¡No pudimos cargar el documento! El archivo que intentas
                      subir no tiene contenido o se encuentra dañado. Intenta
                      con uno nuevo.
                    </FormHelperText>
                  ) : null}
                  {!isNewFolder && !file && fileError ? (
                    <FormHelperText error>
                      Debes ingresar este campo.
                    </FormHelperText>
                  ) : null}
                </Box>
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default NewElementModal
