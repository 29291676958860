import * as yup from 'yup'

import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'
import CompensationFundList from 'components/UI/Formik/CommonFields/CompensationFundList'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  name: yup.string().trim().min(2).max(40).required(),
  department: yup.object().nullable().required(),
  city: yup.object().nullable().required(),
  address: yup.string().min(5).max(100).nullable().notRequired(),
  compensation_fund: yup.object().nullable().required(),
  electronic_payroll_prefix: yup
    .string()
    .matches(/^[a-zA-Z\\s]*$/, 'El prefijo debe contener solo letras')
    .max(9)
    .nullable()
    .notRequired(),
})

export function getColumnsData() {
  return [
    {
      Header: 'Nombre',
      accessor: 'name',
      name: 'name',
      placeholder: 'Nombre',
      editable: true,
      customWidth: 150,
    },
    {
      Header: 'Departamento',
      accessor: 'department',
      editable: true,
      Cell: ({ row }) => row.original.department.name,
      Edit: () => (
        <DepartmentsList
          name="department"
          optional={false}
          resetCompensationFundQuery
        />
      ),
      customWidth: 180,
    },
    {
      Header: 'Ciudad',
      accessor: 'city',
      editable: true,
      Cell: ({ row }) => row.original.city.name,
      Edit: () => <CitiesList name="city" optional={false} />,
      customWidth: 180,
    },
    {
      Header: 'Dirección',
      placeholder: 'Dirección',
      accessor: 'address',
      name: 'address',
      editable: true,
      emptyValue: '-',
    },
    {
      Header: 'C. de Compensación',
      accessor: 'compensation_fund',
      editable: true,
      Cell: ({ row }) => row.original.compensation_fund.name,
      Edit: () => (
        <CompensationFundList name="compensation_fund" optional={false} />
      ),
      customWidth: 180,
    },
    {
      Header: 'Prefijo NE',
      placeholder: 'Prefijo nómina electrónica',
      accessor: 'electronic_payroll_prefix',
      name: 'electronic_payroll_prefix',
      editable: true,
      emptyValue: '-',
    },
  ]
}

export const formatLocation = (location) => {
  let locationFormatted = {}

  locationFormatted = {
    name: location?.name,
    address: location?.address,
    city_id: location?.city.id,
    electronic_payroll_prefix: location?.electronic_payroll_prefix,
    work_center_code: location?.work_center_code,
    compensation_fund_id: location?.compensation_fund.id,
    id: location?.id,
  }

  return locationFormatted
}
