import http, { getApiURL } from '../httpService'

export const getNotificationsToken = () => {
  const url = getApiURL({
    pathname: '/inbox_provider',
  })

  return http.postV2(url)
}

export default {
  getNotificationsToken,
}
