import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Typography } from '@mui/material'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { documentTypesOptions } from 'utils/company'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { getValidationSchema, initialValues } from './helpers'

const NewCompanyModal = ({ state, handleClose }) => {
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const { open, partners, distributors } = state
  const queryClient = useQueryClient()
  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })

  const isDistributorNominapp =
    distributors?.length === 1 && distributors[0].name === 'Nominapp'
  const hasDistributors =
    !!distributors && distributors.length > 0 && !isDistributorNominapp
  const hasPartners = !!partners && partners.length > 0

  const handleSubmit = (values, form) => {
    const { distributor, partner, ...restValues } = values
    const formattedValues = {
      ...restValues,
    }

    if (hasDistributors) {
      formattedValues.distributor_id = distributor?.id
    }

    if (hasPartners) {
      formattedValues.partner_id = partner?.id
    }

    companyMutation.mutate(
      {
        mutationMethod: 'POST',
        company: { ...formattedValues, role: 'accountant' },
      },
      {
        onError: (error) => {
          handleError(error, form)
        },
        onSuccess: () => {
          handleClose()
          queryClient.invalidateQueries(['getAllCompanies'])
          showSuccessMessage('Se ha creado la empresa')
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={getValidationSchema(hasDistributors, hasPartners)}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit, resetForm }) => {
        return (
          <Modal
            okText="Crear empresa"
            header="Crear nueva empresa"
            loadingText="Creando empresa"
            open={open}
            onOk={onSubmit}
            onCancel={() => {
              resetForm()
              handleClose()
            }}
            isLoading={companyMutation.isLoading}
          >
            <Typography gutterBottom>
              Con esta opción puedes crear una nueva empresa para vincularte
              como su contador.
            </Typography>
            <Form>
              <FormField name="name" label="Nombre de la empresa" />
              <DocumentTypeField options={documentTypesOptions} />
              <CompanyIdentificationNumberField />
              <FormField
                name="email"
                label="Correo electrónico de la empresa"
              />
              {hasPartners ? (
                <FormField
                  name="partner"
                  label="Partner"
                  variant="autocomplete"
                  options={partners}
                  tooltipTextContent="Tu usuario está asociado a varias cuentas de partner (tales como contadores o outsourcers). Acá podrás seleccionar a cuál partner deseas agregar esta nueva empresa."
                />
              ) : null}
              {hasDistributors ? (
                <FormField
                  name="distributor"
                  label="Distribuidor"
                  variant="autocomplete"
                  options={distributors}
                />
              ) : null}
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default NewCompanyModal
