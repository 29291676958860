import { Navigate } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'

/**
 * View relacted to a organizer so the user needs to be admin role to access this routes
 */
const View = ({ children }) => {
  const { isOrganizer } = useUser()

  return isOrganizer ? children : <Navigate to="/" />
}

export default View
