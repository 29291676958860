import { useLayoutEffect, useReducer } from 'react'

import { Box, Typography } from '@mui/material'

import OrganizationEmail from 'components/UI/OrganizationEmail'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'

import ElectronicPayrollContent from './Content'

const initialState = {
  isActivated: false,
  showWelcomeBanner: true,
  showSuccessBanner: false,
  firstAttempt: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'show_only_config_form':
      return {
        ...state,
        showWelcomeBanner: false,
        showSuccessBanner: false,
        isActivated: true,
      }

    case 'show_form_from_banner':
      return {
        ...state,
        showWelcomeBanner: false,
      }

    case 'show_success_banner':
      return {
        ...state,
        showSuccessBanner: true,
      }

    case 'first_config_attempt':
      return {
        ...state,
        firstAttempt: true,
      }

    default:
      throw new Error(`action ${action.type} doesn't exist`)
  }
}

const ElectronicPayrollSettings = () => {
  const companyId = getCompanyId()
  const { electronicPayrollConfigurationQuery } =
    useElectronicPayrollConfigurationService({
      serviceParams: {
        queryKey: ['electronicPayrollStatus', companyId],
      },
    })
  const [state, dispatch] = useReducer(reducer, initialState)
  const { isActivated } = state

  const isElectronicPayrollAuthorized =
    electronicPayrollConfigurationQuery.data?.government_enabled_status ===
    'authorized'

  const isElectronicPayrollActive =
    electronicPayrollConfigurationQuery.data?.active

  useLayoutEffect(() => {
    if (
      isElectronicPayrollAuthorized &&
      isElectronicPayrollActive &&
      !isActivated
    ) {
      dispatch({
        type: 'show_only_config_form',
      })
    }
  }, [isActivated, isElectronicPayrollActive, isElectronicPayrollAuthorized])

  return (
    <Page
      documentTitle="Configurar Nómina Electrónica"
      isLoading={electronicPayrollConfigurationQuery.isLoading}
      header={
        <Box
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          <Typography variant="lead1">
            Configuración de Nómina Electrónica
          </Typography>
          <Typography
            color="black.dark"
            sx={(theme) => ({
              margin: theme.spacing(1, 0, -3),
            })}
          >
            Sonríe. En este paso a paso, debes mostrarle a la DIAN que el
            software que elegiste para emitir Nómina Electrónica funciona ¡al
            pelo!
          </Typography>
        </Box>
      }
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <ElectronicPayrollContent state={state} dispatch={dispatch} />
        <Typography
          sx={(theme) => ({
            color: theme.palette.black.dark,
            textAlign: 'center',
            marginTop: theme.spacing(4),
          })}
          variant="body2"
        >
          Si necesitas que te rescatemos, escríbenos a <OrganizationEmail />.
        </Typography>
      </Box>
    </Page>
  )
}

export default ElectronicPayrollSettings
