import { Navigate, useLocation } from 'react-router-dom'

import { Box, Grid, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { isFreeCompany } from 'utils/auth'

import TimeStopImg from 'assets/images/errors/stopwatch.png'

import { DASHBOARD } from 'config/routes'

import PageGetPremium from '../Premium/PageGetPremium'
import PageGetPremiumHR from '../Premium/PageGetPremiumHR'
import { usePremiumActions } from '../Premium/helpers'

function getMessagesByErrorCode(errorCode, hasToPayNewWorkers) {
  let title
  let description
  let buttonText

  if (['0004', '0005'].includes(errorCode)) {
    title = 'Lo sentimos. Tu plan actual no te permite añadir más personas.'
    description = 'Para cambiar de plan o si tienes alguna duda'
    buttonText = hasToPayNewWorkers
      ? 'Agregar más personas a tu plan'
      : 'Mejora tu plan'
  } else {
    title = 'Lo sentimos. Tu periodo de prueba ha terminado.'
    description = `Para continuar liquidando la nómina más rápida de tu vida,
      puedes pagar haciendo clic en este botón. Si tienes alguna duda o ya habías realizado tu pago`
    buttonText = 'Recuperar superpoderes'
  }

  return { title, description, buttonText }
}

const PaymentRequired = () => {
  const { subscription } = useSubscription()
  const location = useLocation()
  const { handleGetPremium, handlePayExtraWorkers } = usePremiumActions()

  if (!location?.state?.error) {
    return <Navigate to={DASHBOARD} replace />
  }

  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  const hasToPayNewWorkers =
    !subscription?.additional_workers_info?.add_workers && !isAFreeCompany

  const error = (location?.state && location?.state?.error) || {}

  const { code } = error

  const { title, description, buttonText } = getMessagesByErrorCode(
    code,
    hasToPayNewWorkers
  )

  const handleClick = () =>
    hasToPayNewWorkers ? handlePayExtraWorkers() : handleGetPremium()

  if (['0006', '0007', '0008', '0009'].includes(code)) {
    return <PageGetPremium code={code} />
  }

  if (code === '0010') {
    return <PageGetPremiumHR />
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box width="80%" m={3} textAlign="center">
        <img
          src={TimeStopImg}
          alt="Time's Up"
          style={{ maxWidth: 250, marginBottom: 60 }}
        />
        <Typography variant="h3" style={{ marginBottom: 15 }}>
          {title}
        </Typography>
        <Typography>
          {description}, escríbenos por el chat o a <OrganizationEmail /> y te
          ayudaremos con gusto en lo que necesites. 🙂
        </Typography>
        <Button
          color="primary"
          onClick={handleClick}
          style={{ margin: 30, padding: '10px 40px' }}
        >
          {buttonText}
        </Button>
      </Box>
    </Grid>
  )
}

export default PaymentRequired
