import useLocalStorage from '@rehooks/local-storage'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Avatar, Box, Button } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import DropdownButton from 'components/UI/Button/Dropdown'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'
import ProfileImg from 'components/UI/ProfileImg'

import { getBase64FromFile } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { profileImageRequirements } from 'utils/worker'

const ProfilePicture = ({ worker }) => {
  const [workerPictureUrl, setWorkerPictureUrl] = useState(worker.picture)

  const confirm = useConfirm()
  const { isWorker } = useUser()
  const queryClient = useQueryClient()
  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })
  const { showSuccessMessage, showErrorMessage } = useNotifications()

  const setPicture = useLocalStorage('user_picture')[1]
  const { supported_formats, max_size, min_size } = profileImageRequirements

  const updatePicture = async (file = null) => {
    workerMutation.mutate(
      {
        mutationMethod: 'PATCH',
        mutationKey: isWorker ? 'workerSelfUpdate' : null,
        worker: {
          picture:
            file instanceof File
              ? {
                  image: await getBase64FromFile(file),
                  name: file.name,
                }
              : {},
        },
        workerId: worker.id,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries(['getWorkerById', worker.id])
          queryClient.invalidateQueries(['getWorkerProfile', worker.id])
          showSuccessMessage(
            `La imagen de perfil ha sido ${file ? 'actualizada' : 'eliminada'}.`
          )
          if (isWorker) setPicture(data.picture)
        },
      }
    )
  }

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'profilePictureMenu',
  })

  const closePopup = () => popupState.close()

  const fileInputRef = useRef(null)
  const stopEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onFileAdded = (event) => {
    stopEvent(event)

    const file = event.target.files[0]

    if (file instanceof File) {
      if (!supported_formats.list.includes(file.type)) {
        showErrorMessage(supported_formats.message)
        return
      }

      if (file.size === min_size.value) {
        showErrorMessage(min_size.message)
        return
      }

      if (file.size > max_size.value) {
        showErrorMessage(max_size.message)
        return
      }
      setWorkerPictureUrl(URL.createObjectURL(file))
      updatePicture(file)
    }
  }

  const handleDeleteFile = () => {
    confirm({
      type: 'warning',
      title: '¿Estás seguro de que quieres eliminar esta foto?',
      description: 'Esta acción no se puede deshacer.',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      onOk: () => {
        setWorkerPictureUrl(null)
        updatePicture()
      },
    })
  }

  const openFileDialog = () => {
    fileInputRef.current.click()
  }

  const getDropdownOptions = () => {
    const options = [
      {
        id: 'update_picture',
        icon: <Icon name="refresh" basic />,
        iconPosition: 'left',
        name: `${workerPictureUrl ? 'Actualizar' : 'Cargar'} foto de perfil`,
        onClick: () => {
          openFileDialog()
          closePopup()
        },
      },
    ]

    if (workerPictureUrl) {
      options.push({
        id: 'delete_picture',
        name: 'Borrar foto de perfil',
        icon: <Icon name="trash" basic />,
        iconPosition: 'left',
        onClick: () => {
          handleDeleteFile()
          closePopup()
        },
      })
    }

    return options
  }

  return (
    <Avatar
      alt="avatar"
      sx={(theme) => ({
        position: 'relative',
        width: 'fit-content',
        height: '100%',
        overflow: 'visible',
        padding: workerPictureUrl ? 0 : theme.spacing(3.5),
        backgroundColor: workerPictureUrl
          ? 'transparent'
          : theme.palette.primary.dark,
        marginTop: theme.spacing(1.2),
        marginBottom: theme.spacing(1),
        objectFit: 'contain !important',
        [theme.breakpoints.up('desktop')]: {
          marginTop: theme.spacing(1.8),
        },
      })}
    >
      <>
        <Box
          component="input"
          type="file"
          ref={fileInputRef}
          accept={['image/jpeg', 'image/png']}
          onChange={onFileAdded}
          data-cy="picture-input"
          sx={{
            display: 'none',
          }}
        />
        <DropdownButton
          popupState={popupState}
          menuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          Icon={
            <Button
              sx={(theme) => ({
                minWidth: '2.1rem',
                height: '2.1rem',
                width: '2.1rem',
                position: 'absolute',
                top: 5,
                right: 5,
                backgroundColor: theme.palette.primary.main,
                borderRadius: '50%',
                padding: theme.spacing(0.5),
              })}
              {...bindTrigger(popupState)}
            >
              <Icon
                name="edit-pencil"
                basic
                sx={(theme) => ({
                  color: theme.palette.black.main,
                  fontSize: '1.6rem',
                })}
              />
            </Button>
          }
          options={getDropdownOptions()}
        />
      </>

      {workerPictureUrl ? (
        <Box
          component="img"
          src={workerPictureUrl}
          sx={{
            objectFit: 'cover',
            height: '9rem',
            width: '9rem',
            borderRadius: '50%',
          }}
        />
      ) : (
        <ProfileImg height={90} width={90} color="black" />
      )}
    </Avatar>
  )
}

export default ProfilePicture
