import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  deleteUser,
  getUnlockAccount,
  getUserToken,
  regeneratePassword,
  resendEmailUnlockAccount,
  resendUserEmail,
  restorePassword,
  sendWhatsappCode,
  updateProfile,
  updateUserState,
  updateUserToken,
} from 'services/settings/userService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, unlock_token } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'userToken') return getUserToken()

  if (currentQueryKey === 'unlockAccount')
    return getUnlockAccount({ unlock_token })

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, mutationKey, user, email, userId, userPhone } =
    mutationData

  if (mutationMethod === 'POST' && mutationKey === 'restorePassword')
    return restorePassword(email)

  if (mutationMethod === 'POST' && mutationKey === 'resendUserEmail')
    return resendUserEmail(user)

  if (mutationMethod === 'POST' && mutationKey === 'whatsappCode')
    return sendWhatsappCode(userId, userPhone)

  if (mutationMethod === 'POST' && mutationKey === 'unlockAccount')
    return resendEmailUnlockAccount({ user })

  if (mutationMethod === 'PATCH' && mutationKey === 'updateUserToken')
    return updateUserToken()

  if (mutationMethod === 'PATCH' && mutationKey === 'updateProfile')
    return updateProfile(user)

  if (mutationMethod === 'PATCH' && mutationKey === 'updateUserInfo')
    return updateUserState(user)

  if (mutationMethod === 'PUT') return regeneratePassword(user)

  if (mutationMethod === 'DELETE') return deleteUser(user)

  return null
}

const useUsersService = ({
  serviceParams = { queryKey: 'userToken' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { token, data } = {}, ...restUserResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  const usersMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    usersQuery: {
      data: token ?? { ...data },
      ...restUserResponse,
    },
    usersMutation,
  }
}

export default useUsersService
