import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { Button, ListItemText, Menu, MenuItem } from '@mui/material'

import Icon from 'components/UI/Icon'

import { formatPeriodDateRange } from 'utils/dateTime'

import {
  PERIOD_HISTORY,
  PERIOD_PAY_ELECTRONIC_PAYROLL,
  PERIOD_PAY_PREVIOUS_PAYROLL,
  PERIOD_PREVIOUS_GENERATED_PAYROLL,
} from 'config/routes'

const DropDownPeriod = ({
  period,
  electronicPayroll = false,
  generatedPayroll = false,
}) => {
  const navigate = useNavigate()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'periodsMenu',
  })

  const {
    related_periods: relatedPeriods,
    related_ep_periods: relatedElectronicPeriods,
  } = period || {}

  const periodFormat = (initialDay, endDay, item) => {
    const copy = item ? 'Periodo' : 'Nómina del periodo'
    return `${copy}: ${formatPeriodDateRange(initialDay, endDay)}`
  }

  const labelCurrentPeriod = 'Historial de periodos'

  const handleNavigateHistory = () =>
    navigate(
      PERIOD_HISTORY(electronicPayroll ? '?tab=electronic_payroll_history' : '')
    )

  const checkElectronicPayroll = (id) =>
    electronicPayroll
      ? PERIOD_PAY_ELECTRONIC_PAYROLL(id)
      : PERIOD_PAY_PREVIOUS_PAYROLL(id)

  const handleNavigatePreviousPeriod = (id) =>
    generatedPayroll
      ? PERIOD_PREVIOUS_GENERATED_PAYROLL(id)
      : checkElectronicPayroll(id)

  const periodsItems = electronicPayroll
    ? relatedElectronicPeriods
    : relatedPeriods

  return (
    <>
      <Button
        variant="text"
        size="large"
        endIcon={<Icon name="arrow-down" basic />}
        {...bindTrigger(popupState)}
      >
        {labelCurrentPeriod}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {periodsItems?.length > 0
          ? periodsItems?.map((item) => (
              <MenuItem
                onClick={() => popupState.close()}
                component={RouterLink}
                key={`period_id_${item?.id}`}
                to={handleNavigatePreviousPeriod(item?.id)}
              >
                <ListItemText>
                  {periodFormat(item?.initial_day, item?.end_day, true)}
                </ListItemText>
              </MenuItem>
            ))
          : null}
        <MenuItem onClick={handleNavigateHistory}>
          <ListItemText>Ver historial</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default DropDownPeriod
