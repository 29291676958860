import { useContext } from 'react'

import { Button } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'

import { ElectronicPeriodContext } from '../ElectronicPayroll'

const ShowSummary = () => {
  const { setShowSummary } = useContext(ElectronicPeriodContext) || {}

  const handleShowSummary = () => setShowSummary(true)

  const endFreeElectronicPayrollModal = useEndFreeModals()

  return (
    <Button
      variant="outlined"
      onClick={
        endFreeElectronicPayrollModal.isNotValidCompany
          ? () =>
              endFreeElectronicPayrollModal.openEndFreeElectronicPayrollModal()
          : handleShowSummary
      }
    >
      Ver resumen
    </Button>
  )
}

export default ShowSummary
