import http, { getApiURL } from '../httpService'

export const getUsers = ({ search, page }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/user_accesses',
    searchParams: { search, page },
  })

  return http.getV2(url)
}

export const updateUser = (user) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/user_accesses/${user.id}`,
  })

  return http.patchV2(url, user)
}

export default {
  updateUser,
  getUsers,
}
