import { isAfter, isEqual, isValid, parseISO } from 'date-fns'
import * as yup from 'yup'

import { Chip, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { formatDate, formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

const categoryName = {
  percentage: 'Porcentaje',
  additional_value: 'Valor adicional',
  discount_value: 'Descuento',
  unique_payment: 'Valor a pagar único',
  recurring_payment: 'Valor a pagar recurrente',
}

const subscriptionType = {
  month: 'Mensual',
  year: 'Anual',
}

const fieldOptions = {
  categoryOptions: [
    { label: 'Porcentaje', value: 'percentage' },
    { label: 'Valor adicional', value: 'additional_value' },
    { label: 'Descuento', value: 'discount_value' },
    { label: 'Valor a pagar único', value: 'unique_payment' },
    { label: 'Valor a pagar recurrente', value: 'recurring_payment' },
  ],
  partnerCategoryOptions: [
    { label: 'Porcentaje', value: 'percentage' },
    { label: 'Valor adicional', value: 'additional_value' },
    { label: 'Descuento', value: 'discount_value' },
  ],
  subscriptionTypeOptions: [
    { label: 'Mensual', value: 'month' },
    { label: 'Anual', value: 'year' },
  ],
  getPlanOptions: (plans = []) => {
    const planOptions = plans.map((plan) => ({
      label: plan.name,
      value: plan.id,
    }))

    planOptions.push({ label: 'Todos', value: 'all_plans' })

    return planOptions
  },
}

const {
  categoryOptions,
  partnerCategoryOptions,
  getPlanOptions,
  subscriptionTypeOptions,
} = fieldOptions

yup.setLocale(yupLocaleES)

const currentDate = formatDate(new Date())

export const validationSchema = yup.object({
  category: yup.string().nullable().required(),
  value: yup.string().when('category', {
    is: 'percentage',
    then: yup
      .string()
      .test(
        'is-between-0-100',
        'El valor debe estar entre 0 y 100',
        (value) => +value > 0 && +value <= 100
      )
      .required(),
    otherwise: yup
      .string()
      .test(
        'is-greater-than-0',
        'El valor debe ser mayor que 0',
        (value) => +value > 0
      )
      .required(),
  }),
  comment: yup
    .string()
    .nullable()
    .required()
    .min(5, 'El comentario debe tener al menos 5 caracteres'),
  plan_id: yup.string().nullable().required(),
  adjustment_type: yup.string().nullable().required(),
  adjustment_months: yup.string().when(['category'], {
    is: (category) =>
      ['unique_payment', 'recurring_payment'].includes(category),
    then: yup.string().nullable().required(),
    otherwise: yup.string().nullable(),
  }),
  payment_limit_date: yup
    .string()
    .nullable()
    .when('active', {
      is: true,
      then: yup
        .string()
        .test(
          'payment-limit-date-greater-or-equal-than-current-date',
          'La fecha límite de pago no puede ser anterior a la fecha actual',
          (paymentLimitDate) => {
            if (!paymentLimitDate) {
              return true
            }

            const isValidDate =
              isValid(parseISO(paymentLimitDate)) &&
              (isAfter(parseISO(paymentLimitDate), parseISO(currentDate)) ||
                isEqual(parseISO(paymentLimitDate), parseISO(currentDate)))

            return isValidDate
          }
        ),
      otherwise: yup.string().nullable(),
    }),
  active: yup.boolean().nullable().required(),
})

export const getColumnsData = (plans, monthsAllowed, isPartner) => {
  const columnsData = [
    {
      Header: 'Tipo',
      accessor: 'category',
      editable: true,
      Cell: ({ row }) => categoryName[row.original.category] || null,
      Edit: () => (
        <FormField
          name="category"
          variant="select"
          options={isPartner ? partnerCategoryOptions : categoryOptions}
          optional={false}
        />
      ),
      customWidth: '10rem',
    },
    {
      Header: 'Valor',
      accessor: 'value',
      editable: true,
      Cell: ({ row }) =>
        row.original.category === 'percentage'
          ? `${row.original.value.slice(0, -2)}%` || null
          : formatCurrency(row.original.value) || null,
      Edit: ({ rowValues }) => (
        <FormField
          name="value"
          variant="currency"
          align="left"
          startAdornment={rowValues.category === 'percentage' ? '%' : '$'}
          thousandSeparator=""
          decimalSeparator="."
        />
      ),
      customWidth: '9rem',
    },
    {
      Header: 'Plan',
      accessor: 'plan_name',
      editable: true,
      Cell: ({ row }) =>
        row.original.plan_name === 'all_plans'
          ? 'Todos'
          : row.original.plan_name || null,
      Edit: () => (
        <FormField
          name="plan_id"
          variant="select"
          optional={false}
          options={getPlanOptions(plans)}
          disabled={isPartner}
        />
      ),
      customWidth: '9rem',
    },
    {
      Header: 'Suscripción',
      accessor: 'adjustment_type',
      editable: true,
      Cell: ({ row }) => subscriptionType[row.original.adjustment_type] || null,
      Edit: () => (
        <FormField
          name="adjustment_type"
          variant="select"
          options={subscriptionTypeOptions}
          optional={false}
          disabled={isPartner}
        />
      ),
      customWidth: '9rem',
    },
    {
      Header: 'Fecha límite pago',
      accessor: 'payment_limit_date',
      editable: true,
      Cell: ({ row }) =>
        formatDisplayDateString(row.original.payment_limit_date) || null,
      Edit: () => (
        <FormField
          name="payment_limit_date"
          variant="datepicker"
          minDate={currentDate}
        />
      ),
      customWidth: '13.5rem',
      emptyValue: '-',
    },
    {
      Header: 'Vigencia',
      accessor: 'adjustment_months',
      editable: true,
      Cell: ({ row }) => {
        if (!row.original.adjustment_months) return '-'
        return `${row.original.adjustment_months} ${
          row.original.adjustment_months > 1 ? 'meses' : 'mes'
        }`
      },
      Edit: () => (
        <FormField
          name="adjustment_months"
          variant="number"
          align="left"
          isAllowed={monthsAllowed}
        />
      ),
      customWidth: '7rem',
      emptyValue: '-',
    },
    {
      Header: 'Email',
      accessor: 'user_email',
      editable: false,
      Cell: ({ row }) =>
        (
          <Typography
            title={row.original.user_email}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {row.original.user_email}
          </Typography>
        ) || null,
      customWidth: '12rem',
    },
    {
      Header: 'Creado',
      accessor: 'created_at',
      Cell: ({ row }) => formatDisplayDateString(row.original.created_at),
      editable: false,
      customWidth: '9rem',
    },
    {
      Header: 'Comentario',
      accessor: 'comment',
      field: 'textfield',
      name: 'comment',
      Cell: ({ row }) =>
        (
          <Typography
            title={row.original.comment}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {row.original.comment}
          </Typography>
        ) || null,
      editable: true,
      customWidth: '10rem',
    },
    {
      Header: 'Estado',
      accessor: 'active',
      Cell: ({ row }) =>
        (
          <Chip
            color={row.original.active ? 'success' : 'warning'}
            label={row.original.active ? 'Activo' : 'Inactivo'}
          />
        ) || null,
      editable: true,
      name: 'active',
      field: 'switch',
      customWidth: '8rem',
    },
  ]

  return columnsData
}
