import { useState } from 'react'

const useBulkNoveltiesCommonInfoModal = () => {
  const [state, setState] = useState({
    open: false,
    messageType: '',
    goBackState: {
      to: '',
      bulkUploadResult: {},
    },
  })

  const openBulkNoveltiesCommonInfoModal = (messageType, goBackState) => {
    setState({ open: true, messageType, goBackState })
  }

  const closeBulkNoveltiesCommonInfoModal = () => {
    setState({ open: false })
  }

  return {
    bulkNoveltiesCommonInfoModalState: state,
    openBulkNoveltiesCommonInfoModal,
    closeBulkNoveltiesCommonInfoModal,
  }
}

export default useBulkNoveltiesCommonInfoModal
