import { OutlinedInput, outlinedInputClasses } from '@mui/material'

const TextField = ({ id, field, value, type = 'text', ...props } = {}) => {
  const { inputProps = {}, sx, align, stretchRightButton, ...restProps } = props

  return (
    <OutlinedInput
      id={id}
      {...field}
      value={value ?? ''}
      type={type}
      inputProps={{
        style: {
          textAlign: type === 'number' && align === 'right' ? 'right' : 'left',
        },
        ...inputProps,
      }}
      sx={[
        {
          [`&.${outlinedInputClasses.adornedEnd}`]: {
            ...(stretchRightButton && {
              paddingRight: 0,
            }),
          },
        },
        sx,
      ]}
      {...restProps}
    />
  )
}

export default TextField
