import { useContext } from 'react'

import { useMediaQuery } from '@mui/material'

import { SidebarContext } from './Sidebar'

const useCloseDrawer = () => {
  const { handleDrawerToggle } = useContext(SidebarContext)
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('laptop'))

  return () => {
    if (xsDown) {
      handleDrawerToggle()
    }
  }
}

export default useCloseDrawer
