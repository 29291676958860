import useSubscription from 'components/Subscription/Atoms/useSubscription'

import useModals from '../ModalsManager/useModals'
import EndFreeElectronicPayrollModal from './Atoms/EndFreeElectronicPayrollModal'
import EndFreePayrollModal from './Atoms/EndFreePayrollModal'
import EndFreeSocialBenefitsModal from './Atoms/EndFreeSocialBenefitsModal'
import EndFreeWorkerModal from './Atoms/EndFreeWorkerModal'

const useEndFreeModals = () => {
  const { subscription } = useSubscription()

  const modals = useModals()

  const handleClose = () => {
    modals.closeModal()
  }

  const isNotValidCompany = subscription.company_access === 'free'

  const openEndFreePayrollModal = () => {
    modals.openModal({
      id: 'endFreePayrolModal',
      content: <EndFreePayrollModal handleClose={handleClose} />,
      modalProps: {
        onCancel: handleClose,
        hideFooter: true,
        adornment: 'pattern-bottom',
        patternColor: 'primary.main',
        patternBackgroundColor: 'accent4.dark',
        paperSx: {
          maxWidth: '50rem',
          position: 'relative',
        },
      },
    })
  }

  const openEndFreeElectronicPayrollModal = () => {
    modals.openModal({
      id: 'endFreeElectronicPayrollModal',
      content: <EndFreeElectronicPayrollModal handleClose={handleClose} />,
      modalProps: {
        onCancel: handleClose,
        hideFooter: true,
        adornment: 'pattern-bottom',
        patternColor: 'accent3.main',
        patternBackgroundColor: 'accent4.dark',
        paperSx: {
          maxWidth: '50rem',
          position: 'relative',
        },
      },
    })
  }

  const openEndFreeWorkerModal = () => {
    modals.openModal({
      id: 'endFreeWorkerModal',
      content: <EndFreeWorkerModal handleClose={handleClose} />,
      modalProps: {
        onCancel: handleClose,
        hideFooter: true,
        adornment: 'pattern-bottom',
        patternColor: 'primary.light',
        patternBackgroundColor: 'accent4.dark',
        paperSx: {
          maxWidth: '50rem',
          position: 'relative',
        },
      },
    })
  }

  const openEndFreeSocialBenefitsModal = () => {
    modals.openModal({
      id: 'endFreeSocialBenefitsModal',
      content: <EndFreeSocialBenefitsModal handleClose={handleClose} />,
      modalProps: {
        onCancel: handleClose,
        hideFooter: true,
        adornment: 'pattern-bottom',
        patternColor: 'complementary2.main',
        patternBackgroundColor: 'accent4.dark',
        paperSx: {
          maxWidth: '50rem',
          position: 'relative',
        },
      },
    })
  }

  return {
    openEndFreePayrollModal,
    openEndFreeElectronicPayrollModal,
    openEndFreeWorkerModal,
    openEndFreeSocialBenefitsModal,
    isNotValidCompany,
  }
}

export default useEndFreeModals
