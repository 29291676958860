import { useState } from 'react'

const useCommentsModal = () => {
  const [state, setState] = useState({
    open: false,
    affiliation: {},
    onboardingId: null,
    workerName: null,
    workerId: null,
  })

  const closeCommentsModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openCommentsModal = ({
    affiliation,
    onboardingId,
    workerName,
    workerId,
  }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      affiliation,
      onboardingId,
      workerName,
      workerId,
    }))
  }

  return {
    commentsModalState: state,
    closeCommentsModal,
    openCommentsModal,
  }
}

export default useCommentsModal
