import { Link } from '@mui/material'

import Icon from 'components/UI/Icon'

const alegra = {
  title: 'Alegra',
  description:
    'Ingresa aquí la información de tu cuenta de Alegra para activar la integración con tu cuenta de Aleluya.',
  username: 'Usuario',
  usernameTooltip: 'Tu usuario de acceso en el portal de Alegra',
  token: 'Token',
  tokenTooltip:
    'Para conocerlo ingresa en Alegra a Configuración/Integraciones con otros sistemas/Integración manual - API',
  how_configure_accounts: (
    <>
      Para personalizar las cuentas a las que enviamos la información de alegra,
      entra a &quot;Configuración&quot;{' '}
      <Icon name="gear-settings" fontSize="small" />
      en las acciones.
    </>
  ),
}

const aportesEnLinea = {
  ...alegra,
  title: 'Aportes en Línea',
  subtitle: 'Conecta tu cuenta',
  description: (
    <>
      Para comenzar a pagar tu planilla PILA sin salir de Aleluya, necesitamos
      conectar Aleluya con tu cuenta de Aportes en Línea.
      <br />
      <br />
      Recuerda debes tener configurados los roles de nómina y tesorería dentro
      del portal de Aportes en Línea,{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-conecto-mi-cuenta-de-aportes-en-línea-para-pagar-la-seguridad-social-sin-salir-de-aleluya"
        target="_blank"
        underline="hover"
      >
        haz clic aquí
      </Link>{' '}
      para conocer más sobre como realizar esta integración y configurar tu
      usuario.
    </>
  ),
  usernameTooltip: 'Tu usuario de acceso en el portal de Aportes en línea',
  token: 'Contraseña',
  tokenTooltip: 'Contraseña de acceso al portal ',
  extra_msg: (
    <>
      ¿Sin cuenta en Aportes en Línea?{' '}
      <Link
        href="https://pymes.aportesenlinea.com/Registro"
        target="_blank"
        underline="hover"
      >
        Crea una cuenta aquí
      </Link>
      .
    </>
  ),
}

export default {
  alegra,
  aportes_en_linea: aportesEnLinea,
}
