import FormField from 'components/UI/Formik/FormField/Index'

const CreditCardExpirationDate = ({ name }) => {
  return (
    <FormField
      name={name || 'expiration_date'}
      label="Fecha de vencimiento"
      variant="number"
      placeholder="MM/AA"
      format="##/##"
      isNumericString
      optional={false}
      inputProps={{ autoComplete: 'cc-exp' }}
      style={{ fontFamily: 'Roboto Mono, monospace' }}
    />
  )
}

export default CreditCardExpirationDate
