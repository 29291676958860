import { useRef } from 'react'

import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'

import Icon from 'components/UI/Icon'

const ToolbarSearch = ({
  globalFilter,
  setGlobalFilter,
  searchProps = {},
  isAsyncData = false,
  asyncSearchFilter,
  setAsyncSearchFilter,
  gotoPage,
}) => {
  const { sx } = searchProps
  const ref = useRef(null)

  return (
    <OutlinedInput
      inputRef={ref}
      sx={[
        (theme) => ({
          borderRadius: '7rem',
          width: '100%',
          '& .MuiSvgIcon-root': {
            color: theme.palette.black.light,
          },
          [theme.breakpoints.up('tablet')]: {
            maxWidth: '16rem',
          },
        }),
        sx,
      ]}
      value={!isAsyncData ? globalFilter || '' : undefined}
      defaultValue={isAsyncData ? asyncSearchFilter : undefined}
      onChange={(event) => {
        if (isAsyncData) {
          setAsyncSearchFilter(event.target.value)
          return
        }

        setGlobalFilter(event.target.value || undefined)
      }}
      placeholder="Buscar"
      variant="standard"
      startAdornment={
        <InputAdornment position="start">
          <Icon name="search" basic />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment
          position="end"
          sx={(theme) => ({
            '& .MuiSvgIcon-root': {
              color: `${theme.palette.black.main} !important`,
            },
            ...(((isAsyncData && !asyncSearchFilter) ||
              (!isAsyncData && !globalFilter)) && {
              visibility: 'hidden',
            }),
          })}
        >
          <IconButton
            disabled={
              (isAsyncData && !asyncSearchFilter) ||
              (!isAsyncData && !globalFilter)
            }
            size="small"
            aria-label="Limpiar búsqueda"
            onClick={() => {
              gotoPage?.(0)
              if (isAsyncData) {
                setAsyncSearchFilter('')
                ref.current.value = ''

                return
              }

              setGlobalFilter('')
            }}
            color="primary"
          >
            <Icon name="close-2" basic fontSize="small" />
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default ToolbarSearch
