import http, { getApiURL } from '../httpService'

export const getAllLocation = () => {
  const url = getApiURL({ pathname: '/locations' })

  return http.getV2(url)
}

export const createLocation = (location) => {
  const url = getApiURL({ pathname: '/locations' })

  const reqData = {
    location,
  }

  return http.postV2(url, reqData)
}

export const updateLocation = (location) => {
  const url = getApiURL({ pathname: `/locations/${location.id}` })

  const data = {
    location,
  }

  return http.patchV2(url, data)
}

export const deleteLocation = (locationId) => {
  const url = getApiURL({ pathname: `/locations/${locationId}` })

  return http.deleteV2(url)
}

export default {
  deleteLocation,
  getAllLocation,
  createLocation,
  updateLocation,
}
