import { useState } from 'react'

export default function useReportFileDownloadModal() {
  const [state, setState] = useState({
    open: false,
    reportType: '',
  })

  const openReportFileDownloadModal = ({ reportType }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      reportType,
    }))
  }
  const closeReportFileDownloadModal = () => {
    setState({ open: false })
  }

  return {
    ReportFileDownloadModalState: state,
    openReportFileDownloadModal,
    closeReportFileDownloadModal,
  }
}
