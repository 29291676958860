import {
  Box,
  Button,
  Link,
  Paper,
  Typography,
  alpha,
  useMediaQuery,
} from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import LordIcon from 'components/UI/LordIcon'
import Page from 'components/UI/Page/Page'
import Tooltip from 'components/UI/Tooltip'

import { eyes } from 'utils/emojis'
import { formatCurrency } from 'utils/format'

import Chepito from 'assets/images/views/holisticPayroll/chepito.png'

import { CLAIMS_LINK, claimsSectionCardInfo } from './helpers'

const DisabilitiesClaims = () => {
  const { company } = useUser()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const noveltiesValue = company.novelties_value

  return (
    <Page
      documentTitle="Reclamaciones"
      header={
        <Box sx={{ gridColumn: '1 / -1' }}>
          <Typography
            variant="h1"
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
          >
            Reclamaciones de incapacidades
          </Typography>
          <Typography>
            Te ayudamos a recuperar el dinero de tus incapacidades en tiempo
            récord.
          </Typography>
        </Box>
      }
      rootSx={(theme) => ({
        backgroundColor: alpha(theme.palette.accent2.light, 0.6),
      })}
      grid
    >
      <Box sx={{ gridColumn: '1 / -1' }}>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateRows: 'repeat(3, auto)',
            gap: theme.spacing(6),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr',
              rowGap: theme.spacing(4),
              [theme.breakpoints.up('md')]: {
                gridTemplateColumns: '65% auto',
                columnGap: theme.spacing(4),
              },
            })}
          >
            <Paper
              elevation={2}
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(3),
                borderRadius: '1rem',
                backgroundColor: 'accent2.light',
                columnGap: !isMobile ? theme.spacing(6) : 0,
              })}
            >
              {!isMobile ? (
                <Box
                  component="img"
                  alt="Relájate mientras tienes Aleluya Premium"
                  src={Chepito}
                  width={225}
                  height={225}
                  sx={(theme) => ({
                    zIndex: 1,
                    objectFit: 'contain',
                    margin: theme.spacing(-5),
                  })}
                />
              ) : null}
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(1),
                })}
              >
                <Typography variant="h1">
                  Somos tu Chepito de las reclamaciones
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button component={Link} target="_blank" href={CLAIMS_LINK}>
                    Iniciar reclamación
                  </Button>
                </Box>
              </Box>
            </Paper>
            <Paper
              elevation={2}
              sx={(theme) => ({
                padding: theme.spacing(4.5),
                borderRadius: '1rem',
                backgroundColor: 'primary.main',
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  columnGap: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                  alignItems: 'center',
                })}
              >
                <Icon name="refund" sx={{ fontSize: '2.5rem' }} />
                <Typography sx={{ fontSize: '1.335rem' }}>
                  Dinero a reclamar
                </Typography>
                <Tooltip
                  title={
                    <Typography variant="h5">
                      Ojito a esto <Emoji code={eyes} />:
                    </Typography>
                  }
                  description={
                    <Typography>
                      Este es un valor aproximado de la platica que reclamarías.
                      Lo calculamos al revisar tus novedades registradas en
                      Aleluya en el último año.
                    </Typography>
                  }
                  placement="bottom-start"
                >
                  <Box sx={{ display: 'flex' }}>
                    <Icon
                      basic
                      name="info"
                      fontSize="1rem"
                      sx={{ fontSize: '1.5rem' }}
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Typography variant="h1">
                {formatCurrency(noveltiesValue * 0.75)}
              </Typography>
            </Paper>
          </Box>

          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(8.25),
              alignItems: 'center',
            })}
          >
            <Typography variant="h2">
              Tu platica en tu cuenta en 3 pasos
            </Typography>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                rowGap: theme.spacing(4),
                [theme.breakpoints.up('md')]: {
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  columnGap: theme.spacing(4),
                  rowGap: 0,
                },
              })}
            >
              {claimsSectionCardInfo.map((info) => (
                <Paper
                  key={info.id}
                  className={info.id}
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: theme.spacing(3),
                    borderRadius: '1rem',
                    position: 'relative',
                  })}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: info.numberBackgroundColor,
                      width: '3.5rem',
                      height: '3.5rem',
                      borderRadius: '100%',
                      top: '-10%',
                      left: '8%',
                    }}
                  >
                    <Typography color="white.main" variant="h3">
                      {info.number}
                    </Typography>
                  </Box>
                  <LordIcon name={info.icon} target={info.id} />
                  <Typography
                    textAlign="center"
                    variant="h3"
                    sx={(theme) => ({ marginTop: theme.spacing(2) })}
                  >
                    {info.title}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: theme.spacing(6.5),
              marginTop: theme.spacing(2),
            })}
          >
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              }}
            >
              Mejora tu <b>flujo de caja</b>
              <br /> y olvídate de todo lo operativo de las reclamaciones.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                component={Link}
                target="_blank"
                variant="outlined"
                href={CLAIMS_LINK}
                endIcon={<Icon name="arrow-right" />}
                size="large"
              >
                Iniciar reclamación
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

export default DisabilitiesClaims
