import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useNotifications from 'utils/hooks/useNotifications'

import { SUBSCRIPTION_PAYMENTS } from 'config/routes'

import { getNonRejectedPayments } from './helpers'

const SeePaymentsCard = () => {
  const companyId = getCompanyId()
  const { showSuccessMessage } = useNotifications()
  const { subscriptionMutation, subscriptionQuery } = useSubscriptionService({
    serviceParams: { queryKey: ['getAllPayments', companyId] },
  })

  const handleResendInvoice = (paymentId) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'POST',
        paymentId,
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const nonRejectedPayments = getNonRejectedPayments(subscriptionQuery.data)

  return (
    <Card sx={(theme) => ({ padding: theme.spacing(2), minWidth: '100%' })}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(1),
        })}
      >
        <Typography variant="h5">Pagos recientes</Typography>
        <Button
          to={SUBSCRIPTION_PAYMENTS()}
          variant="text"
          component={RouterLink}
          endIcon={<Icon name="eye" basic />}
        >
          Ver más
        </Button>
      </Box>
      {subscriptionQuery.isLoading ? (
        <LoadingBox />
      ) : (
        <>
          {nonRejectedPayments.map(({ id, date, value, invoice_id }) => {
            return (
              <Box
                key={id}
                sx={(theme) => ({
                  display: 'grid',
                  gridTemplateColumns: '50% 1fr 10%',
                  alignItems: 'center',
                  marginTop: theme.spacing(1),
                  padding: theme.spacing(1, 0),
                  borderBottom: `0.063rem solid ${alpha(
                    theme.palette.black.light,
                    0.4
                  )}`,
                })}
              >
                <Typography>{formatDisplayDateString(date)}</Typography>
                <Typography variant="lead1">{formatCurrency(value)}</Typography>
                {invoice_id ? (
                  <Tooltip title="Reenviar factura">
                    <IconButton
                      onClick={() => handleResendInvoice(id)}
                      disabled={subscriptionMutation.isLoading}
                    >
                      <Icon name="paperplane-send" basic />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Box>
            )
          })}
        </>
      )}
    </Card>
  )
}

export default SeePaymentsCard
