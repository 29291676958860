import { Box } from '@mui/material'

import Icon from 'components/UI/Icon'

const WorkerPicture = ({ avatarId, picture, avatarsSx, pictureSx }) => {
  const avatars = {
    1: <Icon name="avatar-1" sx={avatarsSx} />,
    2: <Icon name="avatar-2" sx={avatarsSx} />,
    3: <Icon name="avatar-3" sx={avatarsSx} />,
    4: <Icon name="avatar-4" sx={avatarsSx} />,
    5: <Icon name="avatar-5" sx={avatarsSx} />,
  }

  if (picture) {
    return (
      <Box
        component="img"
        sx={[
          (theme) => ({
            width: '2.125rem',
            height: '2.125rem',
            borderRadius: '50%',
            margin: theme.spacing(0, 0.625, 0, 0.625),
            transform: 'translateZ(0)',
            backfaceVisibility: 'hidden',
            msTransform: 'translateZ(0)',
            WebkitTransform: 'translateZ(0)',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          }),
          pictureSx,
        ]}
        src={picture}
      />
    )
  }

  return avatars[avatarId]
}

export default WorkerPicture
