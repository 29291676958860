import { isSameMonth, isToday } from '@internationalized/date'
import { useCalendarCell } from '@react-aria/calendar'
import { useFocusRing } from '@react-aria/focus'
import { mergeProps } from '@react-aria/utils'
import { useRef } from 'react'

import { Box } from '@mui/material'

const CalendarCell = ({ state, date, startDate }) => {
  const ref = useRef()
  const {
    cellProps,
    buttonProps,
    isSelected,
    isDisabled,
    formattedDate,
  } = useCalendarCell({ date }, state, ref)
  const dataCy = `${date.year}-${date.month}-${date.day}`
  const isCurrentDate = isToday(date, state.timeZone)
  const isOutsideMonth = !isSameMonth(startDate, date)
  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <Box
      component="td"
      {...cellProps}
      sx={(theme) => ({
        padding: theme.spacing(0.375, 0),
      })}
    >
      <Box
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideMonth}
        data-cy={dataCy}
        sx={(theme) => ({
          width: '2.5rem',
          height: '2.5rem',
          outline: '2px solid transparent',
          outlineOffset: 2,
          borderRadius: 99999,
          ...(isDisabled && {
            color: theme.palette.black.light,
          }),
        })}
      >
        <Box
          sx={(theme) => ({
            position: 'relative',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'default',
            userSelect: 'none',
            color: theme.palette.black.dark,
            ...(isDisabled && {
              color: theme.palette.black.light,
              cursor: 'not-allowed',
            }),
            ...(isSelected && {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.black.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }),
            ...(isFocusVisible && {
              boxShadow: `inset 0 0 0 2px ${theme.palette.white.main}, 0 0 0 2px ${theme.palette.primary.main}`,
            }),
          })}
        >
          {formattedDate}
          <Box
            component="span"
            sx={(theme) => ({
              position: 'absolute',
              display: 'none',
              height: '0.4rem',
              width: '0.4rem',
              backgroundColor: theme.palette.primary.dark,
              borderRadius: '50%',
              bottom: '0.3rem',
              ...(isCurrentDate && {
                display: 'block',
              }),
              ...(isCurrentDate &&
                isSelected && {
                  backgroundColor: theme.palette.primary.dark,
                }),
            })}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CalendarCell
