import { useQuery } from 'react-query'

import { getAllCostCenter } from 'services/company/costCenterService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, searchParams } = serviceParams

  if (queryKey === 'costCenters') return getAllCostCenter(searchParams)

  return null
}

const useCostCenterService = ({
  queryOptions = {},
  serviceParams = { queryKey: 'costCenters' },
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restCostCenter } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  return {
    costCenterQuery: { data, ...restCostCenter },
  }
}

export default useCostCenterService
