import {
  addMonths,
  endOfMonth,
  isAfter,
  isBefore,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns'
import { isEqual } from 'lodash'
import * as yup from 'yup'

import Icon from 'components/UI/Icon'

import { formatDate, formatDisplayDate } from 'utils/dateTime'
import { contributorSubtype } from 'utils/worker'

export const columnsData = [
  {
    Header: '#',
    accessor: 'number',
    Cell: ({ row }) => row.index + 1,
  },
  {
    Header: 'Subtipo de cotizante',
    accessor: 'contributor_subtype',
    Cell: ({ row }) =>
      contributorSubtype[row.original.contributor_subtype] || 'N/A',
  },
  {
    Header: 'Centro de trabajo',
    accessor: 'work_center',
    Cell: ({ row }) => row.original.work_center.name,
  },
  {
    Header: 'Pensión de alto riesgo',
    accessor: 'high_risk_pension',
    Cell: ({ row }) => (row.original.high_risk_pension === true ? 'Si' : 'No'),
  },
  {
    Header: 'Fecha inicial',
    accessor: 'initial_day',
    Cell: ({ row }) =>
      formatDisplayDate(row.original.initial_day) || 'No aplica',
  },
  {
    Header: 'Fecha final',
    accessor: 'end_day',
    Cell: ({ row }) => formatDisplayDate(row.original.end_day) || 'No aplica',
  },
]

export const getActions = ({
  handleOpenCreateContractDetailModal,
  handleDeleteContractDetail,
  handleEditContractDetail,
}) => [
  {
    id: 'new_contract_detail',
    tooltip: 'Nuevo detalle de contrato',
    isFreeAction: true,
    onClick: handleOpenCreateContractDetailModal,
  },
  {
    id: 'edit_contract_detail',
    icon: <Icon name="edit-pencil" basic />,
    tooltip: 'Editar',
    onClick: (rowData) => handleEditContractDetail(rowData),
  },
  (rowData) => ({
    id: 'delete_contract_detail',
    icon: <Icon name="trash" basic />,
    tooltip: 'Eliminar',
    onClick: () => handleDeleteContractDetail(rowData.id),
    buttonProps: {
      disabled: !rowData.deleteable,
    },
  }),
]

export const getInitialValues = ({
  lastContractDetail = {},
  currentContractDetail = {},
  isEditing,
  contractEndDay,
}) => {
  if (isEditing) {
    return {
      initial_day: currentContractDetail?.initial_day || null,
      end_day: currentContractDetail?.end_day || null,
      contributor_subtype:
        currentContractDetail?.contributor_subtype || 'no_subtype',
      work_center: currentContractDetail?.work_center?.id
        ? {
            ...currentContractDetail?.work_center,
            risk_type: currentContractDetail?.work_center?.risk_type_coded_name,
          }
        : null,
      high_risk_pension: currentContractDetail?.high_risk_pension || false,
    }
  }

  const initialValues = {
    initial_day: null,
    contributor_subtype:
      lastContractDetail?.contributor_subtype || 'no_subtype',
    work_center: null,
    high_risk_pension: false,
  }

  if (lastContractDetail?.initial_day) {
    const canStartInCurrentDate =
      isAfter(
        startOfMonth(new Date()),
        parseISO(lastContractDetail?.initial_day)
      ) &&
      !isEqual(
        startOfMonth(new Date()),
        parseISO(lastContractDetail?.initial_day)
      )

    if (canStartInCurrentDate) {
      initialValues.initial_day = formatDate(startOfMonth(new Date()))
    } else if (
      isBefore(
        addMonths(parseISO(lastContractDetail?.initial_day), 1),
        parseISO(contractEndDay)
      )
    ) {
      initialValues.initial_day = formatDate(
        startOfMonth(addMonths(parseISO(lastContractDetail?.initial_day), 1))
      )
    }
  }

  return initialValues
}

export const getValidationSchema = (isLastContractDetail) => {
  return yup.object({
    initial_day: yup.string().nullable().required(),
    end_day: yup.string().when('initial_day', {
      is: isLastContractDetail === false,
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable(),
    }),
    contributor_subtype: yup.string().nullable().required(),
    work_center: yup.object().nullable().required(),
    high_risk_pension: yup
      .boolean()
      .nullable()
      .when(['work_center', 'contributor_subtype'], {
        is: (workCenter, workerContributorSubtype) =>
          Boolean(
            workCenter?.name?.includes('5') &&
              workerContributorSubtype === 'no_subtype'
          ),
        then: yup.boolean().nullable().required(),
      }),
  })
}

export const getInitialDayBoundaryDates = ({
  lastContractDetail = {},
  contractDetail = {},
  previousContractDetail = {},
  nextContractDetail = {},
  contractInitialDay,
  contractEndDay,
  isEditing,
  isLastContractDetail,
}) => {
  let minDate = contractInitialDay
  let maxDate = contractEndDay

  if (!isEditing && lastContractDetail?.initial_day) {
    if (
      !lastContractDetail?.end_day ||
      !isAfter(
        startOfMonth(addMonths(parseISO(lastContractDetail?.initial_day), 1)),
        parseISO(lastContractDetail?.end_day)
      )
    ) {
      minDate = formatDate(
        startOfMonth(addMonths(parseISO(lastContractDetail?.initial_day), 1))
      )
    } else {
      minDate = formatDate(
        startOfMonth(parseISO(lastContractDetail?.initial_day))
      )
    }
    maxDate = contractEndDay
  } else if (isEditing) {
    if (isLastContractDetail) {
      minDate = contractDetail?.initial_day
    } else if (
      previousContractDetail?.initial_day &&
      nextContractDetail?.initial_day
    ) {
      minDate = formatDate(
        startOfMonth(
          addMonths(parseISO(previousContractDetail?.initial_day), 1)
        )
      )
      maxDate = formatDate(
        startOfMonth(subMonths(parseISO(nextContractDetail?.initial_day), 1))
      )
    }
  }

  return { minDate, maxDate }
}

export const getEndDayBoundaryDates = ({
  contractDetail = {},
  previousContractDetail = {},
  nextContractDetail = {},
  contractInitialDay,
  contractEndDay,
  isEditing,
  isLastContractDetail,
}) => {
  let minDate = contractInitialDay
  let maxDate = contractEndDay

  if (isEditing) {
    if (isLastContractDetail) {
      minDate = contractDetail?.initial_day || contractInitialDay

      if (contractEndDay) {
        maxDate = contractEndDay
      }
    } else if (
      previousContractDetail?.initial_day &&
      nextContractDetail?.initial_day
    ) {
      minDate = formatDate(endOfMonth(parseISO(contractDetail?.initial_day)))
      maxDate = formatDate(
        endOfMonth(subMonths(parseISO(nextContractDetail?.initial_day), 1))
      )
    }
  }

  return { minDate, maxDate }
}

export const findAdjacentContractDetail = (contractDetails = [], baseIndex) => {
  let previousContractDetail = {}
  let nextContractDetail = {}

  for (let i = 0; i < contractDetails.length; i += 1) {
    if (i === baseIndex + 1) {
      previousContractDetail = contractDetails[i]
    }

    if (i === baseIndex - 1) {
      nextContractDetail = contractDetails[i]
    }
  }

  return {
    previousContractDetail,
    nextContractDetail,
  }
}
