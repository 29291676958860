import { Typography } from '@mui/material'

import CardEdit from 'components/UI/MaterialUI/Cards/CardEdit'
import Page from 'components/UI/Page/Page'

import { useCompanyService } from 'utils/hooks/company/companyService'

import useGetCardsData from './helpers'

const CompanyShow = () => {
  const { companyQuery } = useCompanyService({
    serviceParams: {
      queryKey: 'companyInformation',
    },
  })

  const cardDetails = useGetCardsData(companyQuery.data?.id)

  const { data: { name: companyName } = {}, isLoading: isQueryLoading } =
    companyQuery || {}

  return (
    <Page
      grid
      documentTitle={`Empresa${companyName ? ` - ${companyName}` : ''}`}
      header={
        <Typography
          variant="h5"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Empresa
          {companyName ? (
            <>
              {' - '}
              <Typography component="span" variant="h5" color="primary">
                {companyName}
              </Typography>
            </>
          ) : null}
        </Typography>
      }
      isLoading={isQueryLoading}
      contentSx={(theme) => ({
        gap: theme.spacing(2),
        gridAutoRows: 'max-content',
        [theme.breakpoints.up('sm')]: {
          gap: theme.spacing(4),
        },
      })}
    >
      {cardDetails.map((card, index) => {
        if (card?.hidden) return null

        return (
          <CardEdit
            key={index.toString()}
            card={card}
            sx={(theme) => ({
              gridColumn: '1 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 'span 3',
              },
            })}
          />
        )
      })}
    </Page>
  )
}

export default CompanyShow
