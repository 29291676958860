import { Form, Formik } from 'formik'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'

import usePartnerPayment from 'utils/hooks/organizer/partner/paymentService'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import PaymentSummary from './Summary'
import PaymentsTable from './Table'
import {
  formatPartnerPaymentData,
  getInitialValues,
  validationSchema,
} from './helpers'

const OrganizerPartnerShowPaymentForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useLocation()
  const partnerId = params?.partnerId
  const { showSuccessMessage } = useNotifications()
  const isEdit = state?.isEdit
  const { partnerPaymentQuery, partnerPaymentMutation } = usePartnerPayment({
    serviceParams: {
      queryKey: ['getPartnerInformation', partnerId],
      partnerId,
    },
  })
  const partnerPaymentData = formatPartnerPaymentData({
    data: partnerPaymentQuery.data || [],
    partner_info: partnerPaymentQuery.partnerInformation || {},
  })

  const redirectToPartner = () => {
    navigate(`${routes.ORGANIZER_PARTNER_SHOW(partnerId)}?tab=payments`)
  }

  const handleSubmit = (values) => {
    partnerPaymentMutation.mutate(
      {
        mutationMethod: 'PUT',
        partnerId,
        company: values,
      },
      {
        onSuccess: () => {
          showSuccessMessage(
            `Pago ${isEdit ? 'actualizado' : 'creado'} con éxito.`
          )
          redirectToPartner()
        },
      }
    )
  }

  const initialValues = getInitialValues(partnerPaymentData?.partner_info)

  return (
    <Page
      documentTitle={`${isEdit ? 'Editar' : 'Crear'} pago - Aliado`}
      header={`${isEdit ? 'Editar' : 'Crear'} pago`}
      isLoading={partnerPaymentQuery.isLoading}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(4),
        })}
      >
        <PaymentsTable paymentsData={partnerPaymentData.data || []} />

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <PaymentSummary partnerInfo={partnerPaymentData.partner_info} />
              </Box>

              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.spacing(4),
                  gap: theme.spacing(2),
                })}
              >
                <Button
                  variant="outlined"
                  disabled={partnerPaymentMutation.isLoading}
                  onClick={redirectToPartner}
                >
                  Cancelar
                </Button>

                <Button
                  type="submit"
                  loading={partnerPaymentMutation.isLoading}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Page>
  )
}

export default OrganizerPartnerShowPaymentForm
