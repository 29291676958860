import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { List } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'

import { getUserRoleSection } from 'utils/auth'

import NavigationOption from './NavigationOption'
import useAdmin from './RoleOptions/admin'
import useOrganizer from './RoleOptions/organizer'
import useWorker from './RoleOptions/worker'

const NavigationOptions = () => {
  const { pathname } = useLocation()
  const { isWorker, isOrganizer } = useUser()
  const adminOptions = useAdmin()
  const organizerOptions = useOrganizer()
  const workerOptions = useWorker()

  const menuOptions = useMemo(() => {
    const role = getUserRoleSection(pathname)

    if (!isOrganizer && !isWorker) return adminOptions
    if (isWorker) return workerOptions
    if (role === 'admin') return adminOptions
    if (role === 'worker') return workerOptions
    return organizerOptions
  }, [
    adminOptions,
    isOrganizer,
    isWorker,
    organizerOptions,
    pathname,
    workerOptions,
  ])

  return (
    <List component="nav" disablePadding>
      {menuOptions.map(({ key, ...item }) => (
        <NavigationOption key={key} {...item} />
      ))}
    </List>
  )
}

export default NavigationOptions
