import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useNotifications from 'utils/hooks/useNotifications'

import messages from 'messages/notification'

import CategoryAccordion from '../CategoryAccordion'
import { institutionCategoriesNames } from '../helpers'

let snackbarId // to store last snackbar showed

const SettingsAccountingSocialSecurityInstitutions = ({ configuration }) => {
  const [ssAcounting, setSSAcounting] = useState(
    configuration?.social_security_accounting
  )
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { closeSnackbar } = useSnackbar()

  const { companyConfigurationQuery, companyConfigurationMutation } =
    useCompanyConfigurationService({
      serviceParams: { queryKey: 'companyConfigurationOptions' },
    })
  const { social_security_accounting: socialSecurityAccounting } =
    companyConfigurationQuery.data || {}

  const handleChange = async ({ target: { value } }) => {
    setSSAcounting(value)

    companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: { social_security_accounting: value },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('companyConfiguration')
          if (snackbarId) closeSnackbar(snackbarId)
          snackbarId = showSuccessMessage(messages.CHANGES_DONE_SUCCESS)
        },
      }
    )
  }

  if (companyConfigurationQuery.isLoading) return <LoadingBox />

  return (
    <div data-cy="social-security-section">
      <Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>
        Instituciones de Seguridad Social
      </Typography>
      <Typography>
        Aquí podrás modificar la cuenta contable de Salud, Pensión, Riesgos y
        Caja de Compensación por institución, los cuales se verán reflejados en
        tu archivo para cargar al software contable.
      </Typography>

      <Alert
        icon={false}
        severity="info"
        sx={(theme) => ({ margin: theme.spacing(2, 0) })}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: '1rem' }}>
            ¿Cómo deseas causar los conceptos de Seguridad Social?
          </FormLabel>
          <RadioGroup
            name="social_security_accounting"
            value={ssAcounting}
            onChange={handleChange}
          >
            {socialSecurityAccounting?.length > 0 &&
              socialSecurityAccounting.map((opt) => (
                <FormControlLabel
                  key={opt.value}
                  label={opt.label}
                  value={opt.value}
                  control={<Radio />}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Alert>

      <Paper>
        {Object.keys(institutionCategoriesNames).map((category) => {
          return (
            <CategoryAccordion
              key={category}
              category={category}
              isInstitution
              ssAcounting={ssAcounting}
            />
          )
        })}
      </Paper>
    </div>
  )
}

export default SettingsAccountingSocialSecurityInstitutions
