import { Box } from '@mui/material'

import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'
import AccountTypeField from 'components/UI/Formik/CommonFields/AccountTypeField'

const AccountFields = ({ accountTypeFieldName, accountNumberFieldName }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(6.25rem, 1fr))',
        columnGap: theme.spacing(4.5),
        rowGap: theme.spacing(2.5),
        [theme.breakpoints.down('tablet')]: {
          gridTemplateColumns: '1fr',
        },
      })}
    >
      <AccountTypeField optional={false} name={accountTypeFieldName} />
      <AccountNumberField optional={false} name={accountNumberFieldName} />
    </Box>
  )
}

export default AccountFields
