import { forwardRef } from 'react'

import { IconButton } from '@mui/material'

import Icon from 'components/UI/Icon'

const ExpandButton = forwardRef(({ expanded, ...props }, ref) => {
  return (
    <IconButton
      ref={ref}
      sx={{
        '& > svg': {
          transform: 'rotate(0deg)',
          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        ...(expanded && {
          '& > svg': {
            transform: 'rotate(180deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        }),
      }}
      size="small"
      color="complementary1"
      {...props}
    >
      <Icon name="arrow-down" basic sx={{ fontSize: '1rem' }} />
    </IconButton>
  )
})

ExpandButton.displayName = 'ExpandButton'

export default ExpandButton
