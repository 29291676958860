import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import useIntegrationService from 'utils/hooks/settings/integrationService'
import useNotifications from 'utils/hooks/useNotifications'

import messages from 'messages/notification'

import IntegrationModal from './Modal/IntegrationModal'
import useIntegrationModal from './Modal/useIntegrationModal'
import IntegrationToken from './Token'
import { ActionButton, columnsData, tableActions } from './helpers'

const SettingsIntegration = () => {
  const queryClient = useQueryClient()
  const { integrationModalState, openIntegrationModal, closeIntegrationModal } =
    useIntegrationModal()
  const navigate = useNavigate()
  const confirm = useConfirm()
  const location = useLocation()
  const { showErrorMessage, showSuccessMessage } = useNotifications()
  const locationState = location.state ? location.state : {}
  const queryKey = ['integrationsCompany', getCompanyId()]
  const { integrationsQuery, integrationsMutation } = useIntegrationService({
    serviceParams: {
      queryKey,
    },
  })
  const { data = [] } = integrationsQuery

  const deleteIntegration = (id) => {
    integrationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        integration: {
          id,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
          showSuccessMessage(messages.INTEGRATION_INACTIVATION)
        },
      }
    )
  }

  useEffect(() => {
    if (locationState.open) {
      openIntegrationModal(
        data.find(({ provider }) => provider === locationState.open)
      )
    }

    if (locationState.error) {
      showErrorMessage(locationState.error.message)
    }
  }, [
    data,
    locationState.error,
    locationState.open,
    openIntegrationModal,
    showErrorMessage,
  ])

  const submitIntegration = () => {
    queryClient.invalidateQueries(queryKey)
  }

  const actions = tableActions(
    openIntegrationModal,
    deleteIntegration,
    navigate,
    confirm
  )

  return (
    <Page header="Integraciones" isLoading={integrationsQuery.isLoading}>
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
        })}
      >
        Conecta Aleluya con tus aplicaciones preferidas de forma simple, para
        sincronizar tu información.
      </Typography>
      <Table
        data={data}
        columns={columnsData}
        actions={actions}
        options={{
          toolbar: false,
          pagination: false,
          customActionsWidth: '190px',
          alignActionsCell: 'center',
        }}
        components={{ ActionButton }}
      />
      <IntegrationToken />
      {integrationModalState.open ? (
        <IntegrationModal
          state={integrationModalState}
          handleClose={closeIntegrationModal}
          submitCallback={submitIntegration}
        />
      ) : null}
    </Page>
  )
}

export default SettingsIntegration
