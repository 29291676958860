import AccountFields from 'components/Subscription/SubscriptionFormFields/Account'
import BankAccountHolderIdentificationNumber from 'components/UI/Formik/CommonFields/BankAccountHolderIdentificationNumber'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'

import { documentTypesOptions } from 'utils/company'

export const AutomaticDebitFields = ({ activePayment }) => {
  return (
    <>
      <DocumentTypeField
        options={documentTypesOptions}
        optional={false}
        name="automatic_debit_form.document_type"
      />
      <BankAccountHolderIdentificationNumber
        disableValidate={activePayment !== 'automatic_debit'}
        name="automatic_debit_form.id_number"
      />
      <BankField provider="druo" name="automatic_debit_form.bank" />
      <AccountFields
        accountTypeFieldName="automatic_debit_form.account_type"
        accountNumberFieldName="automatic_debit_form.account_number"
      />
    </>
  )
}
