import { configureScope } from '@sentry/react'

import Loading from 'components/UI/Loading/Loading'

import auth from 'utils/auth'
import useSessionService from 'utils/hooks/auth/session'

import { LOGIN } from 'config/routes'

const Logout = () => {
  const handleLogout = () => {
    // Remove user from Sentry
    configureScope((scope) => scope.setUser(null))
    window?.Appcues?.reset?.()
    // 'replace' to prevent adding '/logout' to navigation history
    auth.logOut()
    window.location.replace(LOGIN)
  }

  useSessionService({
    queryOptions: {
      enabled: true,
      onSuccess: () => handleLogout(),
      onError: () => handleLogout(),
    },
  })

  return <Loading message="Cerrando sesión..." />
}

export default Logout
