import {
  Pagination,
  PaginationItem,
  Tooltip,
  paginationClasses,
  useMediaQuery,
} from '@mui/material'

import Icon from '../Icon'

const TablePagination = (props) => {
  const { count, page, pageSize, onPageChange } = props
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const numberOfPages = Math.ceil(count / pageSize)

  const handleChange = (_, value) => {
    const newPage = value - 1

    if (newPage !== page) onPageChange(newPage)
  }

  return (
    <Pagination
      page={page + 1}
      count={numberOfPages}
      showFirstButton={mdUp}
      showLastButton={mdUp}
      onChange={handleChange}
      sx={(theme) => ({
        [`&.${paginationClasses.root}`]: {
          marginTop: theme.spacing(4),
        },
      })}
      siblingCount={mdUp ? 1 : 0}
      size={mdUp ? 'medium' : 'small'}
      renderItem={(item) => {
        const isNotPage = !['page', 'end-ellipsis', 'start-ellipsis'].includes(
          item.type
        )

        return isNotPage ? (
          <Tooltip
            title={!item.disabled ? item['aria-label'] : ''}
            placement="bottom"
          >
            {/* span required to prevent warning when button disabled */}
            <span>
              <PaginationItem
                components={{
                  previous: () => <Icon name="back-page" basic />,
                  first: () => <Icon name="first-page" basic />,
                  next: () => <Icon name="next-page" basic />,
                  last: () => <Icon name="last-page" basic />,
                }}
                {...item}
              />
            </span>
          </Tooltip>
        ) : (
          <PaginationItem {...item} />
        )
      }}
    />
  )
}

export default TablePagination
