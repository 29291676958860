const checkFalsyValue = (value) => [null, undefined, '', false].includes(value)

const transformBooleanValue = (value) => {
  if (checkFalsyValue(value)) return false

  return true
}

export const booleanSort = (rowA, rowB, columnId) => {
  const valueA = transformBooleanValue(rowA.values[columnId])
  const valueB = transformBooleanValue(rowB.values[columnId])

  if (valueA === valueB) return 0

  return valueA ? -1 : 1
}

export const alphanumericSort = (rowA, rowB, columnId, desc) => {
  const valueA = rowA.values[columnId]
  const valueB = rowB.values[columnId]

  if (checkFalsyValue(valueA) && checkFalsyValue(valueB)) return 0

  if (checkFalsyValue(valueA)) return desc ? -1 : 1

  if (checkFalsyValue(valueB)) return desc ? 1 : -1

  return valueA.localeCompare(valueB)
}
