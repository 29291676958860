import { useMutation } from 'react-query'

import { updateCompensatedDays } from 'services/payroll/compensatedDaysService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, payrollId, compensatedDays } = mutationData

  if (mutationMethod === 'PUT')
    return updateCompensatedDays(payrollId, compensatedDays)

  return null
}

const useCompensatedDaysService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const compensatedDaysMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    compensatedDaysMutation,
  }
}

export default useCompensatedDaysService
