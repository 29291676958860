import { Box, Typography, useMediaQuery } from '@mui/material'

const EstimatedDaysCard = ({ affiliation }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const estimatedDays = affiliation.estimated_days

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.accent2.light,
        height: '8rem',
        borderRadius: '0.5rem',
        padding: theme.spacing(1, 2, 0, 2),
        boxShadow: theme.shadows[4],
        gap: theme.spacing(1.5),
      })}
    >
      <Typography
        variant={isMobile ? 'body2' : 'body1'}
        sx={{
          textAlign: 'center',
          width: '80%',
          zIndex: 2,
        }}
      >
        Días estimados para completar la afiliación
      </Typography>
      <Typography variant="h2" sx={{ fontWeight: 700 }}>
        {estimatedDays} {estimatedDays > 1 ? 'días hábiles' : 'día hábil'}
      </Typography>
    </Box>
  )
}

export default EstimatedDaysCard
