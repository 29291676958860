import { subYears } from 'date-fns'

import { Box, Typography } from '@mui/material'

import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { formatDate } from 'utils/dateTime'

const PersonalInformationStep = () => {
  const maxDateBirthDay = formatDate(subYears(new Date(), 15))

  return (
    <>
      <Typography
        sx={(theme) => ({ marginBottom: theme.spacing(6) })}
        color="black.dark"
        variant="body1"
      >
        Confirma los datos de esta persona y termina de llenar lo que haga
        falta.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <DocumentTypeField optional={false} />
        <FormField
          name="id_number"
          label="Número de documento"
          optional={false}
        />
        <FormField
          name="birthday"
          label="Fecha de nacimiento"
          variant="datepicker"
          optional={false}
          maxDate={maxDateBirthDay}
        />
        <FormField name="email" label="Correo electrónico" />
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridColumn: '1 / -1',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              columnGap: theme.spacing(2.5),
              gridTemplateColumns: '1fr 1fr 1fr',
            },
          })}
        >
          <DepartmentsList
            name="department"
            label="Departamento"
            optional={false}
          />
          <CitiesList name="city" label="Ciudad" optional={false} />
          <FormField
            name="address"
            label="Dirección de residencia"
            optional={false}
          />
        </Box>
      </Box>
    </>
  )
}

export default PersonalInformationStep
