import { OutlinedInput } from '@mui/material'

import {
  generateBasicNumberFormatter,
  generateBasicNumberParser,
} from 'utils/format'

const CustomFormatField = ({ id, name, onBlur, value, ...props }) => {
  const {
    setValue,
    formatter = generateBasicNumberFormatter(),
    parser = generateBasicNumberParser(),
    ...restProps
  } = props
  const formattedValue = formatter(value)

  return (
    <OutlinedInput
      id={id}
      name={name}
      onBlur={onBlur}
      value={formattedValue}
      onChange={({ target: { value: currentValue } }) => {
        const parsedValue = parser(currentValue)
        setValue(parsedValue)
      }}
      {...restProps}
    />
  )
}

export default CustomFormatField
