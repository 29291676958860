import { useFormikContext } from 'formik'
import { useState } from 'react'
import { flushSync } from 'react-dom'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import CreatePayrollConceptModal from 'components/Period/Payroll/Modals/CreatePayrollConceptModal'
import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import { getAutoCompleteElementFilteredOptions } from 'utils/form'

import messages from 'messages/worker'

import {
  deductionCategoryOptions,
  proportionalRecurrentPaymentOptions,
} from '../helpers'

const DeductionsSection = () => {
  const [isCreatePayrollConceptModalOpen, setIsCreatePayrollConceptModalOpen] =
    useState(false)
  const [conceptToCreate, setConceptToCreate] = useState({})
  const { values, setFieldValue } = useFormikContext()
  const queryClient = useQueryClient()
  const deductionsQueryKey = ['payrollConcepts', 'deductions', getCompanyId()]
  const deductionsCache = queryClient.getQueryData(deductionsQueryKey)

  const deductionsConcepts = (deductionsCache?.data || [])
    .filter(
      ({ coded_name: codedName }) =>
        codedName !== 'loan' &&
        codedName !== 'payroll_retention' &&
        codedName !== 'avista_payroll_credit'
    )
    .map((concept) => ({
      id: concept.id,
      name: concept.name,
    }))

  const handleCreateDeductionConcept = (_, newValue) => {
    if (newValue?.id === 'create') {
      setConceptToCreate(newValue.value ? { name: newValue.value } : {})

      flushSync(() => {
        setIsCreatePayrollConceptModalOpen(true)
      })
    } else {
      setFieldValue('payroll_concept_id', newValue)
    }
  }

  const handleCloseCreatePayrollConceptModal = () =>
    setIsCreatePayrollConceptModalOpen(false)

  const handleRevalidateConcepts = async ({ payrollConcepts }) => {
    await queryClient.invalidateQueries(deductionsQueryKey)
    setFieldValue('payroll_concept_id', {
      id: payrollConcepts[0].id,
      name: payrollConcepts[0].name,
    })
  }

  return (
    <>
      <Typography>
        Configura <strong>deducciones o préstamos</strong> que se descontarán de
        forma automática de la nómina.
      </Typography>
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <FormField
          label="Tipo"
          name="category"
          variant="select"
          options={deductionCategoryOptions}
          optional={false}
        />
        {values.category === 'deductions' ? (
          <>
            <FormField
              label="Concepto"
              name="payroll_concept_id"
              variant="autocomplete"
              placeholder="Escribe el concepto de deducción a agregar..."
              noOptionsText="Cargando conceptos de deducción..."
              loadingText="Cargando conceptos de deducción..."
              options={deductionsConcepts}
              onChange={handleCreateDeductionConcept}
              filterOptions={(opt, params) =>
                getAutoCompleteElementFilteredOptions(
                  opt,
                  params,
                  'Crear nuevo concepto de deducción'
                )
              }
              optional={false}
            />
            <FormField
              name="concept_type"
              label="¿La deducción es fija o proporcional?"
              variant="select"
              options={proportionalRecurrentPaymentOptions}
              tooltipContent={messages.proportional_recurrent_payments}
              optional={false}
            />
          </>
        ) : (
          <FormField label="Concepto" name="name" optional={false} />
        )}
        {values.category === 'loans' ? (
          <FormField
            label="Valor"
            name="value"
            variant="currency"
            align="left"
            optional={false}
          />
        ) : null}
        <FormField
          label="Cuota mensual"
          name={values.category === 'loans' ? 'monthly_fee' : 'monthly_value'}
          variant="currency"
          align="left"
          optional={false}
        />
      </Box>
      {isCreatePayrollConceptModalOpen ? (
        <CreatePayrollConceptModal
          category="deductions"
          conceptToCreate={conceptToCreate}
          handleClose={handleCloseCreatePayrollConceptModal}
          callback={handleRevalidateConcepts}
        />
      ) : null}
    </>
  )
}

export default DeductionsSection
