import { TourProvider } from '@reactour/tour'

import { Box, Button, ButtonBase, useTheme } from '@mui/material'

import Icon from 'components/UI/Icon'

const Close = ({ onClick }) => (
  <ButtonBase
    onClick={onClick}
    sx={(theme) => ({
      position: 'absolute',
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
    })}
  >
    <Icon name="close" sx={{ fontSize: '1.3rem' }} />
  </ButtonBase>
)

const Arrow = ({ inverted, disabled }) => {
  return (
    <Box
      component="span"
      sx={(theme) => ({
        cursor: 'pointer',
        color: theme.palette.black.main,
      })}
    >
      {inverted ? (
        <Icon
          name="next-page"
          sx={{ fontSize: '2rem', display: disabled ? 'none' : 'block' }}
        />
      ) : (
        <Icon
          name="back-page"
          sx={{ fontSize: '2rem', display: disabled ? 'none' : 'block' }}
        />
      )}
    </Box>
  )
}

const ReacTourProvider = ({ children, ...props }) => {
  const themeInstance = useTheme()

  return (
    <TourProvider
      components={{ Close, Arrow }}
      showDots={false}
      styles={{
        badge: (base) => ({
          ...base,
          backgroundColor: themeInstance.palette.primary.main,
          color: themeInstance.palette.black.main,
          width: '2rem',
          height: '2rem',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          boxShadow: themeInstance.shadows[3],
        }),
        maskArea: (base) => ({
          ...base,
          rx: '1rem',
        }),
        popover: (base) => ({
          ...base,
          marginTop: themeInstance.spacing(1),
          borderRadius: '1rem',
          padding: themeInstance.spacing(3),
          backgroundColor: themeInstance.palette.white.dark,
        }),
      }}
      prevButton={({ currentStep, setCurrentStep }) => {
        return (
          <Button
            size="small"
            variant="text"
            startIcon={<Icon name="back-page" />}
            sx={{
              visibility: currentStep === 0 ? 'hidden' : 'visible',
            }}
            onClick={() => {
              if (currentStep > 0) {
                setCurrentStep(currentStep - 1)
              }
            }}
          >
            Volver
          </Button>
        )
      }}
      nextButton={({ currentStep, setCurrentStep, stepsLength }) => {
        return (
          <Button
            size="small"
            variant="text"
            endIcon={<Icon name="next-page" />}
            sx={{
              visibility:
                currentStep === stepsLength - 1 ? 'hidden' : 'visible',
            }}
            onClick={() => {
              if (currentStep < stepsLength - 1) {
                setCurrentStep(currentStep + 1)
              }
            }}
          >
            Siguiente
          </Button>
        )
      }}
      {...props}
    >
      {children}
    </TourProvider>
  )
}

export default ReacTourProvider
