import { useFormikContext } from 'formik'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { SubscriptionViewContext } from '../Index'
import { useCalculateSubscription } from '../helpers'

const PlanSelector = () => {
  const location = useLocation()
  const companyId = getCompanyId()
  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
    },
    queryOptions: {
      enabled: !location?.state,
    },
  })

  const { values, setFieldValue } = useFormikContext()
  const { setSelectedPlan } = useContext(SubscriptionViewContext)
  const { calculateSubscription } = useCalculateSubscription()
  const plans = location?.state?.planOptions || plansQuery?.data?.plans || []

  const planOptions = plans.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }))

  return (
    <FormField
      fullWidth={false}
      name="plan"
      variant="select"
      optional={false}
      options={planOptions}
      onChange={({ target }) => {
        setFieldValue('plan', target?.value)
        calculateSubscription(
          {
            extraWorkers: values?.workers_number,
            planId: target?.value,
          },
          setSelectedPlan
        )
      }}
    />
  )
}

export default PlanSelector
