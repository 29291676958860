import http, { getApiURL } from '../httpService'

export const getTermination = (contractId) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}/termination` })

  return http.getV2(url)
}

export const getPaymentsHistory = (contractId, date) => {
  const url = getApiURL({
    pathname: `/contracts/${contractId}/salary_payments_file`,
    searchParams: { date, async: true },
  })

  return http.getV2(url)
}

export const createTermination = (contractId, termination) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}/termination` })

  const dataReq = { termination }

  return http.postV2(url, dataReq)
}

export const createPreview = (
  contractId,
  termination,
  { file_format: fileFormat } = {}
) => {
  const url = getApiURL({
    pathname: `/contracts/${contractId}/termination_preview`,
    searchParams: { file_format: fileFormat },
  })

  const dataReq = { termination }

  return http.postV2(url, dataReq)
}

export const updateTermination = (contractId, termination) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}/termination` })

  const dataReq = { termination }

  return http.putV2(url, dataReq)
}

export const deleteTermination = (contractId) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}/termination` })

  return http.deleteV2(url)
}

export default {
  getTermination,
  getPaymentsHistory,
  createTermination,
  createPreview,
  updateTermination,
  deleteTermination,
}
