import { useCallback, useMemo, useState } from 'react'

import LoadingModal from './LoadingModal'
import LoadingModalContext from './LoadingModalContext'

const LoadingModalProvider = ({ children }) => {
  const [isloadingModalOpen, setIsLoadingModalOpen] = useState(false)

  const showLoadingModal = useCallback(() => setIsLoadingModalOpen(true), [])
  const hideLoadingModal = useCallback(() => setIsLoadingModalOpen(false), [])

  const LoadingModalContextValue = useMemo(
    () => ({
      showLoadingModal,
      hideLoadingModal,
    }),
    [hideLoadingModal, showLoadingModal]
  )

  return (
    <>
      <LoadingModalContext.Provider value={LoadingModalContextValue}>
        {children}
      </LoadingModalContext.Provider>
      {isloadingModalOpen ? <LoadingModal /> : null}
    </>
  )
}

export default LoadingModalProvider
