import http, { getApiURL } from '../httpService'

export const getLaborCertificate = (workerId, options) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/labor_certificate`,
    searchParams: options,
  })

  return http.getV2(url)
}

export const getIncomeCertificate = (workerId, options) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/income_certificate`,
    searchParams: options,
  })

  return http.getV2(url)
}

export const getWorkerProfileLaborCertificate = (workerId, options) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/labor_certificate`,
    searchParams: options,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getWorkerProfileIncomeCertificate = (workerId, options) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/income_certificate`,
    searchParams: options,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getWorkersIncomeCertificates = (options) => {
  const url = getApiURL({
    pathname: `/workers/income_certificate`,
    searchParams: { async: true, ...options },
  })

  return http.getV2(url)
}

export default {
  getLaborCertificate,
  getIncomeCertificate,
  getWorkersIncomeCertificates,
}
