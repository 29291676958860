import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'
import Page from 'components/UI/Page/Page'

import { isWorker } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatDateRange } from 'utils/dateTime'
import usePayrollService from 'utils/hooks/payroll/payroll'
import useWorkerService from 'utils/hooks/worker/workerService'
import { getWorkerId } from 'utils/worker'

import usePayslipModal from '../Payroll/Modals/usePayslipModal'
import DetailsBody from './DetailsBody'
import DetailsHeader from './DetailsHeader'
import { getDetails } from './helpers'

const PeriodDetails = () => {
  const companyId = getCompanyId()
  const { payrollId, socialBenefitsId } = useParams()
  const workerId = getWorkerId()

  const [isLoading, setIsLoading] = useState(false)
  const { handleDownloadPayslip } = usePayslipModal()

  const { workerQuery: baseWorkerPayrollDetailsQuery } = useWorkerService({
    serviceParams: {
      queryKey: ['getWorkerDetails', workerId, payrollId, 'base_details'],
      workerId,
      payrollId,
    },
    queryOptions: {
      enabled: isWorker() && Boolean(payrollId) && Boolean(workerId),
    },
  })

  const { payrollQuery: basePayrollDetailsQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'base_details'],
      payrollId,
      searchParams: { social_benefit_period_id: socialBenefitsId },
    },
    queryOptions: {
      enabled: !isWorker() && Boolean(payrollId),
    },
  })

  const {
    payroll_retention: payrollRetention,
    termination,
    social_benefits: socialBenefits,
  } = basePayrollDetailsQuery.data?.header?.payroll_options || {}

  const { payrollQuery: payrollRetentionQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'payroll_retention'],
      payrollId,
      searchParams: {
        social_benefit_period_id: socialBenefitsId,
        details_section: 'payroll_retention',
      },
    },
    queryOptions: {
      enabled: !!payrollRetention,
    },
  })

  const { payrollQuery: terminationQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'termination'],
      payrollId,
      searchParams: {
        social_benefit_period_id: socialBenefitsId,
        details_section: 'termination',
      },
    },
    queryOptions: {
      enabled: !!termination,
    },
  })

  const { payrollQuery: socialBenefitsQuery } = usePayrollService({
    serviceParams: {
      queryKey: ['getDetails', companyId, payrollId, 'social_benefits'],
      payrollId,
      searchParams: {
        social_benefit_period_id: socialBenefitsId,
        details_section: 'social_benefits',
      },
    },
    queryOptions: {
      enabled:
        !!socialBenefits && !socialBenefitsId?.includes('socialBenefitsId'),
    },
  })

  const { data: baseWorkerPayrollDetailsData } = baseWorkerPayrollDetailsQuery
  const { data: basePayrollDetailsData } = basePayrollDetailsQuery
  const { data: payrollRetentionDetailsData } = payrollRetentionQuery
  const { data: terminationDetailsData } = terminationQuery
  const { data: socialBenefitsDetailsData } = socialBenefitsQuery

  const details = useMemo(
    () =>
      getDetails(
        baseWorkerPayrollDetailsData,
        basePayrollDetailsData,
        payrollRetentionDetailsData,
        terminationDetailsData,
        socialBenefitsDetailsData
      ),
    [
      baseWorkerPayrollDetailsData,
      basePayrollDetailsData,
      payrollRetentionDetailsData,
      socialBenefitsDetailsData,
      terminationDetailsData,
    ]
  )

  const downloadPayslip = async () => {
    setIsLoading(true)
    await handleDownloadPayslip({ payroll: { id: payrollId } })
    setIsLoading(false)
  }

  return (
    <Page
      isLoading={
        baseWorkerPayrollDetailsQuery.isLoading ||
        basePayrollDetailsQuery.isLoading ||
        payrollRetentionQuery.isLoading ||
        terminationQuery.isLoading ||
        socialBenefitsQuery.isLoading
      }
      documentTitle={`Cálculos${
        details.header
          ? ` de ${details.header.worker.name} del ${formatDateRange(
              details.header.period.period_payment.initial_day,
              details.header.period.period_payment.end_day
            )}`
          : ''
      }`}
      header={
        <Box
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          <Typography variant="h2">Cálculos</Typography>
          <Typography>
            A continuación, podrás encontrar los cálculos que realiza Aleluya,
            para que puedas revisar de dónde proviene cada valor de la
            liquidación.
          </Typography>
        </Box>
      }
      isLoadingWithModal={isLoading}
      grid
    >
      <DetailsHeader headerData={details.header} />
      {isWorker() ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            margin: theme.spacing(3, 0),
            gridColumn: '1 / -1',
          })}
        >
          <Button
            endIcon={<Icon name="download" basic />}
            onClick={downloadPayslip}
          >
            Descargar colilla
          </Button>
        </Box>
      ) : null}
      <DetailsBody details={details} socialBenefitsId={socialBenefitsId} />
    </Page>
  )
}

export default PeriodDetails
