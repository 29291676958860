import http, { getApiURL } from '../httpService'

export const enableDuplicateNit = (data) => {
  const url = getApiURL({
    withoutCompany: true,
    withoutVersion: true,
    pathname: '/organizers/countries',
  })

  return http.patchV2(url, data)
}

export default {
  enableDuplicateNit,
}
