import { useCallback } from 'react'

import { PAYROLL_ACTION_TYPES } from './usePayrollConfiguration'

const useIncomeModal = ({ payrollState, payrollDispatch }) => {
  const openIncomeModal = useCallback(
    (noveltyConcept) => {
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SHOW_MODAL,
        modalKeys: {
          noveltyTypeModal: false,
          incomeModal: true,
        },
      })
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SET_MODAL_CONFIGURATION,
        config: {
          noveltyConcept,
        },
      })
    },
    [payrollDispatch]
  )

  const closeIncomeModal = () =>
    payrollDispatch({
      type: PAYROLL_ACTION_TYPES.CLOSE_MODAL,
      modalKeys: {
        incomeModal: false,
        noveltyTypeModal: true,
      },
    })

  return {
    incomeModalState: {
      open: Boolean(payrollState.incomeModal),
    },
    openIncomeModal,
    closeIncomeModal,
  }
}

export default useIncomeModal
