import { useMutation } from 'react-query'

import {
  getBulkPdfElectronicPayroll,
  getIndividualPdfElectronicPayroll,
} from 'services/payroll/fileService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationKey,
    electronicPayrollId,
    periodId,
    fileType,
    async,
  } = mutationData

  if (mutationMethod === 'GET') {
    if (mutationKey === 'getAllElectronicPayrollPdfs') {
      return getBulkPdfElectronicPayroll(periodId, fileType, async)
    }

    if (mutationKey === 'getElectronicPayrollPdf') {
      return getIndividualPdfElectronicPayroll(electronicPayrollId, periodId)
    }
  }
  return null
}

const useElectronicPayrollPdfService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()
  const downloadPdfMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    downloadPdfMutation,
  }
}

export default useElectronicPayrollPdfService
