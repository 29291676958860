import { formatCompleteDate } from 'utils/dateTime'

const columnsData = [
  {
    Header: 'Fecha',
    accessor: 'created_at',
    alignHeader: 'center',
    alignCell: 'center',
    Cell: ({ row }) => formatCompleteDate(row.original.created_at),
  },
  {
    Header: 'Detalle',
    accessor: 'comment',
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    Header: 'Usuario',
    accessor: 'email',
    alignHeader: 'center',
    alignCell: 'center',
  },
]

export default columnsData
