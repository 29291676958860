import { CourierProvider } from '@trycourier/react-provider'

import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import useNotificationsTokenService from 'utils/hooks/notifications/authenticationToken'

import NotificationsPage from './Page'

const NofiticationsView = () => {
  const companyId = getCompanyId()

  const { notificationsTokenQuery } = useNotificationsTokenService({
    serviceParams: {
      queryKey: ['getNotificationsToken', companyId],
    },
  })

  return (
    <Page
      documentTitle="Notificaciones"
      header="Notificaciones"
      grid
      contentSx={{
        gridTemplateRows: '1fr',
      }}
      isLoading={notificationsTokenQuery.isLoading}
    >
      <CourierProvider
        userId={companyId}
        authorization={notificationsTokenQuery.data?.token}
      >
        <NotificationsPage />
      </CourierProvider>
    </Page>
  )
}

export default NofiticationsView
