import { Box, Typography } from '@mui/material'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

const Tooltip = ({ title, disableInteractive = true }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TooltipInfoIcon
        title={
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.black.main,
            })}
          >
            {title}
          </Typography>
        }
        iconProps={{
          sx: {
            margin: '0 !important',
          },
        }}
        tooltipProps={{
          disableInteractive,
          componentsProps: {
            tooltip: {
              sx: (theme) => ({
                backgroundColor: theme.palette.white.dark,
              }),
            },
            arrow: {
              sx: (theme) => ({
                '&::before': {
                  backgroundColor: theme.palette.white.dark,
                },
              }),
            },
          },
        }}
      />
    </Box>
  )
}

export default Tooltip
