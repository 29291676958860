import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'

import DocumentsMan from 'assets/images/views/common/aleluya_documents_man_right.svg'
import HappyMan from 'assets/images/views/common/aleluya_person_no_shadow.svg'

const NoItems = ({ type, onClick }) => {
  const { isWorker } = useUser()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const getActionLabel = () => {
    if (isMobile) return ''

    if (type === 'incomes') return 'ingresos'

    return 'deducciones y préstamos'
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        borderRadius: '1rem',
        backgroundColor: theme.palette.accent1.light,
        padding: theme.spacing(4),
        justifyContent: 'center',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          columnGap: theme.spacing(3.5),
          [theme.breakpoints.down('tablet')]: {
            textAlign: 'center',
          },
        })}
      >
        {!isMobile ? (
          <Box
            component="img"
            sx={{
              maxHeight: '16.5rem',
              objectFit: 'contain',
            }}
            src={type === 'incomes' ? HappyMan : DocumentsMan}
          />
        ) : null}
        <Box>
          <Typography paragraph>
            No existen{' '}
            {type === 'incomes'
              ? 'ingresos asociados'
              : 'deducciones asociadas'}
          </Typography>
          {!isWorker ? (
            <Button
              variant="outlined"
              onClick={onClick}
              sx={(theme) => ({
                fontSize: '1rem',
                padding: theme.spacing(1, 2.5),
                height: 'fit-content',
              })}
            >
              {`Agregar ${getActionLabel()}`}
              <Icon
                name="plus"
                basic
                sx={(theme) => ({ marginLeft: theme.spacing(1) })}
              />
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default NoItems
