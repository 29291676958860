import http, { getApiURL } from '../httpService'

export const getAllByCategory = async (category) => {
  const url = getApiURL({
    pathname: '/payroll_concepts',
    searchParams: { category },
  })

  return http.getV2(url)
}

export const getPayrollConceptsByCategory = (category) => {
  const url = getApiURL({
    pathname: '/payroll_concepts',
    searchParams: { category },
  })

  return http.getV2(url)
}

export const updatePayrollConcepts = (concepts) => {
  const url = getApiURL({ pathname: '/payroll_concepts' })

  return http.putV2(url, concepts)
}

export const removePayrollConcept = (payrollConceptId) => {
  const url = getApiURL({ pathname: `/payroll_concepts/${payrollConceptId}` })

  return http.deleteV2(url)
}

export default {
  getAllByCategory,
  getPayrollConceptsByCategory,
  removePayrollConcept,
  updatePayrollConcepts,
}
