import { useState } from 'react'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'

const initialState = {
  open: false,
  integration: {},
}

const useIntegrationModal = () => {
  const [state, setState] = useState(initialState)
  const { distributor } = useUser()
  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const openModal = (integration) => {
    const isAlegraDistributorAndIntegration =
      ['alegra', 'alegra_nomina', 'alegra_old'].includes(distributor) &&
      integration.provider === 'alegra'

    if (!isAlegraDistributorAndIntegration && !showPremiumFeatures) {
      openPremiumFeatureModal()
    } else {
      setState({
        open: true,
        integration,
      })
    }
  }

  const closeModal = () => setState(initialState)

  return {
    integrationModalState: state,
    openIntegrationModal: openModal,
    closeIntegrationModal: closeModal,
  }
}

export default useIntegrationModal
