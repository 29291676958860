import useModals from 'components/App/ModalsManager/useModals'

import ExtraWorkersModalContent from './ExtraWorkersModalContent'

const useExtraWorkersModal = () => {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal('extraWorker')
  }

  const openModal = () => {
    modals.openModal({
      id: 'extraWorker',
      content: <ExtraWorkersModalContent onClose={handleClose} />,
      modalProps: {
        onCloseModal: handleClose,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
        },
        paperSx: {
          maxWidth: '55rem',
          position: 'relative',
        },
      },
    })
  }

  return { openModal }
}

export default useExtraWorkersModal
