import Icon from '../Icon'
import Link from '../MaterialUI/Link'

const GoBackLink = ({ children = 'Regresar', to, ...rest }) => {
  return (
    <Link
      to={to}
      style={{ display: 'inline-flex', alignItems: 'center' }}
      {...rest}
    >
      <Icon
        name="arrow-right-2"
        sx={{
          transform: 'rotate(180deg)',
        }}
      />
      {children}
    </Link>
  )
}

export default GoBackLink
