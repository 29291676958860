import { Box, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

const HelpModal = ({ modalInfo, handleClose, state }) => {
  const { videoLink, title, description, footer } = modalInfo

  return (
    <Modal
      onOk={handleClose}
      open={state.open}
      okText="Entiendo"
      onCancel={handleClose}
      header={<Typography variant="h5">{title}</Typography>}
      hideCancelButton
      paperSx={{
        maxWidth: '45rem',
      }}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          paddingBottom: '62.5%',
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          height: 0,
        })}
      >
        <Box
          component="iframe"
          title="Video tutorial de habilitacion en la DIAN de Nómina Electronica"
          src={videoLink}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          allowFullScreen
        />
      </Box>
      <Typography sx={(theme) => ({ marginBottom: theme.spacing(3) })}>
        {description}
      </Typography>
      <Typography>{footer}</Typography>
    </Modal>
  )
}

export default HelpModal
