import { Box, Typography } from '@mui/material'

import Adornment from 'components/UI/Adornment'
import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'

import aleluyaDocumentsManRight from 'assets/images/views/common/aleluya_documents_man_right.svg'

const positionAdornments = [
  {
    top: '-8.125rem',
  },
  {
    top: '5.062rem',
  },
  {
    top: '18.937rem',
  },
  {
    bottom: '-4.937rem',
  },
]

const EmptyState = ({
  title,
  description,
  handleAddWorkerManually,
  enableFileOption,
}) => {
  return (
    <Box
      sx={{
        height: '39rem',
        display: 'grid',
        gridTemplateColumns: '20.437rem 1fr',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'accent3.dark',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {positionAdornments.map((adornment, index) => (
          <Adornment
            key={index.toString()}
            variant="spring"
            color="black.main"
            width={443}
            height={195}
            sx={{ left: '-1.937rem', ...adornment }}
          />
        ))}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.accent1.light,
        })}
      >
        <Box
          sx={(theme) => ({
            marginLeft: theme.spacing(7),
            marginRight: theme.spacing(6),
          })}
        >
          <img
            src={aleluyaDocumentsManRight}
            alt="Documents Men"
            width={248}
            height={400}
          />
        </Box>
        <Box
          sx={(theme) => ({
            maxWidth: '20.5rem',
            minWidth: '20.5rem',
            marginRight: theme.spacing(2),
          })}
        >
          <Typography
            sx={(theme) => ({
              marginBottom: theme.spacing(1),
            })}
            variant="h3"
          >
            {title}
          </Typography>
          <Typography
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
            variant="body1"
          >
            {description}
          </Typography>
          <ButtonAddWorker
            handleAddWorkerManually={handleAddWorkerManually}
            enableFileOption={enableFileOption}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default EmptyState
