import { Box } from '@mui/material'

const ContainerFields = ({ children }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: theme.spacing(2.5),
      })}
    >
      {children}
    </Box>
  )
}

export default ContainerFields
