import { TableCell, TableRow, Typography } from '@mui/material'

import ConfirmEditActions from './ConfirmEditActions'

const DeleteRow = ({
  colSpan,
  cancelEditRow,
  confirmEditRow,
  rowValues,
  customActionsWidth,
  alignActionsCell = 'left',
  ...props
}) => {
  const onDeleteRow = () => confirmEditRow(rowValues)

  return (
    <TableRow
      {...props}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.error.main} !important`,
      })}
    >
      <TableCell role="cell" colSpan={colSpan}>
        <Typography variant="h6">
          ¿Estás seguro de eliminar este registro?
        </Typography>
      </TableCell>
      <TableCell
        role="cell"
        colSpan={1}
        sx={{
          whiteSpace: 'noWrap',
          textAlign: 'left',
          ...(alignActionsCell === 'center' && {
            textAlign: 'center',
          }),
          ...(alignActionsCell === 'right' && {
            textAlign: 'right',
          }),
        }}
        style={customActionsWidth ? { width: customActionsWidth } : undefined}
      >
        <ConfirmEditActions onCancel={cancelEditRow} onSave={onDeleteRow} />
      </TableCell>
    </TableRow>
  )
}

export default DeleteRow
