import { Box, Typography, useMediaQuery } from '@mui/material'

import SelectField from 'components/UI/Formik/FormField/SelectField'

import messages from 'messages/payroll'

import MobileCell from '../common/CardGrid/MobileCell'
import Tooltip from '../common/Tooltip'

const listOptions = [
  {
    value: 'early_payment_holidays_and_payroll',
    label: 'Vacaciones + nómina',
  },
  {
    value: 'early_payment_holidays_only',
    label: 'Solo vacaciones',
  },
  {
    value: 'no_early_payment',
    label: 'Sin pago anticipado',
  },
]

const EarlyPayment = ({ earlyPayment, onChange }) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 1,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(1),
          })}
        >
          <Typography variant="h6" color="black.dark">
            ¿Pago anticipado?
          </Typography>
          {!isTabletUp ? (
            <Tooltip
              title={messages.early_payment}
              disableInteractive={false}
            />
          ) : null}
        </Box>
      </MobileCell>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        alignY="center"
        sx={(theme) => ({
          gridColumn: '1 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 2,
          },
        })}
      >
        <SelectField
          options={listOptions}
          name="early_payment_type"
          onChange={(e) => onChange(e, 'early_payment_type')}
          value={earlyPayment}
          data-cy="input-early_payment"
          sx={{
            height: '2rem',
            width: '100%',
            '& .MuiSelect-select': {
              padding: '0.25rem 0',
            },
          }}
        />
      </MobileCell>
      {isTabletUp ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title={messages.early_payment} disableInteractive={false} />
        </Box>
      ) : null}
    </>
  )
}

export default EarlyPayment
