import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { getLoans } from 'services/worker/loansService'

import * as routes from 'config/routes'

import { columnsDataIndex, getActions } from './helpers'

const LoansIndex = () => {
  const { worker } = useWorker()
  const { workerId } = useParams()

  const fetchLoans = (pageIndex, search) => {
    return {
      queryKey: ['getAllLoans', pageIndex + 1, search],
      queryFunction: () =>
        getLoans(workerId, {
          page: pageIndex + 1,
          search,
        }),
    }
  }

  const columns = columnsDataIndex(workerId)
  const actions = getActions(workerId)

  return (
    <Page
      documentTitle={`Prestamos${worker ? ` de ${worker.fullName}` : ''}`}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Préstamos de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Table
          columns={columns}
          data={fetchLoans}
          options={{
            pagination: false,
            search: true,
            alignActionsHeader: 'center',
            alignActionsCell: 'center',
            version: {
              toolbar: 'v2',
            },
          }}
          actions={actions}
        />
      </Box>
    </Page>
  )
}

export default LoansIndex
