import { Box, Link } from '@mui/material'

import Button from 'components/UI/Button/Button'

const Footer = ({
  primaryButtonText,
  secondaryButtonText,
  onClick,
  status,
  code,
  page = false,
}) => {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(1.5),
        })}
      >
        {secondaryButtonText ? (
          <Button
            component={Link}
            href={
              status === 'premium_due' || code === '0007'
                ? 'https://api.whatsapp.com/send/?phone=573007405537&text&app_absent=0'
                : null
            }
            target="_blank"
            size={page ? 'medium' : 'small'}
            variant="outlined"
            sx={(theme) => ({
              marginRight: theme.spacing(2),
              '&:hover': {
                textDecoration: 'none',
              },
            })}
          >
            {secondaryButtonText}
          </Button>
        ) : null}
        <Button size={page ? 'medium' : 'small'} onClick={onClick}>
          {primaryButtonText}
        </Button>
      </Box>
    </Box>
  )
}

export default Footer
