import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Menu, MenuItem, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import SocialSecurityPaymentModal from 'components/Period/SocialSecurity/PaymentModal/SocialSecurityPaymentModal'
import useSocialSecurityPaymentModal from 'components/Period/SocialSecurity/PaymentModal/useSocialSecurityPaymentModal'
import ProviderModal from 'components/Period/SocialSecurity/ProviderModal'
import Icon from 'components/UI/Icon'

import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import { usePeriod } from '../../helpers'
import { ActionContent } from '../helpers'

const PaySocialSecurityMenu = ({ fixedButtonStyle, label }) => {
  const navigate = useNavigate()
  const {
    period,
    options: {
      social_security_file: socialSecurityFile,
      students_social_security_file: studentsSocialSecurityFile,
      social_security_integration: socialSecurityIntegration,
    },
    payPreviousPayroll,
  } = usePeriod()

  const {
    socialSecurityPaymentModalState,
    openSocialSecurityPaymentModal,
    closeSocialSecurityPaymentModal,
    activateSocialSecurityPaymentIntegration,
  } = useSocialSecurityPaymentModal(period, socialSecurityIntegration?.active)

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const modals = useModals()
  const [socialSecurityProvider, setSocialSecurityProvider] = useState({
    open: false,
    selectedProvider: null,
    formType: null,
  })
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'paySocialSecurityMenu',
  })
  const popupBindTrigger = bindTrigger(popupState)
  const { showErrorMessage } = useNotifications()
  const isCurrentForm = Object.values(
    socialSecurityIntegration?.forms_keys
  ).some((value) => value !== null)

  const openPopover = (event) => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    popupBindTrigger.onClick(event)
  }

  // Close the select social security provider modal
  const handleCloseProviderModal = () =>
    setSocialSecurityProvider({
      open: false,
      selectedProvider: null,
      formType: null,
    })

  const openNextStepModal = (formType, provider) => {
    if (formType === 'employee') {
      if (
        socialSecurityIntegration?.forms_keys.employee &&
        socialSecurityIntegration?.active
      ) {
        navigate(
          payPreviousPayroll
            ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(period.id)
            : routes.PERIOD_SOCIAL_SECURITY_SHOW(period.id),
          {
            state: { formType: 'employee', provider },
          }
        )
      } else {
        openSocialSecurityPaymentModal({ provider })
      }
    } else if (formType === 'student') {
      if (
        socialSecurityIntegration?.forms_keys.student &&
        socialSecurityIntegration?.active
      ) {
        navigate(
          payPreviousPayroll
            ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(period.id)
            : routes.PERIOD_SOCIAL_SECURITY_SHOW(period.id),
          {
            state: { formType: 'student_decree_055', provider },
          }
        )
      } else {
        openSocialSecurityPaymentModal({
          formType: 'student_decree_055',
          provider,
        })
      }
    }
  }

  const handleClick = (formType = 'employee') => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    // Verify if there isn't an activated social security provider
    if (!socialSecurityIntegration?.active) {
      modals.openModal({
        id: `withoutSocialSecurityIntegration`,
        content: (
          <Typography variant="body1" color="black.dark">
            Relax! Realiza la integración con tu operador PILA para que puedas
            realizar el pago en menos de lo que canta un gallo.
          </Typography>
        ),
        modalProps: {
          header: '¿Quieres pagar tu SS a un clic?',
          okText: 'Realizar integración',
          cancelText: 'Realizar después',
          adornment: 'spring',
          onOk: () => {
            modals.closeAll()
            navigate(routes.SETTINGS_INTEGRATION_INDEX())
          },
          onCancel: () => modals.closeAll(),
          dialogProps: {
            fullWidth: true,
            maxWidth: 'tablet',
          },
        },
      })
      return
    }

    // Check if the user can select between the 2 providers (arus | aportes_en_linea)
    if (socialSecurityIntegration?.providers_options?.length === 2) {
      if (!isCurrentForm) {
        setSocialSecurityProvider((previousState) => ({
          ...previousState,
          open: true,
          formType,
        }))
        return
      }
      openNextStepModal(formType)
      return
    }

    // If this point is reached, there's only one active integration
    const currentIntegration = socialSecurityIntegration?.providers_options?.[0]
    openNextStepModal(formType, currentIntegration.provider)
  }

  const handleSelectProvider = (value) =>
    setSocialSecurityProvider((previousState) => ({
      ...previousState,
      selectedProvider: value,
    }))

  const handleNextStepWithSelectedProvider = () => {
    if (!socialSecurityProvider.selectedProvider) {
      showErrorMessage(
        'Por favor selecciona el operador con el que deseas realizar el pago'
      )
      return
    }

    setSocialSecurityProvider((previousState) => ({
      ...previousState,
      open: false,
    }))
    openNextStepModal(
      socialSecurityProvider.formType,
      socialSecurityProvider.selectedProvider
    )
  }

  return (
    <>
      {socialSecurityFile && studentsSocialSecurityFile ? (
        <>
          <Button
            variant="text"
            startIcon={<Icon name="refund" basic />}
            sx={fixedButtonStyle}
            endIcon={
              showPremiumFeatures ? <Icon name="arrow-down" basic /> : undefined
            }
            {...popupBindTrigger}
            onClick={openPopover}
          >
            <ActionContent label={label || 'Pagar seguridad social'} />
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {socialSecurityFile ? (
              <MenuItem onClick={() => handleClick()}>Empleados</MenuItem>
            ) : null}
            {studentsSocialSecurityFile ? (
              <MenuItem onClick={() => handleClick('student')}>
                Estudiantes (Decreto 055)
              </MenuItem>
            ) : null}
          </Menu>
        </>
      ) : (
        <>
          {socialSecurityFile ? (
            <Button
              variant="text"
              sx={fixedButtonStyle}
              startIcon={<Icon name="refund" basic />}
              onClick={() => handleClick()}
            >
              <ActionContent label={label || 'Pagar seguridad social'} />
            </Button>
          ) : null}
          {studentsSocialSecurityFile ? (
            <Button
              variant="text"
              sx={fixedButtonStyle}
              startIcon={<Icon name="refund" basic />}
              onClick={() => handleClick('student')}
            >
              <ActionContent
                label={`${label || 'Pagar seguridad social'} (Estudiantes)`}
              />
            </Button>
          ) : null}
        </>
      )}
      {socialSecurityPaymentModalState.open ? (
        <SocialSecurityPaymentModal
          state={socialSecurityPaymentModalState}
          handleClose={closeSocialSecurityPaymentModal}
          handleActivateIntegration={activateSocialSecurityPaymentIntegration}
        />
      ) : null}
      {socialSecurityProvider.open ? (
        <ProviderModal
          open={socialSecurityProvider.open}
          onCancel={handleCloseProviderModal}
          selectedProvider={socialSecurityProvider.selectedProvider}
          onChangeProvider={handleSelectProvider}
          onOk={handleNextStepWithSelectedProvider}
        />
      ) : null}
    </>
  )
}

export default PaySocialSecurityMenu
