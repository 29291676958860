import { Typography } from '@mui/material'

import GoBackLink from 'components/UI/LinkNav/GoBackLink'

const Header = ({
  showWorkers,
  isOnlyWorkers,
  integrationProvider,
  setShowWorkers,
}) => {
  if (showWorkers && !isOnlyWorkers) {
    return (
      <GoBackLink component="button" onClick={() => setShowWorkers(false)}>
        Regresar
      </GoBackLink>
    )
  }

  if (isOnlyWorkers) {
    return (
      <Typography
        variant="h5"
        sx={(theme) => ({
          paddingRight: theme.spacing(2),
          fontWeight: 'normal',
          '& > span': {
            fontWeight: 'bold',
          },
          '& > img': {
            marginLeft: theme.spacing(0.5),
            marginBottom: theme.spacing(-1),
          },
        })}
      >
        Personas para pago de seguridad social en{' '}
        <span>{integrationProvider.name}</span>
        <img
          src={integrationProvider.logo}
          alt={`Isotipo de ${integrationProvider.name}`}
          width={integrationProvider.logoWidth}
          height={integrationProvider.logoHeight}
        />
      </Typography>
    )
  }

  return (
    <Typography
      variant="h5"
      sx={(theme) => ({
        paddingRight: theme.spacing(2),
        fontWeight: 'normal',
        '& > span': {
          fontWeight: 'bold',
        },
        '& > img': {
          marginLeft: theme.spacing(0.5),
          marginBottom: theme.spacing(-1),
        },
      })}
    >
      Vamos a pagar tu seguridad social con{' '}
      <span>{integrationProvider.name}</span>
      <img
        src={integrationProvider.logo}
        alt={`Isotipo de ${integrationProvider.name}`}
        width={integrationProvider.logoWidth}
        height={integrationProvider.logoHeight}
      />
    </Typography>
  )
}

export default Header
