import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'

import { formatLongDisplayDate } from 'utils/dateTime'
import useCompanyService from 'utils/hooks/organizer/company'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { SUBSCRIPTION_STATUS } from 'config/routes'

import CancelReasons from './CancelReasons'
import SubscriptionCanceledModal from './SubscriptionCanceledModal'
import { getInitialValues, getValidationSchema, reasons } from './helpers'

const CancelSubscriptionModal = ({
  activeUntil,
  isFromOrganizerView,
  subscriptionCancellationData,
}) => {
  const { refreshCompany } = useUser()
  const modals = useModals()
  const navigate = useNavigate()
  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: { enabled: false },
  })
  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()

  const handleSubmit = (values) => {
    const dataToSend = values
    if (dataToSend.reason !== reasons.anotherReason) {
      delete dataToSend.another_reason_detail
    }

    if (isFromOrganizerView) {
      companyMutation.mutate(
        {
          mutationMethod: 'PATCH',
          company: {
            id: subscriptionCancellationData.companyId,
            active_subscription:
              !subscriptionCancellationData.paymentData.active_subscription,
            ...dataToSend,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              subscriptionCancellationData.companyQueryKey
            )
            modals.closeModal('cancelSubscriptionModal')
          },
          onError: (error) => {
            handleError(error)
          },
        }
      )
      return
    }

    subscriptionMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'cancelSubscription',
        cancellationData: dataToSend,
      },
      {
        onSuccess: () => {
          refreshCompany()
          modals.closeModal('cancelSubscriptionModal')
          modals.openModal({
            id: 'subscriptionCanceledModal',
            content: <SubscriptionCanceledModal activeUntil={activeUntil} />,
            modalProps: {
              hideFooter: true,
              header: 'Suscripción cancelada',
              isLoading: subscriptionMutation.isLoading,
              adornment: [
                {
                  variant: 'spring',
                  color: 'accent2.main',
                  width: 182.67,
                  height: 80,
                  sx: { bottom: '2.5rem', right: '1.5rem' },
                },
              ],
              paperSx: {
                maxWidth: '45.5rem',
              },
            },
          })
        },
      }
    )
  }

  const handleFormErrors = (errors, setFieldTouched) => {
    Object.keys(errors).forEach((errorKey) => {
      setFieldTouched(errorKey, true)
    })
  }

  return (
    <Box>
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(3) })}
      >
        La cancelación se hará efectiva al finalizar{' '}
        {isFromOrganizerView ? 'el' : 'tu'} actual periodo de facturación, el{' '}
        <b>{formatLongDisplayDate(activeUntil)}</b>.
      </Typography>
      <Typography color="black.dark">
        {isFromOrganizerView
          ? 'Selecciona,'
          : 'Puedes regresar cuando quieras. Para ayudarnos a mejorar, selecciona,'}{' '}
        dentro de la encuesta, cuál es el motivo de cancelación de{' '}
        {isFromOrganizerView ? 'la' : 'tu'} suscripción:
      </Typography>
      <Formik
        initialValues={getInitialValues(isFromOrganizerView)}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema(isFromOrganizerView)}
        enableReinitialize
      >
        {(form) => {
          const {
            errors,
            values,
            isValid,
            handleSubmit: onCancelSubscription,
            setFieldTouched,
          } = form

          return (
            <>
              <Form>
                <CancelReasons
                  values={values}
                  isFromOrganizerView={isFromOrganizerView}
                />
              </Form>
              <Box
                sx={(theme) => ({
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: theme.spacing(2),
                  marginTop: theme.spacing(6),
                  flexWrap: 'wrap',
                  [theme.breakpoints.up('tablet')]: {
                    justifyContent: 'flex-start',
                  },
                })}
              >
                <Button
                  variant={isFromOrganizerView ? 'outlined' : 'contained'}
                  disabled={
                    subscriptionMutation.isLoading || companyMutation.isLoading
                  }
                  onClick={() => {
                    modals.closeModal('cancelSubscriptionModal')
                    if (!isFromOrganizerView) navigate(SUBSCRIPTION_STATUS())
                  }}
                >
                  {isFromOrganizerView
                    ? 'Mantener la suscripción'
                    : 'Seguir con mi suscripción'}
                </Button>
                <Button
                  sx={(theme) => ({
                    ...(isFromOrganizerView && {
                      backgroundColor: theme.palette.error.main,
                      '&:hover': {
                        backgroundColor: theme.palette.error.light,
                      },
                    }),
                  })}
                  variant={isFromOrganizerView ? 'contained' : 'outlined'}
                  onClick={() => {
                    if (!isValid) {
                      handleFormErrors(errors, setFieldTouched)
                      return
                    }
                    onCancelSubscription()
                  }}
                  loading={
                    subscriptionMutation.isLoading || companyMutation.isLoading
                  }
                >
                  Cancelar {isFromOrganizerView ? 'la' : 'mi'} suscripción
                </Button>
              </Box>
            </>
          )
        }}
      </Formik>
    </Box>
  )
}

export default CancelSubscriptionModal
