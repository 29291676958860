import AccountantFile from './Accountant/Form'
import DuplicatedNIT from './DuplicatedNIT/Form'

export const tabsData = [
  {
    label: 'Archivos Contadores',
    content: <AccountantFile />,
  },
  {
    label: 'Activar NIT duplicado',
    content: <DuplicatedNIT />,
  },
]

export default { tabsData }
