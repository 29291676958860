import http, { getApiURL } from '../httpService'

export const getAllPosition = () => {
  const url = getApiURL({ pathname: '/positions' })

  return http.getV2(url)
}

export const putAllPosition = (positions) => {
  const url = getApiURL({ pathname: '/positions' })

  const reqData = {
    positions,
  }

  return http.putV2(url, reqData)
}

export default {
  getAllPosition,
  putAllPosition,
}
