import { createContext, useState } from 'react'

import Page from 'components/UI/Page/Page'
import useWorker from 'components/Worker/useWorker'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'

import WorkerBody from './Body/Body'
import WorkerShowHeader from './Header'

export const WorkerProfileContext = createContext()
WorkerProfileContext.displayName = 'WorkerProfileContext'

const WorkerShow = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { worker, isLoading, isError, isFromAffiliationView } = useWorker()
  const affiliationQueryKey = ['getAffiliationById', worker.id]
  useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId: worker.id,
    },
    queryOptions: {
      enabled: Boolean(worker.id) && isFromAffiliationView,
    },
  })

  return (
    <WorkerProfileContext.Provider value={{ activeTab, setActiveTab }}>
      <Page
        documentTitle={`Ver ${worker ? worker.fullName : 'persona'}`}
        isLoading={isLoading && !isError}
        grid
      >
        {!isLoading && !isError && !!worker ? (
          <>
            <WorkerShowHeader />
            <WorkerBody />
          </>
        ) : null}
      </Page>
    </WorkerProfileContext.Provider>
  )
}

export default WorkerShow
