import { Button, Chip, Tooltip, alpha } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { getWordSingular } from 'utils/format'

import { WORKER_SHOW } from 'config/routes'

import ApproveRequest from '../Modals/ApproveRequest'
import UpdateRequest from '../Modals/UpdateRequest'

export const getColumnsData = (handleDownloadEvidence) => {
  const statusLabel = {
    pending: 'Pendiente',
    approved: 'Aprobada',
    rejected: 'Rechazada',
  }

  return [
    {
      Header: 'Persona',
      accessor: 'worker_name',
      Cell: ({ row }) => (
        <Link to={WORKER_SHOW(row.original.worker_id)} color="black.main">
          {row.original.worker_name}
        </Link>
      ),
      customWidth: '12rem',
    },
    {
      Header: 'Tipo de permiso',
      accessor: 'payroll_concept_name',
      customWidth: '14rem',
    },
    {
      Header: 'Fecha de ausencia',
      accessor: 'initial_day',
      Cell: ({ row }) => (
        <span style={{ display: 'flex', columnGap: '5px' }}>
          {formatDisplayDateString(row.original.initial_day)}
          <Icon name="arrow-right" />
          {formatDisplayDateString(row.original.end_day)}
        </span>
      ),
      customWidth: '15rem',
    },
    {
      Header: 'Comentarios',
      accessor: 'comment',
      Cell: ({ row }) => {
        if (!row.original.comment) return 'N/A'

        const comment =
          row.original.comment?.length > 45
            ? `${row.original.comment.slice(0, 45)}...`
            : row.original.comment

        return (
          <Tooltip title={row.original.comment}>
            <span>{comment}</span>
          </Tooltip>
        )
      },
      customWidth: '9rem',
    },
    {
      Header: 'Documentos',
      accessor: 'evidence',
      Cell: ({ row }) => {
        if (!row.original.evidence) return 'N/A'

        return (
          <Button
            size="small"
            variant="text"
            onClick={() => handleDownloadEvidence(row.original.evidence)}
          >
            Ver documento
          </Button>
        )
      },
      customWidth: '9.5rem',
    },
    {
      Header: 'Duración',
      accessor: 'days',
      Cell: ({ row }) =>
        `${row.original.days} ${getWordSingular(
          'días',
          row.original.days_requested <= 1
        )}`,
      customWidth: '6rem',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) => (
        <Chip
          label={statusLabel[row.original.status]}
          sx={(theme) => ({
            width: 'fit-content',
            ...(row.original.status === 'approved' && {
              backgroundColor: alpha(theme.palette.success.light, 0.2),
              '& .MuiChip-label': {
                color: theme.palette.success.dark,
              },
            }),
            ...(row.original.status === 'rejected' && {
              backgroundColor: alpha(theme.palette.error.light, 0.2),
              '& .MuiChip-label': {
                color: theme.palette.error.main,
              },
            }),
            ...(row.original.status === 'pending' && {
              backgroundColor: alpha(theme.palette.warning.light, 0.2),
              '& .MuiChip-label': {
                color: theme.palette.warning.dark,
              },
            }),
          })}
        />
      ),
      customWidth: '9rem',
      alignCell: 'center',
      alignHeader: 'center',
    },
  ]
}
const optionsData = [
  { value: '', label: 'Todas las solicitudes' },
  {
    value: 'pending',
    label: 'Pendientes',
  },
  {
    value: 'approved',
    label: 'Aprobadas',
  },
  {
    value: 'rejected',
    label: 'Rechazadas',
  },
]

export const useTableActions = (
  freeTimeActiveStatus,
  setFreeTimeActiveStatus
) => {
  const modals = useModals()
  const handleClose = () => modals.closeAll()

  const handleApprove = (rowData) => {
    modals.openModal({
      id: 'approveRequest',
      content: (
        <ApproveRequest
          conceptId={rowData.payroll_concept_id}
          requestId={rowData.id}
          name={rowData.worker_name}
          initialDay={rowData.initial_day}
          endDay={rowData.end_day}
          onClose={handleClose}
        />
      ),
      modalProps: {
        header: 'Solicitud de tiempo fuera',
        onCloseModal: handleClose,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  const handleEdit = (rowData) => {
    modals.openModal({
      id: 'editRequest',
      content: <UpdateRequest requestData={rowData} onClose={handleClose} />,
      modalProps: {
        header: 'Modificar tiempo fuera',
        onCloseModal: handleClose,
        hideFooter: true,
        paperSx: {
          width: '100%',
          maxWidth: '46rem',
        },
      },
    })
  }

  return [
    (rowData) => ({
      id: 'to_approve',
      tooltip: 'Aprobar o rechazar',
      icon: <Icon name="approve-checked" basic />,
      disabled: rowData.status === 'approved',
      onClick: () => handleApprove(rowData),
    }),
    (rowData) => ({
      id: 'editable',
      tooltip: 'Editar',
      icon: <Icon name="edit-pencil" basic />,
      disabled: rowData.status === 'approved',
      onClick: () => handleEdit(rowData),
    }),
    {
      id: 'filterFreeTimeType',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          options={optionsData}
          defaultLabel="Todas las solicitudes"
          value={freeTimeActiveStatus}
          onChange={setFreeTimeActiveStatus}
        />
      ),
    },
  ]
}

export const getEmptyStateTitle = (freeTimeActiveStatus) => {
  let status = ''

  if (freeTimeActiveStatus === 'approved') {
    status = 'aprobadas'
  } else if (freeTimeActiveStatus === 'rejected') {
    status = 'rechazadas'
  } else if (freeTimeActiveStatus === 'pending') {
    status = 'pendientes'
  }

  return `No tienes solicitudes de tiempo fuera ${status}`
}
