import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getAlegraAccountingCodes } from 'services/settings/integrationAccountingCodesService'
import {
  getAllIntegration,
  updateIntegration,
} from 'services/settings/integrationService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, category } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'integrationsCompany') return getAllIntegration()

  if (currentQueryKey === 'alegraAccountingCodes')
    return getAlegraAccountingCodes(category)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, integration } = mutationData

  if (mutationMethod === 'PUT') return updateIntegration(integration)

  return null
}

const useIntegrationService = ({
  serviceParams = { queryKey: 'integrationsCompany' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const {
    data: { data, alegra_options: alegraOptions } = {},
    ...restIntegrations
  } = useQuery(serviceParams.queryKey, () => getQueryFunction(serviceParams), {
    onError: (error) => {
      handleError(error)
    },
    ...queryOptions,
  })

  const integrationsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    integrationsQuery: {
      data,
      alegraOptions,
      ...restIntegrations,
    },
    integrationsMutation,
  }
}

export default useIntegrationService
