import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Button as MButton, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import NewSteps from 'components/UI/Steps/NewSteps'

import { getCompanyId } from 'utils/company'
import { areObjectsEqual } from 'utils/general'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useCompanyFilesService from 'utils/hooks/company/files'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useWorkerService from 'utils/hooks/worker/workerService'

import AffiliationDataStep from './AffiliationDataStep'
import AttachDocumentsStep from './AttachDocumentsSteps'
import ConfirmAffiliation from './Modals/ConfirmAffiliation'
import PersonalInformationStep from './PersonalInformationStep'
import {
  formHasChanged,
  getDirtyWorker,
  getEntitiesWithMissingCredentialsKey,
  getInitialValues,
  stepsData,
  validationSchema,
} from './helpers'

const AffiliationForm = ({
  currentStep,
  setCurrentStep,
  handleClose,
  workerId,
  workerName,
  affiliationStatus,
}) => {
  const queryClient = useQueryClient()

  const modals = useModals()
  const { handleError } = useErrorHandler()
  const workerQueryKey = ['getWorkerById', workerId]
  const affiliationQueryKey = ['getAffiliationById', workerId]
  const { workerQuery, workerMutation } = useWorkerService({
    serviceParams: { queryKey: workerQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId),
    },
  })
  const { affiliationsQuery, affiliationsMutation } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId,
    },
    queryOptions: {
      enabled: Boolean(workerId),
      onError: (error) => {
        // Avoid displaying the error alert when the error is 0001, since it means that the affiliation does not exist yet
        if (error?.errors?.[0] && error.errors[0]?.code !== '0001') {
          handleError(error)
        }
      },
    },
    mutationOptions: {
      // Avoid displaying the error about administrators' credentials, as the custom alert is displayed instead
      onError: (error) => {
        const isOnlyEntitiesErrors = error.errors?.every((errorItem) =>
          ['3008', '3009', '3010', '3011', '3012'].includes(errorItem.code)
        )

        if (!isOnlyEntitiesErrors) {
          handleError(error)
        }
      },
    },
  })

  const companyInformationQueryKey = ['companyInformation', getCompanyId()]

  const { companyQuery } = useCompanyService({
    serviceParams: {
      queryKey: companyInformationQueryKey,
      searchParams: { only_files: true },
    },
  })

  const {
    data: {
      files: { bank_certificate_document: companyBankCertificate } = {},
    } = {},
  } = companyQuery || {}

  const { companyFilesMutation } = useCompanyFilesService()

  const currentWorker = workerQuery.data || {}
  const currentAffiliation = affiliationsQuery.data || {}

  const initialValues = getInitialValues({
    worker: currentWorker,
    affiliation: currentAffiliation,
    bankCertificateDocumentFile: companyBankCertificate,
  })

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((previousStep) => previousStep - 1)
    } else {
      handleClose()
    }
  }

  const handleAffiliationMutation = (data, onSuccess, onError) => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData: data,
      },
      {
        onSuccess,
        onError,
      }
    )
  }

  const handleBankCertificateUpload = (bankCertificateData) => {
    companyFilesMutation.mutate(
      {
        mutationMethod: 'PATCH',
        files: bankCertificateData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(companyInformationQueryKey)
        },
      }
    )
  }

  const handleConfirmationModal = () => {
    handleClose()
    modals.setLoadingModal(false)
    modals.openModal({
      id: 'confirmAffiliation',
      content: (
        <ConfirmAffiliation
          handleAffiliationMutation={handleAffiliationMutation}
          affiliationQueryKey={affiliationQueryKey}
        />
      ),
      modalProps: {
        hideFooter: true,
        paperSx: {
          maxWidth: '49rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
          position: 'relative',
        },
      },
    })
  }

  const onSubmit = (values) => {
    if (currentStep !== 2) {
      const workerDataHasChanged = formHasChanged(
        values,
        initialValues,
        currentStep
      )

      const entitiesToAffiliateHaveChanged =
        values.entities_to_affiliate &&
        !areObjectsEqual(
          values.entities_to_affiliate,
          currentAffiliation?.entities_to_affiliate
        )

      const contributorSubtypeHasChanged =
        values.contributor_subtype !== initialValues.contributor_subtype

      const affiliationData = new FormData()

      if (entitiesToAffiliateHaveChanged)
        affiliationData.append(
          'worker_entities_to_affiliate',
          JSON.stringify(
            getEntitiesWithMissingCredentialsKey(values.entities_to_affiliate)
          )
        )

      if (entitiesToAffiliateHaveChanged && !contributorSubtypeHasChanged) {
        handleAffiliationMutation(affiliationData, async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          if (!workerDataHasChanged) {
            if (currentStep < stepsData.length - 1) {
              setCurrentStep((previousStep) => previousStep + 1)
            }
          }
        })
      }

      const dirtyWorker = getDirtyWorker(values, currentStep)

      if (workerDataHasChanged) {
        workerMutation.mutate(
          {
            mutationMethod: 'PATCH',
            worker: { id: workerId, ...dirtyWorker },
            workerId,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(workerQueryKey, {
                refetchInactive: true,
                refetchActive: true,
                exact: false,
              })

              if (
                entitiesToAffiliateHaveChanged &&
                contributorSubtypeHasChanged
              ) {
                handleAffiliationMutation(affiliationData, async () => {
                  await queryClient.invalidateQueries(affiliationQueryKey)
                  if (currentStep < stepsData.length - 1) {
                    setCurrentStep((previousStep) => previousStep + 1)
                  }
                })
              } else if (currentStep < stepsData.length - 1) {
                setCurrentStep((previousStep) => previousStep + 1)
              }
            },
          }
        )
      } else if (
        currentStep < stepsData.length - 1 &&
        !entitiesToAffiliateHaveChanged
      ) {
        setCurrentStep((previousStep) => previousStep + 1)
      }
    } else if (currentStep === 2) {
      if (formHasChanged(values, initialValues, currentStep)) {
        const affiliationData = new FormData()

        if (values.bank_certificate_document instanceof File) {
          const bankCertificateData = new FormData()

          bankCertificateData.append(
            'bank_certificate_document',
            values.bank_certificate_document
          )

          handleBankCertificateUpload(bankCertificateData)
        }

        if (values.identification_document instanceof File) {
          affiliationData.append(
            'identification_document',
            values.identification_document
          )
        }

        if (values.contract_document instanceof File) {
          affiliationData.append('contract_document', values.contract_document)
        }

        if (values.comment && values.comment.length > 0) {
          affiliationData.append('comment', values.comment)
        } else {
          affiliationData.append('comment', '')
        }

        handleAffiliationMutation(affiliationData, async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          handleConfirmationModal()
        })
      } else {
        handleConfirmationModal()
      }
    }
  }

  return (
    <Box>
      {currentStep === 0 && affiliationStatus !== 'not_apply' ? (
        <Typography variant="h3">
          Se afiliará a {workerName} a la Seguridad Social
        </Typography>
      ) : currentStep !== 0 ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(2),
          })}
        >
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({
              marginTop: theme.spacing(1),
            })}
          >
            La información de la persona será utilizada para ayudarte a
            gestionar la afiliación a Seguridad Social, pensión y cesantías.
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          margin: theme.spacing(4, 0, 3, 0),
        })}
      >
        <NewSteps
          stepsData={stepsData}
          current={currentStep}
          progress={currentStep}
          onChangeStep={(step) => setCurrentStep(step)}
        />
      </Box>
      {(workerQuery.isLoading || affiliationsQuery.isLoading) &&
      currentStep === 0 ? (
        <LoadingBox />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema[currentStep]}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit, values, errors }) => {
            const formErros = Object.keys(errors || {}).length > 0

            const contractDocumentRequired = [
              'schooling_trainee',
              'productive_trainee',
              'intern',
              'student_decree_055',
              'student_law_789',
            ].includes(values?.contract_category)

            return (
              <Form>
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    marginBottom: theme.spacing(2),
                  })}
                  color="primary.dark"
                >
                  {stepsData[currentStep]?.pageTitle}
                </Typography>
                {currentStep === 0 ? <PersonalInformationStep /> : null}
                {currentStep === 1 ? (
                  <AffiliationDataStep worker={currentWorker} />
                ) : null}
                {currentStep === 2 ? <AttachDocumentsStep /> : null}
                <Box
                  sx={(theme) => ({
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(6),
                    flexWrap: 'wrap',
                    [theme.breakpoints.up('tablet')]: {
                      justifyContent: 'flex-start',
                    },
                  })}
                >
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    loading={
                      workerMutation.isLoading || affiliationsMutation.isLoading
                    }
                    disabled={
                      currentStep === 2 &&
                      (!values.identification_document ||
                        (contractDocumentRequired &&
                          !values.contract_document) ||
                        currentAffiliation?.status === 'waiting_response' ||
                        formErros)
                    }
                  >
                    Guardar y continuar
                  </Button>
                  <MButton variant="outlined" onClick={handleBack}>
                    {currentStep === 0 ? 'Cancelar' : 'Volver al paso anterior'}
                  </MButton>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

export default AffiliationForm
