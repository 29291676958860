import { Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

const ValidationMethod = () => {
  return (
    <>
      <Typography
        variant="body1"
        color="black.dark"
        sx={(theme) => ({ margin: theme.spacing(0, 0, 3) })}
      >
        Puedes agregarle Hiperseguridad a la firma de tu documento agregando{' '}
        <b>Firma con Selfie.</b> No es obligatorio, tiene la misma validez
        jurídica que la firma digital.
      </Typography>
      <FormField
        name="require_selfie_photo"
        variant="checkbox"
        checkboxLabel="Firma con selfie"
        checkboxSx={(theme) => ({
          border: `1px solid ${theme.palette.black.light}`,
          width: 'max-content',
          borderRadius: '3rem',
          paddingLeft: theme.spacing(2),
        })}
      />
    </>
  )
}

export default ValidationMethod
