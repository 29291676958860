import http, { getApiURL } from '../httpService'

export const getAllCompanies = ({ filter, search, page }) => {
  const url = getApiURL({
    pathname: '/companies',
    searchParams: { filter, search, page },
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getCompany = ({ companyId, searchParams }) => {
  const url = getApiURL({
    pathname: `/companies/${companyId}`,
    searchParams,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const updateCompany = (companyId, company) => {
  const url = getApiURL({
    pathname: `/companies/${companyId}`,
    withoutCompany: true,
  })

  const data = { company }
  return http.patchV2(url, data)
}

export const getWorkersFile = () => {
  const url = getApiURL({
    pathname: '/workers_file',
    searchParams: { async: true },
  })

  return http.getV2(url)
}

export const createCompany = (company) => {
  const url = getApiURL({ pathname: '/companies', withoutCompany: true })

  return http.postV2(url, company)
}

export const uploadFiles = (files) => {
  const url = getApiURL({ pathname: '/company_files' })

  return http.patchV2(url, files)
}

export const updateCredentials = (credentialsData) => {
  const url = getApiURL({ pathname: `/company_credentials` })

  return http.putV2(url, credentialsData)
}

export const getCredentials = () => {
  const url = getApiURL({
    pathname: `/company_credentials`,
  })

  return http.getV2(url)
}

export default {
  getAllCompanies,
  getWorkersFile,
  createCompany,
  getCompany,
  updateCompany,
  uploadFiles,
}
