import http, { getApiURL } from '../httpService'

export const updatePayroll = (payrollId, data) => {
  const url = getApiURL({ pathname: `/payrolls/${payrollId}` })

  return http.patchV2(url, data)
}

export const getPayslip = (payrollId, payslipType, payrollRange) => {
  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/payslip`,
    searchParams: { payslip_type: payslipType, payroll_range: payrollRange },
  })

  return http.getV2(url)
}

export const sendPayslip = (
  payrollId,
  payslipType,
  payrollRange,
  whatsappNotification
) => {
  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/payslip_mail`,
    searchParams: {
      payslip_type: payslipType,
      payroll_range: payrollRange,
      whatsapp_notification: whatsappNotification,
    },
  })

  return http.postV2(url)
}

export const getPayslipOptions = (payrollId) => {
  const url = getApiURL({ pathname: `/payrolls/${payrollId}/payslip_options` })
  return http.getV2(url)
}

export const getBankFileOptions = async (payrollId) => {
  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/bank_file_options`,
  })

  const { data } = await http.get(url)
  return data
}

export const getBankFile = async (
  payrollId,
  {
    payment_type: paymentType,
    pay_day: payDay,
    payroll_range: payrollRange,
    file_format: fileFormat,
  }
) => {
  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/bank_file`,
    searchParams: {
      payment_type: paymentType,
      pay_day: payDay,
      payroll_range: payrollRange,
      file_format: fileFormat,
    },
  })

  const response = await http.get(url)
  return response
}

export const getDetails = (payrollId, searchParams) => {
  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/view_details`,
    searchParams,
  })

  return http.getV2(url)
}

export const putConnectivityAid = (payrollId, quantity) => {
  const url = getApiURL({ pathname: `/payrolls/${payrollId}/connectivity_aid` })

  const dataReq = {
    connectivity_aid: { quantity },
  }

  return http.putV2(url, dataReq)
}

export default {
  updatePayroll,
  getPayslip,
  sendPayslip,
  getPayslipOptions,
  getBankFileOptions,
  getBankFile,
  getDetails,
  putConnectivityAid,
}
