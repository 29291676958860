import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import useAutoScroll from 'utils/hooks/useAutoScroll'

import SectionCard from '../SectionCard'
import EditPeriodHistoryModal from './Modals/EditPeriodHistoryModal'
import useEditPeriodHistoryModal from './useEditPeriodHistoryModal'

const EditPeriodHistory = ({ options = [] }) => {
  const [elementId] = useAutoScroll({
    elementId: 'edit_periods',
    auto: location.hash === '#edit_periods',
    options: {
      block: 'center',
    },
  })
  const {
    openEditPeriodHistoryModal,
    closeEditPeriodHistoryModal,
    editPeriodHistoryModalState,
  } = useEditPeriodHistoryModal()
  const { setFieldValue } = useFormikContext()

  const handleOnChange = (event) => {
    if (event.target.value === 'true') {
      openEditPeriodHistoryModal()
    } else {
      setFieldValue('edit_periods', false)
    }
  }

  const handleContinue = () => {
    setFieldValue('edit_periods', true)
    closeEditPeriodHistoryModal()
  }

  return (
    <Box sx={(theme) => ({ marginTop: theme.spacing(6) })}>
      <SectionCard
        subtitle="Edición de periodos históricos"
        titleId={elementId}
      >
        <Typography
          variant="body1"
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Activa la opción de Editar periodos históricos para realizar
          modificaciones en la liquidación de nómina, novedades, cálculos e IBC
          de periodos históricos ya cerrados de tu nómina. Ten en cuenta que
          esto puede modificar valores que ya se emitieron, pagaron y generaron.
        </Typography>
        <FormField
          name="edit_periods"
          variant="radio-group"
          options={options}
          row
          onChange={handleOnChange}
        />
      </SectionCard>
      {editPeriodHistoryModalState.open ? (
        <EditPeriodHistoryModal
          state={editPeriodHistoryModalState}
          handleClose={closeEditPeriodHistoryModal}
          handleContinue={handleContinue}
        />
      ) : null}
    </Box>
  )
}

export default EditPeriodHistory
