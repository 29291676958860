import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import Icon from 'components/UI/Icon'

const CheckboxField = ({ id, field, checkboxLabel, checkboxSx, ...props }) => {
  return (
    <Box sx={[checkboxSx]}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id={id}
              {...field}
              size="medium"
              checkedIcon={<Icon name="check-box-2" />}
              icon={<Icon name="check-box-1" basic />}
              {...props}
            />
          }
          label={checkboxLabel}
        />
      </FormGroup>
    </Box>
  )
}

export default CheckboxField
