import { useState } from 'react'

const initialState = {
  open: false,
  payment: {},
  isPreviousPayment: false,
  formData: {},
}

const useCurrentPaymentModal = () => {
  const [state, setState] = useState(initialState)

  const openModal = async (payment = {}, formData) => {
    setState({ open: true, payment, formData })
  }

  const closeModal = () => {
    setState(initialState)
  }

  return {
    currentPaymentModalState: state,
    openCurrentPaymentModal: openModal,
    closeCurrentPaymentModal: closeModal,
  }
}

export default useCurrentPaymentModal
