import { Box, Toolbar } from '@mui/material'

import ToolbarActions from './ToolbarActions'
import ToolbarSearch from './ToolbarSearch'

const TableToolbar = (props) => {
  const {
    setGlobalFilter,
    globalFilter,
    freeActions,
    showSearch,
    rows,
    searchProps,
    isNewToolbar,
    isAsyncData,
    asyncSearchFilter,
    setAsyncSearchFilter,
    gotoPage,
  } = props

  const leftActions = freeActions.filter((action) => action.position === 'left')
  const rightActions = freeActions.filter(
    (action) => action.position !== 'left'
  )

  return (
    <Toolbar
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.white.dark,
        padding: theme.spacing(2),
        ...(showSearch &&
          rightActions.length === 0 && {
            justifyContent: 'flex-start',
          }),
        ...(!showSearch &&
          leftActions.length === 0 && {
            justifyContent: 'flex-end !important',
          }),
        ...(isNewToolbar && {
          padding: `${theme.spacing(0, 0, 2, 0)} !important`,
          backgroundColor: 'transparent !important',
        }),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: '100%',
          flexDirection: 'column',
          [theme.breakpoints.up('tablet')]: {
            width: 'auto',
            flexDirection: 'row',
            flexWrap: 'wrap',
          },
        })}
      >
        {leftActions && leftActions.length > 0 ? (
          <ToolbarActions freeActions={leftActions} rows={rows} />
        ) : null}
        {showSearch ? (
          <ToolbarSearch
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            searchProps={searchProps}
            isAsyncData={isAsyncData}
            asyncSearchFilter={asyncSearchFilter}
            setAsyncSearchFilter={setAsyncSearchFilter}
            gotoPage={gotoPage}
          />
        ) : null}
      </Box>
      {rightActions && rightActions.length > 0 ? (
        <ToolbarActions freeActions={rightActions} />
      ) : null}
    </Toolbar>
  )
}

export default TableToolbar
