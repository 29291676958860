import { useInfiniteQuery, useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createDocument,
  createFolder,
  deleteDocument,
  deleteFolder,
  getAllAdminDocuments,
  getAllAdminFolders,
  getAllWorkerDocuments,
  getAllWorkerFolders,
  updateDocument,
  updateFolder,
} from 'services/worker/documentsService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, searchParams, workerId, folderId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getAllFolders')
    return getAllAdminFolders(workerId, searchParams)

  if (currentQueryKey === 'getAllDocuments')
    return getAllAdminDocuments(workerId, folderId, searchParams)

  if (currentQueryKey === 'getAllWorkerFolders')
    return getAllWorkerFolders(workerId, searchParams)

  if (currentQueryKey === 'getAllWorkerDocuments')
    return getAllWorkerDocuments(workerId, folderId, searchParams)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, folderId, data, documentId } = mutationData

  if (mutationMethod === 'POST_FOLDER') return createFolder(workerId, data)

  if (mutationMethod === 'POST_DOCUMENT')
    return createDocument(workerId, folderId, data)

  if (mutationMethod === 'DELETE_FOLDER')
    return deleteFolder(workerId, folderId)

  if (mutationMethod === 'DELETE_DOCUMENT')
    return deleteDocument(workerId, folderId, documentId)

  if (mutationMethod === 'PUT_DOCUMENT')
    return updateDocument(workerId, folderId, documentId, data)

  if (mutationMethod === 'PUT_FOLDER')
    return updateFolder(workerId, folderId, data)

  if (mutationMethod === 'GET_DOCUMENTS')
    return getAllAdminDocuments(workerId, folderId)

  return null
}
const useDocumentsService = ({
  serviceParams = { queryKey: 'getAllFolders' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restDocumentsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const documentsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    documentsQuery: {
      data,
      ...restDocumentsResponse,
    },
    documentsMutation,
  }
}

export const useInfiniteDocumentsService = ({
  serviceParams = { queryKey: 'getAllFolders' },
  queryOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const infiniteDocumentsQuery = useInfiniteQuery(
    serviceParams.queryKey,
    async ({ pageParam = 1 }) => {
      const { data = {}, filters = {} } = await getQueryFunction({
        ...serviceParams,
        searchParams: { page: pageParam },
      })

      const nextPage =
        filters.page * filters.per_page < filters.total_records
          ? filters.page + 1
          : null
      const previousPage = filters.page > 1 ? filters.page - 1 : null

      return {
        data,
        nextPage,
        previousPage,
        totalRecords: filters.total_records,
      }
    },
    {
      onError: (error) => handleError(error),
      ...queryOptions,
      getPreviousPageParam: (firstPage) => firstPage.previousPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    }
  )

  return {
    infiniteDocumentsQuery,
  }
}

export default useDocumentsService
