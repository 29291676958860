import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography, alpha } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import EditButton from 'components/UI/Button/EditButton'
import Icon from 'components/UI/Icon'
import useWorker from 'components/Worker/useWorker'

import { formatCurrency } from 'utils/format'
import useRetentionsConfigurationService from 'utils/hooks/worker/retentionConfigurationService'
import { isDataCached } from 'utils/reactQuery'

import RetentionConfigurationModal from '../Modals/RetentionConfigurationModal/RetentionConfigurationModal'

const RetentionConfigurationItem = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { worker } = useWorker({ useCache: true })
  const workerId = worker.id
  const queryClient = useQueryClient()
  const queryKey = ['retentionConfigurations', worker.id]
  const retentionConfigurationDataCache =
    queryClient.getQueryData(queryKey) || {}
  const { retentionsQuery } = useRetentionsConfigurationService({
    serviceParams: {
      queryKey,
      workerId,
    },
    queryOptions: {
      enabled:
        Boolean(workerId) &&
        worker.retention_configuration &&
        !isDataCached(retentionConfigurationDataCache),
    },
  })
  const retentionConfiguration = isDataCached(retentionConfigurationDataCache)
    ? retentionConfigurationDataCache.data
    : retentionsQuery.data

  const { isWorker } = useUser()

  const isRetentionActive = retentionConfigurationDataCache.data?.active

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(7),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        })}
      >
        <Typography variant="h5">Retención en la fuente</Typography>
        {!isWorker && !retentionsQuery.isLoading && !retentionsQuery.isError ? (
          <EditButton
            onClick={() =>
              !showPremiumFeatures
                ? openPremiumFeatureModal()
                : setIsModalOpen(true)
            }
            startIcon={<PremiumFeatureIcon />}
            data-cy="retention_configuration_button"
          />
        ) : null}
      </Box>
      {retentionConfiguration ? (
        <Box
          sx={(theme) => ({
            width: '100%',
            padding: theme.spacing(3),
            borderRadius: '1rem',
            backgroundColor: alpha(theme.palette.black.light, 0.2),
            ...(isRetentionActive && {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
            }),
          })}
        >
          <Box
            sx={(theme) => ({
              width: '5rem',
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              marginBottom: theme.spacing(1),
              color: theme.palette.black.light,
              ...(isRetentionActive && { color: theme.palette.primary.main }),
            })}
          >
            <Typography
              variant="h5"
              color={isRetentionActive ? 'primary.dark' : 'black.dark'}
            >
              {isRetentionActive ? 'Activa' : 'Inactiva'}
            </Typography>
            {isRetentionActive ? (
              <Icon
                name="approved-checked"
                basic
                sx={(theme) => ({
                  marginLeft: theme.spacing(0.5),
                  color: theme.palette.primary.dark,
                })}
              />
            ) : null}
          </Box>
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              [theme.breakpoints.down('tablet')]: {
                gridTemplateColumns: '1fr',
              },
              gap: theme.spacing(4),
              ...(!isRetentionActive && { color: theme.palette.black.dark }),
            })}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={(theme) => ({
                  marginBottom: theme.spacing(1),
                })}
              >
                <strong>Valor mensualizado</strong> de los intereses en
                préstamos
              </Typography>
              <Typography variant="h4">
                {formatCurrency(retentionConfiguration?.housing_interests)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={(theme) => ({
                  marginBottom: theme.spacing(1),
                })}
              >
                <strong>Valor mensualizado</strong> de los pagos a medicina
              </Typography>
              <Typography variant="h4">
                {formatCurrency(retentionConfiguration?.prepaid_medicine)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={(theme) => ({
                  marginBottom: theme.spacing(1),
                })}
              >
                Deducción por dependientes o personas a cargo
              </Typography>
              <Typography variant="h4">
                {retentionConfiguration?.dependents_deduction
                  ? 'Activa'
                  : 'Inactiva'}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {isModalOpen ? (
        <RetentionConfigurationModal
          handleClose={() => setIsModalOpen(false)}
        />
      ) : null}
    </Box>
  )
}

export default RetentionConfigurationItem
