import { Box, Typography } from '@mui/material'

import SvgLoading from './SvgLoading'

const Loading = ({ message = 'Cargando...' }) => {
  return (
    <Box
      data-cy="loading-page"
      sx={(theme) => ({
        width: '100%',
        paddingTop: theme.spacing(4),
        textAlign: 'center',
      })}
    >
      <SvgLoading />
      {message ? <Typography variant="h4">{message}</Typography> : null}
    </Box>
  )
}

export default Loading
