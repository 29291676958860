import { useEffect } from 'react'

export default function useAutoScroll({
  elementId,
  auto = false,
  options = {},
}) {
  // Auto scroll
  useEffect(() => {
    if (!auto) return

    const element = document.getElementById(elementId)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', ...options })
    }
  }, [auto, elementId, options])

  // Manual scroll
  function scrollToElement() {
    const element = document.getElementById(elementId)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', ...options })
    }
  }

  return [elementId, scrollToElement]
}
