import { HELP_CENTER } from 'config/organization'

export const helpOnClick = ({ openNotWorkingChatModal, setShowChat }) => {
  if (window.$zoho?.salesiq) {
    if (window.$zoho.salesiq.floatwindow) {
      setShowChat(true)
      window.$zoho.salesiq.floatwindow.visible('0.2')
    } else {
      openNotWorkingChatModal()
    }
  } else {
    window.open(HELP_CENTER, '_blank')
  }
}
