import { klona } from 'klona'

import { Box, Card, Typography, alpha } from '@mui/material'

import LordIcon from 'components/UI/LordIcon'
import { getTextEditor, removeEmptyRow } from 'components/UI/Spreadsheet'

const noveltiesTitles = {
  overtime: 'Extras y recargos',
  novelties: 'Vacac e Incap',
  licenses: 'Licencias',
  incomes: 'Ingresos',
  deductions: 'Deducciones',
  personal_note: 'Notas',
}

const noveltiesTitlesExtended = {
  deductions: 'Deducciones, préstamos y retefuente',
  incomes: 'Ingresos salariales y no salariales',
  licenses: 'Licencias remuneradas y no remuneradas',
  overtime: 'Horas extras y otros recargos',
  novelties: 'Vacaciones e incapacidades',
  personal_note: 'Notas',
}

export const ErrorInfoCard = ({ novelty, quantity }) => {
  return (
    <Card
      className="error_file"
      sx={(theme) => ({
        height: '3.5rem',
        maxWidth: '29.5rem',
        display: 'grid',
        gridTemplateColumns: '3.5rem auto',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        margin: theme.spacing(2, 0),
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette.error.light, 0.4),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          paddingTop: theme.spacing(0.75),
        })}
      >
        <LordIcon
          sx={{
            '& > lord-icon': {
              width: '2.5rem',
              height: '2.5rem',
            },
          }}
          name="document-error-red"
          target="error_file"
        />
      </Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 1),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: theme.spacing(2),
        })}
      >
        <Typography variant="lead2">{novelty}</Typography>
        <Typography variant="body2">
          Tienes <b>{quantity} errores</b> en la carga de estas novedades
        </Typography>
      </Box>
    </Card>
  )
}

const filterFailureCases = (bulkUploadResult) => {
  return Object.keys(bulkUploadResult)
    .map((novelty) => {
      if (bulkUploadResult[novelty]?.failure_cases_count > 0) {
        return {
          label: noveltiesTitles[novelty],
          extendedLabel: noveltiesTitlesExtended[novelty],
          failure_cases_count: bulkUploadResult[novelty].failure_cases_count,
          novelty,
        }
      }

      return false
    })
    .filter(Boolean)
}

export const validateIfDateValuesChange = (key, initialRow, currentRow) => {
  const initialDayKey = key.includes('initial_day')
    ? key.split('_initial_day')[0]
    : ''
  const endDayKey = key.includes('end_day') ? key.split('_end_day')[0] : ''

  const endDateChange =
    initialDayKey !== '' &&
    initialRow[initialDayKey.concat('_end_day')] !==
      currentRow[initialDayKey.concat('_end_day')]

  const initialDateChange =
    endDayKey !== '' &&
    initialRow[endDayKey.concat('_initial_day')] !==
      currentRow[endDayKey.concat('_initial_day')]

  return endDateChange || initialDateChange
}

export const getDirtyRows = (bulkUploadResult, newBulkUploadResult) => {
  const dirtyData = []
  const dateChangedKeys = []
  const crossDatesKeys = []

  Object.keys(newBulkUploadResult).forEach((novelty) => {
    const currentRowValues =
      newBulkUploadResult[novelty]?.failure_cases?.rows || []
    const initialRowValues =
      removeEmptyRow(bulkUploadResult[novelty]?.failure_cases?.rows) || []

    const skippedKeys = ['name', 'payroll_id', 'worker_id', 'worker_id_number']

    initialRowValues.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (skippedKeys.includes(key)) {
          return
        }

        const currentRowFound = currentRowValues.find(
          (currentRow) => currentRow.worker_id === row.worker_id
        )

        if (key === 'error_details') {
          Object.keys(row.error_details).forEach((errorDetailKey) => {
            const hasCrossDate = row.error_details[errorDetailKey].includes(
              'Una persona no puede tener más de una novedad en la misma fecha'
            )

            if (hasCrossDate) {
              if (crossDatesKeys.length === 0) {
                crossDatesKeys.push({
                  keys: [errorDetailKey],
                  worker_id: row.worker_id,
                })
              } else {
                crossDatesKeys.forEach(({ keys, worker_id: workerId }) => {
                  if (workerId === row.worker_id) {
                    keys.push(errorDetailKey)
                  }
                })
              }

              const existPersonCrossDates = crossDatesKeys.find(
                ({ worker_id: workerId }) => workerId === row.worker_id
              )

              if (!existPersonCrossDates) {
                crossDatesKeys.push({
                  keys: [errorDetailKey],
                  worker_id: row.worker_id,
                })
              }
            }
          })
        }

        if (validateIfDateValuesChange(key, row, currentRowFound)) {
          dateChangedKeys.push(row.worker_id)
          return
        }

        if (row[key] === currentRowFound[key]) {
          dirtyData.push({ key, worker_id: row.worker_id })
        }
      })
    })
  })

  if (dateChangedKeys.length > 0) {
    const filtered = crossDatesKeys?.filter(({ worker_id: workerId }) =>
      dateChangedKeys.includes(workerId)
    )

    return dirtyData.filter(
      ({ key, worker_id: dirtyWorkerId }) =>
        !filtered
          .find((crossDateKey) => crossDateKey.worker_id === dirtyWorkerId)
          ?.keys.some((crossDateKey) => crossDateKey === key)
    )
  }

  return dirtyData
}

const SummaryCell = ({ label }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {label}
    </Typography>
  )
}

const addNewKeys = (bulkUploadResult) => {
  const noveltiesCopy = klona(bulkUploadResult?.success || {})

  // Custom columns
  Object.keys(noveltiesCopy).forEach((novelty) => {
    const columns = noveltiesCopy[novelty]?.failure_cases?.columns

    if (columns) {
      const customColumns = columns?.map((column) => {
        const updatedColumn = {
          ...column,
          renderHeaderCell({ column: currentColumn }) {
            return <Box sx={{ textAlign: 'center' }}>{currentColumn.name}</Box>
          },
        }

        if (updatedColumn.key === 'name') {
          return updatedColumn
        }

        if (updatedColumn.key.includes('end_day')) {
          updatedColumn.mixed_column = false
        }

        return {
          ...updatedColumn,
          cellClass: () => undefined,
          renderSummaryCell({ column: summaryColumn }) {
            if (summaryColumn.key.includes('initial_day')) {
              return <SummaryCell label="Fecha inicial" />
            }
            if (summaryColumn.key.includes('end_day')) {
              return <SummaryCell label="Fecha final" />
            }
            return ''
          },
          colSpan(columnParams) {
            if (updatedColumn?.mixed_column && columnParams.type === 'HEADER') {
              return 2
            }
            return undefined
          },
          renderEditCell(cellParams) {
            if (!cellParams.row?.error_details[column.key]) {
              return null
            }

            return getTextEditor(cellParams)
          },
        }
      })

      noveltiesCopy[novelty].failure_cases.columns = customColumns
    }
  })

  // Custom rows
  Object.keys(noveltiesCopy).forEach((novelty) => {
    const rows = removeEmptyRow(noveltiesCopy[novelty]?.failure_cases?.rows)

    if (rows) {
      const customRows = rows?.map((row) => {
        return {
          ...row,
        }
      })

      noveltiesCopy[novelty].failure_cases.rows = customRows
    }
  })

  return noveltiesCopy
}

export const useDataProcessing = (bulkUploadResult = {}) => {
  const newBulkUploadResult = addNewKeys(bulkUploadResult)
  const failureCases = filterFailureCases(newBulkUploadResult)

  const {
    incomes: { failure_cases: failureCasesIncomes },
    licenses: { failure_cases: failureCasesLicenses },
    overtime: { failure_cases: failureCasesOvertime },
    novelties: { failure_cases: failureCasesNovelties },
    deductions: { failure_cases: failureCasesDeductions },
    personal_note: { failure_cases: failurePersonalNote },
  } = newBulkUploadResult || {}

  const {
    incomes: { failure_cases: initialFailureCasesIncomes },
    licenses: { failure_cases: initialFailureCasesLicenses },
    overtime: { failure_cases: initialFailureCasesOvertime },
    novelties: { failure_cases: initialFailureCasesNovelties },
    deductions: { failure_cases: initialFailureCasesDeductions },
    personal_note: { failure_cases: initialFailureCasesPersonalNote },
  } = bulkUploadResult.success || {}

  return {
    failureCases,
    newBulkUploadResult,
    extendedTitle: {
      show: failureCases.length === 1,
      label: noveltiesTitlesExtended[failureCases[0].novelty],
    },
    newFailureCases: {
      failureCasesIncomes,
      failureCasesLicenses,
      failureCasesOvertime,
      failureCasesNovelties,
      failureCasesDeductions,
      failurePersonalNote,
    },
    initialFailureCases: {
      initialFailureCasesIncomes,
      initialFailureCasesLicenses,
      initialFailureCasesOvertime,
      initialFailureCasesNovelties,
      initialFailureCasesDeductions,
      initialFailureCasesPersonalNote,
    },
  }
}
