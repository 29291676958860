import { Box, Button, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'

import aleluyaGirlMeditating from 'assets/images/views/common/aleluya_girl_meditating.svg'

const FreeTimeEmptyState = ({ handleNewRequest }) => {
  const { isWorker } = useUser()

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        backgroundColor: theme.palette.accent1.light,
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(4),
        [theme.breakpoints.down('tablet')]: { flexDirection: 'column' },
      })}
    >
      <Box
        component="img"
        sx={(theme) => ({
          maxHeight: '16.5rem',
          objectFit: 'contain',
          margin: theme.spacing(0, 3, 0, 0),
          [theme.breakpoints.down('tablet')]: {
            margin: theme.spacing(0, 0, 3, 0),
            maxHeight: '10rem',
          },
        })}
        src={aleluyaGirlMeditating}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
        >
          {isWorker
            ? 'No tienes solicitudes de tiempo fuera'
            : 'Esta persona no tiene solicitudes de tiempo fuera'}
        </Typography>
        {isWorker ? (
          <Button
            variant="outlined"
            onClick={handleNewRequest}
            endIcon={<Icon name="plus" basic />}
          >
            <Typography variant="lead1">Solicitar días libres </Typography>
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}

export default FreeTimeEmptyState
