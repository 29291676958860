import { addDays, parseISO } from 'date-fns'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'

import { formatDate } from 'utils/dateTime'

import messages from 'messages/wage'

// contractInitialDay prob no necessary
const InitialDayField = ({ currentWage, contractInitialDay, optional }) => {
  const { values, setFieldValue } = useFormikContext()

  let minInitialDay =
    values?.prevInitialDay || currentWage?.initial_day || contractInitialDay

  // if its not the currentWage
  if (values.id !== currentWage?.id)
    minInitialDay = addDays(parseISO(minInitialDay), 1)

  useEffect(() => {
    // default value
    if (!values.initial_day)
      setFieldValue('initial_day', formatDate(new Date()))
  }, [setFieldValue, values.initial_day])

  return (
    <FormField
      name="initial_day"
      label="Fecha inicial salario"
      variant="datepicker"
      disabled={values?.first}
      minDate={formatDate(minInitialDay)}
      tooltipContent={messages.initial_day}
      optional={optional}
    />
  )
}

export default InitialDayField
