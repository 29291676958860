import { Form, Formik } from 'formik'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import useUsersService from 'utils/hooks/settings/usersService'
import useNotifications from 'utils/hooks/useNotifications'

import { LOGIN } from 'config/routes'

import AuthLogo from '../common/AuthLogo'
import MainContainer from '../common/MainContainer'
import { initialValues, validationSchema } from './helpers'

const UnlockAccount = () => {
  const { showSuccessMessage } = useNotifications()
  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = ({ email }, form) => {
    usersMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'unlockAccount',
        user: { email },
      },
      {
        onSuccess: () => {
          showSuccessMessage(
            <Typography variant="body2">
              <b>Correo enviado exitosamente</b>
              <br />
              Si estás registrado en Aleluya, recibirás un mensaje para
              <br /> recuperar tu cuenta. Recuerda revisar tu{' '}
              <b>
                bandeja de <br />
                promociones y spam.
              </b>
            </Typography>
          )
          form.resetForm({})
        },
      }
    )
  }

  return (
    <MainContainer>
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          padding: theme.spacing(8, 2, 6, 2),
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(8, 8, 6, 8),
          },
          [theme.breakpoints.between('laptop', 'lg')]: {
            maxWidth: 'none',
            padding: theme.spacing(4, 6, 6, 6),
          },
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(4, 13.5, 6, 13.5),
            maxWidth: 'none',
          },
          [theme.breakpoints.up('desktop')]: {
            padding: theme.spacing(4, 2, 6, 2),
            maxWidth: '30rem',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(7.75),
          })}
        >
          <AuthLogo />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(6.75),
          })}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: 'left',
              width: '100%',
            }}
            gutterBottom
          >
            Recuperar cuenta
          </Typography>
          <Typography align="left">
            ¡No te preocupes! Si olvidaste tu contraseña simplemente ingresa el
            correo electrónico y te enviaremos las instrucciones para
            restablecerla.{' '}
            <strong>Recuerda revisar tu bandeja de promociones y spam.</strong>
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          <Form>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: theme.spacing(1, 0),
              })}
            >
              <Box
                sx={(theme) => ({
                  marginBottom: theme.spacing(4),
                })}
              >
                <FormField
                  type="email"
                  name="email"
                  label="Tu correo electrónico"
                  placeholder="Ingresa tu correo electrónico"
                  autoComplete="email"
                  optional={false}
                />
              </Box>
              <Button
                type="submit"
                loading={usersMutation.isLoading}
                sx={(theme) => ({
                  width: '100%',
                  marginBottom: theme.spacing(4),
                })}
              >
                Recuperar cuenta
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="body1" color="black.main">
                Regresar a{' '}
                <Link to={LOGIN} fontWeight={600} color="accent4.main">
                  Iniciar sesión
                </Link>
              </Typography>
            </Box>
          </Form>
        </Formik>
      </Box>
    </MainContainer>
  )
}

export default UnlockAccount
