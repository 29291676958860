import { Box, Typography } from '@mui/material'

import LordIcon from 'components/UI/LordIcon'

const BadgeRole = ({
  role,
  id,
  detail,
  isSelectedRole,
  handleSelectRole,
  iconName,
  dataCy,
}) => {
  return (
    <Box
      className={iconName}
      sx={(theme) => ({
        borderRadius: '1rem',
        border: `${theme.spacing(0.4)} solid ${theme.palette.primary.main}`,
        display: 'grid',
        gridTemplateColumns: '3.125rem 1fr',
        minHeight: '6.5rem',
        alignItems: 'center',
        padding: theme.spacing(1, 3),
        cursor: 'pointer',
        gap: theme.spacing(3),
        textAlign: 'left',
        fill: theme.palette.primary.main,
        ...(isSelectedRole && {
          backgroundColor: theme.palette.white.dark,
          outline: `1px solid ${theme.palette.primary.main}`,
        }),
      })}
      onClick={() => handleSelectRole(id)}
      id={id}
      role="button"
      data-cy={dataCy}
    >
      <LordIcon name={iconName} target={iconName} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="lead1">{role}</Typography>
        {detail ? (
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.palette.black.dark,
            })}
          >
            {detail}
          </Typography>
        ) : null}
      </Box>
    </Box>
  )
}

export default BadgeRole
