import { useMutation, useQuery } from 'react-query'

import { getAllAreas, updateArea } from 'services/company/areaService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams

  if (queryKey === 'companyAreas') return getAllAreas()

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, data } = mutationData

  if (mutationMethod === 'PUT') return updateArea(data)

  return null
}

const useAreaService = ({
  queryOptions = {},
  serviceParams = { queryKey: 'companyAreas' },
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restArea } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const areaMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    areaQuery: { data, ...restArea },
    areaMutation,
  }
}

export default useAreaService
