import FilterButton from 'components/UI/Button/FilterButton'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency, formatNumber } from 'utils/format'

import * as routes from 'config/routes'

import DownloadNoveltiesAction from './DownloadNoveltiesAction'

export const getColumnsData = (noveltyType, fromPeriod) => {
  let columnsData = []
  switch (noveltyType) {
    case 'novelties':
      columnsData = [
        {
          Header: 'Tipo novedad',
          accessor: 'name',
        },
        {
          Header: 'Fecha de inicio',
          accessor: 'initial_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.initial_date),
        },
        {
          Header: 'Fecha de fin',
          accessor: 'end_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.end_date),
        },
        {
          Header: 'Valor',
          accessor: 'value',
          Cell: ({ row }) => formatCurrency(row.original.value),
        },
      ]
      break
    case 'overtime':
      columnsData = [
        {
          Header: 'Tipo',
          accessor: 'name',
        },
        {
          Header: 'Cantidad',
          accessor: 'quantity',
          Cell: ({ row }) => formatNumber(row.original.quantity),
        },
        {
          Header: 'Fecha',
          accessor: 'end_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.end_date),
        },
        {
          Header: 'Valor',
          accessor: 'value',
          Cell: ({ row }) => formatCurrency(row.original.value),
        },
      ]
      break
    case 'contract_novelties':
      columnsData = [
        {
          Header: 'Tipo',
          accessor: 'name',
          Cell: ({ row }) =>
            row.original.name == 'Terminacion de contrato'
              ? 'Terminación de contrato'
              : row.original.name,
        },
        {
          Header: 'Fecha',
          accessor: 'date ',
          Cell: ({ row }) => formatDisplayDateString(row.original.date),
        },
        {
          Header: 'Valor',
          accessor: 'value',
          Cell: ({ row }) =>
            row.original.value == 0 ? '' : formatCurrency(row.original.value),
        },
      ]
      break
    default:
      columnsData = [
        {
          Header: 'Tipo',
          accessor: 'name',
        },
        {
          Header: 'Fecha',
          accessor: 'end_date',
          Cell: ({ row }) => formatDisplayDateString(row.original.end_date),
        },
        {
          Header: 'Valor',
          accessor: 'value',
          Cell: ({ row }) => formatCurrency(row.original.value),
        },
      ]
      break
  }

  if (fromPeriod) {
    // append workerName to
    columnsData.unshift({
      Header: 'Personas',
      accessor: 'worker_name',
      Cell: ({ row }) => (
        <Link
          to={routes.WORKER_SHOW(row.original.worker_id)}
          color="black.main"
        >
          {row.original.worker_name}
        </Link>
      ),
    })
  }

  return columnsData
}

export const optionsData = [
  {
    label: 'Novedades de contrato',
    value: 'contract_novelties',
  },
  {
    label: 'Vacac. Incap. y Licen.',
    value: 'novelties',
  },
  {
    label: 'Extras y Recargos',
    value: 'overtime',
  },
  {
    label: 'Ingresos Constitutivos',
    value: 'salary_income',
  },
  {
    label: 'Ingresos No Constitutivos',
    value: 'non_salary_income',
  },
  {
    label: 'Deducciones',
    value: 'deductions',
  },
]

export const getActions = (activeNoveltyType, handleChangeNovelty) => {
  return [
    {
      id: 'downloadNovelties',
      isFreeAction: true,
      Component: <DownloadNoveltiesAction />,
    },
    {
      id: 'filterNoveltyType',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          options={optionsData}
          defaultLabel="Tipo de novedad"
          value={activeNoveltyType}
          onChange={handleChangeNovelty}
        />
      ),
    },
  ]
}
