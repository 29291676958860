import { Box, Typography } from '@mui/material'

const StatsCounter = ({ label, icon, total }) => {
  return (
    <Box
      sx={(theme) => ({
        alignItems: 'center',
        backgroundColor: theme.palette.accent4.dark,
        borderRadius: '1rem',
        color: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        overflow: 'hidden',
        padding: theme.spacing(3),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      })}
    >
      <Box
        sx={(theme) => ({
          alignItems: 'center',
          display: 'flex',
          gap: theme.spacing(1),
        })}
      >
        {icon}
        <Typography
          variant="h6"
          title={label}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
          }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        title={total}
        variant="lead1"
        sx={{
          fontSize: '4.5rem',
          fontWeight: 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {total || 0}
      </Typography>
    </Box>
  )
}

export default StatsCounter
