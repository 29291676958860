import { useFormikContext } from 'formik'
import { createContext, useContext } from 'react'

import { Tooltip } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import LordIcon from 'components/UI/LordIcon'
import DSLink from 'components/UI/MaterialUI/Link'

import {
  calculateNumberBetwenLimits,
  formatCurrency,
  generateBasicNumberParser,
} from 'utils/format'

import { PERIOD_PAYROLL_VIEW_DETAILS, WORKER_SHOW } from 'config/routes'

const currencyValueParser = generateBasicNumberParser({ max: 13 })
const daysParser = generateBasicNumberParser({ max: 3 })

const handleChangeBaseValueAndDays = (form, _, category, newValues) => {
  const currentSocialBenefit = form.values

  const baseValue = newValues.base_value ?? currentSocialBenefit.base_value
  const days = newValues.days ?? currentSocialBenefit.days

  const calculation =
    category === 'severance_interests' ? baseValue : (baseValue * days) / 360

  const newValue =
    category === 'severance_interests'
      ? Math.round(calculation * 0.12 * (days / 360))
      : Math.round(calculation)

  const { paid_value: paidValue, item_value: itemValue } = currentSocialBenefit

  let newBalance = newValue - paidValue - itemValue
  let newItemValue = itemValue

  // to prevent negative balance
  if (newBalance < 0) {
    newBalance = 0
  }

  if (currentSocialBenefit.initial_item_value > 0) {
    newBalance = newValue - paidValue - itemValue
  }

  // to prevent negative item_value
  if (newItemValue < 0) {
    newItemValue = 0
  }

  form.setValues({
    ...currentSocialBenefit,
    base_value: baseValue,
    days,
    value: newValue,
    balance: newBalance,
    item_value: newItemValue,
  })
}

export const getMessage = (category) => {
  if (category === 'service_bonus') return 'la Prima de servicios'
  if (category === 'severance') return 'las Cesantías'
  if (category === 'severance_interests') return 'los Intereses a las cesantías'

  return null
}

export const getMessagesAlerts = (label, category, action) => {
  const titlesBenefits = {
    service_bonus: `La ${label}`,
    severance: `Las ${label}`,
    severance_interests: `Los ${label}`,
  }

  const messages = {
    service_bonus: {
      recalculate: {
        inprogress: `${titlesBenefits[category]} se está recalculando para todas las personas.`,
        success: `Se ha recalculado ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
      paidBenefits: {
        inprogress: `${titlesBenefits[category]} está en proceso de liquidación para todas las personas.`,
        success: `Liquidaste ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
      deleteBenefits: {
        inprogress: `${titlesBenefits[category]} está en proceso de eliminación`,
        success: `Eliminaste ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
    },
    severance: {
      recalculate: {
        inprogress: `${titlesBenefits[category]} se están recalculando para todas las personas.`,
        success: `Se han recalculado ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
      paidBenefits: {
        inprogress: `${titlesBenefits[category]} están en proceso de liquidación para todas las personas.`,
        success: `Liquidaste ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
      deleteBenefits: {
        inprogress: `${titlesBenefits[category]} están en proceso de eliminación`,
        success: `Eliminaste ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
    },
    severance_interests: {
      recalculate: {
        inprogress: `${titlesBenefits[category]} se están recalculando para todas las personas.`,
        success: `Se han recalculado ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
      paidBenefits: {
        inprogress: `${titlesBenefits[category]} están en proceso de liquidación para todas las personas.`,
        success: `Liquidaste ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
      deleteBenefits: {
        inprogress: `${titlesBenefits[category]} están en proceso de eliminación`,
        success: `Eliminaste ${titlesBenefits[
          category
        ]?.toLowerCase()} de todas las personas`,
      },
    },
  }

  const benefitsMessages = messages[category]

  return benefitsMessages[action]
}

export const socialBenefitAction = {
  service_bonus: 'prima',
  severance: 'cesantías',
  severance_interests: 'intereses',
}

export const getColumnsData = (category) => {
  if (!category) return []

  const maxDays = category === 'service_bonus' ? 180 : 360

  const columns = [
    {
      Header: 'Personas',
      accessor: 'worker_name',
      Cell: ({ row }) => (
        <DSLink to={WORKER_SHOW(row.original.worker_id)} color="black.main">
          {row.original.worker_name}
        </DSLink>
      ),
    },
    {
      Header: `Base ${socialBenefitAction[category]}`,
      accessor: 'base_value',
      Cell: ({ row }) => formatCurrency(row.original.base_value),
      editable: true,
      Edit: ({ row }) => {
        const rowIndex = row.index
        const form = useFormikContext()

        return (
          <FormField
            name="base_value"
            variant="currency"
            isNumericString
            onValueChange={({ value }) => {
              const baseValue = currencyValueParser(value)

              handleChangeBaseValueAndDays(form, rowIndex, category, {
                base_value: baseValue,
              })
            }}
            startAdornment={null}
            prefix="$ "
            disabled={form.values?.value !== form.values?.balance}
            sx={(theme) => ({
              padding: theme.spacing(0, 1),
            })}
          />
        )
      },
    },
    {
      Header: 'Días trabajados',
      accessor: 'days',
      Cell: ({ row }) => row.original.days,
      editable: true,
      Edit: ({ row }) => {
        const rowIndex = row.index
        const form = useFormikContext()

        return (
          <FormField
            name="days"
            variant="number"
            isNumericString
            onValueChange={({ value }) => {
              const days = calculateNumberBetwenLimits(
                daysParser(value),
                0,
                maxDays
              )

              handleChangeBaseValueAndDays(form, rowIndex, category, {
                days,
              })
            }}
            inputProps={{ style: { textAlign: 'right' } }}
            disabled={form.values?.value !== form.values?.balance}
          />
        )
      },
      noWrapCell: true,
    },
    {
      Header: `Valor ${socialBenefitAction[category]}`,
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
      editable: true,
      Edit: ({ rowValues }) => formatCurrency(rowValues?.value),
    },
    {
      Header: 'Saldo pendiente',
      accessor: 'balance',
      Cell: ({ row }) => formatCurrency(row.original.balance),
      editable: true,
      Edit: ({ rowValues }) => formatCurrency(rowValues?.balance),
    },
    {
      Header: 'Valor a pagar',
      accessor: 'item_value',
      Cell: ({ row }) => formatCurrency(row.original.item_value),
      editable: true,
      Edit: () => {
        const form = useFormikContext()

        const {
          value = 0,
          paid_value: paidValue = 0,
          item_value: itemValue = 0,
          balance = 0,
        } = form.values || {}
        const currentBalance = value - paidValue

        return (
          <FormField
            name="item_value"
            variant="currency"
            isNumericString
            onValueChange={({ value: inputValue }) => {
              let parsedValue = currencyValueParser(inputValue)

              if (parsedValue > currentBalance) {
                parsedValue = currentBalance
              }

              const newBalance = value - paidValue - parsedValue

              form.setValues({
                ...form.values,
                balance: newBalance,
                item_value: parsedValue,
              })
            }}
            startAdornment={null}
            prefix="$ "
            sx={(theme) => ({
              padding: theme.spacing(0, 1),
            })}
            disabled={balance === 0 && itemValue === 0}
          />
        )
      },
    },
  ]

  if (category === 'service_bonus') {
    columns.push({
      Header: 'Retefuente',
      accessor: 'service_bonus_retention',
      editable: true,
      Cell: ({ row }) => formatCurrency(row.original.service_bonus_retention),
      Edit: ({ rowValues }) => {
        return (
          <FormField
            name="service_bonus_retention"
            variant="currency"
            isNumericString
            startAdornment={null}
            prefix="$ "
            sx={(theme) => ({
              padding: theme.spacing(0, 1),
            })}
            disabled={rowValues?.item_value === 0}
          />
        )
      },
    })
  }

  return columns
}

export const socialBenefitTitle = {
  service_bonus: 'Liquida la',
  severance: 'Liquida las',
  severance_interests: 'Liquida los',
}

export const SocialBenefitsPeriodContext = createContext({})
SocialBenefitsPeriodContext.displayName = 'SocialBenefitsPeriodContext'

export const useSocialBenefitsPeriod = () => {
  const context = useContext(SocialBenefitsPeriodContext)

  if (context === undefined) {
    throw new Error(
      'useSocialBenefitsPeriod must be used within a SocialBenefitsPeriodContext provider'
    )
  }

  return context
}

export const SocialBenefitsPeriodAPIContext = createContext({})
SocialBenefitsPeriodAPIContext.displayName = 'SocialBenefitsPeriodAPIContext'

export const useSocialBenefitsPeriodAPI = () => {
  const context = useContext(SocialBenefitsPeriodAPIContext)

  if (context === undefined) {
    throw new Error(
      'useSocialBenefitsPeriodAPI must be used within a SocialBenefitsPeriodAPIContext provider'
    )
  }

  return context
}

const DownloadSummary = ({ endFreeSocialBenefitsModal }) => {
  const { handleDownloadFile } = useSocialBenefitsPeriodAPI()

  return (
    <Tooltip
      arrow
      title="Presiona este botón para descargar un resumen en Excel del cálculo de esta prestación social, los valores pagados y por pagar para cada persona."
    >
      <Button
        variant="outlined"
        endIcon={<Icon name="download" basic />}
        onClick={
          endFreeSocialBenefitsModal.isNotValidCompany
            ? () => endFreeSocialBenefitsModal.openEndFreeSocialBenefitsModal()
            : handleDownloadFile
        }
      >
        Descargar resumen
      </Button>
    </Tooltip>
  )
}

const PayBenefit = ({ label, category, endFreeSocialBenefitsModal }) => {
  const { handleSaveAllSocialBenefits } = useSocialBenefitsPeriodAPI()

  return (
    <Tooltip
      arrow
      title={`Al Liquidar verás reflejada ${getMessage(
        category,
        label
      )} en el resumen de nómina, las colillas de pago y el archivo para pago en banco.`}
    >
      <Button
        endIcon={<Icon name="coin" basic />}
        onClick={
          endFreeSocialBenefitsModal.isNotValidCompany
            ? () => endFreeSocialBenefitsModal.openEndFreeSocialBenefitsModal()
            : () => handleSaveAllSocialBenefits(label, category)
        }
        data-cy="pay-benefits"
      >
        Liquidar {socialBenefitAction[category] || ''}
      </Button>
    </Tooltip>
  )
}

const DeleteSocialBenefit = ({ label, category }) => {
  const { handleDeleteAllSocialBenefits } = useSocialBenefitsPeriodAPI()

  return (
    <Tooltip
      arrow
      title="Presiona este botón para eliminar el pago de esta prestación social."
    >
      <Button
        variant="outlined"
        endIcon={<Icon name="trash" basic />}
        onClick={() => handleDeleteAllSocialBenefits(label, category)}
      >
        Eliminar pago {socialBenefitAction[category] || ''}
      </Button>
    </Tooltip>
  )
}

const ResetValues = ({ label, category }) => {
  const { handleRecalculateAllSocialBenefits } = useSocialBenefitsPeriodAPI()

  return (
    <Tooltip
      arrow
      title="Al presionar este botón, Aleluya recalculará el saldo a pagar para todas las personas con base en la información a la fecha."
    >
      <Button
        variant="outlined"
        endIcon={<Icon name="refresh" basic />}
        onClick={() => handleRecalculateAllSocialBenefits(label, category)}
      >
        Reiniciar valores
      </Button>
    </Tooltip>
  )
}

export const getActions = ({
  action,
  category,
  label,
  handleRecalculateIndividualSocialBenefit,
  socialBenefitPeriodId,
  endFreeSocialBenefitsModal,
}) => {
  return [
    {
      id: 'downloadSummary',
      isFreeAction: true,
      Component: (
        <DownloadSummary
          endFreeSocialBenefitsModal={endFreeSocialBenefitsModal}
        />
      ),
    },
    {
      id: 'deleteSocialBenefit',
      isFreeAction: true,
      hidden: action === 'create',
      Component: <DeleteSocialBenefit label={label} category={category} />,
    },
    {
      id: 'resetValues',
      isFreeAction: true,
      hidden: action === 'create',
      Component: <ResetValues label={label} category={category} />,
    },
    {
      id: 'payBenefit',
      isFreeAction: true,
      Component: (
        <PayBenefit
          label={label}
          category={category}
          endFreeSocialBenefitsModal={endFreeSocialBenefitsModal}
        />
      ),
    },
    {
      id: 'editWorkerSocialBenefits',
      tooltip:
        'Puedes realizar acciones individuales sobre las personas que requieran adelantos de prima o liquidaciones antes de tiempo.',
      icon: <Icon name="edit-pencil" basic />,
      customUpdateAction: true,
    },
    (rowData) => ({
      id: 'recalculate',
      icon: <Icon name="refresh" basic />,
      tooltip: 'Recalcular',
      disabled: rowData.id === null,
      buttonProps: {
        color: 'complementary1',
        size: 'medium',
      },
      onClick: () => handleRecalculateIndividualSocialBenefit(rowData),
    }),
    {
      id: 'showCalculations',
      icon: <Icon name="eye" basic />,
      tooltip: 'Ver cálculos',
      buttonProps: {
        color: 'complementary1',
        size: 'medium',
      },
      onClick: endFreeSocialBenefitsModal.isNotValidCompany
        ? () => endFreeSocialBenefitsModal.openEndFreeSocialBenefitsModal()
        : (rowData) => {
            window.open(
              `${PERIOD_PAYROLL_VIEW_DETAILS(
                rowData.payroll_id,
                socialBenefitPeriodId
              )}?selected_tab=social_benefits`
            )
          },
    },
  ]
}

export const dateRangeConfiguration = {
  service_bonus: {
    adornment: 'asterisk',
    color: 'primary',
    adornmentColor: 'primary.dark',
  },
  severance: {
    adornment: 'spring',
    color: 'accent2',
    adornmentColor: 'accent2.dark',
  },
  severance_interests: {
    adornment: 'explosion',
    color: 'complementary2.light',
    adornmentColor: 'complementary2.dark',
  },
}

export const helpLinksConfiguration = {
  service_bonus: {
    tutorial_1_title: '¿Cómo liquidar la prima?',
    tutorial_2_title: '¿Cómo reiniciar valores?',
    tutorial_1_link:
      'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/paga-la-prima-de-servicios-a-tus-empleados-con-aleluya',
    tutorial_2_link:
      'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/paga-la-prima-de-servicios-a-tus-empleados-con-aleluya',
    tutorial_1_color: 'accent4.light',
    tutorial_2_color: 'accent1.light',
    tutorial_1_icon: <LordIcon name="document" target="tutorial_1" />,
    tutorial_2_icon: <LordIcon name="refresh" target="tutorial_2" />,
  },
  severance: {
    tutorial_1_title: '¿Cómo liquidar las cesantías?',
    tutorial_2_title: '¿Cómo reiniciar valores?',
    tutorial_1_link:
      'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/paga-tus-prestaciones-sociales-con-aleluya',
    tutorial_2_link:
      'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/edita-la-liquidación-de-las-cesantías-en-aleluya',
    tutorial_1_color: 'complementary1',
    tutorial_2_color: 'complementary2',
    tutorial_1_icon: <LordIcon name="document" target="tutorial_1" />,
    tutorial_2_icon: <LordIcon name="refresh" target="tutorial_2" />,
  },
  severance_interests: {
    tutorial_1_title: '¿Cómo liquidar los intereses?',
    tutorial_2_title: '¿Cómo reiniciar valores?',
    tutorial_1_link:
      'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/liquida-los-intereses-de-cesantías-de-tus-empleados-con-aleluya',
    tutorial_2_link:
      'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/edita-la-liquidación-de-los-intereses-a-las-cesantías-en-aleluya',
    tutorial_1_color: 'primary',
    tutorial_2_color: 'accent3.light',
    tutorial_1_icon: <LordIcon name="document" target="tutorial_1" />,
    tutorial_2_icon: <LordIcon name="refresh" target="tutorial_2" />,
  },
}
