import { Box, ButtonBase, Link, Paper, Typography } from '@mui/material'

import LordIcon from 'components/UI/LordIcon'

const TutorialButtonCard = ({
  openHelpModal,
  article,
  link,
  dataCy,
  lordIcon,
  backgroundColor,
  cardTitle,
}) => {
  return (
    <Paper
      className={lordIcon}
      elevation={2}
      sx={(theme) => ({
        display: 'flex',
        minHeight: '5rem',
        maxHeight: '5rem',
        maxWidth: '17rem',
        borderRadius: '0.5rem',
        [theme.breakpoints.up('tablet')]: {
          marginLeft: theme.spacing(2),
        },
      })}
    >
      <Box
        sx={{
          backgroundColor,
          borderTopLeftRadius: '0.5rem',
          borderBottomLeftRadius: '0.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '4.5rem',
        }}
      >
        <LordIcon
          name={lordIcon}
          target={lordIcon}
          sx={{
            '& > lord-icon': {
              width: '3rem',
              height: '3rem',
            },
          }}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(1.5, 1),
          minWidth: '12.5rem',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        })}
      >
        <Typography variant="lead2">{cardTitle}</Typography>
        <ButtonBase
          component={article ? Link : ButtonBase}
          sx={{
            alignItems: 'flex-start',
            height: 'fit-content',
          }}
          onClick={!article ? openHelpModal : null}
          href={article ? link : null}
          target="_blank"
          data-cy={dataCy}
        >
          {!article ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                columnGap: theme.spacing(1.63),
              })}
            >
              <Typography
                variant="lead1"
                color="accent4.main"
                textDecoration="underline"
                sx={{
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Ver tutorial
              </Typography>
            </Box>
          ) : null}
          {article ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                columnGap: theme.spacing(1.63),
              })}
            >
              <Typography
                variant="lead1"
                color="accent4.main"
                sx={{
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Ver artículo
              </Typography>
            </Box>
          ) : null}
        </ButtonBase>
      </Box>
    </Paper>
  )
}

export default TutorialButtonCard
