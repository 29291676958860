import { Link as MuiLink } from '@mui/material'

import Link from './Link'

const TooltipLink = ({
  children,
  variant = 'url',
  to,
  href,
  target = '_blank',
  state,
}) => {
  if (variant === 'navigation') {
    return (
      <Link
        sx={(theme) => ({
          fontWeight: 'bold',
          color: theme.palette.accent4.main,
        })}
        to={to}
        state={state}
      >
        {children}
      </Link>
    )
  }

  return (
    <MuiLink
      sx={(theme) => ({
        fontWeight: 'bold',
        color: theme.palette.accent4.main,
      })}
      href={href}
      target={target}
      color="accent4.main"
    >
      {children}
    </MuiLink>
  )
}

export default TooltipLink
