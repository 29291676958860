import { Box } from '@mui/material'

import CreditCardExpirationDate from 'components/Subscription/SubscriptionFormFields/CreditCardExpirationDate'
import CreditCardNumberField from 'components/Subscription/SubscriptionFormFields/CreditCardNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { CreditCardPlaceholder } from './CreditCardPlaceholder'

export const CreditCardFields = () => {
  return (
    <>
      <CreditCardPlaceholder />
      <FormField
        name="credit_card_form.payer_name"
        optional={false}
        label="Nombre del titular"
        inputProps={{ autoComplete: 'cc-name', maxLength: 30 }}
      />
      <CreditCardNumberField
        showLogo={false}
        name="credit_card_form.card_number"
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            flexDirection: 'row',
            alignItems: 'flex-end',
          },
        })}
      >
        <FormField
          name="credit_card_form.security_code"
          optional={false}
          label="Código de seguridad (CVV)"
          variant="number"
          placeholder="000"
          format="####"
          inputProps={{ autoComplete: 'cc-csc' }}
        />
        <CreditCardExpirationDate name="credit_card_form.expiration_date" />
      </Box>
    </>
  )
}
