import { Typography } from '@mui/material'

export const getColumnsDataIndex = () => {
  return [
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({ row }) => row.original.worker.name,
      customWidth: '250px',
    },
    {
      Header: 'Detalles',
      accessor: 'details',
      Cell: ({ row }) =>
        row.original.message.details && row.original.message.details.length > 0
          ? row.original.message.details.map((detail, index) => {
              const key = `detail_${index}`
              return (
                <Typography
                  key={key}
                  variant="body1"
                  sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                >
                  {detail}
                </Typography>
              )
            })
          : null,
      customWidth: '200px',
      cellDirection: 'column',
    },
    {
      Header: 'Motivo del rechazo',
      accessor: 'error',
      Cell: ({ row }) => (
        <Typography
          title={row.original.message.error}
          sx={{
            width: '22rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row.original.message.error}
        </Typography>
      ),
    },
  ]
}

export const columnsDataDetail = [
  {
    Header: 'Error',
    accessor: 'item',
    Cell: ({ row }) => row.original.item,
    customWidth: '100px',
  },
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }) => row.original.detail,
  },
]
