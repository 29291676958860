import { Box, useMediaQuery } from '@mui/material'

import AuthSlideShow from './SlideShow'

const MainContainer = ({ children, isFromRecoveryPassword = false }) => {
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up('laptop'))

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        backgroundColor: theme.palette.white.main,
        justifyItems: 'center',
        minHeight: '100%',
        ...(isFromRecoveryPassword
          ? {
              gridTemplateColumns: '1fr',
              '& > div > form > div': {
                marginBottom: theme.spacing(3),
              },
              '& > div, form': {
                marginBottom: theme.spacing(0),
              },
            }
          : {
              [theme.breakpoints.up('laptop')]: {
                gridTemplateColumns: '1fr 54%',
                padding: theme.spacing(0),
              },
            }),
      })}
    >
      {children}
      {desktopUp && !isFromRecoveryPassword ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          <AuthSlideShow />
        </Box>
      ) : null}
    </Box>
  )
}

export default MainContainer
