import { useLayoutEffect, useRef, useState } from 'react'

import { Box, useTheme } from '@mui/material'

import Adornment from './Adornment'

const ADORNMENT_WIDTH = 210
const ADORNMENT_MARGIN = 64

const Pattern = ({
  color = 'complementary1',
  patternColor = 'black',
  adornment = '',
  transform = null,
  patternSx,
} = {}) => {
  const themeInstance = useTheme()
  const colorKeys = color.split('.')
  const backgroundColor = color?.includes('.')
    ? themeInstance.palette[colorKeys[0]][colorKeys[1]]
    : themeInstance.palette[colorKeys[0]]?.main
  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)

  useLayoutEffect(() => {
    setContainerWidth(containerRef.current.offsetWidth)
  }, [])

  return (
    <Box
      sx={[
        {
          backgroundColor,
          height: '1.5rem',
          width: '100%',
          position: 'absolute',
          transform,
          overflow: 'hidden',
          zIndex: 2,
          ...(adornment.includes('bottom') ? { bottom: 0 } : { top: 0 }),
        },
        patternSx,
      ]}
    >
      <Box
        ref={containerRef}
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: theme.spacing(1),
        })}
      >
        {new Array(
          Math.ceil(containerWidth / (ADORNMENT_WIDTH - ADORNMENT_MARGIN)) + 2
        )
          .fill(0)
          .map((_, index) => (
            <Adornment
              key={index.toString()}
              variant="signature"
              width={210}
              height={282}
              color={patternColor}
              sx={(theme) => ({
                top: 0,
                transform: 'rotate(180deg)',
                position: 'static',
                marginLeft: `-${
                  ADORNMENT_MARGIN / theme.typography.htmlFontSize
                }rem`,
              })}
            />
          ))}
      </Box>
    </Box>
  )
}

export default Pattern
