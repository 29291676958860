/**
 * @param {(string|Array)} queryKey The queryKey to be evaluated
 * @returns {string} The base queryKey from a complex queryKey in array format
 */
export const getQueryKey = (queryKey) => {
  if (typeof queryKey === 'string') return queryKey

  if (typeof queryKey === 'object' && queryKey instanceof Array)
    return queryKey[0]

  throw new Error('An invalid queryKey was provided to this function')
}

/**
 * @param {(Array|Object)} data The data to be evaluated
 * @returns {boolean} True if the data is an object, false otherwise
 */

export const isDataCached = (data) => {
  if (data === undefined) return false
  if (data !== null && Array.isArray(data) && data.length === 0) return false
  if (
    data !== null &&
    typeof data === 'object' &&
    Object.keys(data).length === 0
  )
    return false

  return true
}
