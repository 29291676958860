import http, { getApiURL } from '../httpService'

export const getItemsByCategory = async (payrollId, category) => {
  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/items`,
    searchParams: { category },
  })

  return http.getV2(url)
}

export const updateDeductionItem = (payrollId, items) => {
  let requestItems = items

  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/items`,
  })

  if (Array.isArray(items)) requestItems = { items }

  return http.putV2(url, requestItems)
}
