import cardValidator from 'card-validator'
import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import { creditCardLogos } from 'components/Subscription/SubscriptionFormFields/CreditCardNumberField'

export const CreditCardPlaceholder = () => {
  const { values } = useFormikContext()
  const ccType = cardValidator.number(values?.credit_card_form.card_number).card
    ?.type

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        backgroundColor: theme.palette.white.light,
        borderRadius: '1rem',
        padding: theme.spacing(1, 3),
        gap: theme.spacing(2),
        border: `2px solid ${theme.palette.white.dark}}`,
        [theme.breakpoints.up('tablet')]: {
          minWidth: '19.5rem',
          height: '10.3rem',
          padding: theme.spacing(2, 3),
        },
      })}
    >
      <Box
        component={ccType && creditCardLogos[ccType] ? 'img' : 'div'}
        src={creditCardLogos[ccType]}
        alt="Franquicia de la tarjeta de crédito/débito"
        sx={(theme) => ({
          backgroundColor: theme.palette.white.dark,
          borderRadius: '0.5rem',
          padding: theme.spacing(1),
          width: '4rem',
          height: '3rem',
          minHeight: '3rem',
        })}
      />
      <Typography
        variant="h4"
        sx={(theme) => ({
          color: theme.palette.black.dark,
          fontWeight: 400,
          [theme.breakpoints.down('tablet')]: {
            fontSize: '1rem',
          },
          ...(!values?.credit_card_form.card_number && {
            letterSpacing: theme.spacing(0.2),
            [theme.breakpoints.up('tablet')]: {
              letterSpacing: theme.spacing(0.5),
            },
          }),
        })}
      >
        {values?.credit_card_form?.card_number?.match(/.{1,4}/g)?.join(' ') ||
          '**** **** **** ****'}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(1),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '3fr 1fr',
            gap: theme.spacing(3),
          },
        })}
      >
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.palette.black.dark,
            maxWidth: '20rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          })}
        >
          {values?.credit_card_form.payer_name || 'NOMBRE Y APELLIDO'}
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.palette.black.dark,
          })}
        >
          {values?.credit_card_form.expiration_date
            ?.match(/.{1,2}/g)
            ?.join('/') || 'MM/AA'}
        </Typography>
      </Box>
    </Box>
  )
}
