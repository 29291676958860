import { useState } from 'react'

const useBulkNoveltiesSummaryModal = () => {
  const [state, setState] = useState({
    open: false,
    bulkUploadResult: {},
  })

  const openBulkNoveltiesSummaryModal = (websocketResult) => {
    setState({ open: true, bulkUploadResult: websocketResult })
  }

  const closeBulkNoveltiesSummaryModal = () => {
    setState({
      open: false,
      bulkUploadResult: {},
    })
  }

  return {
    bulkNoveltiesSummaryModalState: state,
    openBulkNoveltiesSummaryModal,
    closeBulkNoveltiesSummaryModal,
  }
}

export default useBulkNoveltiesSummaryModal
