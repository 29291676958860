import * as yup from 'yup'

import { formatNumberDisplayDate } from 'utils/dateTime'

export const getInitialValues = (workCenterToUpdate) => ({
  work_center_id: workCenterToUpdate?.id || '',
  name: workCenterToUpdate?.name || '',
  initial_day: workCenterToUpdate?.initial_day || '',
  activity_code_id: workCenterToUpdate?.activity_code
    ? {
        id: workCenterToUpdate?.activity_code?.id,
        name: workCenterToUpdate?.activity_code?.ciiu_code,
      }
    : null,
  code: workCenterToUpdate?.code || '',
  risk_type:
    workCenterToUpdate?.activity_code?.risk_type || 'Mínimo - Riesgo 1',
})

export const validationSchema = yup.object({
  name: yup.string().required('El nombre es requerido'),
  initial_day: yup.string().required(),
  activity_code_id: yup
    .object()
    .nullable()
    .required('La actividad es requerida'),
  code: yup.string().required(),
  new_history: yup.boolean(),
})

export const historyColumns = [
  {
    accessor: 'initial_day',
    Header: 'Fecha inicial',
    Cell: ({ row }) => formatNumberDisplayDate(row.original.initial_day),
  },
  {
    accessor: 'end_day',
    Header: 'Fecha final',
    Cell: ({ row }) => {
      if (!row.original.end_day) return 'N/A'

      return formatNumberDisplayDate(row.original.end_day)
    },
  },
  {
    accessor: 'activity_code',
    Header: 'Actividad(CIIU)',
    Cell: ({ row }) => row.original.activity_code.ciiu_code,
  },
  {
    accessor: 'risk_type',
    Header: 'Nivel de riesgo',
    Cell: ({ row }) => row.original.activity_code.risk_type,
    customWidth: '200px',
  },
  {
    accessor: 'description',
    Header: 'Descripción',
    Cell: ({ row }) => row.original.activity_code.description,
    customWidth: '500px',
  },
]
