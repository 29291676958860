/**
 * Replaces payroll_concet with payroll_concept_id and adds category
 *
 * Used in Deductions and Income recurrent concepts components
 */
export const formatRecurrentConceptItem = (item, category) => {
  const newItem = {
    ...item,
    payroll_concept_id: item.payroll_concept.id,
    category,
    name: item.payroll_concept.name,
  }

  delete newItem.payroll_concept

  return newItem
}

export const formatRecurrentConceptsData = (
  recurrentConcepts = {},
  loans = []
) => {
  let deductions = []
  let incomes = []

  if (Array.isArray(recurrentConcepts.deductions) && Array.isArray(loans)) {
    deductions = [
      ...recurrentConcepts.deductions.map((deduction) =>
        formatRecurrentConceptItem(deduction, 'deductions')
      ),
      ...loans.map((loan) => ({
        ...loan,
        category: 'loans',
      })),
    ]
  }

  if (
    Array.isArray(recurrentConcepts.salary_income) &&
    Array.isArray(recurrentConcepts.non_salary_income)
  ) {
    incomes = [
      ...recurrentConcepts.salary_income.map((income) =>
        formatRecurrentConceptItem(income, 'salary_income')
      ),
      ...recurrentConcepts.non_salary_income.map((income) =>
        formatRecurrentConceptItem(income, 'non_salary_income')
      ),
    ]
  }

  return { deductions, incomes }
}

const entityLabels = {
  risk_provider: 'ARL',
  health_provider: 'EPS',
  compensation_fund: 'CCF',
  pension_provider: 'AFP',
}

export const getWorkerEntitiesToAffiliateCopy = (entities = []) => {
  const labels = entities.map((entity) => entityLabels[entity])

  if (labels.length === 0) return ''
  if (labels.length === 1) return labels[0]

  const lastLabel = labels.pop()
  return `${labels.join(', ')} y ${lastLabel}`
}
