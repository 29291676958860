import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createContract,
  deleteWage,
  getAllWages,
  getCurrentContract,
  updateWage,
  updateWorkerWage,
} from 'services/worker/wageService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, contractId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'currentWage') return getCurrentContract(contractId)

  if (currentQueryKey === 'allWages') return getAllWages(contractId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, contractId, wage, wageId, mutationKey } = mutationData

  if (mutationMethod === 'POST') {
    if (mutationKey === 'updateWorkerWage') return updateWorkerWage(wage)
    return createContract(contractId, wage)
  }

  if (mutationMethod === 'PATCH') return updateWage(wage)

  if (mutationMethod === 'DELETE') return deleteWage(wageId)

  return null
}

const useWageService = ({
  serviceParams = { queryKey: 'currentWage' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restWageResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  const wageMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    wageQuery: {
      data,
      ...restWageResponse,
    },
    wageMutation,
  }
}

export default useWageService
