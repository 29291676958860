import { Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

const Breadcrumb = ({ variant = 'text', label, to, onClick, icon }) => {
  if (variant === 'text') {
    return (
      <Typography
        variant="h6"
        color="black.main"
        sx={(theme) => ({
          userSelect: 'none',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: theme.spacing(0.5),
        })}
      >
        {icon ? (
          <Icon
            name={icon}
            twoTone
            sx={{
              fontSize: '2rem',
            }}
          />
        ) : null}
        {label}
      </Typography>
    )
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      sx={(theme) => ({
        textDecorationColor: `${theme.palette.primary.dark} !important`,
      })}
    >
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          userSelect: 'none',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: theme.spacing(0.5),
        })}
      >
        {icon ? (
          <Icon
            name={icon}
            twoTone
            sx={{
              fontSize: '2rem',
              color: 'initial',
            }}
          />
        ) : null}
        {label}
      </Typography>
    </Link>
  )
}

export default Breadcrumb
