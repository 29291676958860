import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import FilterButton from 'components/UI/Button/FilterButton'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'

import { isOrganizer } from 'utils/auth'
import usePayrollOrganizerService from 'utils/hooks/organizer/payrollService'
import useNotifications from 'utils/hooks/useNotifications'
import { getGeneralContractCategory, getNameWorkerType } from 'utils/worker'

import * as routes from 'config/routes'

import { SocialBenefitsViewMenu } from '../ActionGroup/Novelties'
import { usePeriodAPI } from '../helpers'
import PayPayrollButton from './PayPayrollButton'
import PayrollSummary from './PayrollSummaryButton'
import UploadNoveltiesButton from './UploadNoveltiesButton'

const useTableActions = ({
  contractsCounter,
  activeContract,
  setActiveContract,
  locations,
  activeLocation,
  setActiveLocation,
  editable,
  periodId,
  payrollsNumber,
  payPreviousPayroll,
  endFreePayrollModal,
}) => {
  const {
    openPayslipModal,
    openBankFileModal,
    handleToggleLoadingModal,
    updatePeriodCallback,
    openSinglePayslipModal,
  } = usePeriodAPI()

  const { payrollOrganizerMutation } = usePayrollOrganizerService()
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()

  const withoutWorkers = payrollsNumber === 0
  const disablePayPayroll = payrollsNumber <= 0

  const handleClickViewPayslip = async (payroll) => {
    handleToggleLoadingModal(true)
    await openPayslipModal({ payroll })
    handleToggleLoadingModal(false)
  }

  const handleClickSendPayslip = async (payroll) => {
    handleToggleLoadingModal(true)
    await openPayslipModal({ payroll, sendPaymentStubs: true })
    handleToggleLoadingModal(false)
  }

  const handleDeletePayroll = useCallback(
    ({ id, workerName }) => {
      confirm({
        type: 'warning',
        title: 'Eliminar nómina',
        description: `Eliminar la nómina de ${workerName} es permanente y no se podrá deshacer. ¿Estás seguro?`,
        okText: 'Eliminar',
        confirmCheckbox: true,
        onOk: () => {
          payrollOrganizerMutation.mutate(
            {
              mutationMethod: 'DELETE',
              payrollId: id,
            },
            {
              onSuccess: ({ data }) => {
                const { payroll } = data
                // Disabled this rule because is a parameter in back for hidden the payroll
                // eslint-disable-next-line no-underscore-dangle
                payroll.__deleted__ = true
                updatePeriodCallback(data)
                showSuccessMessage(data.message)
              },
            }
          )
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatePeriodCallback]
  )

  const contractOptions = Object.entries(contractsCounter)
    .filter(([, count]) => count > 0)
    .map(([contract, count]) => ({
      label: `${getNameWorkerType(contract, true)} (${count})`,
      value: getGeneralContractCategory(contract),
    }))

  const locationOptions = locations.map((location) => ({
    label: `${location.name} (${location.workers})`,
    value: location.id,
  }))

  const { showPremiumFeatures } = usePremiumFeature()

  const actions = [
    {
      id: 'filterContract',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Contrato"
          options={contractOptions}
          value={activeContract}
          onChange={setActiveContract}
        />
      ),
    },
    {
      id: 'filterLocation',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Sede"
          options={locationOptions}
          value={activeLocation}
          onChange={setActiveLocation}
        />
      ),
    },
    {
      id: 'socialBenefits',
      isFreeAction: true,
      Component: <SocialBenefitsViewMenu isTableButton />,
      hidden: payPreviousPayroll,
    },
    {
      id: 'uploadNovelties',
      isFreeAction: true,
      Component: <UploadNoveltiesButton />,
    },
    (rowData) => ({
      id: 'contractTermination',
      tooltip: 'Liquidar persona',
      icon: <Icon name="coin" basic />,
      hidden:
        !editable ||
        rowData.locked ||
        payPreviousPayroll ||
        [
          'student_law_789',
          'productive_trainee',
          'student_decree_055',
          'contractor',
          'terminated',
        ].includes(rowData.contract_category),
      buttonProps: {
        component: Link,
        to: routes.WORKER_CONTRACT_TERMINATION_NEW(
          rowData.worker_id,
          rowData.contract_id
        ),
        color: 'complementary1',
        onClick: undefined,
      },
    }),
    (rowData) => ({
      id: 'addNote',
      tooltip: 'Agregar Nota',
      icon: <Icon name="edit-document" basic />,
      commentsDialog: true,
      badge: rowData.comments,
      hidden: !editable || rowData.locked,
      buttonProps: {
        color: 'complementary1',
      },
    }),
    endFreePayrollModal.isNotValidCompany
      ? (rowData) => ({
          id: 'seeCalculations',
          tooltip: 'Ver cálculos',
          icon: <Icon name="eye" basic />,
          onClick: () => endFreePayrollModal.openEndFreePayrollModal(),
          hidden: rowData.contract_category === 'contractor' || rowData.locked,
          buttonProps: {
            'data-cy': `payroll-${rowData.rowIndex}-view_details`,
            color: 'complementary1',
          },
        })
      : (rowData) => ({
          id: 'seeCalculations',
          tooltip: 'Ver cálculos',
          icon: <Icon name="eye" basic />,
          hidden: rowData.contract_category === 'contractor' || rowData.locked,
          buttonProps: {
            component: Link,
            to: payPreviousPayroll
              ? routes.PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS(
                  periodId,
                  rowData.id
                )
              : routes.PERIOD_PAYROLL_VIEW_DETAILS(rowData.id),
            target: '_blank',
            'data-cy': `payroll-${rowData.rowIndex}-view_details`,
            color: 'complementary1',
          },
        }),
    (rowData) => ({
      id: 'seeBill',
      tooltip: 'Ver colilla de pago',
      icon: <Icon name="ticket-coupon" basic />,
      onClick: (payroll) => handleClickViewPayslip(payroll),
      hidden: rowData.locked,
      buttonProps: {
        'data-cy': `payroll-${rowData.rowIndex}-view_payslip`,
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'sendBill',
      tooltip: 'Enviar colilla de pago',
      icon: <Icon name="paperplane-send" basic />,
      onClick: showPremiumFeatures
        ? (payroll) => openSinglePayslipModal({ payroll })
        : (payroll) => handleClickSendPayslip(payroll),
      hidden:
        (!rowData.payslip_mail && !rowData.payslip_whatsapp) || rowData.locked,
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'bankFile',
      tooltip: 'Archivo pago en banco',
      icon: <Icon name="document" basic />,
      onClick: (payroll) => openBankFileModal({ payroll }),
      hidden: !rowData.bank_file || rowData.locked,
      buttonProps: {
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'deletePayroll',
      tooltip: 'Eliminar nómina',
      icon: <Icon name="trash" basic />,
      hidden: !isOrganizer(),
      onClick: () =>
        handleDeletePayroll({
          id: rowData.id,
          workerName: rowData.worker_name,
        }),
      buttonProps: {
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'decree376Payment',
      tooltip:
        'Esta nómina contiene pagos de aporte por decreto 376 para una persona que ya fue liquidada',
      icon: <Icon name="shield-security" basic />,
      hidden: !rowData.locked,
      buttonProps: {
        color: 'complementary1',
      },
    }),
    {
      id: 'payPayroll',
      isFreeAction: true,
      hidden: withoutWorkers || payPreviousPayroll,
      Component: <PayPayrollButton disabled={disablePayPayroll} />,
    },
    {
      id: 'payrollSummary',
      isFreeAction: true,
      hidden: withoutWorkers || !payPreviousPayroll,
      Component: <PayrollSummary />,
    },
  ]

  return actions
}

export default useTableActions
