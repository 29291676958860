import { Box, Typography, useTheme } from '@mui/material'

import { ReactComponent as Success } from 'assets/images/views/common/aleluya_person.svg'

const Header = ({ title }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > img': {
          width: '214px',
          height: '170px',
        },
      }}
    >
      <Box>
        <Success fill={theme.palette.primary.main} />
      </Box>
      <Typography variant="h5">{title}</Typography>
    </Box>
  )
}

export default Header
