import Emoji from 'components/UI/Emoji'

import {
  formatUnixDate,
  getColombianCurrentDate,
  reorderNumberDisplayDate,
} from 'utils/dateTime'
import { fire, redCrossMark, warning } from 'utils/emojis'

export const getNoveltiesData = (bulkUploadResult) => {
  const { success } = bulkUploadResult || {}

  const {
    overtime,
    novelties,
    licences,
    incomes,
    deductions,
    success_workers_count: workersCount,
  } = success || {}

  const noveltiesCards = [
    {
      id: 'workers',
      title: 'Personas con novedades',
      amount: workersCount || 0,
      icon: 'group',
    },
    {
      id: 'overtime',
      title: 'Horas extras y otros recargos',
      amount: overtime?.success_cases || 0,
      icon: 'clock',
    },
    {
      id: 'novelties',
      title: 'Vacaciones e incapacidades',
      amount: novelties?.success_cases || 0,
      icon: 'man',
    },
    {
      id: 'licences',
      title: 'Licencias remuneradas y no remuneradas',
      amount: licences?.success_cases || 0,
      icon: 'consultation',
    },
    {
      id: 'incomes',
      title: 'Otros ingresos',
      amount: incomes?.success_cases || 0,
      icon: 'stock-share',
    },
    {
      id: 'deductions',
      title: 'Deducciones, préstamos y retefuente',
      amount: deductions?.success_cases || 0,
      icon: 'finance',
    },
  ]

  return noveltiesCards
}

const getCurrentDate = () =>
  reorderNumberDisplayDate(formatUnixDate(getColombianCurrentDate() / 1000))

export const getModalText = (
  successWorkersCount,
  fileWithErrors,
  totalFailureCases
) => {
  const colombianCurrentDate = getCurrentDate()
  const moreThanOne = totalFailureCases > 1
  const modalText = {}

  if (!successWorkersCount) {
    modalText.header = (
      <>
        ¡Ohh, Yisus!{' '}
        {moreThanOne
          ? 'Tu archivo tiene algunos errores y no pudimos cargar tus novedades'
          : 'Hay un error en la novedad que intentaste cargar'}{' '}
        <Emoji code={redCrossMark} />
      </>
    )
    modalText.body = (
      <>
        {moreThanOne
          ? 'Revisa cuáles son los errores y corrígelos para poder cargar tus novedades.'
          : 'Revisa cuál es el error y corrígelo para poder cargar tu novedad.'}
      </>
    )
  }

  if (successWorkersCount && fileWithErrors) {
    modalText.header = (
      <>
        La subida de tu archivo fue exitosa, pero{' '}
        {moreThanOne
          ? 'algunas novedades presentan errores y no fueron cargadas'
          : 'una novedad presenta un error y no logramos cargarla'}{' '}
        <Emoji code={warning} />
      </>
    )
    modalText.body = (
      <>
        A continuación, verás el resumen de las novedades cargadas y tendrás la
        opción de corregir {moreThanOne ? 'los errores' : 'el error'} para
        completar al 100% la carga de tus novedades.
      </>
    )
  }

  if (!fileWithErrors) {
    modalText.header = (
      <>
        ¡Aleluya! Tu archivo se cargó correctamente <Emoji code={fire} />
      </>
    )
    modalText.body = (
      <>
        ¡La estamos sacando del estadio! Tu archivo de novedades se cargó
        exitosamente el <strong>{colombianCurrentDate}</strong>. Ahora puedes
        seguir con tu proceso de liquidación de nómina.
      </>
    )
  }

  return modalText
}
