import http, { getApiURL } from 'services/httpService'

export const createRegistry = (registry) => {
  const url = getApiURL({ pathname: '/registries', withoutCompany: true })

  const data = {
    registry: {
      ...registry,
      terms_accepted: true,
    },
  }

  return http.postV2(url, data, {
    headers: {
      Authorization: `Basic ${window.btoa(
        `${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_API_TOKEN}`
      )}`,
    },
  })
}

export default {
  createRegistry,
}
