import * as yup from 'yup'

import { Box, Chip, alpha } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import NoWrap from 'components/UI/NoWrap'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import { proportionalRecurrentPaymentOptions } from '../../Modals/RecurrentConceptsModal/helpers'
import EditDeductionConcept from './EditDeductionConcept'

yup.setLocale(yupLocaleES)

export function getValidationSchema(baseSalary) {
  return yup.object({
    // for deductions
    payroll_concept_id: yup.string().nullable().when('category', {
      is: 'deductions',
      then: yup.string().trim().nullable().required(),
    }),
    monthly_value: yup.number().nullable().when('category', {
      is: 'deductions',
      then: yup.number().positive().nullable().required(),
    }),
    concept_type: yup.string().nullable().when('category', {
      is: 'deductions',
      then: yup.string().nullable().required(),
    }),
    // for loans
    name: yup.string().nullable().when('category', {
      is: 'loans',
      then: yup.string().trim().nullable().required(),
    }),
    value: yup
      .number()
      .nullable()
      .when('category', {
        is: 'loans',
        then: yup
          .number()
          .positive()
          .nullable()
          .when('paid_value', {
            is: (value) => value,
            then: yup
              .number()
              .min(
                yup.ref('paid_value'),
                ({ min }) =>
                  `No debe ser menor al valor ya pagado de ${formatCurrency(min)}.`
              ),
          })
          .required(),
      }),
    monthly_fee: yup
      .number()
      .nullable()
      .when('category', {
        is: 'loans',
        then: yup
          .number()
          .positive()
          .nullable()
          .when(['value', 'active'], {
            is: (value, active) =>
              (active === true || active == null) && value > baseSalary,
            then: yup
              .number()
              .max(baseSalary, 'No debe superar el valor del salario base.'),
          })
          .max(yup.ref('value'), 'No debe superar el valor del préstamo.')
          .required(),
      }),
    // both
    active: yup.bool(),
  })
}

export const getDeductionsColumns = (concepts = []) => {
  const categoryLookUp = {
    deductions: 'Deducción',
    loans: 'Préstamo',
  }

  const conceptTypeInfo = {
    proportional: 'Proporcional',
    fixed: 'Fijo',
  }

  return [
    {
      Header: 'Categoría',
      accessor: 'category',
      Cell: ({ row }) => categoryLookUp[row.original.category],
    },
    {
      Header: 'Concepto',
      accessor: 'name',
      Edit: (props) => (
        <EditDeductionConcept
          {...props}
          options={concepts.map((concept) => ({
            label: concept.name,
            value: concept.id,
          }))}
        />
      ),
      editable: true,
    },
    {
      Header: 'Tipo',
      accessor: 'concept_type',
      Cell: ({ row }) => conceptTypeInfo[row.original.concept_type],
      name: 'concept_type',
      Edit: () => (
        <FormField
          name="concept_type"
          variant="select"
          options={proportionalRecurrentPaymentOptions}
        />
      ),
      editable: ({ row }) => row.original.category === 'deductions',
      emptyValue: '-',
    },
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
      field: 'currency',
      name: 'value',
      editable: ({ row }) => row.original.category === 'loans',
      emptyValue: '-',
    },
    {
      Header: 'Cuota mensual',
      id: 'monthly_value',
      accessor: ({ category }) =>
        category === 'deductions' ? 'monthly_value' : 'monthly_fee',
      Cell: ({ row }) =>
        formatCurrency(row.original.monthly_value || row.original.monthly_fee),
      field: 'currency',
      name: ({ row }) =>
        row.original.category === 'deductions'
          ? 'monthly_value'
          : 'monthly_fee',
      editable: true,
    },
    {
      Header: 'Saldo',
      accessor: 'balance',
      Cell: ({ row }) => {
        const {
          value,
          paid_value: paidValue,
          pending_months: pendingMonths,
        } = row.original

        if (value) {
          return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <NoWrap>Saldo: {formatCurrency(value - paidValue)}</NoWrap>
              <div>Meses: {pendingMonths}</div>
            </Box>
          )
        }
        return null
      },
      Edit: ({ rowValues }) => {
        const {
          value,
          paid_value: paidValue,
          monthly_fee: monthlyFee,
        } = rowValues

        if (value) {
          const calculatedBalance = paidValue ? value - paidValue : value

          const numberOfMoths =
            monthlyFee && monthlyFee !== 0
              ? Math.ceil(calculatedBalance / monthlyFee)
              : 0

          return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <NoWrap>Saldo: {formatCurrency(calculatedBalance)}</NoWrap>
              <div>Meses: {numberOfMoths}</div>
            </Box>
          )
        }
        return '-'
      },
      emptyValue: '-',
      editable: true,
    },
    {
      Header: 'Activo',
      accessor: 'active',
      Cell: ({ row }) => (
        <Chip
          sx={(theme) => ({
            minWidth: '6rem',
            ...(!row.original.active && {
              backgroundColor: alpha(theme.palette.error.main, 0.2),
              color: theme.palette.error.dark,
            }),
            ...(row.original.active && {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.dark,
            }),
          })}
          label={row.original.active ? 'Activo' : 'Inactivo'}
        />
      ),
      name: 'active',
      field: 'switch',
      editable: true,
    },
  ]
}
