import { Form, Formik } from 'formik'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { documentTypesOptions, paymentTypesOptions } from 'utils/company'
import usePartnerCompany from 'utils/hooks/organizer/partner/company'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { initialValues, validationSchema } from '../helpers'

const CompanyModal = ({
  open,
  company,
  partnerId,
  handleClose,
  submitCallback,
}) => {
  const { partnerCompanyMutation } = usePartnerCompany({
    queryOptions: { enabled: false },
  })

  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()

  const isEdit = !!company.id

  const handleSubmit = (values, form) => {
    partnerCompanyMutation.mutate(
      {
        mutationMethod: 'PUT',
        partnerId,
        company: { ...values },
      },
      {
        onSuccess: () => {
          submitCallback()
          showSuccessMessage(
            `Empresa ${isEdit ? 'actualizada' : 'creada'} con éxito.`
          )
          handleClose()
        },
        onError: (error) => handleError(error, form),
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ ...initialValues, ...company }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit, isSubmitting }) => {
        return (
          <Modal
            header={`${isEdit ? 'Editar' : 'Crear'} compañía`}
            okText="Guardar"
            loadingText="Guardando..."
            open={open}
            onOk={onSubmit}
            onCancel={handleClose}
            isLoading={isSubmitting}
            dialogProps={{
              maxWidth: 'sm',
              scroll: 'body',
            }}
          >
            <Form>
              <FormField name="name" label="Razón social" />
              <FormField name="email" type="email" label="Email contacto" />
              <DocumentTypeField options={documentTypesOptions} />
              <CompanyIdentificationNumberField />
              <FormField
                name="payment_category"
                label="Tipo de Pago"
                options={paymentTypesOptions}
                variant="select"
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default CompanyModal
