import { useSplitTreatments } from '@splitsoftware/splitio-react'

import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'

/**
 * @param {Object} treatments - Set of feature flags to format
 * @returns Set of formatted feature flags
 */
const formatTreatments = (treatments) => {
  if (isObjectEmpty(treatments) || !Object.entries) return {}
  const parsedTreatments = {}

  Object.entries(treatments).forEach(([treatmentKey, treatmentValue]) => {
    if (treatmentValue?.treatment === 'control') {
      parsedTreatments[treatmentKey] =
        `Error: this feature flag doesn't exist or isn't ready yet`
      return
    }

    parsedTreatments[treatmentKey] = {
      value: treatmentValue?.treatment,
      config: treatmentValue?.config
        ? JSON.parse(treatmentValue?.config)
        : null,
    }
  })

  return parsedTreatments
}

/**
 * @param {Array} flags - Set of feature flags to be queried
 * @param {string} [trackingMode=segment] - Type of tracking to use. Its value can be attributes or segment (default)
 * @param {Object} attributes - Set of attributes to track the user
 * @param {string} segmentKey - Key used to track the user in a specific segment
 * @returns Set of queried feature flags
 */
const useFeatureFlags = ({
  flags = [],
  trackingMode = 'segment',
  attributes = {},
  segmentKey,
} = {}) => {
  const companyId = getCompanyId()
  const { treatments, isReady } = useSplitTreatments({
    names: flags,
    attributes: trackingMode === 'attributes' ? attributes : undefined,
    splitKey: segmentKey || companyId || undefined,
    updateOnSdkUpdate: true,
    updateOnSdkReady: true,
  })

  return {
    ...formatTreatments(treatments),
    flagsReady: isReady,
  }
}

export default useFeatureFlags
