export const billingFrencuency = {
  year: 'Anual',
  month: 'Mensual',
}

export const billingFrencuencyOptions = Object.keys(billingFrencuency).map(
  (key) => ({
    label: billingFrencuency[key],
    value: key,
  })
)
