import useModals from 'components/App/ModalsManager/useModals'

import AffiliationCredentialsModalContent from './CredentialsModalContent'

const useAffiliationCredentialsModal = () => {
  const modals = useModals()

  const handleClose = () =>
    modals.closeModal('reviewAffiliationCredentialsModal')

  const openAffiliationCredentialsModal = (workerId) => {
    modals.openModal({
      id: 'reviewAffiliationCredentialsModal',
      content: (
        <AffiliationCredentialsModalContent
          workerId={workerId}
          handleClose={handleClose}
          setLoadingModal={modals.setLoadingModal}
        />
      ),
      modalProps: {
        header: 'Debes revisar tu solicitud de afiliación a seguridad social',
        onCloseModal: handleClose,
        onCancel: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '42rem',
        },
        adornment: [
          {
            variant: 'signature',
            color: 'complementary1.light',
            width: 217,
            height: 139,
            sx: { bottom: '-3rem', right: '0.25rem' },
          },
        ],
      },
    })
  }

  return {
    openAffiliationCredentialsModal,
  }
}

export default useAffiliationCredentialsModal
