import { Typography } from '@mui/material'

import generateSimpleMessage from './utils'

export default {
  with_just_cause: (
    <>
      <Typography sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
        Marca <b>&quot;SÍ&quot;</b> cuando exista una justa causa para la
        terminación del contrato (No se aplica indemnización).
      </Typography>
      <Typography>
        Marca <b>&quot;NO&quot;</b> en caso de retiro sin justa causa (Se aplica
        indemnización).
      </Typography>
    </>
  ),
  hire_date:
    'Si deseas editar este campo, debes regresar al perfil del empleado y editar la fecha de contratación.',
  date: 'Selecciona el día de terminación del contrato. Solo se podrá seleccionar una fecha del periodo actual o del anterior. Advertencia: ¡Cambiar la fecha reajusta todos los valores!',
  worked_days: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-los-días-a-liquidar-en-una-liquidación-laboral',
    'El cálculo se realiza del día de fecha de contratación, al día de terminación del contrato.'
  ),
  notes:
    'Aquí puedes registrar tus observaciones para aclarar información de la liquidación laboral. Son visibles en la colilla.',
  holidays: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-la-liquidación-de-las-vacaciones-en-la-liquidación-laboral',
    'Días pendientes de vacaciones a pagar.'
  ),
  termination_base: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-el-salario-base-en-una-liquidación-laboral',
    'Ingresos salariales promedio del ultimo año trabajado.'
  ),
  holidays_base: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-la-liquidación-de-las-vacaciones-en-la-liquidación-laboral',
    'Ingresos salariales promedio ultimo año trabajado sin incluir recargos u horas extras (solo recargo nocturno).'
  ),
  severance_base: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-cesantías-en-una-liquidación-laboral',
    'Ingresos salariales del año de la liquidación más subsidio de transporte (si aplica).'
  ),
  service_bonus_base: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-los-cálculos-de-la-liquidación-de-la-prima-de-servicios',
    'Ingresos salariales del semestre de la liquidación más subsidio de transporte (si aplica).'
  ),
  pay_rest_days:
    'Incluir pago en la nómina de los días de descanso (Por ejemplo, si es liquidado un viernes y descansa sábado y domingo, se le debe pagar el salario de estos días).',
  pay_previous_service_bonus:
    'No se ha pagado la prima del semestre anterior y se quiere incluir en la liquidación.',
  holidays_value: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-la-liquidación-de-las-vacaciones-en-la-liquidación-laboral'
  ),
  severance: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-cesantías-en-una-liquidación-laboral'
  ),
  severance_interests: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-la-base-para-la-liquidación-de-las-cesantías'
  ),
  service_bonus: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-los-cálculos-de-la-liquidación-de-la-prima-de-servicios'
  ),
  compensation: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-la-indemnización-por-liquidación-sin-justa-causa'
  ),
  trial_period: (
    <>
      <Typography sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
        Si marcas <b>&quot;No&quot;</b> y la liquidación es SIN justa causa, se
        aplica indemnización.
      </Typography>
      <Typography>De lo contrario, NO se aplica indemnización.</Typography>
    </>
  ),
}
