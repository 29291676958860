import { Avatar, Box, Button, Typography, useMediaQuery } from '@mui/material'

import { useSupportMenuContext } from 'components/App/SupportMenuContext'
import ProfileImg from 'components/UI/ProfileImg'

const ContactExpertCard = ({ handleClose }) => {
  const { openSupportMenuFromOutside } = useSupportMenuContext()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleOpenSupportMenu = () => {
    openSupportMenuFromOutside()
    handleClose()
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.white.dark,
        display: 'grid',
        alignItems: 'center',
        gap: theme.spacing(3),
        gridTemplateColumns: '1fr 5fr',
        height: '6.349rem',
        maxWidth: '23rem',
        borderRadius: '0.5rem',
        marginTop: theme.spacing(3),
        padding: theme.spacing(1, 3),
        boxShadow: theme.shadows[6],
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('tablet')]: {
          padding: theme.spacing(3),
          gap: theme.spacing(2),
          height: 'fit-content',
        },
      })}
    >
      <Box sx={{ display: 'flex' }}>
        <Avatar
          alt="Habla con un experto"
          sx={(theme) => ({
            width: '4rem',
            height: '4rem',
            backgroundColor: theme.palette.primary.main,
          })}
        >
          <ProfileImg color="black" height={50} width={50} />
        </Avatar>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        })}
      >
        <Typography
          variant="lead1"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          ¿Tienes preguntas?
        </Typography>
        <Button
          variant="text"
          sx={{ maxWidth: '15rem', textAlign: 'left' }}
          onClick={handleOpenSupportMenu}
        >
          <Typography variant={isMobile ? 'body2' : 'body1'}>
            Clic aquí para hablar con uno de nuestros expertos
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default ContactExpertCard
