import { Form, Formik } from 'formik'
import { Navigate, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import FormBox from 'components/UI/FormBox'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import Page from 'components/UI/Page/Page'

import { formatValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { setOnBoardingNextStepWorkerForm } from 'utils/onboarding'

import * as routes from 'config/routes'

import FieldsOnBoarding from './Fields'
import schemaValidation from './helpers'

const WorkerForm = () => {
  const { handleError } = useErrorHandler()
  const { showSuccessMessage, showWarningMessage } = useNotifications()
  const navigate = useNavigate()

  const { company, refreshCompany } = useUser()
  const { onboarding_first_steps: onboardingFirstSteps = {} } = company

  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })

  const initialWorker = {
    name: localStorage.getItem('user_name'),
    document_type: 'cc',
    high_risk_pension: null,
  }

  const handleRedirect = () => {
    navigate(routes.PERIOD_PAY_PAYROLL())
  }

  const callbackSuccess = () => {
    setOnBoardingNextStepWorkerForm()
    showSuccessMessage('La persona fue creada exitosamente')
    handleRedirect()
    refreshCompany()
  }

  const callbackError = (error, form) => {
    handleError(error, form, {
      errorsToNotificate: [
        { object: 'id_number' },
        { object: 'email' },
        { object: 'worker_payment' },
      ],
      errorFieldMap: { user: 'email' },
    })
  }

  const handleSubmit = (values, form) => {
    const worker = formatValues(values)

    if (!isObjectEmpty(worker)) {
      workerMutation.mutate(
        {
          mutationMethod: 'POST',
          worker: { worker: { ...worker } },
        },
        {
          onSuccess: () => {
            callbackSuccess()
          },
          onError: (error) => {
            callbackError(error, form)
          },
        }
      )
    }
  }

  if (onboardingFirstSteps?.first_worker) {
    return <Navigate to={routes.WORKER_INDEX()} />
  }

  return (
    <Page header="Crear nueva Persona" documentTitle="Crear nueva Persona">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '55rem',
            width: '100%',
          }}
        >
          <FormBox>
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialWorker}
              validationSchema={schemaValidation}
              enableReinitialize
            >
              {({ isValid, handleSubmit: onSubmit }) => {
                return (
                  <>
                    <Form>
                      <FieldsOnBoarding />
                    </Form>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: theme.spacing(4),
                        [theme.breakpoints.down('sm')]: {
                          justifyContent: 'center',
                        },
                      })}
                    >
                      <Button
                        onClick={() => {
                          if (!isValid)
                            showWarningMessage(
                              'Existen errores en alguno de los campos.'
                            )
                          onSubmit()
                        }}
                        disabled={workerMutation.isLoading}
                      >
                        Crear empleado
                      </Button>
                    </Box>
                    {workerMutation.isLoading ? <LoadingModal /> : null}
                  </>
                )
              }}
            </Formik>
          </FormBox>
        </Box>
      </Box>
    </Page>
  )
}

export default WorkerForm
