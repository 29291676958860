import { useState } from 'react'

const useContractDetailModal = () => {
  const [state, setState] = useState({
    open: false,
    worker: {},
    contractDetail: {},
    lastContractDetail: {},
    previousContractDetail: {},
    nextContractDetail: {},
    isEditing: false,
    contractId: undefined,
    isFromContractDetailIndex: true,
  })

  const openContractDetailModal = ({
    worker = {},
    lastContractDetail = {},
    contractDetail = {},
    previousContractDetail = {},
    nextContractDetail = {},
    isEditing = false,
    contractId,
    isFromContractDetailIndex = true,
  }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      worker,
      lastContractDetail,
      contractDetail,
      previousContractDetail,
      nextContractDetail,
      isEditing,
      contractId,
      isFromContractDetailIndex,
    }))
  }

  const closeContractDetailModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const resetContractDetailModal = () => {
    setState((previousState) => ({
      ...previousState,
      worker: {},
      contractDetail: {},
      lastContractDetail: {},
      previousContractDetail: {},
      nextContractDetail: {},
      isEditing: false,
      contractId: undefined,
      isFromContractDetailIndex: true,
    }))
  }

  return {
    contractDetailModalState: state,
    openContractDetailModal,
    closeContractDetailModal,
    resetContractDetailModal,
  }
}

export default useContractDetailModal
