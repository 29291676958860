import { useMutation, useQuery } from 'react-query'

import { formatDate } from 'utils/dateTime'
import { getQueryKey } from 'utils/reactQuery'

import {
  createRetentions,
  deleteRetentionConfiguration,
  getRetentions,
  updateRetentions,
} from 'services/worker/retentionConfigurationService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'retentionConfigurations')
    return getRetentions(workerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, data } = mutationData

  if (mutationMethod === 'POST') return createRetentions(workerId, data)

  if (mutationMethod === 'PATCH') return updateRetentions(workerId, data)

  if (mutationMethod === 'DELETE') return deleteRetentionConfiguration(workerId)

  return null
}

const useRetentionsConfigurationService = ({
  serviceParams = { queryKey: 'retentionConfigurations' },
  queryOptions = {},
  mutationOptions = {},
}) => {
  let dataToReturn = {}
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restRetentionsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  dataToReturn = { data }

  const retentionsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  if (!queryOptions.enabled) {
    dataToReturn = {
      data: {
        active: false,
        activate_since: formatDate(new Date()),
        housing_interests: 0,
        prepaid_medicine: 0,
        dependents_deduction: false,
      },
      isError: false,
      isLoading: false,
      isRetConfigEditing: false,
    }
  }

  return {
    retentionsQuery: {
      ...restRetentionsResponse,
      ...dataToReturn,
    },
    retentionsMutation,
  }
}

export default useRetentionsConfigurationService
