import ClientTypeField from 'components/Subscription/SubscriptionFormFields/ClientType'
import BankField from 'components/UI/Formik/CommonFields/BankField'

export const PseFields = () => {
  return (
    <>
      <ClientTypeField name="pse_form.client_type" />
      <BankField name="pse_form.bank" />
    </>
  )
}
