import { Button } from '@mui/material'

const LinkButton = ({ children, ...props }) => {
  return (
    <Button
      variant="text"
      {...props}
      sx={(theme) => ({
        verticalAlign: 'baseline',
        color: theme.palette.accent4.main,
        fontWeight: 700,
        height: 'max-content',
        lineHeight: 1.35,
        fontSize: '1rem',
        borderRadius: 'initial',
        minWidth: 'max-content',
        ':focus, :hover': {
          color: theme.palette.accent4.main,
        },
        ':focus-visible': {
          outlineOffset: 1,
          outline: 'auto',
          outlineColor: theme.palette.black.main,
        },
      })}
    >
      {children}
    </Button>
  )
}

export default LinkButton
