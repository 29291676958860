import http, { getApiURL } from '../httpService'

export const updateCompensatedDays = (payrollId, compensatedDays) => {
  const url = getApiURL({
    pathname: `/payrolls/${payrollId}/compensated_days`,
  })

  const requestData = {
    compensated_days: compensatedDays,
  }

  return http.putV2(url, requestData)
}

export default {
  updateCompensatedDays,
}
