export const validateEditFieldMetadata = (columnData) => {
  if (columnData.field) {
    if (
      ![
        'textfield',
        'number',
        'switch',
        'select',
        'autocomplete',
        'currency',
      ].includes(columnData.field)
    )
      throw new Error(
        'The provided field is not supported yet in the EditRow component'
      )

    if (
      (columnData.field === 'select' || columnData.field === 'autocomplete') &&
      !columnData.editOptions
    )
      throw new Error(
        'To edit a select field, please provide the available options in the editOptions key'
      )

    return {
      name: columnData.name,
      field: columnData.field,
      editOptions: columnData.editOptions,
    }
  }

  return {}
}

export const getIsRowEditable = (cell, values, editMode) => {
  if (cell.column.editable) {
    if (typeof cell.column.editable === 'boolean') return cell.column.editable
    if (typeof cell.column.editable === 'function') {
      const editableValue = cell.column.editable(cell, values)
      if (typeof editableValue === 'boolean') return editableValue
      if (typeof editableValue === 'string') return editableValue === editMode
    }

    return cell.column.editable === editMode
  }

  return false
}

export const getEditInitialValue = (cells, columnId, values) => {
  const cell = cells?.find((cellItem) => cellItem.column.id === columnId)
  const editInitialValue = cell?.column?.editInitialValue
  const defaultValue = values[columnId]

  if (editInitialValue !== undefined && editInitialValue !== null) {
    if (defaultValue !== undefined && defaultValue !== null) return defaultValue
    return editInitialValue
  }

  return defaultValue
}
