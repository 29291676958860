import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import ContributorSubtype from 'components/Worker/Contract/Fields/ContributorSubtype'
import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import RiskTypeField from 'components/Worker/Contract/Fields/RiskType'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'
import LocationField from 'components/Worker/Form/Fields/Personal/Location/Location'
import PositionField from 'components/Worker/Form/Fields/Personal/Position/Position'
import BaseSalaryField from 'components/Worker/Wage/Fields/BaseSalary'

import { getAffiliationEntitiesAlert, getEntitiesToAffiliate } from './helpers'

const AffiliationDataStep = ({ worker }) => {
  const { values } = useFormikContext()
  const { beneficiaries } = values || []

  const { showAffiliationEntitiesAlert, alertText } =
    getAffiliationEntitiesAlert({ worker, beneficiaries })

  return (
    <>
      <Typography variant="body1" color="black.dark">
        Esta es la información que registraste en el perfil de la persona.
        Puedes verificarla y, si es necesario, ajustarla antes de solicitar la
        afiliación.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(3),
          marginTop: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(3),
          },
        })}
      >
        <PositionField
          placeholder="Cargo"
          tooltipContent={null}
          optional={false}
        />
        <BaseSalaryField
          isEditing={false}
          contractCategory={worker.contract_category}
          tooltipContent={null}
          optional={false}
        />
        <InitialDayField
          tooltipContent={null}
          label="Fecha de ingreso"
          optional={false}
        />
        <ContributorSubtype
          optional={false}
          contractCategory={worker.contract_category}
          isFromAffiliation
        />
        <HealthProviderField
          contractCategory={worker.contract_category}
          tooltipContent={null}
          optional={false}
        />
        <PensionProviderField
          contractCategory={worker.contract_category}
          documentType={worker.document_type}
          label="Pensiones"
          tooltipContent={null}
          optional={false}
          contributorSubtype={values?.contributor_subtype}
        />
        <SeveranceProviderField
          wageCategory={worker.wage_category}
          contractCategory={worker.contract_category}
          label="Cesantías"
          tooltipContent={null}
          optional={false}
        />
        <RiskTypeField
          contractCategory={worker.contract_category}
          label="Centro de trabajo - Nivel de riesgo"
          tooltipContent={null}
          optional={false}
        />
        <LocationField
          tooltipContent={null}
          label="Sede donde trabaja"
          optional={false}
        />
      </Box>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({ marginTop: theme.spacing(3) })}
      >
        Entidades a las que quieres afiliar a {worker.name}
        <Box
          component="span"
          sx={(theme) => ({ color: theme.palette.error.main })}
        >
          *
        </Box>
      </Typography>
      <Typography
        variant="body1"
        color="black.dark"
        sx={(theme) => ({ margin: theme.spacing(1, 0, 2) })}
      >
        Para poder continuar con el proceso, necesitamos que nos digas a qué
        entidades deseas realizar la afiliación:
      </Typography>
      {showAffiliationEntitiesAlert ? (
        <Alert header="Ten en cuenta que:" severity="info">
          {alertText}
        </Alert>
      ) : null}
      <FormField
        name="entities_to_affiliate"
        variant="checkbox-group"
        options={getEntitiesToAffiliate({ worker, values, beneficiaries })}
        row
      />
    </>
  )
}

export default AffiliationDataStep
