import aleluyaColors from './aleluya'

const ARUS_COLORS = {
  ...aleluyaColors,
  PRIMARY: {
    dark: 'rgb(89, 15, 32)',
    main: 'rgb(139, 24, 31)',
    light: 'rgb(220, 112, 130)',
  },
}

export default ARUS_COLORS
