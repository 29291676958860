import { getCompanyId } from 'utils/company'

import http, { getApiURL } from '../httpService'

export const newPeriod = (data) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/period_actions`,
    withoutVersion: true,
  })

  return http.postV2(url, data)
}

export const generateAccountantsFile = (data) => {
  const url = getApiURL({
    pathname: '/organizers/accountants_companies_file',
    withoutVersion: true,
  })

  return http.postV2(url, data)
}

export const reinvoicePayment = () => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/current_payment`,
    withoutVersion: true,
  })

  const data = { payment: { perform: 'reinvoice' } }

  return http.patchV2(url, data)
}

export const updateElectronicPayrollAction = (
  electronicPayrollPeriodId,
  perform
) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/electronic_payroll_period_actions/${electronicPayrollPeriodId}`,
    withoutVersion: true,
  })

  const data = { period_action: { perform } }

  return http.putV2(url, data)
}

export const createElectronicPayrollPeriod = (data) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/electronic_payroll_period_actions`,
    withoutVersion: true,
  })

  return http.postV2(url, data)
}
