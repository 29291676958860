import { Divider, alpha } from '@mui/material'

const GroupDivider = () => {
  return (
    <Divider
      sx={(theme) => ({
        borderColor: alpha(theme.palette.complementary1.light, 0.4),
        margin: theme.spacing(0, 1.5),
        gridColumn: '1 / -1',
        [theme.breakpoints.up('tablet')]: {
          display: 'none',
        },
      })}
    />
  )
}

export default GroupDivider
