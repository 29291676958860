import { createContext, useContext, useState } from 'react'

import useBulkNoveltiesCommonInfoModal from '../Modals/useBulkNoveltiesCommonInfoModal'
import useBulkNoveltiesSummaryModal from '../Modals/useBulkNoveltiesSummaryModal'
import BulkNoveltiesAlert from './index'

export const BulkNoveltiesAlertContext = createContext({})
BulkNoveltiesAlertContext.displayName = 'BulkNoveltiesAlertContext'

export const useBulkNoveltiesAlert = () => {
  const context = useContext(BulkNoveltiesAlertContext)

  if (!context) {
    throw new Error(
      'useBulkNoveltiesAlert must be used within a BulkNoveltiesAlertContext provider'
    )
  }

  return context
}

const BulkNoveltiesAlertWrapper = ({ children }) => {
  const {
    openBulkNoveltiesSummaryModal,
    closeBulkNoveltiesSummaryModal,
    bulkNoveltiesSummaryModalState,
  } = useBulkNoveltiesSummaryModal()

  const {
    bulkNoveltiesCommonInfoModalState,
    openBulkNoveltiesCommonInfoModal,
    closeBulkNoveltiesCommonInfoModal,
  } = useBulkNoveltiesCommonInfoModal()

  const [state, setState] = useState({
    show: false,
    showErrorModal: false,
    messageResponse: '',
    showSummaryModal: false,
    websocketResult: {},
  })

  const openBulkNoveltiesAlert = ({
    showErrorModal,
    messageResponse,
    showSummaryModal,
    websocketResult,
  }) => {
    setState({
      show: true,
      showErrorModal,
      messageResponse,
      showSummaryModal,
      websocketResult,
    })
  }
  const closeBulkNoveltiesAlert = () => {
    setState({
      show: false,
      showErrorModal: false,
      showSummaryModal: false,
      websocketResult: {},
    })
  }

  return (
    <BulkNoveltiesAlertContext.Provider
      value={{
        openBulkNoveltiesAlert,
        closeBulkNoveltiesAlert,
        openBulkNoveltiesSummaryModal,
        closeBulkNoveltiesSummaryModal,
        bulkNoveltiesSummaryModalState,
        bulkNoveltiesCommonInfoModalState,
        openBulkNoveltiesCommonInfoModal,
        closeBulkNoveltiesCommonInfoModal,
      }}
    >
      {state.show ? (
        <BulkNoveltiesAlert
          state={state}
          handleClose={closeBulkNoveltiesAlert}
        />
      ) : null}
      {children}
    </BulkNoveltiesAlertContext.Provider>
  )
}

export default BulkNoveltiesAlertWrapper
