import { useState } from 'react'

const useSubscriptionAlertModal = () => {
  const [state, setState] = useState({
    open: false,
    subscriptionStatus: '',
  })

  const openSubscriptionAlertModal = ({
    subscriptionStatus,
    subscriptionExpiredByCancellation,
  }) => {
    setState({
      open: true,
      subscriptionStatus: subscriptionExpiredByCancellation
        ? 'premium_expired'
        : subscriptionStatus,
    })
  }

  const closeSubscriptionAlertModal = () => {
    setState({ open: false })
  }

  return {
    subscriptionAlertModalState: state,
    openSubscriptionAlertModal,
    closeSubscriptionAlertModal,
  }
}

export default useSubscriptionAlertModal
