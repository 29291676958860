import { isValid, parseISO } from 'date-fns'
import { useFormikContext } from 'formik'

import { useTheme } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'

import messages from 'messages/contract'

const InitialDayField = ({
  contract,
  optional,
  showConfirmation = false,
  ...restProps
}) => {
  const confirm = useConfirm()
  const { setFieldValue, setFieldTouched, touched } = useFormikContext()
  const themeInstance = useTheme()

  const onChange = (selectedValue) => {
    confirm({
      title: 'Cambio de fecha de contratación',
      description:
        'Vas a realizar un cambio en la fecha del contrato de la persona. Esto modifica las liquidaciones anteriores. ¿Deseas continuar?',
      okText: 'Sí',
      cancelText: 'No',
      okButtonProps: {
        sx: { padding: themeInstance.spacing(0, 10) },
      },
      cancelButtonProps: {
        sx: { padding: themeInstance.spacing(0, 10) },
      },
      adornment: {
        variant: 'asterisk',
        color: 'complementary1.light',
        width: 100,
        height: 100,
        sx: { bottom: '1rem', right: '2rem' },
      },
      onOk: () => {
        if (isValid(parseISO(selectedValue))) {
          setFieldValue('initial_day', selectedValue)

          if (!touched?.initial_day) {
            setFieldTouched('initial_day', true)
          }
        }
      },
    })
  }

  return (
    <FormField
      name="initial_day"
      label="Fecha de contratación"
      variant="datepicker"
      onChange={showConfirmation ? onChange : undefined}
      minDate={contract?.prevEndDay}
      tooltipContent={messages.initial_day}
      optional={optional}
      {...restProps}
    />
  )
}

export default InitialDayField
