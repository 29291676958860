const ALELUYA_COLORS = {
  PRIMARY: {
    dark: 'rgb(1, 148, 82)',
    main: 'rgb(2, 199, 110)',
    light: 'rgb(136, 224, 185)',
  },
  COMPLEMENTARY_1: {
    dark: 'rgb(31, 146, 204)',
    main: 'rgb(39, 183, 255)',
    light: 'rgb(115, 208, 255)',
  },
  COMPLEMENTARY_2: {
    dark: 'rgb(186, 144, 52)',
    main: 'rgb(238, 185, 67)',
    light: 'rgb(240, 208, 139)',
  },
  ACCENT_1: {
    dark: 'rgb(184, 179, 167)',
    main: 'rgb(248, 242, 225)',
    light: 'rgb(247, 247, 237)',
  },
  ACCENT_2: {
    dark: 'rgb(143, 105, 199)',
    main: 'rgb(179, 132, 250)',
    light: 'rgb(225, 208, 251)',
  },
  ACCENT_3: {
    dark: 'rgb(194, 80, 39)',
    main: 'rgb(244, 101, 50)',
    light: 'rgb(247, 156, 123)',
  },
  ACCENT_4: {
    dark: 'rgb(63, 48, 199)',
    main: 'rgb(80, 61, 249)',
    light: 'rgb(147, 135, 251)',
  },
  ERROR: {
    dark: 'rgb(128, 29, 29)',
    main: 'rgb(255, 59, 59)',
    light: 'rgb(255, 135, 135)',
  },
  SUCCESS: {
    dark: 'rgb(40, 107, 73)',
    main: 'rgb(87, 235, 161)',
    light: 'rgb(159, 238, 199)',
  },
  INFO: {
    dark: 'rgb(0, 48, 120)',
    main: 'rgb(0, 99, 247)',
    light: 'rgb(75, 144, 249)',
  },
  WARNING: {
    dark: 'rgb(128, 102, 0)',
    main: 'rgb(255, 204, 0)',
    light: 'rgb(255, 219, 77)',
  },
  WHITE: {
    dark: 'rgb(235, 235, 240)',
    main: 'rgb(255, 255, 255)',
    light: 'rgb(248, 248, 248)',
  },
  BLACK: {
    dark: 'rgb(77, 77, 77)',
    main: 'rgb(0, 0, 0)',
    light: 'rgb(153, 153, 153)',
  },
}

export default ALELUYA_COLORS
