import {
  endOfMonth,
  getWeeksInMonth,
  startOfWeek,
  today,
} from '@internationalized/date'
import { useCalendarGrid } from '@react-aria/calendar'
import { useDateFormatter, useLocale } from '@react-aria/i18n'
import { useEffect, useMemo, useRef } from 'react'

import { Box, Slide, Typography } from '@mui/material'

import { capitalize } from 'utils/general'

import CalendarCell from './CalendarCell'

const CalendarGrid = ({ state, offset = {}, gridTransition }) => {
  const { locale } = useLocale()
  const startDate = state.visibleRange.start.add(offset)
  const endDate = endOfMonth(startDate)
  const tableRef = useRef()
  const mounted = useRef(false)
  const { gridProps, headerProps } = useCalendarGrid(
    { startDate, endDate },
    state
  )
  const dayFormatter = useDateFormatter({
    weekday: 'short',
    timeZone: state.timeZone,
  })
  const weekDays = useMemo(() => {
    const weekStart = startOfWeek(today(state.timeZone), locale)
    const [first, ...restDays] = [...new Array(7).keys()].map((index) => {
      const date = weekStart.add({ days: index })
      const dateDay = date.toDate(state.timeZone)
      return capitalize(dayFormatter.format(dateDay))
    })

    return [...restDays, first]
  }, [locale, state.timeZone, dayFormatter])

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(startDate, locale)

  // Avoid the first transition when opening the popover
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }

    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <Box
      component="table"
      ref={tableRef}
      sx={(theme) => ({
        height: '100%',
        width: '100%',
        marginTop: theme.spacing(3),
        borderSpacing: 0,
        borderCollapse: 'collapse',
      })}
      {...gridProps}
      cellPadding={0}
      cellSpacing={0}
    >
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <Box
              component="th"
              sx={(theme) => ({
                color: theme.palette.black.main,
                fontWeight: 'bold',
              })}
              key={index.toString()}
            >
              <Typography
                variant="body2"
                fontWeight="bold"
                sx={{
                  userSelect: 'none',
                }}
              >
                {day}
              </Typography>
            </Box>
          ))}
        </tr>
      </thead>
      <Slide
        key={gridTransition.key}
        direction={gridTransition.direction === 'right' ? 'left' : 'right'}
        in
        appear={mounted.current}
        container={tableRef.current}
      >
        <tbody>
          {[...new Array(weeksInMonth + 1).keys()].map((weekIndex) => (
            <tr key={weekIndex}>
              {state.getDatesInWeek(weekIndex).map((date, index) => {
                if (date) {
                  return (
                    <CalendarCell
                      key={index.toString()}
                      state={state}
                      date={date.subtract({ days: 6 })}
                      startDate={startDate}
                    />
                  )
                }
                return <td key={index.toString()} />
              })}
            </tr>
          ))}
        </tbody>
      </Slide>
    </Box>
  )
}

export default CalendarGrid
