import { useState } from 'react'

const initialState = {
  open: false,
  isOnlyWorkers: false,
  isIntegrationActive: true,
  formType: 'employee',
  provider: null,
}

const useSocialSecurityPaymentModal = (
  period,
  isIntegrationActive = true,
  isOnlyWorkers = false
) => {
  const [state, setState] = useState({
    ...initialState,
    period,
    isIntegrationActive,
    isOnlyWorkers,
  })

  const openModal = ({ formType = 'employee', provider = null }) =>
    setState({ ...state, open: true, formType, provider })

  const closeModal = () =>
    setState({ ...state, open: false, formType: 'employee' })

  const activateIntegration = () =>
    setState({ ...state, isIntegrationActive: true })

  return {
    socialSecurityPaymentModalState: state,
    openSocialSecurityPaymentModal: openModal,
    closeSocialSecurityPaymentModal: closeModal,
    activateSocialSecurityPaymentIntegration: activateIntegration,
  }
}

export default useSocialSecurityPaymentModal
