import { useState } from 'react'

const useUpcomingDowngradeAlertModal = () => {
  const [state, setState] = useState({ open: false, downgradeData: {} })

  const openUpcomingDowngradeAlertModal = ({ downgradeData } = {}) => {
    setState({ open: true, downgradeData })
  }

  const closeUpcomingDowngradeAlertModal = () => {
    setState({ open: false })
  }

  return {
    upcomingDowngradeAlertModalState: state,
    openUpcomingDowngradeAlertModal,
    closeUpcomingDowngradeAlertModal,
  }
}

export default useUpcomingDowngradeAlertModal
