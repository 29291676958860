import { Link } from '@mui/material'

export default {
  payment_preferences_notification:
    'La información se agregó a la cola de actualización. En unos minutos, el cambio se reflejará en el periodo actual de tu empresa.',
  account_number:
    'Este es necesario para generar el archivo con el cual vas a pagar en la sucursal virtual de tu banco, una vez hayas liquidado tu nómina. No realizaremos ninguna transacción a esta cuenta.',
  constitution_date:
    'La fecha de constitución de tu empresa se usará para calcular los beneficios de la Ley 1429 o Ley 590, si aplican.',
  law_1429: (
    <>
      Si eres beneficiario de la Ley 1429, la liquidación de los valores en tu
      planilla podrá cambiar según el año de constitución de tu empresa, por lo
      cual, esta información es fundamental. Puedes validar si aplicas a este
      beneficio con tu contador o con nuestro equipo de soporte.{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-aplica-la-ley-1429-de-2010-en-la-liquidación-de-los-aportes-de-seguridad-social-y-parafiscales-de-tu-compañía"
        target="_blank"
        fontWeight={600}
        color="accent4.main"
      >
        Puedes conocer más acerca de esta ley aquí.
      </Link>
    </>
  ),
  law_590: (
    <>
      Si eres beneficiario de la Ley 590, la liquidación de los valores de tus
      parafiscales cambiará dependiendo del año de beneficio en que se encuentre
      tu empresa. Puedes validar si aplicas a este beneficio con tu contador o
      con nuestro equipo de soporte.{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-aplica-la-ley-590-del-2000-en-la-liquidación-de-los-aportes-parafiscales-de-tu-compañía"
        target="_blank"
        fontWeight={600}
        color="accent4.main"
      >
        Puedes conocer más acerca de esta ley aquí.
      </Link>
    </>
  ),
  law_1607: (
    <>
      Si eres beneficiario de la Ley 1607, eres exonerado del pago de
      parafiscales y salud, por lo cual, esta información es fundamental. Puedes
      validar si aplicas a este beneficio con tu contador o con nuestro equipo
      de soporte.{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-aplica-la-ley-1607-de-2012-en-la-liquidación-de-los-aportes-de-seguridad-social-y-parafiscales-de-tu-compañía"
        target="_blank"
        fontWeight={600}
        color="accent4.main"
      >
        Puedes conocer más acerca de esta ley aquí.
      </Link>
    </>
  ),
  decree_558: (
    <>
      Si quieres acogerte al beneficio del Decreto 558 de 2020, que permitió
      durante los meses de Abril y Mayo del 2020 tener una tarifa del 3% para
      pensión.{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-aplica-el-decreto-558-de-2020-en-el-pago-de-aportes-a-pensiones-de-tu-compañía"
        target="_blank"
        fontWeight={600}
        color="accent4.main"
      >
        Puedes conocer más acerca de este decreto aquí.
      </Link>
    </>
  ),
}
