import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getConfigurationOptions,
  getElectronicPayrollConfiguration,
  updateElectronicPayrollConfiguration,
} from 'services/settings/electronicPayrollConfigurationService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'electronicPayrollStatus')
    return getElectronicPayrollConfiguration()

  if (currentQueryKey === 'getConfigurationOptions')
    return getConfigurationOptions()

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, configuration } = mutationData

  if (mutationMethod === 'PATCH')
    return updateElectronicPayrollConfiguration(configuration)

  return null
}

const useElectronicPayrollConfigurationService = ({
  queryOptions = {},
  serviceParams = {
    queryKey: 'electronicPayrollStatus',
  },
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restElectronicPayroll } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const electronicPayrollConfigurationMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    electronicPayrollConfigurationQuery: { data, ...restElectronicPayroll },
    electronicPayrollConfigurationMutation,
  }
}

export default useElectronicPayrollConfigurationService
