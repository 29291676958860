import * as yup from 'yup'

import { formatDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const initialValues = {
  active: false,
  activate_since: formatDate(new Date()),
  housing_interests: 0,
  prepaid_medicine: 0,
  dependents_deduction: false,
}

export const validationSchema = yup.object({
  active: yup.bool().required(),
  activate_since: yup.string().required(),
  housing_interests: yup.number().when('active', {
    is: true,
    then: yup.number().min(0).required(),
  }),
  prepaid_medicine: yup.number().when('active', {
    is: true,
    then: yup.number().min(0).required(),
  }),
  dependents_deduction: yup.bool().when('active', {
    is: true,
    then: yup.bool().required(),
  }),
})

export const getDataToSend = (isCreated, values) => {
  let dataRetention = {}
  if (isCreated) {
    dataRetention = values.active
      ? {
          ...values,
          activate_since:
            values?.activate_since instanceof Date
              ? formatDate(values?.activate_since)
              : values?.activate_since,
        }
      : { active: false }
  } else {
    dataRetention = {
      ...values,
      activate_since:
        values?.activate_since instanceof Date
          ? formatDate(values?.activate_since)
          : values?.activate_since,
    }
  }

  return dataRetention
}
