import { Box, Card, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import calendar from 'assets/images/views/dashboard/calendar.svg'
import file from 'assets/images/views/dashboard/file.svg'
import person from 'assets/images/views/dashboard/person.svg'
import sun from 'assets/images/views/dashboard/sun.svg'
import teaCup from 'assets/images/views/dashboard/tea-cup.svg'

import StatsCounter from './Counter'

const TeamStats = ({ teamCount, workersOffDuty, isLoading }) => {
  const { subscription } = useSubscription()

  const { status: subscriptionStatus } = subscription || {}
  const isPremiumAccount = ['premium_trial', 'active_premium_hr'].includes(
    subscriptionStatus
  )
  const teamColumns = isPremiumAccount ? 5 : 4

  return (
    <Box
      sx={(theme) => ({
        gridColumn: '1 / -1',
        [theme.breakpoints.up('laptop')]: {
          gridColumn: '1 / -1',
          gridRow: '3',
        },
      })}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ' 1fr',
        }}
      >
        <Typography
          variant="h5"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          Tu equipo
        </Typography>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Card
            sx={(theme) => ({
              borderRadius: '1rem',
              padding: theme.spacing(2, 3),
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                gap: theme.spacing(2),
                gridTemplateColumns: '1fr',
                [theme.breakpoints.up('tablet')]: {
                  gridTemplateColumns: 'repeat(3, 1fr)',
                },
                [theme.breakpoints.up('laptop')]: {
                  gridTemplateColumns: `repeat(${teamColumns}, 1fr)`,
                },
              })}
            >
              <StatsCounter
                label="Personas activas"
                icon={
                  <Box component="img" src={person} sx={{ width: '3rem' }} />
                }
                total={teamCount?.active_workers}
              />
              <StatsCounter
                label="Personas incapacitadas"
                icon={
                  <Box component="img" src={teaCup} sx={{ width: '3rem' }} />
                }
                total={workersOffDuty?.on_sick_leave}
              />
              <StatsCounter
                label="Personas de licencia"
                icon={
                  <Box component="img" src={calendar} sx={{ width: '3rem' }} />
                }
                total={workersOffDuty?.on_leave}
              />
              <StatsCounter
                label="Personas en vacaciones"
                icon={<Box component="img" src={sun} sx={{ width: '3rem' }} />}
                total={workersOffDuty?.on_vacation}
              />
              {isPremiumAccount ? (
                <StatsCounter
                  label="Personas en contratación"
                  icon={
                    <Box component="img" src={file} sx={{ width: '3rem' }} />
                  }
                  total={teamCount?.onboarding_workers}
                />
              ) : null}
            </Box>
          </Card>
        )}
      </Box>
    </Box>
  )
}

export default TeamStats
