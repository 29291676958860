import { Box, Typography } from '@mui/material'

const SummaryItem = ({ label, value, isLastItem = false, center = false }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 0),
        ...(!isLastItem && {
          borderBottom: `1px dashed ${theme.palette.black.light}`,
        }),
        ...(center && {
          alignItems: 'center',
        }),
      })}
    >
      <Typography variant="body1" sx={{ color: 'black.light' }}>
        {label}
      </Typography>
      {typeof value !== 'string' ? (
        <>{value}</>
      ) : (
        <Typography variant="lead1" sx={{ textAlign: 'end' }}>
          {value}
        </Typography>
      )}
    </Box>
  )
}

export default SummaryItem
