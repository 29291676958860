import { GoogleLogin } from '@react-oauth/google'

import { useUser } from 'components/App/UserContext/useUser'

import { isBuildForCI, isProduction, isTest } from 'utils/general'
import useSessionService from 'utils/hooks/auth/session'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import messages from 'messages/notification'

const GoogleLoginButton = ({ action = 'signin' }) => {
  const { logIn } = useUser()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()

  const { sessionMutation } = useSessionService()

  const onSuccess = async (response) => {
    sessionMutation.mutateAsync(
      {
        mutationMethod: 'POST_GOOGLE',
        credentials: {
          googleId: response.clientId,
          idToken: response.credential,
        },
      },
      {
        onSuccess: ({ data }) => {
          logIn(data)
          if (action === 'signin') showSuccessMessage(messages.LOGIN_OK)
          if (isProduction && !isBuildForCI && data?.active_until) {
            window?.dataLayer?.push?.({
              event: 'signup',
            })
          }
        },
        onError: (error) => {
          handleError(error)
        },
      }
    )
  }

  if (isTest) return null

  return (
    <GoogleLogin
      size="large"
      text={action === 'signin' ? 'signin_with' : 'signup_with'}
      shape="pill"
      locale="es_ES"
      onSuccess={onSuccess}
    />
  )
}

export default GoogleLoginButton
