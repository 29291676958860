import { useMutation } from 'react-query'

import {
  createElectronicPayrollPeriod,
  generateAccountantsFile,
  newPeriod,
  reinvoicePayment,
  updateElectronicPayrollAction,
} from 'services/organizer/periodService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationAction,
    electronicPayrollPeriodId,
    mutationKey,
    period,
    accountantFileData,
  } = mutationData

  if (mutationMethod === 'PUT')
    return updateElectronicPayrollAction(
      electronicPayrollPeriodId,
      mutationAction
    )

  if (mutationMethod === 'PATCH') return reinvoicePayment()

  if (mutationMethod === 'POST' && mutationKey === 'createPeriod')
    return newPeriod(period)

  if (mutationMethod === 'POST' && mutationKey === 'generateAccountantFile')
    return generateAccountantsFile(accountantFileData)

  if (
    mutationMethod === 'POST' &&
    mutationKey === 'createElectronicPayrollPeriod'
  )
    return createElectronicPayrollPeriod(period)

  return null
}

const usePeriodService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const periodMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    periodMutation,
  }
}

export default usePeriodService
