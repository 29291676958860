import amex from 'assets/images/views/subscription/amex.svg'
import codensa from 'assets/images/views/subscription/codensa.png'
import diners from 'assets/images/views/subscription/dinersclub.svg'
import automaticDebit from 'assets/images/views/subscription/druo.svg'
import mastercard from 'assets/images/views/subscription/mastercard.svg'
import pse from 'assets/images/views/subscription/pse.svg'
import visa from 'assets/images/views/subscription/visa.svg'

import { AutomaticDebitFields } from './atoms/AutomaticDebitFields'
import { BillingInfoFields } from './atoms/BillingInfoFields'
import { CreditCardFields } from './atoms/CreditCardFields'
import { PseFields } from './atoms/PseFields'

export const creditCardOptions = [
  {
    id: 'mastercard',
    icon: mastercard,
    width: 20,
    height: 16,
  },
  {
    id: 'visa',
    icon: visa,
    width: 40,
    height: 16,
  },
  {
    id: 'amex',
    icon: amex,
    width: 18,
    height: 18,
  },
  {
    id: 'diners',
    icon: diners,
    width: 57,
    height: 16,
  },
  {
    id: 'codensa',
    icon: codensa,
    width: 32,
    height: 16,
  },
]

export const pseOptions = [
  {
    id: 'pse',
    icon: pse,
    width: 24,
    height: 24,
  },
]

export const automaticDebitOptions = [
  {
    id: 'automatic_debit',
    icon: automaticDebit,
    width: 50,
    height: 50,
  },
]

export const getPaymentFields = ({
  showCreditCard,
  showAutomaticDebitMethod,
  showPseMethod,
  expandedAccordion,
}) => {
  const paymentMethodAccordionOptions = []

  if (showCreditCard) {
    paymentMethodAccordionOptions.push({
      id: 'credit_card',
      title:
        expandedAccordion === 'credit_card'
          ? 'Ingresa los datos de tu tarjeta débito o crédito'
          : 'Tarjeta débito/crédito',
      logos: creditCardOptions,
      Fields: CreditCardFields,
    })
  }

  if (showAutomaticDebitMethod) {
    paymentMethodAccordionOptions.push({
      id: 'automatic_debit',
      title:
        expandedAccordion === 'automatic_debit'
          ? 'Ingresa los datos de tu cuenta para débito automático'
          : 'Débito Automático',
      logos: automaticDebitOptions,
      Fields: AutomaticDebitFields,
    })
  }

  if (showPseMethod) {
    paymentMethodAccordionOptions.push({
      id: 'pse',
      title: expandedAccordion === 'pse' ? 'Ingresa los datos de PSE' : 'PSE',
      logos: pseOptions,
      Fields: PseFields,
    })
  }

  paymentMethodAccordionOptions.push({
    id: 'billing_info',
    title:
      expandedAccordion === 'billing_info'
        ? 'Edita tus datos de facturación'
        : 'Datos de facturación',
    Fields: BillingInfoFields,
  })

  return paymentMethodAccordionOptions
}

export const getCurrentPaymentMethods = (currentPaymentMethodsArray) => {
  return currentPaymentMethodsArray
    ?.map((currentPaymentMethod) => currentPaymentMethod.category)
    ?.filter((currentPaymentMethod) => currentPaymentMethod !== 'pse')
}

export const getMainPaymentMethod = (currentPaymentMethodsArray) => {
  return currentPaymentMethodsArray?.find(
    (currentPaymentMethod) => currentPaymentMethod.main_payment_method
  )
}

export const findPaymentMethod = (
  currentPaymentMethodsArray,
  paymentCategory
) => {
  return currentPaymentMethodsArray?.find(
    (currentPaymentMethod) => currentPaymentMethod.category === paymentCategory
  )
}
