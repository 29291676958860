import { useQueryClient } from 'react-query'

import { Box, Button as MButton, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'

import { getCompanyId } from 'utils/company'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import useNotifications from 'utils/hooks/useNotifications'

const DeleteWorkCenter = ({ workCenterId, handleCloseModal }) => {
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()

  const { companyWorkCentersMutation } = useCompanyWorkCenters({
    queryOptions: { enabled: false },
  })

  const handleDelete = () => {
    companyWorkCentersMutation.mutate(
      {
        mutationMethod: 'DELETE',
        workCenterId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['getWorkCenters', companyId])
          showSuccessMessage('Centro de trabajo eliminado correctamente')
          handleCloseModal()
        },
      }
    )
  }

  return (
    <div>
      <Typography variant="body1" color="black.light">
        ¿Estás seguro que quieres eliminar este centro de trabajo? si lo
        eliminas puedes afectar la actividad CIIU y el nivel de riesgo que
        tienen los empleados asignados a este centro de trabajo.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          margin: theme.spacing(5, 0, 4, 0),
          gap: theme.spacing(2),
        })}
      >
        <MButton variant="outlined" onClick={handleCloseModal}>
          Volver
        </MButton>
        <Button
          onClick={handleDelete}
          loading={companyWorkCentersMutation.isLoading}
        >
          Eliminar centro de trabajo
        </Button>
      </Box>
    </div>
  )
}

export default DeleteWorkCenter
