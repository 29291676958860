import NumberFormat from 'react-number-format'

import { OutlinedInput } from '@mui/material'

const NumberField = ({ id, name, value, ...props } = {}) => {
  const { setValue, onBlur, isAllowed, ...restProps } = props

  return (
    <NumberFormat
      id={id}
      name={name}
      value={value}
      onBlur={onBlur}
      isAllowed={isAllowed}
      onValueChange={(values) => {
        setValue(values.value)
      }}
      customInput={OutlinedInput}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      {...restProps}
    />
  )
}

export default NumberField
