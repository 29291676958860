export const getIcon = {
  criminal_records: 'policeman',
  disciplinary_records: 'privacy-policy',
  fiscal_history: 'archive',
  ofac_compliance: 'document-signature',
}

export const getStatus = {
  in_progress: 'En proceso',
  finished: 'Terminado',
  pending: 'Sin respuesta',
  not_apply: 'No aplica',
  unstarted: 'Sin comenzar',
}

export const statusColor = {
  in_progress: 'info',
  finished: 'success',
  pending: 'error',
  not_apply: 'warning',
  unstarted: 'warning',
}

export const getStatusBackgroundColor = (theme, status) => {
  const currentStatusColor = {
    in_progress: theme.palette.info.light,
    finished: theme.palette.success.light,
    pending: theme.palette.error.light,
    not_apply: theme.palette.warning.main,
    unstarted: theme.palette.warning.main,
  }

  return currentStatusColor[status]
}

const institutionsOrder = [
  'criminal_records',
  'disciplinary_records',
  'fiscal_history',
  'ofac_compliance',
]

export const sortInstitutionsByName = (institutions = []) => {
  return institutions.sort((a, b) => {
    const indexA = institutionsOrder.indexOf(a.coded_name)
    const indexB = institutionsOrder.indexOf(b.coded_name)

    if (indexA < indexB) return -1

    if (indexA > indexB) return 1

    return 0
  })
}
