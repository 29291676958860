import { useEffect, useState } from 'react'

import { Box, Divider, Paper, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { wait } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import socialSecurityService from 'services/payroll/socialSecurityService'

const DynamicLoaderItem = ({ position, content, progressPosition }) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-start',
          padding: theme.spacing(1.5, 0, 1.5, 4),
          ...(position > progressPosition && {
            color: theme.palette.white.light,
          }),
        })}
      >
        {position > progressPosition ? (
          <Icon
            name="help-center"
            basic
            sx={(theme) => ({
              marginRight: theme.spacing(2),
            })}
          />
        ) : (
          <Icon
            name="approved-checked"
            basic
            sx={(theme) => ({
              marginRight: theme.spacing(2),
            })}
          />
        )}
        <Typography variant="body1">{content}</Typography>
      </Box>
    </>
  )
}

const PeriodSocialSecurityPaymentModalStepTwo = ({
  periodId,
  formType,
  completed,
  setCompleted,
  handleClose,
  provider,
  providerName,
}) => {
  const [progressPosition, setProgressPosition] = useState(1)
  const { handleError } = useErrorHandler()
  const { showErrorMessage } = useNotifications()

  useEffect(() => {
    const firstFetch = async () => {
      try {
        await socialSecurityService.getGenerateConfirmation(
          periodId,
          formType,
          provider
        )
        setProgressPosition(2)
      } catch (error) {
        if (error.errors) {
          const errors = error.errors[0]

          showErrorMessage(errors.message)
        } else {
          handleError(error)
        }
        handleClose()
      }
    }

    const secondFetch = async () => {
      try {
        await socialSecurityService.getValidateConfirmation(periodId, formType)

        setProgressPosition(3)
        wait(500) // This is for delay to complete te job, product requeriment
        setProgressPosition(4)
        setCompleted(true)
      } catch (error) {
        handleError(error)
        handleClose()
      }
    }

    if (progressPosition === 2) secondFetch()
    if (progressPosition === 1) firstFetch()
  }, [
    formType,
    handleClose,
    handleError,
    periodId,
    progressPosition,
    provider,
    setCompleted,
    showErrorMessage,
  ])

  return (
    <>
      <Typography variant="lead1">Creación de planilla</Typography>
      <Typography variant="body1">
        Este proceso puede tardar unos minutos.
      </Typography>

      <Paper
        sx={(theme) => ({
          display: 'flex',
          borderRadius: '0.75rem',
          padding: theme.spacing(1.5, 0, 1.5, 4),
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
          ...(completed && {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
          }),
        })}
      >
        <Typography
          variant="lead1"
          sx={{
            color: 'initial',
          }}
        >
          {completed
            ? '¡Tu planilla está lista!'
            : 'Estamos preparando tu pago...'}
        </Typography>
      </Paper>
      <Paper
        sx={(theme) => ({
          borderRadius: '0.75rem',
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        })}
      >
        <DynamicLoaderItem
          position={1}
          content={`Creando planilla en ${providerName}`}
          progressPosition={progressPosition}
        />
        <Divider />
        <DynamicLoaderItem
          position={2}
          content="Validando errores y advertencias"
          progressPosition={progressPosition}
        />
        <Divider />
        <DynamicLoaderItem
          position={3}
          content="Generando link de pago"
          progressPosition={progressPosition}
        />
      </Paper>
    </>
  )
}

export default PeriodSocialSecurityPaymentModalStepTwo
