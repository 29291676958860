import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  name: yup.string().trim().nullable().required(),
  email: yup
    .string()
    .email('Debe ser un correo electrónico válido!')
    .required(),
  phone: yup.string().nullable().required(),
  role: yup.string().nullable().required(),
  active: yup.bool(),
})

export default validationSchema
