export const detailsRole = [
  {
    id: 'payroll_assistant',
    role: 'Soy responsable de nómina donde trabajo',
    iconName: 'bribery',
  },
  {
    id: 'accountant',
    role: 'Contador independiente',
    iconName: 'refund',
  },
  {
    id: 'outsourcer',
    role: 'Outsourcing de nómina',
    iconName: 'consultation',
  },
  {
    id: 'businessman',
    role: 'Empresario o gerente',
    iconName: 'management-team',
  },
  {
    id: 'hr_lead',
    role: 'Trabajo en recursos humanos',
    iconName: 'longevity',
  },
  {
    id: 'other',
    role: 'Otro',
    detail:
      'Selecciona esta opción si no te identificas con ninguno de los roles',
    dataCy: 'other-role-button',
    iconName: 'engage-users',
  },
]

export const getValuesToSend = (selectRole, otherRoleInformation) => {
  let valuesToSend = { onboarding_step: 'data' }

  if (selectRole === 'other') {
    valuesToSend = {
      ...valuesToSend,
      user_role: 'other',
      user_role_other_info: otherRoleInformation,
    }
  } else {
    valuesToSend = { ...valuesToSend, user_role: selectRole }
  }

  return valuesToSend
}
