import { Backdrop, CircularProgress, alpha } from '@mui/material'

const LoadingBackdrop = ({ open, showLoader = true, sx, ...props }) => {
  return (
    <Backdrop
      open={open}
      sx={[
        (theme) => ({
          zIndex: theme.zIndex.drawer + 1,
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: alpha(theme.palette.white.main, 0.8),
        }),
        sx,
      ]}
      {...props}
    >
      {showLoader ? <CircularProgress color="primary" /> : null}
    </Backdrop>
  )
}

export default LoadingBackdrop
