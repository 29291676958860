import { useState } from 'react'

const useInProcessModal = () => {
  const [state, setState] = useState({
    open: false,
    workerId: null,
  })

  const closeInProcessModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openInProcessModal = ({ workerId }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      workerId,
    }))
  }

  const resetInProcessModalState = () => {
    setState((previousState) => ({
      ...previousState,
      workerId: null,
    }))
  }

  return {
    inProcessModalState: state,
    closeInProcessModal,
    openInProcessModal,
    resetInProcessModalState,
  }
}

export default useInProcessModal
