import { Box, Link, Typography } from '@mui/material'

import AddButton from 'components/UI/Button/AddButton'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import messages from 'messages/electronic_payroll'

import OptionSelector from './OptionSelector'
import useOtherIncomes from './useOtherIncomes'

const OtherIncomes = ({ showModal, handleClose, electronicPayrollId }) => {
  const {
    handleSubmit,
    handleAddConcept,
    handleSelectItem,
    handleChangeItemValue,
    handleDeleteItem,
    incomes,
    concepts,
    isQueryLoading,
    isMutatingData,
  } = useOtherIncomes(handleClose, electronicPayrollId)

  return (
    <Modal
      open={showModal}
      header="Otros ingresos"
      onOk={() => handleSubmit(incomes)}
      okText="Guardar"
      onCancel={handleClose}
      fullScreenBreakpoint="xs"
      isLoading={isMutatingData}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(1),
        })}
      >
        <Typography paragraph>
          Aquí podrás agregar todos los Pagos Adicionales que tiene tu empleado
          por medio de Bonos Electrónicos, Dotación, Pagos alimenticios y demás.
          Para conocer en detalle cada uno de estos conceptos, lee este artículo
          de nuestro{' '}
          <Link
            href={messages.ELECTRONIC_PAYROLL_BONUS_CONCEPT}
            target="_blank"
          >
            centro de ayuda.
          </Link>
        </Typography>
        {!isQueryLoading ? (
          <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '62% 38%',
                justifyItems: 'flex-start',
              }}
            >
              <Typography variant="lead2">Concepto</Typography>
              <Typography variant="lead2">Valor</Typography>
            </Box>
            <div>
              <OptionSelector
                data={incomes}
                listOptions={concepts}
                onSelectItem={handleSelectItem}
                onChangeItemValue={handleChangeItemValue}
                onDeleteItem={handleDeleteItem}
                category="other_income"
                name="payroll_concept_id"
              />
            </div>

            <AddButton
              onClick={() => handleAddConcept()}
              disabled={concepts.length === incomes.length}
            >
              Agregar ingreso
            </AddButton>
          </>
        ) : (
          <LoadingBox />
        )}
      </Box>
    </Modal>
  )
}

export default OtherIncomes
