import { useState } from 'react'

const useNewWorkerModal = () => {
  const [state, setState] = useState({
    open: false,
    workerId: null,
    workerName: '',
  })

  const closeNewWorkerModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openNewWorkerModal = ({ workerId, workerName } = {}) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      workerId: workerId || null,
      workerName,
    }))
  }

  return {
    newWorkerModalState: state,
    closeNewWorkerModal,
    openNewWorkerModal,
  }
}

export default useNewWorkerModal
