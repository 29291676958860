import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createContract,
  createContractDetail,
  deleteContractDetail,
  getAllContractDetail,
  getAllContracts,
  getContractById,
  getCurrentContract,
  updateContract,
  updateContractDetail,
} from 'services/worker/contractService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId, contractId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'allContracts') return getAllContracts(workerId)

  if (currentQueryKey === 'contractById') return getContractById(contractId)

  if (currentQueryKey === 'currentContract') return getCurrentContract(workerId)

  if (currentQueryKey === 'getAllContractDetail')
    return getAllContractDetail(contractId)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationKey,
    workerId,
    contractId,
    contract,
    contractDetail,
    contractDetailId,
  } = mutationData

  if (mutationMethod === 'POST' && mutationKey === 'createContractDetail')
    return createContractDetail(contractId, contractDetail)

  if (mutationMethod === 'POST' && mutationKey === 'createContract')
    return createContract(workerId, contract)

  if (mutationMethod === 'PUT')
    return updateContractDetail(contractDetailId, contractDetail)

  if (mutationMethod === 'PATCH') return updateContract(contract)

  if (mutationMethod === 'DELETE' && mutationKey === 'deleteContractDetail')
    return deleteContractDetail(contractDetailId)

  return null
}

const useContractsService = ({
  serviceParams = { queryKey: 'allContracts' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restContractsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const contractsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    contractsQuery: {
      data,
      ...restContractsResponse,
    },
    contractsMutation,
  }
}

export default useContractsService
