import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import Loading from 'components/UI/Loading/Loading'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import useSocialBenefitsService from 'utils/hooks/payroll/socialBenefits'

import ContentWrapper from './ContentWrapper'
import SocialBenefitDetail from './SocialBenefitDetail'
import TableContent from './TableContent'

const SocialBenefits = () => {
  const { periodId, socialBenefitPeriodId } = useParams()
  const companyId = getCompanyId()

  const { socialBenefitsQuery } = useSocialBenefitsService({
    serviceParams: {
      queryKey: ['getSocialBenefitsPeriod', periodId, companyId],
      periodId,
      socialBenefitsPeriodId: socialBenefitPeriodId,
    },
  })

  if (socialBenefitsQuery.isLoading) {
    return <Loading />
  }

  return (
    <Page documentTitle={socialBenefitsQuery?.data?.label} grid header={null}>
      {socialBenefitsQuery.data ? (
        <Box
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          <ContentWrapper>
            <SocialBenefitDetail />
            <TableContent />
          </ContentWrapper>
        </Box>
      ) : (
        <Box
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          <Alert severity="error">
            Mes inválido para generar pago de prestaciones sociales.
          </Alert>
        </Box>
      )}
    </Page>
  )
}

export default SocialBenefits
