import { Box, useTheme } from '@mui/material'

import { ReactComponent as Profile } from 'assets/images/avatars/profile.svg'

/**
 * Component to render some SVG adornments
 * @param {number} width - The width of the rendered SVG
 * @param {number} height - The height of the rendered SVG
 * @param {string} color - Color of the rendered SVG. Must be one of the valid colors defined in the theme
 * @param {object} sx - Styles applied to SVG's container
 */
const ProfileImg = ({
  width = 'auto',
  height = 'auto',
  color = 'primary',
  sx = {},
} = {}) => {
  const theme = useTheme()
  const colorKeys = color.split('.')
  const themeColor = color?.includes('.')
    ? theme.palette[colorKeys[0]][colorKeys[1]]
    : theme.palette[colorKeys[0]]?.main

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <Profile width={width} height={height} fill={themeColor} />
    </Box>
  )
}

export default ProfileImg
