import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { getFileNameFromUrl, isValidUrl } from 'utils/general'

import BeneficiariesStep from './Beneficiaries/Index'

const AttachDocumentsStep = () => {
  const { values, setFieldValue, initialValues } = useFormikContext()
  const { worker } = values
  const identificationDocumentUrl = isValidUrl(values?.identification_document)
  const contractDocumentUrl = isValidUrl(values?.contract_document)

  const bankCertificateDocumentUrl = isValidUrl(
    values?.bank_certificate_document
  )

  const identificationDocumentName = getFileNameFromUrl(
    identificationDocumentUrl ? identificationDocumentUrl.search : undefined
  )

  const contractDocumentName = getFileNameFromUrl(
    contractDocumentUrl ? contractDocumentUrl.search : undefined
  )

  const bankCertificateDocumentName = getFileNameFromUrl(
    bankCertificateDocumentUrl ? bankCertificateDocumentUrl.search : undefined
  )

  const showContractField = [
    'schooling_trainee',
    'productive_trainee',
    'intern',
    'student_decree_055',
    'student_law_789',
  ].includes(values?.contract_category)

  const showAddBeneficiary = ['health_provider', 'compensation_fund'].some(
    (entity) => values?.entities_to_affiliate?.includes(entity)
  )

  // Workaround to preserve documents when submitting beneficiaries form
  const preservedDocumentsRef = useRef({
    launchPreservedDocuments: false,
    identification_document: values.identification_document,
    contract_document: values.contract_document,
    bank_certificate_document: values.bank_certificate_document,
  })

  const preserveDocuments = () => {
    preservedDocumentsRef.current = {
      launchPreservedDocuments: true,
      identification_document: values.identification_document,
      contract_document: values.contract_document,
      bank_certificate_document: values.bank_certificate_document,
    }
  }

  useEffect(() => {
    if (preservedDocumentsRef.current.launchPreservedDocuments) {
      setFieldValue(
        'identification_document',
        preservedDocumentsRef.current.identification_document
      )
      setFieldValue(
        'contract_document',
        preservedDocumentsRef.current.contract_document
      )
      setFieldValue(
        'bank_certificate_document',
        preservedDocumentsRef.current.bank_certificate_document
      )

      preservedDocumentsRef.current.launchPreservedDocuments = false
    }
  }, [setFieldValue, initialValues])

  return (
    <>
      <Box
        sx={(theme) => ({
          margin: theme.spacing(2.5, 0, 5, 0),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <Typography variant="body1" color="black.dark">
          Adjunta la cédula de la persona, con el fin de poder validar la
          información y obtener los datos necesarios para la afiliación a
          seguridad social.
        </Typography>
        <FormField
          name="identification_document"
          label="Cédula de la persona"
          variant="file"
          fileName={identificationDocumentName}
          fileType="application/pdf"
          accept="application/pdf"
          optional={false}
        />
        {showContractField ? (
          <FormField
            name="contract_document"
            label="Contrato de aprendizaje"
            variant="file"
            fileName={contractDocumentName}
            fileType="application/pdf"
            accept="application/pdf"
            optional={false}
          />
        ) : null}
        {!bankCertificateDocumentUrl ? (
          <>
            <Typography variant="body1" color="black.dark">
              Adjunta la certificación bancaria de tu empresa.
            </Typography>
            <FormField
              name="bank_certificate_document"
              label="Certificación bancaria de la empresa"
              variant="file"
              fileName={bankCertificateDocumentName}
              fileType="application/pdf"
              accept="application/pdf"
              optional
            />
          </>
        ) : null}
      </Box>

      {showAddBeneficiary ? (
        <BeneficiariesStep
          worker={worker}
          selectedEntitiesToAffiliate={values?.entities_to_affiliate}
          isTrainee={showContractField}
          preserveDocuments={preserveDocuments}
        />
      ) : null}
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(5),
        })}
      >
        <FormField
          name="comment"
          label="Para finalizar, describe a detalle tu solicitud, compartiendo información relevante para la misma. Por ejemplo, ¿la persona ya está afiliada a otras entidades? ¿Cuáles?"
          variant="textarea"
          rows={4}
        />
      </Box>
    </>
  )
}

export default AttachDocumentsStep
