import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'
import ContractCategoryField from 'components/Worker/Contract/Fields/Category'
import EndDayField from 'components/Worker/Contract/Fields/EndDay'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import TermField from 'components/Worker/Contract/Fields/Term'
import BaseSalaryField from 'components/Worker/Wage/Fields/BaseSalary'
import WageCategoryField from 'components/Worker/Wage/Fields/Category'

const WorkerOnBoardingFormFields = () => {
  const form = useFormikContext()
  const { values } = form

  return (
    <>
      <Typography variant="h6" color="primary" gutterBottom>
        Datos principales
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2.5),
          columnGap: theme.spacing(4),
          marginBottom: theme.spacing(4),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <FormField name="name" label="Nombre" optional={false} />
        <FormField name="last_name" label="Apellidos" optional={false} />
        <DocumentTypeField optional={false} />
        <IdentificationNumberField optional={false} />
      </Box>
      <Typography variant="h6" color="primary" gutterBottom>
        Información laboral
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2.5),
          columnGap: theme.spacing(4),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <ContractCategoryField name="contract_category" optional={false} />
        <TermField
          contractCategory={values.contract_category}
          optional={false}
        />
        <InitialDayField optional={false} />
        <EndDayField optional={false} />
        <WageCategoryField
          name="wage_category"
          label="Salario base"
          contractCategory={values.contract_category}
          optional={false}
        />
        <BaseSalaryField
          contractCategory={values.contract_category}
          optional={false}
        />
      </Box>
    </>
  )
}

export default WorkerOnBoardingFormFields
