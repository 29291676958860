import { useUser } from 'components/App/UserContext/useUser'

import { getCompanyId, hasOneCompany } from 'utils/company'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import * as routes from 'config/routes'

import useCloseDrawer from '../../useCloseDrawer'

export default function useWorker() {
  const { active, clearUser } = useUser()
  const handleCloseMobileDrawer = useCloseDrawer()

  const companyId = getCompanyId()
  const { holisticPayrollMVP, flagsReady } = useFeatureFlags({
    flags: ['holisticPayrollMVP'],
    trackingMode: 'attributes',
    attributes: {
      companyId,
    },
  })

  const hideWorkerFinancialWellnessOption =
    !flagsReady || holisticPayrollMVP?.value !== 'on' || !active

  return [
    {
      key: 'informations',
      icon: 'avatar-user',
      text: 'Mi perfil',
      to: routes.WORKER_PROFILE_SHOW(),
      hidden: !active,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'payroll_history',
      icon: 'clock-time',
      text: 'Historial de nómina',
      to: routes.WORKER_PROFILE_PAYMENTS_HISTORY(),
      hidden: !active,
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [routes.WORKER_PAYMENT_DETAIL()],
    },
    {
      key: 'financial_wellness',
      text: 'Bienestar financiero',
      icon: 'wired-outline-internship',
      to: routes.WORKER_PROFILE_FINANCIAL_WELLNESS(),
      hidden: hideWorkerFinancialWellnessOption,
      badge: 'Beta',
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'companies',
      icon: 'grid-list',
      text: 'Empresas',
      to: routes.COMPANY_INDEX(),
      hidden: hasOneCompany(),
      onClick: () => {
        clearUser()
        handleCloseMobileDrawer()
      },
    },
  ]
}
