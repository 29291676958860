/* eslint-disable */
import TagManager from 'react-gtm-module'

export const googleTagManagerScript = {
  child: () => googleTagManagerSriptCode(),
  header: true,
}

const googleTagManagerSriptCode = () => {
  TagManager.initialize({ gtmId: 'GTM-MD6KLN' })
}
