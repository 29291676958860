import http, { getApiURL } from '../httpService'

export const getAllDistributors = () => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/distributors',
  })

  return http.getV2(url)
}

export default {
  getAllDistributors,
}
