import { createContext, useMemo } from 'react'

import { Box, Drawer } from '@mui/material'

import SidebarContent from './Content'

export const SidebarContext = createContext()

const AppSidebar = ({
  mobileOpen,
  handleDrawerToggle,
  openUpdatePaymentMethodInfoModal,
}) => {
  const container =
    window !== undefined ? () => window.document.body : undefined

  const contextValue = useMemo(
    () => ({
      handleDrawerToggle,
      mobileOpen,
    }),
    [handleDrawerToggle, mobileOpen]
  )

  return (
    <SidebarContext.Provider value={contextValue}>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          elevation: 0,
        }}
        sx={(theme) => ({
          display: {
            mobile: 'initial',
            laptop: 'none',
          },
          marginTop: theme.mobileAppbar,
          '& .MuiBackdrop-root': {
            top: theme.mobileAppbar,
          },
          '& .MuiPaper-root': {
            width: '100%',
            marginTop: theme.mobileAppbar,
            height: `calc(100% - ${theme.mobileAppbar})`,
            backgroundColor: theme.palette.white.main,
            boxShadow: theme.shadows[7],
          },
        })}
      >
        <SidebarContent
          openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
        />
      </Drawer>
      <Box
        sx={(theme) => ({
          height: '100%',
          width: '16rem',
          backgroundColor: theme.palette.white.main,
          boxShadow: theme.shadows[7],
          display: {
            mobile: 'none',
            laptop: 'block',
          },
          zIndex: '1',
          overflow: 'hidden',
        })}
      >
        <SidebarContent
          openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
        />
      </Box>
    </SidebarContext.Provider>
  )
}

export default AppSidebar
