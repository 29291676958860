import { useState } from 'react'

const initialState = {
  open: false,
  initialTab: 'errors',
}

const useErrorsModal = () => {
  const [state, setState] = useState(initialState)

  const openModal = (initialTab = 'errors') => {
    setState({ ...state, open: true, initialTab })
  }

  const closeModal = () => setState({ ...state, open: false })

  return {
    errorsModalState: state,
    openErrorsModal: openModal,
    closeErrorsModal: closeModal,
  }
}

export default useErrorsModal
