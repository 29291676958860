import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import messages from 'messages/termination'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  id: yup.string().nullable(),
  contract_id: yup.string().nullable(),
  payroll_id: yup.string().nullable(),
  cause: yup.string().required('La causa es requerida').nullable(),
  possible_trial_period: yup.boolean().nullable(),
  trial_period: yup.boolean().nullable(),
  with_just_cause: yup.boolean().nullable(),
  hire_date: yup.string().nullable(),
  date: yup.string().nullable(),
  min_date: yup.string().nullable(),
  max_date: yup.string().nullable(),
  worked_days: yup.number().nullable(),
  holidays: yup.string().nullable(),
  accumulated_holidays: yup.number().nullable(),
  holidays_value: yup.number().nullable(),
  termination_base: yup.number().nullable(),
  holidays_base: yup.number().nullable(),
  service_bonus_base: yup.number().nullable(),
  severance_base: yup.number().nullable(),
  previous_service_bonus_base: yup.number().nullable(),
  previous_severance_base: yup.number().nullable(),
  service_bonus_days: yup.number().nullable(),
  previous_service_bonus_days: yup.number().nullable(),
  severance_days: yup.number().nullable(),
  previous_severance_days: yup.number().nullable(),
  severance_interests_days: yup.number().nullable(),
  previous_severance_interests_days: yup.number().nullable(),
  pay_rest_days: yup.boolean().nullable(),
  show_pay_service_bonus: yup.boolean().nullable(),
  pay_service_bonus: yup.boolean().nullable(),
  pay_previous_service_bonus: yup.boolean().nullable(),
  show_pay_previous_severance: yup.boolean().nullable(),
  pay_previous_severance: yup.boolean().nullable(),
  show_pay_previous_severance_interests: yup.boolean().nullable(),
  pay_previous_severance_interests: yup.boolean().nullable(),
  non_salary_income: yup.number().nullable(),
  loans: yup.number().nullable(),
  deductions: yup.number().nullable(),
  anticipated_social_benefits_value: yup.number().nullable(),
  notes: yup.string().nullable(),
  show_payroll_credit: yup.boolean().nullable(),
  payroll_credit_value: yup.number().nullable(),
  show_decree376: yup.boolean().nullable(),
  pay_decree376: yup.boolean().nullable(),
  decree376: yup.number().nullable(),
  service_bonus: yup.number().nullable(),
  previous_service_bonus: yup.number().nullable(),
  severance: yup.number().nullable(),
  previous_severance: yup.number().nullable(),
  severance_interests: yup.number().nullable(),
  previous_severance_interests: yup.number().nullable(),
  compensation_fund: yup.number().nullable(),
  sena: yup.number().nullable(),
  icbf: yup.number().nullable(),
  compensation: yup.number().nullable(),
  worker_payment: yup.number().nullable(),
  company_cost: yup.number().nullable(),
  bank_file: yup.boolean().nullable(),
  compensation_retention: yup.number().nullable(),
  service_bonus_retention: yup.number().nullable(),
})

export const getColumnData = () => {
  return [
    {
      Header: <Typography variant="h3">Concepto</Typography>,
      accessor: 'name',
    },
    {
      Header: <Typography variant="h3">Valor</Typography>,
      accessor: 'value',
      Cell: ({ row }) => (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            columnGap: theme.spacing(0.5),
            ...(!row.original?.tooltip && { marginRight: theme.spacing(3) }),
          })}
        >
          <Typography variant="lead1">
            {row.original.value < 0 ||
            (row.original.isDeduction && row.original.value > 0)
              ? `- ${formatCurrency(Math.abs(row.original.value))}`
              : formatCurrency(row.original.value)}
          </Typography>
          {row.original?.tooltip ? (
            <TooltipInfoIcon
              title={row.original?.tooltip}
              tooltipProps={{
                disableInteractive: false,
              }}
            />
          ) : null}
        </Box>
      ),
      alignCell: 'right',
      alignHeader: 'right',
    },
  ]
}

export const getTableData = (values) => {
  const data = [
    {
      name: 'Vacaciones',
      value: values.holidays_value,
      tooltip: messages.holidays_value,
      isDeduction: false,
    },
    {
      name: 'Cesantías',
      value: values.severance,
      tooltip: messages.severance,
      isDeduction: false,
    },
  ]

  if (values.pay_previous_severance) {
    data.push({
      name: 'Cesantías año anterior',
      value: values.previous_severance,
      isDeduction: false,
    })
  }

  data.push({
    name: 'Intereses a las Cesantías',
    value: values.severance_interests,
    tooltip: messages.severance_interests,
    isDeduction: false,
  })

  if (values.pay_previous_severance_interests) {
    data.push({
      name: 'Intereses a las Cesantías año anterior',
      value: values.previous_severance_interests,
      tooltip: messages.severance_interests,
      isDeduction: false,
    })
  }

  data.push({
    name: 'Prima de Servicios',
    value: values.service_bonus,
    tooltip: messages.service_bonus,
    isDeduction: false,
  })

  if (values.pay_previous_service_bonus) {
    data.push({
      name: 'Prima de Servicios semestre anterior',
      value: values.previous_service_bonus,
      isDeduction: false,
    })
  }

  if (values.withholding_tax) {
    data.push({
      name: 'Retención en la fuente (Prima e indemnización)',
      value: values.withholding_tax,
      isDeduction: true,
    })
  }

  data.push({
    name: 'Indemnización por despido',
    value: values.compensation,
    tooltip: messages.compensation,
    isDeduction: false,
  })

  data.push({
    name: 'Otros',
    value:
      values.non_salary_income -
      (values.deductions || 0) -
      (values.loans || 0) -
      values.anticipated_social_benefits_value -
      values.payroll_credit_value -
      (values.pay_decree376 ? values.decree376 : 0),
    isDeduction: false,
  })

  return data
}
