import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createPreview,
  createTermination,
  deleteTermination,
  getPaymentsHistory,
  getTermination,
  updateTermination,
} from 'services/worker/terminationService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, contractId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getTermination') return getTermination(contractId)
  if (currentQueryKey === 'getTerminationPreview')
    return createPreview(contractId)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    contractId,
    date,
    termination,
    fileFormat,
  } = mutationData

  if (mutationMethod === 'GET') return getTermination(contractId)
  if (mutationMethod === 'GET_PAYMENTS_HISTORY')
    return getPaymentsHistory(contractId, date)
  if (mutationMethod === 'CREATE')
    return createTermination(contractId, termination)
  if (mutationMethod === 'UPDATE')
    return updateTermination(contractId, termination)
  if (mutationMethod === 'CREATE_PREVIEW')
    return createPreview(contractId, termination, { fileFormat })
  if (mutationMethod === 'DELETE') return deleteTermination(contractId)

  return null
}

const useTerminationService = ({
  serviceParams = { queryKey: 'getTermination' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restTerminationResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const terminationMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    terminationQuery: {
      data,
      ...restTerminationResponse,
    },
    terminationMutation,
  }
}

export default useTerminationService
