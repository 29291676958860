import http, { getApiURL } from '../httpService'

export const getAllWorkersWages = ({ search, page } = {}) => {
  const url = getApiURL({
    pathname: '/workers/wages_update',
    searchParams: { page, search },
  })

  return http.getV2(url)
}

export const getAllWages = (contractId) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}/wages` })

  return http.getV2(url)
}

export const updateWorkerWage = (wage) => {
  const url = getApiURL({ pathname: `/workers/wages_update` })

  return http.postV2(url, { wage })
}

export const getCurrentContract = (contractId) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}/wage` })

  return http.getV2(url)
}

export const createContract = (contractId, wage) => {
  const url = getApiURL({ pathname: `/contracts/${contractId}/wages` })

  const data = { wage }

  return http.postV2(url, data)
}

export const updateWage = (wage) => {
  const url = getApiURL({ pathname: `/wages/${wage.id}` })

  const data = { wage }

  return http.patchV2(url, data)
}

export const deleteWage = (wageId) => {
  const url = getApiURL({ pathname: `/wages/${wageId}` })

  return http.deleteV2(url)
}

export { deleteWage as delete }

export default {
  getAllWorkersWages,
  getAllWages,
  updateWorkerWage,
  getCurrentContract,
  createContract,
  updateWage,
  delete: deleteWage,
}
