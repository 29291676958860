import { Box, Grid, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

const Item = ({ item }) => {
  const { label, value, icon } = item

  return (
    <Grid item xs={12} tablet={6}>
      <Box
        sx={(theme) => ({
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '3.125rem 1fr',
          gap: theme.spacing(1),
          ...(!icon && {
            gridTemplateColumns: '1fr',
          }),
        })}
      >
        {icon ? (
          <Box
            sx={(theme) => ({
              display: 'flex',
              backgroundColor: theme.palette.complementary1.light,
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: theme.spacing(1),
              minWidth: '3.125rem',
              maxWidth: '3.125rem',
              minHeight: '3.125rem',
              maxHeight: '3.125rem',
            })}
          >
            <Icon name={icon} sx={{ fontSize: '2rem' }} />
          </Box>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            title={typeof value === 'string' ? value : undefined}
            variant="h5"
            sx={(theme) => ({
              wordBreak: 'break-word',
              width: typeof value === 'string' ? 'fit-content' : '100%',
              ...(!value && {
                color: theme.palette.primary.light,
              }),
            })}
          >
            {value || 'Pendiente'}
          </Typography>
          <Typography variant="body1" color="black.dark">
            {label}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default Item
