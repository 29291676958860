import http, { getApiURL } from '../httpService'

export const getAllWorkCenters = () => {
  const url = getApiURL({ pathname: '/work_centers' })

  return http.getV2(url)
}

export const saveWorkCenter = (workCenter) => {
  const url = getApiURL({ pathname: '/work_centers' })

  return http.postV2(url, workCenter)
}

export const updateWorkCenter = (workCenterId, workCenterData) => {
  const url = getApiURL({ pathname: `/work_centers/${workCenterId}` })

  return http.putV2(url, workCenterData)
}

export const getCIIU = (searchParams) => {
  const url = getApiURL({
    pathname: '/activity_codes',
    withoutCompany: true,
    searchParams,
  })

  return http.getV2(url)
}

export const getWorkCenterHistory = ({ page, workCenterId }) => {
  const url = getApiURL({
    pathname: `/work_centers/${workCenterId}`,
    searchParams: { page },
  })

  return http.getV2(url)
}

export const deleteWorkCenter = (workCenterId) => {
  const url = getApiURL({
    pathname: `/work_centers/${workCenterId}`,
  })

  return http.deleteV2(url)
}

export default {
  getAllWorkCenters,
  saveWorkCenter,
  getCIIU,
  getWorkCenterHistory,
  deleteWorkCenter,
}
