import Cleave from 'cleave.js/react'
import { forwardRef } from 'react'

import { parseNumberToString } from 'utils/number'

const NumberFormatField = forwardRef(
  function NumberFormatFieldForwardRef(props, ref) {
    const { value, options, ...other } = props

    return (
      <Cleave
        htmlRef={() => ref}
        onFocus={(e) => {
          e.target.placeholder = ''
        }}
        onBlur={(e) => {
          e.target.placeholder = '0'
        }}
        style={{ textAlign: 'right' }}
        options={{
          numeral: true,
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralPositiveOnly: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 2,
          numericOnly: true,
          ...options,
        }}
        value={parseNumberToString(value)}
        {...other}
      />
    )
  }
)

export default NumberFormatField
