import { Formik, useFormikContext } from 'formik'
import { useRef, useState } from 'react'
import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import LordIcon from 'components/UI/LordIcon'

import Actions from '../../SSAffiliation/Form/Beneficiaries/Actions'

const initialValues = {
  signed_name: '',
  signed_email: '',
}

const validationSchema = yup.object({
  signed_name: yup.string().trim().required(),
  signed_email: yup.string().trim().email().required(),
})

const AddSigners = ({ setPeopleToSign, peopleToSign }) => {
  const formikRef = useRef(null)
  const { values: mainFormValues, setValues } = useFormikContext()
  const [showAddSigners, setShowAddSigners] = useState(false)
  const signers = mainFormValues?.signers

  const handleCloseAddSignersFields = () => {
    setShowAddSigners(false)
    formikRef.current?.resetForm()
  }

  const onSubmit = (values, form) => {
    const data = {
      signed_name: values.signed_name,
      signed_email: values.signed_email,
    }

    setPeopleToSign([...peopleToSign, data])
    setValues({ ...mainFormValues, signers: [...signers, data] })

    setShowAddSigners(false)
    form.resetForm()
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: theme.spacing(3),
                [theme.breakpoints.up('tablet')]: {
                  gridTemplateColumns: '1fr 1fr',
                },
              })}
            >
              {peopleToSign.map((signer) => (
                <Box
                  key={signer?.signed_name}
                  className="signer_icon_box"
                  sx={(theme) => ({
                    display: 'flex',
                    boxShadow: theme.shadows[3],
                    width: '100%',
                    maxHeight: '5rem',
                    borderRadius: '0.5rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  })}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      minWidth: '5rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderTopLeftRadius: '0.5rem',
                      borderBottomLeftRadius: '0.5rem',
                      backgroundColor: 'complementary1.light',
                    }}
                  >
                    <LordIcon name="regular-avatar" target="signer_icon_box" />
                  </Box>
                  <Box
                    sx={(theme) => ({
                      padding: theme.spacing(1.4),
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '100%',
                    })}
                  >
                    <Typography
                      variant="h6"
                      title={signer?.signed_name}
                      sx={(theme) => ({
                        marginBottom: theme.spacing(1),
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      })}
                    >
                      {signer?.signed_name}
                    </Typography>
                    <Typography
                      title={signer?.signed_email}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {signer?.signed_email}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            {showAddSigners ? (
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  columnGap: theme.spacing(3),
                  marginTop: theme.spacing(2.5),
                  gridTemplateColumns: '1fr 5rem',
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gap: theme.spacing(3),
                    gridTemplateColumns: '1fr',
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                  })}
                >
                  <FormField
                    name="signed_name"
                    label="Nombres"
                    optional={false}
                    placeholder="Nombres"
                  />
                  <FormField
                    name="signed_email"
                    label="Correo electrónico"
                    optional={false}
                    placeholder="Correo electrónico"
                  />
                </Box>
                <Actions
                  leftAction={{
                    tooltip: 'Confirmar',
                    icon: <Icon name="approve-checked" basic />,
                    onClick: handleSubmit,
                  }}
                  rightAction={{
                    tooltip: 'Cancelar',
                    icon: <Icon name="close-2" basic />,
                    onClick: handleCloseAddSignersFields,
                  }}
                />
              </Box>
            ) : null}
            <Button
              variant="outlined"
              endIcon={<Icon name="plus" basic />}
              onClick={() => {
                setShowAddSigners(true)
              }}
              sx={(theme) => ({ margin: theme.spacing(3, 0, 0) })}
            >
              Agregar nueva persona
            </Button>
          </>
        )
      }}
    </Formik>
  )
}

export default AddSigners
