import { usePopupState } from 'material-ui-popup-state/hooks'
import { useContext } from 'react'

import { Box, Typography, useMediaQuery } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'

import { formatNumberDisplayDate } from 'utils/dateTime'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import { FoldersContainerContext } from './Index'
import {
  ActionButton,
  AnimatedIcon,
  DropdownMenu,
  documentsActions,
} from './helpers'

const FileCard = ({ showAsCard, card, isAffiliationFolder }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const { isWorker } = useUser()
  const { setActionModal } = useContext(FoldersContainerContext)
  const downloadURI = useDownloadURI()

  const handleDownloadFile = () => downloadURI(card.url_file)

  const options = [
    {
      id: documentsActions.move_file.id,
      name: documentsActions.move_file.callToAction,
      onClick: () => {
        setActionModal({ type: documentsActions.move_file.id, context: card })
        popupState.close()
      },
    },
    {
      id: documentsActions.change_file_name.id,
      name: documentsActions.change_file_name.callToAction,
      onClick: () => {
        setActionModal({
          type: documentsActions.change_file_name.id,
          context: card,
        })
        popupState.close()
      },
    },
    {
      id: documentsActions.delete_file.id,
      name: documentsActions.delete_file.callToAction,
      onClick: () => {
        setActionModal({ type: documentsActions.delete_file.id, context: card })
        popupState.close()
      },
    },
  ]

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      className="animated_icon_box"
      sx={(theme) => ({
        backgroundColor: theme.palette.accent1.main,
        borderRadius: theme.spacing(1),
        display: 'flex',
        padding: theme.spacing(2),
        ...(!showAsCard && {
          width: '100%',
          minHeight: '4.5rem',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          rowGap: theme.spacing(2),
          [theme.breakpoints.down('tablet')]: {
            justifyContent: 'space-between',
          },
        }),
        ...(showAsCard && {
          minHeight: '8.375rem',
          flexDirection: 'column',
        }),
      })}
    >
      {showAsCard ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
              justifyContent: 'space-between',
            })}
          >
            <Box>
              <AnimatedIcon fileIcon />
              <Typography
                title={card.name}
                variant="h6"
                color="black.main"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '13rem',
                }}
              >
                {card.name}
              </Typography>
            </Box>
            <Typography variant="body1" color="black.dark">
              {`Creada el ${formatNumberDisplayDate(card.created_at)}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
              ...(isWorker && {
                justifyContent: 'flex-end',
              }),
            }}
          >
            {!isWorker && !isAffiliationFolder ? (
              <DropdownMenu options={options} popupState={popupState} />
            ) : null}
            <ActionButton
              title="Descargar"
              action={handleDownloadFile}
              icon="download"
              showAsCard
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              gap: theme.spacing(1),
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: theme.spacing(3),
                gridTemplateColumns: '1fr 5fr 3fr',
                width: '70%',
                [theme.breakpoints.down('tablet')]: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                },
              })}
            >
              <AnimatedIcon fileIcon />
              <Typography
                title={card.name}
                variant="h6"
                color="black.main"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                }}
              >
                {card.name}
              </Typography>
              <Typography variant="body1" color="black.dark">
                {`Creado el ${formatNumberDisplayDate(card.created_at)}`}
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                minHeight: '100%',
                gap: theme.spacing(1),
                alignItems: 'center',
                [theme.breakpoints.down('tablet')]: {
                  flexDirection: 'column-reverse',
                  justifyContent: 'space-between',
                },
              })}
            >
              <ActionButton
                title="Descargar"
                action={handleDownloadFile}
                icon="download"
                showAsCard={isMobile}
              />
              {!isWorker &&
                !isAffiliationFolder &&
                (!isMobile ? (
                  <>
                    <ActionButton
                      title="Mover archivo"
                      action={() =>
                        setActionModal({
                          type: documentsActions.move_file.id,
                          context: card,
                        })
                      }
                      icon="share-arrow"
                    />
                    <ActionButton
                      title="Renombrar"
                      action={() =>
                        setActionModal({
                          type: documentsActions.change_file_name.id,
                          context: card,
                        })
                      }
                      icon="edit-pencil"
                    />
                    <ActionButton
                      title="Eliminar"
                      action={() =>
                        setActionModal({
                          type: documentsActions.delete_file.id,
                          context: card,
                        })
                      }
                      icon="trash"
                    />
                  </>
                ) : (
                  <DropdownMenu options={options} popupState={popupState} />
                ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default FileCard
