import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import useDepartmentsService from 'utils/hooks/departmentsService'

import FormField from '../FormField/Index'

/**
 * This component is intended to be used in conjunction with the <CitiesList /> component, since when changing the department
 * a query is executed to bring the cities of that department.
 *
 * Also, changing the department will reset the list of compensation funds, since those are filtered by the selected department.
 *
 * If the onChange prop is provided, you have the control to receive the new selected value to use it as needed.
 * By default, selecting a new value automatically updates the key "department" in the Formik context in which this component is being used,
 * and also clears the key "city" in that context.
 */

const DepartmentsList = ({
  name = '',
  label = '',
  onChange,
  optional,
  resetCompensationFundQuery = false,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const queryClient = useQueryClient()

  const { departmentsQuery } = useDepartmentsService({
    serviceParams: {
      queryKey: 'departments',
    },
    queryOptions: {
      staleTime: Infinity,
    },
  })

  const handleChange = (_, newSelectedValue) => {
    if (values?.department && values?.department?.id !== newSelectedValue?.id) {
      if (resetCompensationFundQuery) {
        queryClient.invalidateQueries([
          'getInstitutionsByCategory',
          'compensation_fund',
          values?.department.name,
        ])
        setFieldValue('compensation_fund', null)
        setFieldTouched('compensation_fund')
      }

      setFieldValue('city', null)
      setFieldTouched('city')
    }
    setFieldValue('department', newSelectedValue)
  }

  return (
    <FormField
      variant="autocomplete"
      options={departmentsQuery?.data || []}
      name={name}
      data-cy={name}
      label={label}
      placeholder="Selecciona un departamento..."
      onChange={onChange || handleChange}
      optional={optional}
    />
  )
}

export default DepartmentsList
