import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'

import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import DropdownButton from 'components/UI/Button/Dropdown'
import Icon from 'components/UI/Icon'

import { useUser } from '../UserContext/useUser'
import useUserMenuOptions from './helpers'

const UserButton = () => {
  const { user, company } = useUser()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const closePopup = () => popupState.close()

  const userName = user?.name || 'Usuario'
  const companyName = company?.name ? ` - ${company?.name || 'Empresa'}` : ''
  const userHashCompanyName = `${userName}${companyName}`

  const userHashMobile = () => {
    const arrayName = userHashCompanyName.split('')

    const formatedName =
      arrayName.length > 15
        ? `${arrayName.slice(0, 15).join('')}...`
        : userHashCompanyName

    return formatedName
  }

  const options = useUserMenuOptions(closePopup, isMobile, userHashMobile)
  const openMenu = popupState?.isOpen

  const IconButton = user.picture ? (
    <Box
      component="img"
      src={user.picture}
      sx={(theme) => ({
        height: '2rem',
        width: '2rem',
        borderRadius: '50%',
        margin: theme.spacing(0, 0.6, 0, 0.5),
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden',
        msTransform: 'translateZ(0)',
        WebkitTransform: 'translateZ(0)',
        objectFit: 'cover',
        objectPosition: '50% 50%',
      })}
    />
  ) : (
    <Icon
      name="avatar-4"
      sx={{
        fontSize: '2.5rem !important',
      }}
    />
  )

  return (
    <DropdownButton
      popupState={popupState}
      variant="text"
      startIcon={IconButton}
      endIcon={
        <Icon
          basic
          name="arrow-down"
          sx={{
            transform: 'rotate(0deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            ...(openMenu && { transform: 'rotate(180deg)' }),
          }}
        />
      }
      sx={(theme) => ({
        color: theme.palette.black.dark,
        maxWidth: '18.75rem',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:focus': {
          backgroundColor: 'transparent',
        },
        [theme.breakpoints.up('tablet')]: {
          paddingRight: 0,
        },
      })}
      title={
        <Typography variant="body1" color="inherit" noWrap>
          {userHashCompanyName}
        </Typography>
      }
      data-cy="header-user-button"
      options={options}
      iconButton={isMobile}
      Icon={
        isMobile ? (
          <Button
            {...bindTrigger(popupState)}
            variant="text"
            data-cy="header-user-button"
            sx={{ minWidth: 'auto' }}
          >
            {IconButton}
          </Button>
        ) : undefined
      }
    />
  )
}

export default UserButton
