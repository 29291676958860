import { Box, Typography, useMediaQuery } from '@mui/material'

import { formatNumber, getWordSingular } from 'utils/format'

import MobileCell from '../common/CardGrid/MobileCell'
import Tooltip from '../common/Tooltip'

const AccumulatedHolidays = ({ accumulatedHolidays }) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 1,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <Typography variant="h6" color="black.dark">
            Vacaciones acumuladas
          </Typography>
          {!isTabletUp ? (
            <Tooltip title="Días que le faltan por disfrutar" />
          ) : null}
        </Box>
      </MobileCell>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        alignY="center"
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 2,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <Typography variant="h6" color="black.dark">
            {`${formatNumber(accumulatedHolidays)} ${getWordSingular(
              'días',
              formatNumber(accumulatedHolidays) === 1
            )}`}
          </Typography>
        </Box>
      </MobileCell>
      {isTabletUp ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title="Días que le faltan por disfrutar" />
        </Box>
      ) : null}
    </>
  )
}

export default AccumulatedHolidays
