import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const OvertimeAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openOvertimeModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={
        payroll.details?.overtime?.quantity === '0.0'
          ? 0
          : payroll.details?.overtime?.quantity
      }
      onClick={() => openOvertimeModal('extra_hours')}
      editable={period.editable && !payroll.locked}
      dataCy="add-extra-hours"
    />
  )
}

export default OvertimeAction
