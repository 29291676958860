import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import {
  formatSocialBenefits,
  getAllSocialBenefits,
} from 'services/worker/socialBenefitsService'

import * as routes from 'config/routes'

import { getColumnsData, getTableAction } from './helpers'

const SocialBenefitsIndex = () => {
  const queryClient = useQueryClient()
  const { worker } = useWorker()
  const { workerId, contractId } = useParams()
  const [filter, setFilter] = useState('')

  const handleChange = (newValue) => setFilter(newValue)

  const fetchSocialBenefits = (pageIndex) => {
    return {
      queryKey: ['getAllSocialBenefits', pageIndex + 1, filter],
      queryFunction: () =>
        getAllSocialBenefits(contractId, {
          searchParams: {
            page: pageIndex + 1,
            category: filter,
          },
        }),
      queryOptions: {
        onSuccess: ({ data }) => {
          data.forEach(formatSocialBenefits)
          queryClient.setQueryData(data)
        },
      },
    }
  }

  const actions = getTableAction({ handleChange, filter })
  const columns = getColumnsData(worker, contractId)

  return (
    <Page
      documentTitle={`Prestaciones sociales de ${
        worker ? worker.fullName : 'trabajador'
      }`}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Prestaciones sociales de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Table
          data={fetchSocialBenefits}
          columns={columns}
          actions={actions}
          options={{
            search: false,
            version: {
              toolbar: 'v2',
            },
          }}
        />
      </Box>
    </Page>
  )
}

export default SocialBenefitsIndex
