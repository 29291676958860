import Table from 'components/UI/Table/Table'

import { getHolisticPayrollCalculatorHistory } from 'services/HolisticPayroll/WorkerServices'

import { columns } from './helpers'

export default function CalculationHistory({ calculatorsHistoryQueryKey }) {
  const [, workerId] = calculatorsHistoryQueryKey

  const fetchCalculationHistory = () => {
    return {
      queryKey: calculatorsHistoryQueryKey,
      queryFunction: () => getHolisticPayrollCalculatorHistory(workerId),
    }
  }

  return (
    <Table
      columns={columns}
      data={fetchCalculationHistory}
      options={{
        pagination: false,
        search: false,
        version: {
          toolbar: 'v2',
        },
      }}
    />
  )
}
