import {
  alpha,
  buttonClasses,
  createTheme,
  outlinedInputClasses,
  responsiveFontSizes,
  svgIconClasses,
} from '@mui/material'
import { esES } from '@mui/material/locale'

import Icon from 'components/UI/Icon'

import { alertColors, colors, getAlphaHexValue } from './colors'
import shadows from './elevation'
import { montserrat, sora } from './fonts'
import typographyStyles from './typography'

/**
 *
 * @param {('nominapp' | 'arus')} name The name of the theme to generate.
 */
const generateTheme = (name = 'nominapp') => {
  const themeColors = colors[name]

  // This base theme is to generate the tone colors variations
  // to be used in the theme
  const baseTheme = createTheme({
    palette: {
      mode: 'light',
      white: {
        ...themeColors.WHITE,
      },
      black: {
        ...themeColors.BLACK,
      },
      primary: {
        ...themeColors.PRIMARY,
      },
      complementary1: {
        ...themeColors.COMPLEMENTARY_1,
      },
      complementary2: {
        ...themeColors.COMPLEMENTARY_2,
        contrastText: themeColors.COMPLEMENTARY_2.dark,
      },
      accent1: {
        ...themeColors.ACCENT_1,
      },
      accent2: {
        ...themeColors.ACCENT_2,
      },
      accent3: {
        ...themeColors.ACCENT_3,
      },
      accent4: {
        ...themeColors.ACCENT_4,
      },
      error: {
        ...themeColors.ERROR,
      },
      warning: {
        ...themeColors.WARNING,
      },
      info: {
        ...themeColors.INFO,
      },
      success: {
        ...themeColors.SUCCESS,
      },
    },
  })

  let theme = createTheme(
    {
      palette: baseTheme.palette,
      typography: {
        fontSize: 16,
        fontFamily: ['Montserrat', 'Sora', 'sans-serif'].join(','),
        h1: { ...typographyStyles.h1 },
        h2: { ...typographyStyles.h2 },
        h3: { ...typographyStyles.h3 },
        h4: { ...typographyStyles.h4 },
        h5: { ...typographyStyles.h5 },
        h6: { ...typographyStyles.h6 },
        body1: { ...typographyStyles.body1 },
        body2: { ...typographyStyles.body2 },
        lead1: { ...typographyStyles.lead1 },
        lead2: { ...typographyStyles.lead2 },
        blockquote: { ...typographyStyles.blockquote },
        capitalized: { ...typographyStyles.capitalized },
        small: { ...typographyStyles.small },
        tiny: { ...typographyStyles.tiny },
      },
      name,
      headerHeight: '5rem',
      mobileAppbar: '4rem',
      breakpoints: {
        values: {
          ...baseTheme.breakpoints.values,
          mobile: 0,
          tablet: 768,
          laptop: 1280,
          desktop: 1440,
          xdesktop: 1920,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            ...[...montserrat, ...sora],
            body: {
              color: baseTheme.palette.black.main,
              backgroundColor: baseTheme.palette.white.dark,
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
            color: 'primary',
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              height: '2.5rem',
              padding: baseTheme.spacing(0, 3),
              fontFamily: 'Montserrat',
              fontWeight: 700,
              fontSize: '0.875rem',
              textTransform: 'none',
              color:
                name !== 'arus'
                  ? baseTheme.palette.black.main
                  : baseTheme.palette.white.main,
              borderRadius: '6.25rem',
              '&:hover': {
                backgroundColor: baseTheme.palette.primary.light,
              },
              '&.Mui-disabled': {
                color: baseTheme.palette.white.main,
                backgroundColor: baseTheme.palette.black.light,
                '& > span > svg': {
                  color: 'white',
                },
              },
              '&:focus': {
                boxShadow: `0 0 0 3px ${baseTheme.palette.primary.light}`,
              },
            },
            endIcon: {
              '& > *:nth-of-type(1)': {
                fontSize: '1.5rem',
              },
            },
            startIcon: {
              '& > *:nth-of-type(1)': {
                fontSize: '1.5rem',
              },
            },
            outlined: {
              backgroundColor: 'transparent',
              color: baseTheme.palette.black.main,
              border: `2px solid ${baseTheme.palette.black.main}`,
              '&.Mui-disabled': {
                color: baseTheme.palette.black.light,
                backgroundColor: 'transparent',
                border: `2px solid ${baseTheme.palette.black.light}`,
                '& > span > svg': {
                  color: baseTheme.palette.black.light,
                },
              },
              '&:hover': {
                border: `2px solid ${baseTheme.palette.black.main}`,
              },
            },
            text: {
              backgroundColor: 'transparent',
              color: baseTheme.palette.black.main,
              padding: '0 !important',
              '&:focus': {
                boxShadow: 'none',
                color: baseTheme.palette.primary.main,
              },
              '&:hover': {
                backgroundColor: 'transparent',
                color: baseTheme.palette.black.dark,
                textDecoration: 'underline',
              },
              '&.Mui-disabled': {
                color: baseTheme.palette.black.light,
                backgroundColor: 'transparent',
              },
            },
            sizeSmall: {
              height: '2rem',
              padding: baseTheme.spacing(0, 2),
              borderRadius: '6.25rem',
              [`& .${buttonClasses.endIcon}`]: {
                '& > *:nth-of-type(1)': {
                  fontSize: '1.25rem',
                },
              },
              [`& .${buttonClasses.startIcon}`]: {
                '& > *:nth-of-type(1)': {
                  fontSize: '1.25rem',
                },
              },
            },
            sizeLarge: {
              height: '3rem',
              padding: baseTheme.spacing(0, 4),
              borderRadius: '6.25rem',
              fontSize: '1rem',
            },
            sizeXlarge: {
              height: '3.5rem',
              padding: baseTheme.spacing(0, 4),
              borderRadius: '3.125rem',
              fontSize: '1rem',
            },
          },
        },
        MuiIconButton: {
          defaultProps: {
            color: 'primary',
          },
          styleOverrides: {
            root: {
              position: 'relative',
              borderRadius: '50%',
              '&.Mui-disabled': {
                backgroundColor: baseTheme.palette.black.light,
                color: baseTheme.palette.black.dark,
              },
              '&::before': {
                borderRadius: '50%',
                bottom: '-0.125rem',
                content: '""',
                display: 'block',
                left: '-0.125rem',
                position: 'absolute',
                right: '-0.125rem',
                top: '-0.125rem',
                willChange: 'box-shadow',
                transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)',
              },
            },
            colorPrimary: {
              backgroundColor: baseTheme.palette.primary.main,
              color: baseTheme.palette.black.main,
              '&:hover': {
                backgroundColor: baseTheme.palette.primary.light,
              },
              '&:focus': {
                backgroundColor: baseTheme.palette.primary.dark,
                '&::before': {
                  boxShadow: `0 0 0 1px ${baseTheme.palette.primary.dark}`,
                },
              },
            },
            colorComplementary1: {
              backgroundColor: baseTheme.palette.complementary1.main,
              color: baseTheme.palette.black.main,
              '&:hover': {
                backgroundColor: baseTheme.palette.complementary1.light,
              },
              '&:focus': {
                backgroundColor: baseTheme.palette.complementary1.dark,
                '&::before': {
                  boxShadow: `0 0 0 1px ${baseTheme.palette.complementary1.dark}`,
                },
              },
            },
            sizeSmall: {
              width: '1.35rem',
              height: '1.35rem',
            },
            sizeMedium: {
              width: '1.8rem',
              height: '1.8rem',
            },
            sizeLarge: {
              width: '2.25rem',
              height: '2.25rem',
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: '0.875rem',
              display: 'inline-flex',
              alignItems: 'center',
              alignSelf: 'start',
              fontWeight: 700,
              fontFamily: 'Montserrat',
              lineHeight: '1.25rem',
              marginBottom: baseTheme.spacing(0.5),
              color: baseTheme.palette.black.main,
              '&.Mui-focused': {
                color: baseTheme.palette.black.main,
              },
              '&.Mui-error': {
                color: baseTheme.palette.black.main,
              },
              '&.Mui-disabled': {
                color: baseTheme.palette.black.main,
              },
            },
            asterisk: {
              color: baseTheme.palette.error.main,
              marginLeft: baseTheme.spacing(-0.25),
            },
          },
        },
        MuiOutlinedInput: {
          defaultProps: {
            notched: true,
            color: 'primary',
          },
          styleOverrides: {
            root: {
              height: '2.5rem',
              borderRadius: '0.5rem',
              padding: baseTheme.spacing(0, 2),
              '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)': {
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                  border: `2px solid ${baseTheme.palette.primary.main}`,
                },
              },
              [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: baseTheme.palette.error.main,
              },
              '&.Mui-disabled': {
                backgroundColor: baseTheme.palette.black.light,
                color: baseTheme.palette.black.main,
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: baseTheme.palette.black.dark,
                },
                [`& .${svgIconClasses.root}`]: {
                  color: baseTheme.palette.black.main,
                },
              },
            },
            input: {
              padding: baseTheme.spacing(1, 0),
              lineHeight: '1.5rem',
              color: baseTheme.palette.black.dark,
              fontFamily: 'Montserrat',
              fontWeight: 500,
              '&:active': {
                opacity: 1,
                color: baseTheme.palette.black.dark,
              },
              '&:focus': {
                opacity: 1,
                color: baseTheme.palette.black.dark,
              },
              '&::placeholder': {
                color: baseTheme.palette.black.light,
                opacity: 1,
              },
              '&.Mui-disabled': {
                WebkitTextFillColor: `${baseTheme.palette.black.main} !important`,
              },
            },
            notchedOutline: {
              borderColor: baseTheme.palette.black.light,
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              alignSelf: 'start',
              lineHeight: '1.25rem',
              color: baseTheme.palette.black.dark,
              marginTop: baseTheme.spacing(0.5),
              '&.Mui-error': {
                color: baseTheme.palette.error.main,
              },
              '&.Mui-disabled': {
                color: baseTheme.palette.error.main,
              },
            },
            contained: {
              marginLeft: 0,
              marginRight: 0,
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              backgroundColor: baseTheme.palette.white.main,
              padding: 0,
              '& textarea': {
                height: 'auto',
              },
            },
            input: {
              '&::placeholder': {
                color: baseTheme.palette.black.light,
                opacity: 1,
              },
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              color: baseTheme.palette.black.light,
            },
            positionStart: {
              marginRight: baseTheme.spacing(1),
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: '0.75rem',
              lineHeight: '1rem',
              backgroundColor: baseTheme.palette.black.dark,
            },
            arrow: {
              '&::before': {
                backgroundColor: baseTheme.palette.black.dark,
              },
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              paddingTop: baseTheme.spacing(0.5),
            },
            indicator: {
              height: '0.25rem',
              backgroundColor: baseTheme.palette.primary.main,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              color: baseTheme.palette.black.main,
              textTransform: 'none',
              padding: baseTheme.spacing(1.5, 2),
              borderRadius: '0.5rem 0.5rem 0 0',
              ...typographyStyles.body1,
              display: 'inline-block',
              '&.Mui-selected': {
                fontWeight: 'bold',
              },
              '&:hover': {
                backgroundColor: baseTheme.palette.white.light,
              },
            },
            wrapper: {
              flexDirection: 'row',
            },
          },
        },
        MuiSelect: {
          defaultProps: {
            IconComponent: () => <Icon name="arrow-down" basic />,
          },
          styleOverrides: {
            select: {
              width: 'auto',
            },
            iconOutlined: {
              right: baseTheme.spacing(1.5),
            },
            icon: {
              color: baseTheme.palette.black.light,
              '&.Mui-disabled': {
                color: baseTheme.palette.black.main,
              },
            },
            outlined: {
              '& > em': {
                color: baseTheme.palette.black.light,
              },
            },
            root: {
              display: 'inline-grid',
              [`& .${svgIconClasses.root}`]: {
                position: 'absolute',
                right: baseTheme.spacing(1.5),
                pointerEvents: 'none',
                color: baseTheme.palette.black.light,
              },
            },
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            popupIcon: <Icon name="arrow-down" basic />,
          },
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root': {
                padding: `0 0 0 ${baseTheme.spacing(1)}`,
              },
            },
            input: {
              paddingLeft: `${baseTheme.spacing(1)} !important`,
            },
            endAdornment: {
              right: `${baseTheme.spacing(1.5)} !important`,
              top: `calc(50% - ${baseTheme.spacing(1.5)})`,
              '& .MuiIconButton-root': {
                width: '1.5rem',
                height: '1.5rem',
                borderRadius: 0,
                color: baseTheme.palette.black.light,
                backgroundColor: 'transparent',
                padding: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
            clearIndicator: {
              marginRight: '0.125rem',
            },
            popupIndicator: {
              marginRight: 0,
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: baseTheme.palette.black.light,
              },
            },
            option: {
              color: baseTheme.palette.black.dark,
              '&.Mui-selected': {
                backgroundColor: `${baseTheme.palette.primary.main} !important`,
                color: `${baseTheme.palette.black.dark} !important`,
              },
              '&.Mui-selected:hover': {
                backgroundColor: `${baseTheme.palette.primary.main} !important`,
                color: `${baseTheme.palette.black.dark} !important`,
              },
              '&.Mui-focused, &.Mui-focusVisible, &:hover': {
                backgroundColor: `${baseTheme.palette.primary.main} !important`,
                color: `${baseTheme.palette.black.dark} !important`,
              },
            },
            paper: {
              marginTop: baseTheme.spacing(0.25),
              marginBottom: baseTheme.spacing(0.25),
            },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: 'outlined',
            fullWidth: true,
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'outlined',
            fullWidth: true,
          },
        },
        MuiInputLabel: {
          defaultProps: {
            variant: 'outlined',
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              '& .MuiIconButton-root': {
                width: '2.5rem',
                height: '2.5rem',
                backgroundColor: 'transparent',
                borderRadius: '50%',
                '&.Mui-disabled': {
                  border: 'none',
                },
                '&:hover': {
                  backgroundColor: baseTheme.palette.primary.light,
                },
              },
            },
            label: {
              marginLeft: baseTheme.spacing(0.25),
            },
          },
        },
        MuiRadio: {
          // TODO: finish radio styles
          defaultProps: {
            color: 'primary',
          },
          styleOverrides: {
            root: {
              '& .MuiSvgIcon-root': {
                width: '1.5rem',
                height: '1.5rem',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&.Mui-disabled': {
                color: baseTheme.palette.black.dark,
              },
            },
          },
        },
        MuiCheckbox: {
          // TODO: finish checkbox styles
          defaultProps: {
            color: 'primary',
          },
          styleOverrides: {
            root: {
              color: baseTheme.palette.black.dark,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        MuiSwitch: {
          defaultProps: {
            color: 'primary',
          },
          styleOverrides: {
            root: {
              width: '3.75rem',
              height: '2.5rem',
              '& .MuiButtonBase-root': {
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                  border: 'none',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
            switchBase: {
              color: baseTheme.palette.white.main,
              '&.Mui-checked': {
                color: baseTheme.palette.primary.main,
              },
              '&.MuiSwitch-track': {
                backgroundColor: baseTheme.palette.white.dark,
              },
              '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: baseTheme.palette.primary.light,
              },
              '&.Mui-disabled': {
                '& + .MuiSwitch-track': {
                  opacity: 0.45,
                },
              },
            },
            thumb: {
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
            },
            colorPrimary: {
              '&.Mui-checked': {
                '&:hover': {
                  backgroundColor: `${
                    baseTheme.palette.primary[200]
                  }${getAlphaHexValue(24)}`,
                },
                '&.Mui-disabled': {
                  color: baseTheme.palette.primary.light,
                  '&  + .MuiSwitch-track': {
                    backgroundColor: baseTheme.palette.primary.light,
                    opacity: 0.35,
                  },
                },
              },
              '&.Mui-disabled': {
                color: baseTheme.palette.common.white,
                '& + .MuiSwitch-track': {
                  backgroundColor: baseTheme.palette.grey[400],
                },
              },
            },
            track: {
              backgroundColor: baseTheme.palette.grey[400],
            },
          },
        },
        MuiLink: {
          defaultProps: {
            color: 'primary',
          },
          styleOverrides: {
            root: {
              '&.MuiIconButton-root': {
                position: 'relative !important',
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: 'inherit',
              justifyContent: 'center',
              minWidth: 0,
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              color: baseTheme.palette.black.dark,
              '&.Mui-selected': {
                backgroundColor: `${baseTheme.palette.primary.main} !important`,
                color:
                  name === 'arus'
                    ? baseTheme.palette.white.main
                    : baseTheme.palette.black.dark,
              },
              '&.Mui-selected:hover': {
                backgroundColor: baseTheme.palette.primary.main,
                color:
                  name === 'arus'
                    ? baseTheme.palette.white.main
                    : baseTheme.palette.black.dark,
              },
              '&.Mui-focused, &.Mui-focusVisible, &:hover': {
                backgroundColor: baseTheme.palette.primary.main,
                color:
                  name === 'arus'
                    ? baseTheme.palette.white.main
                    : baseTheme.palette.black.dark,
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              color: 'inherit',
              backgroundColor: baseTheme.palette.white.main,
              boxShadow: shadows[5],
            },
            rounded: {
              borderRadius: '0.5rem',
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              marginTop: baseTheme.spacing(1.5),
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: baseTheme.spacing(2.5),
              '& > :not(:first-of-type)': {
                marginLeft: '0 !important',
              },
            },
          },
        },
        MuiPopover: {
          defaultProps: {
            elevation: 1,
          },
        },
        MuiDialog: {
          defaultProps: {
            PaperProps: {
              elevation: 3,
            },
          },
          styleOverrides: {
            paper: {
              boxShadow: shadows[7],
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              padding: baseTheme.spacing(1.5),
              backgroundColor: baseTheme.palette.white.dark,
              color: baseTheme.palette.black.main,
            },
            label: {
              paddingLeft: 0,
              paddingRight: 0,
              fontWeight: 600,
              fontFamily: 'Montserrat',
              fontSize: '0.875rem',
            },
            outlined: {
              borderColor: baseTheme.palette.black.main,
              backgroundColor: 'transparent',
              color: baseTheme.palette.black.main,
            },
            icon: {
              width: '1rem',
              height: '1rem',
              marginRight: baseTheme.spacing(1),
              color: baseTheme.palette.black.main,
            },
            deleteIcon: {
              margin: 0,
              marginLeft: baseTheme.spacing(1),
              backgroundColor: 'transparent',
              color: baseTheme.palette.black.main,
              '&:hover': {
                color: baseTheme.palette.black.main,
              },
            },
            colorPrimary: {
              backgroundColor: baseTheme.palette.primary.main,
            },
            colorComplementary2: {
              color: baseTheme.palette.complementary2.dark,
              backgroundColor: alpha(
                baseTheme.palette.complementary2.main,
                0.2
              ),
            },
            colorSuccess: {
              color: baseTheme.palette.success.dark,
              backgroundColor: alpha(baseTheme.palette.success.light, 0.2),
            },
            colorError: {
              color: baseTheme.palette.error.dark,
              backgroundColor: alpha(baseTheme.palette.error.light, 0.2),
            },
            colorInfo: {
              color: baseTheme.palette.info.dark,
              backgroundColor: alpha(baseTheme.palette.info.light, 0.2),
            },
            colorWarning: {
              color: baseTheme.palette.warning.dark,
              backgroundColor: alpha(baseTheme.palette.warning.light, 0.2),
            },
            sizeMedium: {
              height: '2rem',
            },
            sizeSmall: {
              height: '1.25rem',
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              backgroundColor: baseTheme.palette.white.main,
              boxShadow: shadows[5],
              borderRadius: '1rem',
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              '& .MuiTableRow-root': {
                borderBottom: 'none',
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              ...typographyStyles.h6,
              padding: baseTheme.spacing(0, 2),
              height: '3rem',
              borderBottom: 0,
              fontWeight: 'inherit',
            },
            head: {
              ...typographyStyles.lead1,
              color: baseTheme.palette.black.main,
            },
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              '&.Mui-active': {
                '& .MuiSvgIcon-root': {
                  color: `${baseTheme.palette.primary.main}`,
                },
              },
            },
          },
        },
        MuiPagination: {
          styleOverrides: {
            ul: {
              justifyContent: 'center',
              gap: baseTheme.spacing(2),
              '& > li:nth-of-type(2)': {
                marginRight: baseTheme.spacing(2),
              },
              '& > li:nth-last-of-type(2)': {
                marginLeft: baseTheme.spacing(2),
              },
            },
          },
        },
        MuiPaginationItem: {
          styleOverrides: {
            root: {
              width: '2.5rem',
              height: '2.5rem',
              borderRadius: '50%',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 0,
              border: `1px solid ${baseTheme.palette.black.main}`,
              ...typographyStyles.h6,
              '&.Mui-disabled': {
                color: baseTheme.palette.black.dark,
              },
              '&:hover': {
                backgroundColor: baseTheme.palette.primary.light,
              },
            },
            page: {
              color: baseTheme.palette.black.main,
              '&.Mui-selected': {
                width: '2.4375rem',
                height: '2.4375rem',
                backgroundColor: baseTheme.palette.primary.main,
                border: 'none',
                color:
                  name === 'arus'
                    ? baseTheme.palette.white.main
                    : baseTheme.palette.black.main,
                '&:hover': {
                  backgroundColor: baseTheme.palette.primary.light,
                },
              },
              '&.Mui-disabled': {
                opacity: 1,
              },
            },
            ellipsis: {
              color: baseTheme.palette.black.main,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            previousNext: {
              color: baseTheme.palette.black.main,
              backgroundColor: baseTheme.palette.primary.main,
              border: 'none',
              '&.Mui-disabled': {
                opacity: 1,
                color: baseTheme.palette.black.light,
                border: `1px solid ${baseTheme.palette.black.light}`,
                backgroundColor: 'transparent',
              },
            },
            firstLast: {
              color: baseTheme.palette.black.main,
              backgroundColor: baseTheme.palette.primary.main,
              border: 'none',
              '&.Mui-disabled': {
                opacity: 1,
                color: baseTheme.palette.black.light,
                border: `1px solid ${baseTheme.palette.black.light}`,
                backgroundColor: 'transparent',
              },
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paperAnchorDockedLeft: {
              borderRight: 'none',
            },
          },
        },
        MuiStepper: {
          styleOverrides: {
            root: {
              backgroundColor: 'transparent',
            },
          },
        },
        MuiStepIcon: {
          styleOverrides: {
            root: {
              width: '1.75rem',
              height: '1.75rem',
              '&.Mui-active': {
                color: baseTheme.palette.primary.main,
                '& .MuiStepIcon-text': {
                  fill:
                    name === 'arus'
                      ? baseTheme.palette.primary.light
                      : baseTheme.palette.black.main,
                },
              },
              '&.Mui-completed': {
                color: baseTheme.palette.primary.main,
                '& .MuiStepIcon-text': {
                  fill: baseTheme.palette.black.main,
                },
              },
            },
            text: {
              color: baseTheme.palette.primary.main,
              ...typographyStyles.lead2,
            },
          },
        },
        MuiStepConnector: {
          styleOverrides: {
            line: {
              borderColor: baseTheme.palette.primary.main,
              opacity: 0.32,
            },
            lineHorizontal: {
              borderTopWidth: 2,
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: 'none',
              '&::before': {
                display: 'none',
              },
              '&.Mui-expanded': {
                margin: 0,
              },
              '& + &': {
                borderTop: `1px solid ${baseTheme.palette.white.main}`,
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              color: baseTheme.palette.black.dark,
              '&:hover': {
                backgroundColor: baseTheme.palette.primary.light,
              },
              '&.Mui-expanded': {
                color: baseTheme.palette.primary.main,
                minHeight: '3rem',
              },
            },
            content: {
              '&.Mui-expanded': {
                margin: 0,
                '& .MuiTypography-root': {
                  color: 'inherit',
                  fontWeight: 'bold',
                },
              },
            },
            expandIcon: {
              color: baseTheme.palette.black.dark,
              backgroundColor: 'transparent',
            },
            expandIconWrapper: {
              '&.Mui-expanded': {
                color: baseTheme.palette.primary.main,
              },
            },
          },
        },
        MuiAccordionActions: {
          styleOverrides: {
            root: {
              backgroundColor: baseTheme.palette.white.main,
              padding: `${baseTheme.spacing(3)} ${baseTheme.spacing(2)}`,
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              borderRadius: 0,
              boxShadow: 'none',
              alignItems: 'center',
            },
            message: {
              fontSize: '0.8rem',
            },
            action: {
              alignItems: 'center',
              paddingTop: 0,
              marginRight: 0,
            },
            icon: {
              alignItems: 'center',
              '& .MuiSvgIcon-root': {
                fontSize: '1.7rem',
              },
            },
            standardSuccess: {
              backgroundColor: alertColors.success,
              color: baseTheme.palette.success.dark,
              '& .MuiAlert-icon': {
                color: baseTheme.palette.success.dark,
              },
            },
            standardInfo: {
              backgroundColor: alertColors.info,
              color: baseTheme.palette.info.dark,
              '& .MuiAlert-icon': {
                color: baseTheme.palette.info.dark,
              },
            },
            standardWarning: {
              backgroundColor: alertColors.warning,
              color: baseTheme.palette.warning.dark,
              '& .MuiAlert-icon': {
                color: baseTheme.palette.warning.dark,
              },
            },
            standardError: {
              backgroundColor: alertColors.error,
              color: baseTheme.palette.error.dark,
              '& .MuiAlert-icon': {
                color: baseTheme.palette.error.dark,
              },
            },
          },
        },
        MuiAlertTitle: {
          styleOverrides: {
            root: {
              ...typographyStyles.lead2,
              marginBottom: baseTheme.spacing(0.5),
            },
          },
        },
      },
      shadows: {
        ...shadows,
      },
    },
    esES
  )

  theme = responsiveFontSizes(theme)

  return theme
}

export default generateTheme
