import { Box, Button, Typography } from '@mui/material'

const DiscardAction = ({
  discardTitles,
  discard,
  setDiscard,
  electronicSignature = false,
}) => {
  const handleDiscard = () => setDiscard(true)

  const handleAffiliate = () => setDiscard(false)

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(-7),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'accent1.main',
          padding: theme.spacing(1),
          borderRadius: '1.9rem',
          maxWidth: '17rem',
          width: '100%',
          [theme.breakpoints.up('tablet')]: {
            maxWidth: electronicSignature ? '24.5rem' : '22.5rem',
          },
        })}
      >
        <Button
          onClick={handleAffiliate}
          sx={(theme) => ({
            backgroundColor: discard ? 'transparent' : 'primary.main',
            [theme.breakpoints.down('tablet')]: {
              padding: theme.spacing(1, 0.8125),
            },
          })}
        >
          <Typography
            variant="lead2"
            sx={(theme) => ({
              [theme.breakpoints.down('tablet')]: {
                fontSize: '0.75rem',
              },
            })}
          >
            {discardTitles}
          </Typography>
        </Button>

        <Button
          onClick={handleDiscard}
          sx={(theme) => ({
            backgroundColor: discard ? 'primary.main' : 'transparent',
            [theme.breakpoints.down('tablet')]: {
              padding: theme.spacing(1, 0.8125),
            },
          })}
        >
          <Typography
            variant="lead2"
            sx={(theme) => ({
              [theme.breakpoints.down('tablet')]: {
                fontSize: '0.75rem',
              },
            })}
          >
            Descartar acción
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default DiscardAction
