import { useState } from 'react'

import { Typography } from '@mui/material'

import CostCenterTable from 'components/Settings/CostCenterTable/DataTable'

import useCostCenterService from 'utils/hooks/company/costCenterService'

import { getCostCenterColumns } from '../helpers'

const SettingsIntegrationsAccountingCostCenters = () => {
  const [alegraOptions, setAlegraOptions] = useState([])

  const { costCenterQuery } = useCostCenterService({
    serviceParams: {
      queryKey: 'costCenters',
      searchParams: { integration_options: true },
    },
    queryOptions: {
      onSuccess: ({ alegra_options: alegraOptionsData }) =>
        setAlegraOptions(alegraOptionsData),
    },
  })

  const columns = getCostCenterColumns(alegraOptions)

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Centros de Costos - PUC
      </Typography>
      <Typography>
        En esta sección puedes configurar los centros de costos de Alegra a los
        cuales quieres que enviemos la información de los centros de costos de
        Aleluya. Recuerda que enviaremos la información de cada persona de
        acuerdo con su centro de costos, si deseas cambiar a una persona de
        centro de costos, deberás editarlo en sus &quot;datos básicos&quot;.
      </Typography>
      <CostCenterTable
        data={costCenterQuery?.data}
        columns={columns}
        alegraAccounting
      />
    </>
  )
}

export default SettingsIntegrationsAccountingCostCenters
