import { Box, TextField, Typography, useMediaQuery } from '@mui/material'

import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import MobileCell from '../common/CardGrid/MobileCell'
import GroupDivider from '../common/GroupDivider'

const ConnectivityAid = ({ connectivityAidDays, setConnectivityAidDays }) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <Box>
      <GroupDivider />

      <GridRow>
        <HeaderCell
          sx={{
            gridColumn: 1,
            gridRow: 1,
          }}
          mobileOnly
        >
          Tipo de ingreso
        </HeaderCell>
        <HeaderCell
          sx={{
            gridColumn: 1,
            gridRow: 2,
          }}
          mobileOnly
          alignY="center"
        >
          Concepto
        </HeaderCell>
        <HeaderCell
          sx={{
            gridColumn: 1,
            gridRow: 3,
          }}
          mobileOnly
          alignY="center"
        >
          Días
        </HeaderCell>
        <MobileCell
          alignX={isTabletUp ? 'left' : 'right'}
          sx={(theme) => ({
            gridColumn: '2 / -1',
            [theme.breakpoints.up('tablet')]: {
              gridColumn: 1,
            },
          })}
        >
          <Typography variant="h6" color="black.dark">
            Auxilio de conectividad
          </Typography>
        </MobileCell>
        <MobileCell
          alignX={isTabletUp ? 'left' : 'right'}
          alignY="center"
          sx={(theme) => ({
            gridColumn: '2 / -1',
            [theme.breakpoints.up('tablet')]: {
              gridColumn: 2,
            },
          })}
        >
          <TextField
            placeholder="Aux. de conectividad"
            disabled
            InputProps={{
              sx: {
                maxWidth: '13.5rem',
                width: 'max-content',
                height: '2rem',
              },
            }}
            fullWidth={false}
          />
        </MobileCell>
        <MobileCell
          alignX={isTabletUp ? 'left' : 'right'}
          alignY="center"
          sx={(theme) => ({
            gridColumn: '2 / -1',
            [theme.breakpoints.up('tablet')]: {
              gridColumn: 3,
            },
          })}
        >
          <TextField
            error={connectivityAidDays > 30}
            helperText={
              connectivityAidDays > 30
                ? 'El valor ingresado debe ser inferior a 30 días'
                : null
            }
            id="connectivityAidDaysInput"
            value={connectivityAidDays}
            onChange={(e) => setConnectivityAidDays(Number(e.target.rawValue))}
            InputProps={{
              inputComponent: NumberFormatField,
              sx: {
                height: '2rem',
                maxWidth: '12rem',
              },
            }}
            placeholder="Días"
            fullWidth={false}
          />
        </MobileCell>
      </GridRow>
    </Box>
  )
}

export default ConnectivityAid
