import { Form, Formik } from 'formik'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import { formatDate } from 'utils/dateTime'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'
import useFreetimeService from 'utils/hooks/freetime/freetime'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useNotifications from 'utils/hooks/useNotifications'

import { filterRequestTypes, validationSchema } from './helpers'

const UpdateRequest = ({ requestData, onClose }) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const conceptsCategory = 'novelties'
  const { payrollConceptsQuery } = usePayrollConceptsService({
    serviceParams: {
      queryKey: ['payrollConcepts', conceptsCategory],
      conceptsCategory,
    },
  })
  const { freetimeMutation } = useFreetimeService({
    queryOptions: {
      enabled: false,
    },
  })
  const isSubmitting = freetimeMutation.isLoading
  const { data: noveltiesConcepts = {} } = payrollConceptsQuery
  const requestTypeOptions = useMemo(() => {
    const concepts = filterRequestTypes(noveltiesConcepts, 'employee')

    return concepts
  }, [noveltiesConcepts])
  const evidenceUrl = isValidUrl(requestData?.evidence)
  const evidenceFileName = getFileNameFromUrl(
    evidenceUrl ? evidenceUrl.search : undefined
  )

  const onSubmit = async (values) => {
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) => {
      if (['initial_day', 'end_day'].includes(key)) {
        if (typeof value === 'string') {
          formData.append(key, value)
        } else {
          formData.append(key, formatDate(value))
        }
      }
    })
    formData.append('payroll_concept_id', values.payroll_concept_id)

    if (values.comment !== requestData.comment) {
      if (typeof values.comment === 'string' && values.comment.length > 0) {
        formData.append('comment', values.comment)
      } else if (
        typeof values.comment === 'undefined' ||
        [null, ''].includes(values.comment)
      ) {
        formData.append('comment', '')
      }
    }

    if (values.evidence !== requestData.evidence) {
      if (values.evidence && typeof values.evidence === 'object') {
        formData.append('evidence', values.evidence)
      } else if (
        typeof values.evidence === 'undefined' ||
        values.evidence === null
      ) {
        formData.append('evidence', '')
      }
    }

    freetimeMutation.mutate(
      {
        mutationMethod: 'PUT',
        noveltyId: requestData.id,
        noveltyRequest: formData,
      },
      {
        onSuccess: () => {
          showSuccessMessage(`La solicitud ha sido actualizada`)
          queryClient.invalidateQueries('getAllRequests', {
            exact: false,
            active: true,
          })
          onClose()
        },
      }
    )
  }

  return (
    <Formik
      initialValues={{
        initial_day: requestData.initial_day,
        end_day: requestData.end_day,
        payroll_concept_id: requestData.payroll_concept_id,
        evidence: requestData.evidence || null,
        comment: requestData.comment,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <hr />
            <Form>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(3),
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: theme.spacing(3),
                    [theme.breakpoints.down('tablet')]: {
                      flexDirection: 'column',
                    },
                  })}
                >
                  <FormField
                    name="initial_day"
                    variant="datepicker"
                    label="Día inicio"
                    optional={false}
                  />
                  <FormField
                    name="end_day"
                    variant="datepicker"
                    label="Día final"
                    optional={false}
                  />
                </Box>
                <FormField
                  name="payroll_concept_id"
                  variant="select"
                  label='Selecciona el tipo de "Tiempo fuera" que quieres solicitar'
                  options={requestTypeOptions}
                  optional={false}
                />
                <FormField
                  name="evidence"
                  variant="file"
                  fileType="application/pdf"
                  fileName={evidenceFileName}
                  accept="application/pdf"
                  label="¿Deseas adjuntar un documento de soporte?"
                  optional
                />
                <FormField
                  name="comment"
                  variant="textarea"
                  label="Nota"
                  rows="4"
                  inputProps={{
                    maxLength: 1000,
                  }}
                  optional
                />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    gap: theme.spacing(2),
                  })}
                >
                  <Button loading={isSubmitting} onClick={handleSubmit}>
                    Actualizar solicitud
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={onClose}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default UpdateRequest
