import {
  Tab,
  Tabs,
  tabClasses,
  tabsClasses,
  useMediaQuery,
} from '@mui/material'

const RoundedTabs = ({
  value,
  onChange,
  tabsConfig,
  backgroundColor,
  sx,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant={isMobile ? 'scrollable' : 'fullWidth'}
      scrollButtons={isMobile}
      sx={[
        (theme) => ({
          width: '90%',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: backgroundColor ?? theme.palette.white.dark,
          padding: theme.spacing(1),
          borderRadius: '2rem !important',
          minHeight: '3.5rem',
          [`& .${tabsClasses.indicator}`]: {
            display: 'none',
          },
          [`& .${tabsClasses.flexContainer}`]: {
            gap: theme.spacing(1),
          },
          [`& .${tabClasses.root}`]: {
            display: 'flex',
            color: theme.palette.black.main,
            ...theme.typography.lead2,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              borderRadius: '1.9rem !important',
              transition: 'background-color 250ms ease-in-out',
            },
          },
          [`& .${tabClasses.selected}`]: {
            color: `${theme.palette.black.main} !important`,
            backgroundColor: `${theme.palette.primary.main} !important`,
            borderRadius: '1.9rem',
          },
        }),
        sx,
      ]}
      {...props}
    >
      {tabsConfig.map((tab, index) => {
        const {
          key = index,
          icon = null,
          label = '',
          dataCy = '',
          disabled = false,
        } = tab

        return (
          <Tab
            key={key}
            label={label}
            value={key}
            icon={icon}
            data-cy={dataCy}
            disabled={disabled}
          />
        )
      })}
    </Tabs>
  )
}

export default RoundedTabs
