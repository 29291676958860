import { Link, useLocation } from 'react-router-dom'

import { Box, Button, Slide, Typography } from '@mui/material'

import Alert from 'components/UI/MaterialUI/Alert/Alert'

import * as routes from 'config/routes'

import { messageType } from './helpers'

const ElectronicPayrollAlert = ({ state, handleClose }) => {
  const location = useLocation()

  const { messageResponse, periodId } = state
  const isInElectronicPayrollView =
    location?.pathname.includes('electronic_payroll')

  return (
    <Slide direction="down" timeout={500} in>
      <Box>
        <Alert
          icon={false}
          fullWidth
          sx={(theme) => ({
            padding: 0,
            boxShadow: theme.shadows[4],
            backgroundColor: 'transparent',
            '.MuiAlert-message': {
              padding: 0,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '73% auto',
              minHeight: '4rem',
              [theme.breakpoints.down('tablet')]: {
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr, 1fr',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(1, 2),
                backgroundColor:
                  messageType[messageResponse].description.backgroundColor,
              })}
            >
              <Typography variant="h5" color="black.main">
                {messageType[messageResponse].description.label}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor:
                  messageType[messageResponse].button.backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isInElectronicPayrollView ? (
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={(theme) => ({
                    maxWidth: '15.625rem',
                    padding: theme.spacing(0, 10),
                    [theme.breakpoints.down('laptop')]: {
                      padding: theme.spacing(0, 7),
                    },
                    [theme.breakpoints.down('tablet')]: {
                      padding: theme.spacing(0, 10),
                      margin: theme.spacing(2, 0),
                    },
                  })}
                >
                  Cerrar
                </Button>
              ) : (
                <Button
                  component={Link}
                  to={routes.PERIOD_PAY_ELECTRONIC_PAYROLL(periodId)}
                  variant="outlined"
                  onClick={handleClose}
                  sx={(theme) => ({
                    maxWidth: '15.625rem',
                    padding: theme.spacing(0, 10),
                    [theme.breakpoints.down('laptop')]: {
                      padding: theme.spacing(0, 7),
                    },
                    [theme.breakpoints.down('tablet')]: {
                      padding: theme.spacing(0, 10),
                      margin: theme.spacing(2, 0),
                    },
                  })}
                >
                  {messageType[messageResponse].button.label}
                </Button>
              )}
            </Box>
          </Box>
        </Alert>
      </Box>
    </Slide>
  )
}

export default ElectronicPayrollAlert
