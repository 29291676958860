import { LazyMotion } from 'framer-motion'

const loadFeatures = () => import('./features.js').then((res) => res.default)

// This animation will run when loadFeatures resolves.
const LazyAnimation = ({ children }) => {
  return <LazyMotion features={loadFeatures}>{children}</LazyMotion>
}

export default LazyAnimation
