import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createElectronicSignature,
  deleteSignedDocument,
  getElectronicSignatureResults,
} from 'services/electronicSignature/electronicSignatureServices'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId } = serviceParams

  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getElectronicSignatureResults')
    return getElectronicSignatureResults(workerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, electronicSignatureData } = mutationData

  if (mutationMethod === 'POST')
    return createElectronicSignature(workerId, electronicSignatureData)

  if (mutationMethod === 'DELETE') return deleteSignedDocument(workerId)

  return null
}

const useElectronicSignatureService = ({
  serviceParams = { queryKey: 'getElectronicSignatureResults' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restElectronicSignatureResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const electronicSignatureMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    electronicSignatureQuery: {
      data,
      ...restElectronicSignatureResponse,
    },
    electronicSignatureMutation,
  }
}

export default useElectronicSignatureService
