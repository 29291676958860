import { getCompanyId } from 'utils/company'

import http, { getApiURL } from '../../httpService'

export const deleteWage = (wageId) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/wages/${wageId}`,
    withoutVersion: true,
  })

  return http.deleteV2(url)
}

export default {
  deleteWage,
}
