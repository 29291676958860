import { formatCurrency } from 'utils/format'

const columnsData = [
  {
    Header: 'Nombre',
    accessor: 'name',
    sorting: false,
  },
  {
    Header: 'ID',
    accessor: 'id_number',
  },
  {
    Header: 'Salud',
    accessor: 'health',
    Cell: ({ row }) => formatCurrency(row.original.health),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Pensión',
    accessor: 'pension',
    Cell: ({ row }) => formatCurrency(row.original.pension),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Riesgos',
    accessor: 'risks',
    Cell: ({ row }) => formatCurrency(row.original.risks),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Parafiscales',
    accessor: 'parafiscals',
    Cell: ({ row }) => formatCurrency(row.original.parafiscals),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Valor a pagar',
    accessor: 'total',
    Cell: ({ row }) => formatCurrency(row.original.total),
    alignHeader: 'right',
    alignCell: 'right',
  },
]

export default columnsData
