import { Box, Typography } from '@mui/material'

import { formatCurrency } from 'utils/format'

const RecurrentConceptsItem = ({ label, value, withoutHolidays }) => {
  const getValue = () => {
    if (label === 'Retención') return value ? 'Activa' : 'Inactiva'

    if (label === '- Deducciones') return `(${formatCurrency(value)})`

    return formatCurrency(value)
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '8rem 1fr',
        columnGap: theme.spacing(1),
        ...(!withoutHolidays
          ? {
              [theme.breakpoints.between('tablet', 1300)]: {
                gridTemplateColumns: 'unset',
                gridTemplateRows: 'auto auto',
              },
              [theme.breakpoints.up(1300)]: {
                gridTemplateColumns: '9rem 1fr',
                gridTemplateRows: 'unset',
              },
              [theme.breakpoints.up('desktop')]: {
                gridTemplateColumns: 'minmax(7.5rem, 10rem) 1fr !important',
              },
            }
          : {
              [theme.breakpoints.up('tablet')]: {
                gridTemplateColumns: 'minmax(7.5rem, 10rem) 1fr',
              },
            }),
      })}
    >
      <Typography
        variant="body1"
        color="black.dark"
        sx={{
          minWidth: '7.25rem',
          maxWidth: '7.25rem',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="h6"
        title={getValue()}
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textAlign: 'left',
        }}
      >
        {getValue()}
      </Typography>
    </Box>
  )
}

export default RecurrentConceptsItem
