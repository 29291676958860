import http, { getApiURL } from '../httpService'

export const getImportWorkersFile = () => {
  const url = getApiURL({ pathname: '/import_workers' })

  return http.getV2(url)
}

export const uploadWorkersFile = (fileData) => {
  const url = getApiURL({
    pathname: '/import_workers',
    searchParams: { async: true },
  })

  return http.postFile(url, fileData)
}

export default {
  getImportWorkersFile,
  uploadWorkersFile,
}
