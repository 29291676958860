import { useContext } from 'react'

import Button from 'components/UI/Button/Button'

import { BulkNoveltiesAlertContext } from '../BulkNoveltiesAlert/BulkNoveltiesAlertWrapper'
import BulkNoveltiesCommonInfoModal from '../Modals/BulkNoveltiesCommonInfoModal'
import BulkNoveltiesErrorsModal from '../Modals/BulkNoveltiesErrorsModal'
import BulkNoveltiesErrorsSummaryModal from '../Modals/BulkNoveltiesErrorsSummaryModal'
import BulkNoveltiesModal from '../Modals/BulkNoveltiesModal'
import BulkNoveltiesSummaryModal from '../Modals/BulkNoveltiesSummaryModal'
import useBulkNoveltiesErrorsModal from '../Modals/useBulkNoveltiesErrorsModal'
import useBulkNoveltiesErrorsSummaryModal from '../Modals/useBulkNoveltiesErrorsSummaryModal'
import useBulkNoveltiesModal from '../Modals/useBulkNoveltiesModal'

const UploadNoveltiesButton = () => {
  const {
    bulkNoveltiesModalState,
    openBulkNoveltiesModal,
    closeBulkNoveltiesModal,
  } = useBulkNoveltiesModal()

  const {
    bulkNoveltiesCommonInfoModalState,
    openBulkNoveltiesCommonInfoModal,
    closeBulkNoveltiesCommonInfoModal,
    bulkNoveltiesSummaryModalState,
    openBulkNoveltiesSummaryModal,
    closeBulkNoveltiesSummaryModal,
  } = useContext(BulkNoveltiesAlertContext)

  const {
    bulkNoveltiesErrorsSummaryModalState,
    openBulkNoveltiesErrorsSummaryModal,
    closeBulkNoveltiesErrorsSummaryModal,
  } = useBulkNoveltiesErrorsSummaryModal()

  const {
    bulkNoveltiesErrorsModalState,
    openBulkNoveltiesErrorsModal,
    closeBulkNoveltiesErrorsModal,
  } = useBulkNoveltiesErrorsModal()

  return (
    <>
      <Button variant="outlined" onClick={openBulkNoveltiesModal}>
        Cargar novedades
      </Button>

      {bulkNoveltiesModalState.open ? (
        <BulkNoveltiesModal handleClose={closeBulkNoveltiesModal} />
      ) : null}

      {bulkNoveltiesCommonInfoModalState.open ? (
        <BulkNoveltiesCommonInfoModal
          configState={bulkNoveltiesCommonInfoModalState}
          handleClose={closeBulkNoveltiesCommonInfoModal}
          openBulkNoveltiesSummaryModal={openBulkNoveltiesSummaryModal}
          openBulkNoveltiesErrorsModal={openBulkNoveltiesErrorsModal}
          openBulkNoveltiesErrorsSummaryModal={
            openBulkNoveltiesErrorsSummaryModal
          }
        />
      ) : null}

      {bulkNoveltiesSummaryModalState.open ? (
        <BulkNoveltiesSummaryModal
          state={bulkNoveltiesSummaryModalState}
          handleClose={closeBulkNoveltiesSummaryModal}
          openBulkNoveltiesErrorsSummaryModal={
            openBulkNoveltiesErrorsSummaryModal
          }
          openBulkNoveltiesErrorsModal={openBulkNoveltiesErrorsModal}
          openBulkNoveltiesCommonInfoModal={openBulkNoveltiesCommonInfoModal}
        />
      ) : null}

      {bulkNoveltiesErrorsSummaryModalState.open ? (
        <BulkNoveltiesErrorsSummaryModal
          state={bulkNoveltiesErrorsSummaryModalState}
          handleClose={closeBulkNoveltiesErrorsSummaryModal}
          openBulkNoveltiesErrorsModal={openBulkNoveltiesErrorsModal}
          openBulkNoveltiesCommonInfoModal={openBulkNoveltiesCommonInfoModal}
        />
      ) : null}

      {bulkNoveltiesErrorsModalState.open ? (
        <BulkNoveltiesErrorsModal
          state={bulkNoveltiesErrorsModalState}
          handleClose={closeBulkNoveltiesErrorsModal}
          openBulkNoveltiesCommonInfoModal={openBulkNoveltiesCommonInfoModal}
        />
      ) : null}
    </>
  )
}

export default UploadNoveltiesButton
