import { Box } from '@mui/material'

import DeleteIconButton from 'components/UI/Button/DeleteIconButton'

import { formatDate } from 'utils/dateTime'

import DateRangePicker from './DateRangePicker'

const NoveltiesDateRangeItem = (props) => {
  const {
    initialDay,
    initialMinDate,
    initialMaxDate,
    endDay,
    endMinDate,
    endMaxDate,
    noveltyType,
    noveltyCode,
    index,
    excludedDates = [], // Already selected days
    onChangeNovelty,
    onDeleteNovelty,
    isNoveltyFromThisPayroll,
    periodInitialDay,
  } = props

  const ariaLabel = `${noveltyType}_date_range_${index}`
  const initialId = `initial-date-picker-${noveltyType}-${noveltyCode}-${index}`
  const endId = `end-date-picker-${noveltyType}-${noveltyCode}-${index}`
  const allExcludedDates = excludedDates.reduce((accumulator, currentValue) => {
    return [...accumulator, ...currentValue]
  }, [])
  const disabled = noveltyType === 'holidays' && !isNoveltyFromThisPayroll

  const onChange = (selectedDate, inputType) => {
    onChangeNovelty(noveltyType, noveltyCode, index, selectedDate, inputType)
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        width: 'fit-content',
      })}
    >
      <DateRangePicker
        aria-label={ariaLabel}
        startDateId={initialId}
        endDateId={endId}
        initialDay={initialDay}
        endDay={endDay}
        periodDate={formatDate(periodInitialDay)}
        allExcludedDates={allExcludedDates}
        onChange={onChange}
        disabled={disabled}
        shouldCloseOnSelect
        initialMinDate={initialMinDate}
        initialMaxDate={initialMaxDate}
        endMinDate={endMinDate}
        endMaxDate={endMaxDate}
        startDateDataCy={`${noveltyCode}-${index}-initial_day`}
        endDateDataCy={`${noveltyCode}-${index}-end_day`}
        tooltipTitle="Novedades"
        tooltipDescription="Los días que quieres seleccionar están deshabilitados porque tienen novedades o están fuera del periodo actual."
      />
      {initialDay || endDay ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <DeleteIconButton
            onClick={() => onDeleteNovelty(noveltyType, noveltyCode, index)}
            data-cy={`delete-${noveltyCode}-${index}-item`}
            color="complementary1"
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default NoveltiesDateRangeItem
