import { usePopupState } from 'material-ui-popup-state/hooks'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Table from 'components/UI/Table/Table'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import periodService from 'services/payroll/periodService'

import ExogenaReportModal from '../Modals/ExogenaReportModal'
import NewPeriodModal from '../Modals/NewPeriodModal'
import ReportFileDownloadModal from '../Modals/ReportFileDownloadModal'
import useReportFileDownloadModal from '../Modals/ReportFileDownloadModal/useReportFileDownloadModal'
import { columnsData, getActions } from './helpers'

const PayrollHistory = ({ hasAlegraIntegration, activeTab }) => {
  const [newPeriodModalOpen, setNewPeriodModalOpen] = useState(false)
  const [openExogenaModal, setOpenExogenaModal] = useState(false)
  const [lastPeriodDate, setLastPeriodDate] = useState({
    date: null,
    length: 'monthly',
  })
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showInfoMessage } = useNotifications()
  const featureState = usePremiumFeature()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'downloadConsolidated',
  })
  const {
    ReportFileDownloadModalState,
    openReportFileDownloadModal,
    closeReportFileDownloadModal,
  } = useReportFileDownloadModal()

  const fetchPeriods = (pageIndex) => {
    const page = pageIndex + 1

    return {
      queryKey: [
        'getAllPeriods',
        pageIndex,
        selectedMonth?.id,
        selectedYear?.id,
      ],
      queryFunction: () =>
        periodService.getAllPeriods(page, selectedMonth?.id, selectedYear?.id),
      queryOptions: {
        onSuccess: ({ data }) => {
          setLastPeriodDate({
            date: data[data.length - 1]?.initial_day,
            length: data[data.length - 1]?.length,
          })
        },
      },
    }
  }

  const handleOrganizerPeriodAction = async (periodId, perform) => {
    try {
      const { message } = await periodService.updateOrganizerAction(
        periodId,
        perform
      )

      queryClient.invalidateQueries(['getAllPeriods'], { exact: false })
      showInfoMessage(message)
    } catch (error) {
      handleError(error)
    }
  }

  const handleOpenNewPeriodModal = () => setNewPeriodModalOpen(true)

  const actions = getActions(
    handleOrganizerPeriodAction,
    handleOpenNewPeriodModal,
    hasAlegraIntegration,
    setSelectedMonth,
    selectedMonth,
    setSelectedYear,
    selectedYear,
    lastPeriodDate,
    setOpenExogenaModal,
    popupState,
    featureState,
    openReportFileDownloadModal
  )

  return (
    <>
      <Table
        columns={columnsData}
        data={fetchPeriods}
        options={{
          search: false,
          alignActionsCell: 'center',
          version: { toolbar: 'v2' },
        }}
        actions={actions}
      />
      {newPeriodModalOpen ? (
        <NewPeriodModal
          visible={newPeriodModalOpen}
          handleClose={() => setNewPeriodModalOpen(false)}
          activeTab={activeTab}
        />
      ) : null}
      {ReportFileDownloadModalState.open ? (
        <ReportFileDownloadModal
          state={ReportFileDownloadModalState}
          handleClose={closeReportFileDownloadModal}
        />
      ) : null}
      <ExogenaReportModal
        openModal={openExogenaModal}
        handleClose={() => setOpenExogenaModal(false)}
      />
    </>
  )
}

export default PayrollHistory
