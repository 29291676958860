import { useQueryClient } from 'react-query'

import { Box, IconButton, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'

import { formatCurrency } from 'utils/format'
import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'
import useElectronicPayrollFileService from 'utils/hooks/ElectronicPayroll/fileService'
import useElectronicPayrollPdfService from 'utils/hooks/ElectronicPayroll/pdfService'
import useDownloadURI from 'utils/hooks/useDownloadURI'

export function getPayrollConceptItemCell({
  buttonId,
  bonusIncomes,
  incomes,
  onClick,
}) {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
      })}
    >
      <Typography
        variant="lead1"
        sx={{ whiteSpace: 'nowrap', fontWeight: 400 }}
      >
        {formatCurrency(incomes)}
      </Typography>
      <IconButton
        sx={{
          backgroundColor: 'black.main',
          '&:hover': { backgroundColor: 'black.light' },
          '&:focus': { backgroundColor: 'black.main' },
          width: '1.7rem',
          height: '1.7rem',
        }}
        id={buttonId}
        size="small"
        onClick={onClick}
        data-cy={buttonId}
      >
        {bonusIncomes ? (
          <Icon name="edit-pencil" basic color="white" fontSize="small" />
        ) : (
          <Icon name="plus" basic color="white" fontSize="small" />
        )}
      </IconButton>
    </Box>
  )
}

export const electronicPayrollStatusOptions = [
  { value: '', label: 'Todas' },
  { value: 'issued', label: 'Emitidas' },
  { value: 'rejected', label: 'Rechazadas' },
  { value: 'processing', label: 'Procesando' },
  { value: 'pending', label: 'Pendientes' },
  { value: 'canceled', label: 'Anuladas' },
  { value: 'modified', label: 'Modificadas' },
]

export const useCancelable = ({
  showLoadingModal,
  hideLoadingModal,
  showSuccessMessage,
  showErrorMessage,
  invalidateQueries = 'electronicPayrollPeriod',
}) => {
  const confirm = useConfirm()
  const queryClient = useQueryClient()
  const { electronicPayrollMutation } = useElectronicPayrollPeriodService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleCancel = (electronicPayroll) => {
    showLoadingModal()
    electronicPayrollMutation.mutate(
      {
        mutationMethod: 'PATCH',
        electronicPayrollId: electronicPayroll.id,
      },
      {
        onSuccess: () => {
          showSuccessMessage('Tú nómina electrónica ha sido anulada')
          hideLoadingModal()
          queryClient.invalidateQueries(invalidateQueries)
        },
        onError: () => {
          hideLoadingModal()
          showErrorMessage('Ha sucedido un error en el proceso de cancelación')
        },
      }
    )
  }

  return {
    handleClick: (rowData) =>
      confirm({
        type: 'warning',
        title:
          rowData.status !== 'issued'
            ? 'Eliminar Nómina Electrónica'
            : 'Anular Nómina Electrónica',
        description:
          rowData.status !== 'issued'
            ? `Eliminar la Nómina Electrónica de ${rowData.worker.name} es permanente y no se podrá deshacer. ¿Estás seguro?`
            : `Anular la Nómina Electrónica de ${rowData.worker.name} es permanente y no se podrá deshacer. ¿Estás seguro?`,
        okText: rowData.status !== 'issued' ? 'Eliminar' : 'Anular',
        confirmCheckbox: true,
        onOk: () => handleCancel(rowData),
      }),
  }
}

export const useDownloadFilesAction = () => {
  const { enqueueDownload } = useDownloadManager()
  const { downloadFileMutation } = useElectronicPayrollFileService()

  const handleDownloadFile = (electronicPayroll, fileType = 'xml') => {
    enqueueDownload({
      name: `comprobante de nómina electrónica para ${electronicPayroll.worker.name}`,
      fileCode: 'electronic_payroll_government_file',
      firebasePath: `electronic_payrolls/${electronicPayroll.id}/government_file`,
      service: () =>
        downloadFileMutation.mutateAsync({
          mutationMethod: 'GET',
          mutationKey: 'payslipsElectronicPayroll',
          electronicPayrollId: electronicPayroll.id,
          fileType,
        }),
    })
  }

  return {
    handleClick: (rowData) => handleDownloadFile(rowData),
  }
}

export const useDownloadPdf = ({
  periodId,
  showLoadingModal,
  hideLoadingModal,
}) => {
  const downloadURI = useDownloadURI()
  const { showPremiumFeatures } = usePremiumFeature()
  const { downloadPdfMutation } = useElectronicPayrollPdfService()

  const handleDownloadPDF = (electronicPayroll) => {
    if (showPremiumFeatures) {
      showLoadingModal()
      downloadPdfMutation.mutate(
        {
          mutationMethod: 'GET',
          mutationKey: 'getElectronicPayrollPdf',
          electronicPayrollId: electronicPayroll.id,
          periodId,
        },
        {
          onSuccess: ({ file }) => {
            if (file) {
              downloadURI(file)
            }
            hideLoadingModal()
          },
          onError: () => {
            hideLoadingModal()
          },
        }
      )
    }
  }

  return {
    handleClick: (rowData) => handleDownloadPDF(rowData),
  }
}
