import { useNavigate } from 'react-router-dom'

import useModals from 'components/App/ModalsManager/useModals'

import { Header, SocialBenefitsResponse } from './SocialBenefitsResponse'

const useSocialBenefitsResponse = () => {
  const navigate = useNavigate()
  const modals = useModals()
  const handleClose = () => modals.closeAll()

  const openModal = (category, label) => {
    const handleContinue = () => {
      modals.closeAll()
      navigate(-1)
    }
    modals.openModal({
      id: 'extraWorker',
      content: <SocialBenefitsResponse category={category} label={label} />,
      modalProps: {
        header: <Header />,
        onCloseModal: handleClose,
        onCancel: handleClose,
        okText: '¿Deseas continuar?',
        alignButtons: 'center',
        onOk: handleContinue,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'xs',
        },
      },
    })
  }

  return {
    openModal,
    handleClose,
  }
}

export default useSocialBenefitsResponse
