import { useMemo } from 'react'

import { useUser } from 'components/App/UserContext/useUser'
import Table from 'components/UI/Table/Table'
import useRecurrentConcepts from 'components/Worker/Show/useRecurrentConcepts'
import useWorker from 'components/Worker/useWorker'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useNotifications from 'utils/hooks/useNotifications'

import { updateLoans } from 'services/worker/loansService'
import { updateRecurrentConcept } from 'services/worker/recurrentConceptService'

import NoItems from '../NoItems'
import { getDeductionsColumns, getValidationSchema } from './helpers'

const DeductionsTable = ({ noItemsOnClick }) => {
  const { worker } = useWorker({ useCache: true })
  const { isWorker } = useUser()
  const { recurrentConcepts, isLoading, isError, refreshRecurrentConcepts } =
    useRecurrentConcepts()
  const { showSuccessMessage } = useNotifications()
  const { payrollConceptsQuery } = usePayrollConceptsService({
    serviceParams: {
      queryKey: ['payrollConcepts', 'deductions', getCompanyId()],
      conceptsCategory: 'deductions',
    },
    queryOptions: {
      enabled: !isWorker,
    },
  })

  const deductionsOptions = (payrollConceptsQuery.data || [])
    .filter(
      ({ coded_name: codedName }) =>
        codedName !== 'loan' &&
        codedName !== 'payroll_retention' &&
        codedName !== 'avista_payroll_credit'
    )
    .map((concept) => ({
      id: concept.id,
      name: concept.name,
    }))

  const deductionsColumns = getDeductionsColumns(deductionsOptions)

  const validationSchema = useMemo(
    () => getValidationSchema(worker.base_salary),
    [worker.base_salary]
  )

  const handleMutationConfig = (data, values) => {
    const recurrentConceptCategory =
      data.category === 'deductions' ? 'concepto recurrente' : 'préstamo'
    const mutationFunction =
      data.category === 'deductions' ? updateRecurrentConcept : updateLoans

    const mutationConfig = {
      data: {
        workerId: worker.id,
        recurrentConcepts: values,
      },
      mutationFunction,
      mutateOptions: {
        onSuccess: () => {
          refreshRecurrentConcepts(
            data.category === 'deductions' ? 'recurrent_concept' : 'loans'
          )
          showSuccessMessage(
            `El ${recurrentConceptCategory} ha sido actualizado.`
          )
        },
      },
    }

    if (data.category === 'loans') {
      mutationConfig.data.loans = values
      delete mutationConfig.data.recurrentConcepts
    }

    return mutationConfig
  }

  const updateItem = (oldData, newData) => {
    const dirtyValues = getDirtyValues(
      oldData,
      newData,
      validationSchema.fields
    )
    const values = [{ ...dirtyValues, id: newData.id }]

    if (!isObjectEmpty(dirtyValues))
      return handleMutationConfig(newData, values)

    return {}
  }

  const deleteItem = (oldData) => {
    const values = [{ id: oldData.id }]
    return handleMutationConfig(oldData, values)
  }

  return (
    <>
      {!isLoading && !isError ? (
        <>
          {recurrentConcepts?.deductions.length !== 0 ? (
            <Table
              data={recurrentConcepts.deductions}
              columns={deductionsColumns}
              options={{
                pagination: false,
                search: false,
                toolbar: false,
                customActionsWidth: 110,
              }}
              editable={{
                validationSchema,
                onUpdateRow: updateItem,
                onDeleteRow: deleteItem,
                isDeletable: ({ deleteable }) => deleteable,
                isEditable: () => !isWorker,
                tableMinWidth: 980,
                hideDelete: isWorker,
                hideEdit: isWorker,
              }}
            />
          ) : (
            <NoItems type="deductions" onClick={noItemsOnClick} />
          )}
        </>
      ) : null}
    </>
  )
}

export default DeductionsTable
