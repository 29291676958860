import http, { getApiURL } from '../httpService'

export const getAccountingInstitutionsCodes = (category) => {
  const url = getApiURL({
    pathname: '/accounting/institution_codes',
    searchParams: { category },
  })

  return http.getV2(url)
}

export const createAccountingInstitutionCodes = ({ accountingCodes }) => {
  const url = getApiURL({ pathname: '/accounting/institution_code' })

  return http.putV2(url, accountingCodes)
}

export default {
  createAccountingInstitutionCodes,
  getAccountingInstitutionsCodes,
}
