import usePremiumFeature from 'components/App/Premium/usePremiumFeature'

import * as routes from 'config/routes'

const useGetCardsData = (id) => {
  const { showHRFeatures } = usePremiumFeature()

  const cards = [
    {
      title: 'Datos Básicos',
      dataCy: 'company-basic-content',
      description: 'Edita los datos básicos de tu empresa',
      btnText: 'Editar',
      to: routes.COMPANY_EDIT(id),
      state: { initialStep: 0 },
    },
    {
      title: 'Sedes',
      dataCy: 'company-locations',
      description:
        'Edita o agrega las sedes de tu empresa (Las sedes comparten ubicación y caja de compensación)',
      btnText: 'Agregar o editar',
      to: routes.COMPANY_EDIT(id),
      state: { initialStep: 1 },
    },
    {
      title: 'Centros de trabajo',
      dataCy: 'work-centers',
      description:
        'Los centros de trabajo comparten el mismo nivel de riesgo para pago de Seguridad Social',
      btnText: 'Agregar o editar',
      to: routes.COMPANY_EDIT(id),
      state: { initialStep: 2 },
    },
    {
      title: 'Áreas',
      dataCy: 'company-areas',
      description: 'Edita o agrega las áreas de tu empresa',
      btnText: 'Editar',
      to: routes.COMPANY_EDIT(id),
      state: { initialStep: 3 },
    },
    {
      title: 'Cargos',
      dataCy: 'company-positions',
      description: 'Edita o agrega los cargos de tu empresa',
      btnText: 'Editar',
      to: routes.COMPANY_EDIT(id),
      state: { initialStep: 4 },
    },
    {
      title: 'Preferencias de pago y pila',
      dataCy: 'company-payment',
      description: 'Edita las preferencias de pago de tu empresa',
      btnText: 'Editar',
      to: routes.COMPANY_EDIT(id),
      state: { initialStep: 5 },
    },
    {
      title: 'Credenciales y documentación',
      dataCy: 'company-credentials_and_documents',
      description: 'Ingresa las credenciales para realizar afiliaciones',
      btnText: 'Editar',
      to: routes.COMPANY_EDIT(id),
      state: { initialStep: 6 },
      hidden: !showHRFeatures,
    },
  ]

  return cards
}

export default useGetCardsData
