import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { categoryOptions } from './helpers'

const ExtraChargeFields = () => {
  const { values, setValues } = useFormikContext()

  const handleChangeCategory = (_, { props: { value: localCategory } }) => {
    let { value } = values

    if (localCategory === 'percentage' && value > 100) {
      value = 0
    }

    setValues({
      ...values,
      value,
      category: localCategory,
    })
  }

  const isValidPercentageValue = (value) => {
    const { floatValue } = value
    const finalValue = floatValue || 0

    return finalValue <= 100
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridGap: theme.spacing(1),
        gridTemplateColumns: '15rem 9.5rem',
      })}
    >
      <FormField
        name="category"
        variant="select"
        options={categoryOptions}
        onChange={handleChangeCategory}
      />
      <FormField
        name="value"
        variant="currency"
        align="left"
        optional={false}
        isAllowed={
          values.category === 'percentage' ? isValidPercentageValue : () => true
        }
        startAdornment={values.category === 'percentage' ? '%' : '$'}
      />
    </Box>
  )
}

export default ExtraChargeFields
