import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const IncapacitiesAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openNoveltiesModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={payroll.details?.incapacities?.quantity}
      onClick={() => openNoveltiesModal('incapacities')}
      editable={period.editable && !payroll.locked}
      dataCy="add-incapacities"
    />
  )
}

export default IncapacitiesAction
