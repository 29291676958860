import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, useEffect, useState } from 'react'

import { Box, ButtonBase } from '@mui/material'

const DotButton = ({ selected, onClick }) => {
  return (
    <ButtonBase
      sx={(theme) => ({
        mx: '1.125rem',
        boxShadow: selected
          ? `0 0 0 3px ${theme.palette.primary.main}`
          : 'none',
        backgroundColor: 'black.main',
        borderRadius: '50%',
        width: '0.5rem',
        height: '0.5rem',
      })}
      onClick={onClick}
    />
  )
}

const SlideShow = ({ slides = [], delay = 6000, showDots = false, sx }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay, stopOnMouseEnter: true, stopOnInteraction: false }),
  ])

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [emblaApi, setSelectedIndex])

  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  )

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)
  }, [emblaApi, onSelect])

  return (
    <Box
      ref={emblaRef}
      id="slide_show"
      sx={[
        {
          overflow: 'hidden',
          flex: 1,
          position: 'relative',
        },
        sx,
      ]}
    >
      <Box
        id="slide_show_container"
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        {slides.map(({ id, content }, index) => (
          <Box
            key={id}
            id={`slide_${index}`}
            sx={{
              flex: '0 0 100%',
              minWidth: 0,
            }}
          >
            {content}
          </Box>
        ))}
      </Box>
      {slides.length > 1 && showDots ? (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
            bottom: '0.93rem',
          }}
        >
          {slides.map((slide, index) => (
            <DotButton
              key={slide.id}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  )
}

export default SlideShow
