import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { Link as RouterLink } from 'react-router-dom'

import { Button, Menu, MenuItem } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import Icon from 'components/UI/Icon'

import * as routes from 'config/routes'

import { usePeriod } from '../helpers'

export const SocialBenefitsViewMenu = ({
  isTableButton = false,
  fixedButtonStyle,
}) => {
  const {
    period: { id: periodId },
    options: { social_benefits: socialBenefits = [] },
    payPreviousPayroll,
  } = usePeriod()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'socialBenefitsMenu',
  })

  const endFreePayrollModal = useEndFreeModals()

  const handleOnClick = () => {
    popupState.close()
    endFreePayrollModal.openEndFreeSocialBenefitsModal()
  }

  return (
    <>
      <Button
        variant={isTableButton ? 'outlined' : 'text'}
        startIcon={!isTableButton ? <Icon name="calendar" basic /> : null}
        endIcon={<Icon name="arrow-down" basic />}
        {...bindTrigger(popupState)}
        sx={
          !isTableButton
            ? fixedButtonStyle
            : (theme) => ({
                padding: theme.spacing(0, 1.5),
              })
        }
      >
        Prestaciones sociales
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {socialBenefits.map((sb) => {
          return endFreePayrollModal.isNotValidCompany ? (
            <MenuItem
              key={sb.social_benefits_period_id}
              component={RouterLink}
              onClick={handleOnClick}
              state={{ payPreviousPayroll }}
            >
              {sb.label}
            </MenuItem>
          ) : (
            <MenuItem
              key={sb.social_benefits_period_id}
              component={RouterLink}
              to={{
                pathname: payPreviousPayroll
                  ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS(
                      periodId,
                      sb.social_benefits_period_id
                    )
                  : routes.PERIOD_SOCIAL_BENEFIT_PERIODS(
                      periodId,
                      sb.social_benefits_period_id
                    ),
              }}
              state={{ payPreviousPayroll }}
            >
              {sb.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export const Decree376Menu = ({ fixedButtonStyle }) => {
  const {
    period: { id: periodId },
    options: { decree376_periods: decree376Periods = [] },
    payPreviousPayroll,
  } = usePeriod()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'Decree376Menu',
  })

  return decree376Periods && decree376Periods.length > 0 ? (
    <>
      <Button
        variant="text"
        startIcon={<Icon name="document" basic />}
        endIcon={<Icon name="arrow-down" basic />}
        {...bindTrigger(popupState)}
        sx={fixedButtonStyle}
      >
        Decreto 376
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {decree376Periods.map((item) => {
          return (
            <MenuItem
              key={item.id}
              component={RouterLink}
              to={{
                pathname: payPreviousPayroll
                  ? routes.PERIOD_PREVIOUS_PAYROLL_DECREE_376_INDEX(
                      periodId,
                      item.id
                    )
                  : routes.PERIOD_DECREE_376_INDEX(periodId, item.id),
              }}
              state={{ payPreviousPayroll }}
            >
              {item.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  ) : null
}
