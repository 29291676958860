import { Box } from '@mui/material'

import anniversary from 'assets/images/views/dashboard/anniversary.png'
import calendar from 'assets/images/views/dashboard/calendar.png'
import contracts from 'assets/images/views/dashboard/contracts.png'
import explorer from 'assets/images/views/dashboard/explorer.svg'
import gift from 'assets/images/views/dashboard/gift.svg'
import trophy from 'assets/images/views/dashboard/trophy.svg'

export const getDataConfig = (type, sizeData) => {
  const defaultCopies = {
    birthdays: {
      shortTitle: 'Cumpleaños',
      title: 'Personas que cumplen años',
      description: 'La siguientes personas cumplen años:',
      icon: <Box component="img" src={gift} sx={{ width: '1.5rem' }} />,
    },
    anniversaries: {
      shortTitle: 'Aniversarios',
      title: 'Aniversarios',
      description:
        'Las siguientes personas cumplen años trabajando en tu compañía:',
      icon: <Box component="img" src={trophy} sx={{ width: '1.5rem' }} />,
    },
    contracts_to_expire: {
      shortTitle: 'Contratos a vencer',
      title: 'Contratos próximos a vencer',
      description:
        'Las siguientes personas tienen contratos próximos a vencer:',
      icon: <Box component="img" src={explorer} sx={{ width: '1.5rem' }} />,
    },
  }

  const emptyStateCopies = {
    birthdays: {
      shortTitle: 'Cumpleaños',
      title: 'Cumpleaños',
      description: 'Este mes no tienes cumpleaños por celebrar',
      icon: <Box component="img" src={calendar} sx={{ width: '5rem' }} />,
    },
    anniversaries: {
      title: 'Aniversarios',
      shortTitle: 'Aniversarios',
      description: 'Este mes no tienes aniversarios por celebrar',
      icon: <Box component="img" src={anniversary} sx={{ height: '5rem' }} />,
    },
    contracts_to_expire: {
      shortTitle: 'Contratos',
      title: 'Contratos próximos a vencer',
      description: 'Este mes no tienes contratos próximos a vencer',
      icon: <Box component="img" src={contracts} sx={{ width: '5rem' }} />,
    },
  }
  return sizeData > 0 ? defaultCopies[type] : emptyStateCopies[type]
}

export default { getDataConfig }
