import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Button, Card, Typography } from '@mui/material'

import OrganizationEmail from 'components/UI/OrganizationEmail'

import { getCompanyId } from 'utils/company'
import { isTest } from 'utils/general'
import useConfetti from 'utils/hooks/useConfetti'
import useNotifications from 'utils/hooks/useNotifications'

import image from 'assets/images/views/payroll/marni.png'

import SummaryTable from '../Summary/Index'
import PayrollActions from './ActionGroup/Actions'
import PeriodTotals from './PeriodTotals'
import { usePeriod, useReturnPayPayroll } from './helpers'

const GeneratedPayroll = () => {
  const { throwConfetti } = useConfetti()

  const {
    period: { contracts_counter: contractsCounter = {}, id: periodId },
    payPreviousPayroll,
    hasEarlyPayment,
  } = usePeriod()
  const handleReturnToPayPayroll = useReturnPayPayroll()
  const { showInfoMessage } = useNotifications()
  const companyId = getCompanyId()
  const queryClient = useQueryClient()
  const payrollsCacheData = queryClient.getQueryData(
    ['periodPayrolls', companyId, periodId],
    {
      exact: false,
    }
  )
  const hasEarlyPaymentFromCache = payrollsCacheData?.data?.early_payment

  const showExclusiveFileAlert =
    ((hasEarlyPaymentFromCache && hasEarlyPayment) ||
      hasEarlyPaymentFromCache ||
      contractsCounter.terminated !== 0) &&
    !payPreviousPayroll &&
    !isTest

  useEffect(() => {
    if (showExclusiveFileAlert) {
      showInfoMessage(
        'Recuerda que las personas liquidadas o con pago anticipado de vacaciones (sin días trabajados después de vacaciones) genera un archivo exclusivo para pagar en el banco.',
        { persist: true, preventDuplicate: true }
      )
    }

    let confettiTimer

    if (!payPreviousPayroll) {
      confettiTimer = setTimeout(() => {
        throwConfetti({ origin: { x: 0.5, y: 0.8 }, particleCount: 150 })
        throwConfetti({ origin: { x: 0.2, y: 0.4 }, particleCount: 150 })
        throwConfetti({ origin: { x: 0.8, y: 0.4 }, particleCount: 150 })
      }, 500)
    }

    return () => {
      clearTimeout(confettiTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!payPreviousPayroll ? (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridColumn: '1 / -1',
            columnGap: theme.spacing(3),
            rowGap: theme.spacing(3),
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridAutoRows: 'max-content',
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: 'repeat(6, 1fr)',
              columnGap: theme.spacing(4),
              rowGap: theme.spacing(4),
            },
            [theme.breakpoints.up('desktop')]: {
              gridTemplateColumns: 'repeat(12, 1fr)',
            },
          })}
        >
          <Box sx={{ gridColumn: '1 / -1', gridRow: '1' }}>
            <Typography
              variant="h3"
              sx={(theme) => ({ marginBottom: theme.spacing(2) })}
            >
              ¡Aleluya! Liquidaste tu nómina en menos de lo que canta un gallo.
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              borderRadius: '1rem',
              gridColumn: '1 / -1',
              gridRow: '2',
              [theme.breakpoints.up('desktop')]: {
                gridColumn: 'span 8',
              },
            })}
          >
            <Card
              sx={(theme) => ({
                backgroundColor: theme.palette.accent1.main,
                borderRadius: '1rem',
                padding: theme.spacing(2.5, 3),
                minHeight: '11.56rem',
                [theme.breakpoints.up('tablet')]: {
                  padding: theme.spacing(2.5, 3, 2.5, 0),
                  overflow: 'initial',
                },
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: 'none',
                    [theme.breakpoints.up('tablet')]: {
                      display: 'block',
                      width: '100%',
                      maxWidth: '12rem',
                      position: 'relative',
                    },
                  })}
                >
                  <Box
                    component="img"
                    src={image}
                    sx={(theme) => ({
                      display: 'none',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      margin: 'auto',
                      [theme.breakpoints.up('tablet')]: {
                        display: 'block',
                      },
                      [theme.breakpoints.up('xdesktop')]: {
                        top: '2rem',
                      },
                    })}
                    width={153}
                    height={239}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body1">
                    Creamos para ti tus archivos para pago en banco, colillas de
                    pago, archivo para pago de seguridad social, interfaz
                    contable y resúmenes de nómina. Recuerda que puedes
                    escribirnos a{' '}
                    <OrganizationEmail sx={{ textDecoration: 'inherit' }} /> si
                    necesitas que te rescatemos con algo.
                  </Typography>
                  <Box
                    sx={(theme) => ({
                      textAlign: 'right',
                      marginTop: theme.spacing(3),
                    })}
                  >
                    <Button onClick={handleReturnToPayPayroll}>Regresar</Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box
            sx={(theme) => ({
              gridColumn: '1 / -1',
              gridRow: '3',
              [theme.breakpoints.up('desktop')]: {
                gridColumn: 'span 4',
                gridRow: '2 / span 2',
              },
              height: '100%',
            })}
          >
            <Card
              sx={(theme) => ({
                borderRadius: '1rem',
                padding: theme.spacing(2),
              })}
            >
              <Typography
                variant="h4"
                sx={(theme) => ({
                  marginBottom: theme.spacing(3),
                })}
              >
                Esto es lo que debes pagar
              </Typography>
              <PeriodTotals stack compact />
            </Card>
          </Box>
          <Box
            sx={(theme) => ({
              gridColumn: '1 / -1',
              gridRow: '4',
              paddingBottom: theme.spacing(10),
              [theme.breakpoints.up('desktop')]: {
                gridColumn: 'span 8',
                gridRow: '3',
              },
            })}
          >
            <PayrollActions />
          </Box>
        </Box>
      ) : null}
      {payPreviousPayroll ? (
        <Box>
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
          >
            Resumen novedades en este periodo
          </Typography>
          <SummaryTable />
        </Box>
      ) : null}
    </Box>
  )
}

export default GeneratedPayroll
