import qs from 'query-string'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import OrganizationEmail from 'components/UI/OrganizationEmail'
import Page from 'components/UI/Page/Page'
import SegmentedControl from 'components/UI/SegmentedControl'

import useIntegrationService from 'utils/hooks/settings/integrationService'

import { PERIOD_HISTORY } from 'config/routes'

import ElectronicPayrollHistory from './ElectronicPayrollHistory/ElectronicPayrollHistory'
import PayrollHistory from './PayrollHistory/PayrollHistory'

const PeriodHistory = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = qs.parse(location.search)
  const initialTab = queryParams?.tab || 'payroll_history'
  const [activeTab, setActiveTab] = useState(initialTab)
  const [hasAlegraIntegration, setHasAlegraIntegration] = useState(null)

  const { integrationsQuery } = useIntegrationService({
    serviceParams: {
      queryKey: 'integrationsCompany',
    },
    queryOptions: {
      onSuccess: ({ data }) =>
        setHasAlegraIntegration(
          data?.filter((integration) => integration.provider === 'alegra')[0]
            .active
        ),
    },
  })

  const { company } = useUser()

  const electronicPayrollAuthorized = company?.electronic_payroll_authorized

  const handleChangeSegment = (newTab) => {
    setActiveTab(newTab)
    navigate(PERIOD_HISTORY(`?tab=${newTab}`))
  }

  const getTabs = (electronicPayroll) => {
    return [
      {
        id: 'payroll_history',
        label: 'Nómina',
        onClick: () => handleChangeSegment('payroll_history'),
      },
      {
        id: 'electronic_payroll_history',
        label: 'Nómina electrónica',
        disabled: !electronicPayroll,
        onClick: () => handleChangeSegment('electronic_payroll_history'),
      },
    ]
  }

  return (
    <Page
      documentTitle="Historial de periodos"
      header={
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            gridColumn: '1 / -1',
          })}
        >
          <Typography variant="h2">Historial de periodos</Typography>
          <Typography variant="body1" color="black.dark">
            Si quieres cargar la información de períodos anteriores escríbenos a{' '}
            <OrganizationEmail />.
          </Typography>
        </Box>
      }
      isLoading={integrationsQuery.isLoading}
      grid
    >
      {electronicPayrollAuthorized ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            gridColumn: '1 / -1',
            marginBottom: theme.spacing(5),
          })}
        >
          <SegmentedControl
            segments={getTabs(activeTab)}
            initialSegment={activeTab}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        {activeTab === 'payroll_history' ? (
          <PayrollHistory
            hasAlegraIntegration={hasAlegraIntegration}
            activeTab={activeTab}
          />
        ) : null}
        {activeTab === 'electronic_payroll_history' ? (
          <ElectronicPayrollHistory activeTab={activeTab} />
        ) : null}
      </Box>
    </Page>
  )
}

export default PeriodHistory
