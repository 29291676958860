import { useContext } from 'react'

import UserContext from './UserContext'

/**
 * @typedef {object} UserContext
 * @property {object} user
 * @property {object} company
 * @property {boolean} hasOneCompany
 * @property {object} subscription
 * @property {string} distributor
 * @property {Function} logIn
 * @property {Function} logOut
 * @property {Function} updateUser
 * @property {Function} clearCompany
 * @property {Function} changeCompany
 * @property {Function} refreshCompany
 * @property {string} authToken
 * @property {string} expires
 * @property {boolean} isAuthenticated
 * @property {boolean} hasRefreshTried
 * @property {boolean} isWorker
 * @property {boolean} isOrganizer
 * @property {boolean} isLoading
 */

/**
 * @returns { UserContext }
 */
export const useUser = () => useContext(UserContext)

export default {}
