import { useState } from 'react'

import { getWordSingular } from 'utils/format'

import useUpdateOnboarding from './useUpdateOnboarding'

const getErrorReason = (errors = []) => {
  if (errors.length === 1) return errors[0]?.complement

  let message = ''

  errors.forEach((error, index) => {
    if (index < errors.length - 1) {
      message += `${index > 0 ? ', ' : ''}${error.complement}`
    } else {
      message += `${index > 0 ? ' y ' : ''}${error.complement}`
    }
  })

  return message
}

const useHiringAlertModal = () => {
  const [state, setState] = useState({
    open: false,
    title: '',
    description: '',
    actionLabel: '',
    hideCancelButton: false,
    action: () => {},
  })
  const { updateOnboarding, onboardingMutation } = useUpdateOnboarding({
    mutationOptions: {
      onError: () => {}, // This is to cancel the default behaviour and not show the floating alert
    },
  })

  const closeHiringAlertModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const handleUpdateOnboarding = (onboardingId, nextStatus, actionType) => {
    updateOnboarding({
      onboardingId,
      data: {
        status: nextStatus,
      },
      onSuccessCallback: () => {
        closeHiringAlertModal()
      },
      onErrorCallback: (error) => {
        const reason = getErrorReason(error.errors)
        const multipleReasons = error.errors.length > 1

        setState((previousState) => ({
          ...previousState,
          title: `No puedes ${
            actionType === 'complete' ? 'completar' : 'descartar'
          } el proceso de forma manual`,
          description: (
            <>
              Actualmente esta persona cuenta con{' '}
              {multipleReasons ? 'los procesos' : 'el proceso'}{' '}
              {getWordSingular('abiertos', !multipleReasons)} de <b>{reason}</b>
              . Para {actionType === 'complete' ? 'completarlo' : 'descartarlo'}
              , debes finalizar primero{' '}
              {getWordSingular('dichos', !multipleReasons)}{' '}
              {getWordSingular('procesos', !multipleReasons)}.
            </>
          ),
          actionLabel: 'Regresar',
          hideCancelButton: true,
          action: closeHiringAlertModal,
        }))
      },
    })
  }

  const openHiringAlertModal = ({ actionType, onboardingId }) => {
    const configuration = {
      title: '',
      description: '',
      actionLabel: '',
      hideCancelButton: false,
      action: closeHiringAlertModal,
    }

    if (actionType === 'complete') {
      configuration.title =
        '¿Quieres marcar como Completado todo el proceso de esta persona?'
      configuration.description =
        'Cuando marcas como completada una nueva contratación, todos los procesos quedan aprobados y la persona sale del flujo de Nuevas Contrataciones.'
      configuration.actionLabel = 'Completar'
      configuration.action = () =>
        handleUpdateOnboarding(onboardingId, 'complete_onboarding', actionType)
    } else if (actionType === 'discard') {
      configuration.title =
        '¿Quieres descartar la Nueva Contratación de esta persona?'
      configuration.description =
        'Si descartas esta persona, automáticamente saldrá de la lista de personas, no podrás realizarle ningún proceso de contratación y tampoco podrás liquidarle su nómina.'
      configuration.actionLabel = 'Descartar'
      configuration.action = () =>
        handleUpdateOnboarding(onboardingId, 'cancel_onboarding', actionType)
    }

    setState((previousState) => ({
      ...previousState,
      open: true,
      ...configuration,
    }))
  }

  return {
    hiringAlertModalState: {
      ...state,
      isLoading: onboardingMutation.isLoading,
    },
    closeHiringAlertModal,
    openHiringAlertModal,
  }
}

export default useHiringAlertModal
