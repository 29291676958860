import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getRecurrentConcepts,
  updateRecurrentConcept,
} from 'services/worker/recurrentConceptService'
import { getWorkerRecurrentConcepts } from 'services/worker/workerProfileService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getRecurrentConcepts')
    return getRecurrentConcepts(workerId)

  if (currentQueryKey === 'getWorkerRecurrentConcepts')
    return getWorkerRecurrentConcepts(workerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, recurrentConcepts } = mutationData

  if (mutationMethod === 'PUT')
    return updateRecurrentConcept({ workerId, recurrentConcepts })

  return null
}

const useRecurrentConceptsService = ({
  serviceParams = { queryKey: 'getRecurrentConcepts' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restRecurrentConceptsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const recurrentConceptsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    recurrentConceptsQuery: {
      data,
      ...restRecurrentConceptsResponse,
    },
    recurrentConceptsMutation,
  }
}

export default useRecurrentConceptsService
