import { useMutation } from 'react-query'

import { updateOnboarding } from 'services/worker/onboardingService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationKey,
    onboardingId,
    onboardingData,
  } = mutationData

  if (mutationMethod === 'PUT' && mutationKey === 'updateOnboarding')
    return updateOnboarding({ onboardingId, onboardingData })

  return null
}

const useCompanyOnboardingService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const companyOnboardingMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    companyOnboardingMutation,
  }
}

export default useCompanyOnboardingService
