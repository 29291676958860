import { Box, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'

import PaymentExoneratedSubscriptionModal from './index'

const usePaymentExoneratedSubscriptionModal = () => {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal('paymentExoneratedSubscriptionModal')
  }

  const openPaymentExoneratedSubscriptionModal = () => {
    modals.openModal({
      id: 'paymentExoneratedSubscriptionModal',
      content: <PaymentExoneratedSubscriptionModal />,
      modalProps: {
        header: (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(1),
            })}
          >
            <Typography variant="h3" color="black.main">
              ¡Conoce los superpoderes de tu plan Aleluya!
            </Typography>
          </Box>
        ),
        onCloseModal: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '50rem',
        },
        contentWrapperSx: {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        },
      },
    })
  }

  return { openPaymentExoneratedSubscriptionModal }
}

export default usePaymentExoneratedSubscriptionModal
