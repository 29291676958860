import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useLocationService from 'utils/hooks/company/locationService'
import useNotifications from 'utils/hooks/useNotifications'

import {
  createLocation,
  deleteLocation,
  updateLocation,
} from 'services/company/locationService'

import { formatLocation, getColumnsData, validationSchema } from './helpers'

const CompanyFormFieldsLocations = () => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { setFieldValue } = useFormikContext()

  const { locationQuery } = useLocationService({
    serviceParams: { queryKey: ['companyLocation', getCompanyId()] },
    queryOptions: {
      onSuccess: ({ data }) => {
        setFieldValue('locations', data)
      },
    },
  })

  const callback = (action) => {
    queryClient.invalidateQueries('companyLocation')
    showSuccessMessage(`La sede  ha sido ${action}.`)
  }

  const handleAddLocation = (location) => {
    const locationFormatted = formatLocation(location)
    return {
      data: {
        ...locationFormatted,
      },
      mutationFunction: createLocation,
      mutateOptions: {
        onSuccess: () => callback('creada'),
      },
    }
  }

  const handleUpdateLocation = (oldLocation, newLocation) => {
    const dirtyValues = getDirtyValues(
      oldLocation,
      newLocation,
      validationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      const locationFormatted = formatLocation(newLocation)
      return {
        data: { ...locationFormatted },
        mutationFunction: updateLocation,
        mutateOptions: {
          onSuccess: () => callback('actualizada'),
        },
      }
    }
    return {}
  }

  const handleDeleteLocation = (location) => {
    return {
      data: location.id,
      mutationFunction: deleteLocation,
      mutateOptions: {
        onSuccess: () => callback('eliminada'),
      },
    }
  }

  const columnsData = getColumnsData()

  return !locationQuery?.isLoading ? (
    <Table
      columns={columnsData}
      data={locationQuery?.data}
      options={{
        pagination: false,
        search: false,
        version: {
          toolbar: 'v2',
        },
      }}
      editable={{
        onAddRow: handleAddLocation,
        onUpdateRow: handleUpdateLocation,
        onDeleteRow: handleDeleteLocation,
        isDeletable: ({ deleteable }) => deleteable,
        enableAddRow: true,
        addRowActionProps: {
          tooltip: 'Añadir Sede',
        },
        addRowPosition: 'top',
        validationSchema,
        tableMinWidth: 1080,
      }}
    />
  ) : null
}

export default CompanyFormFieldsLocations
