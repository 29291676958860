import { Box, Typography } from '@mui/material'

const Header = ({ title, description }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        marginBottom: theme.spacing(3),
      })}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  )
}

export default Header
