import { useState } from 'react'

const useUpdateGeneralWorkerDataModal = () => {
  const [state, setState] = useState({
    open: false,
    title: null,
    modalType: null,
  })

  const openModal = (title, modalType) =>
    setState((previousState) => ({
      ...previousState,
      open: true,
      modalType,
      title,
    }))

  const closeModal = () =>
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))

  return {
    updateGeneralDataModalState: state,
    openGeneralDataModal: openModal,
    closeGeneralDataModal: closeModal,
  }
}

export default useUpdateGeneralWorkerDataModal
