import { Box, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'

const CommentsModal = ({ state, handleClose, openAffiliationModal }) => {
  const companyId = getCompanyId()
  const { workerId } = state
  const affiliationQueryKey = ['getAffiliationById', workerId, companyId]

  const { affiliationsQuery } = useAffiliationsService({
    serviceParams: { queryKey: affiliationQueryKey, workerId, companyId },
    queryOptions: {
      enabled: Boolean(workerId) && state.open,
    },
  })

  const openAffiliationForm = () => {
    openAffiliationModal({
      affiliation: state.affiliation,
      onboardingId: state.onboardingId,
      workerName: state.workerName,
      workerId: state.workerId,
    })
    handleClose()
  }

  const affiliations = affiliationsQuery?.data || {}

  return (
    <Modal
      open={state.open}
      header="Tu solicitud de afiliación a Seguridad Social debe ser revisada"
      onCancel={handleClose}
      onOk={openAffiliationForm}
      okText="Completar afiliación"
      adornment={[
        {
          variant: 'signature',
          color: 'complementary1.light',
          width: 177,
          height: 195,
          sx: { bottom: '-4rem', right: '2rem' },
        },
      ]}
      paperSx={{
        maxWidth: '45.5rem',
      }}
    >
      <Box>
        <Typography
          variant="h4"
          color="accent4.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          Fecha de creación: {affiliations.rejected_at}
        </Typography>
        <Typography color="black.light">
          {affiliations.rejected_comment}
        </Typography>
      </Box>
    </Modal>
  )
}

export default CommentsModal
