import { useQueryClient } from 'react-query'

import { Box } from '@mui/material'

import Table from 'components/UI/Table/Table'

import useNotifications from 'utils/hooks/useNotifications'

import {
  deleteCompanyComment,
  getAllCompanyComments,
} from 'services/organizer/companyService'

import CommentModal from './CommentModal'
import { getActions, getColumnsData } from './helpers'
import { useCommentModal } from './useCommentModal'

export default function CommentsTable({ companyId }) {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()

  const { openCommentModal, commentModalState, closeCommentModal } =
    useCommentModal()

  const fetchComments = (pageIndex, search) => {
    return {
      queryKey: ['getAllCompanyComments', pageIndex + 1, search],
      queryFunction: () =>
        getAllCompanyComments({ page: pageIndex + 1, search, companyId }),
    }
  }

  const callback = (action) => {
    queryClient.invalidateQueries('getAllCompanyComments')
    closeCommentModal()

    showSuccessMessage(`Comentario ${action} exitosamente`)
  }

  const handleShowComment = (commentData) => {
    openCommentModal({ commentData, action: 'show' })
  }

  const handleCreateComment = () => {
    openCommentModal({ companyId, action: 'create' })
  }

  const handleUpdateComment = (commentData) => {
    openCommentModal({ commentData, companyId, action: 'update' })
  }

  const handleDeleteComment = (oldData) => {
    return {
      data: {
        companyId,
        commentId: oldData.id,
      },
      mutationFunction: deleteCompanyComment,
      mutateOptions: {
        onSuccess: () => callback('eliminado'),
      },
    }
  }

  const columns = getColumnsData()
  const actions = getActions({
    handleShowComment,
    handleCreateComment,
    handleUpdateComment,
  })

  return (
    <>
      <Box sx={(theme) => ({ marginTop: theme.spacing(3) })}>
        <Table
          data={fetchComments}
          columns={columns}
          actions={actions}
          options={{
            alignActionsCell: 'center',
            pagination: false,
            search: false,
            version: {
              toolbar: 'v2',
            },
          }}
          editable={{
            hideEdit: true,
            onDeleteRow: handleDeleteComment,
            tableMinWidth: 960,
            isDeletable: ({ deleteable }) => deleteable,
          }}
        />
      </Box>

      {commentModalState.open ? (
        <CommentModal
          state={commentModalState}
          handleClose={closeCommentModal}
          callback={callback}
        />
      ) : null}
    </>
  )
}
