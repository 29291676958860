import Emoji from 'components/UI/Emoji'

import { cryEmoji } from 'utils/emojis'

export const getSubscriptionAlertCopies = (subscriptionStatus) => {
  const subscriptionAlertCopies = {
    premium_expired: {
      title: '¡Yisus! Tu suscripción está vencida',
      buttonLabel: 'Renovar suscripción',
    },
    premium_due: {
      title: (
        <>
          Tu periodo de prueba te dice adiós <Emoji code={cryEmoji} />
        </>
      ),
      buttonLabel: 'Activar plan premium',
    },
  }

  return subscriptionAlertCopies[subscriptionStatus]
}
