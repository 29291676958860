import { zohoScript } from 'utils/integrations/scripts/zoho'

export function removeCurrentChat() {
  document.getElementById('zoho_support_chat')?.remove()

  document
    .querySelectorAll('iframe')
    ?.forEach?.((iframe) => iframe.parentNode.removeChild(iframe))
}

function loadChatScript() {
  // Creates script element
  const script = document.createElement('script')
  script.setAttribute('id', 'zoho_support_chat')

  // Sets script attributes
  script.src = zohoScript.src
  script.async = zohoScript.async
  script.defer = zohoScript.defer

  // Executes onload function
  script.onload = zohoScript.child()

  // Appends the script to the DOM
  document.body.appendChild(script)
}

export default loadChatScript
