import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  initial_day: yup.string().required('La fecha de inicio es requerida'),
  end_day: yup.string().required('La fecha de fin es requerida'),
  payroll_concept_id: yup.string().required('El tipo de novedad es requerido'),
  evidence: yup.array().of(yup.object(), yup.string()).nullable(),
  comment: yup.string().nullable(),
})

const conceptsByContract = (contract) => {
  switch (contract) {
    case 'schooling_trainee':
      return [
        'general_incapacity',
        'maternity_leave',
        'paternity_leave',
        'suspension',
      ]

    case ['productive_trainee', 'intern'].includes(contract):
      return [
        'general_incapacity',
        'labor_incapacity',
        'maternity_leave',
        'paternity_leave',
        'suspension',
      ]

    case 'student_decree_055':
      return ['labor_incapacity']

    case 'part_time_contract':
      return [
        'enjoyed_days',
        'labor_incapacity',
        'paid_leave',
        'unpaid_leave',
        'suspension',
        'job_abandonment',
      ]

    default:
      return []
  }
}

export const filterRequestTypes = (concepts, contractCategory) => {
  const invalidConcepts = ['compensated_days', 'job_abandonment', 'suspension']

  const allConcepts = Object.values(concepts).reduce(
    (filteredConcepts, currentConcept) => {
      return [...filteredConcepts, ...currentConcept]
    },
    []
  )

  let filteredConcepts = allConcepts.filter(
    (concept) => !invalidConcepts.includes(concept.coded_name)
  )

  if (!['student_law_789', 'employee'].includes(contractCategory)) {
    const contractConcepts = conceptsByContract(contractCategory)
    filteredConcepts = filteredConcepts.filter((concept) =>
      contractConcepts.includes(concept.coded_name)
    )
  }

  return filteredConcepts.map((concept) => ({
    label: concept.name,
    value: concept.id,
  }))
}

export default validationSchema
