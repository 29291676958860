import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useTheme } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import ActionButton from 'components/Period/Payroll/DataTable/ActionButton'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Table from 'components/UI/Table/Table'

import useDianMaintenance from 'utils/hooks/ElectronicPayroll/useDianMaintenance'

import SingleEmittedRejectedModal from '../../ModalRejected/SingleEmittedRejectedModal'
import useSingleEmittedRejectedModal from '../../ModalRejected/useSingleEmittedRejectedModal'
import { getColumnsData, getDataFilter, useActionsData } from './helpers'

const TableElectronicPayrollPeriod = ({ electronicPayrollsData }) => {
  const theme = useTheme()
  const { periodId } = useParams()

  const confirm = useConfirm()
  const endFreeElectronicPayrollModal = useEndFreeModals()
  const { maintenance } = useDianMaintenance()
  const {
    closeSingleEmittedRejectedModal,
    openSingleEmittedRejectedModal,
    singleEmittedRejectedModalState,
  } = useSingleEmittedRejectedModal()

  const [activeStatus, setActiveStatus] = useState('')
  const [electronicPayrollFormatted, setElectronicPayrollFormatted] =
    useState(null)

  const allPayrollIssued =
    electronicPayrollsData?.electronic_payrolls_counter?.issued ===
    electronicPayrollsData?.electronic_payrolls_counter?.total
  const disabledButtonSubmit = allPayrollIssued || maintenance

  const getCustomRowStyle = ({ options }) => {
    const { to_issue: toIssue } = options

    if (!toIssue) {
      return {
        backgroundColor: theme.palette.white.light,
      }
    }
    return {}
  }

  const columnsData = getColumnsData(openSingleEmittedRejectedModal)
  const actionsData = useActionsData({
    maintenance,
    disabledButtonSubmit,
    activeStatus,
    setActiveStatus,
    periodId,
    confirm,
    endFreeElectronicPayrollModal,
  })

  useEffect(() => {
    const dataFormatted = [
      ...(electronicPayrollsData?.electronic_payrolls || []),
    ]

    dataFormatted.forEach((electronicPayroll, index) => {
      if (!electronicPayroll?.options?.to_issue) {
        dataFormatted[index].disableCheck = true
      }
    })

    setElectronicPayrollFormatted(dataFormatted)
  }, [electronicPayrollsData])

  return electronicPayrollFormatted ? (
    <>
      <Formik initialValues={{ electronicPayrollFormatted }} enableReinitialize>
        {({ values }) => {
          return (
            <Form>
              <Table
                columns={columnsData}
                data={getDataFilter(
                  values.electronicPayrollFormatted,
                  activeStatus
                )}
                actions={actionsData}
                options={{
                  version: {
                    toolbar: 'v2',
                  },
                  selection: true,
                  selectionId: 'disableCheck',
                  rowStyle: getCustomRowStyle,
                  hiddenColumns: ['worker.id_number'],
                  customActionsWidth: '15.7rem',
                  alignActionsCell: 'center',
                }}
                components={{
                  ActionButton,
                }}
              />
            </Form>
          )
        }}
      </Formik>
      {singleEmittedRejectedModalState.open ? (
        <SingleEmittedRejectedModal
          closeModal={closeSingleEmittedRejectedModal}
          state={singleEmittedRejectedModalState}
        />
      ) : null}
    </>
  ) : null
}

export default TableElectronicPayrollPeriod
