import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import DeleteItemButton from 'components/UI/Button/DeleteIconButton'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import GridRow from '../Payroll/Modals/common/CardGrid/GridRow'
import HeaderCell from '../Payroll/Modals/common/CardGrid/HeaderCell'
import MobileCell from '../Payroll/Modals/common/CardGrid/MobileCell'
import GroupDivider from '../Payroll/Modals/common/GroupDivider'

const AVISTA_ADVANCE = 'avista_payroll_advance'

export default function OptionSelector({
  data,
  listOptions,
  onSelectItem,
  onChangeItemValue,
  onDeleteItem,
  category,
  indexToIgnore, // to ignore the payroll_retention item
  name = 'payroll_concept_id',
  gutterBottom,
}) {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  const conceptLabel = {
    salary_income: 'Constitutivo de salario',
    non_salary_income: 'No constitutivo de salario',
    deductions: 'Deducciones',
  }

  return data.map((item, index) => {
    if (indexToIgnore && indexToIgnore.includes(index)) return null
    const defaultKey = `${category}-item-${index}`

    const { value } = item
    // Get the id from the custom name
    const payrollConceptId = item[name]
    // Validation for disabled when deleteable false and does not exist item for created concepts
    const optionItem =
      !!listOptions &&
      listOptions.filter(
        (option) => option.owner_type !== 'Country' && option.name === item.name
      )

    const disableButton =
      (index === 0 && !payrollConceptId && data.length === 1) ||
      (!!optionItem &&
        optionItem.length > 0 &&
        !optionItem[0].deleteable &&
        value === 0)

    return (
      <Box key={item.id || defaultKey}>
        <GridRow
          gutterBottom={gutterBottom && index < data?.length - 1}
          id={`${category}-item-${index}`}
          dataCy={`${category}-item-${index}`}
        >
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 1,
            }}
            mobileOnly
          >
            Tipo de ingreso
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 2,
            }}
            mobileOnly
            alignY="center"
          >
            Concepto
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 3,
            }}
            mobileOnly
            alignY="center"
          >
            Valor
          </HeaderCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 1,
              },
            })}
          >
            <Typography variant="h6" color="black.dark">
              {conceptLabel[category]}
            </Typography>
          </MobileCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            alignY="center"
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 2,
              },
            })}
          >
            <Select
              name={name}
              value={payrollConceptId}
              onChange={(e) => {
                onSelectItem(e.target.value, index, category)
              }}
              displayEmpty
              data-cy={`${category}-select-${index}`}
              sx={{
                maxWidth: '13.5rem',
                width: '100%',
                height: '2rem',
                textAlign: 'left',
              }}
            >
              <MenuItem disabled value="">
                <em>Selecciona...</em>
              </MenuItem>

              {listOptions.map((option) => {
                const { name: nameOption, id, selected } = option

                return (selected && id !== payrollConceptId) ||
                  option.coded_name === AVISTA_ADVANCE ? null : (
                  <MenuItem key={id} value={id}>
                    {nameOption}
                  </MenuItem>
                )
              })}
            </Select>
          </MobileCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            alignY="center"
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 3,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
              })}
            >
              <TextField
                name={`${category}_value_${index}`}
                value={value}
                onChange={(e) => onChangeItemValue(e, index, category)}
                InputProps={{
                  inputComponent: NumberFormatField,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  placeholder: '0',
                  sx: {
                    height: '2rem',
                    maxWidth: '12rem',
                  },
                }}
              />
              {!isTabletUp ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <DeleteItemButton
                    aria-label="delete"
                    onClick={() => onDeleteItem(index, category)}
                    deleteable={!disableButton}
                    color="complementary1"
                  />
                </Box>
              ) : null}
            </Box>
          </MobileCell>
          {isTabletUp ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteItemButton
                aria-label="delete"
                onClick={() => onDeleteItem(index, category)}
                deleteable={!disableButton}
                color="complementary1"
              />
            </Box>
          ) : null}
        </GridRow>
        {index + 1 < data?.length ? <GroupDivider /> : null}
      </Box>
    )
  })
}
