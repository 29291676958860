import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { useTableStyles } from '../helpers'
import RowCell from './RowCell'
import { getIsRowEditable, validateEditFieldMetadata } from './helpers'

const EditRowField = ({
  name,
  variant = 'textfield',
  placeholder,
  options,
}) => {
  return (
    <FormField
      name={name}
      data-cy={name}
      variant={variant}
      placeholder={placeholder}
      options={options}
    />
  )
}

const EditRowCell = ({ cell, editMode }) => {
  const { values } = useFormikContext()
  const { name, field, editOptions } = validateEditFieldMetadata(cell.column)
  const { getCellStyles } = useTableStyles()
  const options =
    typeof editOptions === 'function' ? editOptions(cell, values) : editOptions
  const fieldName =
    typeof name === 'function' ? name(cell, values) : name || cell.column.id
  const isEditable = getIsRowEditable(cell, values, editMode)

  if (isEditable && cell.column.Edit) {
    return (
      <Box
        sx={getCellStyles(cell.column)}
        style={{
          ...cell.column.cellStyle,
          width: `calc(${cell.column.customWidth} - 32px)`,
        }}
      >
        {cell.render('Edit', { rowValues: values })}
      </Box>
    )
  }

  if (isEditable) {
    return (
      <Box
        sx={getCellStyles(cell.column)}
        style={{
          ...cell.column.cellStyle,
          width: `calc(${cell.column.customWidth} - 32px)`,
        }}
      >
        <EditRowField
          name={fieldName}
          variant={field}
          options={options}
          placeholder={cell.column.placeholder}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={getCellStyles(cell.column)}
      style={{
        ...cell.column.cellStyle,
        width: `calc(${cell.column.customWidth} - 32px)`,
      }}
    >
      <RowCell cell={cell} />
    </Box>
  )
}

export default EditRowCell
