import { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import {
  Box,
  Divider,
  Button as MButton,
  Slide,
  Typography,
} from '@mui/material'

import Button from 'components/UI/Button/Button'

import { getColombianCurrentDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import DeleteForm from './Modals/DeleteForm'
import { getSummaryItems } from './helpers'

const SocialSecurityPaymentSummary = ({
  isLoading,
  formData,
  hasErrors,
  handlePayForm,
  handleDeleteForm,
  provider,
}) => {
  const navigate = useNavigate()
  const { showErrorMessage } = useNotifications()
  const { status } = formData
  const [showDeleteForm, setShowDeleteForm] = useState(false)

  const paymentSummary = getSummaryItems(formData)

  const date = getColombianCurrentDate()
  const dateHour = date.getHours()
  const dateMinute = date.getMinutes()
  const isAELDisabled =
    (dateHour === 16 && dateMinute >= 30) ||
    dateHour === 17 ||
    (dateHour === 18 && dateMinute <= 30)

  useEffect(() => {
    if (isAELDisabled && (status === 'created' || status === 'declined')) {
      showErrorMessage(
        'Recuerda que por reglamentación en Colombia no es posible realizar pago de la seguridad social entre las 4:30 y las 6:30pm'
      )
    }
  }, [isAELDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Slide direction="left" timeout={500} in>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          padding: theme.spacing(3),
          boxShadow: theme.shadows[3],
          backgroundColor: theme.palette.white.main,
        })}
      >
        <Typography variant="h5">Resumen de tu pago</Typography>
        <Box
          sx={(theme) => ({
            margin: theme.spacing(5, 0, 5),
          })}
        >
          {paymentSummary.map((item, index) => {
            if (item.hidden) return null

            return (
              <div key={`${index.toString()}_${item.label}`}>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(2, 0),
                  })}
                >
                  <Typography color="black.dark">{item.label}</Typography>
                  <Typography variant="lead1">{item.value}</Typography>
                </Box>
                {index !== paymentSummary.length - 1 ? <Divider /> : null}
              </div>
            )
          })}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginBottom: theme.spacing(2),
          })}
        >
          <Typography>Total a pagar</Typography>

          <Typography variant="h4">
            {formatCurrency(formData?.value || 0)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > button, button + a, a': {
              marginLeft: '0 !important',
            },
          }}
        >
          {!hasErrors ? (
            <>
              {status === 'created' || status === 'declined' ? (
                <Button
                  onClick={handlePayForm}
                  sx={(theme) => ({
                    width: '100%',
                    marginTop: theme.spacing(2),
                  })}
                  disabled={isLoading || isAELDisabled}
                  loading={isLoading}
                >
                  {status === 'created'
                    ? 'Pagar seguridad social'
                    : 'Reintentar pago'}
                </Button>
              ) : null}
              {status === 'pending' ? (
                <MButton
                  sx={(theme) => ({
                    width: '100%',
                    marginTop: theme.spacing(2),
                  })}
                  component={RouterLink}
                  to={routes.SETTINGS_SOCIAL_SECURITY_INDEX()}
                  state={{
                    provider,
                  }}
                >
                  Ver historial de planillas
                </MButton>
              ) : null}
              <MButton
                variant={status === 'paid' ? 'contained' : 'outlined'}
                sx={(theme) => ({
                  width: '100%',
                  marginTop: theme.spacing(2),
                })}
                onClick={() => navigate(-1)}
              >
                Regresar{status === 'created' && ' y pagar luego'}
              </MButton>
            </>
          ) : null}
          {status !== 'paid' ? (
            <Button
              variant="text"
              sx={(theme) => ({
                width: 'fit-content',
                marginTop: theme.spacing(2),
              })}
              onClick={() => setShowDeleteForm(true)}
              disabled={isLoading}
            >
              Eliminar planilla
            </Button>
          ) : null}
          {showDeleteForm ? (
            <DeleteForm
              open={showDeleteForm}
              provider={provider}
              status={status}
              onCancel={() => setShowDeleteForm(false)}
              onOk={handleDeleteForm}
              isLoading={isLoading}
            />
          ) : null}
        </Box>
      </Box>
    </Slide>
  )
}

export default SocialSecurityPaymentSummary
