import cloneDeep from 'lodash/cloneDeep'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import { formatWorker } from 'components/Worker/Form/helpers'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useWorkerService from 'utils/hooks/worker/workerService'
import { formatWorker as formatWorkerData, getWorkerId } from 'utils/worker'

import { WORKER_INDEX } from 'config/routes'

const useWorker = ({ applyFormat = false, useCache = false } = {}) => {
  const params = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { isWorker: isWorkerRole } = useUser()

  const workerId = isWorkerRole
    ? getWorkerId()
    : state?.workerId || params.workerId

  const queryKey = isWorkerRole
    ? ['getWorkerProfile', workerId]
    : ['getWorkerById', workerId]
  const queryClient = useQueryClient()
  const workerCache = workerId ? queryClient.getQueryData(queryKey) : null
  const { handleError } = useErrorHandler()
  const isFromAffiliationView = Boolean(state?.fromAffiliation)

  const { workerQuery } = useWorkerService({
    serviceParams: {
      queryKey,
      workerId,
    },
    queryOptions: {
      enabled: (!useCache || (useCache && !workerCache)) && Boolean(workerId),
      onError: (error) => {
        if (error.status === 404) {
          navigate(WORKER_INDEX('?tab=active'))
        } else {
          handleError(error)
        }
      },
    },
  })

  const refreshWorker = () => queryClient.refetchQueries(queryKey)

  if (useCache && workerCache) {
    return {
      worker: applyFormat
        ? formatWorker(cloneDeep(workerCache?.data || {}))
        : formatWorkerData(cloneDeep(workerCache?.data || {})),
      refreshWorker,
      isFromAffiliationView,
    }
  }

  return {
    worker: applyFormat
      ? formatWorker(cloneDeep(workerQuery.data || {}))
      : formatWorkerData(cloneDeep(workerQuery.data || {})),
    refreshWorker,
    isFromAffiliationView,
    ...workerQuery,
  }
}

export default useWorker
