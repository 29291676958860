import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getSSPaymentSummary } from 'services/payroll/fileService'
import {
  deleteSSForm,
  getSSForm,
  getSSFormErrors,
  paySSForm,
} from 'services/payroll/socialSecurityService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, periodId, formType, format } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getSSForm') return getSSForm(periodId, formType)

  if (currentQueryKey === 'getSSFormErrors')
    return getSSFormErrors(periodId, format, formType)

  if (currentQueryKey === 'getSSPaymentSummary')
    return getSSPaymentSummary(periodId, formType)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, periodId, formKey, formType, format } = mutationData

  if (mutationMethod === 'DELETE')
    return deleteSSForm(periodId, formKey, formType)

  if (mutationMethod === 'POST') return paySSForm(periodId, formKey)

  if (mutationMethod === 'GET')
    return getSSFormErrors(periodId, format, formType)

  return null
}

const useSocialSecurityService = ({
  serviceParams = { queryKey: 'getSSForm' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restSocialSecurityResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const socialSecurityMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    socialSecurityQuery: {
      data,
      ...restSocialSecurityResponse,
    },
    socialSecurityMutation,
  }
}

export default useSocialSecurityService
