import { usePopupState } from 'material-ui-popup-state/hooks'
import { useNavigate } from 'react-router-dom'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import DropdownButton from 'components/UI/Button/Dropdown'
import Icon from 'components/UI/Icon'

import { PERIOD_PREVIOUS_GENERATED_PAYROLL } from 'config/routes'

import { usePeriod, usePeriodAPI } from '../helpers'

const PayrollSummary = () => {
  const navigate = useNavigate()

  const {
    period: { id: periodId },
  } = usePeriod()
  const { openPayrollSummaryFileModal } = usePeriodAPI()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filter',
  })

  const handleClick = () =>
    navigate(PERIOD_PREVIOUS_GENERATED_PAYROLL(periodId))

  const endFreePayrollModal = useEndFreeModals()

  const options = [
    {
      id: 'downloadSummary',
      name: 'Descargar resumen',
      icon: <PremiumFeatureIcon fontSize="1rem" />,
      onClick: openPayrollSummaryFileModal,
    },
    {
      id: 'seeSummary',
      name: 'Ver resumen',
      onClick: endFreePayrollModal.isNotValidCompany
        ? () => endFreePayrollModal.openEndFreePayrollModal()
        : () => handleClick(),
    },
  ]
  return (
    <DropdownButton
      title="Resumen de nómina"
      options={options}
      variant="contained"
      popupState={popupState}
      endIcon={<Icon name="arrow-down" basic />}
      menuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
    />
  )
}

export default PayrollSummary
