import { useState } from 'react'

const useLaborModal = () => {
  const [state, setState] = useState({ open: false, worker: null })

  const closeLaborModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openLaborModal = (worker) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      worker,
    }))
  }

  return {
    laborModalState: state,
    closeLaborModal,
    openLaborModal,
  }
}

export default useLaborModal
