import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getCredentials,
  updateCredentials,
} from 'services/company/companyService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (servicesParams) => {
  const { queryKey } = servicesParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getCredentials') return getCredentials()

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, credentialsData } = mutationData

  if (mutationMethod === 'PUT') return updateCredentials(credentialsData)

  return null
}

const useCompanyCredentialsService = ({
  queryParams = { queryKey: 'getCredentials' },
  queryOptions = {},
  mutationOptions = {},
}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restCredentialsResponse } = useQuery(
    queryParams.queryKey,
    () => getQueryFunction(queryParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  const companyCredentialsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    companyCredentialsQuery: {
      data,
      ...restCredentialsResponse,
    },
    companyCredentialsMutation,
  }
}

export default useCompanyCredentialsService
