import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'

/* hide fields that came from landing page (values from query params), only show if has or had error */
const AuthSignupLandingCustomField = ({ queryParams, ...props }) => {
  const { errors } = useFormikContext()
  const [hadError, setHadError] = useState(false)

  const { name } = props

  useEffect(() => {
    if (errors[name]) setHadError(true)
  }, [errors[name]]) // eslint-disable-line react-hooks/exhaustive-deps

  return queryParams[name] == null || hadError ? <FormField {...props} /> : null
}

export default AuthSignupLandingCustomField
