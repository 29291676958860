import { useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getNotificationsToken } from 'services/notifications/notifications'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getNotificationsToken')
    return getNotificationsToken()
  return null
}

const useNotificationsTokenService = ({
  queryOptions = {},
  serviceParams = { queryKey: 'getNotificationsToken' },
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restNotificationsToken } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  return {
    notificationsTokenQuery: { data, ...restNotificationsToken },
  }
}

export default useNotificationsTokenService
