import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import {
  generateWageBaseSalaryValidationSchema,
  getMinimumSalary,
} from 'utils/worker'

yup.setLocale(yupLocaleES)

const wageBaseSalaryValidationSchema = generateWageBaseSalaryValidationSchema(
  'category'
)

const getValidationSchema = (documentType) => {
  const documentNoPension = [
    'ce_no_pension',
    'pe_no_pension',
    'pt_no_pension',
  ].includes(documentType)

  return yup.object({
    category: yup.string().trim().required(),
    term: yup.string().trim().nullable().required(),
    initial_day: yup.string().nullable().default(null).required(),
    end_day: yup
      .string()
      .nullable()
      .default(null)
      .when('term', {
        is: 'fixed',
        then: yup.string().nullable().default(null).required(),
        otherwise: yup.string().nullable().default(null).notRequired(),
      }),
    wage_category: yup.string().nullable().required(),
    base_salary: wageBaseSalaryValidationSchema,
    transport_subsidy: yup
      .boolean()
      .nullable()
      .when(['base_salary', 'category'], {
        is: (baseSalary, category) =>
          category !== 'contractor' &&
          Number(baseSalary) < getMinimumSalary({ multiplier: 2 }),
        then: yup.boolean().nullable().required(),
      }),
    contributor_subtype: yup
      .string()
      .nullable()
      .when(['contract_category', 'category'], {
        is: (contractCategory, category) =>
          (category || contractCategory) &&
          ['employee', 'part_time_contract'].includes(
            category || contractCategory
          ),
        then: yup.string().nullable().required(),
      }),
    work_center: yup
      .object()
      .nullable()
      .when('category', {
        is: (category) =>
          category &&
          category !== 'contractor' &&
          category !== 'schooling_trainee',
        then: yup
          .object()
          .test({
            name: 'testWorkCenter',
            test: (value) => {
              return value?.id && value?.name && value?.risk_type
            },
            message: 'Debes ingresar el centro de trabajo',
          })
          .nullable()
          .required(),
      }),
    high_risk_pension: yup
      .boolean()
      .nullable()
      .when(['work_center', 'contributor_subtype'], {
        is: (workCenter, contributorSubtype) =>
          Boolean(
            workCenter?.name?.includes('5') &&
              contributorSubtype === 'no_subtype'
          ),
        then: yup.boolean().nullable().required(),
      }),
    health_provider: yup
      .object()
      .nullable()
      .when('category', {
        is: (category) =>
          category &&
          !['contractor', 'student_decree_055', 'part_time_contract'].includes(
            category
          ),
        then: yup.object().nullable().required(),
      }),
    pension_provider: yup
      .object()
      .nullable()
      .when(['category', 'contributor_subtype'], {
        is: (category, contributorSubtype) =>
          documentNoPension || contributorSubtype !== 'no_subtype'
            ? false
            : category &&
              (category === 'employee' || category === 'student_law_789'),
        then: yup.object().nullable().required(),
      }),
    severance_provider: yup
      .object()
      .nullable()
      .when(['category', 'wage_category'], {
        is: (category, wageCategory) =>
          category &&
          wageCategory &&
          category === 'employee' &&
          wageCategory !== 'integral_salary',
        then: yup.object().nullable().required(),
      }),
  })
}

export default getValidationSchema
