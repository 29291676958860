import { useState } from 'react'

import { Box, Button, useMediaQuery, useTheme } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import CancelSubscriptionModal from 'components/Subscription/Atoms/CancelSubscription/CancelSubscriptionModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { isFreeCompany } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatCurrency } from 'utils/format'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { PLANS_DETAIL_HELP_CENTER, WHATSAPP_SALES } from 'config/organization'

import ModalitySwitch from '../../Atoms/ModalitySwitch'
import { getDetailCard } from '../helpers'
import SubscriptionCardsModalContent from './SubscriptionCardsModalContent'

const SubscriptionCardsModal = ({ isPlanBaseAliados }) => {
  const { subscription } = useSubscription()
  const [modality, setModality] = useState(subscription?.type || 'month')
  const modals = useModals()
  const themeInstance = useTheme()
  const companyId = getCompanyId()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const freeUserButtonText = xsDown
    ? 'Conversar sobre planes'
    : 'Conversar sobre los planes y suscripciones'

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
    },
  })

  if (plansQuery.isLoading) {
    return <LoadingBox />
  }

  const plansData = plansQuery?.data?.plans || []
  const plans = getDetailCard(plansData, themeInstance) || []

  const {
    active_until: activeUntil,
    status,
    payment_status: paymentStatus,
    partner: isPartner,
  } = subscription || {}

  const isAFreeCompany = isFreeCompany(status, paymentStatus)

  const isPlanAnnually = modality === 'year'
  const currentPlan = plans?.find((plan) => plan?.planData.current_plan)
  const currentPlanIndex = plans.indexOf(currentPlan)

  const yearlyDiscount =
    currentPlan?.planData?.subscription_value?.discounts?.yearly_percentage *
    100

  const onChange = (value) => {
    setModality(value)
  }

  const handleContinueCancellation = () => {
    modals.closeModal('subscriptionCardsModal')
    modals.openModal({
      id: 'cancelSubscriptionModal',
      content: <CancelSubscriptionModal activeUntil={activeUntil} />,
      modalProps: {
        hideFooter: true,
        header: '¿Cancelar suscripción?',
        adornment: [
          {
            variant: 'spring',
            color: 'accent2.main',
            width: 182.67,
            height: 80,
            sx: { bottom: '2.5rem', right: '1.5rem' },
          },
        ],
        paperSx: {
          maxWidth: '45.5rem',
        },
      },
    })
  }

  return (
    <Box
      sx={(theme) =>
        isPlanBaseAliados
          ? {
              margin: theme.spacing(9, 0),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          : {}
      }
    >
      {!isPartner ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 0, 2),
          })}
        >
          <ModalitySwitch
            onChange={onChange}
            modality={modality}
            discount={yearlyDiscount}
          />
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'grid',
          width: '100%',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2),
          ...(!isPlanBaseAliados && { marginTop: theme.spacing(9) }),
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(2),
            alignContent: 'center',
            rowGap: theme.spacing(8),
          },
          [theme.breakpoints.up('laptop')]: {
            gridTemplateColumns: !isPartner ? 'repeat(3, 1fr)' : '1fr',
            ...(isPlanBaseAliados && { maxWidth: '31.25rem' }),
          },
          [theme.breakpoints.between('sm', 'laptop')]: {
            ...(plans.length % 2 !== 0 && {
              '& div:last-child': { gridColumn: 'span 2' },
            }),
          },
        })}
      >
        {plans.map((plan, index) => {
          const { planData, cardsStyles, codedName } = plan
          const planId = planData.id

          const baseValue = isPlanAnnually
            ? planData.subscription_value.yearly.value
            : planData.subscription_value.monthly.value

          const workerValue = isPlanAnnually
            ? planData.subscription_value.yearly.extra_worker_value
            : planData.subscription_value.monthly.extra_worker_value

          return (
            <SubscriptionCardsModalContent
              key={planId}
              baseValue={formatCurrency(baseValue)}
              cardsStyles={cardsStyles}
              codedName={codedName}
              isAFreeCompany={isAFreeCompany}
              selectedModality={modality}
              planData={planData}
              workerValue={formatCurrency(workerValue)}
              selectedPlanIndex={index}
              currentPlanIndex={currentPlanIndex}
              currentPlanModality={subscription.type}
              plansData={plansData}
              isPlanBaseAliados={isPlanBaseAliados}
            />
          )
        })}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          columnGap: theme.spacing(6),
          margin: theme.spacing(7, 0, 0),
          [theme.breakpoints.down(isAFreeCompany ? 'tablet' : 'laptop')]: {
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: theme.spacing(3),
          },
        })}
      >
        {!isAFreeCompany && status !== 'premium_canceled' ? (
          <Button
            onClick={handleContinueCancellation}
            variant="outlined"
            endIcon={<Icon name="close" basic />}
          >
            Cancelar suscripción
          </Button>
        ) : null}

        {plans.length > 1 ? (
          <>
            <Button
              variant="text"
              endIcon={<Icon name="eye" basic />}
              target="_blank"
              href={PLANS_DETAIL_HELP_CENTER}
            >
              Ver detalle de los planes
            </Button>

            <Button
              variant={!isAFreeCompany ? 'text' : 'outlined'}
              href={WHATSAPP_SALES}
              target="_blank"
              endIcon={<Icon name="whatsapp" basic />}
            >
              {!isAFreeCompany
                ? 'Conversar sobre tu suscripción'
                : freeUserButtonText}
            </Button>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default SubscriptionCardsModal
