import useLocalStorage from '@rehooks/local-storage'
import { useFormikContext } from 'formik'
import { useRef } from 'react'

import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import LoadingBackdrop from 'components/UI/Loading/LoadingBackdrop'

import { isObjectEmpty, wait } from 'utils/general'
import useAutoScroll from 'utils/hooks/useAutoScroll'

import useOnboardingMutation from '../useOnboardingMutation'
import BadgeRole from './BadgeRole'
import { detailsRole, getValuesToSend } from './helpers'

const OnboardingRole = ({ handleNextStep }) => {
  const [, setRole] = useLocalStorage('role')
  const otherRoleRef = useRef(false)

  const [elementId, scrollToElement] = useAutoScroll({
    elementId: 'other-user-role',
  })

  const { values: initialValues, errors, setFieldValue } = useFormikContext()

  const { handleUpdateOnboarding, isLoading } = useOnboardingMutation()

  const handleSaveRoleStep = (value) => {
    if (value === 'other') {
      return
    }

    const valuesToSend = getValuesToSend(value)

    handleUpdateOnboarding({
      data: valuesToSend,
      callback: handleNextStep,
    })
  }

  // Event for send the role when selected other role and wait for the other information
  const handleSaveOtherRoleInformation = () => {
    const valuesToSend = getValuesToSend(
      initialValues.user_role,
      initialValues.user_role_other_info
    )

    handleUpdateOnboarding({
      data: valuesToSend,
      callback: handleNextStep,
    })
  }

  const handleClick = async (id) => {
    setFieldValue('user_role', id)
    handleSaveRoleStep(id)

    if (id === 'other') {
      otherRoleRef.current = true
      // Auto scroll to write the other role wait while render the component
      await wait(200)
      scrollToElement()
    } else {
      otherRoleRef.current = false
    }

    if (id === 'accountant' || id === 'outsourcer') setRole('accountant')
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '42rem',
        alignSelf: 'center',
        paddingBottom: theme.spacing(3),
      })}
    >
      <LoadingBackdrop open={isLoading} />
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          marginTop: theme.spacing(3),
          [theme.breakpoints.up('desktop')]: {
            marginTop: theme.spacing(6),
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        })}
      >
        {detailsRole.map(({ id, role, detail, iconName, dataCy }) => {
          const isSelectedRole = id === initialValues.user_role

          return (
            <BadgeRole
              key={id}
              id={id}
              role={role}
              detail={detail}
              isSelectedRole={isSelectedRole}
              handleSelectRole={handleClick}
              iconName={iconName}
              dataCy={dataCy}
            />
          )
        })}
      </Box>
      {otherRoleRef.current || initialValues.user_role === 'other' ? (
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            width: '100%',
            justifySelf: 'center',
          })}
          id={elementId}
        >
          <FormField
            name="user_role_other_info"
            label="¿Cuál es tu rol?"
            placeholder="Escribe aquí tu rol"
            data-cy="user_role_other_info"
          />
          <Button
            onClick={handleSaveOtherRoleInformation}
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
            disabled={!isObjectEmpty(errors)}
          >
            Continuar
          </Button>
        </Box>
      ) : null}
    </Box>
  )
}

export default OnboardingRole
