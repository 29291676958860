import http, { getApiURL } from '../httpService'

export const getAllUsers = ({ search, page, filter, status }) => {
  const url = getApiURL({
    pathname: '/users',
    searchParams: { search, page, filter, status },
  })

  return http.getV2(url)
}

export const getUserToken = () => {
  const url = getApiURL({
    pathname: '/users/token',
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getUnlockAccount = ({ unlock_token }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/users/unlock',
    searchParams: { unlock_token },
  })

  return http.getV2(url)
}

export const createUser = (data) => {
  const url = getApiURL({ pathname: '/users' })

  return http.postV2(url, data)
}

// to update name, email or password
export const updateProfile = (user) => {
  // different api url
  const url = getApiURL({ withoutVersion: true, pathname: '/users' })

  const data = { user }

  return http.patchV2(url, data)
}

// to update rol or active state
export const updateUserState = (user) => {
  const url = getApiURL({ pathname: `/users/${user.id}` })

  const data = { user }

  return http.patchV2(url, data)
}

export const updateUserToken = () => {
  const url = getApiURL({
    pathname: '/users/token',
    withoutCompany: true,
  })

  return http.patchV2(url)
}

export const restorePassword = (email) => {
  const url = getApiURL({ withoutVersion: true, pathname: '/users/password' })

  const data = { user: { email } }

  return http.postV2(url, data)
}

export const regeneratePassword = (user) => {
  const url = getApiURL({ withoutVersion: true, pathname: '/users/password' })

  const data = { user }

  return http.putV2(url, data)
}

export const resendUserEmail = (user) => {
  const url = getApiURL({ pathname: '/invitation_mail' })

  const data = { user }

  return http.postV2(url, data)
}

export const resendEmailUnlockAccount = ({ user }) => {
  const url = getApiURL({ withoutVersion: true, pathname: '/users/unlock' })

  const data = { user }

  return http.postV2(url, data)
}

export const deleteUser = (user) => {
  const url = getApiURL({ pathname: `/users/${user.id}` })

  return http.deleteV2(url)
}

export const sendWhatsappCode = (userId, userPhone) => {
  const url = getApiURL({
    pathname: '/users/confirmation_code',
    withoutCompany: true,
  })

  const userData = {
    user_id: userId,
    user_phone: userPhone,
  }

  return http.postV2(url, userData)
}

export default {
  restorePassword,
  regeneratePassword,
  deleteUser,
  createUser,
  getAllUsers,
  updateUserState,
  resendUserEmail,
  getUserToken,
  updateUserToken,
  updateProfile,
  sendWhatsappCode,
}
