import { Box, Button, Card, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

const EditContractModal = ({ state, handleClose, onEdit, onCreate }) => {
  return (
    <Modal
      header="Editar detalle del contrato"
      open={state.open}
      onCancel={handleClose}
      hideFooter
      paperSx={{
        maxWidth: '49rem',
        width: '100%',
      }}
      adornment="pattern-bottom"
      patternBackgroundColor="accent3.main"
      patternColor="black"
    >
      <Typography color="black.dark">
        Acá puedes editar el detalle del contrato, recuerda tener presente que
        un cambio en el detalle del contrato actual afectará las nóminas que
        estén en su rango de fechas, incluso las que ya están cerradas.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: theme.spacing(3),
          marginTop: theme.spacing(5),
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        })}
      >
        <Card
          sx={(theme) => ({
            minHeight: '14rem',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(3),
            justifyContent: 'space-between',
          })}
        >
          <Box>
            <Typography variant="h5" gutterBottom>
              Editar los datos del detalle actual
            </Typography>
            <Typography>
              En caso de que desees modificar la información actual del detalle
              de contrato de la persona modificando toda la información
              histórica. Recomendado si el subtipo de cotizante o el nivel de
              riesgo está mal.
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.spacing(6),
            })}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleClose()
                onEdit()
              }}
            >
              Editar
            </Button>
          </Box>
        </Card>
        <Card
          sx={(theme) => ({
            minHeight: '14rem',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(3),
            justifyContent: 'space-between',
          })}
        >
          <Box>
            <Typography variant="h5" gutterBottom>
              Crear un nuevo detalle de contrato
            </Typography>
            <Typography>
              En caso de que desees crear un detalle de contrato para la
              persona, el cual comenzará a aplicar desde la fecha inicial que
              elijas. Recomendado para cuando hay una novedad en el subtipo de
              cotizante o un cambio en el nivel de riesgo.
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.spacing(6),
            })}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleClose()
                onCreate()
              }}
            >
              Crear
            </Button>
          </Box>
        </Card>
      </Box>
    </Modal>
  )
}

export default EditContractModal
