import FormField from 'components/UI/Formik/FormField/Index'

import useInstitutionsService from 'utils/hooks/institutionsService'

import messages from 'messages/contract'

const PensionProviderField = ({
  contractCategory,
  contributorSubtype,
  documentType,
  optional,
  ...restProps
}) => {
  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey: ['getInstitutionsByCategory', 'pension_provider'],
      category: 'pension_provider',
    },
  })

  const showPensionProviderField =
    !['ce_no_pension', 'pe_no_pension', 'pt_no_pension'].includes(
      documentType
    ) &&
    (contractCategory === 'student_law_789' ||
      (contributorSubtype === 'no_subtype' &&
        ['employee', 'part_time_contract'].includes(contractCategory)))

  return showPensionProviderField ? (
    <FormField
      name="pension_provider"
      label="Fondo de pensiones"
      variant="autocomplete"
      placeholder="Seleccione un fondo de pensiones..."
      options={institutionsQuery?.data || []}
      tooltipContent={messages.pension_provider}
      optional={optional}
      {...restProps}
    />
  ) : null
}

export default PensionProviderField
