import { Box, Button } from '@mui/material'

const CalendarActions = ({ onCloseCalendar }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1.5),
        marginTop: theme.spacing(3),
      })}
    >
      <Button size="small" variant="outlined" onClick={onCloseCalendar}>
        Cancelar
      </Button>
      <Button size="small" onClick={onCloseCalendar}>
        Aceptar
      </Button>
    </Box>
  )
}

export default CalendarActions
