import { Form, Formik } from 'formik'
import { useRef } from 'react'
import * as yup from 'yup'

import {
  Box,
  Chip,
  FormHelperText,
  Button as MButton,
  Typography,
} from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import { formatDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import usePeriodService from 'utils/hooks/organizer/periodService'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { OrganizerButtonComponent } from '../ComponentGridForm'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  email: yup.string(),
  date: yup.string(),
  emails: yup
    .array()
    .of(yup.string().email('Por favor ingresa correos válidos')),
})

const OrganizerFilesAccountants = () => {
  const { handleError } = useErrorHandler()
  const downloadURI = useDownloadURI()
  const { periodMutation } = usePeriodService()
  const formRef = useRef()

  const handleSubmit = async (values, form) => {
    if (values.emails.length) {
      periodMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'generateAccountantFile',
          accountantFileData: {
            emails: values.emails,
            date: values.date,
          },
        },
        {
          onSuccess: ({ file }) => {
            downloadURI(file)
          },
          onError: (error) => handleError(error, form),
        }
      )
    }
  }

  const handleAddEmail = () => {
    const nextEmails = [...formRef.current.values.emails]

    formRef.current?.setFieldValue('emails', [
      ...nextEmails,
      formRef.current?.values.email,
    ])
    formRef.current?.setFieldValue('email', '')
  }

  const handleRemoveEmail = (emailIndex) => {
    const newEmails = formRef.current?.values.emails.filter(
      (_, index) => index !== emailIndex
    )

    formRef.current?.setFieldValue('emails', newEmails)
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddEmail()
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        email: '',
        emails: [],
        date: formatDate(new Date()),
      }}
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, errors }) => {
        return (
          <Form>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: theme.spacing(6),
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: '43.75rem',
                })}
              >
                <Typography variant="h3" gutterBottom align="left">
                  Generar archivo de contadores
                </Typography>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    alignItems: 'flex-end',
                    gap: theme.spacing(2),
                    gridTemplateColumns: '1fr',
                    marginBottom: theme.spacing(3),
                    [theme.breakpoints.up('sm')]: {
                      gridTemplateColumns: '1fr 13rem',
                    },
                  })}
                >
                  <FormField
                    name="email"
                    margin="none"
                    label="Correo del contador"
                    onKeyPress={onKeyPress}
                  />
                  <MButton variant="outlined" onClick={handleAddEmail}>
                    Agregar correo
                  </MButton>
                </Box>
                {values.emails.length > 0 ? (
                  <Box
                    sx={(theme) => ({
                      marginBottom: theme.spacing(3),
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        gap: theme.spacing(1),
                        flexWrap: 'wrap',
                      })}
                    >
                      {values.emails?.map((value, index) => (
                        <Chip
                          key={value}
                          label={value}
                          color="primary"
                          onDelete={() => handleRemoveEmail(index)}
                        />
                      ))}
                    </Box>
                    {errors.emails && errors.emails.length > 0 ? (
                      <FormHelperText
                        error
                        sx={(theme) => ({
                          marginTop: theme.spacing(1),
                        })}
                      >
                        Por favor ingresa correos válidos
                      </FormHelperText>
                    ) : null}
                  </Box>
                ) : null}
                <FormField
                  name="date"
                  label="Fecha del periodo a crear"
                  variant="datepicker"
                />
                <OrganizerButtonComponent>
                  <Button type="submit" loading={periodMutation.isLoading}>
                    Generar archivo
                  </Button>
                </OrganizerButtonComponent>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OrganizerFilesAccountants
