import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Emoji from 'components/UI/Emoji'

import { sparkles } from 'utils/emojis'

import sitYellowWoman from 'assets/images/views/common/sit_yellow_woman.png'

const UpdateConfirmationModal = () => {
  const modals = useModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box sx={{ display: 'flex' }}>
      {!isMobile ? (
        <Box
          sx={(theme) => ({
            margin: theme.spacing(-3, 0, -5, -3),
            minWidth: '20rem',
            minHeight: '20rem',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('tablet')]: {
              margin: theme.spacing(-3, 0, -5, -3),
              paddingLeft: theme.spacing(2),
              position: 'relative',
              overflow: 'hidden',
            },
            [theme.breakpoints.up('laptop')]: {
              margin: theme.spacing(-4, 0, -5, -4),
            },
          })}
        >
          <Box
            sx={{
              width: '28rem',
              height: '28rem',
              borderRadius: '100%',
              backgroundColor: 'accent2.light',
              position: 'absolute',
              right: 0,
              top: '-1.21rem',
            }}
          />
          <Box
            component="img"
            src={sitYellowWoman}
            sx={{ width: '14.37rem', height: '13.35rem', zIndex: '10000' }}
          />
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          marginLeft: theme.spacing(2.5),
        })}
      >
        <Typography variant="h4">¡Ganaste la maratón!</Typography>
        <Typography
          variant="body1"
          color="black.dark"
          sx={(theme) => ({ marginTop: theme.spacing(3) })}
        >
          Has actualizado correctamente tu información. ¡Ahora podrás disfrutar
          de todas las maravillas y noticias que tenemos para ti!{' '}
          <Emoji code={sparkles} />
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            [theme.breakpoints.down('tablet')]: {
              justifyContent: 'center',
            },
          })}
        >
          <Button
            sx={(theme) => ({ width: '12rem', marginTop: theme.spacing(7) })}
            onClick={() => modals.closeAll()}
          >
            Cerrar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default UpdateConfirmationModal
