import { useCallback } from 'react'

import { PAYROLL_ACTION_TYPES } from './usePayrollConfiguration'

const useWorkedDaysModal = ({ payrollState, payrollDispatch }) => {
  const openWorkedDaysModal = useCallback(
    (noveltyConcept) => {
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SHOW_MODAL,
        modalKeys: {
          noveltyTypeModal: false,
          workedDaysModal: true,
        },
      })
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SET_MODAL_CONFIGURATION,
        config: {
          noveltyConcept,
        },
      })
    },
    [payrollDispatch]
  )

  const closeWorkedDaysModal = () =>
    payrollDispatch({
      type: PAYROLL_ACTION_TYPES.CLOSE_MODAL,
      modalKeys: {
        workedDaysModal: false,
        noveltyTypeModal: true,
      },
    })

  return {
    workedDaysModalState: {
      open: Boolean(payrollState.workedDaysModal),
    },
    openWorkedDaysModal,
    closeWorkedDaysModal,
  }
}

export default useWorkedDaysModal
