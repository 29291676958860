import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'

import { Box, Typography, useMediaQuery } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getUserId } from 'utils/auth'
import { eyes } from 'utils/emojis'
import useUsersService from 'utils/hooks/settings/usersService'
import useNotifications from 'utils/hooks/useNotifications'

import peopleRunning from 'assets/images/views/dashboard/people_running.png'

import messages from 'messages/notification'

import UpdateConfirmationModal from './UpdateConfirmationModal'
import { initialValues, validationSchema } from './helpers'

const UpdateCompanyPhoneModal = ({ state, handleClose }) => {
  const modals = useModals()
  const { showSuccessMessage } = useNotifications()
  const [currentStep, setCurrentStep] = useState(0)
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(59)

  const { usersMutation } = useUsersService({
    queryOptions: { enabled: false },
  })

  const userId = getUserId()

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleReinitiateCountdown = () => {
    setMinutes(1)
    setSeconds(59)
  }

  const handleSendWhatsappCode = (values) => {
    usersMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'whatsappCode',
        userId,
        userPhone: values.phone,
      },
      {
        onSuccess: () => {
          showSuccessMessage('El código se ha enviado correctamente')
          handleReinitiateCountdown()
          if (currentStep === 0) {
            setCurrentStep((previousStep) => previousStep + 1)
          }
        },
      }
    )
  }

  const onSubmit = (values) => {
    if (currentStep === 0) {
      handleSendWhatsappCode(values)
    }

    if (currentStep === 1) {
      usersMutation.mutate(
        {
          mutationMethod: 'PATCH',
          mutationKey: 'updateUserInfo',
          user: { id: userId, ...values },
        },
        {
          onSuccess: () => {
            showSuccessMessage(messages.SETTINGS_USER_PROFILE_SUCCESS)
            handleClose()
            modals.openModal({
              id: 'updateCompanyPhoneModal',
              content: <UpdateConfirmationModal />,
              modalProps: {
                hideFooter: true,
                paperSx: {
                  maxWidth: '49rem',
                  minHeight: '20rem',
                },
              },
            })
          },
        }
      )
    }
  }

  useEffect(() => {
    if (currentStep === 1) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval)
          } else {
            setSeconds(59)
            setMinutes(minutes - 1)
          }
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [seconds, currentStep, minutes])

  return (
    <Modal
      open={state.open}
      hideFooter
      adornment="pattern-bottom"
      patternBackgroundColor="complementary2.main"
      paperSx={{
        maxWidth: '50rem',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { mobile: '1fr', tablet: '1fr 1fr' },
        }}
      >
        {!isMobile ? (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.white.main,
              margin: theme.spacing(-4, 0, -5, -3),
              backgroundImage: `url(${peopleRunning})`,
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              [theme.breakpoints.up('laptop')]: {
                marginLeft: theme.spacing(-4),
              },
            })}
          />
        ) : null}
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('tablet')]: {
              paddingLeft: theme.spacing(3),
            },
          })}
        >
          <Typography
            variant="h4"
            sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
          >
            Actualiza tus datos para ingresar a tu cuenta <Emoji code={eyes} />
          </Typography>

          <Formik
            validationSchema={validationSchema[currentStep]}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              return (
                <Form>
                  {currentStep === 0 ? (
                    <>
                      <Typography variant="body1" color="black.dark">
                        Actualiza tu número celular para recibir alertas,
                        novedades y reportes de tu nómina, y de paso,{' '}
                        <b>que puedas ganar la maratón!</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="black.dark"
                        sx={(theme) => ({ margin: theme.spacing(3, 0) })}
                      >
                        <b>Te enviaremos un código a tu Whatsapp</b>
                      </Typography>
                    </>
                  ) : null}

                  {currentStep === 1 ? (
                    <>
                      <Typography
                        variant="body1"
                        color="black.dark"
                        sx={(theme) => ({ margin: theme.spacing(2, 0) })}
                      >
                        Acabamos de enviarte un mensaje de{' '}
                        <b>Whatsapp al +57 {values?.phone}</b>.
                      </Typography>
                      <Typography variant="body1" color="black.dark">
                        Da clic en Regresar en caso de que desees corregir tu
                        número celular.
                      </Typography>
                    </>
                  ) : null}
                  <Box
                    sx={(theme) => ({
                      marginTop: theme.spacing(3),
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(6),
                    })}
                  >
                    {currentStep === 0 ? (
                      <FormField
                        name="phone"
                        variant="number"
                        label="Tu número de WhatsApp"
                        isNumericString
                        format="### ### ####"
                        optional={false}
                      />
                    ) : (
                      <Box
                        sx={(theme) => ({
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          rowGap: theme.spacing(1),
                        })}
                      >
                        <FormField
                          name="whatsapp_code"
                          variant="number"
                          label="Ingresa tu código"
                          isNumericString
                          format="# # # #"
                          optional={false}
                          sx={{
                            height: '3rem',
                            fontSize: '2rem',
                            '& .MuiOutlinedInput-input': {
                              textAlign: 'center',
                            },
                          }}
                        />

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="body2" color="black.light">
                            {`0${minutes}:${
                              seconds < 10 ? `0${seconds}` : seconds
                            }`}
                          </Typography>
                          <Button
                            onClick={() => {
                              handleSendWhatsappCode(values)
                              setFieldValue('whatsapp_code', '')
                              handleReinitiateCountdown()
                            }}
                            variant="text"
                            disabled={seconds > 0 || minutes > 0}
                            sx={{ color: 'accent4.main' }}
                            fontWeight={600}
                          >
                            Reenviar código
                          </Button>
                        </Box>
                      </Box>
                    )}

                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        columnGap: theme.spacing(2),
                        justifyContent: !isMobile ? 'flex-start' : 'center',
                      })}
                    >
                      <Button
                        onClick={handleSubmit}
                        loading={usersMutation.isLoading}
                        disabled={
                          currentStep === 0
                            ? false
                            : values?.whatsapp_code.length !== 4
                        }
                        sx={{ width: currentStep === 0 ? '10rem' : '8rem' }}
                      >
                        {currentStep !== 1 ? 'Enviar código' : 'Enviar'}
                      </Button>

                      {currentStep === 1 ? (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setFieldValue('whatsapp_code', '')
                            setCurrentStep((previousStep) => previousStep - 1)
                          }}
                          sx={{
                            width: '8rem',
                          }}
                        >
                          Regresar
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </Box>
    </Modal>
  )
}

export default UpdateCompanyPhoneModal
