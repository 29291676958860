import { useState } from 'react'

const useWageUpdateConfirmModal = () => {
  const [state, setState] = useState({
    open: false,
    newWageData: {},
  })

  const openWageUpdateConfirmModal = (newWageData) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      newWageData,
    }))
  }

  const closeWageUpdateConfirmModal = () => {
    setState({ open: false })
  }

  return {
    wageUpdateConfirmModalState: state,
    openWageUpdateConfirmModal,
    closeWageUpdateConfirmModal,
  }
}

export default useWageUpdateConfirmModal
