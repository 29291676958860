import http, { getApiURL } from '../httpService'

export const getAllPartners = ({ page, search } = {}) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/partners/informations',
    searchParams: { search, page },
  })

  return http.getV2(url)
}

export const updateInformartions = (partner) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/partners/informations',
  })

  return http.putV2(url, { partner })
}

export default {
  getAllPartners,
  updateInformartions,
}
