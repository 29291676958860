import http, { getApiURL } from '../httpService'

export const getAllCostCenter = (querySearch) => {
  const url = getApiURL({
    pathname: '/cost_centers',
    searchParams: querySearch,
  })

  return http.getV2(url)
}

export const createCostCenter = (costCenter) => {
  const url = getApiURL({ pathname: '/cost_centers' })

  const data = {
    cost_center: costCenter,
  }

  return http.postV2(url, data)
}

export const updateCostCenter = (costCenter) => {
  const url = getApiURL({ pathname: `/cost_centers/${costCenter.id}` })

  const data = {
    cost_center: costCenter,
  }

  return http.patchV2(url, data)
}

export const deleteCostCenter = (costCenterId) => {
  const url = getApiURL({ pathname: `/cost_centers/${costCenterId}` })

  return http.deleteV2(url)
}

export default {
  getAllCostCenter,
  createCostCenter,
  updateCostCenter,
  deleteCostCenter,
}
