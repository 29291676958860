import { capitalize } from 'utils/general'

const filesNames = {
  socialSecurity: 'Social Security',
  payrollSummary: 'Payroll Summary',
  noveltiesReport: 'Novelties Report',
  individualPayslip: 'Individual Payslips',
  payslipsPdf: 'Payslips PDF',
  payslipsZip: 'Payslips ZIP',
  accounting: 'Accounting',
  bank: 'Bank',
}

const recurrentConcept = {
  salary_income: 'Salary Income',
  non_salary_income: 'Non Salary Income',
  deductions: 'Deductions',
  loans: 'Loans',
}

export const integrationEventsPayload = (args) => {
  const [event, eventData, extraInfo] = args

  switch (event) {
    // Events Only For Appcues
    // case 'Anual Payment Report':
    case 'Company Area Add':
    case 'Company Position Add':
    case 'Company Location Add':
    case 'Employee Income Certificate':
    case 'Ael Load Form':
    case 'Ael Paid Form':
    case 'First Payment':
    case 'Free Trial Ended':
      return { appcues: { event } }

    case 'Recurrent Add':
      return {
        appcues: { event: `Recurrent ${recurrentConcept[eventData]} Add` },
      }

    // Events tracked with appcues by event name
    case 'Payroll Generate':
    case 'Payroll Bonus Add':
    case 'Payroll Overtime Add':
    case 'Payroll Novelties Add':
    case 'Payroll Loan Other Deduction Add':
    case 'Pay Payroll Old Period Edit':
    case 'Payslips Send Email':
    case 'Novelties File Upload':
    case 'Employee Termination':
    case 'Employee Labor Certificate':
      return { appcues: { event } }

    // Download Files
    //
    // Social Security
    // Payroll Summary
    // Novelties Report
    // Individual Payslips
    // Bank
    // Accounting
    // Paylips (PDF/ZIP)

    // Todo
    // Employee information cual es?
    case 'Download File':
      return {
        appcues: { event: `Download ${filesNames[eventData.name]} File` },
      }

    // Create Employee
    case 'Employee Register':
      return {
        appcues: { event: `${event} ${capitalize(extraInfo)}` },
      }

    // Company Events
    case 'Company Update':
      return {
        appcues: { event },
      }

    // Settings Events
    case 'Edit Settings':
      return {
        appcues: { event: `${event} ${eventData}` },
      }

    // Collaborator Events
    case 'Invite Collaborator':
      return {
        appcues: {
          event: `Invite ${capitalize(eventData.role)} Collaborator`,
        },
      }

    case 'Subscription Buy':
      return {
        appcues: { event },
      }

    case 'Integration Done':
      return {
        appcues: {
          event: `${event} ${eventData}`,
        },
      }

    // Create UTM in Marketing dont remove
    case 'Sign Up':
      return { trackDataLayer: { event } }
    // On Boarding
    case 'Test PayPayroll':
    case 'First Employee Create':
    case 'First PayPayroll':
      return { appcues: { event } }

    default:
      return {}
  }
}

export default integrationEventsPayload
