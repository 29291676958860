import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import { formatContracts } from 'components/Worker/Contract/Index/Index'
import useWorker from 'components/Worker/useWorker'

import { isObjectEmpty } from 'utils/general'
import useWageOrganizerService from 'utils/hooks/organizer/wageService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWageService from 'utils/hooks/worker/wageService'

import * as routes from 'config/routes'

import WageModal from '../Modal/WageModal'
import { columnsData, getActions } from './helpers'

const WageIndex = () => {
  const location = useLocation()
  const { worker } = useWorker()
  const [wages, setWages] = useState([])
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()

  const [wageToEdit, setWageToEdit] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { handleError } = useErrorHandler()
  const { workerId, contractId } = useParams()
  const wagesQueryKey = ['allWages', workerId, contractId]

  const currentContractData = {
    id: worker.contract_id,
    category: worker.contract_category,
    initial_day: worker.initial_day,
    end_day: worker.end_day,
    period_end_day: worker.period_end_day,
    term: worker.term,
    terminated: worker.terminated,
    termination: worker.termination,
    base_salary: worker.base_salary,
    wage_id: worker.wage_id,
    wage_category: worker.wage_category,
    transport_subsidy: worker.transport_subsidy,
    work_center: worker.work_center,
    risk_type: worker.risk_type,
    high_risk_pension: worker.high_risk_pension,
    health_provider: worker.health_provider,
    pension_provider: worker.pension_provider,
    severance_provider: worker.severance_provider,
  }
  const [formattedContract] = formatContracts([currentContractData])
  const contractData = location.state?.contract || formattedContract

  const { wageQuery, wageMutation } = useWageService({
    serviceParams: {
      queryKey: wagesQueryKey,
      contractId,
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        const wagesFormatted = [...data]
        for (let i = 0; i < wagesFormatted.length; i += 1) {
          const wage = wagesFormatted[i]

          wage.number = i + 1

          if (i === wagesFormatted.length - 1) wage.first = true

          if (wagesFormatted[i + 1]) {
            wage.prevInitialDay = wagesFormatted[i + 1].initial_day
            wage.prevEndDay = wagesFormatted[i + 1].end_day
          }
        }

        setWages(wagesFormatted)
      },
    },
  })

  const { wageOrganizerMutation } = useWageOrganizerService()

  const refreshWages = () => queryClient.invalidateQueries(wagesQueryKey)

  const showWageModal = (wage) => {
    setWageToEdit(wage)
    setIsModalVisible(true)
  }

  const hideWageModal = () => {
    setWageToEdit({})
    setIsModalVisible(false)
  }

  const handleEditWage = (wage) => {
    confirm({
      title: '¿Estás seguro de editar el salario?',
      description:
        'Este cambio afectará a todas las nóminas que incluyan este salario.',
      type: 'warning',
      onOk: () => showWageModal(wage),
    })
  }

  const handleDeleteWage = (wage) => {
    const deleteWage = () => {
      wageMutation.mutate(
        {
          mutationMethod: 'DELETE',
          wageId: wage.id,
        },
        {
          onSuccess: () => {
            showSuccessMessage('Salario eliminado correctamente')
            refreshWages()
          },
        },
        {
          onError: handleError,
        }
      )
    }
    confirm({
      title: '¿Estás seguro de eliminar el salario?',
      description:
        'Este cambio afectará a todas las nóminas que incluyan este salario.',
      type: 'warning',
      onOk: () => deleteWage(wage),
    })
  }

  const organizerDeleteWage = (wage) => {
    wageOrganizerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        wageId: wage,
      },
      {
        onSuccess: () => {
          showSuccessMessage('Salario eliminado correctamente')
          refreshWages()
        },
      },
      {
        onError: handleError,
      }
    )
  }

  const actions = getActions({
    organizerDeleteWage,
    confirm,
    showWageModal,
    handleEditWage,
    handleDeleteWage,
  })

  return (
    <Page
      documentTitle={`Salarios de ${worker ? worker.fullName : 'trabajador'}`}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Salarios de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
      isLoading={wageQuery.isLoading}
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Typography
          sx={{
            textAlign: 'justify',
          }}
        >
          Este es el historial de salarios. Aquí puedes ver todos los salarios
          que una persona ha tenido desde que fue creada en Aleluya o crear un
          nuevo salario para el contrato actual.{' '}
          <b>
            Ten en cuenta que si registras una variación del salario, Aleluya
            generará inmediatamente la novedad de VSP (Variación del Salario
            Permanente).
          </b>
        </Typography>
      </Box>
      <br />
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Table
          data={wages}
          columns={columnsData}
          options={{
            pagination: false,
            search: false,
            alignActionsCell: 'center',
            version: {
              toolbar: 'v2',
            },
          }}
          actions={worker?.terminated === false ? actions : undefined}
        />
      </Box>
      {isModalVisible && contractData ? (
        <WageModal
          contract={contractData}
          wage={wageToEdit}
          isEdit={!isObjectEmpty(wageToEdit)}
          onCancel={hideWageModal}
          submitCallback={refreshWages}
        />
      ) : null}
    </Page>
  )
}

export default WageIndex
