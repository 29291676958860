import { Box, Button, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'

import MobileCell from '../common/CardGrid/MobileCell'

const AddConceptWrapper = ({
  children,
  showButton = false,
  handleAddConcept,
  disableAddConceptButton = false,
  handleCreateConcept,
  gutterBottom = false,
}) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('tablet')]: {
          rowGap: theme.spacing(1.5),
        },
        ...(gutterBottom && {
          marginBottom: theme.spacing(2),
        }),
      })}
    >
      {children}
      {showButton ? (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 0.6fr 2.5rem',
            width: '100%',
            [theme.breakpoints.down('tablet')]: {
              marginTop: theme.spacing(1.5),
            },
          })}
        >
          <MobileCell
            sx={{
              gridColumn: '2 / -1',
            }}
            alignX={isTabletUp ? 'left' : 'right'}
          >
            <Box
              sx={(theme) => ({
                paddingLeft: theme.spacing(1),
                display: 'flex',
                columnGap: theme.spacing(6),
                [theme.breakpoints.down('tablet')]: {
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  rowGap: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                },
              })}
            >
              <Button
                variant="text"
                onClick={handleAddConcept}
                disabled={disableAddConceptButton}
                startIcon={<Icon name="plus" basic />}
                sx={{
                  height: 'auto',
                }}
              >
                Agregar concepto
              </Button>

              <Button
                variant="text"
                onClick={handleCreateConcept}
                startIcon={<Icon name="plus" basic />}
                sx={{
                  height: 'auto',
                }}
              >
                Crear nuevo concepto
              </Button>
            </Box>
          </MobileCell>
        </Box>
      ) : null}
    </Box>
  )
}

export default AddConceptWrapper
