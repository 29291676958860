import { useCallback, useEffect, useState } from 'react'

import useErrorHandler from 'utils/hooks/useErrorHandler'

import fileService from 'services/payroll/fileService'

const initialState = {
  open: false,
  providerOptions: [],
  periodOptions: [],
  fileOptions: [],
}

/**
 * Used in conjunction with AccountingFileModal
 * @param {Object} period
 */
const useAccountingFileModal = (period) => {
  const [state, setState] = useState({ ...initialState, period })
  const { handleError } = useErrorHandler()

  useEffect(() => {
    setState({ ...initialState, period })
  }, [period])

  const openModal = useCallback(async () => {
    try {
      const {
        provider_options: providerOptions,
        period_options: periodOptions,
        file_options: fileOptions,
      } = await fileService.getAccountingFileOptions(period.id)

      const newState = {
        providerOptions,
        periodOptions,
        fileOptions,
        open: true,
      }

      setState((previousState) => ({
        ...previousState,
        ...newState,
      }))
    } catch (error) {
      handleError(error)
    }
  }, [handleError, period.id])

  const closeModal = () => {
    setState({ ...initialState, period })
  }

  return {
    accountingFileModalState: state,
    openAccountingFileModal: openModal,
    closeAccountingFileModal: closeModal,
  }
}

export default useAccountingFileModal
