import { Box } from '@mui/material'

export const OrganizerGridComponent = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          width: '50%',
          padding: theme.spacing(4),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        })}
      >
        {children}
      </Box>
    </Box>
  )
}

export const OrganizerButtonComponent = ({ children }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'flex-end',
      })}
    >
      {children}
    </Box>
  )
}
