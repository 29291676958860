export default {
  COMPANY_UPDATE_SUCCESS: 'La empresa fue actualizada.',
  INTEGRATION_ACTIVATION: 'La integración ha sido creada.',
  INTEGRATION_INACTIVATION: 'Se ha eliminado la integración.',
  LOGIN_OK: '¡Bienvenido a Aleluya!',
  SETTINGS_USER_PROFILE_SUCCESS: 'El usuario fue actualizado.',
  WORKER_ACTIVATION_SUCCESS: 'La persona fue actualizada.',
  INVITE_COLLABORATOR: 'La persona fue invitada correctamente.',
  CHANGES_DONE_SUCCESS: 'Los cambios se guardaron correctamente.',
}
