import {
  Box,
  Button,
  Chip,
  FormControl,
  Typography,
  alpha,
} from '@mui/material'

import Adornment from 'components/UI/Adornment'

import { isArusDist } from 'utils/auth'

const ModalitySwitch = ({ onChange, modality, discount }) => {
  const isYearly = modality === 'year'
  const isArusDistributor = isArusDist()

  return (
    <FormControl
      fullWidth
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: '14.57rem',
      }}
    >
      <Box
        sx={(theme) => ({
          maxWidth: '14.57rem',
          width: '100%',
          height: '3.75rem',
          backgroundColor: 'accent1.main',
          borderRadius: '1.875rem',
          boxShadow: theme.shadows[3],
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            userSelect: 'none',
            width: '100%',
            padding: 0,
            margin: 0,
          }}
        >
          <Button
            variant="large"
            onClick={() => onChange('month')}
            sx={{
              backgroundColor: !isYearly ? 'primary.main' : 'accent1.main',
              ...(isArusDistributor && {
                color: isYearly ? 'black.main' : 'white.main',
              }),
            }}
          >
            Mensual
          </Button>
          <Button
            onClick={() => onChange('year')}
            sx={{
              backgroundColor: isYearly ? 'primary.main' : 'accent1.main',
              ...(isArusDistributor && {
                color: !isYearly ? 'black.main' : 'white.main',
              }),
            }}
          >
            Anual
          </Button>
        </Box>
        {discount ? (
          <>
            <Chip
              label={
                <Typography variant="small" fontWeight={700}>
                  {`${discount}% DCTO`}
                </Typography>
              }
              size="small"
              sx={(theme) => ({
                position: 'absolute',
                right: '-1%',
                top: '-80%',
                backgroundColor: alpha(theme.palette.accent4.dark, 0.2),
                boxShadow: theme.shadows[2],
                '& .MuiChip-label': {
                  weight: 600,
                  color: theme.palette.accent4.dark,
                },
                [theme.breakpoints.up('sm')]: {
                  right: '-33%',
                  top: '-45%',
                },
                [theme.breakpoints.up('laptop')]: {
                  right: '-40%',
                  top: '-45%',
                },
              })}
            />
            <Adornment
              variant="row"
              width="2.269rem"
              height="2.036rem"
              sx={(theme) => ({
                top: '-40%',
                right: '5%',
                [theme.breakpoints.up('sm')]: {
                  top: '4%',
                  right: '-19%',
                },
              })}
            />
          </>
        ) : null}
      </Box>
    </FormControl>
  )
}

export default ModalitySwitch
