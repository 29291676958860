import { useCallback, useState } from 'react'

const useSinglePayslipModal = () => {
  const [state, setState] = useState({
    open: false,
    payroll: {},
  })

  const openSinglePayslipModal = useCallback(({ payroll }) => {
    setState({ open: true, payroll })
  }, [])

  const closeSinglePayslipModal = () => {
    setState({ open: false })
  }

  return {
    singlePayslipModalState: state,
    openSinglePayslipModal,
    closeSinglePayslipModal,
  }
}

export default useSinglePayslipModal
