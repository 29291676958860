import { useState } from 'react'

import { Box, Typography } from '@mui/material'

import PaymentsTable from 'components/Subscription/Atoms/Payments/DataTable/Table'
import Page from 'components/UI/Page/Page'

const Payments = () => {
  const [isLoadingWithModal, setIsLoadingWithModal] = useState(false)

  return (
    <Page
      documentTitle="Pagos"
      isLoadingWithModal={isLoadingWithModal}
      header={
        <>
          <Box
            sx={(theme) => ({
              display: 'flex',
              marginBottom: theme.spacing(1.5),
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              [theme.breakpoints.down('tablet')]: {
                flexDirection: 'column',
                gap: theme.spacing(2),
              },
            })}
          >
            <Typography variant="h2">Historial de pagos</Typography>
          </Box>
        </>
      }
    >
      <PaymentsTable setIsLoading={setIsLoadingWithModal} />
    </Page>
  )
}

export default Payments
