import { alpha } from '@mui/material'

const getChipStatusStyles = (themeInstance) => {
  const status = {
    Firmado: 'Firmado',
    Pendiente: 'Pendiente',
  }

  const chipBackgroundColor = {
    Firmado: alpha(themeInstance.palette.primary.light, 0.2),
    Pendiente: alpha(themeInstance.palette.complementary2.light, 0.2),
  }

  const color = {
    Firmado: 'primary.dark',
    Pendiente: 'complementary2.dark',
  }

  const animatedIcon = {
    Firmado: 'approved-document',
    Pendiente: 'document-error',
  }

  const animatedIconBackgroundColor = {
    Firmado: themeInstance.palette.primary.light,
    Pendiente: themeInstance.palette.accent3.light,
  }

  return {
    status,
    chipBackgroundColor,
    color,
    animatedIcon,
    animatedIconBackgroundColor,
  }
}

export default getChipStatusStyles
