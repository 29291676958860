import { Box, Card, Typography } from '@mui/material'

import { concatTitlePaidSocialBenefits } from 'components/Period/Payroll/helpers'

import colorFulWoman from 'assets/images/views/common/colorful_woman.png'
import globesMan from 'assets/images/views/common/globes_man.png'

const DescriptionCardHistory = ({ paidSocialBenefits }) => {
  const titles = paidSocialBenefits?.map(({ label }) => label)
  const benefit = concatTitlePaidSocialBenefits(titles)

  return (
    <Card
      sx={{
        minHeight: { mobile: 'auto', desktop: '12.5rem' },
        display: 'flex',
        backgroundColor: `${paidSocialBenefits.length == 1 ? 'accent2.light' : 'accent1.main'}`,
        borderRadius: '1rem',
        overflow: 'visible',
        width: '100%',
        height: 'auto',
      }}
    >
      <Box
        sx={(theme) => ({
          py: theme.spacing(3),
          px: theme.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          justifyContent: 'center',
          flex: 1,
        })}
      >
        <Typography
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
          variant="h3"
        >
          Este periodo liquidaste {benefit}
        </Typography>
        <Typography variant="body1">
          Puedes ir a verificar el valor pagado ingresando al módulo de
          Prestaciones Sociales.
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: paidSocialBenefits.length == 1 ? '26rem' : '20rem',
          position: 'relative',
          display: {
            mobile: 'none',
            laptop: 'block',
          },
        }}
      >
        <img
          src={paidSocialBenefits.length == 1 ? colorFulWoman : globesMan}
          alt=""
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            margin: 'auto',
            height: '20rem',
          }}
        />
      </Box>
    </Card>
  )
}

export default DescriptionCardHistory
