import { parseDateSegments } from './DatePickerField/helpers'
import DateRangePicker from './DateRangePicker'
import { parseRangeValue } from './DateRangePicker/helpers'

const DateRangePickerField = ({
  name,
  disabled,
  setValue,
  value,
  showError,
  touched,
  setTouched,
}) => {
  const startDateId = `${name}_start_date`
  const endDateId = `${name}_end_date`
  const fieldValue = parseRangeValue(value)

  const onChange = ({ start, end }) => {
    setValue({
      start: parseDateSegments(start),
      end: parseDateSegments(end),
    })

    if (!touched) {
      setTouched(true)
    }
  }

  return (
    <DateRangePicker
      aria-label="Rango de fechas"
      startDateId={startDateId}
      endDateId={endDateId}
      disabled={disabled}
      onChange={onChange}
      value={fieldValue}
      error={showError}
    />
  )
}

export default DateRangePickerField
