import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export default {
  contract_data: yup.object({
    contract_category: yup.string().nullable().required(),
    contributor_subtype: yup
      .string()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          ['employee', 'part_time_contract'].includes(contractCategory),
        then: yup.string().nullable().required(),
      }),
    term: yup.string().nullable().required(),
    initial_day: yup.string().nullable().default(null).required(),
    end_day: yup
      .string()
      .nullable()
      .default(null)
      .when('term', {
        is: (term) => term === 'fixed' || term === 'project',
        then: yup.string().nullable().default(null).required(),
      }),
    work_center: yup
      .object()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory &&
          contractCategory !== 'contractor' &&
          contractCategory !== 'schooling_trainee',
        then: yup
          .object()
          .test({
            name: 'testWorkCenter',
            test: (value) => {
              return value?.id && value?.name && value?.risk_type
            },
            message: 'Debes ingresar el centro de trabajo',
          })
          .nullable()
          .required(),
        otherwise: yup.object(),
      }),
    rest_days: yup
      .array()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory && contractCategory === 'employee',
        then: yup
          .array()
          .of(yup.string().nullable())
          .min(1, 'El empleado debe tener por lo menos un día de descanso.'),
      }),
    accumulated_holidays: yup.number().required(),
  }),
  entities: yup.object({
    health_provider: yup
      .object()
      .nullable()
      .when('contract_category', {
        is: (contractCategory) =>
          contractCategory &&
          !['contractor', 'student_decree_055', 'part_time_contract'].includes(
            contractCategory
          ),
        then: yup.object().nullable().required(),
      }),
    pension_provider: yup
      .object()
      .nullable()
      .when(['contract_category', 'document_type', 'contributor_subtype'], {
        is: (contractCategory, documentType, contributorSubtype) =>
          ['ce_no_pension', 'pe_no_pension', 'pt_no_pension'].includes(
            documentType
          ) || contributorSubtype !== 'no_subtype'
            ? false
            : contractCategory &&
              ['employee', 'student_law_789', 'part_time_contract'].includes(
                contractCategory
              ),
        then: yup.object().nullable().required(),
      }),
    severance_provider: yup
      .object()
      .nullable()
      .when(['contract_category', 'wage_category'], {
        is: (contractCategory, wageCategory) =>
          contractCategory &&
          wageCategory &&
          contractCategory === 'employee' &&
          wageCategory !== 'integral_salary',
        then: yup.object().nullable().required(),
      }),
  }),
  job_position_data: yup.object({
    location: yup.object().nullable().required(),
    area: yup.object().nullable().required(),
    position: yup.object().nullable().required(),
    cost_center: yup.object().nullable().required(),
  }),
  basic_data: yup.object({
    name: yup.string().trim().required(),
    last_name: yup.string().trim().required(),
    document_type: yup.string().trim().required(),
    email: yup.string().nullable().email().when('active_user', {
      is: true,
      then: yup.string().nullable().email().required(),
    }),
    phone: yup.string().nullable().required(),
    active_user: yup.boolean().nullable().required(),
  }),
  payment_data: yup.object({
    payment_method: yup.string().nullable().required(),
    bank: yup.mixed().when('payment_method', {
      is: 'wire_transfer',
      then: yup.object().nullable().required(),
      otherwise: yup.mixed().nullable(),
    }),
    account_type: yup.string().nullable().when('payment_method', {
      is: 'wire_transfer',
      then: yup.string().nullable().required(),
    }),
    account_number: yup.number().when('payment_method', {
      is: 'wire_transfer',
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable(),
    }),
  }),
}
