import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const AdditionalIncomeAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openIncomeModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={payroll.details?.additional_income?.value}
      onClick={openIncomeModal}
      editable={period.editable && !payroll.locked}
      dataCy="add-additional-income"
    />
  )
}

export default AdditionalIncomeAction
