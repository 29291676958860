import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Link as MuiLink, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Link from 'components/UI/MaterialUI/Link'

import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { PASSWORD_RECOVERY, PERIOD_PAY_PAYROLL } from 'config/routes'

import AuthLogo from '../common/AuthLogo'
import LinkToSignup from '../common/LinkToSignup'
import MainContainer from '../common/MainContainer'
import { initialValues, validationSchema } from './helpers'

const PasswordReset = () => {
  const navigate = useNavigate()
  const [invalidToken, setInvalidToken] = useState(false)
  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const { reset_password_token: resetPasswordToken } = queryString.parse(
    useLocation().search
  )

  const queryPasswordRecovery = '?password_recovered=true'

  const { handleError } = useErrorHandler()

  if (!resetPasswordToken) {
    throw new Error('Error al restablecer contraseña')
  }

  const handleSubmit = async (values, form) => {
    usersMutation.mutate(
      {
        mutationMethod: 'PUT',
        user: {
          ...values,
          reset_password_token: resetPasswordToken,
        },
      },
      {
        onSuccess: () => {
          navigate(PERIOD_PAY_PAYROLL(queryPasswordRecovery), {
            state: { passwordRecoveredSuccessfully: true },
          })
        },
        onError: (error) => {
          handleError(error, form, {
            errorsToNotificate: [{ object: 'reset_password_token' }],
            notistackOptions: {
              preventDuplicate: true,
            },
          })

          if (
            error.errors[0].code === '0101' &&
            error.errors[0].object === 'reset_password_token'
          ) {
            setInvalidToken(true)
          }
        },
      }
    )
  }

  return (
    <MainContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          overflow: 'auto',
          maxHeight: '100vh',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            padding: theme.spacing(8, 2, 6, 2),
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacing(8, 8, 6, 8),
            },
            [theme.breakpoints.between('laptop', 'lg')]: {
              maxWidth: 'none',
              padding: theme.spacing(4, 6, 6, 6),
            },
            [theme.breakpoints.up('lg')]: {
              padding: theme.spacing(4, 13.5, 6, 13.5),
              maxWidth: 'none',
            },
            [theme.breakpoints.up('desktop')]: {
              padding: theme.spacing(4, 2, 6, 2),
              maxWidth: '30rem',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              marginBottom: theme.spacing(7.75),
            })}
          >
            <AuthLogo />
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              marginBottom: theme.spacing(6.75),
            })}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: 'left',
                width: '100%',
              }}
            >
              Crear contraseña
            </Typography>
          </Box>
          {invalidToken ? (
            <Alert
              sx={(theme) => ({
                textAlign: 'left',
                marginBottom: theme.spacing(3),
              })}
            >
              <Typography variant="body2">
                El código para restablecer la contraseña ya expiró o es
                inválido.{' '}
              </Typography>
              <br />
              <Typography variant="body2">
                Vuelve a realizar el proceso haciendo{' '}
                <Link
                  to={PASSWORD_RECOVERY}
                  fontWeight={600}
                  color="accent4.main"
                >
                  click aquí
                </Link>
                .
              </Typography>
              <br />
              <Typography variant="body2">
                Para más detalles puedes ver:{' '}
                <MuiLink
                  href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-puedes-recuperar-tu-contraseña"
                  target="_blank"
                  fontWeight={600}
                  color="accent4.main"
                >
                  ¿Cómo recupero mi contraseña?
                </MuiLink>
              </Typography>
            </Alert>
          ) : null}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {() => {
              return (
                <>
                  <Form>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        margin: theme.spacing(1, 0),
                      })}
                    >
                      <Box
                        sx={(theme) => ({
                          display: 'grid',
                          gridTemplateColumns: '1fr',
                          rowGap: theme.spacing(3),
                          marginBottom: theme.spacing(4),
                        })}
                      >
                        <PasswordField
                          name="password"
                          label="Tu contraseña (Mínimo 8 caracteres)"
                          placeholder="Ingresa tu contraseña"
                          autoComplete="password"
                        />
                        <PasswordField
                          name="password_confirmation"
                          label="Repetir contraseña (Mínimo 8 caracteres)"
                          placeholder="Confirma tu contraseña"
                          autoComplete="password"
                        />
                      </Box>
                      <Button
                        type="submit"
                        loading={usersMutation.isLoading}
                        sx={(theme) => ({
                          width: '100%',
                          marginBottom: theme.spacing(4),
                        })}
                      >
                        Cambiar mi contraseña
                      </Button>
                    </Box>
                  </Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <LinkToSignup />
                  </Box>
                </>
              )
            }}
          </Formik>
        </Box>
      </Box>
    </MainContainer>
  )
}

export default PasswordReset
