import { Box } from '@mui/material'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'

import LogoFormField from './LogoField'

const CompanyFormFieldsBasics = ({ documentType }) => {
  return (
    <>
      <Box
        component="section"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          [theme.breakpoints.up('xl')]: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <FormField name="name" label="Razón social" optional={false} />
        <FormField
          name="email"
          type="email"
          label="Email contacto"
          optional={false}
        />
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('lg')]: {
              columnGap: theme.spacing(3),
              gridTemplateColumns: '1fr 1fr',
            },
            ...(documentType === 'ni' && {
              gridColumnEnd: 'span 2',
            }),
          })}
        >
          <DocumentTypeField options={documentTypesOptions} optional={false} />
          <CompanyIdentificationNumberField optional={false} />
        </Box>
        <FormField
          name="phone"
          type="phone"
          label="Teléfono"
          optional={false}
        />
      </Box>
      <LogoFormField />
    </>
  )
}

export default CompanyFormFieldsBasics
