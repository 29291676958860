import http, { getApiURL } from '../httpService'

export const getAllElectronicPayrolls = ({ page, month, year }) => {
  const url = getApiURL({
    pathname: '/electronic_payroll/periods',
    searchParams: { page, month, year },
  })

  return http.getV2(url)
}

export const getElectronicPayrollPeriodById = (
  periodId,
  electronicPayrollStatuses
) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/periods/${periodId}`,
    searchParams: { electronic_paryoll_statuses: electronicPayrollStatuses },
  })

  return http.getV2(url)
}

export const getElectronicPayrollItems = (electronicPayrollId) => {
  const url = getApiURL({
    pathname: `/${electronicPayrollId}/electronic_payroll_items`,
  })

  return http.getV2(url)
}

export const sendElectronicPayroll = (periodId, electronicPayrolls) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/periods/${periodId}/payrolls_to_issue`,
    searchParams: { async: true },
  })
  const reqData = {
    electronic_payroll_ids: electronicPayrolls,
  }

  return http.putV2(url, reqData)
}

export const createElectronicPreviousPayroll = () => {
  const url = getApiURL({
    pathname: '/electronic_payroll/periods',
  })

  return http.postV2(url)
}

// mutation for create update and delete a bonus item incomes
export const updateBonusIncomes = (electronicPayrollId, data) => {
  const url = getApiURL({
    pathname: `/${electronicPayrollId}/electronic_payroll_items`,
  })

  return http.putV2(url, data)
}

export const periodPayrollSummary = ({ search, page, electronicPayrollId }) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/periods/${electronicPayrollId}/period_payrolls_summary`,
    searchParams: { page, search },
  })

  return http.getV2(url)
}

export const getElectronicPayrollHistoryByWorker = ({
  search,
  page,
  periodId,
  workerId,
  filter,
}) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/periods/${periodId}/workers/${workerId}/payroll_histories`,
    searchParams: { page, search, filter },
  })

  return http.getV2(url)
}

export const cancelElectronicPayroll = (electronicPayrollId) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/cancels/${electronicPayrollId}`,
  })

  return http.patchV2(url)
}

export default {
  getAllElectronicPayrolls,
  cancelElectronicPayroll,
  getElectronicPayrollPeriodById,
  getElectronicPayrollItems,
  sendElectronicPayroll,
  createElectronicPreviousPayroll,
  updateBonusIncomes,
}
