import { Navigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Page from 'components/UI/Page/Page'

import * as routes from 'config/routes'

import IndexTable from './DataTable/Table'

const NoveltiesRequestIndex = () => {
  const { showHRFeatures } = usePremiumFeature()

  if (!showHRFeatures) {
    return (
      <Navigate
        to={routes.PAYMENT_REQUIRED()}
        state={{ error: { code: '0010' } }}
      />
    )
  }

  return (
    <Page
      header={
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            gridColumn: '1 / -1',
          })}
        >
          <Typography variant="h2">
            Historial de solicitudes de tiempo fuera
          </Typography>
          <Typography>
            Acá puedes gestionar todas las solicitudes de tiempo fuera de tus
            compañeros.
          </Typography>
        </Box>
      }
      documentTitle="Historial de solicitudes de tiempo fuera"
      grid
    >
      <IndexTable />
    </Page>
  )
}

export default NoveltiesRequestIndex
