import { Box, Typography, alpha, useMediaQuery } from '@mui/material'

import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { formatCurrency } from 'utils/format'

import DataTable from './DataTable'
import { formatComment, tableTitles } from './helpers'

const PeriodDetailsBodySection = ({ details, activeDetail }) => {
  const tabletDown = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const currentTabData = details[activeDetail] || {}

  return Object.keys(currentTabData).map((tableName) => {
    const { data: tableData, comments } = currentTabData[tableName]
    const total = tableData.find((data) => data?.symbol === '=')

    return tableData.length > 0 ? (
      <Box
        key={tableName}
        sx={(theme) => ({
          marginBottom: theme.spacing(6),
          '&:nth-last-of-type(1)': {
            marginBottom: theme.spacing(1),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
          })}
        >
          <Typography variant="h4" color="primary.dark">
            {tableTitles[tableName]}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            boxShadow: theme.shadows[5],
            borderRadius: '1rem',
          })}
        >
          <DataTable
            name={tableName}
            data={tableData}
            tabletDown={tabletDown}
            totalLabel={total?.name}
          />

          {tableData.map((data, index) => {
            const isTotal = data.symbol === '='
            const isTotalWorkedDays = data.name === 'Total días trabajados'

            return isTotal ? (
              <Box
                key={index}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  borderBottomLeftRadius: '1rem',
                  borderBottomRightRadius: '1rem',
                  padding: theme.spacing(2.75, 2),
                })}
              >
                <Typography variant={tabletDown ? 'h4' : 'h3'}>
                  {data.name}
                </Typography>
                <Typography variant={tabletDown ? 'h4' : 'h3'}>
                  {isTotalWorkedDays
                    ? `${data.value} días`
                    : formatCurrency(data.value)}
                </Typography>
              </Box>
            ) : null
          })}
        </Box>
        {comments.length > 0 ? (
          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
          >
            <Alert
              severity="info"
              icon={false}
              sx={(theme) => ({
                padding: theme.spacing(2.5, 4, 2.5, 4),
                borderRadius: '1rem',
                margin: theme.spacing(2.5, 0, 2.5, 0),
              })}
            >
              {comments.map((comment, index) => {
                return (
                  <Typography variant="body1" key={index.toString()}>
                    {formatComment(comment)}
                  </Typography>
                )
              })}
            </Alert>
          </Box>
        ) : null}
      </Box>
    ) : null
  })
}

export default PeriodDetailsBodySection
