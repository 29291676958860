import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { getCompanyId } from 'utils/company'
import { loveHotel } from 'utils/emojis'

import sitYellowWoman from 'assets/images/views/common/sit_yellow_woman.png'

import { COMPANY_EDIT } from 'config/routes'

import CredentialsAlert from './CredentialsAlert'

const ConfirmAffiliation = ({
  handleAffiliationMutation,
  affiliationQueryKey,
}) => {
  const queryClient = useQueryClient()
  const modals = useModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const navigate = useNavigate()

  const handleCloseConfirmationModal = () => {
    modals.closeModal('confirmAffiliation')
  }

  const handleCloseCredentialsAlertModal = () => {
    modals.closeModal('credentialsAlert')
  }

  const handleSubmitAffiliation = () => {
    const confirmationData = new FormData()
    confirmationData.append('status', 'waiting_response')
    modals.setLoadingModal(true)

    handleAffiliationMutation(
      confirmationData,
      async () => {
        await queryClient.invalidateQueries(affiliationQueryKey)
        await queryClient.invalidateQueries([
          'getCompanyOnboardings',
          getCompanyId(),
        ])
        modals.setLoadingModal(false)
        handleCloseConfirmationModal()
      },
      (error) => {
        modals.setLoadingModal(false)
        handleCloseConfirmationModal()
        modals.openModal({
          id: 'credentialsAlert',
          content: <CredentialsAlert errors={error.errors} />,
          modalProps: {
            header: 'Completa la información de tu empresa',
            okText: 'Ir a empresa',
            onCancel: handleCloseCredentialsAlertModal,
            onOk: () => {
              handleCloseCredentialsAlertModal()
              navigate(COMPANY_EDIT(getCompanyId()), {
                state: {
                  initialStep: 6,
                },
              })
            },
            dialogProps: {
              fullWidth: true,
              maxWidth: 'sm',
            },
            adornment: [
              {
                variant: 'signature',
                color: 'complementary1.light',
                width: 160,
                height: 176,
                sx: { bottom: '-4.2rem', right: '2rem' },
              },
            ],
          },
        })
      }
    )
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gap: theme.spacing(6),
        gridTemplateColumns: { mobile: '1fr', tablet: '1fr 2fr' },
      })}
    >
      <ButtonBase
        onClick={handleCloseConfirmationModal}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="close" sx={{ fontSize: '1.7rem' }} />
      </ButtonBase>
      {!isMobile ? (
        <>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.accent1.main,
              width: '40rem',
              height: '40rem',
              borderRadius: '100%',
              position: 'absolute',
              left: '-23rem',
              top: '-10rem',
              [theme.breakpoints.down('laptop')]: {
                left: '-25rem',
                top: '-10rem',
              },
            })}
          />
          <Box
            src={sitYellowWoman}
            component="img"
            alt="Con Aleluya tienes la mejor atención"
            sx={(theme) => ({
              alignSelf: 'center',
              objectFit: 'contain',
              zIndex: 1,
              width: '100%',
              marginLeft: theme.spacing(-2),
              [theme.breakpoints.up('laptop')]: {
                width: '16rem',
              },
            })}
          />
        </>
      ) : null}
      <Box>
        <Typography
          variant="h2"
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
            paddingRight: theme.spacing(2.5),
          })}
        >
          Finalizar solicitud de afiliación <Emoji code={loveHotel} />
        </Typography>
        <Typography
          variant="body1"
          color="black.dark"
          sx={(theme) => ({
            marginBottom: theme.spacing(3),
          })}
        >
          Estás a un paso de terminar tu solicitud de afiliación a seguridad
          social. Una vez realicemos todo el proceso, te estaremos notificando
          vía correo electrónico.
        </Typography>
        <Typography
          variant="body1"
          color="black.dark"
          sx={(theme) => ({
            marginBottom: theme.spacing(3),
          })}
        >
          También podrás validar el estado de cada afiliación a seguridad social
          en el módulo de Personas.
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(2),
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('tablet')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Button
            onClick={handleSubmitAffiliation}
            loading={modals.loadingModal}
          >
            Solicitar afiliación
          </Button>
          <Button variant="outlined" onClick={handleCloseConfirmationModal}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ConfirmAffiliation
