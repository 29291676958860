import { createCalendar } from '@internationalized/date'
import { useDateField, useDateSegment } from '@react-aria/datepicker'
import { useLocale } from '@react-aria/i18n'
import { useDateFieldState } from '@react-stately/datepicker'
import { forwardRef, useRef } from 'react'

import { Box } from '@mui/material'

import { useDatePickerContext } from '.'
import { parseValueWithLeadingZeros } from './helpers'

const DateSegment = ({ segment, state }) => {
  const ref = useRef()
  const { segmentProps } = useDateSegment(segment, state, ref)
  const parsedText =
    ['day', 'month'].includes(segment.type) &&
    !Number.isNaN(Number(segment.text))
      ? parseValueWithLeadingZeros(segment.text)
      : segment.text

  return (
    <Box
      {...segmentProps}
      component="span"
      ref={ref}
      style={{
        ...segmentProps.style,
      }}
      sx={(theme) => ({
        boxSizing: 'content-box',
        textAlign: 'right',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        borderRadius: '0.125rem',
        userSelect: 'none',
        color: theme.palette.black.light,
        [theme.breakpoints.up(380)]: {
          textTransform: 'uppercase',
        },
        ...(!segment.isEditable
          ? {
              color: theme.palette.black.light,
            }
          : {
              color: theme.palette.black.dark,
            }),
        ...(segment.isPlaceholder && {
          color: theme.palette.black.light,
        }),
      })}
    >
      {segment.isPlaceholder ? segment.placeholder : parsedText}
    </Box>
  )
}

const DateField = forwardRef(function DateFieldForwardRef(
  { ...props },
  inputRef
) {
  const { locale } = useLocale()
  const { fieldProps: baseFieldProps } = useDatePickerContext()
  const state = useDateFieldState({
    ...baseFieldProps,
    locale,
    createCalendar,
  })

  const ref = useRef()
  const { fieldProps } = useDateField(baseFieldProps, state, ref)

  return (
    <Box
      {...fieldProps}
      {...props}
      ref={(node) => {
        ref.current = node
        // eslint-disable-next-line no-param-reassign
        inputRef.current = node
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {state.segments.map((segment, index) => (
        <DateSegment
          key={`${segment.type}_${index.toString()}`}
          segment={segment}
          state={state}
        />
      ))}
    </Box>
  )
})

export default DateField
