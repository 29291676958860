import { Box, IconButton, Tooltip } from '@mui/material'

import Icon from 'components/UI/Icon'

const ConfirmEditActions = ({ onSave, onCancel }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(1),
      })}
    >
      <Tooltip title="Guardar">
        <span>
          <IconButton
            onClick={onSave}
            color="complementary1"
            sx={{
              padding: 0,
              width: '2rem',
              height: '2rem',
            }}
          >
            <Icon name="approve-checked" basic />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Cancelar">
        <span>
          <IconButton
            onClick={onCancel}
            color="complementary1"
            sx={{
              padding: 0,
              width: '2rem',
              height: '2rem',
            }}
          >
            <Icon name="close-2" basic />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
}

export default ConfirmEditActions
