import { createCalendar } from '@internationalized/date'
import { useDateField } from '@react-aria/datepicker'
import { useLocale } from '@react-aria/i18n'
import { useDateFieldState } from '@react-stately/datepicker'
import { useRef } from 'react'

import { TextField } from '@mui/material'

const DateField = ({
  onClick,
  placeholder,
  id,
  disabled,
  error,
  dataCy,
  sx,
  ...props
}) => {
  const { locale } = useLocale()
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  })
  const ref = useRef()
  const { fieldProps } = useDateField(props, state, ref)
  const value =
    state.formatValue({
      month: 'numeric',
    }) || ''

  return (
    <TextField
      {...fieldProps}
      id={id}
      ref={ref}
      onClick={onClick}
      placeholder={placeholder}
      value={value}
      margin="none"
      disabled={disabled}
      error={error}
      InputProps={{
        readOnly: true,
        inputProps: {
          'data-cy': dataCy || undefined,
          style: {
            textAlign: 'center',
          },
        },
        sx: [
          (theme) => ({
            padding: theme.spacing(0, 1),
            [theme.breakpoints.up('tablet')]: {
              padding: theme.spacing(0, 2),
            },
          }),
          sx,
        ],
      }}
    />
  )
}

export default DateField
