import { useState } from 'react'

const useBulkNoveltiesErrorsSummaryModal = () => {
  const [state, setState] = useState({
    open: false,
    errorsObject: {},
    bulkUploadResult: {},
  })

  const openBulkNoveltiesErrorsSummaryModal = (bulkUploadResult) => {
    const { success } = bulkUploadResult || {}
    const errorsObject = {}

    Object.entries(success).forEach(([key, value]) => {
      if (value?.failure_cases_count || value?.not_found_workers) {
        errorsObject[key] = { main_errors: [], other_errors: [] }
      }

      if (value?.failure_cases_count > 0) {
        errorsObject[key].main_errors = value.failure_cases.rows
      }

      if (value?.not_found_workers) {
        errorsObject[key].other_errors = value.not_found_workers
      }
    })

    setState({ open: true, errorsObject, bulkUploadResult })
  }

  const closeBulkNoveltiesErrorsSummaryModal = () => {
    setState({ open: false })
  }

  return {
    bulkNoveltiesErrorsSummaryModalState: state,
    openBulkNoveltiesErrorsSummaryModal,
    closeBulkNoveltiesErrorsSummaryModal,
  }
}

export default useBulkNoveltiesErrorsSummaryModal
