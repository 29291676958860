import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import useAreaService from 'utils/hooks/company/areaService'

const initialValues = { name: '' }

const WorkerFormFieldsPersonalAreaDialog = ({
  handleClose,
  submitCallback,
}) => {
  const queryClient = useQueryClient()

  const { areaMutation } = useAreaService({ queryOptions: { enabled: false } })

  const mutateAreaService = (values) => {
    areaMutation.mutate(
      {
        mutationMethod: 'PUT',
        data: [values],
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries('companyAreas')
          submitCallback(data.data)
          handleClose()
        },
      }
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={mutateAreaService}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit } = formProps
        return (
          <Modal
            open
            header="Agregar nueva área"
            onOk={handleSubmit}
            onCancel={handleClose}
            okText="Guardar"
            isLoading={areaMutation.isLoading}
            disableBackdropClick={areaMutation.isLoading}
            disableEscapeKeyDown={areaMutation.isLoading}
            dialogProps={{
              maxWidth: 'xs',
              fullWidth: true,
            }}
            dataCy="create-area-modal"
          >
            <Form>
              <FormField name="name" label="Nombre" autoFocus />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default WorkerFormFieldsPersonalAreaDialog
