import { Box } from '@mui/material'

import PayrollRetentionValue from './PayrollRetentionValue'
import UVTConfiguration from './UVTConfiguration'

const PayrollRetention = ({
  handleChangeItemValue,
  payrollRetentionInfo,
  workerPayroll,
  uvtValueUpLimit,
  setUvtValueUpLimit,
  setArt206LimitValue,
  setArt336LimitValue,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(4),
      })}
    >
      <UVTConfiguration
        workerPayroll={workerPayroll}
        uvtValueUpLimit={uvtValueUpLimit}
        setUvtValueUpLimit={setUvtValueUpLimit}
        setArt206LimitValue={setArt206LimitValue}
        setArt336LimitValue={setArt336LimitValue}
      />
      <PayrollRetentionValue
        handleChangeItemValue={handleChangeItemValue}
        payrollRetentionInfo={payrollRetentionInfo}
        payrollId={workerPayroll.id}
        workerId={workerPayroll?.worker_id}
      />
    </Box>
  )
}

export default PayrollRetention
