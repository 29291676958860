import { Box, Link, Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'

import { eyes } from 'utils/emojis'
import { getWordSingular } from 'utils/format'

const getEntitiesType = (errors) => {
  return errors
    .map((error) => {
      if (error.code === '3008') {
        return `Caja de compensación - ${error.complement}`
      }

      if (error.code === '3009') {
        return `Salud - ${error.complement}`
      }

      if (error.code === '3010') {
        return `ARL - ${error.complement}`
      }

      if (error.code === '3011') {
        return `Pensión - ${error.complement}`
      }

      return null
    })
    .filter(Boolean)
}

const CredentialsAlert = ({ errors = [] }) => {
  const entitiesCredentials = getEntitiesType(errors)
  const credentialsSize = entitiesCredentials.length
  const documents = errors.filter(({ code }) => code === '3012')
  const documentsSize = documents.length

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
      })}
    >
      <Typography variant="body1" color="black.dark">
        {credentialsSize > 0 ? (
          <>
            Ojo <Emoji code={eyes} />. Debes agregar las credenciales de{' '}
            {getWordSingular('las', credentialsSize === 1)}{' '}
            {getWordSingular('administradoras', credentialsSize === 1)} de{' '}
            <Box
              component="span"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {entitiesCredentials.length > 1
                ? entitiesCredentials.join(', ').replace(/, ([^,]*)$/, ' y $1')
                : entitiesCredentials[0]}
            </Box>
            {documentsSize > 0 ? (
              <>
                , y adjuntar el{' '}
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {documents
                    .map(({ complement }) => complement)
                    .join(', ')
                    .replace(/, ([^,]*)$/, ' y $1')}
                </Box>
              </>
            ) : null}{' '}
            de tu compañía en el módulo de Empresa - Credenciales y
            documentación, para poder continuar con tu afiliación a seguridad
            social.
          </>
        ) : null}
        {documentsSize > 0 && credentialsSize === 0 ? (
          <>
            Ojo <Emoji code={eyes} />. Debes agregar el{' '}
            <Box
              component="span"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {documents
                .map(({ complement }) => complement)
                .join(', ')
                .replace(/, ([^,]*)$/, ' y $1')}
            </Box>{' '}
            de tu compañía en el módulo de Empresa - Credenciales y
            documentación, para poder continuar con tu afiliación a seguridad
            social.
          </>
        ) : null}
      </Typography>
      <Typography variant="body1" color="black.dark">
        En este{' '}
        <Link
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/agrega-las-credenciales-y-documentación-para-que-afilies-a-tus-empleados-a-seguridad-social-sin-salir-de-aleluya"
          target="_blank"
          color="accent4.main"
          fontWeight={600}
        >
          artículo
        </Link>
        , podrás encontrar el paso a paso de cómo hacerlo.
      </Typography>
    </Box>
  )
}

export default CredentialsAlert
