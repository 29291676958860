import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const HolidaysAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openNoveltiesModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={payroll.details?.holidays?.quantity}
      onClick={() => openNoveltiesModal('holidays')}
      editable={period.editable && !payroll.locked}
      dataCy="add-holidays"
    />
  )
}

export default HolidaysAction
