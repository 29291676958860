import { useQueryClient } from 'react-query'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Loading from 'components/UI/Loading/Loading'
import Table from 'components/UI/Table/Table'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useCompanyService from 'utils/hooks/organizer/company'
import useNotifications from 'utils/hooks/useNotifications'

import { updatePaymentAdjustment } from 'services/organizer/companyService'

import { getColumnsData, validationSchema } from './helpers'

const PaymentAdjustmentsHistory = ({ companyId, plans, monthsAllowed }) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { subscription } = useSubscription()

  const isPartner = subscription.partner
  const paymentAdjustmentsQueryKey = ['getCompanyPaymentAdjustments', companyId]

  const { companyQuery: paymentAdjustmentsQuery } = useCompanyService({
    serviceParams: {
      queryKey: paymentAdjustmentsQueryKey,
      companyId,
    },
  })

  const callback = () => {
    queryClient.invalidateQueries(paymentAdjustmentsQueryKey)
    showSuccessMessage('El ajuste de pago ha sido actualizado.')
  }

  const handleUpdatePaymentAdjustment = (
    oldPaymentAdjustment,
    newPaymentAdjustment
  ) => {
    const dirtyValues = getDirtyValues(
      oldPaymentAdjustment,
      newPaymentAdjustment,
      validationSchema.fields
    )

    const paymentAdjustmentData = { payment_adjustment: dirtyValues }

    if (!isObjectEmpty(dirtyValues)) {
      return {
        data: {
          companyId,
          paymentAdjustmentId: oldPaymentAdjustment.id,
          paymentAdjustmentData,
        },
        mutationFunction: updatePaymentAdjustment,
        mutateOptions: {
          onSuccess: () => callback(),
        },
      }
    }
    return {}
  }

  const columnsData = getColumnsData(plans, monthsAllowed, isPartner)

  return paymentAdjustmentsQuery?.isLoading ? (
    <Loading />
  ) : (
    <Table
      columns={columnsData}
      data={paymentAdjustmentsQuery?.data}
      options={{
        customActionsWidth: '5rem',
        alignActionsHeader: 'center',
        alignActionsCell: 'center',
        search: false,
        pagination: false,
      }}
      editable={{
        onUpdateRow: handleUpdatePaymentAdjustment,
        validationSchema,
        hideDelete: true,
        isEditable: ({ editable }) => editable,
      }}
    />
  )
}

export default PaymentAdjustmentsHistory
