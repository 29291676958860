export function generateEmptyPayrollItem(
  payrollConceptId = '',
  payrollConceptName = null,
  codedName = '',
  keyConcept = 'payroll_concept_id' // Put the key concept for get more flexibility
) {
  return {
    [keyConcept]: payrollConceptId,
    name: payrollConceptName || '',
    value: 0,
    coded_name: codedName,
  }
}

export function generateNewCustomConcept({
  payrollId,
  payrollConcepts,
  category,
  active = true,
}) {
  return {
    payroll_id: payrollId,
    payroll_concepts: payrollConcepts,
    category,
    active,
  }
}

export function getNameOfPayrollConceptCategory(category, plural = false) {
  switch (category) {
    case 'salary_income':
      return plural ? 'Ingresos salariales' : 'Ingreso salarial'
    case 'non_salary_income':
      return plural ? 'Ingresos NO salariales' : 'Ingreso NO salarial'
    case 'deductions':
      return plural ? 'Deducciones' : 'Deducción'
    case 'novelties':
      return plural ? 'Novedades' : 'Novedad'
    case 'overtime':
      return plural ? 'Horas Extras/Recargos' : 'Hora Extra/Recargo'
    default:
      return 'Categoría indefinida'
  }
}

export const socialBenefitsName = {
  service_bonus: 'Prima',
  severance: 'Cesantías',
  severance_interests: 'Intereres a las cesantías',
}

export const salaryIncomeRecurrentConcepts = [
  'benefit_bonus',
  'commissions',
  'other_income',
]

export const nonSalaryIncomeRecurrentConcepts = [
  'food_bonus',
  'study_bonus',
  'transport_aid',
  'mobilization_aid',
  'health_bonus',
  'housing_aid',
  'non_benefit_bonus',
  'productivity_bonus',
  'meal_aid',
  'working_fee',
  'other_non_benefit_income',
]

export const deductionsRecurrentConcepts = [
  'afc_voluntary_contribution',
  'avc_voluntary_contribution',
  'pension_voluntary_contribution',
  'mandatory_pension_fund_voluntary_contribution',
  'phone',
  'other_deductions',
]

export const incomesElectronicPayroll = [
  'endowment',
  'non_wage_food_bonus',
  'non_wage_bonus',
]
