export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Extract the number from a string.
 * Only works with values with comma as decimal part separator.
 *
 * Example: '$ 123.456,78' => 123456.78
 * @param {String} string
 */
export function parseStringToNumber(string) {
  return Number(
    string.replace(/[^0-9,]|[,]/g, (matched) => (matched === ',' ? '.' : ''))
  )
}

/**
 * Replace dots with commas.
 *
 * Workarround to cleavejs not parsing number type values using the format options
 *
 * @param {number} number
 */
export function parseNumberToString(number) {
  return number != null ? number.toString().replace(/\./g, ',') : ''
}

/**
 * Round value of a especified number of decimals.
 *
 * Value must be of type string.
 *
 * @param {String} value
 * @param {Number} numberOfDecimals
 */
export function roundDecimals(value, numberOfDecimals = 3) {
  const decimals = 10 ** numberOfDecimals
  return Math.round(parseFloat(value) * decimals) / decimals
}
