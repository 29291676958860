import { getCompanyId } from 'utils/company'

import http, { getApiURL } from '../httpService'

export const deletePayroll = (payrollId) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/payrolls/${payrollId}`,
    withoutVersion: true,
  })

  return http.deleteV2(url)
}

export default {
  deletePayroll,
}
