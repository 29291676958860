import MontserratBoldTtf from 'assets/fonts/Montserrat-Bold.ttf'
import MontserratMediumTtf from 'assets/fonts/Montserrat-Medium.ttf'
import MontserratSemiBoldTtf from 'assets/fonts/Montserrat-SemiBold.ttf'
import SoraRegularTtf from 'assets/fonts/Sora-Regular.ttf'
import SoraSemiBoldTtf from 'assets/fonts/Sora-SemiBold.ttf'

import generateFont from './utils'

function generateMontserrat(fontWeight, url) {
  return generateFont({
    fontFamily: 'Montserrat',
    fontWeight,
    url,
  })
}

function generateSora(fontWeight, url) {
  return generateFont({
    fontFamily: 'Sora',
    fontWeight,
    url,
  })
}

const montserratMedium = generateMontserrat(500, MontserratMediumTtf)
const montserratSemiBold = generateMontserrat(600, MontserratSemiBoldTtf)
const montserratBold = generateMontserrat(700, MontserratBoldTtf)
const soraSemiBold = generateSora(600, SoraSemiBoldTtf)
const soraRegular = generateSora(400, SoraRegularTtf)

export const montserrat = [montserratMedium, montserratSemiBold, montserratBold]

export const sora = [soraRegular, soraSemiBold]
