import http, { getApiURL } from './httpService'

export const getInstitutionsByCategory = (
  category,
  needCredentials = false,
  departmentId,
  provider
) => {
  const url = getApiURL({
    pathname: '/institutions',
    withoutCompany: true,
    searchParams: {
      category,
      company_credentials: needCredentials,
      department_id: departmentId,
      provider,
    },
  })

  return http.getV2(url)
}

export default {
  getInstitutionsByCategory,
}
