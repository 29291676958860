import { Box } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import useWorker from 'components/Worker/useWorker'

import HolidaysContent from './HolidaysContent'
import RecurrentConceptsContent from './RecurrentConceptsContent/RecurrentConceptsContent'
import SalaryContent from './SalaryContent'
import WorkerHeaderActions from './WorkerHeaderActions'
import WorkerSummary from './WorkerSummary'

const WorkerShowHeader = () => {
  const { worker } = useWorker({ useCache: true })
  const showHolidaysContent = worker.contract_category === 'employee'
  const { isWorker } = useUser()

  const { showHRFeatures, showPremiumFeatures } = usePremiumFeature()

  const showHeaderActions = showPremiumFeatures || showHRFeatures

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridColumn: '1 / -1',
      }}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.white.main,
          gridColumn: '1 / -1',
          borderRadius: '1rem',
          boxShadow: theme.shadows[5],
          marginBottom: theme.spacing(5),
          padding: theme.spacing(
            2,
            2,
            !showHeaderActions && isWorker ? 0 : 2,
            2
          ),
          [theme.breakpoints.up('tablet')]: {
            padding: theme.spacing(
              4,
              3,
              !showHeaderActions && isWorker ? 0 : 5,
              3
            ),
          },
        })}
      >
        <WorkerSummary
          showHeaderActions={showHeaderActions}
          isWorker={isWorker}
        />
        <WorkerHeaderActions />
      </Box>
      <Box
        sx={(theme) => ({
          gridColumn: '1 / -1',
          marginBottom: theme.spacing(5),
          display: 'grid',
          rowGap: theme.spacing(2),
          gridTemplateColumns: '1fr',
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: showHolidaysContent
              ? 'repeat(3, 1fr)'
              : 'repeat(2, 1fr)',
            columnGap: theme.spacing(4),
          },
        })}
      >
        <SalaryContent />
        <RecurrentConceptsContent withoutHolidays={!showHolidaysContent} />
        {showHolidaysContent ? <HolidaysContent /> : null}
      </Box>
    </Box>
  )
}

export default WorkerShowHeader
