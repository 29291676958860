import { OutlinedInput } from '@mui/material'

const TextareaField = ({ id, name, value, ...props } = {}) => {
  const { onBlur, onChange, sx, ...restProps } = props

  return (
    <OutlinedInput
      id={id}
      name={name}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      multiline
      sx={[
        {
          height: 'auto',
        },
        sx,
      ]}
      {...restProps}
    />
  )
}

export default TextareaField
