import { Box, Paper, Typography } from '@mui/material'

import { useCardsContent } from './helpers'

const Summary = ({
  coachingSessions,
  financialWellnessIndicator,
  changeTab,
}) => {
  const contentCards = useCardsContent({
    coachingSessions,
    financialWellnessIndicator,
    changeTab,
  })

  return (
    <>
      <Typography
        variant="h3"
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
        })}
      >
        Diagnóstico General
      </Typography>
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
      >
        Asegúrate que el tintico no se enfríe. Veamos cómo está tu diagnóstico y
        esa platica que entra a tu bolsillo mes a mes.
      </Typography>

      <Paper
        sx={(theme) => ({
          display: ' grid',
          gridTemplateColumns: {
            mobile: '1fr',
            tablet: 'repeat(2, 1fr)',
            desktop: 'repeat(3, 1fr)',
          },
          gridTemplateRows: {
            mobile: 'repeat(3, 1fr)',
            tablet: 'repeat(2, 1fr)',
            desktop: '1fr',
          },
          columnGap: theme.spacing(4),
          rowGap: theme.spacing(4),
          width: '100%',
          minHeight: '28.5rem',
          padding: theme.spacing(2, 2, 2),
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4, 4, 6),
          },
          backgroundColor: 'white.main',
          borderRadius: '0.5rem',
          boxShadow: theme.shadows[6],
        })}
      >
        {contentCards.map(({ id, title, mainContent, button }, index) => {
          const addSpanColumnClass = index === contentCards.length - 1

          return (
            <Box
              key={id}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.accent1.light,
                borderRadius: '0.6563rem',
                padding: theme.spacing(2),
                ...(addSpanColumnClass && {
                  [theme.breakpoints.between('tablet', 'laptop')]: {
                    gridColumn: 'span 2',
                    justifySelf: 'center',
                  },
                }),
              })}
            >
              <Typography variant="h5">{title}</Typography>

              {mainContent}

              {button}
            </Box>
          )
        })}
      </Paper>
    </>
  )
}

export default Summary
