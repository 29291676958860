import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import firebase from 'firebase/app'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { StyledEngineProvider } from '@mui/material/styles'

import AppWrapper from 'components/App/Wrapper'

import 'assets/stylesheets/main.css'

import firebaseConfig from './firebase-config'
import './styles/tailwind.css'

firebase.initializeApp(firebaseConfig)

const splitSdkConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_API_KEY,
    key: 'anonymous',
    labelsEnabled: false,
  },
}
const root = createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <SplitFactoryProvider
      config={splitSdkConfig}
      updateOnSdkUpdate
      updateOnSdkReady
    >
      <StyledEngineProvider injectFirst>
        <AppWrapper />
      </StyledEngineProvider>
    </SplitFactoryProvider>
  </StrictMode>
)
