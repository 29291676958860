import { Form, Formik } from 'formik'
import { useQueries, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Paper, Typography } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Page from 'components/UI/Page/Page'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useNotifications from 'utils/hooks/useNotifications'

import {
  getCompanyConfiguration,
  getConfigurationOptions,
} from 'services/settings/configurationService'

import { SETTINGS } from 'config/routes'

import Alert from '../../UI/MaterialUI/Alert/Alert'

const WorkerBenefits = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showHRFeatures } = usePremiumFeature()
  const { showSuccessMessage } = useNotifications()
  const { companyConfigurationMutation } = useCompanyConfigurationService({
    queryOptions: { enabled: false },
  })

  const queryFunction = [getConfigurationOptions, getCompanyConfiguration]
  const [companyConfigurationOptionsQuery, companyConfigurationQuery] =
    useQueries(
      ['companyConfigurationOptions', 'companyConfiguration'].map(
        (query, index) => {
          return {
            queryKey: query,
            queryFn: () => queryFunction[index](),
          }
        }
      )
    )

  const isLoading =
    companyConfigurationOptionsQuery.isLoading ||
    companyConfigurationQuery.isLoading

  const { data: { data: companyConfigurationOptions } = {} } =
    companyConfigurationOptionsQuery
  const { data: { data: companyConfiguration } = {} } =
    companyConfigurationQuery

  const handleRedirect = () => navigate(SETTINGS())

  const onSubmit = (values) => {
    const dirtyValues = getDirtyValues(companyConfiguration, values)

    if (isObjectEmpty(dirtyValues)) handleRedirect()

    companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: dirtyValues,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['companyConfiguration'])
          showSuccessMessage('La configuración se actualizó correctamente.')
          handleRedirect()
        },
      }
    )
  }

  const {
    workers_handle_novelties: workersHandleNovelties,
    workers_income_certificate_access: incomeCertificateAccess,
    workers_labor_certificate_access: laborCertificateAccess,
    workers_payslips_access: payslipsAccess,
  } = companyConfigurationOptions || {}

  return (
    <Page
      documentTitle="Beneficios y accesibilidad para personas"
      header="Beneficios y accesibilidad para personas"
      isLoading={isLoading}
    >
      <Formik
        initialValues={companyConfiguration}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Form>
            <Paper
              sx={(theme) => ({
                padding: theme.spacing(2, 2.5, 7, 4),
                marginBottom: theme.spacing(4),
              })}
            >
              <Typography
                variant="h5"
                sx={(theme) => ({ marginBottom: theme.spacing(4) })}
              >
                ¿Qué beneficios y opciones de accesibilidad quieres habilitar
                para tu equipo?
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(3),
                  marginTop: theme.spacing(2),
                })}
              >
                {showHRFeatures ? (
                  <FormField
                    name="workers_handle_novelties"
                    label="Auto-gestionar sus vacaciones, licencias e incapacidades"
                    variant="radio-group"
                    row
                    options={workersHandleNovelties || []}
                  />
                ) : null}
                <FormField
                  name="workers_payslips_access"
                  label="Descargar colillas de pago"
                  variant="radio-group"
                  options={payslipsAccess || []}
                  row
                />
                <FormField
                  name="workers_labor_certificate_access"
                  label="Descargar certificados laborales"
                  variant="radio-group"
                  options={laborCertificateAccess || []}
                  row
                />
                <FormField
                  name="workers_income_certificate_access"
                  label="Descargar certificados de ingresos y retenciones"
                  variant="radio-group"
                  options={incomeCertificateAccess || []}
                  row
                />
              </Box>
              {showHRFeatures ? (
                <Alert
                  icon={false}
                  header="¡Accesibilidad para personas!"
                  severity="info"
                  sx={(theme) => ({
                    maxWidth: '37.5rem',
                    marginTop: theme.spacing(5),
                  })}
                >
                  Con este súper poder puedes permitir a las personas
                  autogestionar sus vacaciones, licencias e incapacidades,
                  descargar automáticamente certificados laborales, colillas de
                  pago y certificados de ingresos y retenciones.
                </Alert>
              ) : null}
            </Paper>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                loading={companyConfigurationMutation.isLoading}
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default WorkerBenefits
