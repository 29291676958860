import { Typography } from '@mui/material'

export const endFreePayrollModalInfo = [
  {
    id: 'whatsappTicket',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        Envía <b>colilla de pago</b> por <b>Whatsapp</b> y correo electrónico.
      </Typography>
    ),
    icon: 'envelope-mail',
  },
  {
    id: 'payslipsSummary',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Descarga tu <b>Resumen de nómina en excel</b> con todo detallado.
      </Typography>
    ),
    icon: 'download-document',
  },
  {
    id: 'socialSecurityPayment',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Paga tu planilla PILA</b> en segundos y evita multas con la UGPP.
      </Typography>
    ),
    icon: 'recurring-cash',
  },
]

export const endFreeElectronicPayrollModalInfo = [
  {
    id: 'sendElectronicPeyroll',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        <b>Emite nómina electrónica</b> de todas tus personas.
      </Typography>
    ),
    icon: 'couple',
  },
  {
    id: 'graphicRepresentation',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Descarga la <b>Representación gráfica</b> de tu emisión.
      </Typography>
    ),
    icon: 'download-document',
  },
  {
    id: 'okDian',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Cumple con la DIAN</b> y sálvate de multas.
      </Typography>
    ),
    icon: 'to-do-list',
  },
]

export const endFreeWorkerModalInfo = [
  {
    id: 'incomesCertification',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        <b>Descarga certificados de ingresos y retenciones</b> de todas tus
        personas.
      </Typography>
    ),
    icon: 'download-document',
  },
  {
    id: 'workCertificarion',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Genera <b>Certificados laborales</b> sin enredos.
      </Typography>
    ),
    icon: 'doc-ok',
  },
  {
    id: 'laborSettlement',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Liquidaciones laborales automáticas</b> en un dos por tres!
      </Typography>
    ),
    icon: 'finance-doc',
  },
]

export const endFreeSocialBenefitsModalInfo = [
  {
    id: 'automaticSocialBenefits',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        Prima, intereses a las cesantías y cesantías <b>automáticas.</b>
      </Typography>
    ),
    icon: 'invoice-document',
  },
  {
    id: 'oneClicArus',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Paga tus cesantías con <b>ARUS a un clic!</b>
      </Typography>
    ),
    icon: 'mouse-left-click',
  },
  {
    id: 'electronicPayrollSocialBenefits',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Emite nómina electrónica</b> de tus prestaciones sociales.
      </Typography>
    ),
    icon: 'documents',
  },
]
