import { Button } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'

import useElectronicPayrollFileService from 'utils/hooks/ElectronicPayroll/fileService'

const Resume = ({ electronicPayrollPeriodId }) => {
  const { downloadFileMutation } = useElectronicPayrollFileService()
  const { enqueueDownload } = useDownloadManager()

  const { openPremiumFeatureModal, showPremiumFeatures } = usePremiumFeature()

  const handleDownload = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    enqueueDownload({
      name: 'resumen de nómina electrónica',
      fileCode: 'summary_electronic_payroll',
      firebasePath: `/electronic_payroll/${electronicPayrollPeriodId}/electronic_payroll_summary`,
      service: () =>
        downloadFileMutation.mutateAsync({
          mutationMethod: 'GET',
          mutationKey: 'electronicPayrollSummary',
          electronicPayrollPeriodId,
        }),
    })
  }

  return (
    <Button
      onClick={handleDownload}
      endIcon={<PremiumFeatureIcon />}
      style={{ marginRight: 8 }}
    >
      Descargar resumen
    </Button>
  )
}

export default Resume
