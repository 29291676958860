import { getApiURL, handleFetchResponse } from 'services/httpService'

export const login = async ({ email, password }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/oauth/token',
  })

  const data = {
    grant_type: 'password',
    email,
    password,
  }

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  const handledResponse = await handleFetchResponse(response)

  return handledResponse
}

export const requestAuth = async (clientId, tokenType, accessToken) => {
  const url = getApiURL({
    pathname: '/oauth/authorize',
    withoutCompany: true,
    searchParams: { client_id: clientId },
  })

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `${tokenType} ${accessToken}`,
    },
  })

  const handledResponse = await handleFetchResponse(response)
  return handledResponse
}

export default {
  login,
  requestAuth,
}
