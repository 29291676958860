const SOCIAL_BENEFIT_INFORMATION = {
  service_bonus: [
    'La prima se verá reflejada en las colillas de pago de tus empleados en este periodo. Podrás descargar tu resumen de nómina con la prima incluida.',
    'Tendrás un archivo para pago en banco de solo la prima, solo la nómina y la prima + la nómina.',
    'La liquidación de tu prima se incluirá en el archivo de pago de seguridad social de este mes.',
  ],
  severance: [
    'Ahora las cesantías se verán reflejada en las colillas de pago de tus empleados en este periodo. Podrás descargar tu resumen de nómina con las cesantías incluida.',
    'Tendrás un archivo para pago en banco de solo las cesantías, solo la nómina y las cesantías + la nómina.',
    'La liquidación de las cesantías se incluirá en el archivo de pago de seguridad social de este mes.',
  ],
  severance_interests: [
    'Ahora los intereses a las cesantías se verán reflejadas en las colillas de pago de tus empleados en este periodo. Podrás descargar tu resumen de nómina con los intereses a las cesantías incluidos.',
    'Tendrás un archivo para pago en banco de solo los intereses a las cesantías, solo la nómina y los intereses a las cesantías + la nómina.',
    'La liquidación de los intereses a las cesantías se incluirá en el archivo de pago de seguridad social de este mes.',
  ],
  decree_376: [
    'El archivo de pago de seguridad social de este mes incluirá los aportes de tu empresa según el decreto 376.',
    'Si seleccionaste la opción de pago de Empresa y Empleado, el valor de los aportes correspondientes a tus empleados activos será descontado de la nómina de este periodo.',
  ],
}

export default SOCIAL_BENEFIT_INFORMATION
