import { useState } from 'react'

import { Box } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useWorkerService from 'utils/hooks/worker/workerService'

import AffiliateInfoCard from './atoms/AffiliateInfoCard'
import AffiliationStepper from './atoms/AffiliationStepper'
import CertificateDownloadCard from './atoms/CertificateDownloadCard'
import ContactExpertCard from './atoms/ContactExpertCard'
import EstimatedDaysCard from './atoms/EstimatedDaysCard'
import InProcessModalHeader from './atoms/ModalHeader'
import {
  getAffiliationEntities,
  getCertificatesData,
  hasAnyDocumentReady,
} from './helpers'

const InProcessModal = ({ state = {}, handleClose }) => {
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false)
  const { enqueueDownload } = useDownloadManager()
  const companyId = getCompanyId()
  const { workerId } = state
  const workerQueryKey = ['getWorkerById', workerId]
  const affiliationQueryKey = ['getAffiliationById', workerId, companyId]

  const { workerQuery } = useWorkerService({
    serviceParams: { queryKey: workerQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId) && state.open,
    },
  })

  const { affiliationsQuery, affiliationsMutation } = useAffiliationsService({
    serviceParams: { queryKey: affiliationQueryKey, workerId, companyId },
    queryOptions: {
      enabled: Boolean(workerId) && state.open,
    },
  })

  const worker = workerQuery?.data || {}
  const affiliation = affiliationsQuery?.data || {}

  const affiliationEntities = getAffiliationEntities(
    affiliation.certificates_data
  )

  const certificatesData = getCertificatesData(affiliationEntities)

  const downloadFiles = () => {
    setIsDownloadingFiles(true)
    enqueueDownload({
      name: 'certificados de afiliación',
      fileCode: 'summary_files',
      namePlural: true,
      firebasePath: `affiliations/${affiliationsQuery?.data?.id}/summary_files`,
      service: () =>
        affiliationsMutation.mutateAsync({
          mutationMethod: 'GET',
          mutationKey: 'getAffiliationCertificates',
          workerId,
          async: true,
        }),
      callback: () => setIsDownloadingFiles(false),
    })
  }

  const affiliationCompleted = affiliation.status === 'approved'

  return (
    <Modal
      open={state.open}
      onCancel={handleClose}
      onOk={downloadFiles}
      okText="Descargar certificados"
      cancelText="Cerrar"
      disableOkButton={
        !hasAnyDocumentReady(affiliationsQuery?.data?.certificates_data)
      }
      fullWidthButtonsOnMobile
      paperSx={{
        maxWidth: '58rem',
      }}
      contentWrapperSx={{ paddingTop: '0 !important' }}
      isLoading={isDownloadingFiles}
      loadingText="Descargando..."
    >
      {workerQuery.isLoading || affiliationsQuery?.isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <InProcessModalHeader
            worker={worker}
            handleClose={handleClose}
            affiliationCompleted={affiliationCompleted}
          />
          <Box
            sx={(theme) => ({
              display: 'grid',
              alignItems: 'center',
              gap: theme.spacing(3),
              marginLeft: 'auto',
              marginRight: 'auto',
              gridTemplateColumns: '1fr 1fr',
              width: '80%',
              marginTop: theme.spacing(7),
              ...(affiliationCompleted && {
                gridTemplateColumns: '1fr',
                width: '50%',
              }),
              [theme.breakpoints.down('laptop')]: {
                width: '100%',
                ...(affiliationCompleted && {
                  width: '60%',
                }),
              },
              [theme.breakpoints.down('tablet')]: {
                gridTemplateColumns: '1fr',
                width: '85%',
              },
            })}
          >
            <AffiliateInfoCard worker={worker} />
            {affiliationCompleted ? null : (
              <EstimatedDaysCard affiliation={affiliation} />
            )}
          </Box>
          <AffiliationStepper
            certificates={affiliation.certificates_data}
            createdAt={affiliation.created_at}
            affiliationCompleted={affiliationCompleted}
          />
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: theme.spacing(3),
              marginTop: theme.spacing(4),
              [theme.breakpoints.down('tablet')]: {
                gridTemplateColumns: '1fr',
              },
            })}
          >
            {certificatesData?.map(
              ({
                iconName,
                provider_type: providerType,
                backgroundColor,
                institution_name: institutionName,
                completed,
                document_url: documentUrl,
                backgroundColorVariant,
              }) => (
                <CertificateDownloadCard
                  key={providerType}
                  iconName={iconName}
                  backgroundColor={backgroundColor}
                  institutionName={institutionName}
                  completed={completed}
                  documentUrl={documentUrl}
                  backgroundColorVariant={backgroundColorVariant}
                />
              )
            )}
          </Box>
          <ContactExpertCard handleClose={handleClose} />
        </>
      )}
    </Modal>
  )
}

export default InProcessModal
