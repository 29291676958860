import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import {
  Box,
  Collapse,
  Typography,
  tabClasses,
  useMediaQuery,
} from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Emoji from 'components/UI/Emoji'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Modal from 'components/UI/Modal/Modal'
import Spreadsheet, { removeEmptyRow } from 'components/UI/Spreadsheet'

import { getUserId } from 'utils/auth'
import { thinkingFace } from 'utils/emojis'
import useNoveltiesImportService from 'utils/hooks/payroll/noveltiesImport'
import useNotifications from 'utils/hooks/useNotifications'

import { useBulkNoveltiesAlert } from '../../BulkNoveltiesAlert/BulkNoveltiesAlertWrapper'
import { usePeriod } from '../../helpers'
import { backgroundBulkNoveltiesSocketHandler } from '../helpers'
import {
  ErrorInfoCard,
  getDirtyRows,
  useDataProcessing,
  validateIfDateValuesChange,
} from './helpers'

const BulkNoveltiesErrorsModal = ({
  state,
  handleClose,
  openBulkNoveltiesCommonInfoModal,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [errorDetail, setErrorDetail] = useState('')
  const [persistedErrorDetail, setPersistedErrorDetail] = useState('')
  const [showTransition, setShowTransition] = useState(true)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'))

  useEffect(() => {
    setShowTransition(true)
    if (errorDetail !== '') {
      setPersistedErrorDetail(errorDetail)
    }
  }, [errorDetail, showTransition])

  const queryClient = useQueryClient()
  const { bulkUploadResult } = state

  const {
    failureCases,
    newBulkUploadResult,
    extendedTitle,
    newFailureCases: {
      failureCasesIncomes,
      failureCasesLicenses,
      failureCasesOvertime,
      failureCasesNovelties,
      failureCasesDeductions,
      failurePersonalNote,
    },
    initialFailureCases: {
      initialFailureCasesIncomes,
      initialFailureCasesLicenses,
      initialFailureCasesOvertime,
      initialFailureCasesNovelties,
      initialFailureCasesDeductions,
      initialFailureCasesPersonalNote,
    },
  } = useDataProcessing(bulkUploadResult)

  const [rowsNovelties, setRowsNovelties] = useState(
    failureCasesNovelties?.rows
  )
  const [rowsDeductions, setRowsDeductions] = useState(
    failureCasesDeductions?.rows
  )
  const [rowsIncomes, setRowsIncomes] = useState(failureCasesIncomes?.rows)
  const [rowsOvertime, setRowsOvertime] = useState(failureCasesOvertime?.rows)
  const [rowsLicenses, setRowsLicenses] = useState(failureCasesLicenses?.rows)
  const [rowsPersonalNote, setRowsPersonalNote] = useState(
    failurePersonalNote?.rows
  )

  const userId = getUserId()
  const { period } = usePeriod()
  const { listenInBackground } = useUser()
  const { openBulkNoveltiesAlert } = useBulkNoveltiesAlert()
  const { noveltiesImportMutation } = useNoveltiesImportService()
  const { showInfoMessage, showErrorMessage } = useNotifications()

  if (newBulkUploadResult.overtime?.failure_cases) {
    newBulkUploadResult.overtime.failure_cases.rows = rowsOvertime
  }

  if (newBulkUploadResult.novelties?.failure_cases) {
    newBulkUploadResult.novelties.failure_cases.rows = rowsNovelties
  }

  if (newBulkUploadResult.incomes?.failure_cases) {
    newBulkUploadResult.incomes.failure_cases.rows = rowsIncomes
  }

  if (newBulkUploadResult.licenses?.failure_cases) {
    newBulkUploadResult.licenses.failure_cases.rows = rowsLicenses
  }

  if (newBulkUploadResult.deductions?.failure_cases) {
    newBulkUploadResult.deductions.failure_cases.rows = rowsDeductions
  }

  if (newBulkUploadResult.personal_note?.failure_cases) {
    newBulkUploadResult.personal_note.failure_cases.rows = rowsPersonalNote
  }

  const dirtyValues = getDirtyRows(
    bulkUploadResult.success,
    newBulkUploadResult
  )

  const handleSubmit = () => {
    noveltiesImportMutation.mutate(
      {
        mutationMethod: 'PUT',
        periodId: period.id,
        dataToSend: { updated_novelties_data: newBulkUploadResult },
      },
      {
        onSuccess: ({ message }) => {
          if (message) {
            showInfoMessage(
              'Estamos verificando la información. Esto puede tardar varios minutos. Te avisaremos cuando esté listo.'
            )

            listenInBackground(
              `users/${userId}/periods/${period.id}/fix_payroll_novelties`,
              (websocketResult, connectionRef) =>
                backgroundBulkNoveltiesSocketHandler(
                  websocketResult,
                  connectionRef,
                  showErrorMessage,
                  openBulkNoveltiesAlert,
                  queryClient
                )
            )

            handleClose()
          }
        },
      }
    )
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
    setErrorDetail('')
    setShowTransition(false)
  }

  const onCancel = () => {
    handleClose()
    openBulkNoveltiesCommonInfoModal('confirm_close_errors', {
      to: 'BulkNoveltiesErrorsModal',
      bulkUploadResult,
    })
  }

  const onSelectedCellChange = ({ row, column }) => {
    if (!row) {
      return setErrorDetail('')
    }

    const novelty = failureCases[activeTab].novelty
    const message = row?.error_details[column.key]
    const initialRowValues = removeEmptyRow(
      bulkUploadResult.success[novelty].failure_cases.rows
    )

    const cellFound = initialRowValues.find(
      (initialRow) => row?.worker_id === initialRow.worker_id
    )
    const dateValuesChange = validateIfDateValuesChange(
      column.key,
      cellFound,
      row
    )

    if (
      cellFound[column.key] !== row[column.key] ||
      dateValuesChange ||
      !message
    ) {
      return setErrorDetail('')
    }

    setErrorDetail(message)
  }

  return (
    <Modal
      open
      okText="Cargar"
      header={
        <Typography variant="h2">
          ¡Ohhhhh, Yisus! Algo pasó con el archivo que subiste{' '}
          <Emoji code={thinkingFace} />
        </Typography>
      }
      onOk={handleSubmit}
      onCancel={onCancel}
      onCloseModal={onCancel}
      paperSx={{ maxWidth: isDesktop ? '67rem' : '75%' }}
      isLoading={noveltiesImportMutation.isLoading}
      disableOkButton={dirtyValues.length > 0}
      adornment="spring"
    >
      <Typography variant="body1" color="black.dark">
        ¡Ahora puedes seleccionar las celdas resaltadas en rojo para visualizar
        el detalle de cada error! Con nuestra nueva funcionalidad, puedes
        corregir los errores directamente desde esta ventana. Recuerda que es
        necesario realizar todas las correcciones para que nuestro sistema te
        permita cargar tus novedades.
      </Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          margin: theme.spacing(4, 0, 3, 0),
        })}
      >
        {extendedTitle.show ? (
          <>
            <Typography variant="h6">{extendedTitle.label}</Typography>
          </>
        ) : (
          <RoundedTabs
            value={activeTab}
            tabsConfig={failureCases}
            onChange={handleChangeTab}
            sx={{
              width: 'fit-content',
              [`& .${tabClasses.root}`]: {
                minWidth: 'fit-content',
              },
            }}
          />
        )}
      </Box>

      <ErrorInfoCard
        novelty={failureCases[activeTab].extendedLabel}
        quantity={failureCases[activeTab].failure_cases_count}
      />

      {failureCases[activeTab].novelty === 'overtime' ? (
        <Spreadsheet
          columns={failureCasesOvertime.columns}
          rows={rowsOvertime}
          onRowsChange={setRowsOvertime}
          initialRows={initialFailureCasesOvertime.rows || []}
          onSelectedCellChange={onSelectedCellChange}
        />
      ) : null}

      {failureCases[activeTab].novelty === 'novelties' ? (
        <Spreadsheet
          columns={failureCasesNovelties.columns}
          rows={rowsNovelties}
          onRowsChange={setRowsNovelties}
          topSummaryRows={[{}]} // This prop adds a top row for initial and final date header titles
          initialRows={initialFailureCasesNovelties.rows || []}
          onSelectedCellChange={onSelectedCellChange}
        />
      ) : null}

      {failureCases[activeTab].novelty === 'licenses' ? (
        <Spreadsheet
          columns={failureCasesLicenses.columns}
          rows={rowsLicenses}
          onRowsChange={setRowsLicenses}
          topSummaryRows={[{}]} // This prop adds a top row for initial and final date header titles
          initialRows={initialFailureCasesLicenses.rows || []}
          onSelectedCellChange={onSelectedCellChange}
        />
      ) : null}

      {failureCases[activeTab].novelty === 'incomes' ? (
        <Spreadsheet
          columns={failureCasesIncomes.columns}
          rows={rowsIncomes}
          onRowsChange={setRowsIncomes}
          initialRows={initialFailureCasesIncomes.rows || []}
          onSelectedCellChange={onSelectedCellChange}
        />
      ) : null}

      {failureCases[activeTab].novelty === 'deductions' ? (
        <Spreadsheet
          columns={failureCasesDeductions.columns}
          rows={rowsDeductions}
          onRowsChange={setRowsDeductions}
          initialRows={initialFailureCasesDeductions.rows || []}
          onSelectedCellChange={onSelectedCellChange}
        />
      ) : null}

      {failureCases[activeTab].novelty === 'personal_note' ? (
        <Spreadsheet
          columns={failurePersonalNote.columns}
          rows={rowsPersonalNote}
          onRowsChange={setRowsPersonalNote}
          initialRows={initialFailureCasesPersonalNote.rows || []}
          onSelectedCellChange={onSelectedCellChange}
        />
      ) : null}

      {showTransition ? (
        <Collapse in={errorDetail !== ''}>
          <Box>
            <Alert sx={(theme) => ({ marginTop: theme.spacing(2) })}>
              <Typography variant="lead1">
                {`Error en la carga de ${failureCases[activeTab].extendedLabel}`}
              </Typography>
              <Typography>{persistedErrorDetail}</Typography>
            </Alert>
          </Box>
        </Collapse>
      ) : null}
    </Modal>
  )
}

export default BulkNoveltiesErrorsModal
