import { Typography } from '@mui/material'

import { formatDateRange, formatDisplayDate } from 'utils/dateTime'
import { formatNumber } from 'utils/format'
import { roundDecimals } from 'utils/number'

export default [
  {
    Header: 'Periodo',
    accessor: 'initial_day',
    Cell: ({ row }) => (
      <>
        {row.original.previous_holidays !== 0 ? (
          <Typography>{formatDisplayDate(row.original.end_day)}</Typography>
        ) : null}
        {row.original.termination ? (
          <Typography>
            Liquidación empleado
            <br />
            {formatDisplayDate(row.original.end_day)}
          </Typography>
        ) : null}
        {!row.original.termination && !row.original.previous_holidays ? (
          <Typography>
            {formatDateRange(row.original.initial_day, row.original.end_day)}
          </Typography>
        ) : null}
      </>
    ),
    customWidth: '160px',
    alignHeader: 'left',
    alignCell: 'left',
  },
  {
    Header: 'Días hábiles de vacaciones disfrutadas o compensadas en dinero',
    accessor: 'enjoyed_holidays',
    Cell: ({ row }) =>
      formatNumber(roundDecimals(row.original.enjoyed_holidays)),
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    Header: 'Vacaciones acumuladas en el periodo',
    accessor: 'period_holidays',
    Cell: ({ row }) =>
      formatNumber(roundDecimals(row.original.period_holidays)),
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    Header: 'Vacaciones acumuladas al final del periodo',
    accessor: 'accumulated_holidays',
    Cell: ({ row }) =>
      formatNumber(roundDecimals(row.original.accumulated_holidays)),
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    Header: 'Comentario',
    Cell: ({ row }) => {
      const { previous_holidays, termination, user_email } = row.original

      if (previous_holidays !== 0) {
        return (
          <Typography>
            Ajuste manual (Valor anterior{' '}
            {formatNumber(roundDecimals(previous_holidays))}) por {user_email}
          </Typography>
        )
      } else if (termination) {
        return <Typography>Vacaciones pagadas en liquidación</Typography>
      } else {
        return <Typography>Generado automáticamente</Typography>
      }
    },
    alignHeader: 'left',
    alignCell: 'left',
  },
]
