import { Box, Typography } from '@mui/material'

const HeaderCell = ({
  children,
  sx,
  mobileOnly = false,
  desktopOnly = false,
  alignX = 'left',
  alignY = 'top',
}) => {
  return (
    <Box
      sx={[
        (theme) => ({
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          ...(alignX === 'right' && {
            justifyContent: 'flex-end',
            textAlign: 'right',
          }),
          ...(alignY === 'center' && {
            alignItems: 'center',
          }),
          [theme.breakpoints.down('tablet')]: {
            ...(desktopOnly && {
              display: 'none',
            }),
          },
          [theme.breakpoints.up('tablet')]: {
            ...(mobileOnly && {
              display: 'none',
            }),
          },
        }),
        sx,
      ]}
    >
      <Typography variant="lead1">{children}</Typography>
    </Box>
  )
}

export default HeaderCell
