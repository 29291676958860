import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const DeductionsAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openDeductionsModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={payroll.details?.deductions?.value}
      onClick={() => openDeductionsModal('deductions')}
      editable={period.editable && !payroll.locked}
      dataCy="add-deductions"
    />
  )
}

export default DeductionsAction
