import { Box } from '@mui/material'

import Emoji from 'components/UI/Emoji'

import { flexedBiceps, grinningFaceWithSweat, sparkles } from 'utils/emojis'
import { isDev } from 'utils/general'

import computerMan from 'assets/images/views/common/computer-man.png'

import ErrorPageAtom from './Atoms/ErrorPage'

const ErrorPage = ({ error, resetError }) => {
  const handleResetError = () => {
    if (resetError) {
      resetError()
    }
  }

  return (
    <ErrorPageAtom
      title={
        <>
          No eres tú, somos nosotros <Emoji code={grinningFaceWithSweat} />
        </>
      }
      description={
        <>
          Parece que algo se nos escapó... Pero ¡no te preocupes! Ya estamos
          trabajando para solucionarlo. <Emoji code={flexedBiceps} />
          <br />
          Gracias por tu paciencia. <Emoji code={sparkles} />
        </>
      }
      image={
        <Box
          component="img"
          src={computerMan}
          alt=""
          width={256}
          height={328}
        />
      }
      details={isDev ? error.stack : null}
      handleResetError={handleResetError}
    />
  )
}

export default ErrorPage
