import { forwardRef } from 'react'

import {
  Box,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  linearProgressClasses,
} from '@mui/material'

import Icon from 'components/UI/Icon'

const LoadedFileContent = forwardRef(
  function LoadedFileContentForwardRef(props, inputRef) {
    const { handleDeleteFile, fileName, value, hideRemoveButton = true } = props

    return (
      <Box
        ref={inputRef}
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'space-between',
          gap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            rowGap: theme.spacing(0.3),
            flexDirection: 'column',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          })}
        >
          <Typography variant="tiny" noWrap>
            {value?.name || fileName || ''}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={100}
            sx={(theme) => ({
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: theme.palette.primary.dark,
              },
            })}
          />
        </Box>
        {!hideRemoveButton ? (
          <IconButton
            size="small"
            aria-label="Delete document"
            onClick={handleDeleteFile}
            sx={(theme) => ({
              padding: 0,
              color: theme.palette.black.dark,
              backgroundColor: 'transparent',
              width: 'auto',
              height: 'auto',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:focus': {
                width: 'auto',
                height: 'auto',
                border: 'none',
                backgroundColor: 'transparent',
              },
              '&::before': {
                content: 'none',
              },
            })}
          >
            <Icon name="close" sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        ) : null}
      </Box>
    )
  }
)

const LoadedFile = ({ ...props } = {}) => {
  return (
    <TextField
      InputProps={{
        inputComponent: forwardRef(
          function InputComponentForwardRef(innerProps, inputRef) {
            return (
              <LoadedFileContent ref={inputRef} {...innerProps} {...props} />
            )
          }
        ),
      }}
    />
  )
}

export default LoadedFile
