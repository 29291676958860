import Modal from 'components/UI/Modal/Modal'

import { isWorker } from 'utils/auth'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'

import LaborContent from './LaborContent'

const LaborCertificateModal = ({ state, handleClose }) => {
  const { companyConfigurationQuery } = useCompanyConfigurationService({
    serviceParams: { queryKey: 'companyConfiguration' },
    queryOptions: { enabled: !isWorker() },
  })

  const companyConfigurationData = companyConfigurationQuery?.data

  return (
    <Modal
      open={state.open}
      header={'Certificado Laboral'}
      onCancel={handleClose}
      hideFooter
      paperSx={{
        width: '100%',
        maxWidth: '44.5rem',
      }}
    >
      <LaborContent
        worker={state?.worker}
        handleClose={handleClose}
        companyConfigurationData={companyConfigurationData}
      />
    </Modal>
  )
}

export default LaborCertificateModal
