import * as yup from 'yup'

import { alpha } from '@mui/material'

import Icon from 'components/UI/Icon'

import { formatCurrency } from 'utils/format'

import amex from 'assets/images/views/subscription/amex.svg'
import dinersclub from 'assets/images/views/subscription/dinersclub.svg'
import automaticDebit from 'assets/images/views/subscription/druo.svg'
import mastercard from 'assets/images/views/subscription/mastercard.svg'
import pse from 'assets/images/views/subscription/pse.svg'
import visa from 'assets/images/views/subscription/visa.svg'

export const getSubscriptionStatusConfiguration = ({
  isPendingPayment,
  isActive,
  isCanceled,
  isValidDate,
  themeInstance,
}) => {
  if (isPendingPayment) {
    return {
      label: 'Pendiente por débito',
      icon: null,
      color: themeInstance.palette.info.dark,
      backgroundColor: alpha(themeInstance.palette.info.main, 0.2),
    }
  }

  if (isCanceled && isValidDate)
    return {
      label: 'Suscripción cancelada',
      icon: <Icon name="close" fontSize="small" basic />,
      color: themeInstance.palette.error.dark,
      backgroundColor: alpha(themeInstance.palette.error.main, 0.2),
    }

  if (isCanceled && !isValidDate)
    return {
      label: 'Cancelado',
      icon: <Icon name="close" fontSize="small" basic />,
      color: themeInstance.palette.error.dark,
      backgroundColor: alpha(themeInstance.palette.error.main, 0.2),
    }

  if (!isActive && !isValidDate)
    return {
      label: 'Inactivo',
      icon: <Icon name="close" fontSize="small" basic />,
      color: themeInstance.palette.complementary2.dark,
      backgroundColor: alpha(themeInstance.palette.complementary2.main, 0.2),
    }

  return {
    label: 'Activo',
    icon: <Icon name="approved-checked" fontSize="small" basic />,
    color: themeInstance.palette.primary.dark,
    backgroundColor: alpha(themeInstance.palette.primary.main, 0.2),
  }
}

export const getItems = (planData = {}, subscriptionData = {}) => {
  const items = []
  const isAnually = subscriptionData.type === 'year'
  const { distributor } = planData.subscription_value?.discounts || {}

  const planSubscriptionValue = isAnually
    ? planData.subscription_value?.yearly || {}
    : planData.subscription_value?.monthly || {}

  const monthlyBase = planData?.monthly_value
  const isExonerated = subscriptionData?.status === 'exonerated_payment'
  const perWorkerPlan = subscriptionData?.plan_category === 'per_worker_plan'
  const pricePerWorker = planData?.monthly_extra_worker_value

  const workersTotal =
    pricePerWorker * subscriptionData?.payrolls_size * (isAnually ? 12 : 1)

  const subtotal = planSubscriptionValue.subtotal + workersTotal

  const total = subscriptionData?.partner
    ? subscriptionData?.month_value
    : planSubscriptionValue.total

  if (!subscriptionData.partner && !isExonerated) {
    items.push({
      id: 'modality',
      label: 'Modalidad',
      value: isAnually ? 'Anual' : 'Mensual',
    })

    items.push({
      id: 'month_base',
      label: `Costo mensual ${perWorkerPlan ? 'base' : ''}`,
      value: formatCurrency(monthlyBase || 0),
    })

    if (perWorkerPlan) {
      items.push({
        id: 'worker_base',
        label: 'Costo por persona',
        value: formatCurrency(pricePerWorker || 0),
      })
    }

    if (planSubscriptionValue.workers_number > 0) {
      items.push({
        id: 'extra_workers',
        label: 'Personas activas',
        value: planSubscriptionValue.workers_number,
      })
    }

    if (planSubscriptionValue.subtotal_extra_workers_value) {
      items.push({
        id: 'extra_workers_subtotal',
        label: 'Subtotal personas',
        value: formatCurrency(
          planSubscriptionValue.subtotal_extra_workers_value || 0
        ),
      })
    }

    items.push({
      id: 'periodicity_subtotal',
      label: `Subtotal ${isAnually ? 'anualidad' : 'mensualidad'}`,
      value: formatCurrency(
        perWorkerPlan ? subtotal || 0 : planSubscriptionValue.subtotal || 0
      ),
    })
  }

  const isDiscount = planSubscriptionValue.extra_charge?.nominapp < 0

  // Aleluya payment adjustment
  if (planSubscriptionValue.extra_charge?.nominapp)
    items.push({
      label: <>{isDiscount ? 'Descuento (Aleluya)' : 'Recargo (Aleluya)'}</>,
      value: `${isDiscount ? '-' : ''} ${formatCurrency(
        Math.abs(planSubscriptionValue.extra_charge?.nominapp) || 0
      )}`,
      id: isDiscount ? 'aleluya_discount' : 'aleluya_extra_charge',
    })

  // Distributor discount value
  if (planSubscriptionValue.extra_charge?.distributor)
    items.push({
      label: (
        <>Descuento {distributor === 'Nominapp' ? 'Aleluya' : distributor}</>
      ),
      value: `- ${formatCurrency(
        Math.abs(planSubscriptionValue.extra_charge?.distributor) || 0
      )}`,
      id: 'distributor_discount',
    })

  items.push({
    label: 'Total',
    value: isExonerated ? 'Exonerado de pago' : formatCurrency(total || 0),
    id: 'total',
    bold: true,
  })

  return items
}

export const billingValidationSchema = yup.object({
  document_type: yup.string().nullable().required(),
  email: yup.string().email().nullable().required(),
  id_number: yup.string().trim().nullable().required(),
  name: yup.string().trim().nullable().required(),
  verification_digit: yup.string().when('document_type', {
    is: (documentType) => documentType === 'ni',
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
})

export const getBillingInitialValues = (values) => {
  return {
    document_type: null,
    email: null,
    id_number: null,
    name: null,
    verification_digit: null,
    ...(values || {}),
  }
}

export const paymentMethodLogo = {
  pse,
  'american-express': amex,
  'diners-club': dinersclub,
  mastercard,
  visa,
  automaticDebit,
}

export const getNonRejectedPayments = (payments = []) => {
  const successPayments = payments.filter(({ status }) => status !== 'rejected')

  return successPayments.slice(0, 6)
}
