import Icon from 'components/UI/Icon'

import usePremiumFeature from '../usePremiumFeature'

const PremiumFeatureIcon = ({
  fontSize = '1.4rem',
  isTableActionIcon = false,
  sx,
}) => {
  const { showPremiumFeatureIcon } = usePremiumFeature()

  if (!showPremiumFeatureIcon) return null

  return (
    <Icon
      name="crown"
      sx={[
        {
          fontSize: `${fontSize} !important`,
          ...(isTableActionIcon && {
            top: -8,
            right: 8.75,
            position: 'absolute',
            marginBottom: 0,
            fontSize: `0.9rem !important`,
          }),
        },
        sx,
      ]}
    />
  )
}

export default PremiumFeatureIcon
