import { useState } from 'react'

import { Box, OutlinedInput, Typography, useMediaQuery } from '@mui/material'

import messages from 'messages/payroll'

import MobileCell from '../common/CardGrid/MobileCell'
import Tooltip from '../common/Tooltip'

const CompensatedDays = ({ compensatedDays, handleCompensatedDays }) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  const [placeholder, setPlaceholder] = useState('0')

  return (
    <>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 1,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(1),
          })}
        >
          <Typography variant="h6" color="black.dark">
            Días compensados en dinero y NO disfrutados
          </Typography>
          {!isTabletUp ? (
            <Tooltip
              title={messages.compensated_days}
              disableInteractive={false}
            />
          ) : null}
        </Box>
      </MobileCell>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        alignY="center"
        sx={(theme) => ({
          gridColumn: '1 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 2,
          },
        })}
      >
        <OutlinedInput
          sx={{ width: '7.875rem', height: '2rem' }}
          type="number"
          placeholder={placeholder}
          value={(compensatedDays === 0 ? '' : compensatedDays) || ''}
          onFocus={() => setPlaceholder('')}
          onBlur={() => setPlaceholder('0')}
          onChange={handleCompensatedDays}
          margin="none"
          inputProps={{
            'data-cy': 'input-compensated_days',
            min: 0,
            max: 30,
            step: 1,
          }}
        />
      </MobileCell>
      {isTabletUp ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip
            title={messages.compensated_days}
            disableInteractive={false}
          />
        </Box>
      ) : null}
    </>
  )
}

export default CompensatedDays
