import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { passwordValid } from 'utils/regex'

yup.setLocale(yupLocaleES)

export const getInitialValues = (user) => ({
  name: user.name,
  email: user.email,
})

export const getValidationSchema = (initialValues) => {
  return yup.object().shape({
    name: yup.string().trim().nullable().required('El nombre es requerido'),
    email: yup
      .string()
      .email('Ingresa un email válido')
      .required('El email es requerido'),
    current_password: yup
      .string()
      .when(['email', 'password', 'password_confirmation'], {
        is: (email, password, password_confirmation) =>
          email !== initialValues.email ||
          password !== initialValues.password ||
          password_confirmation !== initialValues.password_confirmation,
        then: yup
          .string()
          .trim()
          .required('Ingresa tu contraseña actual para realizar los cambios'),
        otherwise: yup.string().trim(),
      }),
    password: yup
      .string()
      .min(8, 'Tu contraseña debe contener mínimo 8 caracteres.')
      .matches(
        passwordValid,
        'La contraseña debe incluir números, al menos una letra mayúscula, una minúscula, y caracteres especiales como "#$!*@%&"'
      )
      .max(128, 'Tu contraseña debe contener máximo 128 caracteres'),
    password_confirmation: yup.string().when('password', {
      is: (password) => password && password !== '',
      then: yup
        .string()
        .trim()
        .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir')
        .required('La confirmación de la contraseña es requerida'),
      otherwise: yup
        .string()
        .trim()
        .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir'),
    }),
  })
}
