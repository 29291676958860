import { usePopupState } from 'material-ui-popup-state/hooks'

import DropdownButton from 'components/UI/Button/Dropdown'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'

import { getPreviousPeriod } from 'utils/dateTime'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import periodService from 'services/payroll/periodService'

const PreviousPeriodAction = ({ lastPeriodDate = {} }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'createPreviousPeriodMenu',
  })
  const confirm = useConfirm()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()

  const { date, length } = lastPeriodDate

  const { start, end } = getPreviousPeriod(date, length) || {}

  const formatDateCopy = () => (
    <>
      <b>{start}</b>
      {` ${length === 'fortnightly' ? 'y del' : 'al'} `}
      <b>{end}</b>
    </>
  )

  const createPreviousPeriod = async (rangeType) => {
    try {
      const { message } = await periodService.createPreviousPeriod(rangeType)
      showSuccessMessage(message)
    } catch (error) {
      handleError(error)
    }
  }

  const handleNextPeriod = () => {
    confirm({
      title: 'Crear periodo siguiente',
      description: (
        <>
          Al continuar se creará el periodo siguiente al que tienes creado
          actualmente en tu cuenta. Solo puedes crear <b>1</b> periodo adicional
          al periodo actual por calendario
        </>
      ),
      okText: 'Generar periodo',
      onOk: () => createPreviousPeriod('next'),
    })
    popupState.close()
  }

  const handlePreviousPeriod = () => {
    confirm({
      title: 'Crear periodo anterior',
      description: (
        <>
          Al continuar se creará el periodo{' '}
          {start && end ? (
            <>
              de nómina del {formatDateCopy()} que representa el periodo
              anterior al último periodo de nómina activo que tiene tu compañía
              en Aleluya
            </>
          ) : (
            'inmediatamente anterior al último periodo de nómina activo que tiene tu compañía en Aleluya'
          )}{' '}
        </>
      ),
      okText: 'Generar periodo',
      onOk: () => createPreviousPeriod('previous'),
    })
    popupState.close()
  }

  const handleHistoricalPeriod = () => {
    confirm({
      title: 'Crear periodos históricos para prestaciones sociales',
      description: (
        <>
          Al continuar se crearán los periodos de nómina necesarios para que
          puedas calcular los promedios históricos de los pagos de prestaciones
          sociales <b>pendientes a la fecha.</b>
          <br />
          <br />
          <b>Nota:</b> tendrás un mes para realizar la carga de información
          histórica y luego estos periodos no podrán editarse
        </>
      ),
      okText: 'Generar histórico',
      onOk: () => createPreviousPeriod('historical'),
    })
    popupState.close()
  }

  const items = [
    {
      id: 'next_period',
      name: 'Periodo siguiente',

      iconPosition: 'left',
      onClick: handleNextPeriod,
    },
    {
      id: 'previous_period',
      name: 'Periodo anterior',
      iconPosition: 'left',
      onClick: handlePreviousPeriod,
    },
    {
      id: 'historical',
      name: 'Históricos',
      iconPosition: 'left',
      onClick: handleHistoricalPeriod,
    },
  ]

  return (
    <DropdownButton
      title="Generar nuevo periodo"
      popupState={popupState}
      options={items}
      endIcon={<Icon name="arrow-down" basic />}
      variant="outlined"
    />
  )
}

export default PreviousPeriodAction
