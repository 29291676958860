import { getUserEmail, getUserName, isWorker } from 'utils/auth'
import { getCompanyId } from 'utils/company'

const onZohoAPIReady = () => {
  if (window.$zoho && window.$zoho.salesiq) {
    if (window.$zoho.salesiq.visitor) {
      window.$zoho.salesiq.visitor.name(getUserName())
      window.$zoho.salesiq.visitor.email(getUserEmail())
      window.$zoho.salesiq.visitor.info({
        'Id de la empresa': getCompanyId(),
        'Es perfil empleado': isWorker(),
      })
    }
    if (window.$zoho.salesiq.floatwindow) {
      window.$zoho.salesiq.floatwindow.onlinetitle('¡Estamos para ayudarte! ❤️')
      window.$zoho.salesiq.floatwindow.offlinetitle('¡Volvemos pronto! 🤗')
      window.$zoho.salesiq.floatwindow.visible('hide')
      window.$zoho.salesiq.floatbutton.visible('hide')
    }
  }
}

export const zohoScript = {
  src: 'https://salesiq.zoho.com/widget',
  child: () => {
    if (window.$zoho && window.$zoho.salesiq) {
      window.$zoho.salesiq.ready(onZohoAPIReady)
    } else {
      window.$zoho = window.$zoho || {}
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: process.env.REACT_APP_ZOHO_WIDGET_CODE,
        values: {},
        ready: onZohoAPIReady,
      }
    }
  },
  async: true,
  defer: true,
}
