/**
 * This is taken from https://mantine.dev/hooks/use-debounced-state/
 */
import { useEffect, useRef, useState } from 'react'

const useDebouncedState = (value, wait, options = { leading: false }) => {
  const [_value, setValue] = useState(value)
  const timeoutRef = useRef(null)
  const leadingRef = useRef(true)

  const clearTimeout = () => window.clearTimeout(timeoutRef.current)

  useEffect(() => clearTimeout, [])

  const debouncedSetValue = (newValue) => {
    clearTimeout()
    if (leadingRef.current && options.leading) {
      setValue(newValue)
    } else {
      timeoutRef.current = window.setTimeout(() => {
        leadingRef.current = true
        setValue(newValue)
      }, wait)
    }
    leadingRef.current = false
  }

  return [_value, debouncedSetValue]
}

export default useDebouncedState
