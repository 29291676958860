import { useState } from 'react'
import { useQueries } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'

import useErrorHandler from 'utils/hooks/useErrorHandler'

import { getAllInformationService } from 'services/informationService'

const initialState = {
  open: false,
  partners: [],
  distributors: [],
}

const useNewCompanyModal = () => {
  const [state, setState] = useState(initialState)
  const { handleError } = useErrorHandler()
  const { user } = useUser()

  const [getDistributors, getPartners] = useQueries(
    user.role === 'accountant'
      ? ['distributors', 'partners'].map((option) => {
          return {
            queryKey: ['informationService', option],
            queryFn: () => getAllInformationService(option),
            queryOptions: {
              onError: (error) => handleError(error),
            },
          }
        })
      : []
  )

  const openModal = () => {
    const [distributors, partners] = [
      getDistributors?.data?.data,
      getPartners?.data?.data,
    ]

    setState({
      open: true,
      partners,
      distributors,
    })
  }

  const closeModal = () => {
    setState(initialState)
  }

  return {
    newCompanyModalState: state,
    openNewCompanyModal: openModal,
    closeNewCompanyModal: closeModal,
  }
}

export default useNewCompanyModal
