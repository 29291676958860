import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  downloadConsolidatePayrollSummaryFile,
  downloadNoveltiesSummaryFile,
  getCurrentPeriod,
  getLocationsByPeriod,
  getPeriodById,
  getPeriodContext,
  getPeriodDateOptions,
  getSeveranceFile,
  updateCurrentPeriod,
} from 'services/payroll/periodService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, periodId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'currentPeriod')
    return getCurrentPeriod({
      searchParams: {
        without_payrolls: true,
      },
    })

  if (currentQueryKey === 'periodById')
    return getPeriodById({
      periodId,
      searchParams: {
        without_payrolls: true,
      },
    })

  if (currentQueryKey === 'periodConfiguration')
    return getPeriodContext(periodId)

  if (currentQueryKey === 'locationsByPeriod')
    return getLocationsByPeriod({ periodId })

  if (currentQueryKey === 'periodOptions') return getPeriodDateOptions()

  return null
}

const getMutationFunction = (mutationData) => {
  const { periodId, mutationMethod, searchParams, period, mutationKey, dates } =
    mutationData

  if (mutationMethod === 'GET_SEVERANCE_FILE')
    return getSeveranceFile(periodId, searchParams)

  if (mutationMethod === 'PATCH') return updateCurrentPeriod(period)

  if (
    mutationMethod === 'GET' &&
    mutationKey === 'downloadConsolidatePayrollSummaryFile'
  )
    return downloadConsolidatePayrollSummaryFile(dates)

  if (
    mutationMethod === 'GET' &&
    mutationKey === 'downloadNoveltiesSummaryFile'
  )
    return downloadNoveltiesSummaryFile(dates)

  return null
}

const usePeriodService = ({
  serviceParams = { queryKey: 'currentPeriod' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restPeriodResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )
  const periodMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    periodQuery: {
      data,
      ...restPeriodResponse,
    },
    periodMutation,
  }
}

export default usePeriodService
