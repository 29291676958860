import { useLocation } from 'react-router-dom'

import { Box } from '@mui/material'

import UpdatePaymentMethodButton from 'components/Subscription/Atoms/UpdatePaymentMethodButton'
import useSubscription from 'components/Subscription/Atoms/useSubscription'

import auth, {
  isFreeCompany,
  isPremiumExpiredByCancellationCompany,
  isWorker,
} from 'utils/auth'

import SubscriptionAlertCard from '../Premium/SubscriptionAlertCard'
import { useUser } from '../UserContext/useUser'
import NavigationOptions from './NavigationOptions'
import PoweredBy from './PoweredBy'
import SidebarLogo from './SidebarLogo'
import useCloseDrawer from './useCloseDrawer'

const AppSidebarContent = ({ openUpdatePaymentMethodInfoModal }) => {
  const { subscription } = useSubscription()
  const { isAPartnerChild } = useUser()
  const { pathname } = useLocation()
  const handleCloseMobileDrawer = useCloseDrawer()

  const isNotPremium =
    isFreeCompany(subscription?.status, subscription?.payment_status) ||
    subscription?.status === 'premium_expired' ||
    isPremiumExpiredByCancellationCompany(subscription)

  const isNotPendingPayment = subscription?.payment_status !== 'pending'
  const isNotValidSubscription = isNotPremium && isNotPendingPayment

  const showSubscriptionAlertCard =
    !isWorker() &&
    isNotValidSubscription &&
    !isAPartnerChild &&
    subscription.payment_category !== 'distributor_payment'

  const showUpdatePaymentMethodButton =
    subscription?.payment_category === null &&
    !isAPartnerChild &&
    !['exonerated_payment', 'premium_trial'].includes(subscription?.status)

  const blockedViews =
    pathname.includes('/organizer') ||
    ['/companies', '/subscription', '/subscription_detail'].includes(pathname)

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(5, 0),
          display: {
            mobile: 'none',
            laptop: 'flex',
          },
          justifyContent: 'center',
        })}
      >
        <SidebarLogo />
      </Box>
      <Box
        className="custom-scrollbar"
        sx={(theme) => ({
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          paddingBottom: theme.spacing(2),
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        })}
      >
        <NavigationOptions />

        {!blockedViews ? (
          <>
            {showSubscriptionAlertCard ? (
              <SubscriptionAlertCard
                handleCloseMobileDrawer={handleCloseMobileDrawer}
              />
            ) : showUpdatePaymentMethodButton ? (
              <UpdatePaymentMethodButton
                openUpdatePaymentMethodInfoModal={
                  openUpdatePaymentMethodInfoModal
                }
                handleCloseMobileDrawer={handleCloseMobileDrawer}
              />
            ) : null}
          </>
        ) : null}
        {auth.isArusDist() ? <PoweredBy /> : null}
      </Box>
    </Box>
  )
}

export default AppSidebarContent
