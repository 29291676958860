import { Box, Paper, Typography, useMediaQuery } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import useHolisticPayrollCompanyService from 'utils/hooks/HolisticPayroll/CompayService'

import RocketWoman from 'assets/images/views/holisticPayroll/rocket_woman.png'

import { getAdminFinancialWellnessSectionsData } from './helpers'

const AdminFinancialWellness = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { company } = useUser()

  const companyId = getCompanyId()

  const { holisticPayrollCompanyDashboardQuery } =
    useHolisticPayrollCompanyService({
      serviceParams: {
        queryKey: ['getHolisticPayrollData', companyId],
      },
    })
  const holisticPayrollCompanyDashboardData =
    holisticPayrollCompanyDashboardQuery?.data

  const companyName = company?.name
  const sectionsData = getAdminFinancialWellnessSectionsData(
    holisticPayrollCompanyDashboardData
  )

  return (
    <Page
      documentTitle="Bienestar financiero"
      isLoading={holisticPayrollCompanyDashboardQuery.isLoading}
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Paper
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(3),
            borderRadius: '1rem',
            backgroundColor: 'accent1.main',
            columnGap: theme.spacing(4),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
            })}
          >
            <Typography variant="h2">
              Salud financiera de{' '}
              <Box component="span" color="accent4.main">
                {companyName}
              </Box>
            </Typography>
            <Typography variant="body1">
              A continuación, te presentamos un resumen de la salud financiera
              de tu equipo de trabajo. Así que siéntate y trae un tintico para
              revisar cada detalle.
            </Typography>
          </Box>
          {!isMobile ? (
            <Box
              component="img"
              alt="Personaje corriendo"
              src={RocketWoman}
              width={417}
              height={355}
              sx={(theme) => ({
                zIndex: 1,
                objectFit: 'contain',
                margin: theme.spacing(-13, -2, -13, -5),
              })}
            />
          ) : null}
        </Paper>

        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(7),
            marginTop: theme.spacing(9.25),
          })}
        >
          {sectionsData.map((section) => (
            <Box
              key={section.id}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
              })}
            >
              <Typography variant="h3">{section.title}</Typography>
              <Typography color="black.dark">{section.description}</Typography>
              {section.content}
            </Box>
          ))}
        </Box>
      </Box>
    </Page>
  )
}

export default AdminFinancialWellness
