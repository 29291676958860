import { Box } from '@mui/material'

import useGetCardsData from 'components/Settings/cards'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import SettingCard from 'components/UI/MaterialUI/Cards/CardEdit'
import Page from 'components/UI/Page/Page'

const Settings = () => {
  const { subscription } = useSubscription()
  const cardsData = useGetCardsData(subscription)

  return (
    <Page header="Configuración">
      <Box
        sx={(theme) => ({
          display: 'grid',
          gap: theme.spacing(2),
          gridGap: theme.spacing(2),
          gridTemplateColumns: '1fr',
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(22rem, 1fr))',
          },
        })}
      >
        {cardsData.map((card) =>
          card.hidden ? null : <SettingCard key={card.title} card={card} />
        )}
      </Box>
    </Page>
  )
}

export default Settings
