import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import EditButton from 'components/UI/Button/EditButton'
import Icon from 'components/UI/Icon'

import Item from './Item'

const WorkerSectionContainer = ({ item, isRegistrationFinished }) => {
  const {
    title,
    onClick,
    buttonCyLabel,
    itemsList,
    items,
    containerCyLabel,
    hiddenEdit,
    emptyState,
    showDivider = true,
    allowsWorkerEdition = false,
  } = item

  const { isWorker } = useUser()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(0, 4),
        ...(showDivider === false && {
          paddingBottom: theme.spacing(6),
        }),
      })}
      data-cy={containerCyLabel}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '3fr 1fr',
          alignItems: 'center',
          marginBottom: theme.spacing(5),
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <Typography variant="h4">{title}</Typography>
        {!hiddenEdit &&
        (!isWorker || allowsWorkerEdition) &&
        isRegistrationFinished ? (
          <EditButton
            onClick={onClick}
            sx={{
              justifySelf: 'flex-end',
            }}
            data-cy={buttonCyLabel}
          />
        ) : null}

        {!isRegistrationFinished &&
        !hiddenEdit &&
        (!isWorker || allowsWorkerEdition) ? (
          <Button
            variant="outlined"
            size="small"
            sx={(theme) => ({
              marginLeft: theme.spacing(6.3),
              height: '1.5rem',
              padding: {
                mobile: theme.spacing(0, 2),
                tablet: theme.spacing(0, 3),
              },
              justifySelf: 'flex-end',
            })}
            onClick={onClick}
            endIcon={!isMobile ? <Icon name="approved-checked" basic /> : null}
          >
            Completar
          </Button>
        ) : null}
      </Box>
      <Grid container spacing={4}>
        {!!itemsList &&
          itemsList.length > 0 &&
          itemsList.map((field, index) => {
            if (!field.hidden) {
              const itemKey =
                field.label &&
                `${field?.label
                  .toString()
                  .toLowerCase()
                  .replace(/ /g, '_')}_${index}`
              return <Item item={field} key={itemKey} />
            }
            return null
          })}
      </Grid>
      {showDivider ? (
        <Divider
          sx={(theme) => ({
            margin: theme.spacing(6, 0),
            backgroundColor: theme.palette.black.light,
          })}
        />
      ) : null}
      {items?.length > 0 &&
        items.map((field) => {
          if (!field.hidden) {
            return (
              <Box key={field.id}>
                <Typography
                  variant="lead1"
                  sx={(theme) => ({ marginBottom: theme.spacing(3) })}
                >
                  {field.label}
                </Typography>
                <Grid container spacing={2}>
                  {field.itemsList.map((subField, index) => {
                    const itemKey =
                      subField.label &&
                      `${subField?.label
                        .toString()
                        .toLowerCase()
                        .replace(/ /g, '_')}_${index}`

                    return <Item item={subField} key={itemKey} />
                  })}
                </Grid>
              </Box>
            )
          }
          return null
        })}
      {items?.length === 0 && emptyState ? emptyState : null}
    </Box>
  )
}

export default WorkerSectionContainer
