import {
  Box,
  IconButton,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  stepLabelClasses,
  useMediaQuery,
} from '@mui/material'

import Icon from '../Icon'

const getStepStatus = (index, current, progress) => {
  if (index === current) return 'current'
  if (index < progress) return 'completed'
  if (index > progress) return 'disabled'
  return 'pending'
}

const Steps = ({
  current = 0,
  progress,
  onChangeStep: onClickStep,
  stepsData,
  style,
  arrows = false,
  leftArrowProps = {},
  rightArrowProps = {},
  orientation = undefined,
  outlined = false,
  ...props
}) => {
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const { hidden: hideLeftArrow = false } = leftArrowProps
  const { hidden: hideRightArrow = false } = rightArrowProps

  const orientationStepper = smUp ? 'horizontal' : 'vertical'

  return (
    <Box
      sx={(theme) => ({
        ...(!arrows && {
          width: '100%',
        }),
        ...(arrows && {
          display: 'grid',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          rowGap: theme.spacing(2),
          columnGap: theme.spacing(3),
          width: '100%',
          padding: theme.spacing(0, 3),
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '32px 1fr 32px',
            padding: theme.spacing(3, 0),
            columnGap: theme.spacing(5),
          },
        }),
        ...(orientation === 'horizontal' && {
          gridTemplateColumns: '32px 1fr 32px',
          padding: theme.spacing(3, 0),
        }),
      })}
    >
      {arrows ? (
        <IconButton
          data-cy="previous-step"
          size="small"
          sx={{
            ...(hideLeftArrow && {
              visibility: 'hidden',
            }),
          }}
          {...leftArrowProps}
        >
          <Icon
            name="arrow-right-2"
            basic
            sx={{ fontSize: '1rem', transform: 'rotate(180deg)' }}
          />
        </IconButton>
      ) : null}
      <Stepper
        activeStep={current}
        style={style}
        orientation={orientation || orientationStepper}
        {...props}
        sx={(theme) => ({
          ...(arrows && {
            padding: 0,
          }),
          ...(outlined && {
            '& .MuiStepConnector-vertical': {
              padding: theme.spacing(0),
              marginLeft: theme.spacing(3.375),
              '& > *': {
                borderLeftWidth: '2px',
              },
            },
          }),
        })}
      >
        {stepsData.map((step, index) => {
          const { title, description } = step

          const stepStatus = getStepStatus(index, current, progress)

          const completed = stepStatus === 'completed'
          const disabled = stepStatus === 'disabled'

          const stepLabelProps = {}

          // this icon is only for not completed and not active step
          // to achieve the desired style of the components library
          if (!completed && current !== index)
            stepLabelProps.icon = (
              <Box
                sx={(theme) => ({
                  width: '1.5rem',
                  height: '1.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: '50%',
                  color: theme.palette.primary.main,
                  backgroundColor: 'transparent',
                  opacity: 0.32,
                  ...theme.typography.lead2,
                })}
              >
                {index + 1}
              </Box>
            )

          return (
            <Step
              key={title}
              disabled={disabled}
              completed={completed}
              sx={(theme) => ({
                ...(outlined && {
                  padding: theme.spacing(2, 6.75, 2, 1.5),
                  borderRadius: theme.spacing(1.25),
                  border: `2px solid ${theme.palette.white.dark}`,
                  backgroundColor: theme.palette.white.main,
                }),
                ...(outlined &&
                  stepStatus === 'current' && {
                    padding: theme.spacing(2, 6.75, 2, 1.5),
                    border: `2px solid ${theme.palette.primary.main}`,
                    borderRadius: theme.spacing(1.25),
                    backgroundColor: theme.palette.white.main,
                  }),
              })}
            >
              {onClickStep ? (
                <StepButton
                  onClick={() => !disabled && onClickStep(index)}
                  {...stepLabelProps}
                >
                  {description}
                </StepButton>
              ) : (
                <StepLabel
                  {...stepLabelProps}
                  sx={{
                    [`& .${stepLabelClasses.iconContainer}`]: {
                      ...(!description && {
                        paddingRight: 0,
                      }),
                    },
                  }}
                >
                  {description}
                </StepLabel>
              )}
            </Step>
          )
        })}
      </Stepper>
      {arrows ? (
        <IconButton
          data-cy="next-step"
          size="small"
          sx={{
            ...(hideRightArrow && {
              visibility: 'hidden',
            }),
          }}
          {...rightArrowProps}
        >
          <Icon name="arrow-right-2" basic sx={{ fontSize: '1rem' }} />
        </IconButton>
      ) : null}
    </Box>
  )
}

export default Steps
