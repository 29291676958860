import http, { getApiURL } from '../httpService'

export const getNoveltiesByPayroll = (payrollId) => {
  const url = getApiURL({ pathname: `/payrolls/${payrollId}/novelties` })

  return http.getV2(url)
}

export const putNovelties = (payrollId, novelties) => {
  const url = getApiURL({ pathname: `/payrolls/${payrollId}/novelties` })

  const requestData = {
    novelties,
  }

  return http.putV2(url, requestData)
}
