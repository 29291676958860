import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import CardGrid from '../common/CardGrid'
import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import HeaderRow from '../common/CardGrid/HeaderRow'
import MobileCell from '../common/CardGrid/MobileCell'
import GroupDivider from '../common/GroupDivider'

const Loans = ({ loanItems, handleChangeLoanValue }) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <CardGrid gridColumns="1fr 1fr">
      <HeaderRow>
        <HeaderCell
          sx={{
            gridColumn: 1,
          }}
          desktopOnly
        >
          Concepto
        </HeaderCell>
        <HeaderCell
          sx={{
            gridColumn: 2,
          }}
          desktopOnly
        >
          Valor
        </HeaderCell>
      </HeaderRow>
      {loanItems.map((loan, index) => {
        return (
          <Box key={loan.payroll_concept_id}>
            <GridRow gutterBottom={index < loanItems?.length - 1}>
              <HeaderCell
                sx={{
                  gridColumn: 1,
                  gridRow: 1,
                }}
                mobileOnly
              >
                Concepto
              </HeaderCell>
              <HeaderCell
                sx={{
                  gridColumn: 1,
                  gridRow: 2,
                }}
                mobileOnly
                alignY="center"
              >
                Valor
              </HeaderCell>
              <MobileCell
                alignX={isTabletUp ? 'left' : 'right'}
                sx={(theme) => ({
                  gridColumn: '2 / -1',
                  [theme.breakpoints.up('tablet')]: {
                    gridColumn: 1,
                  },
                })}
              >
                <Typography
                  variant="h6"
                  color="black.dark"
                  htmlFor={`inputLoan_${index}`}
                  component="label"
                >
                  {loan.name}
                </Typography>
              </MobileCell>
              <MobileCell
                alignX={isTabletUp ? 'left' : 'right'}
                alignY="center"
                sx={(theme) => ({
                  gridColumn: '2 / -1',
                  [theme.breakpoints.up('tablet')]: {
                    gridColumn: 2,
                  },
                })}
              >
                <TextField
                  id={`inputLoan_${index}`}
                  value={loan.value}
                  onChange={(e) => handleChangeLoanValue(e, index)}
                  InputProps={{
                    inputComponent: NumberFormatField,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    sx: {
                      maxWidth: '15rem',
                      height: '2rem',
                    },
                  }}
                  fullWidth={false}
                />
              </MobileCell>
            </GridRow>
            {index + 1 < loanItems?.length ? <GroupDivider /> : null}
          </Box>
        )
      })}
    </CardGrid>
  )
}

export default Loans
