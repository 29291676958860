import { useEffect } from 'react'

import { Box, Typography } from '@mui/material'

const CalendlyWidget = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Box
      className="calendly-inline-widget"
      data-url="https://calendly.com/d/ckqm-dp8-jb2/sesion-1-coaching-financiero?hide_gdpr_banner=1"
      sx={(theme) => ({
        minWidth: '20rem',
        height: '43.75rem',
        [theme.breakpoints.between('mobile', 'md')]: {
          marginTop: theme.spacing(4),
        },
      })}
    />
  )
}

const FinancialCoachingSessions = ({ workerName }) => {
  return (
    <>
      <Typography
        variant="h3"
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
        })}
      >
        Coach Financiero
      </Typography>

      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
      >
        <Typography variant="lead1" sx={{ color: 'accent4.main' }}>
          {workerName}
        </Typography>{' '}
        tu futuro se verá en HD con nuestro coach financiero. Anímate a
        solicitar una cita, dale con toda.
      </Typography>

      <CalendlyWidget />
    </>
  )
}

export default FinancialCoachingSessions
