import { useState } from 'react'

import { usePremiumActions } from 'components/App/Premium/helpers'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import ActivationModal from './Modals/ActivationModal'

const WorkerShowActivateButton = ({ isWorker, worker }) => {
  const [showActivationModal, setShowActivationModal] = useState(false)
  const { handlePayExtraWorkers } = usePremiumActions()
  const { subscription } = useSubscription()
  const hasToPayNewWorkers = !subscription?.additional_workers_info?.add_workers

  const handleWorkerActivation = () => {
    if (hasToPayNewWorkers && !worker?.active) {
      handlePayExtraWorkers()
    } else {
      setShowActivationModal(true)
    }
  }

  return !isWorker && !worker?.terminated ? (
    <>
      <Button
        sx={{ width: { mobile: 1, tablet: 'auto' } }}
        variant="outlined"
        size="large"
        endIcon={
          worker?.active ? (
            <Icon name="close" basic />
          ) : (
            <Icon name="approved-checked" basic />
          )
        }
        onClick={handleWorkerActivation}
      >
        {worker?.active ? 'Desactivar' : 'Activar'}
      </Button>
      {showActivationModal ? (
        <ActivationModal onCancel={() => setShowActivationModal(false)} />
      ) : null}
    </>
  ) : null
}

export default WorkerShowActivateButton
