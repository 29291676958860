import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box } from '@mui/material'

import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'
import CompensationFundList from 'components/UI/Formik/CommonFields/CompensationFundList'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getDirtyValues } from 'utils/form'
import useLocationService from 'utils/hooks/company/locationService'

import validationSchema from './validationSchema'

const WorkerFormFieldsPersonalLocationDialog = ({
  open,
  handleClose,
  initialLocation,
  submitCallback,
}) => {
  const queryClient = useQueryClient()

  const { locationMutation } = useLocationService({
    queryOptions: { enabled: false },
  })

  const mutateLocation = (values) => {
    const dirtyValues = getDirtyValues({}, values)

    locationMutation.mutate(
      {
        mutationMethod: 'POST',
        data: dirtyValues,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries('companyLocation')

          submitCallback(data.data)
          handleClose()
        },
      }
    )
  }

  const initialLocalLocation = {
    ...initialLocation,
    department: null,
    compensation_fund: null,
    city: null,
  }

  return (
    <Formik
      onSubmit={mutateLocation}
      initialValues={initialLocalLocation}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        const { values, handleSubmit } = formProps

        return (
          <Modal
            header="Agregar nueva sede"
            open={open}
            onOk={handleSubmit}
            onCancel={handleClose}
            okText="Guardar"
            isLoading={locationMutation.isLoading}
            disableBackdropClick={locationMutation.isLoading}
            disableEscapeKeyDown={locationMutation.isLoading}
            paperSx={{
              width: '100%',
              maxWidth: '40.5rem',
            }}
            dataCy="create-location-modal"
          >
            <Form>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                })}
              >
                <FormField
                  name="name"
                  label="Nombre"
                  placeholder="Nombre de la sede"
                  autoFocus
                />
                <DepartmentsList
                  name="department"
                  label="Departamento"
                  resetCompensationFundQuery
                />
                <CitiesList
                  name="city"
                  label="Ciudad"
                  department={values.department}
                />
                <CompensationFundList
                  name="compensation_fund"
                  label="C. de Compensación"
                  department={values.department}
                />
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default WorkerFormFieldsPersonalLocationDialog
