import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createCompanyComment,
  createPaymentAdjustment,
  deleteCompany,
  getCompanyFromOrganizer,
  getCompanyPaymentAdjustments,
  updateCompany,
  updateCompanyComment,
} from 'services/organizer/companyService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, companyId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getCompanyFromOrganizer')
    return getCompanyFromOrganizer(companyId)

  if (currentQueryKey === 'getCompanyPaymentAdjustments')
    return getCompanyPaymentAdjustments(companyId)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    companyId,
    company,
    paymentAdjustmentData,
    mutationKey,
    commentData,
    commentId,
  } = mutationData

  if (mutationMethod === 'POST' && mutationKey === 'createComment') {
    return createCompanyComment(companyId, commentData)
  }

  if (mutationMethod === 'PATCH' && mutationKey === 'updateComment') {
    return updateCompanyComment(companyId, commentData, commentId)
  }

  if (mutationMethod === 'POST')
    return createPaymentAdjustment(companyId, paymentAdjustmentData)

  if (mutationMethod === 'DELETE') return deleteCompany(companyId)

  if (mutationMethod === 'PATCH') return updateCompany(company)

  return null
}

const useCompanyService = ({
  serviceParams = { queryKey: 'getCompanyFromOrganizer' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restCompanyResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const companyMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    companyQuery: {
      data,
      ...restCompanyResponse,
    },
    companyMutation,
  }
}

export default useCompanyService
