import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'

import { Box, Divider, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import { isWorker } from 'utils/auth'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useCertificateService from 'utils/hooks/worker/certificate'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'
import { salaryTypeOptions } from 'utils/worker'

import * as routes from 'config/routes'

import {
  getSignatureData,
  laborModalInitialValues,
  laborValidationSchema,
} from './helpers'

const LaborContent = ({ worker, handleClose, companyConfigurationData }) => {
  const { certificateMutation } = useCertificateService({
    queryOptions: { enabled: false },
  })
  const downloadURI = useDownloadURI()

  const hasLaborCertificateSignature =
    companyConfigurationData?.has_labor_certificate_signature

  const laborCertificateData =
    companyConfigurationData?.labor_certificate_signature

  const signatureDataContent = getSignatureData(laborCertificateData)

  const handleSubmit = (values) => {
    certificateMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: isWorker()
          ? 'workerProfileLaborCertificate'
          : 'laborCertificate',
        workerId: worker?.id,
        options: values,
      },
      {
        onSuccess: ({ file }) => {
          downloadURI(file)
          trackEvent(integrationEvent.EMPLOYEE_LABOR_CERTIFICATE)
          handleClose()
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={laborModalInitialValues}
      validationSchema={laborValidationSchema}
    >
      {({ handleSubmit: onSubmit }) => {
        return (
          <Form>
            {!isWorker() ? (
              <>
                <Typography
                  sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                  color="black.dark"
                >
                  Selecciona el <b>Tipo de salario</b> para el certificado
                  laboral de la persona.
                </Typography>

                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: theme.spacing(2),
                      boxShadow: theme.shadows[5],
                      padding: theme.spacing(2, 3),
                      borderRadius: '1rem',
                      backgroundColor: !hasLaborCertificateSignature
                        ? 'accent2.light'
                        : 'white.black',
                      marginX: theme.spacing(3),
                    })}
                  >
                    <Typography variant="h4">
                      {!hasLaborCertificateSignature
                        ? '¡Ups! Aún no hay datos de tu firma'
                        : 'Datos de la firma'}
                    </Typography>
                    {hasLaborCertificateSignature ? (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography variant="lead1">
                            Imagen de la firma:
                          </Typography>
                          <Box
                            component="img"
                            src={laborCertificateData.signature_image}
                            sx={(theme) => ({
                              maxHeight: '5.5rem',
                              maxWidth: '10.25rem',
                              marginTop: theme.spacing(1),
                              objectFit: 'contain',
                            })}
                          />
                        </Box>
                        <Divider
                          color="black"
                          sx={(theme) => ({ margin: theme.spacing(2, 0, 2) })}
                        />
                        <Box
                          sx={(theme) => ({
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: theme.spacing(1),
                            [theme.breakpoints.up('tablet')]: {
                              rowGap: theme.spacing(0.5),
                              flexWrap: 'wrap',
                              maxHeight: '5rem',
                            },
                          })}
                        >
                          {signatureDataContent.map((signature) => {
                            if (signature.value) {
                              return (
                                <Box
                                  key={signature.id}
                                  sx={(theme) => ({
                                    display: 'flex',
                                    columnGap: theme.spacing(1),
                                  })}
                                >
                                  <Typography variant="lead1">
                                    {signature.key}:
                                  </Typography>
                                  <Typography>{signature.value}</Typography>
                                </Box>
                              )
                            }

                            return null
                          })}
                        </Box>
                      </Box>
                    ) : (
                      <Typography color="black.dark">
                        Ingresa ahora, agrega los datos de quien firma y crea{' '}
                        <b>¡certificados laborales personalizados!</b> Esto es
                        especialmente útil para demostrar la autenticidad de tus
                        documentos.
                      </Typography>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        component={Link}
                        variant="text"
                        endIcon={<Icon name="edit-pencil" />}
                        to={{
                          pathname: routes.SETTINGS_ADVANCED(),
                          search: '?tab=signature_documents',
                        }}
                        onClick={handleClose}
                      >
                        {hasLaborCertificateSignature ? `Editar` : 'Crear'}{' '}
                        datos de la firma
                      </Button>
                    </Box>
                  </Box>
                  <FormField
                    name="salary_type"
                    label="Tipo de salario"
                    variant="select"
                    options={salaryTypeOptions}
                    optional={false}
                  />
                </Box>
              </>
            ) : (
              <Box>
                <Typography
                  sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                  color="black.dark"
                >
                  Selecciona si deseas que tu certificado laboral especifique o
                  no tu salario.
                </Typography>
                <FormField
                  name="salary_type"
                  label="Tipo de salario"
                  variant="select"
                  options={salaryTypeOptions}
                  optional={false}
                />
              </Box>
            )}

            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: theme.spacing(7),
                rowGap: theme.spacing(2),
                [theme.breakpoints.up('tablet')]: {
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: theme.spacing(4),
                  columnGap: theme.spacing(2),
                  rowGap: 0,
                },
              })}
            >
              <Button
                onClick={onSubmit}
                sx={(theme) => ({ paddingX: theme.spacing(5) })}
                loading={certificateMutation.isLoading}
                disabled={!isWorker() ? !hasLaborCertificateSignature : false}
              >
                Descargar
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={(theme) => ({
                  paddingX: theme.spacing(5),
                })}
              >
                Cancelar
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LaborContent
