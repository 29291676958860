import { useCallback } from 'react'

import { PAYROLL_ACTION_TYPES } from './usePayrollConfiguration'

const useNoveltiesModal = ({ payrollState, payrollDispatch }) => {
  const openNoveltiesModal = useCallback(
    (noveltyConcept) => {
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SHOW_MODAL,
        modalKeys: {
          noveltyTypeModal: false,
          noveltiesModal: true,
        },
      })
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SET_MODAL_CONFIGURATION,
        config: {
          noveltyConcept,
        },
      })
    },
    [payrollDispatch]
  )

  const closeNoveltiesModal = () =>
    payrollDispatch({
      type: PAYROLL_ACTION_TYPES.CLOSE_MODAL,
      modalKeys: {
        noveltiesModal: false,
        noveltyTypeModal: true,
      },
    })

  return {
    noveltiesModalState: {
      open: Boolean(payrollState.noveltiesModal),
    },
    openNoveltiesModal,
    closeNoveltiesModal,
  }
}

export default useNoveltiesModal
