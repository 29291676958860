import { Box, Link as MuiLink, Typography } from '@mui/material'

import Alert from 'components/UI/MaterialUI/Alert/Alert'

import Tooltip from '../common/Tooltip'

const Info = () => {
  const titleHeader = (
    <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1) })}>
      Nota:
      <Tooltip
        title={
          <>
            <MuiLink
              href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-registrar-ingresos-recurrentes-a-tu-empleado"
              target="_blank"
              color="accent4.main"
              fontWeight={600}
            >
              Aquí
            </MuiLink>{' '}
            puedes encontrar cómo registrar ingresos o deducciones recurrentes.
          </>
        }
        disableInteractive={false}
      />
    </Box>
  )

  return (
    <Alert
      sx={(theme) => ({ borderRadius: theme.spacing(2) })}
      severity="info"
      icon={false}
      header={titleHeader}
    >
      <Typography variant="body2">
        Si deseas configurar préstamos o deducciones recurrentes para que se
        apliquen automáticamente en cada periodo, ingresa al perfil del empleado
        y entra a la pestaña Conceptos Recurrentes.
      </Typography>
      <br />
      <Typography variant="body2">
        El auxilio de conectividad es una medida temporal y transitoria del
        Gobierno en reemplazo del auxilio de transporte para empleados que
        reciben hasta dos salarios mínimos.
      </Typography>
    </Alert>
  )
}

export default Info
