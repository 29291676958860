import http, { getApiURL } from '../httpService'

export const getAllIntegration = () => {
  const url = getApiURL({ pathname: '/integrations' })

  return http.getV2(url)
}

export const updateIntegration = (integration) => {
  const url = getApiURL({ pathname: '/integrations' })

  const data = { integration }

  return http.putV2(url, data)
}

export default {
  getAllIntegration,
  updateIntegration,
}
