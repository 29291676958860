import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { Link, Typography, useMediaQuery } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { yupLocaleES } from 'utils/form'
import usePayrollService from 'utils/hooks/payroll/payroll'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { useNoveltyType, usePeriod, usePeriodAPI } from '../helpers'
import CardGrid from './common/CardGrid'
import GridRow from './common/CardGrid/GridRow'
import HeaderCell from './common/CardGrid/HeaderCell'
import HeaderRow from './common/CardGrid/HeaderRow'
import MobileCell from './common/CardGrid/MobileCell'

yup.setLocale(yupLocaleES)

const validationSchema = (periodType) => {
  if (periodType === 'monthly')
    return yup.object({
      worked_time: yup
        .number()
        .integer()
        .min(0)
        .max(29, 'El valor debe ser menor o igual a 29'),
    })

  return yup.object({
    worked_time: yup
      .number()
      .integer()
      .min(0)
      .max(15, 'El valor debe ser menor o igual a 15'),
  })
}

const WorkedDaysModal = ({ state, handleClose }) => {
  const { period } = usePeriod()
  const { payrollState } = useNoveltyType()
  const { updatePeriodCallback } = usePeriodAPI()
  const { workerName, workerPayroll } = payrollState
  const payrollId = workerPayroll.id
  const { handleError } = useErrorHandler()
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const columnsWidth = '1fr 1fr'

  const handleSubmit = (values, form) => {
    payrollMutation.mutate(
      {
        mutationMethod: 'PATCH',
        payrollId,
        data: {
          payroll: values,
        },
      },
      {
        onSuccess: ({ data }) => {
          updatePeriodCallback(data)
          handleClose()
        },
      },
      {
        onError: (error) => handleError(error, form),
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ worked_time: workerPayroll.worked_time }}
      validationSchema={validationSchema(period?.length || 'monthly')}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit: onSubmit } = formProps

        return (
          <Form>
            <Modal
              open={state.open}
              header={`¿Reportale los días trabajados a ${workerName}?`}
              onOk={onSubmit}
              okText="Guardar"
              onCancel={handleClose}
              isLoading={payrollMutation.isLoading}
              paperSx={{
                maxWidth: {
                  tablet: '45rem',
                  laptop: '48.5rem',
                },
              }}
              adornment="signature"
            >
              <Typography
                paragraph
                sx={(theme) => ({
                  marginBottom: theme.spacing(4),
                  color: theme.palette.black.dark,
                })}
              >
                Aquí podrás reportar los días efectivamente laborados por la
                persona en el periodo correspondiente. Recuerda que con estos
                días será realizada la liquidación de la nómina de la persona.
                Si tienes inquietudes ingresa a{' '}
                <Link
                  href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/contrato-a-tiempo-parcial-en-aleluya"
                  target="_blank"
                  color="accent4.main"
                  fontWeight={600}
                >
                  este artículo de nuestro centro de ayuda.
                </Link>
              </Typography>
              <CardGrid gridColumns={columnsWidth}>
                <HeaderRow>
                  <HeaderCell
                    sx={{
                      gridColumn: 1,
                    }}
                    desktopOnly
                  >
                    Concepto
                  </HeaderCell>
                  <HeaderCell
                    sx={{
                      gridColumn: 2,
                    }}
                    desktopOnly
                  >
                    # de días trabajados
                  </HeaderCell>
                </HeaderRow>
                <GridRow>
                  <HeaderCell
                    sx={{
                      gridColumn: 1,
                      gridRow: 1,
                    }}
                    mobileOnly
                  >
                    Concepto
                  </HeaderCell>
                  <HeaderCell
                    sx={{
                      gridColumn: 1,
                      gridRow: 2,
                    }}
                    mobileOnly
                    alignY="center"
                  >
                    # de días trabajados
                  </HeaderCell>
                  <MobileCell
                    alignX={isTabletUp ? 'left' : 'right'}
                    sx={(theme) => ({
                      gridColumn: '2 / -1',
                      [theme.breakpoints.up('tablet')]: {
                        gridColumn: 1,
                      },
                    })}
                  >
                    <Typography variant="h6" color="black.dark">
                      Días trabajados en el periodo
                    </Typography>
                  </MobileCell>
                  <MobileCell
                    alignX={isTabletUp ? 'left' : 'right'}
                    alignY="center"
                    sx={(theme) => ({
                      gridColumn: '2 / -1',
                      [theme.breakpoints.up('tablet')]: {
                        gridColumn: 2,
                      },
                    })}
                  >
                    <FormField
                      sx={{ width: '12rem', height: '2rem' }}
                      name="worked_time"
                      type="number"
                      fullWidth={false}
                    />
                  </MobileCell>
                </GridRow>
              </CardGrid>
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}

export default WorkedDaysModal
