import { useMutation } from 'react-query'

import { createDistributorSession } from 'services/auth/distributorService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, token } = mutationData

  if (mutationMethod === 'POST') return createDistributorSession(token)

  return null
}

const useDistributorSessionService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const distributorSessionMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    distributorSessionMutation,
  }
}

export default useDistributorSessionService
