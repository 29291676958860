const evaluateError = (error) => {
  const isChunkLoadError =
    error &&
    ((error.message && error.message.includes('Loading chunk')) ||
      error.name === 'ChunkLoadError')

  if (isChunkLoadError) return true

  return false
}

export default evaluateError
