import { useState } from 'react'

import { getPayrollConceptItemCell } from 'components/Period/common/helpers'

import OtherIncomes from '../Modals/OtherIncomes'

const CellOtherIncomes = ({
  bonusIncomes,
  index,
  electronicPayrollId,
  incomes,
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  return (
    <>
      {getPayrollConceptItemCell({
        buttonId: `overtime_${index}`,
        bonusIncomes,
        incomes,
        onClick: handleOpenModal,
      })}
      {showModal ? (
        <OtherIncomes
          showModal={showModal}
          handleClose={handleClose}
          electronicPayrollId={electronicPayrollId}
        />
      ) : null}
    </>
  )
}

export default CellOtherIncomes
