import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { generateWageBaseSalaryValidationSchema } from 'utils/worker'

yup.setLocale(yupLocaleES)

const wageBaseSalaryValidationSchema = generateWageBaseSalaryValidationSchema()

const schemaValidation = yup.object({
  name: yup.string().trim().nullable().required(),
  last_name: yup.string().trim().nullable().required(),
  document_type: yup.string().nullable().required(),
  term: yup.string().nullable().required(),
  contract_category: yup.string().nullable().required(),
  initial_day: yup.date().nullable().default(null).required(),
  end_day: yup
    .date()
    .nullable()
    .default(null)
    .when('term', {
      is: (term) => ['fixed', 'project'].includes(term),
      then: yup.date().nullable().default(null).required(),
    }),
  wage_category: yup.string().nullable().required(),
  base_salary: wageBaseSalaryValidationSchema,
})

export default schemaValidation
