import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import AddSigners from './AddSigners'

const PeopleToSign = ({ setPeopleToSign, peopleToSign }) => {
  const { values } = useFormikContext()

  return (
    <>
      <Typography
        color="black.dark"
        variant="body1"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        Si requieres que otra persona firme el documento, agrega los datos de
        las demás personas y nosotros nos encargaremos de notificarles en un
        2x3.
      </Typography>
      <AddSigners
        peopleToSign={peopleToSign}
        setPeopleToSign={setPeopleToSign}
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          margin: theme.spacing(3, 0, 0),
        })}
      >
        <FormField
          name="with_date_limit"
          variant="checkbox"
          checkboxLabel="Fecha límite para firmar el documento"
        />
        {values?.with_date_limit ? (
          <Box sx={(theme) => ({ margin: theme.spacing(2, 0, 0) })}>
            <FormField
              name="date_limit_to_sign"
              variant="datepicker"
              label="Selecciona la fecha límite"
              optional={false}
            />
          </Box>
        ) : null}
      </Box>
    </>
  )
}

export default PeopleToSign
