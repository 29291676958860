const NoWrap = ({ children, tag = 'span', ...props }) => {
  const Tag = tag // to convert to React element

  return (
    <Tag style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} {...props}>
      {children}
    </Tag>
  )
}

export default NoWrap
