import { Box, IconButton, Tooltip, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import {
  PERIOD_PAYROLL_VIEW_DETAILS,
  PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS,
  WORKER_SHOW,
} from 'config/routes'

import ExpandButton from './ExpandButton'

const WorkerExpander = ({
  workerId,
  payrollId,
  onExpand,
  expanded,
  workerName,
  periodId,
  payPreviousPayroll,
  showSummary,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '12.5rem',
        ...(expanded && {
          padding: theme.spacing(1.5, 0),
        }),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={(theme) => ({
            marginRight: theme.spacing(0.5),
          })}
        >
          <Link to={WORKER_SHOW(workerId)} color="complementary1.main">
            <Typography
              variant="small"
              color="complementary1.main"
              fontWeight={600}
            >
              {workerName}
            </Typography>
          </Link>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1.5rem 1.5rem',
            columnGap: theme.spacing(0.7),
          })}
        >
          <Tooltip
            title={expanded ? 'Contraer' : 'Expandir'}
            disableInteractive
            enterDelay={300}
          >
            <ExpandButton onClick={onExpand} expanded={expanded} />
          </Tooltip>
          {showSummary ? null : (
            <Tooltip title="Ver cálculos" disableInteractive enterDelay={300}>
              <IconButton
                component={Link}
                size="small"
                target="_blank"
                color="complementary1"
                to={
                  payPreviousPayroll
                    ? PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS(periodId, payrollId)
                    : PERIOD_PAYROLL_VIEW_DETAILS(payrollId)
                }
              >
                <Icon name="eye" basic fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default WorkerExpander
