import useModals from 'components/App/ModalsManager/useModals'

import TransactionResponseModal from './TransactionResponseModal'

const useTransactionResponseModal = () => {
  const modals = useModals()

  const handleClose = () => {
    modals.closeAll()
  }

  const openTransactionResponseModal = ({
    subscriptionTypeValidators = {},
    transactionStatus = 'APPROVED',
    isExtraworkersPayment = false,
    downgradeData,
  }) => {
    const { isPremiumExpiredSubscription } = subscriptionTypeValidators || {}

    modals.openModal({
      id: 'TransactionResponse',
      content: (
        <TransactionResponseModal
          subscriptionTypeValidators={subscriptionTypeValidators}
          handleClose={handleClose}
          transactionStatus={transactionStatus}
          isExtraworkersPayment={isExtraworkersPayment}
          showCloseButton={
            transactionStatus !== 'APPROVED' || isExtraworkersPayment
          }
          downgradeData={downgradeData}
        />
      ),
      modalProps: {
        hideFooter: true,
        header:
          transactionStatus !== 'APPROVED' || isExtraworkersPayment
            ? null
            : isPremiumExpiredSubscription
              ? '¡Recibimos el pago de tu plan!'
              : '¡Acabas de actualizar tu plan!',
        onCloseModal: handleClose,
        onCancel: handleClose,
        paperSx: {
          maxWidth:
            transactionStatus !== 'APPROVED' || isExtraworkersPayment
              ? '50rem'
              : '46rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })
  }

  return { openTransactionResponseModal }
}

export default useTransactionResponseModal
