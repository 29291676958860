import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getDecree376File,
  getDecree376Payments,
  getDecree376SocialSecurityFile,
  updateDecree376Payments,
} from 'services/payroll/decree376Service'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, params } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getDecree376Payments')
    return getDecree376Payments(params)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, mutationKey, params } = mutationData

  if (mutationMethod === 'PUT') return updateDecree376Payments(params)

  if (mutationMethod === 'GET') {
    if (mutationKey === 'getDecree376File') return getDecree376File(params)

    if (mutationKey === 'getDecree376SocialSecurityFile')
      return getDecree376SocialSecurityFile(params)
  }

  return null
}

const useDecree376Service = ({
  serviceParams = { queryKey: 'getDecree376Payments' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restDecree376Response } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const decree376Mutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    decree376Query: {
      data,
      ...restDecree376Response,
    },
    decree376Mutation,
  }
}

export default useDecree376Service
