import { Box } from '@mui/material'

import OptionSelector from 'components/Period/common/OptionSelector'

import CardGrid from '../common/CardGrid'
import HeaderCell from '../common/CardGrid/HeaderCell'
import HeaderRow from '../common/CardGrid/HeaderRow'
import AddConceptWrapper from './AddConceptWrapper'
import ConnectivityAid from './ConnectivityAid'

const NO_SALARY_INCOME = 'non_salary_income'

const NonSalaryIncome = ({
  handleAddConcept,
  concepts,
  incomes,
  setIsCreatePayrollConceptModalOpen,
  setNameCreatePayrollConcept,
  workerPayroll,
  handleSelectItem,
  handleChangeItemValue,
  handleDeleteItem,
  connectivityAidDays,
  setConnectivityAidDays,
}) => {
  return (
    <CardGrid gridColumns="1fr 1fr 0.6fr 2.5rem">
      <Box
        sx={{
          animation: 'fadeIn 700ms',
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
        }}
      >
        <HeaderRow>
          <HeaderCell
            sx={{
              gridColumn: 1,
            }}
            desktopOnly
          >
            Tipo de ingreso
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 2,
            }}
            desktopOnly
          >
            Concepto
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 3,
            }}
            desktopOnly
          >
            Valor / Días
          </HeaderCell>
        </HeaderRow>
        <AddConceptWrapper
          showButton
          handleAddConcept={() => handleAddConcept(NO_SALARY_INCOME)}
          disableAddConceptButton={
            concepts[NO_SALARY_INCOME]?.length <
            incomes[NO_SALARY_INCOME]?.length
          }
          handleCreateConcept={() => {
            setIsCreatePayrollConceptModalOpen(true)
            setNameCreatePayrollConcept(NO_SALARY_INCOME)
          }}
          gutterBottom={workerPayroll.connectivity_aid_option}
        >
          <OptionSelector
            data={incomes.non_salary_income}
            listOptions={concepts.non_salary_income}
            onSelectItem={handleSelectItem}
            onChangeItemValue={handleChangeItemValue}
            onDeleteItem={handleDeleteItem}
            category={NO_SALARY_INCOME}
            gutterBottom={false}
          />
        </AddConceptWrapper>
        {workerPayroll.connectivity_aid_option ? (
          <ConnectivityAid
            connectivityAidDays={connectivityAidDays}
            setConnectivityAidDays={setConnectivityAidDays}
          />
        ) : null}
      </Box>
    </CardGrid>
  )
}

export default NonSalaryIncome
