import { useCallback } from 'react'

import { PAYROLL_ACTION_TYPES } from './usePayrollConfiguration'

const useOvertimeModal = ({ payrollState, payrollDispatch }) => {
  const openOvertimeModal = useCallback(
    (noveltyConcept) => {
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SHOW_MODAL,
        modalKeys: {
          noveltyTypeModal: false,
          overtimeModal: true,
        },
      })
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SET_MODAL_CONFIGURATION,
        config: {
          noveltyConcept,
        },
      })
    },
    [payrollDispatch]
  )

  const closeOvertimeModal = () =>
    payrollDispatch({
      type: PAYROLL_ACTION_TYPES.CLOSE_MODAL,
      modalKeys: {
        overtimeModal: false,
        noveltyTypeModal: true,
      },
    })

  return {
    overtimeModalState: {
      open: Boolean(payrollState.overtimeModal),
    },
    openOvertimeModal,
    closeOvertimeModal,
  }
}

export default useOvertimeModal
