import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import { formatDisplayDateString } from 'utils/dateTime'
import useFreetimeService from 'utils/hooks/freetime/freetime'
import useNotifications from 'utils/hooks/useNotifications'

const ApproveRequest = ({
  conceptId,
  requestId,
  name,
  initialDay,
  endDay,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const [actionType, setActionType] = useState()
  const { showSuccessMessage } = useNotifications()
  const { freetimeMutation } = useFreetimeService({
    queryOptions: {
      enabled: false,
    },
  })
  const isApproving = freetimeMutation.isLoading && actionType === 'approved'
  const isRefusing = freetimeMutation.isLoading && actionType === 'rejected'

  const onManageRequest = (status) => {
    setActionType(status)
    const label = status === 'approved' ? 'aprobada' : 'rechazada'
    const formData = new FormData()
    formData.append('payroll_concept_id', conceptId)
    formData.append('initial_day', initialDay)
    formData.append('end_day', endDay)
    formData.append('status', status)

    freetimeMutation.mutate(
      {
        mutationMethod: 'PUT',
        noveltyId: requestId,
        noveltyRequest: formData,
      },
      {
        onSuccess: () => {
          showSuccessMessage(`La solicitud ha sido ${label}`)
          queryClient.invalidateQueries('getAllRequests', {
            exact: false,
            active: true,
          })
          onClose()
        },
      }
    )
  }

  return (
    <>
      <Typography variant="body1" gutterBottom>
        <Box
          component="span"
          sx={(theme) => ({
            display: 'inline-flex',
            flexWrap: 'wrap',
            columnGap: theme.spacing(0.5),
          })}
        >
          {`${name} no estará disponible del `}
          <span>{`${formatDisplayDateString(initialDay)} `}</span>
          <Icon name="arrow-right" />
          <span>{` ${formatDisplayDateString(endDay)}`}</span>
        </Box>
      </Typography>
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          loading={isApproving}
          disabled={isRefusing}
          onClick={() => onManageRequest('approved')}
        >
          Aprobar
        </Button>
        <Button
          variant="outlined"
          disabled={isApproving}
          loading={isRefusing}
          onClick={() => onManageRequest('rejected')}
        >
          Rechazar
        </Button>
      </Box>
    </>
  )
}

export default ApproveRequest
