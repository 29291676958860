import { createContext, useContext, useState } from 'react'

import ElectronicPayrollAlert from '.'

export const ElectronicPayrollAlertContext = createContext({})
ElectronicPayrollAlertContext.displayName = 'ElectronicPayrollAlertContext'

export const useElectronicPayrollAlert = () => {
  const context = useContext(ElectronicPayrollAlertContext)

  if (!context) {
    throw new Error(
      'useElectronicPayrollAlert must be used within a ElectronicPayrollAlertContext provider'
    )
  }

  return context
}

const ElectronicPayrollAlertWrapper = ({ children }) => {
  const [webSocketState, setWebSocketState] = useState('unset') // Possible states: 'unset' | 'inProgress' | 'finished'
  const [state, setState] = useState({
    show: false,
    messageResponse: '',
    periodId: null,
  })

  const openElectronicPayrollAlert = ({ messageResponse, periodId }) => {
    setState((previousState) => ({
      ...previousState,
      show: true,
      messageResponse,
      periodId,
    }))
  }

  const closeElectronicPayrollAlert = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }))
  }

  return (
    <ElectronicPayrollAlertContext.Provider
      value={{
        webSocketState,
        openElectronicPayrollAlert,
        setWebSocketState,
      }}
    >
      {state.show ? (
        <ElectronicPayrollAlert
          state={state}
          handleClose={closeElectronicPayrollAlert}
        />
      ) : null}
      {children}
    </ElectronicPayrollAlertContext.Provider>
  )
}

export default ElectronicPayrollAlertWrapper
