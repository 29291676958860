import { useState } from 'react'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import AddButton from 'components/UI/Button/AddButton'
import useWorker from 'components/Worker/useWorker'

import RecurrentConceptsModal from '../Modals/RecurrentConceptsModal/RecurrentConceptsModal'
import DeductionsTable from './Deductions/DeductionsTable'
import IncomesTable from './Income/IncomesTable'
import RetentionConfigurationItem from './RetentionConfigurationItem'

const RecurrentConceptsContent = () => {
  const [isIncomesModalOpen, setIsIncomesModalOpen] = useState(false)
  const [isDeductionsModalOpen, setIsDeductionsModalOpen] = useState(false)

  const { isWorker } = useUser()
  const { worker } = useWorker({ useCache: true })
  const showRetentionConfiguration = worker?.contract_category === 'employee'

  return (
    <>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 3, 6, 3),
        })}
      >
        <Typography
          variant="h4"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Conceptos recurrentes
        </Typography>
        <Typography variant="body1" color="black.dark">
          Acá puedes agregar ingresos y deducciones recurrentes o activar el
          cálculo de la retención en la fuente
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(2),
          })}
        >
          <Typography variant="h5">Ingresos</Typography>
          {!isWorker ? (
            <AddButton
              onClick={() => setIsIncomesModalOpen(true)}
              data-cy="add_incomes_button"
            />
          ) : null}
        </Box>
        <IncomesTable noItemsOnClick={() => setIsIncomesModalOpen(true)} />
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(7),
            marginBottom: theme.spacing(2),
          })}
        >
          <Typography variant="h5">Deducciones y préstamos</Typography>
          {!isWorker ? (
            <AddButton
              onClick={() => setIsDeductionsModalOpen(true)}
              data-cy="add_deductions_button"
            />
          ) : null}
        </Box>
        <DeductionsTable
          noItemsOnClick={() => setIsDeductionsModalOpen(true)}
        />
        {!isWorker && showRetentionConfiguration ? (
          <RetentionConfigurationItem />
        ) : null}
      </Box>
      {isIncomesModalOpen ? (
        <RecurrentConceptsModal
          modalType="incomes"
          onCancel={() => setIsIncomesModalOpen(false)}
        />
      ) : null}
      {isDeductionsModalOpen ? (
        <RecurrentConceptsModal
          modalType="deductions"
          onCancel={() => setIsDeductionsModalOpen(false)}
        />
      ) : null}
    </>
  )
}

export default RecurrentConceptsContent
