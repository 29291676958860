import { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'

import { isPremiumExpiredByCancellationCompany } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'

import useTransactionResponseModal from './TransactionResponseModal/useTransactionResponseModal'
import useSubscription from './useSubscription'

const usePsePaymentCheck = () => {
  const location = useLocation()
  const { refreshCompany } = useUser()
  const { subscription } = useSubscription()
  const { openTransactionResponseModal } = useTransactionResponseModal()
  const initialSubscriptionRef = useRef(subscription)
  const isExtraworkersPayment = location.pathname.startsWith('/workers')
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const planQueryKey = ['getPlans', companyId]

  const isPsePayment =
    new URLSearchParams(location.search).get('pse_payment') === 'true'

  const isPremiumExpiredSubscription =
    subscription?.status === 'premium_expired' ||
    isPremiumExpiredByCancellationCompany(initialSubscriptionRef.current)

  const { paymentMethodQuery: pseResponse } = usePaymentMethodService({
    serviceParams: { queryKey: 'getPaymentPSE' },
    queryOptions: {
      enabled: isPsePayment,
      onSuccess: async ({
        payment_status: transactionStatus,
        payment_type: paymentType,
      }) => {
        if (transactionStatus === 'APPROVED') {
          await refreshCompany()
          if (paymentType !== 'year_additional_workers')
            queryClient.invalidateQueries(planQueryKey)
        }
      },
    },
  })

  const {
    data: {
      plan_id: planId,
      subscription_type_change: subscriptionModalityChange,
      payment_status: transactionStatus,
    } = {},
  } = pseResponse || {}

  const subscriptionTypeValidators = {
    isCurrentPlan: planId === initialSubscriptionRef.current.plan?.id,
    isChangingModality: subscriptionModalityChange,
    currentPlanPayment:
      planId === initialSubscriptionRef.current.plan?.id &&
      !subscriptionModalityChange,
    isPremiumExpiredSubscription,
  }

  const openPseTransactionResponseModal = () =>
    openTransactionResponseModal({
      subscriptionTypeValidators,
      transactionStatus,
      isExtraworkersPayment,
    })

  return {
    openPseTransactionResponseModal,
    pseTransactionStatus: isPsePayment ? transactionStatus : null,
  }
}

export default usePsePaymentCheck
