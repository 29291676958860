import { Box } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCancellationOptions, reasons } from './helpers'

const CancelReasons = ({ values, isFromOrganizerView }) => {
  return (
    <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
      <FormField
        name="reason"
        variant="radio-group"
        options={getCancellationOptions(isFromOrganizerView)}
      />
      {values.reason === reasons.anotherReason ? (
        <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
          <FormField
            name="another_reason_detail"
            label={`Escribe el motivo de cancelación de ${isFromOrganizerView ? 'la' : 'tu'} suscripción`}
            variant="textarea"
            optional={false}
            rows={4}
          />
        </Box>
      ) : null}
      {isFromOrganizerView ? (
        <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
          <FormField
            name="comment"
            variant="textarea"
            label="Comentario sobre la cancelación"
            rows="4"
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default CancelReasons
