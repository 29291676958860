import {
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import OptionSelector from 'components/Period/common/OptionSelector'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import AddConceptWrapper from '../Income/AddConceptWrapper'
import CardGrid from '../common/CardGrid'
import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import HeaderRow from '../common/CardGrid/HeaderRow'
import MobileCell from '../common/CardGrid/MobileCell'
import GroupDivider from '../common/GroupDivider'

const AvistaConcepts = ({ name, value, id }) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 1,
          },
        })}
      >
        <Typography variant="h6" color="black.dark">
          Deducciones
        </Typography>
      </MobileCell>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        alignY="center"
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 2,
          },
        })}
      >
        <Typography variant="h6" color="black.dark">
          {name}
        </Typography>
      </MobileCell>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        alignY="center"
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 3,
          },
        })}
      >
        <TextField
          id={id}
          value={value}
          disabled
          fullWidth={false}
          InputProps={{
            inputComponent: NumberFormatField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            sx: {
              height: '2rem',
              maxWidth: '12rem',
            },
          }}
        />
      </MobileCell>
    </>
  )
}

const Deductions = ({
  novelty,
  payrollRetentionInfo,
  tabData,
  handleSelectItem,
  handleChangeItemValue,
  handleDeleteItem,
  handleAddConcept,
  setIsCreatePayrollConceptModalOpen,
}) => {
  const { deductions, concepts } = tabData
  const { payrollRetentionItemIndex } = payrollRetentionInfo

  // Get the index for avista deductions
  const avistaPayrollCreditItemIndex = deductions?.findIndex(
    (ded) => ded.coded_name === 'avista_payroll_credit'
  )
  const avistaPayrollAdvanceItemIndex = deductions?.findIndex(
    (ded) => ded.coded_name === 'avista_payroll_advance'
  )

  const getIndexToIgnore = () => {
    const indexArray = []
    if (payrollRetentionItemIndex !== -1) {
      indexArray.push(payrollRetentionItemIndex)
    }
    if (avistaPayrollCreditItemIndex !== -1) {
      indexArray.push(avistaPayrollCreditItemIndex)
    }
    if (avistaPayrollAdvanceItemIndex !== -1) {
      indexArray.push(avistaPayrollAdvanceItemIndex)
    }

    return indexArray
  }

  const indexToIgnore = getIndexToIgnore()

  // Get the object for avista deductions
  const avistaPayrollCreditItem = deductions[avistaPayrollCreditItemIndex]
  const avistaPayrollAdvanceItem = deductions[avistaPayrollAdvanceItemIndex]

  return (
    <CardGrid gridColumns="1fr 1fr 0.6fr 2.5rem">
      <HeaderRow>
        <HeaderCell
          sx={{
            gridColumn: 1,
          }}
          desktopOnly
        >
          Tipo
        </HeaderCell>
        <HeaderCell
          sx={{
            gridColumn: 2,
          }}
          desktopOnly
        >
          Concepto
        </HeaderCell>
        <HeaderCell
          sx={{
            gridColumn: 3,
          }}
          desktopOnly
        >
          Valor
        </HeaderCell>
      </HeaderRow>
      {avistaPayrollCreditItem?.value > 0 ? (
        <>
          <GridRow gutterBottom>
            <HeaderCell
              sx={{
                gridColumn: 1,
                gridRow: 1,
              }}
              mobileOnly
            >
              Tipo de ingreso
            </HeaderCell>
            <HeaderCell
              sx={{
                gridColumn: 1,
                gridRow: 2,
              }}
              mobileOnly
              alignY="center"
            >
              Concepto
            </HeaderCell>
            <HeaderCell
              sx={{
                gridColumn: 1,
                gridRow: 3,
              }}
              mobileOnly
              alignY="center"
            >
              Valor
            </HeaderCell>
            <AvistaConcepts
              name={avistaPayrollCreditItem.name}
              value={avistaPayrollCreditItem.value}
              id="avistaPayrollCreditInputValue"
            />
          </GridRow>
          {avistaPayrollAdvanceItem?.value > 0 || deductions?.length > 0 ? (
            <GroupDivider />
          ) : null}
        </>
      ) : null}
      {avistaPayrollAdvanceItem?.value > 0 ? (
        <>
          <GridRow gutterBottom>
            <HeaderCell
              sx={{
                gridColumn: 1,
                gridRow: 1,
              }}
              mobileOnly
            >
              Tipo de ingreso
            </HeaderCell>
            <HeaderCell
              sx={{
                gridColumn: 1,
                gridRow: 2,
              }}
              mobileOnly
              alignY="center"
            >
              Concepto
            </HeaderCell>
            <HeaderCell
              sx={{
                gridColumn: 1,
                gridRow: 3,
              }}
              mobileOnly
              alignY="center"
            >
              Valor
            </HeaderCell>
            <AvistaConcepts
              name={avistaPayrollAdvanceItem.name}
              value={avistaPayrollAdvanceItem.value}
              id="avistaPayrollCreditInputValue"
            />
          </GridRow>
          {deductions?.length > 0 ? <GroupDivider /> : null}
        </>
      ) : null}
      <AddConceptWrapper
        showButton
        handleAddConcept={handleAddConcept}
        disableAddConceptButton={concepts.length < deductions.length}
        handleCreateConcept={() => {
          setIsCreatePayrollConceptModalOpen(true)
        }}
        gutterBottom
      >
        <div id={`${novelty}-items`}>
          <OptionSelector
            data={deductions}
            listOptions={concepts}
            onSelectItem={handleSelectItem}
            onChangeItemValue={handleChangeItemValue}
            onDeleteItem={handleDeleteItem}
            indexToIgnore={indexToIgnore}
            category="deductions"
            gutterBottom
          />
        </div>
      </AddConceptWrapper>
    </CardGrid>
  )
}

export default Deductions
