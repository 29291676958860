import http, { getApiURL } from '../httpService'

export const getConfigurationOptions = () => {
  const url = getApiURL({ pathname: '/company_configuration_options' })

  return http.getV2(url)
}

export const getCompanyConfiguration = () => {
  const url = getApiURL({ pathname: '/company_configurations' })

  return http.getV2(url)
}

export const updateCompanyConfiguration = (configuration) => {
  const url = getApiURL({ pathname: '/company_configurations' })

  return http.putV2(url, configuration)
}

export default {
  getCompanyConfiguration,
  getConfigurationOptions,
  updateCompanyConfiguration,
}
