import { useState } from 'react'

const useFinalSigningsStatusModal = () => {
  const [state, setState] = useState({
    open: false,
    workerId: null,
    workerName: null,
    onboardingId: null,
    electronicSignature: null,
  })

  const openFinalSigningsStatusModal = ({
    workerId,
    workerName,
    onboardingId,
    electronicSignature,
  }) => {
    setState((previuosState) => ({
      ...previuosState,
      open: true,
      workerId,
      workerName,
      onboardingId,
      electronicSignature,
    }))
  }

  const closeFinalSigningsStatusModal = () => {
    setState((previuosState) => ({
      ...previuosState,
      open: false,
    }))
  }

  return {
    openFinalSigningsStatusModal,
    closeFinalSigningsStatusModal,
    finalSigningsStatusModalState: state,
  }
}

export default useFinalSigningsStatusModal
