import { Box } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import CreditCardNumberField from 'components/Subscription/SubscriptionFormFields/CreditCardNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import ContainerFields from './ContainerFields'
import CreditCardExpirationDateField from './CreditCardExpirationDate'
import TermsAcceptanceCard from './TermsAcceptanceCard'

const CreditCardFields = ({ isEditingPaymentMethod, newSubscriptionType }) => {
  const { subscription } = useSubscription()
  const ccType = subscription?.payment_method_info?.card_brand?.toLowerCase()
  const isAmexCreditCard = ccType === 'american-express'

  return (
    <ContainerFields>
      <FormField
        name="credit_card_form.payer_name"
        label="Nombre del titular"
        inputProps={{ autoComplete: 'cc-name' }}
        optional={false}
      />
      <CreditCardNumberField name="credit_card_form.card_number" />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridGap: theme.spacing(4.5),
          width: '100%',
          gridTemplateColumns: 'minmax(auto, 123px) 1fr',
        })}
      >
        <FormField
          name="credit_card_form.security_code"
          label="CVV"
          variant="number"
          placeholder={isAmexCreditCard ? '0000' : '000'}
          format="####"
          inputProps={{ autoComplete: 'cc-csc' }}
          style={{ fontFamily: 'Roboto Mono, monospace' }}
          optional={false}
        />
        <CreditCardExpirationDateField name="credit_card_form.expiration_date" />
      </Box>
      <TermsAcceptanceCard
        paymentVariant={
          isEditingPaymentMethod ? 'payment_data_update' : 'new_payment'
        }
        newSubscriptionType={newSubscriptionType}
      />
    </ContainerFields>
  )
}

export default CreditCardFields
