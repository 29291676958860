import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createSocialBenefitsWorker,
  deleteSocialBenefits,
  getSocialBenefitsFile,
  getSocialBenefitsPeriod,
  recalculateAllSocialBenefits,
  recalculateWorkerSocialBenefits,
  updateSocialBenefits,
  updateSocialBenefitsWorker,
} from 'services/payroll/socialBenefitsService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, periodId, socialBenefitsPeriodId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getSocialBenefitsPeriod')
    return getSocialBenefitsPeriod(periodId, socialBenefitsPeriodId)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationKey,
    socialBenefitId,
    periodId,
    socialBenefitPeriodId,
    socialBenefits,
  } = mutationData

  if (
    mutationMethod === 'PUT' &&
    mutationKey === 'recalculateWorkerSocialBenefits'
  )
    return recalculateWorkerSocialBenefits(
      periodId,
      socialBenefitPeriodId,
      socialBenefitId,
      socialBenefits
    )

  if (
    mutationMethod === 'PUT' &&
    mutationKey === 'recalculateAllSocialBenefits'
  )
    return recalculateAllSocialBenefits(
      periodId,
      socialBenefitPeriodId,
      socialBenefits
    )

  if (mutationMethod === 'PUT' && mutationKey === 'updateSocialBenefits')
    return updateSocialBenefits(periodId, socialBenefitPeriodId, socialBenefits)

  if (mutationMethod === 'GET')
    return getSocialBenefitsFile(periodId, socialBenefitPeriodId)

  if (mutationMethod === 'DELETE')
    return deleteSocialBenefits(periodId, socialBenefitPeriodId, socialBenefits)

  if (mutationMethod === 'PUT' && mutationKey === 'updateSocialBenefitsWorker')
    return updateSocialBenefitsWorker(
      periodId,
      socialBenefitPeriodId,
      socialBenefitId,
      socialBenefits
    )

  if (mutationMethod === 'POST' && mutationKey === 'createSocialBenefitsWorker')
    return createSocialBenefitsWorker({
      periodId,
      socialBenefitPeriodId,
      socialBenefits,
    })

  return null
}

const useSocialBenefitsService = ({
  serviceParams = { queryKey: 'getSocialBenefitsPeriod' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restSocialBenefitsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const socialBenefitsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    socialBenefitsQuery: {
      data,
      ...restSocialBenefitsResponse,
    },
    socialBenefitsMutation,
  }
}

export default useSocialBenefitsService
