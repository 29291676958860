import Emoji from 'components/UI/Emoji'

import { partyPopperEmoji } from 'utils/emojis'
import { getWordSingular } from 'utils/format'

import {
  ONBOARDING_WELCOME,
  PERIOD_PAY_PAYROLL,
  SETTINGS_ELECTRONIC_PAYROLL,
  WORKER_NEW,
} from 'config/routes'

const currentStepsInformation = [
  {
    id: 'account_configurated',
    number: 1,
    title: (
      <>
        Tu cuenta fue creada <Emoji code={partyPopperEmoji} />
      </>
    ),
    description: 'Sigue estos pasos y pon tu nómina en modo automágico.',
    status: 'pending',
    path: ONBOARDING_WELCOME(),
  },
  {
    id: 'first_worker',
    number: 2,
    title: 'Crea personas',
    description: 'Esto te permitirá liquidarles la nómina cada periodo.',
    status: 'pending',
    path: WORKER_NEW(),
  },
  {
    id: 'first_payroll',
    number: 3,
    title: 'Liquida tu primera nómina',
    description: 'No se generarán pagos ni se emitirá todavía a la DIAN.',
    status: 'pending',
    path: PERIOD_PAY_PAYROLL(),
  },
  {
    id: 'electronic_payroll_configurated',
    number: 4,
    title: 'Habilitate para emitir nómina electrónica',
    description: 'Quítate a la DIAN de la cabeza y cumple con la norma.',
    status: 'pending',
    path: SETTINGS_ELECTRONIC_PAYROLL(),
  },
]

export const getMessage = (name, remainingSteps, activeStep) => {
  const stepPhrase = {
    account_configurated: 'Ve a configurar tu cuenta',
    first_worker: 'Ve a crear personas',
    first_payroll: 'Ve a liquidar tu primera nómina',
    electronic_payroll_configurated:
      'Ve a habilitarte para emitir nómina electrónica',
  }

  return (
    <>
      <b>!Hola {name}</b>, estás a {remainingSteps}{' '}
      {getWordSingular('pasos', remainingSteps === 1)} de liquidar la nómina más
      rápida que hayas visto<b>! {stepPhrase[activeStep]}</b>
    </>
  )
}

export const getFirstSteps = (firstSteps) => {
  const stepsInformation = currentStepsInformation
  const steps = stepsInformation.map((step) => ({
    ...step,
    status: firstSteps[step.id] ? 'completed' : 'pending',
  }))

  return steps.map((step, index) => {
    if (step.status === 'pending') {
      if (
        index === 0 ||
        (index - 1 >= 0 && steps[index - 1].status === 'completed')
      ) {
        return {
          ...step,
          status: 'active',
        }
      }
    }
    return step
  })
}
