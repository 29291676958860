import { formatCurrency } from 'utils/format'
import { isObjectEmpty } from 'utils/general'

import PlanSelector from './PlanSelector'
import WorkersCounter from './WorkersCounter'

export const subscriptionType = (discountChip) => [
  { label: 'Mensual', value: 'month' },
  {
    label: discountChip ? <>Anual {discountChip}</> : 'Anual',
    value: 'year',
  },
]

const initialValues = [
  { id: 'plan', label: 'Plan', value: '-' },
  { id: 'periodicity', label: 'Modalidad', value: '-' },
  { id: 'monthly_cost', label: 'Costo mensual', value: '-' },
  { id: 'periodicity_subtotal', label: 'Subtotal', value: '-' },
  { id: 'total', label: 'TOTAL', value: '-' },
]

export const getSummaryItems = (plan, subscription, isAnually, companyName) => {
  if (isObjectEmpty(plan)) return initialValues

  const planSubscriptionValue = isAnually
    ? plan.subscription_value?.yearly || {}
    : plan.subscription_value?.monthly || {}

  const isExonerated = subscription?.status === 'exonerated_payment'

  // Company name
  const summary = [
    {
      label: 'Empresa',
      value: companyName,
      id: 'company_name',
    },
  ]

  // Plan name
  summary.push({
    label: 'Plan',
    value: <PlanSelector selectedPlan={plan} />,
    id: 'plan',
  })

  if (!subscription?.data?.partner && !isExonerated) {
    summary.push({
      label: 'Suscripción',
      value: formatCurrency(planSubscriptionValue.subtotal),
      id: 'subtotal_item',
    })

    // Workers number
    if (
      planSubscriptionValue.workers_number > 0 ||
      subscription?.payrolls_size >= 0
    ) {
      summary.push({
        label: 'Cantidad de personas',
        value: <WorkersCounter />,
        id: 'extra_workers',
      })
      summary.push({
        label: `Personas (${planSubscriptionValue.workers_number})`,
        value: formatCurrency(
          planSubscriptionValue.subtotal_extra_workers_value
        ),
        id: 'extra_workers_subtotal',
      })
    }

    // Plan type
    summary.push({
      label: 'Modalidad',
      value: isAnually ? 'Anual' : 'Mensual',
      id: 'periodicity',
    })

    // Subtotal value
    summary.push({
      label: `Subtotal ${isAnually ? 'anualidad' : 'mensualidad'}`,
      value: formatCurrency(planSubscriptionValue.subtotal),
      id: 'periodicity_subtotal',
    })

    // Discounts
    if (
      planSubscriptionValue.extra_charge?.distributor ||
      planSubscriptionValue.extra_charge?.nominapp
    ) {
      summary.push({
        label: 'Descuento',
        value: `- ${formatCurrency(
          Math.abs(
            planSubscriptionValue.extra_charge?.distributor ||
              planSubscriptionValue.extra_charge?.nominapp
          )
        )}`,
        id: 'subscription_discount',
      })
    }
  }

  const total = subscription?.partner
    ? subscription?.month_value
    : planSubscriptionValue.total

  // Total value
  summary.push({
    label: 'TOTAL',
    value: isExonerated ? 'Exonerado de pago' : formatCurrency(total),
    id: 'total',
    bold: true,
  })

  return summary
}
