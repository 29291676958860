import useLocalStorage from '@rehooks/local-storage'
import { useFormikContext } from 'formik'

import { Box, Button } from '@mui/material'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import NumberOfEmployeesField from 'components/UI/Formik/CommonFields/NumberOfEmployeesField'
import OnboardingPayrollFrequencyField from 'components/UI/Formik/CommonFields/OnboardingPayrollFrequencyField'
import FormField from 'components/UI/Formik/FormField/Index'
import LoadingBackdrop from 'components/UI/Loading/LoadingBackdrop'

import { documentTypesOptions } from 'utils/company'

import colombianPhoneNumber from 'assets/images/views/common/colombian_phone_number.png'

const OnboardingPersonalize = ({ isLoading }) => {
  const { handleSubmit, setFieldValue } = useFormikContext()
  const [, setUserName] = useLocalStorage('user_name')

  const handleChangeName = ({ target: { value } }) => {
    const name = value.split(' ')[0]
    setFieldValue('user_name', value)
    setUserName(name || 'Usuario')
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        rowGap: theme.spacing(3),
        maxWidth: '35rem',
        width: '100%',
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(6),
        },
      })}
    >
      <LoadingBackdrop open={isLoading} />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2.5),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(2.5),
          },
        })}
      >
        <FormField
          data-cy="user-name"
          name="user_name"
          label="¿Cómo te llamas?"
          placeholder="Escribe tu nombre completo"
          onChange={handleChangeName}
          optional={false}
        />
        <FormField
          data-cy="phone"
          name="phone"
          variant="number"
          label="¿Cuál es tu celular?"
          placeholder="Escribe tu número de celular"
          isNumericString
          format="### ### ####"
          startAdornment={
            <Box
              component="img"
              src={colombianPhoneNumber}
              alt="Número de teléfono Colombiano"
              width={50}
              height={20}
            />
          }
          optional={false}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2.5),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(2.5),
          },
        })}
      >
        <FormField
          data-cy="company-name"
          name="name"
          label="Nombre de tu empresa"
          placeholder="Nombre de tu empresa"
          optional={false}
        />
        <DocumentTypeField
          label="Tipo de documento de tu empresa"
          options={documentTypesOptions}
          optional={false}
        />
      </Box>
      <CompanyIdentificationNumberField label="Número de documento" />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2.5),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(2.5),
          },
        })}
      >
        <NumberOfEmployeesField label="Número de personas" />
        <OnboardingPayrollFrequencyField />
      </Box>
      <Button
        onClick={handleSubmit}
        disabled={isLoading}
        sx={(theme) => ({ marginTop: theme.spacing(7) })}
      >
        Finalizar
      </Button>
    </Box>
  )
}

export default OnboardingPersonalize
