import useInstitutionsService from 'utils/hooks/institutionsService'

import FormField from '../FormField/Index'

const BankField = ({ provider, name, ...props }) => {
  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey: ['getInstitutionsByCategory', 'bank'],
      category: 'bank',
      provider,
    },
  })

  return (
    <FormField
      name={name || 'bank'}
      label="Banco"
      variant="autocomplete"
      placeholder="Selecciona el banco..."
      options={institutionsQuery.data || []}
      optional={false}
      {...props}
    />
  )
}

export default BankField
