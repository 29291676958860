import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const PayrollRetentionAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openDeductionsModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={payroll.details?.payroll_retention?.value}
      onClick={() => openDeductionsModal('payroll_retention')}
      editable={period.editable && !payroll.locked}
      dataCy="add-payroll-retention"
    />
  )
}

export default PayrollRetentionAction
