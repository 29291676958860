import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const columnsData = [
  {
    Header: 'Centro de costos',
    placeholder: 'Centro de costos',
    accessor: 'name',
    name: 'name',
    editable: true,
  },
  {
    Header: 'Prefijo cuenta contable',
    placeholder: 'Prefijo cuenta contable',
    accessor: 'accounting_code',
    name: 'accounting_code',
    editable: true,
  },
  {
    Header: 'Código centro de costos',
    placeholder: 'Código centro de costos',
    accessor: 'code',
    name: 'code',
    editable: true,
    emptyValue: '-',
  },
  {
    Header: '# Personas',
    accessor: 'workers_number',
  },
]

export const getValidationSchema = (alegraAccounting) => {
  return yup.object({
    name: yup.string().required(),
    accounting_code: alegraAccounting ? null : yup.string().required(),
  })
}
