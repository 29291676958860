import { Box, Card, CardContent as MUICardContent } from '@mui/material'

const DescriptionCard = ({
  children,
  direction = 'row',
  backgroundColor = 'white.main',
  sx,
}) => {
  return (
    <Card
      sx={[
        {
          backgroundColor,
          display: 'flex',
          flexDirection: direction,
          height: '100%',
          borderRadius: '1rem',
        },
        sx,
      ]}
    >
      {children}
    </Card>
  )
}

export const CardContent = ({ children }) => {
  return <MUICardContent>{children}</MUICardContent>
}

export const CardMedia = ({
  component = 'img',
  height = 0,
  width = 0,
  alt = '',
  src = '',
  sx = {},
}) => {
  return (
    <Box
      component={component}
      height={height}
      width={width}
      alt={alt}
      src={src}
      sx={sx}
    />
  )
}

export default DescriptionCard
