import { useFormikContext } from 'formik'
import { useState } from 'react'
import { flushSync } from 'react-dom'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import { getAutoCompleteElementFilteredOptions } from 'utils/form'
import useLocationService from 'utils/hooks/company/locationService'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import tooltipInfo from 'messages/worker'

import LocationCreateDialog from './LocationCreateDialog/LocationCreateDialog'

const WorkerFormFieldsPersonalLocation = ({ optional, ...props }) => {
  const [isLocationCreateDialogOpen, setIsLocationCreateDialogOpen] =
    useState(false)
  const [locationToCreate, setLocationToCreate] = useState({})
  const { showSuccessMessage } = useNotifications()
  const form = useFormikContext()

  const { locationQuery } = useLocationService({
    serviceParams: { queryKey: ['companyLocation', getCompanyId()] },
  })

  const callbackMutateLocations = (newLocation) => {
    form.setFieldValue('location', newLocation)
    showSuccessMessage('La sede se creó con éxito.')

    trackEvent(integrationEvent.COMPANY_ADD_LOCATION)

    setLocationToCreate({})
  }

  const handleOpenCreateLocation = (__, newValue) => {
    if (newValue?.id === 'create') {
      setLocationToCreate(newValue.value ? { name: newValue.value } : {})
      flushSync(() => {
        setIsLocationCreateDialogOpen(true)
      })
    } else {
      form.setFieldValue('location', newValue)
    }
  }

  return (
    <>
      <LocationCreateDialog
        open={isLocationCreateDialogOpen}
        handleClose={() => setIsLocationCreateDialogOpen(false)}
        initialLocation={locationToCreate}
        submitCallback={callbackMutateLocations}
      />
      <FormField
        name="location"
        label="Sede"
        variant="autocomplete"
        placeholder="Escribe la sede de trabajo de la persona..."
        noOptionsText="No hay sedes registradas."
        loadingText="Cargando sedes..."
        options={locationQuery.data}
        tooltipContent={tooltipInfo.location}
        onChange={handleOpenCreateLocation}
        loading={locationQuery.isLoading}
        filterOptions={(opt, params) =>
          getAutoCompleteElementFilteredOptions(opt, params, 'Crear nueva sede')
        }
        optional={optional}
        {...props}
      />
    </>
  )
}

export default WorkerFormFieldsPersonalLocation
