import { Box, Chip, Typography, useTheme } from '@mui/material'

import LordIcon from 'components/UI/LordIcon'

import getChipStatusStyles from './getChipStatusStyles'

const DocumentStatus = ({ electronicSignatureQueryData }) => {
  const themeInstance = useTheme()

  const signers = electronicSignatureQueryData[0]?.signers || []
  const {
    animatedIcon,
    animatedIconBackgroundColor,
    chipBackgroundColor,
    color,
    status,
  } = getChipStatusStyles(themeInstance)

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: theme.spacing(3),
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: '1fr 1fr',
        },
      })}
    >
      {signers.map((signer) => (
        <Box
          key={signer?.name}
          className="signer_icon_box"
          sx={(theme) => ({
            display: 'flex',
            boxShadow: theme.shadows[3],
            width: '100%',
            minHeight: '7rem',
            borderRadius: '0.5rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              minWidth: '5rem',
              alignItems: 'center',
              justifyContent: 'center',
              borderTopLeftRadius: '0.5rem',
              borderBottomLeftRadius: '0.5rem',
              backgroundColor: animatedIconBackgroundColor[signer.status],
            }}
          >
            <LordIcon
              name={animatedIcon[signer.status]}
              target="signer_icon_box"
            />
          </Box>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(1.62, 1.4, 1.62, 2),
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
            })}
          >
            <Typography
              variant="h6"
              title={signer?.name}
              sx={(theme) => ({
                marginBottom: theme.spacing(1),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
            >
              {signer?.name}
            </Typography>
            <Typography
              title={signer?.email}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {signer?.email}
            </Typography>
            <Chip
              label={
                <Typography variant="lead2" color={color[signer.status]}>
                  {status[signer.status]}
                </Typography>
              }
              color="primary"
              sx={(theme) => ({
                margin: theme.spacing(1, 0, 0),
                padding: theme.spacing(0, 2.8),
                backgroundColor: chipBackgroundColor[signer.status],
              })}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default DocumentStatus
