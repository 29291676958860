import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import HeaderImage from 'components/UI/Modal/HeaderImage'

import SOCIAL_BENEFIT_INFORMATION from './helpers'

export const SocialBenefitsResponse = ({ category, label }) => {
  const socialBenefitItems = SOCIAL_BENEFIT_INFORMATION[category]

  return (
    <section>
      <Typography
        variant="lead1"
        sx={{
          textAlign: 'center',
          width: '16rem !important',
          margin: 'auto',
        }}
      >
        ¡Genial! Acabas de liquidar {label}
      </Typography>
      <Box
        component="ol"
        sx={(theme) => ({
          paddingInlineStart: theme.spacing(2),
        })}
      >
        {socialBenefitItems?.map((item, index) => {
          const key = `social_benefit_item_${index}`
          return <li key={key}>{item}</li>
        })}
      </Box>
    </section>
  )
}

export const Header = () => {
  const { user } = useUser()

  const userName = user?.name || ''

  const title = `!Hola ${userName}!`
  return <HeaderImage title={title} />
}
