import { addDays, addYears, isSameDay, isSameMonth, parseISO } from 'date-fns'

import { Box, Typography, useMediaQuery } from '@mui/material'

import { formatDate } from 'utils/dateTime'

import { usePeriod } from '../../helpers'
import MobileCell from '../common/CardGrid/MobileCell'
import Tooltip from '../common/Tooltip'
import DateRangeItem from './DateRangeItem'
import { parseItemDay } from './helpers'

const DateRangeContent = ({
  title,
  data,
  onChangeNovelty,
  onDeleteNovelty,
  noveltyType,
  noveltyCode,
  contentTooltip,
  selectedDays,
  payroll = {},
}) => {
  const { items } = data
  const {
    period: { initial_day: periodInitialDay, end_day: periodEndDay },
  } = usePeriod()
  const {
    contract_initial_day: contractInitialDay,
    contract_end_day: contractEndDay,
  } = payroll
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        sx={(theme) => ({
          gridColumn: '2 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 1,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(1),
          })}
        >
          <Typography variant="h6" color="black.dark">
            {title}
          </Typography>
          {!isTabletUp && contentTooltip !== undefined ? (
            <Tooltip title={contentTooltip} disableInteractive={false} />
          ) : null}
        </Box>
      </MobileCell>
      <MobileCell
        alignX={isTabletUp ? 'left' : 'right'}
        alignY="center"
        sx={(theme) => ({
          gridColumn: '1 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 2,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: theme.spacing(2),
          })}
        >
          {items.map((novelty, index) => {
            const parsedInitialDay = parseItemDay(novelty.initial_day)
            const parsedEndDay = parseItemDay(novelty.end_day)

            let initialMinDate = periodInitialDay
            let initialMaxDate =
              noveltyType === 'holidays'
                ? addDays(periodEndDay, 1)
                : periodEndDay

            const endMinDate = parsedInitialDay || periodInitialDay
            let endMaxDate = addYears(new Date(), 10)

            const excludedDates = []

            Object.keys(selectedDays).forEach((noveltyCategory) => {
              const noveltiesByCategory = selectedDays[noveltyCategory]

              Object.keys(noveltiesByCategory).forEach(
                (noveltySubcategoryKey) => {
                  const subcategoryGroup =
                    noveltiesByCategory[noveltySubcategoryKey]

                  subcategoryGroup.forEach((noveltyDates, noveltyItemIndex) => {
                    // calculate min and max dates
                    noveltyDates.forEach((date) => {
                      // no dates selected
                      if (!parsedInitialDay && !parsedEndDay) {
                        if (date.getTime() >= initialMaxDate.getTime()) {
                          endMaxDate = date
                        }
                        // when only initialDay is selected
                      } else if (parsedInitialDay && !parsedEndDay) {
                        if (endMinDate < date && date < endMaxDate)
                          endMaxDate = date
                        // when only endDay is selected
                      } else if (parsedEndDay && !parsedInitialDay) {
                        if (date > initialMinDate && date < initialMaxDate) {
                          initialMinDate = date
                        }
                      } else if (
                        parsedEndDay &&
                        parsedInitialDay &&
                        (noveltyCategory !== noveltyType ||
                          noveltySubcategoryKey !== noveltyCode ||
                          noveltyItemIndex !== index)
                      ) {
                        if (date > endMinDate && date < endMaxDate)
                          endMaxDate = date
                      }
                    })

                    // if not current novelty
                    if (
                      noveltyCategory !== noveltyType ||
                      noveltySubcategoryKey !== noveltyCode ||
                      noveltyItemIndex !== index
                    ) {
                      excludedDates.push(noveltyDates)
                    }
                  })
                }
              )
            })

            // Validate min and max date between contract dates
            if (parseISO(contractEndDay) < initialMaxDate) {
              initialMaxDate = parseISO(contractEndDay)
              if (!isSameMonth(initialMinDate, initialMaxDate)) {
                initialMinDate = initialMaxDate
              }
            }
            if (initialMinDate < parseISO(contractInitialDay)) {
              initialMinDate = parseISO(contractInitialDay)
              if (contractEndDay && initialMaxDate > parseISO(contractEndDay)) {
                initialMaxDate = parseISO(contractEndDay)
              }
            }
            if (contractEndDay && endMaxDate > parseISO(contractEndDay)) {
              endMaxDate = parseISO(contractEndDay)
            }

            /**
             * This is a special case: when the start and end dates of the contract are the same
             * (it only lasts 1 day), user cannot add novelties, so the entire calendar is disabled
             */
            if (
              isSameDay(parseISO(contractInitialDay), parseISO(contractEndDay))
            ) {
              initialMinDate = parseISO(contractInitialDay)
              initialMaxDate = parseISO(contractInitialDay)

              // Exclude the contract date so it can't be selected
              excludedDates.push([parseISO(contractInitialDay)])
            }

            /**
             * In this case, if a person ends its contract on the first day of the second fortnight,
             * this day must be blocked because new novelties cannot be added
             */
            if (initialMaxDate < periodInitialDay) {
              initialMaxDate = periodInitialDay

              // Exclude the date so it can't be selected
              excludedDates.push([periodInitialDay])
            }

            const isNoveltyFromThisPayroll =
              !novelty.payroll_id || novelty.payroll_id === payroll.id

            return (
              <DateRangeItem
                key={novelty.id || index}
                index={index}
                noveltyType={noveltyType}
                noveltyCode={noveltyCode}
                initialDay={novelty.initial_day || null}
                initialMinDate={formatDate(initialMinDate)}
                initialMaxDate={formatDate(initialMaxDate)}
                endDay={novelty.end_day || null}
                endMinDate={formatDate(endMinDate)}
                endMaxDate={formatDate(endMaxDate)}
                excludedDates={excludedDates}
                onChangeNovelty={onChangeNovelty}
                onDeleteNovelty={onDeleteNovelty}
                isNoveltyFromThisPayroll={isNoveltyFromThisPayroll}
                periodInitialDay={periodInitialDay}
              />
            )
          })}
        </Box>
      </MobileCell>
      {isTabletUp && contentTooltip !== undefined ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title={contentTooltip} disableInteractive={false} />
        </Box>
      ) : null}
    </>
  )
}

export default DateRangeContent
