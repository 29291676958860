import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getItemsByCategory,
  updateDeductionItem,
} from 'services/payroll/itemsService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, payrollId, conceptsCategory } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'itemsByCategory')
    return getItemsByCategory(payrollId, conceptsCategory)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, payrollId, deductionItem } = mutationData

  if (mutationMethod === 'PUT')
    return updateDeductionItem(payrollId, deductionItem)

  return null
}

const useItemsService = ({
  serviceParams = { queryKey: 'payrollConcepts' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restItemsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const itemsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (err) => handleError(err),
      ...mutationOptions,
    }
  )

  return {
    itemsQuery: { data, ...restItemsResponse },
    itemsMutation,
  }
}

export default useItemsService
