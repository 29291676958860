const noveltiesConcepts = {
  deductions: 'Deducciones',
  incomes: 'Ingresos',
  licenses: 'Licencias',
  overtime: 'Extras y recargos',
  novelties: 'Vacac e incap',
  personal_note: 'Notas',
}

export const noveltiesConceptsExtended = {
  deductions: 'Deducciones, préstamos y retefuente',
  incomes: 'Ingresos salariales y no salariales',
  licenses: 'Licencias remuneradas y no remuneradas',
  overtime: 'Horas extras y otros recargos',
  novelties: 'Vacaciones e incapacidades',
  personal_note: 'Notas',
}

export const getTabsConfig = (errorsObject, useShortCut) =>
  Object.keys(errorsObject).map((page) => ({
    label: useShortCut
      ? noveltiesConcepts[page]
      : noveltiesConceptsExtended[page],
    content: errorsObject[page],
  }))

export const getErrorsList = (tabsConfig, activeTab) =>
  tabsConfig[activeTab].content.main_errors.map((error) => ({
    workerId: error.worker_id,
    workerName: error.name,
    workerErrors: error.error_details
      ? Object.values(error.error_details).map((workerError) => workerError)
      : [],
  }))
