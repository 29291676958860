import { useMutation } from 'react-query'

import { updateInformartions } from 'services/organizer/partnerService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, partner } = mutationData

  if (mutationMethod === 'PUT') return updateInformartions(partner)

  return null
}

const usePartnerOrganizerService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const partnerOrganizerMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    partnerOrganizerMutation,
  }
}

export default usePartnerOrganizerService
