import { AppBar, Box, useMediaQuery } from '@mui/material'

import { isWorker } from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import Breadcrumbs from './Breadcrumbs/Index'
import { useBreadcrumbs } from './Breadcrumbs/helpers'
import NotificationsPopover from './CourierNotifications'
import HeadwayButton from './HeadwayButton'
import HelpButton from './HelpButton'
import UserButton from './UserButton'

const Header = () => {
  const breadcrumbs = useBreadcrumbs()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const showHeaderButton = hasSelectedCompany() && !isWorker()

  return (
    <AppBar
      sx={(theme) => ({
        position: 'static',
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: 'none',
        height: theme.mobileAppbar,
        padding: theme.spacing(0, 2.5, 0, 2),
        [theme.breakpoints.up('laptop')]: {
          height: theme.headerHeight,
          padding: theme.spacing(0, 5, 0, 4),
        },
        ...(!(breadcrumbs.length > 1) &&
          xsDown && {
            justifyContent: 'flex-end',
          }),
      })}
    >
      <Breadcrumbs />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          alignItems: 'center',
          columnGap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridAutoFlow: 'column',
            alignItems: 'center',
            columnGap: theme.spacing(1),
          })}
        >
          <HelpButton />
          {showHeaderButton ? <HeadwayButton /> : null}
          {showHeaderButton ? <NotificationsPopover /> : null}
        </Box>
        <UserButton />
      </Box>
    </AppBar>
  )
}

export default Header
