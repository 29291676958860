import { Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'

import { partyingFace } from 'utils/emojis'

const Header = ({ status }) => {
  return (
    <>
      {status === 'in_progress' ? (
        <Typography variant="h3">Aún hay firmas pendientes</Typography>
      ) : null}

      {status === 'finished' ? (
        <Typography variant="h3">
          Wujuu tus contratos han sido firmados <Emoji code={partyingFace} />
        </Typography>
      ) : null}
    </>
  )
}

export default Header
