import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Link, Typography } from '@mui/material'

import ActionButton from 'components/Period/Payroll/DataTable/ActionButton'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import useDianMaintenance from 'utils/hooks/ElectronicPayroll/useDianMaintenance'

import { getElectronicPayrollHistoryByWorker } from 'services/payroll/electronicPayrollPeriodService'

import SingleEmittedRejectedModal from '../ModalRejected/SingleEmittedRejectedModal'
import useSingleEmittedRejectedModal from '../ModalRejected/useSingleEmittedRejectedModal'
import useSendElectronicPayrolls from '../useSendElectronicPayrolls'
import { getColumns, useActionsTable } from './helpers'

const ElectronicPayrollWorkerHistory = () => {
  const [activeStatus, setActiveStatus] = useState()
  const [workerName, setWorkerName] = useState('')

  const { periodId, workerId } = useParams()
  const { maintenance } = useDianMaintenance()
  const {
    closeSingleEmittedRejectedModal,
    openSingleEmittedRejectedModal,
    singleEmittedRejectedModalState,
  } = useSingleEmittedRejectedModal()
  const { handleSendElectronicPayroll, setIsLoading, isLoading } =
    useSendElectronicPayrolls({
      invalidateQueries: 'getElectronicPayrollHistoryByWorker',
    })

  const fetchHistoriesByWorker = (pageIndex) => {
    return {
      queryKey: [
        'getElectronicPayrollHistoryByWorker',
        pageIndex + 1,
        activeStatus,
        periodId,
        workerId,
      ],
      queryFunction: () =>
        getElectronicPayrollHistoryByWorker({
          page: pageIndex + 1,
          periodId,
          workerId,
          filter: activeStatus,
        }),
      queryOptions: {
        onSuccess: ({ data }) =>
          setWorkerName(`${data.worker.name} ${data.worker.last_name}`),
      },
    }
  }

  const columns = getColumns(openSingleEmittedRejectedModal)
  const actions = useActionsTable({
    periodId,
    maintenance,
    activeStatus,
    setActiveStatus,
    handleSendElectronicPayroll,
    setIsLoading,
    isLoading,
  })

  return (
    <Page
      header={`Historial NE de ${workerName}`}
      documentTitle="Historial individual"
      isLoading={false}
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Box sx={(theme) => ({ marginBottom: theme.spacing(7) })}>
          <Typography>
            Este es el historial de Nóminas Electrónicas. Aquí puedes ver todas
            las Nóminas Electrónicas que una persona ha tenido desde que fue
            creada en Aleluya. Revisa{' '}
            <Link
              href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/preguntas-frecuentes-sobre-la-emisión-de-nómina-electrónica"
              target="_blank"
              underline="hover"
              color="accent4.main"
              fontWeight={700}
            >
              este artículo
            </Link>{' '}
            de preguntas frecuentes sobre la emisión de Nómina Electrónica
          </Typography>
        </Box>
        <Table
          data={fetchHistoriesByWorker}
          columns={columns}
          actions={actions}
          options={{
            version: {
              toolbar: 'v2',
            },
            search: false,
            customQueryFetch: 'electronic_payrolls',
            alignActionsCell: 'center',
          }}
          components={{
            ActionButton,
          }}
        />
      </Box>

      {singleEmittedRejectedModalState.open ? (
        <SingleEmittedRejectedModal
          closeModal={closeSingleEmittedRejectedModal}
          state={singleEmittedRejectedModalState}
        />
      ) : null}
    </Page>
  )
}

export default ElectronicPayrollWorkerHistory
