import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import {
  generateWageBaseSalaryValidationSchema,
  profileImageRequirements,
} from 'utils/worker'

yup.setLocale(yupLocaleES)

const wageBaseSalaryValidationSchema = generateWageBaseSalaryValidationSchema()

const { supported_formats, max_size, min_size } = profileImageRequirements

export const stepsData = [
  {
    title: 'Paso 1',
    description: 'Datos básicos',
    schemaValidation: yup.object({
      name: yup.string().trim().required(),
      last_name: yup.string().trim().required(),
      document_type: yup.string().trim().required(),
      email: yup.string().nullable().email().when('active_user', {
        is: true,
        then: yup.string().nullable().email().required(),
      }),
      active_user: yup.boolean().nullable().required(),
      picture: yup
        .mixed()
        .nullable()
        .test('fileSize', max_size.message, (value) => {
          if (!value) return true

          if (value instanceof File) {
            return value.size <= max_size.value
          }

          return true
        })
        .test('fileSize', min_size.message, (value) => {
          if (!value) return true

          if (value instanceof File) {
            return value.size > min_size.value
          }

          return true
        })
        .test('fileType', supported_formats.message, (value) => {
          if (!value) return true

          if (value instanceof File) {
            return supported_formats.list.includes(value.type)
          }

          return true
        }),
    }),
  },
  {
    title: 'Paso 2',
    description: 'Información laboral',
    schemaValidation: yup.object({
      // Labor Information
      term: yup.string().nullable().required(),
      contract_category: yup.string().nullable().required(),
      initial_day: yup.date().nullable().default(null).required(),
      end_day: yup
        .date()
        .nullable()
        .default(null)
        .when('term', {
          is: (term) => term === 'fixed' || term === 'project',
          then: yup.date().nullable().default(null).required(),
        }),
      wage_category: yup.string().nullable().required(),
      base_salary: wageBaseSalaryValidationSchema,
      high_risk_pension: yup
        .boolean()
        .nullable()
        .when(['work_center', 'contributor_subtype'], {
          is: (workCenter, contributorSubtype) =>
            Boolean(workCenter?.name?.includes('5')) &&
            contributorSubtype === 'no_subtype',
          then: yup.boolean().nullable().required(),
        }),

      // worker station
      location: yup.object().nullable().required(),
      area: yup.object().nullable().required(),
      position: yup.object().nullable().required(),
      cost_center: yup.object().nullable().required(),
      transport_subsidy: yup
        .boolean()
        .nullable()
        .when('contract_category', {
          is: (contractCategory) =>
            contractCategory && contractCategory !== 'contractor',
          then: yup.boolean().nullable().required(),
        }),
      contributor_subtype: yup
        .string()
        .nullable()
        .when('contract_category', {
          is: (contractCategory) =>
            contractCategory &&
            ['employee', 'part_time_contract'].includes(contractCategory),
          then: yup.string().nullable().required(),
        }),
      work_center: yup
        .object()
        .nullable()
        .when('contract_category', {
          is: (contractCategory) =>
            contractCategory &&
            contractCategory !== 'contractor' &&
            contractCategory !== 'schooling_trainee',
          then: yup.object().nullable().required(),
        }),

      // Days off
      rest_days: yup.array().of(yup.object().nullable()).nullable(),
      enjoyed_days_since_hired: yup.number().required(),
    }),
  },
  {
    title: 'Paso 3',
    description: 'Datos de pago',
    schemaValidation: yup.object({
      payment_method: yup.string().nullable().required(),
      bank: yup.mixed().when('payment_method', {
        is: 'wire_transfer',
        then: yup.object().nullable().required(),
        otherwise: yup.mixed().nullable(),
      }),
      account_type: yup.string().nullable().when('payment_method', {
        is: 'wire_transfer',
        then: yup.string().nullable().required(),
      }),
      account_number: yup.number().when('payment_method', {
        is: 'wire_transfer',
        then: yup.number().nullable().required(),
        otherwise: yup.number().nullable(),
      }),
    }),
  },
  {
    title: 'Paso 4',
    description: 'Datos personales',
    schemaValidation: yup.object({
      birthday: yup.date().nullable().default(null),
      address: yup.string().trim().nullable(),
      phone: yup.string().trim().nullable().required(),
      health_provider: yup
        .object()
        .nullable()
        .when('contract_category', {
          is: (contractCategory) =>
            contractCategory &&
            ![
              'contractor',
              'student_decree_055',
              'part_time_contract',
            ].includes(contractCategory),
          then: yup.object().nullable().required(),
        }),
      pension_provider: yup
        .object()
        .nullable()
        .when(['contract_category', 'document_type', 'contributor_subtype'], {
          is: (contractCategory, documentType, contributorSubtype) =>
            ['ce_no_pension', 'pe_no_pension', 'pt_no_pension'].includes(
              documentType
            ) || contributorSubtype !== 'no_subtype'
              ? false
              : contractCategory &&
                ['employee', 'student_law_789', 'part_time_contract'].includes(
                  contractCategory
                ),
          then: yup.object().nullable().required(),
        }),
      severance_provider: yup
        .object()
        .nullable()
        .when(['contract_category', 'wage_category'], {
          is: (contractCategory, wageCategory) =>
            contractCategory &&
            wageCategory &&
            ['employee', 'part_time_contract'].includes(contractCategory) &&
            wageCategory !== 'integral_salary',
          then: yup.object().nullable().required(),
        }),
    }),
  },
]

export const getInitialValues = (worker) => {
  const initialValues = {
    // STEP 0 Basic
    id: worker?.id || null,
    document_type: worker?.document_type || 'cc',
    id_number: worker?.id_number || null,
    name: worker?.name || '',
    last_name: worker?.last_name || '',
    email: worker?.email || null,
    active_user:
      typeof worker?.active_user === 'boolean' ? worker?.active_user : true,
    picture: worker?.picture || '',
    // STEP 1 laboral
    contract_category: worker?.contract_category || null,
    term: worker?.term || null,
    initial_day: worker?.initial_day || null,
    end_day: worker?.end_day || null,
    base_salary: worker?.base_salary || null,
    contributor_subtype:
      worker?.contributor_subtype ||
      worker?.contract_detail?.contributor_subtype ||
      null,
    high_risk_pension: worker?.high_risk_pension,
    work_center: worker?.work_center || null,
    transport_subsidy:
      typeof worker?.transport_subsidy === 'boolean'
        ? worker?.transport_subsidy
        : null,
    // WorkState
    wage_category: worker?.wage_category || null,
    position: worker?.position?.id ? worker?.position : null,
    location: worker?.location?.id ? worker?.location : null,
    area: worker?.area?.id ? worker.area : null,
    cost_center: worker?.cost_center?.id ? worker?.cost_center : null,

    // STEP 2 Payment
    payment_method: worker?.payment_method || null,
    bank: worker?.bank?.id ? worker.bank : null,
    account_type: worker?.account_type || null,
    account_number: worker?.account_number || null,

    // STEP 3 Personal
    birthday: worker?.birthday || null,
    address: worker?.address || null,
    phone: worker?.phone || null,
    // Social Security
    health_provider: worker?.health_provider?.id
      ? worker?.health_provider
      : null,
    pension_provider: worker?.pension_provider?.id
      ? worker?.pension_provider
      : null,
    severance_provider: worker?.severance_provider?.id
      ? worker?.severance_provider
      : null,
  }

  if (worker?.contract_category?.value !== 'contractor') {
    Object.assign(initialValues, {
      accumulated_holidays: worker?.accumulated_holidays
        ? Number(worker?.accumulated_holidays)
        : 0,
      enjoyed_days_since_hired: worker?.enjoyed_days_since_hired
        ? Number(worker?.enjoyed_days_since_hired)
        : 0,
      rest_days:
        !worker?.rest_days || worker?.rest_days.length === 0
          ? ['6', '0']
          : worker?.rest_days,
    })
  }

  return initialValues
}
