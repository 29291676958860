import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import { getCompanyId } from 'utils/company'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import fileService from 'services/payroll/fileService'
import payrollService from 'services/payroll/payrollService'

import * as routes from 'config/routes'

const initialState = {
  open: false,
  payroll: null,
  paymentTypes: [],
  contractTypes: [],
  fileFormats: [],
  payrollRanges: [],
  bankName: null,
}

/**
 * Used in conjunction with BankFileModal
 * @param {Object} period If not specified, the modal will only works for payroll bank file
 */
const useBankFileModal = (period) => {
  const [state, setState] = useState({ ...initialState, period })
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const { handleError } = useErrorHandler()

  const confirm = useConfirm()
  const navigate = useNavigate()

  useEffect(() => {
    setState({ ...initialState, period })
  }, [period])
  /**
   * Required options:
   *
   * For period: Do not specify payroll option
   *
   * For payroll: Must specify payroll opiion
   *
   * @param {Object} options
   */
  const openBankFileModal = useCallback(
    async ({ payroll } = {}) => {
      showLoadingModal()

      let newState

      try {
        if (payroll) {
          const {
            payment_type: paymentTypes,
            payroll_range: payrollRanges,
            file_format: fileFormats,
            bank_name: bankName,
          } = await payrollService.getBankFileOptions(payroll.id)

          newState = {
            payroll,
            paymentTypes,
            payrollRanges,
            fileFormats,
            bankName,
          }
        } else if (period) {
          // from period
          const {
            payment_type: paymentTypes,
            contract_type: contractTypes,
            file_format: fileFormats,
            bank_name: bankName,
            location,
          } = await fileService.getBankFileOptions(period.id)

          newState = {
            paymentTypes,
            contractTypes,
            fileFormats,
            bankName,
            locations: location,
          }
        } else {
          throw new Error(
            'No payroll or period data specified for BankFileModal'
          )
        }

        newState.open = true

        setState((previousState) => ({
          ...previousState,
          ...newState,
        }))
      } catch (error) {
        if (error.errors?.[0].code === '0604') {
          confirm({
            type: 'info',
            okText: 'Editar empresa',
            title: 'Completa la información de tu empresa',
            description:
              'Para poder generar el archivo de pago en bancos, debes completar la información de tu empresa como el banco o el número de cuenta.',
            onOk: () =>
              navigate(routes.COMPANY_EDIT(getCompanyId()), {
                state: {
                  initialStep: 5,
                },
              }),
          })
        } else {
          handleError(error)
        }
      }

      hideLoadingModal()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, period]
  )

  const closeBankFileModal = () => {
    setState({ ...initialState, period })
  }

  return {
    bankFileModalState: state,
    openBankFileModal,
    closeBankFileModal,
  }
}

export default useBankFileModal
