import { useState } from 'react'

const useAffiliationFilesModal = () => {
  const [state, setState] = useState({
    open: false,
    workerId: null,
  })

  const closeAffiliationFilesModal = () => {
    setState(() => ({
      open: false,
      workerId: null,
    }))
  }

  const openAffiliationFilesModal = (workerId) => {
    setState(() => ({
      open: true,
      workerId,
    }))
  }

  return {
    affiliationFilesModalState: state,
    closeAffiliationFilesModal,
    openAffiliationFilesModal,
  }
}

export default useAffiliationFilesModal
