import { Box, Chip, alpha } from '@mui/material'

import { useTableStyles } from '../helpers'

const renderCellContent = (cell, renderEmpty) => {
  if (renderEmpty) return cell.column.emptyValue

  if (cell.column.type !== undefined) {
    if (cell.column.type === 'boolean')
      return cell.value === true ? (
        <Chip
          label="Activo"
          sx={(theme) => ({
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.dark,
            width: '6rem',
          })}
        />
      ) : (
        <Chip
          label="Inactivo"
          sx={(theme) => ({
            backgroundColor: alpha(theme.palette.error.main, 0.2),
            color: theme.palette.error.dark,
            width: '6rem',
          })}
        />
      )
    return null
  }

  return cell.render('Cell')
}

const RowCell = ({ cell }) => {
  const { getCellStyles } = useTableStyles()
  const hasEmptyValue =
    cell.column.emptyValue !== undefined &&
    cell.column.emptyValue !== null &&
    (cell.value === undefined || cell.value === null) &&
    cell.column.id !== 'actions'

  return (
    <Box
      sx={getCellStyles(cell.column)}
      style={{
        ...cell.column.cellStyle,
        width: `calc(${cell.column.customWidth} - 32px)`,
      }}
    >
      {renderCellContent(cell, hasEmptyValue)}
    </Box>
  )
}

export default RowCell
