import FormField from 'components/UI/Formik/FormField/Index'

import { accountTypesOptions } from 'utils/company'

const AccountTypeField = ({ optional, name, ...props }) => {
  return (
    <FormField
      name={name || 'account_type'}
      label="Tipo de cuenta"
      variant="select"
      placeholder="Selecciona el tipo de cuenta..."
      options={accountTypesOptions}
      optional={optional}
      {...props}
    />
  )
}

export default AccountTypeField
