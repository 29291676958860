import http, { getApiURL } from './httpService'

/**
 *
 * @param {'greet_message' | 'pendings' | 'next_payments' | 'comming_soon' | 'variations' | 'payroll_costs' | 'costs_in_percentages'} metrics
 */

export const getDataByMetrics = (metrics) => {
  const url = getApiURL({
    pathname: '/company_dashboard',
    searchParams: { metrics },
  })

  return http.getV2(url)
}

export default {
  getDataByMetrics,
}
