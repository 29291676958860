import { getCompanyId } from 'utils/company'

import http, { getApiURL } from '../httpService'

export const getPlans = (companyId) => {
  const url = getApiURL({
    companyId,
    pathname: '/plans',
  })

  return http.getV2(url)
}

export const createPayment = (companyId, payment) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/bank_transfer`,
  })

  return http.postV2(url, payment)
}

export const deletePayment = (paymentId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${getCompanyId()}/payments/${paymentId}`,
  })

  return http.deleteV2(url)
}

export const updateCurrentPayment = (payment) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/current_payment`,
    withoutVersion: true,
  })

  return http.patchV2(url, { payment })
}

export const updatePreviousPayment = (payment, paymentId) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/payments/${paymentId}`,
    withoutVersion: true,
  })

  return http.putV2(url, { payment })
}

export default {
  getPlans,
  createPayment,
  updateCurrentPayment,
  updatePreviousPayment,
  deletePayment,
}
