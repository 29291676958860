import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'

const EntitiesSection = () => {
  const { values } = useFormikContext()

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
      })}
    >
      <HealthProviderField
        contractCategory={values.contract_category}
        optional={false}
      />
      <PensionProviderField
        documentType={values.document_type}
        contractCategory={values.contract_category}
        optional={false}
        contributorSubtype={values.contract_detail?.contributor_subtype}
      />
      <SeveranceProviderField
        wageCategory={values.wage_category}
        contractCategory={values.contract_category}
        optional={false}
      />
    </Box>
  )
}

export default EntitiesSection
