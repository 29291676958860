import { useNavigate } from 'react-router-dom'

import { Box, Button, Link, Typography } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'

import useDianMaintenance from 'utils/hooks/ElectronicPayroll/useDianMaintenance'

import { DASHBOARD } from 'config/routes'

import ConfigurationProcess from './ConfigurationProcess/Index'
import EmptyState from './EmptyState'
import RequiredDataItem from './RequiredDataItem'

const ElectronicPayrollContent = ({ state, dispatch }) => {
  const navigate = useNavigate()
  const { maintenance, maintenanceDate } = useDianMaintenance()
  const electronicPayrollMaintenance = maintenance ? maintenanceDate : false
  const userName = localStorage.getItem('user_name')

  const showConfigurationForm = () =>
    dispatch({ type: 'show_form_from_banner' })

  const handleEditConfiguration = () =>
    dispatch({ type: 'show_only_config_form' })

  const redirectToDashboard = () => navigate(DASHBOARD)

  const closeForm = () => dispatch({ type: 'show_success_banner' })

  const endFreeElectronicPayrollModal = useEndFreeModals()

  if (!state.firstAttempt && state.showWelcomeBanner) {
    return (
      <EmptyState
        title="Estás a punto de configurar tu nómina electrónica"
        maintenance={electronicPayrollMaintenance}
      >
        <Typography
          variant="h6"
          sx={(theme) => ({
            margin: theme.spacing(3, 0),
          })}
        >
          Antes de comenzar es importante que tengas esta información a la mano:
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridAutoColumns: '1fr',
            gap: theme.spacing(2.5),
            marginBottom: theme.spacing(6),
          })}
        >
          <RequiredDataItem label="Datos de acceso al portal de la DIAN - MUISCA" />
          <RequiredDataItem label="Datos legales de la empresa (NIT, Datos del representante legal)" />
          <RequiredDataItem label="Acceso al correo registrado en el RUT" />
        </Box>
        <Button
          onClick={
            endFreeElectronicPayrollModal.isNotValidCompany
              ? () =>
                  endFreeElectronicPayrollModal.openEndFreeElectronicPayrollModal()
              : showConfigurationForm
          }
          disabled={maintenance}
        >
          Comenzar configuración
        </Button>
      </EmptyState>
    )
  }

  if (state.firstAttempt && state.showSuccessBanner) {
    return (
      <EmptyState
        title="¡Ya estás habilitado para emitir nómina electrónica!"
        maintenance={electronicPayrollMaintenance}
      >
        <Typography
          variant="body1"
          sx={(theme) => ({
            margin: theme.spacing(3.5, 0, 10.5, 0),
          })}
        >
          Eh Ave María, ¿así o más fácil {userName}?
          <br />
          <br />
          Recuerda que debes emitir la Nómina Electrónica los primeros 10 días
          hábiles después de cada mes.
          <br />
          <br />
          Aquí te enseñamos{' '}
          <Link
            href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/verifica-que-estás-habilitado-para-emitir-tu-nómina-electrónica-y-el-estado-de-tu-set-de-pruebas-en-el-portal-de-la-dian"
            target="_blank"
            underline="hover"
          >
            cómo emitir tu primera Nómina Electrónica.
          </Link>
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(2),
            flexDirection: 'column',
            [theme.breakpoints.up('tablet')]: {
              flexDirection: 'row',
            },
          })}
        >
          <Button
            variant="outlined"
            onClick={handleEditConfiguration}
            disabled={maintenance}
          >
            Editar configuración
          </Button>
          <Button onClick={redirectToDashboard}>
            Ir a la torre de control
          </Button>
        </Box>
      </EmptyState>
    )
  }

  return (
    <ConfigurationProcess
      dispatch={dispatch}
      closeForm={closeForm}
      comeFromEdit={!state.firstAttempt}
      shouldShowSuccessBanner={state.firstAttempt}
    />
  )
}

export default ElectronicPayrollContent
