import { useTheme } from '@mui/material'

import Loading from 'components/UI/Loading/Loading'

import { getCompanyId } from 'utils/company'
import { removeSuffix } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { getDetailCard } from '../../helpers'
import PaymentExoneratedSubscriptionModalCard from './PaymentExoneratedSubscriptionModalCard'

const PaymentExoneratedSubscriptionModal = () => {
  const themeInstance = useTheme()

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', getCompanyId()],
    },
  })

  const plansData =
    (plansQuery?.data?.plans || []).filter(
      (plan) => removeSuffix(plan.coded_name, -8) !== 'pro_per_worker_plan'
    ) || []

  const plans = getDetailCard(plansData, themeInstance) || []
  const currentPlan = plans?.find((plan) => plan?.planData.current_plan)
  const { planId, cardsStyles, codedName, planData } = currentPlan || {}

  const { isLoading } = plansQuery || {}

  return isLoading ? (
    <Loading />
  ) : (
    <PaymentExoneratedSubscriptionModalCard
      key={planId}
      cardsStyles={cardsStyles}
      codedName={codedName}
      planData={planData}
    />
  )
}

export default PaymentExoneratedSubscriptionModal
