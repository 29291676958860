import { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { Box, Paper } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'

import { DASHBOARD } from 'config/routes'

import MessageBubble from '../Atoms/MessageBubble'
import StepCard from './StepCard'
import { getFirstSteps, getMessage } from './helpers'

const FirstSteps = () => {
  const { company, user } = useUser()
  const { onboarding_first_steps: onboardingFirstSteps = {} } = company
  const steps = getFirstSteps(onboardingFirstSteps)
  const progressValue = useMemo(
    () =>
      (steps.filter((step) => step.status === 'completed')?.length /
        steps.length) *
      100,
    [steps]
  )
  const activeStep = steps.find((step) => step.status === 'active')
  const remainingSteps = steps.filter((step) =>
    ['pending', 'active'].includes(step.status)
  ).length

  if (onboardingFirstSteps?.finished_all_steps) {
    return <Navigate to={DASHBOARD} />
  }

  return (
    <Page documentTitle="Primeros pasos">
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(4.5),
        })}
      >
        <Paper
          sx={(theme) => ({
            maxWidth: '35.625rem',
            width: '100%',
            padding: theme.spacing(2.5),
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(2),
          })}
        >
          <MessageBubble
            message={getMessage(
              user.name || '',
              remainingSteps,
              activeStep?.id || null
            )}
            progressValue={progressValue}
          />
          {steps.map((step) => (
            <StepCard
              key={step.id}
              number={step.number}
              title={step.title}
              description={step.description}
              status={step.status}
              path={step.path}
              disabled={['completed', 'pending'].includes(step.status)}
            />
          ))}
        </Paper>
      </Box>
    </Page>
  )
}

export default FirstSteps
