import http, { getApiURL } from '../httpService'

export const getWorkHoursConfiguration = () => {
  const url = getApiURL({
    pathname: `/work_hours_configurations`,
  })

  return http.getV2(url)
}

export const createWorkHoursConfiguration = (workHoursConfigurationData) => {
  const url = getApiURL({
    pathname: `/work_hours_configurations`,
  })

  return http.postV2(url, workHoursConfigurationData)
}

export const editWorkHoursConfiguration = (
  workHoursConfigurationsId,
  workHoursConfigurationData
) => {
  const url = getApiURL({
    pathname: `/work_hours_configurations/${workHoursConfigurationsId}`,
  })

  return http.patchV2(url, workHoursConfigurationData)
}

export const deleteWorkHoursConfiguration = (workHoursConfigurationsId) => {
  const url = getApiURL({
    pathname: `/work_hours_configurations/${workHoursConfigurationsId}`,
  })

  return http.deleteV2(url)
}

export default {
  getWorkHoursConfiguration,
  createWorkHoursConfiguration,
  editWorkHoursConfiguration,
  deleteWorkHoursConfiguration,
}
