import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import logoAEL from 'assets/images/logos/aportes_en_linea/logo_aportes_en_linea.png'
import logoArus from 'assets/images/logos/arus/logo_arus_dark.png'

const ProviderModal = ({
  open,
  onCancel,
  selectedProvider,
  onChangeProvider,
  onOk,
}) => {
  const onChange = (event) => {
    onChangeProvider(event.target.value)
  }

  return (
    <Modal
      open={open}
      header={
        <Typography
          variant="h5"
          sx={(theme) => ({
            paddingRight: theme.spacing(2),
          })}
        >
          ¡Estamos listos! vamos a pagar tu seguridad social sin salir de
          Aleluya
        </Typography>
      }
      okText="Continuar"
      onOk={onOk}
      onCancel={onCancel}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
    >
      <Typography variant="body1" color="black.dark">
        Para continuar primero debes seleccionar con qué operador quieres pagar
        tu seguridad social.
      </Typography>
      <RadioGroup
        aria-label="Proveedor seguridad social"
        name="provider"
        value={selectedProvider}
        onChange={onChange}
        row
        sx={(theme) => ({
          marginTop: theme.spacing(2),
        })}
      >
        <FormControlLabel
          value="aportes_en_linea"
          control={<Radio />}
          label={
            <img
              src={logoAEL}
              alt="Logo aportes en línea"
              width={91}
              height={32}
            />
          }
        />
        <FormControlLabel
          value="arus"
          control={<Radio />}
          label={<img src={logoArus} alt="Logo arus" width={140} height={32} />}
        />
      </RadioGroup>
    </Modal>
  )
}

export default ProviderModal
