import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  circularProgressClasses,
} from '@mui/material'

import ProfileImg from 'components/UI/ProfileImg'

const MessageBubble = ({ message, progressValue }) => {
  const showProgress = progressValue !== undefined && progressValue >= 0

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '6.25rem 1fr',
        columnGap: theme.spacing(1),
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: '1fr',
        },
      })}
    >
      <Box
        sx={{
          width: '6.25rem',
          height: '6.25rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Avatar
          alt="Cara primeros pasos"
          sx={(theme) => ({
            width: '4.375rem',
            height: '4.375rem',
            backgroundColor: theme.palette.complementary1.main,
            ...(!showProgress && {
              width: '5.625rem',
              height: '5.625rem',
              backgroundColor: theme.palette.complementary1.main,
            }),
          })}
        >
          <ProfileImg color="black" height={50} width={50} />
        </Avatar>
        {showProgress ? (
          <CircularProgress
            size="6.25rem"
            sx={(theme) => ({
              position: 'absolute',
              color: theme.palette.success.main,
              [`& .${circularProgressClasses.circle}`]: {
                strokeWidth: 2,
              },
            })}
            variant="determinate"
            value={progressValue}
          />
        ) : null}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            width: '1.5625rem',
            height: '1.5625rem',
            backgroundColor: theme.palette.primary.main,
            transform: 'rotate(45deg)',
            marginRight: theme.spacing(-1.75),
            [theme.breakpoints.down('sm')]: {
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(-1.75),
              marginRight: 0,
            },
          })}
        />
        <Box
          sx={(theme) => ({
            '& > p': {
              color: theme.palette.white.main,
            },
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.black.main,
            borderRadius: theme.spacing(2),
            position: 'relative',
            padding: theme.spacing(2),
          })}
        >
          <Typography variant="body1">{message}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default MessageBubble
