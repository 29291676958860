import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'

import { calculateVerificationDigit } from 'utils/company'
import { generateBasicNumberParser } from 'utils/format'

import FormField from '../FormField/Index'

const BankAccountHolderIdentificationNumber = ({
  disabled = false,
  label,
  disableValidate,
  name,
}) => {
  const form = useFormikContext()
  const { values } = form

  return values.document_type === 'ni' ? (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '5fr 1fr',
        alignItems: 'start',
        gridGap: theme.spacing(1),
      })}
    >
      <IdentificationNumberField
        name={name}
        disableValidate={disableValidate}
        onChange={({ target: { value } }) => {
          const parser = generateBasicNumberParser({
            max: 9,
            convertToNumber: false,
          })

          const parsed = parser(value)

          if (values.id_number !== parsed) {
            form.setValues({
              ...values,
              id_number: parsed,
              verification_digit: calculateVerificationDigit(parsed),
            })
          }
        }}
        isCompanyId
        disabled={disabled}
        label={label}
        optional={false}
      />
      <FormField name="verification_digit" label="DV" disabled />
    </Box>
  ) : (
    <IdentificationNumberField
      name={name}
      disableValidate={disableValidate}
      disabled={disabled}
      label={label}
      optional={false}
    />
  )
}

export default BankAccountHolderIdentificationNumber
