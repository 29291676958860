import {
  Box,
  Divider,
  Step,
  Stepper,
  Typography,
  alpha,
  stepConnectorClasses,
  useMediaQuery,
} from '@mui/material'

import { formatDisplayDateString } from 'utils/dateTime'

import { ENTITY_LABELS, getAffiliationEntities } from '../helpers'

const CertificateState = ({
  isCertificateCompleted,
  isAffiliationCompleted,
}) => (
  <Typography
    variant="tiny"
    sx={(theme) => ({
      width: 'fit-content',
      textAlign: 'center',
      padding: theme.spacing(0.5, 1),
      backgroundColor: isCertificateCompleted
        ? alpha(theme.palette.primary.light, 0.4)
        : isAffiliationCompleted
          ? alpha(theme.palette.error.light, 0.4)
          : alpha(theme.palette.info.light, 0.4),
      color: isCertificateCompleted
        ? theme.palette.primary.dark
        : isAffiliationCompleted
          ? theme.palette.error.dark
          : theme.palette.info.dark,
      borderRadius: '0.75rem',
    })}
  >
    {isCertificateCompleted
      ? 'Completada'
      : isAffiliationCompleted
        ? 'Descartada'
        : 'En proceso'}
  </Typography>
)

const StepIcon = ({
  isCertificateCompleted,
  isAffiliationCompleted,
  index,
}) => {
  return (
    <Typography
      variant="h5"
      sx={(theme) => ({
        color: theme.palette.white.main,
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.black.light,
        ...(isCertificateCompleted && {
          backgroundColor: theme.palette.primary.dark,
        }),
        ...(isAffiliationCompleted &&
          !isCertificateCompleted && {
            backgroundColor: theme.palette.error.dark,
          }),
      })}
    >
      {index + 1}
    </Typography>
  )
}

const AffiliationStepper = ({
  certificates,
  createdAt,
  affiliationCompleted,
}) => {
  const affiliationEntities = getAffiliationEntities(certificates)

  const isMobileBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down('tablet')
  )

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('tablet')]: {
          width: 'fit-content',
          gap: theme.spacing(2),
        },
      })}
    >
      <Typography variant="lead1" color="black.dark">
        Iniciada el {formatDisplayDateString(createdAt)}
      </Typography>
      <Stepper
        alternativeLabel={isMobileBreakpoint ? false : true}
        orientation={isMobileBreakpoint ? 'vertical' : 'horizontal'}
        sx={{
          [`& .${stepConnectorClasses.line}`]: {
            display: 'none',
          },
        }}
      >
        {affiliationEntities?.map(
          (
            {
              completed,
              completion_date: completionDate,
              institution_name: institutionName,
              provider_type: providerType,
            },
            index
          ) => {
            return (
              <Step
                key={institutionName}
                completed={completed}
                sx={(theme) => ({
                  padding: theme.spacing(0),
                  marginRight: theme.spacing(4),
                  [theme.breakpoints.down('tablet')]: {
                    marginRight: theme.spacing(0),
                    marginBottom: theme.spacing(2),
                    height: '5rem',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: theme.spacing(1),
                    [theme.breakpoints.down('tablet')]: {
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: 'fit-content',
                      gap: theme.spacing(2),
                    },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      width: 'calc(100% + 2rem)',
                      alignItems: 'center',
                      [theme.breakpoints.down('tablet')]: {
                        flexDirection: 'column',
                        alignItems: 'center',
                        verticalAlign: 'top',
                        width: 'fit-content',
                        height: 'calc(100% + 1rem)',
                      },
                    })}
                  >
                    <StepIcon
                      isCertificateCompleted={completed}
                      isAffiliationCompleted={affiliationCompleted}
                      index={index}
                    />
                    {index + 1 < affiliationEntities?.length ? (
                      <Divider
                        orientation={
                          isMobileBreakpoint ? 'vertical' : 'horizontal'
                        }
                        sx={(theme) => ({
                          width: 'calc(100% - 2.5rem)',
                          border: '0.085rem solid',
                          borderColor: theme.palette.black.light,
                          ...(completed && {
                            borderColor: theme.palette.primary.dark,
                          }),
                          ...(affiliationCompleted &&
                            !completed && {
                              borderColor: theme.palette.error.dark,
                            }),
                          [theme.breakpoints.down('tablet')]: {
                            height: 'calc(100% - 2.5rem)',
                          },
                        })}
                      />
                    ) : null}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(1),
                      [theme.breakpoints.down('tablet')]: {
                        height: 'calc(100% + 1rem)',
                        gap: theme.spacing(0.5),
                      },
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        display: 'grid',
                        width: 'fit-content',
                        gridTemplateColumns: '1fr 3fr',
                        gap: theme.spacing(1),
                        [theme.breakpoints.down('laptop')]: {
                          width: 'calc(100% + 1rem)',
                        },
                        [theme.breakpoints.down('laptop')]: {
                          width: 'fit-content',
                        },
                      })}
                    >
                      <Typography variant="lead1">
                        {ENTITY_LABELS[providerType]}
                      </Typography>
                      <CertificateState
                        isCertificateCompleted={completed}
                        isAffiliationCompleted={affiliationCompleted}
                      />
                    </Box>
                    <Typography variant="body2" color="black.dark">
                      {institutionName}
                    </Typography>
                    {completed ? (
                      <Typography
                        variant="body2"
                        color="black.dark"
                        sx={(theme) => ({
                          paddingRight: theme.spacing(1),
                          width: 'calc(100% + 2rem)',
                        })}
                      >
                        Completada el {formatDisplayDateString(completionDate)}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </Step>
            )
          }
        )}
      </Stepper>
    </Box>
  )
}

export default AffiliationStepper
