import { useDateFormatter } from '@react-aria/i18n'
import { createRef, useLayoutEffect, useMemo } from 'react'

import { Box, Button, Grow } from '@mui/material'

import { useDateRangePickerContext } from './indexV2'

const CalendarYearGrid = ({ open, state, onCloseYearGrid }) => {
  const { isDesktop, maxValue, minValue } = useDateRangePickerContext()
  const { focusedDate, timeZone } = state
  const currentYear = new Date().getFullYear().toString()
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone,
  })
  const years = useMemo(() => {
    let nextYears = []
    // Format 20 years on each side of the current year according
    // to the current locale and calendar system.
    for (let i = -20; i <= 20; i += 1) {
      const date = focusedDate.add({ years: i })

      nextYears.push({
        value: date,
        label: formatter.format(date.toDate(timeZone)),
      })
    }

    // Add only years between minValue and maxValue

    if (minValue?.year) {
      nextYears = nextYears.filter(
        (yearItem) => yearItem?.year >= minValue?.year
      )
    }

    if (maxValue?.year) {
      nextYears = nextYears.filter(
        (yearItem) => yearItem?.year <= maxValue?.year
      )
    }

    return nextYears
  }, [focusedDate, formatter, maxValue?.year, minValue?.year, timeZone])
  const yearsRefs = useMemo(
    () =>
      years.reduce((acc, value) => {
        acc[value.label] = createRef()
        return acc
      }, {}),
    [years]
  )

  useLayoutEffect(() => {
    if (open) {
      if (yearsRefs[currentYear]) {
        yearsRefs[currentYear].current?.focus({ focusVisible: true })
      }
    }
  }, [currentYear, open, yearsRefs])

  const onChange = (index) => {
    const date = years[index].value
    state.setFocusedDate(date)
    onCloseYearGrid()
  }

  return (
    <Grow in={open} unmountOnExit>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          right: 0,
          left: 0,
          top: theme.spacing(7),
          transformOrigin: 'top center 0',
          width: '100%',
          height: `calc(100% - ${theme.spacing(7)} - 72px)`,
          backgroundColor: theme.palette.white.main,
          zIndex: '2',
          ...(isDesktop && {
            height: `calc(100% - ${theme.spacing(7)})`,
          }),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            overflowY: 'scroll',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: theme.spacing(1),
            height: '100%',
          })}
        >
          {years.map((yearItem, index) => (
            <Button
              key={yearItem.label}
              ref={yearsRefs[yearItem.label]}
              variant="text"
              size="small"
              onClick={() => onChange(index)}
            >
              {yearItem.label}
            </Button>
          ))}
        </Box>
      </Box>
    </Grow>
  )
}

export default CalendarYearGrid
