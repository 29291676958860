import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createWorkHoursConfiguration,
  deleteWorkHoursConfiguration,
  editWorkHoursConfiguration,
  getWorkHoursConfiguration,
} from 'services/company/workHoursConfigurationService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workHoursConfiguration } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getWorkHoursConfiguration')
    return getWorkHoursConfiguration(workHoursConfiguration)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    workHoursConfiguration,
    workHoursConfigurationsId,
  } = mutationData

  if (mutationMethod === 'POST')
    return createWorkHoursConfiguration(workHoursConfiguration)

  if (mutationMethod === 'PATCH')
    return editWorkHoursConfiguration(
      workHoursConfigurationsId,
      workHoursConfiguration
    )

  if (mutationMethod === 'DELETE')
    return deleteWorkHoursConfiguration(workHoursConfigurationsId)

  return null
}

const useWorkHoursConfigurationService = ({
  serviceParams = { queryKey: 'getWorkHoursConfiguration' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const {
    data: { data } = {},
    ...restWorkHoursConfigurationResponse
  } = useQuery(serviceParams.queryKey, () => getQueryFunction(serviceParams), {
    onError: (error) => {
      handleError(error)
    },
    ...queryOptions,
  })

  const workHoursConfigurationMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    workHoursConfigurationQuery: {
      data,
      ...restWorkHoursConfigurationResponse,
    },
    workHoursConfigurationMutation,
  }
}

export default useWorkHoursConfigurationService
