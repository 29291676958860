import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import Pattern from 'components/UI/Pattern'

import AleluyaGirl from 'assets/images/views/common/colorful_aleluya_girl_meditating.svg'

import usePremiumFeature from './usePremiumFeature'

const PageGetPremiumHR = () => {
  const { openPremiumFeatureModal } = usePremiumFeature()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleOpenPremiumFeatureModal = () => {
    openPremiumFeatureModal()
  }

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Pattern color="accent4.dark" patternColor="accent4.light" />
      <Box
        sx={(theme) => ({
          display: 'grid',
          justifyItems: 'center',
          height: '100%',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          padding: theme.spacing(0, 4),
          gap: theme.spacing(2),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1.5fr 1fr',
          },
          [theme.breakpoints.up('laptop')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
          }}
        >
          <Typography variant="h1">
            ¡Ups! Lo sentimos. Actualmente, no cuentas con los superpoderes
            necesarios.
          </Typography>
          <Typography variant="body1" color="black.dark">
            ¡Mejora tu plan y desbloquea un mundo de funcionalidades asombrosas
            que te harán la vida mucho más fácil! Imagina poder gestionar tus
            empleados de manera eficiente, con reportes detallados y acceso a
            características exclusivas que agilizarán tus tareas diarias.
          </Typography>
          <Button
            onClick={handleOpenPremiumFeatureModal}
            sx={{ width: 'fit-content' }}
          >
            Activar superpoderes
          </Button>
        </Box>
        {isMobile ? null : (
          <Box
            component="img"
            alt="Relájate mientras tienes Aleluya Premium"
            src={AleluyaGirl}
            sx={(theme) => ({
              height: '30rem',
              width: '30rem',
              [theme.breakpoints.down('laptop')]: {
                height: '25rem',
                width: '25rem',
              },
            })}
          />
        )}
      </Box>
      <Pattern
        color="accent3.dark"
        patternColor="accent3.light"
        adornment="pattern-bottom"
        transform="rotate(180deg)"
      />
    </Box>
  )
}

export default PageGetPremiumHR
