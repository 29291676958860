import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getNoveltiesByPayroll,
  putNovelties,
} from 'services/payroll/noveltiesService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, payrollId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'noveltiesByPayroll')
    return getNoveltiesByPayroll(payrollId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, payrollId, novelties } = mutationData

  if (mutationMethod === 'PUT') return putNovelties(payrollId, novelties)

  return null
}

const useNoveltiesService = ({
  serviceParams = { queryKey: 'noveltiesByPayroll' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restNoveltiesResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const noveltiesMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    noveltiesQuery: {
      data,
      ...restNoveltiesResponse,
    },
    noveltiesMutation,
  }
}

export default useNoveltiesService
