import { useState } from 'react'

const useTotalEmittedRejectedModal = () => {
  const [state, setState] = useState({
    open: false,
    electronicPayrollData: {},
  })

  const openTotalEmittedRejectedModal = (electronicPayrollData) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      electronicPayrollData,
    }))
  }

  const closeTotalEmittedRejectedModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    openTotalEmittedRejectedModal,
    closeTotalEmittedRejectedModal,
    totalEmittedRejectedModalState: state,
  }
}

export default useTotalEmittedRejectedModal
