import { useInbox } from '@trycourier/react-hooks'
import ReactMarkdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'
import remarkGfm from 'remark-gfm'

import {
  Badge,
  Box,
  IconButton,
  Tooltip,
  Typography,
  badgeClasses,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import { timestampToReadableDate } from 'utils/dateTime'

import happyMan from 'assets/images/views/common/aleluya_person_no_shadow.svg'

export const Message = ({ message, withBorder, compact = false }) => {
  const { markMessageRead, markMessageUnread, markMessageArchived } = useInbox()

  const handleReadMessage = (messageId) => {
    markMessageRead(messageId)
  }

  const handleUnreadMessage = (messageId) => {
    markMessageUnread(messageId)
  }

  const handleArchiveMessage = (messageId) => {
    markMessageArchived(messageId)
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column',
        padding: theme.spacing(1, 2),
        ...(message.read && {
          backgroundColor: theme.palette.white.light,
        }),
        ...(withBorder && {
          borderBottom: `1px solid ${theme.palette.white.dark}`,
        }),
        ...(compact
          ? { padding: theme.spacing(1, 2) }
          : {
              padding: theme.spacing(3),
            }),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="small" color="black.light">
          Aleluya · {timestampToReadableDate(message.created)}
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(1),
            alignItems: 'center',
          })}
        >
          <Tooltip
            arrow
            title={!message.read ? 'Marcar como leído' : 'Marcar como no leído'}
          >
            <IconButton
              onClick={
                !message.read
                  ? () => handleReadMessage(message.messageId)
                  : () => handleUnreadMessage(message.messageId)
              }
              sx={{
                backgroundColor: 'transparent',
                '&:focus,:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {!message.read ? (
                <Icon name="mail-notification-open" twoTone />
              ) : (
                <Icon name="mail-notification" twoTone />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Eliminar">
            <IconButton
              onClick={() => handleArchiveMessage(message.messageId)}
              sx={{
                backgroundColor: 'transparent',
                '&:focus,:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Icon name="trash" twoTone />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Badge
        invisible={Boolean(message.read)}
        variant="dot"
        color="primary"
        sx={{
          [`& .${badgeClasses.badge}`]: {
            right: 8,
            top: '50%',
            height: '1rem',
            width: '1rem',
            borderRadius: '50%',
          },
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            paddingRight: theme.spacing(2.5),
            position: 'relative',
            ...(compact
              ? {
                  gap: theme.spacing(0.5),
                }
              : {
                  gap: theme.spacing(1),
                }),
          })}
        >
          <Typography variant="lead1" component="div">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
              className="reactMarkDown"
            >
              {message.title}
            </ReactMarkdown>
          </Typography>
          <Typography color="black.dark" variant="body2" component="div">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
              className="reactMarkDown"
            >
              {message.preview}
            </ReactMarkdown>
          </Typography>
        </Box>
      </Badge>
    </Box>
  )
}

const Messages = ({ messages = [] }) => {
  const showEmptyState = messages.length === 0

  if (showEmptyState) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            height: '20rem',
            width: '21rem',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: theme.spacing(2),
          })}
        >
          <Box
            component="img"
            sx={{
              width: '40%',
              height: '40%',
              objectFit: 'contain',
            }}
            src={happyMan}
            alt="Estás al día con tus notificaciones"
          />
          <Typography variant="body2">
            ¡Estás al día con tus notificaciones!
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={(theme) => ({
        maxHeight: '29rem',
        overflowY: 'auto',
        /* Firefox */
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.white.dark} ${theme.palette.white.main}`,
        /* Chrome, Edge, and Safari */
        '::-webkit-scrollbar': {
          width: '0.375rem',
        },
        '::-webkit-scrollbar-track': {
          background: theme.palette.white.main,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.white.dark,
          borderRadius: '0.1875rem',
          border: 'none',
        },
      })}
    >
      {messages?.map((message, index) => {
        return (
          <Message
            key={message.created}
            message={message}
            withBorder={index !== messages.length - 1}
            compact
          />
        )
      })}
    </Box>
  )
}

export default Messages
