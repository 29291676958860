import { useMutation } from 'react-query'

import {
  getElectronicPayrollSummary,
  getPayslipsElectronicPayroll,
} from 'services/payroll/fileService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationKey,
    electronicPayrollId,
    electronicPayrollPeriodId,
    fileType,
  } = mutationData

  if (mutationMethod === 'GET') {
    if (mutationKey === 'payslipsElectronicPayroll')
      return getPayslipsElectronicPayroll(electronicPayrollId, fileType)

    if (mutationKey === 'electronicPayrollSummary')
      return getElectronicPayrollSummary(electronicPayrollPeriodId)
  }

  return null
}

const useElectronicPayrollFileService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const downloadFileMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    downloadFileMutation,
  }
}

export default useElectronicPayrollFileService
