import { useMutation, useQuery } from 'react-query'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import { getQueryKey } from 'utils/reactQuery'

import {
  getAllCompanies,
  updatePartnerCompany,
} from 'services/organizer/partner/companyService'

const getQueryFunction = (serviceParams) => {
  const { queryKey, partnerId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getPartnerCompanies')
    return getAllCompanies(partnerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, ...rest } = mutationData

  if (mutationMethod === 'PUT') return updatePartnerCompany({ ...rest })

  return null
}

const usePartnerCompany = ({
  serviceParams = { queryKey: 'getPartnerCompanies' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restPartnerCompanyResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const partnerCompanyMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    partnerCompanyQuery: {
      data,
      ...restPartnerCompanyResponse,
    },
    partnerCompanyMutation,
  }
}

export default usePartnerCompany
