import { Box, Link, Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Modal from 'components/UI/Modal/Modal'

import { rightPointingHand } from 'utils/emojis'

import ErrorIndex from './Content/ErrorIndex'

const TotalEmittedRejectedModal = ({ state, closeModal }) => {
  const {
    electronicPayrollData: { electronic_payrolls_counter, errors },
    open,
  } = state

  return (
    <Modal
      open={open}
      onCloseModal={closeModal}
      header={
        <Box>
          <Typography
            variant="h4"
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
          >
            Listado de nóminas electrónicas rechazadas
          </Typography>

          <Typography>
            Total de nóminas electrónicas rechazadas:{' '}
            <b>{electronic_payrolls_counter?.rejected}</b>
          </Typography>
        </Box>
      }
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
      hideCancelButton
      okText="Cerrar"
      onOk={closeModal}
    >
      <Typography
        variant="lead1"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          columnGap: theme.spacing(0.5),
          marginBottom: theme.spacing(2),
        })}
      >
        <Emoji code={rightPointingHand} />
        <Link
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/preguntas-frecuentes-sobre-la-emisión-de-nómina-electrónica"
          target="_blank"
          underline="hover"
        >
          Ptss! Mira aquí qué significa cada error y cómo solucionarlo
        </Link>
      </Typography>
      <ErrorIndex errors={errors} />
    </Modal>
  )
}

export default TotalEmittedRejectedModal
