import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'

import { getDocumentTypeOptions } from 'utils/worker'

const DocumentTypeField = ({
  name,
  idNumberKey,
  verificationDigitKey,
  clientTypeKey,
  optional,
  label,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext()
  const {
    id_number: idNumber,
    document_type: documentType,
    verification_digit: verificationDigit,
    client_type: clientType,
    [idNumberKey]: idNumberFromProps,
    [name]: documentTypeFromProps,
    [verificationDigitKey]: verificationDigitFromProps,
    [clientTypeKey]: clientTypeFromProps,
  } = values
  const localIdNumber = idNumberFromProps || idNumber
  const localDocumentType = documentTypeFromProps || documentType
  const localVerificationDigit = verificationDigitFromProps || verificationDigit
  const localClientType = clientTypeFromProps || clientType

  useEffect(() => {
    if (
      localDocumentType &&
      localDocumentType !== 'pa' &&
      !/^\d+$/.test(localIdNumber)
    ) {
      // only 'pa' can contain letters, so we cleare id_number field if id_number come with some not digit char
      setFieldValue(idNumberKey || 'id_number', null)
    }

    if (
      localDocumentType === 'ni' &&
      localIdNumber &&
      localIdNumber.length > 9
    ) {
      setFieldValue(idNumberKey || 'id_number', null)
      if (localVerificationDigit) {
        setFieldValue(verificationDigitKey || 'verification_digit', null)
      }
    }
  }, [
    idNumberKey,
    localDocumentType,
    localIdNumber,
    localVerificationDigit,
    setFieldValue,
    verificationDigitKey,
  ])

  const options = getDocumentTypeOptions(localClientType)

  return (
    <FormField
      data-cy="document-type-select"
      name={name || 'document_type'}
      label={label || 'Tipo de documento'}
      variant="select"
      options={options}
      optional={optional}
      {...props}
    />
  )
}

export default DocumentTypeField
