import http, { getApiURL } from '../httpService'

export const getAllAffiliations = ({ searchParams = {} }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/affiliations',
    searchParams,
  })

  return http.getV2(url)
}

export default {
  getAllAffiliations,
}
