import { useSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, Link, Typography } from '@mui/material'

import * as routes from 'config/routes'

const PeriodEditingInactiveModal = ({ handleClose }) => {
  const { closeSnackbar } = useSnackbar()

  const handleOnOk = () => {
    handleClose()
    closeSnackbar()
  }

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up('tablet')]: {
          width: '80%',
        },
      })}
    >
      <Typography color="black.dark">
        Recuerda que la edición de periodos históricos está inactiva. Para
        realizar ediciones de estos periodos, debes de volver a activar esta
        opción en las configuraciones. Conoce{' '}
        <Link
          color="accent4.main"
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/edita-periodos-históricos-de-tu-compañía-en-aleluya"
          target="_blank"
        >
          <b>aquí</b>
        </Link>{' '}
        cómo habilitar la opción.
      </Typography>

      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          component={RouterLink}
          to={{
            pathname: routes.SETTINGS_ADVANCED(),
            hash: 'edit_periods',
          }}
          onClick={handleOnOk}
        >
          Ir a configuración
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Regresar
        </Button>
      </Box>
    </Box>
  )
}

export default PeriodEditingInactiveModal
