import { SvgIcon, useTheme } from '@mui/material'

import basicIconsSprite from 'assets/basic-icons-sprite.svg'
import iconsSprite from 'assets/icons-sprite.svg'

const Icon = ({
  name,
  twoTone = false,
  basic = false,
  sx,
  fontSize,
  color = 'inherit',
} = {}) => {
  const themeInstance = useTheme()
  const tone = themeInstance.palette.primary.dark

  return (
    <SvgIcon inheritViewBox sx={sx} fontSize={fontSize}>
      <use
        href={`${!basic ? iconsSprite : basicIconsSprite}#${name}${
          basic ? '-basic' : ''
        }`}
        // eslint-disable-next-line no-nested-ternary
        color={basic ? color : twoTone ? tone : 'black'}
      />
    </SvgIcon>
  )
}

export default Icon
