export function getRoleDisplayName(
  companyType = 'Sin terminar registro',
  plural
) {
  let companyTypeName

  switch (companyType) {
    case 'admin':
      companyTypeName = plural ? 'Administradores' : 'Administrador'
      break
    case 'worker':
      companyTypeName = plural ? 'Personas' : 'Persona'
      break
    case 'accountant':
      companyTypeName = plural ? 'Contadores' : 'Contador'
      break
    default:
      companyTypeName = companyType
      break
  }

  return companyTypeName
}

export function getCompanyId() {
  return localStorage.getItem('company_id')
}

export function getCompanyData() {
  return JSON.parse(localStorage.getItem('company'))
}

export function setCompanyId(companyId) {
  localStorage.setItem('company_id', companyId)
}

export function deleteCompanyId() {
  localStorage.removeItem('company_id')
}

export function hasSelectedCompany() {
  return localStorage.getItem('company_id') !== null
}

export function hasOneCompany() {
  return localStorage.getItem('has_one_company') === 'true'
}

export function setHasOneCompany(hasOneCompanyProp) {
  return localStorage.setItem('has_one_company', hasOneCompanyProp)
}

export function setCompanyOwner(companyOwnerData) {
  return localStorage.setItem('company_owner', companyOwnerData)
}

export const documentTypes = {
  cc: 'Cédula de ciudadanía',
  ce: 'Cédula de extranjería',
  ti: 'Tarjeta de identidad',
  pa: 'Pasaporte',
  ni: 'NIT',
  pt: 'Permiso de protección temporal',
}

export const documentTypesOptions = Object.keys(documentTypes).map((value) => ({
  value,
  label: documentTypes[value],
}))

export const accountTypes = {
  savings_account: 'Cuenta de ahorros',
  current_account: 'Cuenta corriente',
}

export const accountTypesOptions = Object.keys(accountTypes).map((value) => ({
  value,
  label: accountTypes[value],
}))

export const payrollFrequencies = {
  fortnightly: 'Quincenal',
  monthly: 'Mensual',
}

export const payrollFrequenciesOptions = Object.keys(payrollFrequencies).map(
  (value) => ({
    value,
    label: payrollFrequencies[value],
  })
)

export const paymentMethods = {
  check: 'Cheque',
  cash: 'Efectivo ',
  wire_transfer: 'Transferencia bancaria',
}

export const paymentMethodsOptions = Object.keys(paymentMethods).map(
  (value) => ({
    value,
    label: paymentMethods[value],
  })
)

export function calculateVerificationDigit(idNumber) {
  const inputNumber = idNumber.toString().replace(/-/g, '')

  const inputNumberCompleted =
    '0'.repeat(15 - inputNumber.toString().length) + inputNumber
  // Multiples from here http://www.gerencie.com/calculando-el-digito-de-verificacion-en-excel.html
  const multiples = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3]
  let sum = 0

  for (let i = 0; i < inputNumberCompleted.length; i += 1) {
    sum += inputNumberCompleted[i] * multiples[i]
  }

  const remaining = sum % 11

  let verDigit

  if (remaining === 0) {
    verDigit = 0
  } else if (remaining === 1) {
    verDigit = 1
  } else {
    verDigit = 11 - remaining
  }

  return verDigit.toString()
}

export function generateEmptyLocation() {
  return {
    name: '',
    department: null,
    city: null,
    compensation_fund: null,
  }
}

export function generateEmptyAreaOrPosition() {
  return {
    name: '',
  }
}

export const subscriptionPaymentCategories = {
  pse: 'PSE',
  bank_transfer: 'Transferencia bancaria',
  credit_card: 'Tarjeta débito/crédito',
  automatic_debit: 'Débito automático',
  exonerated_payment: 'Exonerado de pagos',
  accountant_payment: 'Pago Contador',
  distributor_payment: 'Pago Distribuidor',
  partner_payment: 'Pago partner',
  clara_payment: 'Pago Clara',
}

export const subscriptionPaymentStatus = {
  approved: 'Aprobado',
  pending: 'Pendiente',
  quote: 'Cotización',
  rejected: 'Rechazado',
  disconnected: 'Desconectado',
}

const paymentTypes = {
  nominapp_payment: 'Pago Nominapp',
  distributor_payment: 'Pago Distribuidor',
  accountant_payment: 'Pago Contador',
  exonerated_payment: 'Pago Exonerado',
  clara_payment: 'Pago Clara',
  none: '',
}

export const paymentTypesOptions = Object.keys(paymentTypes).map((value) => ({
  value,
  label: paymentTypes[value],
}))

export function getCurrentDistributor(distributors, id) {
  return distributors?.find((distributor) => distributor.id === id)?.coded_name
}

export const accountingCategoryNames = {
  legal: 'Salario y Subsidio de Transporte',
  incapacities_licenses: 'Licencias e Incapacidades',
  salary_income: 'Ingresos Constitutivos de Salario',
  overtime: 'Horas extras y recargos',
  non_salary_income: 'Ingresos No Constitutivos de Salario',
  deductions: 'Deducciones',
  retentions: 'Retenciones',
  social_benefits: 'Prestaciones Sociales',
  social_security: 'Seguridad Social',
  termination: 'Liquidación',
  payment_method: 'Forma de pago',
}
