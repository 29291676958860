import http, { getApiURL } from '../httpService'

export const getElectronicSignatureResults = (workerId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/electronic_signatures`,
  })

  return http.getV2(url)
}

export const createElectronicSignature = (
  workerId,
  electronicSignatureData
) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/electronic_signatures`,
  })

  return http.postV2(url, electronicSignatureData)
}

export const deleteSignedDocument = (workerId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/electronic_signatures`,
  })

  return http.deleteV2(url)
}

export default {
  getElectronicSignatureResults,
  createElectronicSignature,
}
