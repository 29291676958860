import messages from 'messages/payroll'

import AddButtonWrapper from '../common/AddButtonWrapper'
import DateRangeContent from './DateRangeContent'

const Holidays = ({
  holidays,
  onAddNovelty,
  onChangeNovelty,
  onDeleteNovelty,
  selectedDays,
  payroll,
}) => {
  const noveltyCode = 'enjoyed_days'
  const { items } = holidays.enjoyed_days

  return (
    <AddButtonWrapper
      showButton={
        items.length >= 1 &&
        items[items.length - 1].initial_day &&
        items[items.length - 1].end_day
      }
      noveltyType="holidays"
      noveltyCode={noveltyCode}
      onAddNovelty={onAddNovelty}
    >
      <DateRangeContent
        title="Días disfrutados en el período"
        data={holidays.enjoyed_days}
        onAddNovelty={onAddNovelty}
        onChangeNovelty={onChangeNovelty}
        onDeleteNovelty={onDeleteNovelty}
        noveltyType="holidays"
        noveltyCode={noveltyCode}
        contentTooltip={messages[noveltyCode]}
        selectedDays={selectedDays}
        payroll={payroll}
      />
    </AddButtonWrapper>
  )
}

export default Holidays
