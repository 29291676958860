function generateTypographyStyle(
  fontFamily = 'Montserrat',
  fontSize = '1rem',
  // eslint-disable-next-line no-unused-vars
  lineHeight = '24px',
  fontWeight = 'normal',
  letterSpacing = '0em'
) {
  return {
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight: 1.35, // TODO: maybe use this value?
    letterSpacing,
  }
}

const h1 = generateTypographyStyle('Montserrat', '2.5rem', 3.375, 700, 0) // 40px
const h2 = generateTypographyStyle('Montserrat', '2rem', 2, 700, 0) // 32px
const h3 = generateTypographyStyle('Montserrat', '1.75rem', 2.375, 700, 0) // 28px
const h4 = generateTypographyStyle('Montserrat', '1.5rem', 2, 700, 0) // 24px
const h5 = generateTypographyStyle('Montserrat', '1.25rem', 1.75, 600, 0) // 20px
const h6 = generateTypographyStyle('Montserrat', '1rem', 1.375, 600, 0) // 16px
const lead1 = generateTypographyStyle('Montserrat', '1rem', 1.5, 700, 0) // 16px
const lead2 = generateTypographyStyle('Montserrat', '0.875rem', 1.5, 700, 0) // 14px
const body1 = generateTypographyStyle('Sora', '1rem', 1.5, 400, 0) // 16px
const body2 = generateTypographyStyle('Sora', '0.875rem', 1.5, 400, 0) // 14px
const blockquote = generateTypographyStyle(
  'Montserrat',
  '1.25rem',
  1.75,
  600,
  0
) // 20px
const capitalized = generateTypographyStyle(
  'Sora',
  '0.875rem',
  1.1025,
  400,
  '0.143em'
) // 14px
const small = generateTypographyStyle('Sora', '0.75rem', 0.945, 400, 0) // 12px
const tiny = generateTypographyStyle('Sora', '0.625rem', 0.7875, 600, 0) // 10px

const typographyStyles = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  lead1,
  lead2,
  body1,
  body2,
  blockquote,
  capitalized,
  small,
  tiny,
}

export default typographyStyles
