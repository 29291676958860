import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'
import useWorker from 'components/Worker/useWorker'

import useLoansService from 'utils/hooks/worker/loans'
import useRecurrentConceptsService from 'utils/hooks/worker/recurrentConcepts'

import { formatRecurrentConceptsData } from './helpers'

const useRecurrentConcepts = () => {
  const queryClient = useQueryClient()
  const { worker: { id: workerId } = {} } = useWorker({ useCache: true })
  const { isWorker: isRoleWorker } = useUser()
  const loansQueryKey = [isRoleWorker ? 'getWorkerLoans' : 'getLoans', workerId]
  const recurrentConceptsQueryKey = [
    isRoleWorker ? 'getWorkerRecurrentConcepts' : 'getRecurrentConcepts',
    workerId,
  ]
  const cachedLoansData = queryClient.getQueryData(loansQueryKey)
  const cachedRecurrentConceptsData = queryClient.getQueryData(
    recurrentConceptsQueryKey
  )

  const { recurrentConceptsQuery } = useRecurrentConceptsService({
    serviceParams: {
      queryKey: recurrentConceptsQueryKey,
      workerId,
    },
    queryOptions: {
      enabled: Boolean(cachedRecurrentConceptsData) === false && !!workerId,
    },
  })
  const { loansQuery } = useLoansService({
    serviceParams: {
      queryKey: loansQueryKey,
      workerId,
    },
    queryOptions: {
      enabled: Boolean(cachedLoansData) === false && !!workerId,
    },
  })

  const refreshRecurrentConcepts = (type) => {
    if (type === 'recurrent_concept') {
      queryClient.refetchQueries(recurrentConceptsQueryKey)
    } else {
      queryClient.refetchQueries(loansQueryKey)
    }
  }

  return {
    recurrentConcepts: formatRecurrentConceptsData(
      cachedRecurrentConceptsData?.data,
      cachedLoansData?.data
    ),
    isLoading: recurrentConceptsQuery.isLoading || loansQuery.isLoading,
    isError: recurrentConceptsQuery.isError || loansQuery.isError,
    refreshRecurrentConcepts,
  }
}

export default useRecurrentConcepts
