import { Box } from '@mui/material'

const MobileCell = ({ children, alignX = 'left', alignY = 'top', sx }) => {
  return (
    <Box
      sx={[
        (theme) => ({
          minHeight: '3.75rem',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          [theme.breakpoints.up('tablet')]: {
            minHeight: 'auto',
            alignItems: 'center',
          },
          ...(alignX === 'right' && {
            justifyContent: 'flex-end',
            textAlign: 'right',
          }),
          ...(alignY === 'center' && {
            alignItems: 'center',
          }),
        }),
        sx,
      ]}
    >
      {children}
    </Box>
  )
}

export default MobileCell
