import { useMutation } from 'react-query'

import { getNoveltiesHistory } from 'services/payroll/fileService'
import { getNoveltiesHistoryWorker } from 'services/worker/workerService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationKey,
    periodId,
    workerId,
    options,
  } = mutationData

  if (mutationMethod === 'GET') {
    if (mutationKey === 'noveltiesHistorySummaryPeriod')
      return getNoveltiesHistory(periodId, options)

    if (mutationKey === 'noveltiesHistorySummaryWorker')
      return getNoveltiesHistoryWorker(workerId, options)
  }
  return null
}

const usePayrollsFileService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const downloadFileMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    downloadFileMutation,
  }
}

export default usePayrollsFileService
