import { Box } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import useFileName from 'utils/hooks/useFileName'

import { DownloadButton, getProviderInfo } from './helpers'

const AffiliationApprovalContent = ({ certificatesData }) => {
  const { document_url: compensationFundUrl, hidden: hideCompensationFund } =
    getProviderInfo('compensation_fund', certificatesData)

  const { document_url: healthProviderUrl, hidden: hideHealthProvider } =
    getProviderInfo('health_provider', certificatesData)

  const { document_url: pensionProviderUrl, hidden: hidePensionProvider } =
    getProviderInfo('pension_provider', certificatesData)

  const { document_url: riskProviderUrl, hidden: hideRiskProvider } =
    getProviderInfo('risk_provider', certificatesData)

  const compensationFundDocumentName = useFileName(compensationFundUrl)
  const healthDocumentName = useFileName(healthProviderUrl)
  const pensionDocumentName = useFileName(pensionProviderUrl)
  const riskDocumentName = useFileName(riskProviderUrl)

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      })}
    >
      {!hideCompensationFund ? (
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}>
          <FormField
            name="approved_compensation_fund_document"
            label="Certificado de afiliación CCF"
            variant="file"
            fileType="application/pdf"
            accept="application/pdf"
            fileName={compensationFundDocumentName}
            optional={false}
          />
          <DownloadButton file={compensationFundUrl} />
        </Box>
      ) : null}
      {!hideHealthProvider ? (
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}>
          <FormField
            name="approved_health_document"
            label="Certificado de afiliación EPS"
            variant="file"
            fileType="application/pdf"
            accept="application/pdf"
            fileName={healthDocumentName}
            optional={false}
          />
          <DownloadButton file={healthProviderUrl} />
        </Box>
      ) : null}
      {!hidePensionProvider ? (
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}>
          <FormField
            name="approved_pension_document"
            label="Certificado de afiliación AFP"
            variant="file"
            fileType="application/pdf"
            accept="application/pdf"
            fileName={pensionDocumentName}
            optional={false}
          />
          <DownloadButton file={pensionProviderUrl} />
        </Box>
      ) : null}
      {!hideRiskProvider ? (
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}>
          <FormField
            name="approved_risk_document"
            label="Certificado de afiliación ARL"
            variant="file"
            fileType="application/pdf"
            accept="application/pdf"
            fileName={riskDocumentName}
            optional={false}
          />
          <DownloadButton file={riskProviderUrl} />
        </Box>
      ) : null}
    </Box>
  )
}

export default AffiliationApprovalContent
