import { Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useBeneficiariesService from 'utils/hooks/affiliations/beneficiaries'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import Actions from './Actions'
import {
  FILES,
  KINSHIP_LABEL,
  attachDocuments,
  getAffiliationTypeLabel,
  getAttachedDocuments,
  getBeneficiaryEntitiesToAffiliate,
  getBeneficiaryEntitiesToAffiliateArray,
  getInitialValues,
  remainKinshipOptions,
  validationSchema,
} from './helpers'

const EditBeneficiary = ({
  worker,
  beneficiaries,
  beneficiary,
  affiliationQueryKey,
  selectedEntitiesToAffiliate,
  preserveDocuments,
}) => {
  const queryClient = useQueryClient()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()

  const [showEditBeneficiary, setShowEditBeneficiary] = useState(false)

  const confirm = useConfirm()
  const downloadURI = useDownloadURI()

  const { beneficiariesMutation } = useBeneficiariesService()

  const beneficiaryManagement = (managementType, beneficiaryData) => {
    const data = new FormData()

    if (managementType === 'update') {
      const { oldData, newData } = beneficiaryData

      const oldDataToCompare = {
        name: oldData.name,
        kinship: oldData.kinship,
        entities_to_affiliate: JSON.stringify(oldData.entities_to_affiliate),
        age: oldData.age,
        identification_document: oldData.files.identification_document?.url,
        marriage_certificate: oldData.files.marriage_certificate?.url,
        labor_certificate: oldData.files.labor_certificate?.url,
        civil_registration: oldData.files.civil_registration?.url,
        study_document: oldData.files.study_document?.url,
        dependency_declaration: oldData.files.dependency_declaration?.url,
        disability_certificate: oldData.files.disability_certificate?.url,
        health_affiliation_certificate:
          oldData.files.health_affiliation_certificate?.url,
        parents_death_certificate: oldData.files.parents_death_certificate?.url,
        adoption_certificate: oldData.files.adoption_certificate?.url,
      }

      const newDataToCompare = {
        name: newData.name,
        kinship: newData.kinship,
        entities_to_affiliate: JSON.stringify(
          getBeneficiaryEntitiesToAffiliateArray(newData.entities_to_affiliate)
        ),
        age: newData.age,
        identification_document: newData.identification_document,
        marriage_certificate: newData.marriage_certificate,
        labor_certificate: newData.labor_certificate,
        civil_registration: newData.civil_registration,
        study_document: newData.study_document,
        dependency_declaration: newData.dependency_declaration,
        disability_certificate: newData.disability_certificate,
        health_affiliation_certificate: newData.health_affiliation_certificate,
        parents_death_certificate: newData.parents_death_certificate,
        adoption_certificate: newData.adoption_certificate,
      }

      const dirtyValues = getDirtyValues(
        oldDataToCompare,
        newDataToCompare,
        validationSchema.fields
      )

      if (isObjectEmpty(dirtyValues)) {
        setShowEditBeneficiary(false)
        return {}
      }

      data.append('beneficiary_id', oldData.id)
      data.append('name', dirtyValues.name || oldData.name)
      data.append('kinship', dirtyValues.kinship || oldData.kinship)

      data.append(
        'beneficiary_entities_to_affiliate',
        JSON.stringify(
          dirtyValues.entities_to_affiliate
            ? getBeneficiaryEntitiesToAffiliateArray(
                newData.entities_to_affiliate
              )
            : oldData.entities_to_affiliate
        )
      )

      if (['child', 'stepchild'].includes(newData.kinship)) {
        data.append('age', dirtyValues.age || oldData.age)
      }

      Object.entries(dirtyValues).forEach(([key, value]) => {
        if (value instanceof File) {
          data.append(key, value)
        }
        if (value === null) {
          data.append(key, '')
        }
      })
    }

    if (managementType === 'delete') {
      const { id } = beneficiaryData
      data.append('beneficiary_id', id)
    }

    showLoadingModal()

    beneficiariesMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId: worker?.id,
        beneficiaryData: data,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          preserveDocuments()
          setShowEditBeneficiary(false)
          hideLoadingModal()
        },
        onError: () => {
          hideLoadingModal()
        },
      }
    )
    return null
  }

  const onDeleteBeneficiary = (id) => {
    beneficiaryManagement('delete', { id })
  }

  const onUpdateBeneficiary = (currentBeneficiary) => {
    const oldData = beneficiary

    const newData = currentBeneficiary

    beneficiaryManagement('update', { oldData, newData })
  }

  const onSubmit = (values) => {
    onUpdateBeneficiary(values)
  }

  return (
    <Formik
      initialValues={getInitialValues(beneficiary)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, submitForm, resetForm, setFieldValue }) => {
        const handleChangeKinship = ({ target: { value } }) => {
          setFieldValue('kinship', value)

          FILES.forEach((file) => {
            if (values[file]) {
              setFieldValue(file, null)
            }
          })
        }

        return (
          <Box
            sx={(theme) => ({
              display: 'grid',
              columnGap: theme.spacing(5),
              marginTop: theme.spacing(2.5),
              gridTemplateColumns: '1fr 5rem',
            })}
          >
            {showEditBeneficiary ? (
              <>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gap: theme.spacing(3),
                    gridTemplateColumns: '1fr',
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: 'repeat(3, 1fr)',
                      ...(['child', 'stepchild'].includes(values.kinship) && {
                        gridTemplateColumns: 'repeat(4, 1fr)',
                      }),
                    },
                  })}
                >
                  <FormField name="name" label="Nombre" optional={false} />
                  <FormField
                    name="kinship"
                    label="Parentesco"
                    variant="select"
                    placeholder="Selecciona"
                    onChange={handleChangeKinship}
                    options={remainKinshipOptions(
                      beneficiaries,
                      beneficiary.kinship
                    )}
                    optional={false}
                  />
                  <FormField
                    name="entities_to_affiliate"
                    label="Tipo afiliación"
                    optional={false}
                    variant="select"
                    placeholder="Selecciona"
                    options={getBeneficiaryEntitiesToAffiliate(
                      selectedEntitiesToAffiliate
                    )}
                  />
                  {['child', 'stepchild'].includes(values.kinship) ? (
                    <FormField
                      name="age"
                      label="Edad"
                      optional={false}
                      variant="number"
                      isAllowed={(values) => {
                        const { floatValue } = values
                        return floatValue === undefined || floatValue <= 25
                      }}
                      placeholder="Edad en años"
                    />
                  ) : null}
                </Box>
                <Actions
                  leftAction={{
                    tooltip: 'Confirmar',
                    icon: <Icon name="approve-checked" basic />,
                    onClick: submitForm,
                  }}
                  rightAction={{
                    tooltip: 'Cancelar',
                    icon: <Icon name="close-2" basic />,
                    onClick: () => {
                      setShowEditBeneficiary(false)
                      resetForm()
                    },
                  }}
                />
                <Box
                  sx={(theme) => ({
                    gridColumn: '1 / -1',
                    display: 'grid',
                    flexDirection: 'column',
                    marginTop: theme.spacing(3),
                    columnGap: theme.spacing(2),
                    rowGap: theme.spacing(1.5),
                    gridTemplateColumns: '1fr 1fr',
                    [theme.breakpoints.down('tablet')]: {
                      gap: theme.spacing(2.5),
                      gridTemplateColumns: '1fr',
                    },
                  })}
                >
                  {attachDocuments({
                    kinship: values.kinship,
                    entities_to_affiliate: values.entities_to_affiliate,
                    age: values.age,
                    identification_document:
                      values.identification_document || null,
                    marriage_certificate: values.marriage_certificate || null,
                    labor_certificate: values.labor_certificate || null,
                    civil_registration: values.civil_registration || null,
                    study_document: values.study_document || null,
                    dependency_declaration:
                      values.dependency_declaration || null,
                    disability_certificate:
                      values.disability_certificate || null,
                    health_affiliation_certificate:
                      values.health_affiliation_certificate || null,
                    parents_death_certificate:
                      values.parents_death_certificate || null,
                    adoption_certificate: values.adoption_certificate || null,
                  })}
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gap: theme.spacing(3),
                    gridTemplateColumns: '1fr',
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: 'repeat(3, 1fr)',
                      ...(['child', 'stepchild'].includes(
                        beneficiary.kinship
                      ) && {
                        gridTemplateColumns: 'repeat(4, 1fr)',
                      }),
                    },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography variant="lead2">Nombre</Typography>
                    <Typography variant="lead2" color="black.dark">
                      {beneficiary.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography variant="lead2">Parentesco</Typography>
                    <Typography variant="lead2" color="black.dark">
                      {KINSHIP_LABEL[beneficiary.kinship]}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography variant="lead2">Tipo afiliación</Typography>
                    <Typography variant="lead2" color="black.dark">
                      {getAffiliationTypeLabel(
                        beneficiary.entities_to_affiliate
                      )}
                    </Typography>
                  </Box>
                  {['child', 'stepchild'].includes(beneficiary.kinship) ? (
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(1),
                      })}
                    >
                      <Typography variant="lead2">Edad</Typography>
                      <Typography variant="lead2" color="black.dark">
                        {beneficiary.age} años
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
                <Actions
                  leftAction={{
                    tooltip: 'Editar',
                    icon: <Icon name="edit-pencil" basic />,
                    onClick: () => setShowEditBeneficiary(true),
                  }}
                  rightAction={{
                    tooltip: 'Eliminar',
                    icon: <Icon name="trash" basic />,
                    onClick: () =>
                      confirm({
                        okText: 'Sí',
                        cancelText: 'No',
                        title:
                          '¿Estás seguro de que deseas eliminar a este beneficiario?',
                        onOk: () => onDeleteBeneficiary(beneficiary.id),
                      }),
                  }}
                />
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridColumn: '1 / -1',
                    gridTemplateColumns: '1fr',
                    columnGap: theme.spacing(3),
                    rowGap: theme.spacing(3),
                    marginTop: theme.spacing(3),
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                  })}
                >
                  {getAttachedDocuments({
                    kinship: beneficiary.kinship,
                    files: beneficiary.files,
                    downloadURI,
                    childAge: beneficiary.age,
                    entitiesToAffiliate: beneficiary.entities_to_affiliate,
                  })}
                </Box>
              </>
            )}
          </Box>
        )
      }}
    </Formik>
  )
}

export default EditBeneficiary
