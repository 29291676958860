import { useContext } from 'react'

import { Box, alpha } from '@mui/material'

import { CardGridContext } from '.'

const GridRow = ({
  children,
  gridColumns,
  gutterBottom = false,
  id,
  dataCy,
}) => {
  const { gridColumns: parentGridColumns } = useContext(CardGridContext)

  return (
    <Box
      id={id}
      data-cy={dataCy}
      sx={(theme) => ({
        gridTemplateColumns: gridColumns || parentGridColumns,
        display: 'grid',
        padding: theme.spacing(2),
        minHeight: '3rem',
        borderRadius: '1rem',
        boxShadow: 'none',
        rowGap: theme.spacing(1),
        '&:hover': {
          backgroundColor: alpha(theme.palette.complementary1.light, 0.4),
        },
        [theme.breakpoints.up('tablet')]: {
          rowGap: theme.spacing(2),
          columnGap: theme.spacing(1),
          alignItems: 'center',
          borderRadius: '0.5rem',
          padding: theme.spacing(1, 2),
          ...(gutterBottom && {
            marginBottom: theme.spacing(2),
          }),
        },
        [theme.breakpoints.up('laptop')]: {
          padding: theme.spacing(1, 4),
        },
      })}
    >
      {children}
    </Box>
  )
}

export default GridRow
