import { Box, useTheme } from '@mui/material'

import { ReactComponent as Asterisk } from 'assets/images/adornments/adornment_asterisk.svg'
import { ReactComponent as Circle } from 'assets/images/adornments/adornment_circle.svg'
import { ReactComponent as Explosion } from 'assets/images/adornments/adornment_explosion.svg'
import { ReactComponent as Row } from 'assets/images/adornments/adornment_row.svg'
import { ReactComponent as Signature } from 'assets/images/adornments/adornment_signature.svg'
import { ReactComponent as Spring } from 'assets/images/adornments/adornment_spring.svg'
import { ReactComponent as Star } from 'assets/images/adornments/adornment_star.svg'

const adornments = {
  asterisk: Asterisk,
  explosion: Explosion,
  signature: Signature,
  spring: Spring,
  circle: Circle,
  star: Star,
  row: Row,
}

/**
 * Component to render some SVG adornments
 * @param {string} variant - The adornment varint to render. Must be one of the following: `asterisk`,`explosion`,`signature`,`spring`
 * @param {number} width - The width of the rendered SVG
 * @param {number} height - The height of the rendered SVG
 * @param {string} color - Color of the rendered SVG. Must be one of the valid colors defined in the theme
 * @param {object} sx - Styles applied to SVG's container
 */
const Adornment = ({
  variant = 'asterisk',
  width = 'auto',
  height = 'auto',
  color = 'primary',
  sx,
} = {}) => {
  const theme = useTheme()
  const colorKeys = color.split('.')
  const themeColor = color?.includes('.')
    ? theme.palette[colorKeys[0]][colorKeys[1]]
    : theme.palette[colorKeys[0]]?.main
  const Element = adornments[variant]

  return (
    <Box
      sx={[
        {
          width: 'fit-content',
          position: 'absolute',
        },
        sx,
      ]}
    >
      <Element width={width} height={height} fill={themeColor} />
    </Box>
  )
}

export default Adornment
