import { Box, Button, Chip, Tooltip, Typography, alpha } from '@mui/material'

import FilterButton from 'components/UI/Button/FilterButton'

import { formatNumberDisplayDate } from 'utils/dateTime'
import { getWordSingular } from 'utils/format'

export const getColumnsData = (handleDownloadEvidence) => {
  const getTypeLabel = (payrollConceptName) => {
    return payrollConceptName === 'Vacaciones disfrutadas'
      ? 'Vacaciones'
      : payrollConceptName
  }

  const statusLabel = {
    pending: 'Pendiente',
    approved: 'Aprobado',
    rejected: 'Rechazado',
  }

  return [
    {
      accessor: 'payroll_concept_name',
      Header: 'Novedades',
      Cell: ({ row }) => getTypeLabel(row.original.payroll_concept_name),
      customMinWidth: '10rem',
    },
    {
      accessor: 'start_date',
      Header: 'Fecha de ausencia',
      Cell: ({ row }) => (
        <Typography>
          {formatNumberDisplayDate(row.original.initial_day)}
          {' - '}
          {formatNumberDisplayDate(row.original.end_day)}
        </Typography>
      ),
      customMinWidth: '10rem',
    },
    {
      accessor: 'comment',
      Header: 'Comentarios',
      Cell: ({ row }) => {
        if (!row.original.comment) return 'N/A'
        return (
          <Box>
            <Tooltip title={row.original.comment} arrow>
              <Typography>
                {row.original.comment.length > 25
                  ? `${row.original.comment.slice(0, 25)}...`
                  : row.original.comment}
              </Typography>
            </Tooltip>
          </Box>
        )
      },
      customMinWidth: '10rem',
    },
    {
      accessor: 'duration',
      Header: 'Duración',
      Cell: ({ row }) =>
        `${row.original.days} ${getWordSingular(
          'días',
          row.original.days === 1
        )}`,
      customMinWidth: '6rem',
    },
    {
      Header: 'Documentos',
      accessor: 'evidence',
      Cell: ({ row }) => {
        if (!row.original.evidence) return 'N/A'
        return (
          <Button
            size="small"
            variant="text"
            onClick={() => handleDownloadEvidence(row.original.evidence)}
          >
            Ver documento
          </Button>
        )
      },
      customMinWidth: '9.5rem',
    },
    {
      accessor: 'status',
      Header: 'Estado',
      Cell: ({ row }) => (
        <Chip
          sx={(theme) => ({
            fontWeight: 600,
            minWidth: '7rem',
            ...(row.original.status === 'approved' && {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.dark,
            }),
            ...(row.original.status === 'rejected' && {
              backgroundColor: alpha(theme.palette.error.main, 0.2),
              color: theme.palette.error.dark,
            }),
            ...(row.original.status === 'pending' && {
              backgroundColor: alpha(theme.palette.complementary2.main, 0.2),
              color: theme.palette.complementary2.dark,
            }),
          })}
          label={statusLabel[row.original.status]}
        />
      ),
      alignHeader: 'center',
      alignCell: 'center',
      customMinWidth: '9rem',
    },
  ]
}

const optionsData = [
  { value: '', label: 'Todas las solicitudes' },
  {
    value: 'pending',
    label: 'Pendientes',
  },
  {
    value: 'approved',
    label: 'Aprobadas',
  },
  {
    value: 'rejected',
    label: 'Rechazadas',
  },
]

export const getActions = (freeTimeActiveStatus, setfreeTimeActiveStatus) => {
  return [
    {
      id: 'filterFreeTimeType',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          options={optionsData}
          defaultLabel="Todas las solicitudes"
          value={freeTimeActiveStatus}
          onChange={setfreeTimeActiveStatus}
        />
      ),
    },
  ]
}

export const getEmptyStateTitle = (freeTimeActiveStatus) => {
  let status = ''

  if (freeTimeActiveStatus === 'approved') {
    status = 'aprobadas'
  } else if (freeTimeActiveStatus === 'rejected') {
    status = 'rechazadas'
  } else if (freeTimeActiveStatus === 'pending') {
    status = 'pendientes'
  }

  return `Esta persona no tiene solicitudes de tiempo fuera ${status}`
}
