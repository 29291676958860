import { useCallback } from 'react'

import { PAYROLL_ACTION_TYPES } from './usePayrollConfiguration'

const useNoveltyTypeModal = ({ payrollState, payrollDispatch }) => {
  const openNoveltyTypeModal = useCallback(
    (configuration) => {
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SHOW_MODAL,
        modalKeys: {
          noveltyTypeModal: true,
        },
      })
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SET_MODAL_CONFIGURATION,
        config: {
          workerName: configuration.workerName,
          workerContractCategory: configuration.workerContractCategory,
          workerPayroll: configuration.workerPayroll,
        },
      })
    },
    [payrollDispatch]
  )

  const closeNoveltyTypeModal = () => {
    payrollDispatch({
      type: PAYROLL_ACTION_TYPES.CLOSE_MODAL,
      modalKeys: {
        noveltyTypeModal: false,
      },
    })
  }

  return {
    noveltyTypeModalState: {
      open: Boolean(payrollState.noveltyTypeModal),
    },
    openNoveltyTypeModal,
    closeNoveltyTypeModal,
  }
}

export default useNoveltyTypeModal
