import * as yup from 'yup'

export const reasons = {
  economicReasons: 'economic_reasons',
  missingFeatures: 'missing_features',
  noWorkers: 'no_workers',
  companyCloses: 'company_closes',
  anotherReason: 'another_reason',
}

export const getCancellationOptions = (isFromOrganizerView) => [
  {
    label: 'Motivos económicos',
    value: reasons.economicReasons,
  },
  {
    label: isFromOrganizerView
      ? 'Aleluya no tiene las funcionalidades que la empresa necesita'
      : 'Aleluya no tiene las funcionalidades que necesito',
    value: reasons.missingFeatures,
  },
  {
    label: isFromOrganizerView
      ? 'La empresa no tiene personas'
      : 'No tengo personas',
    value: reasons.noWorkers,
  },
  {
    label: isFromOrganizerView ? 'La empresa cerró' : 'Cerré mi empresa',
    value: reasons.companyCloses,
  },
  {
    label: 'Otros motivos',
    value: reasons.anotherReason,
  },
]

export const getInitialValues = (isFromOrganizerView) => {
  let initialValues = { reason: '', another_reason_detail: '' }

  if (isFromOrganizerView)
    initialValues = {
      ...initialValues,
      comment: '',
    }

  return initialValues
}

export const getValidationSchema = (isFromOrganizerView) => {
  let validationSchema = yup.object({
    reason: yup
      .string()
      .trim()
      .required('Debes seleccionar un motivo de cancelación'),
    another_reason_detail: yup.string().when('reason', {
      is: (reason) => reason === reasons.anotherReason,
      then: yup.string().trim().required(),
    }),
  })

  if (isFromOrganizerView) {
    validationSchema = validationSchema.concat(
      yup.object({
        comment: yup
          .string()
          .nullable()
          .required('Explica por qué se hace esta cancelación desde SA'),
      })
    )
  }

  return validationSchema
}

export const initialValuesCanceled = { comment: '' }

export const validationSchemaCanceled = yup.object({
  comment: yup.string().nullable(),
})
