import http, { getApiURL } from '../httpService'

export const getRetentions = (workerId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/retention_configurations`,
  })

  return http.getV2(url)
}

export const createRetentions = (workerId, data) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/retention_configurations`,
  })

  return http.postV2(url, data)
}

export const updateRetentions = (workerId, data) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/retention_configurations`,
  })

  return http.patchV2(url, data)
}

export const deleteRetentionConfiguration = (workerId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/retention_configurations`,
  })

  return http.deleteV2(url)
}

export default {
  getRetentions,
  createRetentions,
  updateRetentions,
  deleteRetentionConfiguration,
}
