import { CourierProvider } from '@trycourier/react-provider'

import { getCompanyId } from 'utils/company'
import useNotificationsTokenService from 'utils/hooks/notifications/authenticationToken'

import Popover from './Popover'

const CourierNotifications = () => {
  const companyId = getCompanyId()

  const { notificationsTokenQuery } = useNotificationsTokenService({
    serviceParams: {
      queryKey: ['getNotificationsToken', companyId],
    },
  })

  if (!notificationsTokenQuery.data?.token) return null

  return (
    <CourierProvider
      userId={companyId}
      authorization={notificationsTokenQuery.data?.token}
    >
      <Popover />
    </CourierProvider>
  )
}

export default CourierNotifications
