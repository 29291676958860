import { useState } from 'react'

const useBulkNoveltiesErrorsModal = () => {
  const [state, setState] = useState({
    open: false,
    bulkUploadResult: {},
  })

  const openBulkNoveltiesErrorsModal = (websocketResult) => {
    setState({ open: true, bulkUploadResult: websocketResult })
  }

  const closeBulkNoveltiesErrorsModal = () => {
    setState({ open: false })
  }

  return {
    bulkNoveltiesErrorsModalState: state,
    openBulkNoveltiesErrorsModal,
    closeBulkNoveltiesErrorsModal,
  }
}

export default useBulkNoveltiesErrorsModal
