import { useQueryClient } from 'react-query'

import FormField from 'components/UI/Formik/FormField/Index'

import useInstitutionsService from 'utils/hooks/institutionsService'

const formatEntityData = (institutionsData) =>
  institutionsData?.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

const Entities = ({ rowValues, ...props }) => {
  const { institution_category: institutionCategory } = rowValues
  const queryClient = useQueryClient()
  const queryKey = ['getInstitutionsByCategory', institutionCategory]

  const hasInstitutions = Boolean(queryClient.getQueryData(queryKey))

  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey,
      category: institutionCategory,
      needCredentials: true,
    },
    queryOptions: {
      enabled: !hasInstitutions && institutionCategory !== '',
    },
  })

  return (
    <FormField
      {...props}
      name="institution_id"
      variant="select"
      options={formatEntityData(institutionsQuery.data) || []}
    />
  )
}

export default Entities
