import { Box, Paper, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'

import downloadIcon from 'assets/images/views/worker/download.svg'
import downloadImg from 'assets/images/views/worker/downloadWorkers.png'

import getWorkersUploadMessage from 'messages/workers_file'

const WorkerImportBoxDownload = ({ onClick, isLoading }) => {
  const workerFileInfo = getWorkersUploadMessage()

  return (
    <Paper
      sx={(theme) => ({
        flex: 1,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(5),
        backgroundColor: theme.palette.white.light,
      })}
    >
      <Typography variant="h5">1. Descargar Formato Excel</Typography>
      <Typography paragraph>{workerFileInfo.download}</Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          marginBottom: theme.spacing(1),
        })}
      >
        <img src={downloadImg} alt="Download File" />
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: theme.spacing(8),
        })}
      >
        <img src={downloadIcon} alt="Upload Icon" />
        <Button
          onClick={onClick}
          sx={(theme) => ({
            marginTop: theme.spacing(3),
          })}
          loading={isLoading}
        >
          Descargar formato Excel
        </Button>
      </Box>
    </Paper>
  )
}

export default WorkerImportBoxDownload
