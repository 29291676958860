import { useCallback } from 'react'

import { PAYROLL_ACTION_TYPES } from './usePayrollConfiguration'

const useDeductionsModal = ({ payrollState, payrollDispatch }) => {
  const openDeductionsModal = useCallback(
    (noveltyConcept) => {
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SHOW_MODAL,
        modalKeys: {
          noveltyTypeModal: false,
          deductionsModal: true,
        },
      })
      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.SET_MODAL_CONFIGURATION,
        config: {
          noveltyConcept,
        },
      })
    },
    [payrollDispatch]
  )

  const closeDeductionsModal = () =>
    payrollDispatch({
      type: PAYROLL_ACTION_TYPES.CLOSE_MODAL,
      modalKeys: {
        deductionsModal: false,
        noveltyTypeModal: true,
      },
    })

  return {
    deductionsModalState: {
      open: Boolean(payrollState.deductionsModal),
    },
    openDeductionsModal,
    closeDeductionsModal,
  }
}

export default useDeductionsModal
