import { useQueryClient } from 'react-query'

import Table from 'components/UI/Table/Table'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import {
  createCostCenter,
  deleteCostCenter,
  updateCostCenter,
} from 'services/company/costCenterService'

import { columnsData, getValidationSchema } from './helpers'

const CostCenterTable = ({
  data,
  columns = columnsData,
  alegraAccounting = false,
}) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()

  const validationSchema = getValidationSchema(alegraAccounting)

  const callback = (action) => {
    if (action === 'creó')
      trackEvent(integrationEvent.EDIT_SETTINGS, 'Cost Center Add')

    queryClient.invalidateQueries('costCenters')
    showSuccessMessage(`El centro de costos se ${action} correctamente.`)
  }

  const handleAddCostCenter = (costCenter) => {
    const costCenterToCreate = alegraAccounting
      ? { accounting_code: '51', ...costCenter }
      : costCenter

    return {
      data: costCenterToCreate,
      mutationFunction: createCostCenter,
      mutateOptions: { onSuccess: () => callback('creó') },
    }
  }

  const handleUpdateCostCenter = (oldCostCenter, newCostCenter) => {
    const dirtyValues = getDirtyValues(oldCostCenter, newCostCenter)

    if (!isObjectEmpty(dirtyValues)) {
      return {
        data: { ...dirtyValues, id: newCostCenter.id },
        mutationFunction: updateCostCenter,
        mutateOptions: { onSuccess: () => callback('editó') },
      }
    }

    return {
      data: {},
      mutationFunction: () => {},
      mutateOptions: {},
    }
  }

  const handleDeleteCostCenter = (costCenter) => {
    return {
      data: costCenter.id,
      mutationFunction: deleteCostCenter,
      mutateOptions: { onSuccess: () => callback('eliminó') },
    }
  }

  return (
    <Table
      columns={columns}
      data={data}
      options={{
        pagination: false,
        search: false,
        customActionsWidth: 110,
        dataCyPrefix: 'cost-center',
        version: {
          toolbar: 'v2',
        },
      }}
      editable={{
        validationSchema,
        enableAddRow: true,
        addRowActionProps: {
          tooltip: 'Añadir Centro de Costos',
        },
        addRowPosition: 'top',
        onAddRow: handleAddCostCenter,
        onUpdateRow: handleUpdateCostCenter,
        onDeleteRow: handleDeleteCostCenter,
        isDeletable: ({ deleteable }) => data.length > 0 && deleteable,
        tableMinWidth: 600,
      }}
    />
  )
}

export default CostCenterTable
