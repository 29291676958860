export default {
  category:
    'A partir del tipo de contrato, la liquidación de la nómina podría variar. Si es un usuario bajo “prestación de servicios” no tendremos en cuenta ningún tipo de prestaciones en su liquidación de nómina y el usuario quedará clasificado en la aplicación como contratista',
  initial_day:
    '¿Desde que día comenzó este usuario a trabajar en tu compañía? Esta información es importante para tu control como empleador y además es requerida por los operadores de pago PILA.',
  wage_category:
    'A partir del “salario base” se liquidara la nomina. Esta será la base tenida en cuenta para la liquidación de la seguridad social y parafiscales.',
  base_salary:
    'Esta será la base mensual para liquidar tus pagos según la frecuencia de pago de la compañía. Si es Integral Mensual: ingresa la totalidad del salario base (incluyendo el factor prestacional); si es Mensual - medio tiempo: ingresa el valor real que va a devengar mensualmente.',
  work_center:
    'Según el Centro de trabajo asociado a este empleado, se asignará la "Clase de riesgo ARL y se modificará el % de pago de la liquidación correspondiente en la planilla de Seguridad Social.',
  high_risk_pension:
    'Estas personas tienen un régimen especial, por lo que se incluirá un 10% adicional asumido por la empresa en el aporte a pensión.',
  health_provider:
    'Confirma aquí, cual es la Entidad Promotora de Salud (EPS) de la persona.',
  pension_provider:
    'Confirma aquí, cual es el Fondo de Pensiones donde cotiza a pensión.',
  severance_provider:
    'Confirma aquí, cual es el Fondo de Cesantías de la persona.',
}
