import { useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getDataByMetrics } from 'services/dashboardService'

import useErrorHandler from './useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, metrics } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'dashboardByMetrics') return getDataByMetrics(metrics)

  return null
}

const useDashboardService = ({
  serviceParams = { queryKey: 'dashboardByMetrics' },
  queryOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restDashboardResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  return {
    dashboardQuery: {
      data,
      ...restDashboardResponse,
    },
  }
}

export default useDashboardService
