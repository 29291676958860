import NumberFormat from 'react-number-format'

import { InputAdornment, OutlinedInput } from '@mui/material'

const CurrencyField = ({ id, name, value, ...props } = {}) => {
  const {
    setValue,
    touched,
    onBlur,
    align = 'right',
    inputProps = {},
    startAdornment = '$',
    isAllowed,
    ...restProps
  } = props

  return (
    <NumberFormat
      id={id}
      name={name}
      value={value}
      onBlur={onBlur}
      placeholder="0"
      isAllowed={isAllowed}
      // Do NOT add onChange from 'field'
      onValueChange={(values) => {
        setValue(values?.floatValue)
      }}
      onFocus={() => {
        if (value === 0 && !touched) {
          setValue('')
        }
      }}
      customInput={OutlinedInput}
      startAdornment={
        <InputAdornment position="start">{startAdornment}</InputAdornment>
      }
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={0}
      allowNegative={false}
      inputProps={{
        style: {
          ...(align === 'right' && {
            textAlign: 'right',
          }),
        },
        ...inputProps,
      }}
      {...restProps}
    />
  )
}

export default CurrencyField
