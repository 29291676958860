import { Link, useLocation, useParams } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import HelperCard from 'components/Period/Atoms/HelperCard'
import RangeDateCard from 'components/Period/Atoms/RangeDateCard'
import AleluyaPerson from 'components/UI/AleluyaPerson'
import DescriptionCard, { CardContent } from 'components/UI/DescriptionCard'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { fire } from 'utils/emojis'

import {
  PERIOD_GENERATED_PAYROLL,
  PERIOD_PAY_PREVIOUS_PAYROLL,
} from 'config/routes'

import {
  dateRangeConfiguration,
  helpLinksConfiguration,
  socialBenefitAction,
  useSocialBenefitsPeriod,
} from './helpers'

const SocialBenefitDetail = () => {
  const { state } = useLocation()
  const { periodId } = useParams()

  const {
    formattedSocialBenefits: {
      social_benefit_period: {
        initial_day: initialDay,
        end_day: endDay,
        label,
        category,
      },
    },
  } = useSocialBenefitsPeriod()

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(3),
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridAutoRows: 'max-content',
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: 'repeat(6, 1fr)',
        },
        [theme.breakpoints.up('desktop')]: {
          rowGap: theme.spacing(1),
          gridTemplateColumns: 'repeat(12, 1fr)',
          gridTemplateRows: 'repeat(2, max-content)',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          gridColumn: '1 / -1',
          [theme.breakpoints.up('desktop')]: {
            gridColumn: 'span 8',
            gridRow: '1 / 3',
          },
        })}
      >
        <DescriptionCard
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: '16rem 1fr',
              gap: theme.spacing(2),
            },
          })}
        >
          <Box
            sx={{
              width: '17rem',
              minHeight: '18rem',
              height: '100%',
              display: {
                mobile: 'none',
                tablet: 'flex',
              },
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.accent1.main,
                width: '18.625rem',
                height: '18.625rem',
                borderRadius: '100%',
                position: 'absolute',
                left: theme.spacing(-3),
              })}
            />
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <AleluyaPerson
                color="accent3.light"
                width={210}
                height={280}
                viewBox="0 0 350 400"
              />
            </Box>
          </Box>
          <CardContent>
            <Typography variant="h4">{label}</Typography>
            <Typography
              variant="body2"
              sx={(theme) => ({
                marginBottom: theme.spacing(4),
                marginTop: theme.spacing(1),
              })}
            >
              Calculamos tu{' '}
              <Typography variant="lead1">
                {socialBenefitAction[category]}
              </Typography>{' '}
              automáticamente <Emoji code={fire} /> teniendo en cuenta toda la
              información registrada en tu cuenta hasta el día en que vas a
              liquidarla, por esta razón, debes registrar toda la información de
              comisiones, horas extras, ingresos salariales y demás en el
              periodo antes de liquidar esta prestación social.
            </Typography>
            <Button
              component={Link}
              to={
                state?.payPreviousPayroll
                  ? PERIOD_PAY_PREVIOUS_PAYROLL(periodId)
                  : PERIOD_GENERATED_PAYROLL()
              }
              variant="outlined"
              startIcon={
                <Icon
                  name="arrow-right"
                  basic
                  sx={{ transform: 'rotate(180deg)' }}
                />
              }
              size="medium"
              sx={{ width: '10rem' }}
            >
              Regresar
            </Button>
          </CardContent>
        </DescriptionCard>
      </Box>
      <Box
        sx={(theme) => ({
          gridColumn: '1 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 'span 3',
          },
          [theme.breakpoints.up('desktop')]: {
            gridColumn: 'span 4',
          },
        })}
      >
        <RangeDateCard
          range={{
            initialDay,
            endDay,
          }}
          {...dateRangeConfiguration[category]}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 1,
          gridColumn: '1 / -1',
          [theme.breakpoints.up('tablet')]: {
            gridColumn: 'span 3',
          },
          [theme.breakpoints.up('desktop')]: {
            gridColumn: '-1 / -5',
            gridRow: '2 / span 1',
          },
        })}
      >
        <HelperCard
          title={helpLinksConfiguration[category]?.tutorial_1_title}
          link={helpLinksConfiguration[category]?.tutorial_1_link}
          color={helpLinksConfiguration[category]?.tutorial_1_color}
          className="tutorial_1"
          icon={helpLinksConfiguration[category]?.tutorial_1_icon}
        />
        <HelperCard
          title={helpLinksConfiguration[category]?.tutorial_2_title}
          link={helpLinksConfiguration[category]?.tutorial_2_link}
          color={helpLinksConfiguration[category]?.tutorial_2_color}
          className="tutorial_2"
          icon={helpLinksConfiguration[category]?.tutorial_2_icon}
        />
      </Box>
    </Box>
  )
}

export default SocialBenefitDetail
