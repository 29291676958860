import { useState } from 'react'

import {
  Box,
  Paper,
  Typography,
  tabClasses,
  tabsClasses,
  useMediaQuery,
} from '@mui/material'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import Page from 'components/UI/Page/Page'

import { getUserName } from 'utils/auth'
import useWorkerHolisticPayroll from 'utils/hooks/HolisticPayroll/WorkerServices'
import { getWorkerId } from 'utils/worker'

import CharacterRunning from 'assets/images/views/holisticPayroll/character_running.svg'

import { getTabsConfig } from './helpers'

export default function WorkerFinancialWellness() {
  const [activeTab, setActiveTab] = useState(0)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const workerId = getWorkerId()

  const { workerHolisticPayrollQuery } = useWorkerHolisticPayroll({
    serviceParams: {
      queryKey: ['getWorkerHolisticPayrollDashboard', workerId],
      workerId,
    },
  })

  const {
    coaching_sessions: coachingSessions,
    credit_score: creditScore,
    financial_wellness_indicator: financialWellnessIndicator,
  } = workerHolisticPayrollQuery.data || {}

  const workerName = getUserName()

  const tabsConfig = getTabsConfig({
    workerName,
    coachingSessions,
    creditScore,
    financialWellnessIndicator,
    setActiveTab,
  })

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  return (
    <Page
      documentTitle="Bienestar financiero"
      grid
      isLoading={workerHolisticPayrollQuery.isLoading}
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Paper
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(3),
            borderRadius: '1rem',
            backgroundColor: 'accent1.main',
            columnGap: theme.spacing(4),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
            })}
          >
            <Typography variant="h2">
              Salud financiera de{' '}
              <Typography variant="span" color="accent4.main">
                {workerName}
              </Typography>
            </Typography>
            <Typography variant="body1">
              Amamos construir tu futuro juntos y en HD! Por eso, prepara un
              café y siéntate... Es hora de ver cómo está tu salud financiera.
            </Typography>
          </Box>
          {!isMobile ? (
            <Box
              component="img"
              alt="Personaje corriendo"
              src={CharacterRunning}
              width={417}
              height={355}
              sx={(theme) => ({
                zIndex: 1,
                objectFit: 'contain',
                margin: theme.spacing(-13, -2, -13, -5),
              })}
            />
          ) : null}
        </Paper>

        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(7, 0, 7),
          })}
        >
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            tabs={tabsConfig}
            variant={isMobile ? 'scrollable' : 'fullWidth'}
            scrollButtons={isMobile}
            sx={(theme) => ({
              width: '80%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'palette.white.dark',
              padding: theme.spacing(1),
              minHeight: '2rem',
              [`& .${tabsClasses.indicator}`]: {
                display: 'none',
              },
              [`& .${tabsClasses.flexContainer}`]: {
                gap: theme.spacing(1),
              },
              [`& .${tabClasses.root}`]: {
                display: 'flex',
                color: 'black.light',
                ...theme.typography.lead2,
                borderRadius: '0.5rem !important',
                backgroundColor: 'white.main',
                '&:hover': {
                  color: 'accent4.main',
                  borderRadius: '0.5rem !important',
                },
              },
              [`& .${tabClasses.selected}`]: {
                color: 'accent4.main',
                border: `2px solid ${theme.palette.accent4.main}`,
                borderRadius: '0.5rem',
              },
            })}
          />
        </Box>
        {tabsConfig[activeTab]?.content}
      </Box>
    </Page>
  )
}
