import { useCallback, useEffect, useRef, useState } from 'react'

import { Box, CircularProgress, Typography } from '@mui/material'

const useCountdown = ({ startCounting, duration, onComplete }) => {
  const [finished, setFinished] = useState(false)
  const [countdownText, setCountdownText] = useState('')
  const [timeDuration, setTimeDuration] = useState(duration)
  const [countdownPercentage, setCountdownPercentage] = useState(100)

  const timer = useRef()

  const countdown = useCallback(() => {
    timer.current = window.setInterval(() => {
      setTimeDuration((prev) => prev - 1)
      setCountdownPercentage((percentage) => percentage - 100 / duration)
    }, 1000)
  }, [duration])

  const handleComplete = useCallback(() => {
    setFinished(true)

    if (typeof onComplete === 'function') {
      onComplete()
    }
  }, [onComplete])

  useEffect(() => {
    if (startCounting && timeDuration > 0) {
      countdown()
    } else {
      handleComplete()
    }

    return () => {
      window.clearInterval(timer.current)
    }
  }, [countdown, handleComplete, startCounting, timeDuration])

  useEffect(() => {
    const minutes = Math.floor(timeDuration / 60)
    const seconds = timeDuration % 60
    setCountdownText(`${minutes}:${seconds < 10 ? '0' + seconds : seconds}`)
  }, [timeDuration])

  return {
    text: countdownText,
    value: countdownPercentage,
    finished,
  }
}

const CircularProgressCounter = ({
  startCounting = false,
  duration,
  onComplete,
}) => {
  const { text, value } = useCountdown({
    startCounting,
    duration,
    onComplete,
  })

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressCounter
