import { useCallback, useState } from 'react'

const initialState = {
  open: false,
}

const useSeveranceFileModal = () => {
  const [state, setState] = useState(initialState)

  const openModal = useCallback(() => {
    setState({ open: true })
  }, [])

  const closeModal = () => {
    setState({ open: false })
  }

  return {
    severanceFileModalState: state,
    openSeveranceFileModal: openModal,
    closeSeveranceFileModal: closeModal,
  }
}

export default useSeveranceFileModal
