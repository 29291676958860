import { useMutation } from 'react-query'

import { deletePayroll } from 'services/organizer/payrollService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, payrollId } = mutationData

  if (mutationMethod === 'DELETE') return deletePayroll(payrollId)

  return null
}

const usePayrollOrganizerService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const payrollOrganizerMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    payrollOrganizerMutation,
  }
}

export default usePayrollOrganizerService
