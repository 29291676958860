import { useMutation } from 'react-query'

import { deleteContract } from 'services/organizer/worker/contractService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, contractId } = mutationData

  if (mutationMethod === 'DELETE') return deleteContract(contractId)

  return null
}

const useOrganizerWorkerService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const organizerWorkerMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return { organizerWorkerMutation }
}

export default useOrganizerWorkerService
