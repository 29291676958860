import FormField from 'components/UI/Formik/FormField/Index'

import useInstitutionsService from 'utils/hooks/institutionsService'

import messages from 'messages/contract'

const HealthProviderField = ({ contractCategory, optional, ...restProps }) => {
  const { institutionsQuery } = useInstitutionsService({
    serviceParams: {
      queryKey: ['getInstitutionsByCategory', 'health_provider'],
      category: 'health_provider',
    },
  })

  return contractCategory &&
    !['contractor', 'student_decree_055', 'part_time_contract'].includes(
      contractCategory
    ) ? (
    <FormField
      name="health_provider"
      label="EPS"
      placeholder="Seleccione una EPS..."
      variant="autocomplete"
      options={institutionsQuery.data || []}
      tooltipContent={messages.health_provider}
      optional={optional}
      {...restProps}
    />
  ) : null
}

export default HealthProviderField
