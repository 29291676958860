import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getOvertime, updateOvertime } from 'services/payroll/overtimeService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, payrollId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'overtimeByPayroll') return getOvertime(payrollId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, payrollId, items } = mutationData

  if (mutationMethod === 'PUT') return updateOvertime(payrollId, items)

  return null
}

const useOvertimeService = ({
  serviceParams = { queryKey: 'overtimeByPayroll' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restOvertimeResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const overtimeMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    overtimeQuery: {
      data,
      ...restOvertimeResponse,
    },
    overtimeMutation,
  }
}

export default useOvertimeService
