import { Form, Formik } from 'formik'

import { Box, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import useCertificateService from 'utils/hooks/worker/certificate'
import { certificateYearOptions } from 'utils/worker'

const DownloadIncomesCertificatesModal = ({ state, handleClose }) => {
  const companyId = getCompanyId()
  const { certificateMutation } = useCertificateService()
  const { enqueueDownload } = useDownloadManager()

  const handleSubmit = (values) => {
    const dataToSend = getDirtyValues({}, values)

    enqueueDownload({
      name: 'certificados de ingresos y retenciones',
      fileCode: 'incomes_certificates',
      namePlural: true,
      firebasePath: `${companyId}/income_certificate`,
      service: () =>
        certificateMutation.mutateAsync(
          {
            mutationMethod: 'GET',
            mutationKey: 'workersIncomesCertificates',
            options: dataToSend,
          },
          {
            onSuccess: () => {
              handleClose()
            },
          }
        ),
    })
  }

  return (
    <Formik
      initialValues={{
        certificate_year: certificateYearOptions[0].value,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit: onSubmit } = formProps

        return (
          <Modal
            open={state.open}
            header="Certificado de Ingresos y Retenciones"
            onOk={onSubmit}
            onCancel={handleClose}
            okText="Generar Archivos"
            isLoading={certificateMutation.isLoading}
          >
            <Typography>
              Escoge el año del cual quieres generar el Certificado de Ingresos
              y Retenciones.
            </Typography>
            <Form>
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(3),
                })}
              >
                <FormField
                  name="certificate_year"
                  label="Año del certificado:"
                  variant="select"
                  options={certificateYearOptions}
                />
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default DownloadIncomesCertificatesModal
