import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'

const BackgroundCheckModalContent = ({
  state,
  handleClose,
  hadleSubmit,
  isLoading,
}) => {
  return (
    <>
      <Typography
        variant="h3"
        sx={(theme) => ({
          marginBottom: theme.spacing(1),
        })}
      >
        Consulta de antecedentes
      </Typography>
      <Typography variant="body1" color="black.dark">
        Vas a realizarle una consulta de antecedentes a{' '}
        <b>{state.workerName}</b>. Recuerda que al iniciar esta consulta estás
        aceptando tener autorización de la persona para validar su historial.
      </Typography>
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button onClick={hadleSubmit} loading={isLoading} type="submit">
          Consultar
        </Button>
        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
          Regresar
        </Button>
      </Box>
    </>
  )
}

export default BackgroundCheckModalContent
