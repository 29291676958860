import { createCalendar } from '@internationalized/date'
import { useRangeCalendar } from '@react-aria/calendar'
import { useRangeCalendarState } from '@react-stately/calendar'
import { useCallback, useRef, useState } from 'react'

import { Box } from '@mui/material'

import CalendarActions from './CalendarActions'
import CalendarGrid from './CalendarGridV2'
import CalendarHeader from './CalendarHeaderV2'
import CalendarYearGrid from './CalendarYearGrid'
import { useDateRangePickerContext } from './indexV2'

const RangeCalendar = ({ onCloseCalendar, ...restProps }) => {
  const { isDesktop, locale } = useDateRangePickerContext()
  const state = useRangeCalendarState({
    ...restProps,
    locale,
    createCalendar,
  })
  const ref = useRef()
  const {
    calendarProps,
    prevButtonProps,
    nextButtonProps,
    title,
  } = useRangeCalendar(restProps, state, ref)
  const [gridTransition, setGridTransition] = useState({
    key: 0,
    direction: null,
  })
  const [showYearGrid, setShowYearGrid] = useState(false)

  const onCloseYearGrid = useCallback(() => {
    setShowYearGrid(false)
  }, [])

  return (
    <Box
      {...calendarProps}
      ref={ref}
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(2, 3),
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
        })}
      >
        <CalendarHeader
          title={title}
          showYearGrid={showYearGrid}
          setShowYearGrid={setShowYearGrid}
          prevButtonProps={{
            ...prevButtonProps,
            onPress: (event) => {
              prevButtonProps.onPress(event)
              setGridTransition((previousValue) => ({
                key: previousValue.key - 1,
                direction: 'left',
              }))
            },
          }}
          nextButtonProps={{
            ...nextButtonProps,
            onPress: (event) => {
              nextButtonProps.onPress(event)
              setGridTransition((previousValue) => ({
                key: previousValue.key + 1,
                direction: 'right',
              }))
            },
          }}
        />
        <CalendarYearGrid
          open={showYearGrid}
          state={state}
          onCloseYearGrid={onCloseYearGrid}
        />
        <CalendarGrid state={state} gridTransition={gridTransition} />
        {!isDesktop ? (
          <CalendarActions onCloseCalendar={onCloseCalendar} />
        ) : null}
      </Box>
    </Box>
  )
}

export default RangeCalendar
