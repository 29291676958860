import { parseDate } from '@internationalized/date'

const spanishDays = {
  L: 'Lun',
  M: 'Mar',
  X: 'Mié',
  J: 'Jue',
  V: 'Vie',
  S: 'Sáb',
  D: 'Dom',
}

export const getDayAbbreviation = (locale, day) => {
  if (locale === 'es-ES') {
    return spanishDays[day]
  }

  return day
}

export const parseRangeValue = (value) => {
  if (value?.start && value?.end) {
    return {
      start: parseDate(value?.start),
      end: parseDate(value?.end),
    }
  }

  return null
}
