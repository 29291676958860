import { useMemo } from 'react'

import { Box, Tooltip, Typography, tooltipClasses } from '@mui/material'

import { formatCurrency } from 'utils/format'

import { noveltyName } from './helpers'

const PayrollCostDetail = ({ total, noveltiesCost = {} }) => {
  const costItems = useMemo(() => {
    const items = []

    Object.keys(noveltiesCost)?.forEach((noveltyType) => {
      const value = noveltiesCost[noveltyType]?.value || 0

      if (value && value > 0) {
        items.push({
          id: noveltyType,
          name: noveltyName[noveltyType],
          value,
        })
      }
    })

    return items
  }, [noveltiesCost])

  return (
    <Tooltip
      title={
        <Box>
          <Typography
            variant="h5"
            sx={(theme) => ({
              marginBottom: theme.spacing(1),
            })}
            color="black.main"
          >
            Rayos X de novedades
          </Typography>
          <Typography
            variant="body1"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
            color="black.main"
          >
            Estas son las novedades que le has reportado a esta persona:
          </Typography>
          {costItems.length > 0 ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                rowGap: theme.spacing(0.5),
              })}
            >
              {costItems.map((novelty, index) => {
                const isDeductionValue = [
                  'deductions',
                  'loans',
                  'payroll_retention',
                ].includes(novelty.id)
                const displayValue = formatCurrency(novelty.value)

                return (
                  <Box
                    key={index.toString()}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="body1" color="black.main">
                      {novelty.name}:
                    </Typography>
                    <Typography variant="body1" color="black.main">
                      {isDeductionValue ? `(${displayValue})` : displayValue}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          ) : (
            <Typography variant="body1" color="black.main">
              Aún no has agregado novedades
            </Typography>
          )}
        </Box>
      }
      arrow
      placement="bottom-end"
      enterDelay={500}
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            [`&.${tooltipClasses.tooltip}`]: {
              borderRadius: '0.75rem',
              padding: theme.spacing(3, 4),
              maxWidth: '22rem',
              backgroundColor: theme.palette.white.dark,
            },
          }),
        },
        arrow: {
          sx: (theme) => ({
            [`&.${tooltipClasses.arrow}`]: {
              marginTop: '-0.64em !important',
              '&::before': {
                backgroundColor: theme.palette.white.dark,
              },
            },
          }),
        },
      }}
    >
      <Typography variant="h6" color="black.dark" sx={{ whiteSpace: 'nowrap' }}>
        {total}
      </Typography>
    </Tooltip>
  )
}

export default PayrollCostDetail
