import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'

import { yupLocaleES } from 'utils/form'
import useGeneralActionsService from 'utils/hooks/organizer/generalActions'
import useNotifications from 'utils/hooks/useNotifications'

import {
  OrganizerButtonComponent,
  OrganizerGridComponent,
} from '../ComponentGridForm'

yup.setLocale(yupLocaleES)

const ActivatedNITDuplicate = () => {
  const { generalActionsMutation } = useGeneralActionsService()
  const confirm = useConfirm()

  const { showInfoMessage } = useNotifications()

  const handleSendNit = (data) => {
    generalActionsMutation.mutate(
      {
        mutationMethod: 'PATCH',
        data: { country: data },
      },
      {
        onSuccess: ({ message }) => {
          showInfoMessage(message)
        },
      }
    )
  }

  const handleSubmit = (values) => {
    confirm({
      category: 'warning',
      description: 'Se va a permitir duplicar en NIT que estas ingresando',
      okText: 'Sí, continuar',
      onOk: () => handleSendNit(values),
    })
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ company_id_number: '' }}
        enableReinitialize
      >
        {({ values }) => (
          <OrganizerGridComponent>
            <Typography variant="h3" gutterBottom>
              Activar NIT duplicado
            </Typography>
            <Form>
              <FormField name="company_id_number" label="NIT" type="text" />
              <OrganizerButtonComponent>
                <Button
                  type="submit"
                  disabled={!values.company_id_number.length}
                  loading={generalActionsMutation.isLoading}
                >
                  Crear NIT duplicado
                </Button>
              </OrganizerButtonComponent>
            </Form>
          </OrganizerGridComponent>
        )}
      </Formik>
    </>
  )
}

export default ActivatedNITDuplicate
