import * as yup from 'yup'

import { Box, Button } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import useDownloadURI from 'utils/hooks/useDownloadURI'

import Entities from './Entities'
import Password from './Password'
import UserName from './UserName'

export const validationSchema = yup.object({
  institution_id: yup.string().nullable().required(),
  username: yup.string().nullable().required(),
  password: yup.string().nullable().required(),
})

export const affiliationProviders = [
  { label: 'EPS', value: 'health_provider' },
  { label: 'Riesgos laborales (ARL)', value: 'risk_provider' },
  { label: 'Fondo de pensiones', value: 'pension_provider' },
  { label: 'Caja de compensación familiar', value: 'compensation_fund' },
]

export const getCredentialsColumns = () => {
  const getNameEntityType = (category) => {
    const provider = affiliationProviders.find(
      ({ value }) => category === value
    )
    return provider ? provider.label : ''
  }

  return [
    {
      Header: 'Tipo de entidad',
      accessor: 'institution_category',
      editable: true,
      Cell: ({ row }) => {
        const category = row?.original?.institution_category
        return category ? getNameEntityType(category) : ''
      },
      name: 'institution_category',
      field: 'select',
      editOptions: () => affiliationProviders,
    },
    {
      Header: 'Nombre entidad',
      accessor: 'institution_id',
      editable: true,
      Cell: ({ row }) =>
        row.original.institution_name ? row.original?.institution_name : '',
      Edit: ({ rowValues }) => <Entities rowValues={rowValues} />,
    },
    {
      Header: 'Usuario',
      accessor: 'username',
      editable: true,
      Cell: ({ value }) => <UserName value={value} />,
      customMinWidth: '150px',
    },
    {
      Header: 'Contraseña',
      accessor: 'password',
      editable: true,
      Cell: ({ value }) => <Password value={value} />,
      customMinWidth: '180px',
    },
  ]
}

export const CompanyDocumentField = ({
  fieldName,
  documentName,
  documentUrl,
  tooltipContent = 'El archivo debe ser un PDF.',
  label,
}) => {
  const downloadURI = useDownloadURI()
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'flex-end',
        columnGap: theme.spacing(1),
        width: '100%',
        minWidth: 0,
      })}
    >
      <FormField
        name={`files.${fieldName}`}
        variant="file"
        fileType="application/pdf"
        accept="application/pdf"
        fileName={documentName}
        label={label}
        tooltipContent={tooltipContent}
      />
      <Button
        variant="text"
        size="small"
        sx={(theme) => ({
          marginBottom: theme.spacing(1),
        })}
        disabled={!documentUrl}
        onClick={() => downloadURI(documentUrl)}
      >
        Ver
      </Button>
    </Box>
  )
}
