import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  name: yup.string().trim().required(),
  department: yup.object().nullable().required(),
  city: yup.object().nullable().required(),
  compensation_fund: yup.object().nullable().required(),
})

export default validationSchema
