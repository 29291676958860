import Link from 'components/UI/MaterialUI/Link'

import MonogramLogo from '../MonogramLogo'

const SidebarLogo = () => {
  return (
    <Link
      to="/"
      sx={{
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
      }}
    >
      <MonogramLogo />
    </Link>
  )
}

export default SidebarLogo
