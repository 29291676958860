import { bindMenu, bindTrigger } from 'material-ui-popup-state/hooks'

import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'

const DropdownButton = ({
  title,
  options,
  popupState,
  menuProps = {},
  iconButton = false,
  Icon,
  ...props
}) => {
  return (
    <>
      {!iconButton && !Icon ? (
        <Button {...bindTrigger(popupState)} {...props}>
          {title}
        </Button>
      ) : (
        Icon
      )}

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock
        {...menuProps}
      >
        {options && options.length > 0
          ? options.map((item) => {
              return (
                <MenuItem
                  onClick={item.onClick}
                  key={`menu_${item.id}_item`}
                  disabled={item.disabled}
                >
                  {item.icon && item.iconPosition === 'left' ? (
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  ) : null}
                  <ListItemText>{item.name}</ListItemText>
                  {item.icon &&
                  (!item.iconPosition || item.iconPosition === 'right') ? (
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  ) : null}
                </MenuItem>
              )
            })
          : null}
      </Menu>
    </>
  )
}

export default DropdownButton
