import { Link } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import { getMonthAndYear } from 'utils/dateTime'
import { areObjectsEqual } from 'utils/general'
import usePeriodService from 'utils/hooks/payroll/periodService'

import { getAllWorkersWages } from 'services/worker/wageService'

import { WORKER_SHOW } from 'config/routes'

import UpdateWageConfirmModal from './WageUpdateConfirmModal'
import useWageUpdateConfirmModal from './WageUpdateConfirmModal/useUpdateWageConfirmModal.js'
import { columnsData, validationSchema } from './helpers'

const WagesUpdate = () => {
  const {
    wageUpdateConfirmModalState,
    openWageUpdateConfirmModal,
    closeWageUpdateConfirmModal,
  } = useWageUpdateConfirmModal()

  const companyId = getCompanyId()
  const { periodQuery } = usePeriodService({
    serviceParams: {
      queryKey: ['currentPeriod', companyId],
    },
  })

  const initialPeriodDay = periodQuery?.data?.initial_day

  const fetchWorkersWages = (pageIndex, search) => {
    return {
      queryKey: ['getAllWorkersWages', pageIndex + 1, search],
      queryFunction: () => getAllWorkersWages({ page: pageIndex + 1, search }),
    }
  }

  const handleUpdateSalary = (oldWageData, newWageData) => {
    if (areObjectsEqual(oldWageData, newWageData)) return

    openWageUpdateConfirmModal(newWageData)
    return
  }

  return (
    <Page
      title="Actualizar salarios"
      documentTitle="Actualizar salarios"
      header={`Detalle de salarios ${getMonthAndYear(initialPeriodDay) || ''}`}
      isLoading={periodQuery.isLoading}
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Typography variant="body1">
          Desde esta tabla, puedes editar el valor del salario y la fecha desde
          la cual aplica el cambio. Para ello, haz clic en la acción editar.
        </Typography>
        <Table
          data={fetchWorkersWages}
          actions={[
            (rowData) => ({
              id: 'seeProfile',
              tooltip: 'Ver perfil',
              icon: <Icon name="eye" basic />,
              buttonProps: {
                component: Link,
                to: WORKER_SHOW(rowData.worker_id),
                color: 'complementary1',
                onClick: undefined,
              },
            }),
          ]}
          columns={columnsData}
          options={{
            version: {
              toolbar: 'v2',
            },
            alignActionsHeader: 'right',
            alignActionsCell: 'right',
            customActionsWidth: '5rem',
          }}
          editable={{
            onUpdateRow: handleUpdateSalary,
            validationSchema,
            hideDelete: true,
          }}
        />
      </Box>
      {wageUpdateConfirmModalState.open ? (
        <UpdateWageConfirmModal
          handleClose={closeWageUpdateConfirmModal}
          state={wageUpdateConfirmModalState}
        />
      ) : null}
    </Page>
  )
}

export default WagesUpdate
