import { Navigate, useLocation } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'

import { ACCOUNT_SETTINGS } from 'config/routes'

/**
 * View related to a worker so the user needs to have worker role to access this routes
 */
const View = ({ children }) => {
  const { isWorker } = useUser()
  const { pathname } = useLocation()

  const isUserProfileRoute = ACCOUNT_SETTINGS === pathname

  if (!isWorker && !isUserProfileRoute) {
    return <Navigate to="/" />
  }

  return children
}

export default View
