import { Formik } from 'formik'
import { useCallback, useMemo } from 'react'

import { TableCell, TableRow } from '@mui/material'

import ConfirmEditActions from './ConfirmEditActions'
import EditRowCell from './EditRowCell'
import { getEditInitialValue } from './helpers'

const EditRow = ({
  rowIndex,
  cancelEditRow,
  confirmEditRow,
  cells,
  rowValues,
  validationSchema,
  editMode,
  setCurrentData,
  customActionsWidth,
  alignActionsCell = 'left',
  ...props
}) => {
  const editableCells = useMemo(
    () =>
      cells
        .filter((cell) => !cell.column.excludeOnEdit)
        .map((cell, index) => ({ ...cell, key: `cell_${index}_edit` })),
    [cells]
  )
  const editableValues = useMemo(() => {
    const values = {}
    Object.keys(rowValues).map((valueKey) => {
      if (!['selection', 'actions'].includes(valueKey)) {
        values[valueKey] = getEditInitialValue(cells, valueKey, rowValues)
      }
      return undefined
    })

    return values
  }, [cells, rowValues])

  const onSubmit = useCallback(
    (currentValues) => {
      confirmEditRow(editableValues, currentValues)
    },
    [confirmEditRow, editableValues]
  )

  const handleCancel = () => {
    if (editMode === 'onAdd') {
      setCurrentData((oldData) =>
        oldData.filter((row) => !row.id.includes('new_row'))
      )
    }
    cancelEditRow()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={editableValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <TableRow
            {...props}
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.error.main} !important`,
            })}
          >
            {editableCells.map((cell) => {
              return (
                <TableCell
                  role="cell"
                  key={cell.key}
                  style={{
                    width: `calc(${cell.column.customWidth} - 32px)`,
                  }}
                >
                  <EditRowCell cell={cell} editMode={editMode} />
                </TableCell>
              )
            })}
            <TableCell
              key={`cell_${rowIndex}_actions`}
              role="cell"
              colSpan={1}
              sx={{
                whiteSpace: 'noWrap',
                textAlign: 'left',
                ...(alignActionsCell === 'center' && {
                  textAlign: 'center',
                }),
                ...(alignActionsCell === 'right' && {
                  textAlign: 'right',
                }),
              }}
              style={
                customActionsWidth ? { width: customActionsWidth } : undefined
              }
            >
              <ConfirmEditActions
                onCancel={handleCancel}
                onSave={handleSubmit}
              />
            </TableCell>
          </TableRow>
        )
      }}
    </Formik>
  )
}

export default EditRow
