import http, { getApiURL } from '../httpService'

export const getAllAdminFolders = (workerId, { page } = {}) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders`,
    searchParams: { page },
  })

  return http.getV2(url)
}

export const getAllWorkerFolders = (workerId, { page } = {}) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/folders`,
    withoutCompany: true,
    searchParams: { page },
  })

  return http.getV2(url)
}

export const getAllAdminDocuments = (workerId, folderId, { page } = {}) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders/${folderId}`,
    searchParams: { page },
  })

  return http.getV2(url)
}

export const getAllWorkerDocuments = (workerId, folderId, { page } = {}) => {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/folders/${folderId}`,
    withoutCompany: true,
    searchParams: { page },
  })

  return http.getV2(url)
}

export const createFolder = (workerId, data) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders`,
  })

  return http.postV2(url, data)
}

export const deleteFolder = (workerId, folderId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders/${folderId}`,
  })

  return http.deleteV2(url)
}

export const updateFolder = (workerId, folderId, data) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders/${folderId}`,
  })

  return http.putV2(url, data)
}

export const createDocument = (workerId, folderId, data) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders/${folderId}/documents`,
  })

  return http.postV2(url, data)
}

export const deleteDocument = (workerId, folderId, documentId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders/${folderId}/documents/${documentId}`,
  })

  return http.deleteV2(url)
}

export const updateDocument = (workerId, folderId, documentId, data) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/folders/${folderId}/documents/${documentId}`,
  })

  return http.putV2(url, data)
}

export default {
  getAllAdminFolders,
  getAllAdminDocuments,
  createFolder,
  deleteFolder,
  updateFolder,
  createDocument,
  deleteDocument,
  updateDocument,
}
