import * as yup from 'yup'

const MAX_FILE_SIZE = 1000000
const MIN_FILE_SIZE = 0
const SUPPORTED_FORMATS = ['image/png', 'image/jpeg']

export const getCompanyConfigurationInitialValues = (
  companyConfigData,
  tab
) => {
  if (tab !== 'working_time') {
    const configMap = {
      payment_settings: {
        // Payment configuration
        general_incapacity_payment:
          companyConfigData.general_incapacity_payment,
        proportional_deductions: companyConfigData.proportional_deductions,
        transport_subsidy_per_period:
          companyConfigData.transport_subsidy_per_period,
        transport_subsidy_base: companyConfigData.transport_subsidy_base,
        pay_holidays_31: companyConfigData.pay_holidays_31,
        worker_pension_unpaid_license:
          companyConfigData.worker_pension_unpaid_license,
        pay_february_extra_days_if_hired_in_month:
          companyConfigData.pay_february_extra_days_if_hired_in_month,
        deduct_not_worked_concepts_from_service_bonus:
          companyConfigData.deduct_not_worked_concepts_from_service_bonus,
        withholding_tax_per_period:
          companyConfigData.withholding_tax_per_period,
        // Edit period history
        edit_periods: companyConfigData.edit_periods,
      },
      uvt: {
        // UVT configuration
        uvt_configuration: companyConfigData?.uvt_configuration,
      },
      signature_documents: {
        // Certificate signature
        signature_image:
          companyConfigData.labor_certificate_signature?.signature_image,
        signee_name: companyConfigData.labor_certificate_signature?.signee_name,
        signee_id_number:
          companyConfigData.labor_certificate_signature?.signee_id_number,
        signee_document_type:
          companyConfigData.labor_certificate_signature?.signee_document_type,
        signee_position:
          companyConfigData.labor_certificate_signature?.signee_position,
        signee_email:
          companyConfigData.labor_certificate_signature?.signee_email,
        signee_phone:
          companyConfigData.labor_certificate_signature?.signee_phone,
        signature_in_labor_certificate:
          companyConfigData.signature_in_labor_certificate,
        signature_in_payslip: companyConfigData.signature_in_payslip,
      },
      // Social security file type
      social_security_file: {
        social_security_file_type: companyConfigData.social_security_file?.type,
        social_security_branch_code:
          companyConfigData.social_security_file?.branch_code,
        social_security_branch_name:
          companyConfigData.social_security_file?.branch_name,
      },
    }

    return configMap[tab]
  }

  return {}
}
export const validationSchema = {
  payment_settings: yup.object({
    general_incapacity_payment: yup.string().nullable().required(),
    pay_holidays_31: yup.string().nullable().required(),
    proportional_deductions: yup.string().nullable().required(),
    transport_subsidy_per_period: yup.string().nullable().required(),
    transport_subsidy_base: yup.string().nullable().required(),
    worker_pension_unpaid_license: yup.string().nullable().required(),
    pay_february_extra_days_if_hired_in_month: yup
      .string()
      .nullable()
      .required(),
    deduct_not_worked_concepts_from_service_bonus: yup
      .string()
      .nullable()
      .required(),
    withholding_tax_per_period: yup.string().nullable().required(),
    edit_periods: yup.boolean().required(),
  }),
  uvt: yup.object({
    uvt_configuration: yup.string().required(),
  }),
  signature_documents: yup.object({
    signature_image: yup
      .mixed()
      .nullable()
      .required()
      .test(
        'fileSize',
        'El tamaño del archivo no debe superar 1 MB.',
        (value) => {
          if (!value) return true

          if (value instanceof File) {
            return value.size <= MAX_FILE_SIZE
          }

          return true
        }
      )
      .test(
        'fileSize',
        '¡No pudimos cargar la imagen! El archivo que intentas subir no tiene contenido o se encuentra dañado. Intenta con uno nuevo.',
        (value) => {
          if (!value) return true

          if (value instanceof File) {
            return value.size > MIN_FILE_SIZE
          }

          return true
        }
      )
      .test('fileType', 'Formato de imagen no soportado.', (value) => {
        if (!value) return true

        if (value instanceof File) {
          return SUPPORTED_FORMATS.includes(value.type)
        }

        return true
      }),
    signee_name: yup
      .string()
      .nullable()
      .when('signature_image', {
        is: (signatureImage) => signatureImage,
        then: yup.string().nullable().required(),
      }),
    signee_id_number: yup.string().nullable(),
    signee_document_type: yup
      .string()
      .nullable()
      .when('signee_id_number', {
        is: (signeeIdNumber) => signeeIdNumber,
        then: yup.string().nullable().required(),
      }),
    signee_position: yup
      .string()
      .nullable()
      .when('signature_image', {
        is: (signatureImage) => signatureImage,
        then: yup.string().nullable().required(),
      }),
    signee_email: yup
      .string()
      .nullable()
      .email()
      .when('signature_image', {
        is: (signatureImage) => signatureImage,
        then: yup.string().nullable().email().required(),
      }),
    signee_phone: yup.string().nullable().min(10).max(10),
  }),
  social_security_file: yup.object({
    social_security_file_type: yup.string().nullable().required(),
    social_security_branch_code: yup
      .string()
      .when('social_security_file_type', {
        is: (socialSecurityFileType) => socialSecurityFileType === 'branch',
        then: yup.string().max(10).nullable().required(),
      }),
    social_security_branch_name: yup
      .string()
      .when('social_security_file_type', {
        is: (socialSecurityFileType) => socialSecurityFileType === 'branch',
        then: yup.string().max(40).nullable().required(),
      }),
  }),
}

export const tabs = [
  {
    name: 'payment_settings',
    label: 'Configuración de pagos',
  },
  {
    name: 'working_time',
    label: 'Jornada laboral',
  },
  {
    name: 'social_security_file',
    label: 'Presentación seguridad social',
  },
  {
    name: 'uvt',
    label: "UVT's para retefuente",
  },
  {
    name: 'signature_documents',
    label: 'Firma para documentos',
  },
]
