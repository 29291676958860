import { useState } from 'react'

const initialState = {
  open: false,
  periodId: null,
  decreePeriodId: null,
}

const useDecreeSocialSecurityFileModal = (periodId) => {
  const [state, setState] = useState({
    ...initialState,
    periodId,
  })
  const openModal = (decreePeriodId) => {
    setState({ ...state, open: true, decreePeriodId })
  }

  const closeModal = () => {
    setState({ ...state, open: false, decreePeriodId: null })
  }

  return {
    decreeSocialSecurityFileModalState: state,
    openDecreeSocialSecurityFileModal: openModal,
    closeDecreeSocialSecurityFileModal: closeModal,
  }
}

export default useDecreeSocialSecurityFileModal
