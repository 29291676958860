import { Box, Grid, Paper, Typography, useMediaQuery } from '@mui/material'

import Adornment from 'components/UI/Adornment'

import { documentTypes as companyDocTypes } from 'utils/company'
import { formatDisplayDate } from 'utils/dateTime'
import { formatIdNumber } from 'utils/format'
import { documentTypes as workerDocTypes } from 'utils/worker'

import { adornmentsConfig, calculationTypes } from './helpers'

const PeriodDetailsHeader = ({ headerData = {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
      sx={{
        gridColumn: '1 / -1',
      }}
    >
      {Object.keys(headerData).map((type) => {
        const typeData = headerData[type]

        return type !== 'payroll_options' ? (
          <Grid key={type} xs={isMobile ? 12 : 4} item>
            <Paper
              sx={(theme) => ({
                height: '100%',
                padding: theme.spacing(2.5, 2.25),
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: calculationTypes[type].backgroundColor,
                position: 'relative',
                overflow: 'hidden',
              })}
            >
              <Adornment
                variant={calculationTypes[type].adornment}
                color={calculationTypes[type].adornmentColor}
                {...adornmentsConfig[calculationTypes[type].adornment]}
              />
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  columnGap: theme.spacing(1),
                  alignItems: 'center',
                  marginBottom: theme.spacing(2),
                  zIndex: 10000,
                })}
              >
                {calculationTypes[type].icon}
                <Typography variant="body1">
                  {calculationTypes[type].title}
                </Typography>
              </Box>
              {type === 'company' || type === 'worker' ? (
                <>
                  <Typography variant="h6">
                    <b>{typeData.name}</b>
                  </Typography>
                  <Typography variant="h6">
                    {type === 'worker'
                      ? workerDocTypes[typeData.document_type]
                      : companyDocTypes[typeData.document_type]}
                    {typeData.document_type ? ': ' : null}
                    {formatIdNumber(typeData.id_number)}
                  </Typography>
                  {type === 'worker' ? (
                    <Typography variant="h6">
                      {typeData.contract_category}
                    </Typography>
                  ) : null}
                </>
              ) : null}
              {type === 'period' ? (
                <>
                  <Typography variant="h6">
                    <b>{`${formatDisplayDate(
                      typeData.period_payment?.initial_day
                    )} - ${formatDisplayDate(
                      typeData.period_payment?.end_day
                    )}`}</b>
                  </Typography>

                  <Typography variant="h6">
                    Días trabajados: {typeData.worked_days}
                  </Typography>
                </>
              ) : null}
            </Paper>
          </Grid>
        ) : null
      })}
    </Grid>
  )
}

export default PeriodDetailsHeader
