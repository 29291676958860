export const getAccountColumns = (
  integrationOptions,
  counterpartCodes,
  category
) => {
  const columns = [
    {
      Header: 'Concepto',
      accessor: 'payroll_concept_name',
      editable: false,
    },
    {
      Header: 'Cuenta Alegra',
      accessor: 'integration_code',
      Cell: ({ row }) => row.original.integration_code?.name,
      editable: (_, rowValues) =>
        !(category === 'social_security' && !rowValues.integration_code),
      field: 'autocomplete',
      name: 'integration_code',
      editOptions: integrationOptions,
    },
  ]

  if (
    category !== 'payment_method' &&
    category !== 'deductions' &&
    category !== 'retentions'
  ) {
    columns.push({
      Header: 'Contrapartida Alegra',
      accessor: 'counterpart_code',
      Cell: ({ row }) => row.original.counterpart_code?.name,
      editable: true,
      field: 'autocomplete',
      name: 'counterpart_code',
      editOptions: counterpartCodes,
    })
  }

  return columns
}

export const getCostCenterColumns = (alegraOptions) => {
  return [
    {
      Header: 'Concepto',
      placeholder: 'Concepto',
      accessor: 'name',
      name: 'name',
      editable: true,
    },
    {
      Header: 'Centro de costos Alegra',
      accessor: 'integration_code',
      name: 'integration_code',
      field: 'select',
      editOptions: () => {
        const options = alegraOptions?.map((option) => ({
          label: option.name,
          value: option.id,
        }))
        return options || []
      },
      Cell: ({ row }) => {
        return (
          alegraOptions.find(
            (option) => option.id === row.original.integration_code
          )?.name || '-'
        )
      },
      editable: true,
    },
    {
      Header: '# Personas',
      accessor: 'workers_number',
    },
  ]
}
