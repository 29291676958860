import { useContext } from 'react'

import { Box } from '@mui/material'

import { CardGridContext } from '.'

const HeaderRow = ({ children }) => {
  const { gridColumns } = useContext(CardGridContext)

  return (
    <Box
      sx={(theme) => ({
        display: 'none',
        [theme.breakpoints.up('tablet')]: {
          display: 'grid',
          gridTemplateColumns: gridColumns,
          padding: theme.spacing(2.5, 2),
        },
        [theme.breakpoints.up('laptop')]: {
          padding: theme.spacing(2.5, 4),
        },
      })}
    >
      {children}
    </Box>
  )
}

export default HeaderRow
