import http, { getApiURL } from '../httpService'

export const getOvertime = (payrollId) => {
  const url = getApiURL({ pathname: `/payrolls/${payrollId}/overtime_items` })

  return http.getV2(url)
}

export const updateOvertime = (payrollId, items) => {
  const url = getApiURL({ pathname: `/payrolls/${payrollId}/overtime_items` })

  const requestData = {
    items,
  }

  return http.putV2(url, requestData)
}

export default {
  getOvertime,
  updateOvertime,
}
