import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useNotifications from 'utils/hooks/useNotifications'
import {
  generateEmptyPayrollItem,
  incomesElectronicPayroll,
} from 'utils/payroll'

const useOtherIncomes = (handleClose, electronicPayrollId) => {
  const [deletedItems, setDeletedItems] = useState([])
  const [incomes, setIncomes] = useState([generateEmptyPayrollItem()])
  const [concepts, setConcepts] = useState([])
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const params = useParams()

  const payrollConceptQueryKey = ['payrollConcepts', 'electronic_payroll']
  const electronicItemsQueryKey = [
    'electronicPayrollItems',
    electronicPayrollId,
  ]

  const {
    electronicPayrollPeriodQuery,
    electronicPayrollMutation,
  } = useElectronicPayrollPeriodService({
    serviceParams: {
      queryKey: electronicItemsQueryKey,
      periodId: electronicPayrollId,
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          setIncomes(data)
        }
      },
    },
  })
  const { payrollConceptsQuery } = usePayrollConceptsService({
    serviceParams: {
      queryKey: payrollConceptQueryKey,
      conceptsCategory: 'electronic_payroll',
    },
    queryOptions: {
      enabled: electronicPayrollPeriodQuery.status === 'success',
      onSuccess: ({ data }) => {
        const newConcepts = data
        incomes.forEach((itemIncome) => {
          const indexConcept = newConcepts.findIndex(
            (itemConcept) => itemConcept.id === itemIncome.payroll_concept_id
          )
          if (indexConcept !== -1) {
            newConcepts[indexConcept].selected = true
          }
        })
        setConcepts(newConcepts)
      },
    },
  })
  const initialValues = electronicPayrollPeriodQuery.data

  const isQueryLoading =
    payrollConceptsQuery.isLoading || electronicPayrollPeriodQuery.isLoading

  const callback = useCallback(() => {
    queryClient.invalidateQueries([
      'electronicPayrollPeriod',
      getCompanyId(),
      params.periodId,
      '',
    ])
    handleClose()
  }, [handleClose, params.periodId, queryClient])

  const getDirtyValues = useCallback(() => {
    let dirtyValues = []
    if (initialValues?.length > 0 && incomes?.length <= initialValues?.length) {
      incomes.forEach((incomeItem) => {
        initialValues.forEach((initialIncome) => {
          const equalId = incomeItem.id === initialIncome.id
          const equalValue = incomeItem.value === initialIncome.value
          const equalCodedName =
            incomeItem.coded_name === initialIncome.coded_name
          if (incomeItem.id && equalId) {
            if (!equalValue) {
              dirtyValues.push(incomeItem)
            }
            if (!equalCodedName) {
              dirtyValues.push({ ...initialIncome, value: 0 })
              dirtyValues.push(incomeItem)
            }
          }
        })
      })
    } else {
      dirtyValues = incomes.filter((incomeItem) => incomeItem.value > 0)
    }

    return dirtyValues
  }, [initialValues, incomes])

  const handleSubmit = useCallback(() => {
    const dirtyValues = getDirtyValues()

    if (!isObjectEmpty(dirtyValues) || deletedItems.length !== 0) {
      electronicPayrollMutation.mutate(
        {
          mutationMethod: 'PUT',
          electronicPayrollItems: {
            items: [...dirtyValues, ...deletedItems],
          },
          periodId: electronicPayrollId,
        },
        {
          onSuccess: () => {
            showSuccessMessage('Concepto de nómina actualizado exitosamente')
            callback()
          },
        }
      )
    }
  }, [
    callback,
    deletedItems,
    electronicPayrollId,
    electronicPayrollMutation,
    getDirtyValues,
    showSuccessMessage,
  ])

  const handleAddConcept = () => {
    const newIncomes = [...incomes]
    setIncomes([...newIncomes, generateEmptyPayrollItem()])
  }

  const handleSelectItem = (electronicPayrollConceptId, index) => {
    const prevItem = incomes[index]

    let selectedConcept

    const newConcepts = concepts.map((concept) => {
      const conceptCopy = { ...concept }
      if (prevItem.payroll_concept_id === conceptCopy.id)
        conceptCopy.selected = false

      if (conceptCopy.id === electronicPayrollConceptId) {
        conceptCopy.selected = true

        selectedConcept = conceptCopy
      }

      return conceptCopy
    })

    const newIncomes = [...incomes]

    newIncomes[index] = {
      ...prevItem,
      name: selectedConcept.name,
      payroll_concept_id: selectedConcept.id,
      coded_name: selectedConcept.coded_name,
    }

    setConcepts(newConcepts)
    setIncomes(newIncomes)
  }

  const handleChangeItemValue = ({ target: { rawValue } }, index) => {
    const newIncomes = [...incomes]

    newIncomes[index] = { ...newIncomes[index], value: Number(rawValue) }

    setIncomes(newIncomes)
  }

  const handleDeleteItem = (index) => {
    const newIncomes = [...incomes]

    const itemToDelete = { ...newIncomes[index], value: 0 }

    newIncomes.splice(index, 1)

    if (incomesElectronicPayroll.includes(itemToDelete.coded_name)) {
      setDeletedItems([...deletedItems, itemToDelete])
    }

    if (itemToDelete.payroll_concept_id !== null) {
      const newConcepts = concepts.map((concept) => {
        const conceptCopy = { ...concept }

        if (itemToDelete.payroll_concept_id === conceptCopy.id)
          conceptCopy.selected = false

        return conceptCopy
      })
      setConcepts(newConcepts)
    }

    if (newIncomes.length === 0) {
      newIncomes.push(generateEmptyPayrollItem())
    }

    setIncomes(newIncomes)
  }

  return {
    handleSubmit,
    handleAddConcept,
    handleSelectItem,
    handleChangeItemValue,
    handleDeleteItem,
    incomes,
    concepts,
    isQueryLoading,
    isMutatingData: electronicPayrollMutation.isLoading,
  }
}

export default useOtherIncomes
