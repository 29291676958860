import http, { getApiURL } from './httpService'

export const getDepartments = () => {
  const url = getApiURL({
    pathname: '/departments',
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getCitiesByDepartment = (departmentId) => {
  const url = getApiURL({
    pathname: `/departments/${departmentId}`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export default {
  getDepartments,
  getCitiesByDepartment,
}
