import { useContext, useMemo, useState } from 'react'

import { useMediaQuery } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import useLocationService from 'utils/hooks/company/locationService'

import { getAllWorkers } from 'services/worker/workerService'

import EmptyState from '../Commons/EmptyState'
import { WorkerIndexContext } from './Index'
import columnsData from './columnsData'
import useTableActions from './useTableActions'

const WorkersTable = ({ paginationRef }) => {
  const [activeLocation, setActiveLocation] = useState('')
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  const {
    subscription: { payrolls_size: payrollsSize },
  } = useSubscription()

  const companyId = getCompanyId()

  const {
    workerTotalData,
    tab,
    setOpenBackgroundCheckModal,
    setWorker,
    handleAddWorkerManually,
    activeContract,
    setActiveContract,
    openNewWorkerModal,
  } = useContext(WorkerIndexContext)

  const hasData = workerTotalData?.some((item) => item.size > 0)

  const { locationQuery } = useLocationService({
    serviceParams: { queryKey: ['companyLocation', companyId] },
  })

  const locationData = useMemo(
    () => locationQuery.data || [],
    [locationQuery.data]
  )

  const fetchFilterWorkers = (pageIndex, search) => {
    return {
      queryKey: [
        'filteredWorkers',
        companyId,
        pageIndex,
        search,
        activeContract,
        activeLocation,
      ],
      queryFunction: () =>
        getAllWorkers({
          page: pageIndex + 1,
          search,
          filter: activeContract,
          location: activeLocation,
        }),
    }
  }

  const tableActions = useTableActions({
    activeLocation,
    setActiveLocation,
    activeContract,
    setActiveContract,
    locationData,
    workerTotalData,
    tab,
    setOpenBackgroundCheckModal,
    setWorker,
    handleAddWorkerManually,
    openNewWorkerModal,
    payrollsSize,
  })

  return (
    <Table
      columns={columnsData}
      data={fetchFilterWorkers}
      actions={tableActions}
      options={{
        searchProps: {
          sx: {
            maxWidth: isTabletUp ? '13.5rem !important' : 'inherit',
          },
        },
        emptyStateComponent: (
          <EmptyState
            title="Aún no has agregado nadie de tu equipo. ¿Qué esperas?"
            description="Carga todos tus empleados para que puedas comenzar a disfrutar de la mejor experiencia de gestión de equipos del mundo."
            handleAddWorkerManually={handleAddWorkerManually}
          />
        ),
        version: {
          toolbar: 'v2',
        },
        alignActionsCell: 'left',
        alignActionsHeader: 'left',
        hasData,
        tableRefs: {
          pagination: paginationRef,
        },
      }}
    />
  )
}

export default WorkersTable
