import http, { getApiURL } from '../httpService'

export const getBillingInformation = () => {
  const url = getApiURL({ pathname: '/billing_information' })

  return http.getV2(url)
}

export const updateBillingInformation = (billingInformation) => {
  const url = getApiURL({ pathname: '/billing_information' })

  const data = { billing_information: billingInformation }

  return http.patchV2(url, data)
}
