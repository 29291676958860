import http, { getApiURL } from '../httpService'

export function getWorkerHolisticPayrollDashboard(workerId) {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/financial_wellness/dashboard`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export function getHolisticPayrollCalculatorData(workerId) {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/financial_wellness/calculator`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export function getHolisticPayrollCalculatorHistory(workerId) {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/financial_wellness/calculators`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export function calculateFinancialWellness(workerId, data) {
  const url = getApiURL({
    pathname: `/workers_profile/workers/${workerId}/financial_wellness/calculator`,
    withoutCompany: true,
  })

  return http.patchV2(url, data)
}
