import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { calculateVerificationDigit } from 'utils/company'
import { generateBasicNumberParser } from 'utils/format'

const CompanyIdentificationNumberField = ({
  disabled = false,
  label = 'Número de documento',
}) => {
  const form = useFormikContext()

  const { values } = form

  return values.document_type === 'ni' ? (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 4.4rem',
        alignItems: 'start',
        gridGap: theme.spacing(1),
      })}
    >
      <IdentificationNumberField
        onChange={({ target: { value } }) => {
          const parser = generateBasicNumberParser({
            max: 9,
            convertToNumber: false,
          })

          const parsed = parser(value)

          if (values.id_number !== parsed) {
            form.setValues({
              ...values,
              id_number: parsed,
              verification_digit: calculateVerificationDigit(parsed),
            })
          }
        }}
        isCompanyId
        disabled={disabled}
        label={label}
        optional={false}
      />
      <FormField
        name="verification_digit"
        label="DV"
        placeholder="DV"
        disabled
      />
    </Box>
  ) : (
    <IdentificationNumberField isCompanyId disabled={disabled} label={label} />
  )
}

export default CompanyIdentificationNumberField
