import { Typography, useMediaQuery } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import CardGrid from '../common/CardGrid'
import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import HeaderRow from '../common/CardGrid/HeaderRow'
import MobileCell from '../common/CardGrid/MobileCell'

const WorkedHours = () => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <CardGrid gridColumns="1fr 1fr">
      <HeaderRow>
        <HeaderCell
          sx={{
            gridColumn: 1,
          }}
          desktopOnly
        >
          Tipo
        </HeaderCell>
        <HeaderCell
          sx={{
            gridColumn: 2,
          }}
          desktopOnly
        >
          # de horas trabajadas
        </HeaderCell>
      </HeaderRow>
      <GridRow>
        <HeaderCell
          sx={{
            gridColumn: 1,
            gridRow: 1,
          }}
          mobileOnly
        >
          Tipo
        </HeaderCell>
        <HeaderCell
          sx={{
            gridColumn: 1,
            gridRow: 2,
          }}
          mobileOnly
          alignY="center"
        >
          # de horas trabajadas
        </HeaderCell>
        <MobileCell
          alignX={isTabletUp ? 'left' : 'right'}
          sx={(theme) => ({
            gridColumn: '2 / -1',
            [theme.breakpoints.up('tablet')]: {
              gridColumn: 1,
            },
          })}
        >
          <Typography variant="h6" color="black.dark">
            Horas trabajadas en el periodo
          </Typography>
        </MobileCell>
        <MobileCell
          alignX={isTabletUp ? 'left' : 'right'}
          alignY="center"
          sx={(theme) => ({
            gridColumn: '2 / -1',
            [theme.breakpoints.up('tablet')]: {
              gridColumn: 2,
            },
          })}
        >
          <FormField
            sx={{ width: '12rem' }}
            name="worked_time"
            type="number"
            fullWidth={false}
          />
        </MobileCell>
      </GridRow>
    </CardGrid>
  )
}

export default WorkedHours
