import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useNotifications from 'utils/hooks/useNotifications'

import { ORGANIZER_AFFILIATIONS } from 'config/routes'

import ApprovalContent from './ApprovalContent'
import RejectionContent from './RejectionContent'
import {
  areRequiredDocumentsCompleted,
  getAffiliationEntities,
  getDescription,
  getEntitiesWithMissingCredentialsKey,
  getInitialValues,
  getValidationSchema,
  hasErrorsInFilledValues,
} from './helpers'

const AffiliationActionsModalContent = ({ action, workerId }) => {
  const [actionSubmitForm, setActionSubmitForm] = useState('')
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const modals = useModals()
  const { showSuccessMessage } = useNotifications()
  const isRejectAction = action === 'rejected'
  const affiliationQueryKey = ['getAffiliationById', workerId]
  const {
    data: { beneficiaries, certificates_data: certificatesData, status } = {},
  } = queryClient.getQueryData(affiliationQueryKey) || {}
  const { affiliationsMutation } = useAffiliationsService({
    queryOptions: {
      enabled: false,
    },
  })

  const allRequiredDocumentsCompleted =
    areRequiredDocumentsCompleted(certificatesData)

  const onSubmit = (values) => {
    const affiliationData = new FormData()

    if (isRejectAction) {
      if (values.rejection_reason === 'novelty') {
        affiliationData.append('status', 'occurrence')
        affiliationData.append('occurrence_reason', values.novelty_type)
      } else {
        affiliationData.append('status', 'rejected')
        affiliationData.append('rejected_reason', values.rejection_reason)

        if (
          [
            'error_in_company_documents',
            'error_in_worker_documents',
            'error_in_beneficiary_documents',
          ].includes(values.rejection_reason)
        ) {
          const invalidDocumentsData = {
            documents: values.invalid_documents,
          }

          if (values.rejection_reason === 'error_in_beneficiary_documents') {
            invalidDocumentsData.id = values.beneficiary
          }

          affiliationData.append(
            'invalid_documents',
            JSON.stringify(invalidDocumentsData)
          )
        }
      }

      if (values.rejection_reason === 'error_in_credentials') {
        affiliationData.append(
          'worker_entities_to_affiliate',
          JSON.stringify(
            getEntitiesWithMissingCredentialsKey({
              entitiesWithErrors: values.invalid_credentials,
              providersArray: certificatesData,
            })
          )
        )
      }

      if (values.comment && values.comment.length > 0) {
        affiliationData.append('rejected_comment', values.comment)
      }
    } else {
      if (actionSubmitForm === 'completeAffiliation') {
        affiliationData.append('status', 'approved')
      }

      if (values.approved_compensation_fund_document instanceof File) {
        affiliationData.append(
          'approved_compensation_fund_document',
          values.approved_compensation_fund_document
        )
      }

      if (values.approved_health_document instanceof File) {
        affiliationData.append(
          'approved_health_document',
          values.approved_health_document
        )
      }

      if (values.approved_pension_document instanceof File) {
        affiliationData.append(
          'approved_pension_document',
          values.approved_pension_document
        )
      }

      if (values.approved_risk_document instanceof File) {
        affiliationData.append(
          'approved_risk_document',
          values.approved_risk_document
        )
      }
    }

    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData,
      },
      {
        onSuccess: async () => {
          if (actionSubmitForm === 'uploadFiles') {
            showSuccessMessage('La certificación ha sido cargada')
          } else {
            showSuccessMessage(
              `La afiliación ha sido ${
                isRejectAction ? 'rechazada' : 'aprobada'
              }`
            )
          }
          modals.closeAll()
          await queryClient.invalidateQueries(['getAllAffiliations'], {
            exact: false,
          })
          navigate(ORGANIZER_AFFILIATIONS())
        },
      }
    )
  }

  const description = getDescription(status)

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(isRejectAction)}
      initialValues={getInitialValues(certificatesData, isRejectAction)}
      validateOnChange
      validateOnBlur
    >
      {({ dirty, errors, values }) => {
        const disableCompleteAffiliationButton =
          Object.keys(errors || {}).length > 0

        const disableUploadDocumenstButton = hasErrorsInFilledValues({
          errors,
          values,
        })

        return (
          <Form>
            <Typography
              variant="body1"
              color="black.dark"
              sx={(theme) => ({
                color: theme.palette.black.dark,
                marginBottom: theme.spacing(3),
              })}
            >
              {description[action]}
            </Typography>
            {action === 'rejected' ? (
              <RejectionContent
                beneficiaries={beneficiaries}
                affiliationEntities={getAffiliationEntities(certificatesData)}
                affiliationStatus={status}
              />
            ) : null}
            {action === 'approved' ? (
              <ApprovalContent certificatesData={certificatesData} />
            ) : null}
            <Box
              sx={(theme) => ({
                justifyContent: 'flex-start',
                display: 'flex',
                gap: theme.spacing(2),
                marginTop: theme.spacing(6),
                [theme.breakpoints.down('tablet')]: {
                  flexDirection: 'column',
                },
              })}
            >
              {!isRejectAction ? (
                <>
                  {allRequiredDocumentsCompleted ? (
                    <>
                      <Button
                        type="submit"
                        disabled={disableCompleteAffiliationButton}
                        loading={affiliationsMutation.isLoading}
                        onClick={() =>
                          setActionSubmitForm('completeAffiliation')
                        }
                      >
                        Completar Afiliación
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => modals.closeAll()}
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        loading={affiliationsMutation.isLoading}
                        disabled={!dirty || disableUploadDocumenstButton}
                        onClick={() => setActionSubmitForm('uploadFiles')}
                      >
                        Enviar información parcial
                      </Button>
                      <Button
                        type="submit"
                        variant="outlined"
                        disabled={disableCompleteAffiliationButton}
                        loading={affiliationsMutation.isLoading}
                        onClick={() =>
                          setActionSubmitForm('completeAffiliation')
                        }
                      >
                        Completar Afiliación
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    loading={affiliationsMutation.isLoading}
                  >
                    Enviar información
                  </Button>
                  <Button variant="outlined" onClick={() => modals.closeAll()}>
                    Cancelar
                  </Button>
                </>
              )}
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AffiliationActionsModalContent
