import DataGrid, { textEditor } from 'react-data-grid'
import 'react-data-grid/lib/styles.css'

import { validateIfDateValuesChange } from 'components/Period/Payroll/Modals/BulkNoveltiesErrorsModal/helpers'

import './styles.css'

export const removeEmptyRow = (rows) => rows?.filter((row) => row.name !== '')

export const getTextEditor = (cellParams) => textEditor(cellParams)

const updateCells = (initialRows, columns) => {
  const initialRowValues = removeEmptyRow(initialRows) || []

  columns.forEach((column) => {
    column.cellClass = (currentRow) => {
      if (column.key === 'name') {
        return undefined
      }

      const rowFound = initialRowValues.find(
        (initialRow) => currentRow.worker_id === initialRow.worker_id
      )

      if (validateIfDateValuesChange(column.key, rowFound, currentRow)) {
        return undefined
      }

      if (
        currentRow[column.key] !== undefined &&
        rowFound[column.key] === currentRow[column.key]
      ) {
        return 'error-cell'
      }

      return undefined
    }
  })

  return columns
}

const Spreadsheet = ({ columns, rows, ...props }) => {
  return (
    <DataGrid
      columns={updateCells(props.initialRows, columns)}
      rows={rows}
      headerRowHeight={50}
      className="rdg-light custom-scrollbar"
      defaultColumnOptions={{
        minWidth: 180,
        resizable: true,
      }}
      {...props}
    />
  )
}

export default Spreadsheet
