import { Box, useMediaQuery } from '@mui/material'

import aleluyaPositive from 'assets/images/logos/aleluya_positive.png'
import arusLogoDark from 'assets/images/logos/arus/logo_arus_dark.png'
import arusLogoBlanco from 'assets/images/logos/arus/logo_arus_white.svg'

import { useUser } from './UserContext/useUser'

const MonogramLogo = () => {
  const { distributor } = useUser()

  const betweenLaptopAndDesktop = useMediaQuery((theme) =>
    theme.breakpoints.between('laptop', 'desktop')
  )

  const isMobileBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down('laptop')
  )

  let imageProps = {}

  if (isMobileBreakpoint) {
    imageProps = {
      alt: 'Aleluya logo',
      src: aleluyaPositive,
      width: '8.75rem',
    }

    if (distributor === 'arus') {
      imageProps.alt = 'Arus logo'
      imageProps.src = arusLogoBlanco
      imageProps.width = '10rem'
      imageProps.height = '2.25rem'
    }
  } else {
    imageProps = {
      alt: 'Aleluya logo',
      src: aleluyaPositive,
      width: '8.75rem',
    }

    if (distributor === 'arus') {
      imageProps.alt = 'Arus logo'
      imageProps.src = arusLogoDark
      imageProps.width = betweenLaptopAndDesktop ? '12.5rem' : '13.5rem'
      imageProps.height = betweenLaptopAndDesktop ? '2.625rem' : '2.875rem'
    }
  }

  return (
    <Box
      component="img"
      src={imageProps.src}
      alt={imageProps.alt}
      loading="lazy"
      sx={{ width: imageProps.width, height: imageProps.height }}
    />
  )
}

export default MonogramLogo
