import { useState } from 'react'

const useSingleEmittedRejectedModal = () => {
  const [state, setState] = useState({
    open: false,
    errors: {},
  })

  const openSingleEmittedRejectedModal = (errors) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      errors,
    }))
  }

  const closeSingleEmittedRejectedModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    openSingleEmittedRejectedModal,
    closeSingleEmittedRejectedModal,
    singleEmittedRejectedModalState: state,
  }
}

export default useSingleEmittedRejectedModal
