import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import { isAuthenticated } from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import { EMAIL_SUPPORT } from 'config/organization'
import {
  DASHBOARD,
  ORGANIZER_COMPANY_INDEX,
  WORKER_PROFILE_SHOW,
} from 'config/routes'

const ErrorPageAtom = ({
  title,
  description,
  image,
  details = null,
  handleResetError = () => {},
  sx,
}) => {
  const isAuthenticatedUser = isAuthenticated()
  const navigate = useNavigate()
  const userRole = localStorage.getItem('role')

  const handleReloadPage = () => {
    window.location.reload()
  }

  const handleGoBack = () => {
    if (userRole === 'worker') return navigate(WORKER_PROFILE_SHOW())

    if (userRole === 'organizer' && !hasSelectedCompany())
      return navigate(ORGANIZER_COMPANY_INDEX())

    return navigate(DASHBOARD)
  }

  const getGoBackText = () => {
    if (userRole === 'worker') return 'Regresar a mi perfil'

    if (userRole === 'organizer' && !hasSelectedCompany())
      return 'Regresar a empresas'

    return 'Regresar a la torre de control'
  }

  return (
    <Box
      sx={[
        (theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          textAlign: 'center',
          padding: theme.spacing(4),
        }),
        sx,
      ]}
    >
      {image}
      <Typography
        variant="h2"
        sx={(theme) => ({
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(2),
        })}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
      >
        {description}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(3),
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            width: 'fit-content',
          },
        })}
      >
        {isAuthenticatedUser ? (
          <Button
            onClick={() => {
              handleGoBack()
              handleResetError()
            }}
          >
            {getGoBackText()}
          </Button>
        ) : (
          <Button onClick={handleReloadPage}>Recargar la página</Button>
        )}
        <Button
          variant="outlined"
          component="a"
          href={`mailto:${EMAIL_SUPPORT}`}
        >
          Contactar a soporte
        </Button>
      </Box>
      {details ? (
        <Box
          component="pre"
          sx={(theme) => ({
            marginTop: theme.spacing(4),
            backgroundColor: theme.palette.white.main,
            width: '100%',
            padding: theme.spacing(2),
            fontSize: '1.2rem',
            wordBreak: 'break-word',
            textAlign: 'left',
            overflow: 'auto',
          })}
        >
          {details}
        </Box>
      ) : null}
    </Box>
  )
}

export default ErrorPageAtom
