import { Switch } from '@mui/material'

const SwitchField = ({ id, name, value, ...props } = {}) => {
  const { setValue, ...restProps } = props

  const onChange = () => {
    setValue(!value)
  }

  return (
    <Switch
      id={id}
      name={name}
      checked={value}
      value={value}
      onChange={onChange}
      {...restProps}
    />
  )
}

export default SwitchField
