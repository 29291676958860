import { Dialog, Popover } from '@mui/material'

import { useDateRangePickerContext } from '.'

const CalendarContainer = ({
  children,
  open,
  onClose,
  anchorEl,
  dialogProps = {},
}) => {
  const { isDesktop } = useDateRangePickerContext()

  return (
    <>
      {isDesktop ? (
        <Popover
          {...dialogProps}
          open={open}
          onClose={onClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {children}
        </Popover>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            elevation: 0,
            sx: {
              margin: 'auto',
            },
          }}
        >
          {children}
        </Dialog>
      )}
    </>
  )
}

export default CalendarContainer
