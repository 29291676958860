import { useCallback, useState } from 'react'

const useInactivePeriodEditingModal = () => {
  const [state, setState] = useState({
    open: false,
  })

  const openInactivePeriodEditingModal = useCallback(() => {
    setState({ open: true })
  }, [])

  const closeInactivePeriodEditingModal = () => {
    setState({ open: false })
  }

  return {
    inactivePeriodEditingModalState: state,
    openInactivePeriodEditingModal,
    closeInactivePeriodEditingModal,
  }
}

export default useInactivePeriodEditingModal
