import Modal from 'components/UI/Modal/Modal'

import PeriodEditingInactiveModal from './PeriodEditingInactiveModal'

export default function InactivePeriodEditingModal({ state, handleClose }) {
  return (
    <Modal
      open={state.open}
      header="¡PILAS! La edición de periodos históricos está inactiva"
      onCancel={handleClose}
      okText="Guardar"
      hideFooter
      adornment={[
        {
          variant: 'signature',
          color: 'accent2',
          width: 155,
          height: 171,
          sx: { bottom: '1.19rem', right: '2rem' },
        },
      ]}
      paperSx={{
        maxWidth: {
          tablet: '45rem',
          laptop: '48.5rem',
        },
      }}
    >
      <PeriodEditingInactiveModal handleClose={handleClose} />
    </Modal>
  )
}
