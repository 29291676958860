import {
  differenceInDays,
  endOfMonth,
  format,
  formatISO,
  fromUnixTime,
  isValid,
  parseISO,
} from 'date-fns'
import esLocale from 'date-fns/locale/es/index.js'

// Map instead of object to prevent default ordering
export const weekdays = new Map([
  ['1', 'Lunes'],
  ['2', 'Martes'],
  ['3', 'Miércoles'],
  ['4', 'Jueves'],
  ['5', 'Viernes'],
  ['6', 'Sábado'],
  ['0', 'Domingo'],
])

export const weekdaysOptions = Array.from(weekdays).map(([value, label]) => ({
  value,
  label,
}))

export const monthShortName = {
  1: 'Ene',
  2: 'Feb',
  3: 'Mar',
  4: 'Abr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dic',
}

export const monthName = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
}

export function getDaysOfWeekFromArray(arrayOfDays) {
  return arrayOfDays ? arrayOfDays.map((d) => weekdays.get(d)).join(', ') : null
}

/**
 * Return an array of the dates within the specified time interval.
 * @param {Date} start
 * @param {Date} end
 * @returns {Array}
 */
export function eachDayOfInterval(start, end) {
  const arr = []
  const dt = new Date(start)

  while (dt <= end) {
    arr.push(new Date(dt))
    dt.setDate(dt.getDate() + 1)
  }

  return arr
}

/**
 * Format a date to  the default API date string format.
 *
 * Faster than moment and date-fns for this simple format.
 * @param {Date} date
 * @returns Date string formatted ('yyyy-mm-dd')
 */
export function formatDate(date) {
  return date && date instanceof Date
    ? formatISO(date, { representation: 'date' })
    : date
}

/**
 *  Converts 'yyyy-mm-dd' to 'dd/MM/yyyy'
 *
 * Example: '2019-10-09' => '09/Oct/2019'
 *
 * @param {String} dateString Date string to format
 * @returns {String}
 */
export function formatDisplayDateString(dateString) {
  if (!dateString) return null
  const arr = dateString.split('-')

  return `${arr[2]}/${monthShortName[parseInt(arr[1], 10)]}/${arr[0]}`
}

/**
 *  Converts 'yyyy-mm-dd' to 'dd/mm/yyyy'
 *
 * Example: '2019-10-09' => '09/10/2019'
 *
 * @param {String} dateString Date string to format
 * @returns {String}
 */
export function formatNumberDisplayDate(dateString) {
  if (!dateString) return null
  const arr = dateString.split('-')

  return `${arr[2]}/${arr[1]}/${arr[0]}`
}

/**
 *  Converts 'yyyy/mm/dd' to 'dd/mm/yyyy'
 *
 * Example: '2023/12/24' => '24/12/2023'
 *
 * @param {String} dateString Date string to format
 * @returns {String}
 */
export function reorderNumberDisplayDate(dateString) {
  if (!dateString) return null
  const arr = dateString.split('/')

  return `${arr[2]}/${arr[1]}/${arr[0]}`
}

/**
 *  Converts a Date object to 'dd/MM/yyyy'
 *
 * Example: '2019-10-09' => '09/Oct/2019'
 *
 * @param {Date} date Date to format
 * @returns {String} Date string formatted
 */
export function formatDisplayDate(date) {
  const dateParse = typeof date === 'string' ? parseISO(date) : date

  if (!isValid(dateParse)) return null

  let day = `${dateParse.getDate()}`
  const month = dateParse.getMonth() + 1
  const year = dateParse.getFullYear()

  if (day.length < 2) day = `0${day}`

  return `${day}/${monthShortName[month]}/${year}`
}

/**
 *  Converts a Date object to 'dd/MMMM/yyyy'
 *
 * Example: '2019-10-09' => '09/octubre/2019'
 *
 * @param {Date} date Date to format
 * @returns {String} Date string formatted
 */
export function formatLongDisplayDate(date) {
  const dateParse = typeof date === 'string' ? parseISO(date) : date

  if (!isValid(dateParse)) return null

  return format(dateParse, 'dd/MMMM/yyyy', { locale: esLocale })
}

/**
 * Convert date strings representing a range to 'dd1 - dd2/MM/yyyy'
 *
 * Example: '2019-10-01' and '2019-10-31' => '01 - 09/Oct/2019'
 *
 * @param {String} startDate
 * @param {String} endDate
 * @returns {String}
 */
export function formatDateRange(start, end) {
  const startDate = typeof start === 'string' ? parseISO(start) : start
  const endDate = typeof end === 'string' ? parseISO(end) : end

  const initDay = startDate?.getDate()

  return `${initDay}-${formatDisplayDate(endDate)}`
}

/**
 * Used by PeriodRangeInfo.jsx
 */
export function formatPeriodDateRange(start, end) {
  const startDate = typeof start === 'string' ? parseISO(start) : start
  const endDate = typeof end === 'string' ? parseISO(end) : end

  if (!isValid(startDate) || !isValid(endDate)) {
    return null
  }

  const initDay = startDate?.getDate()

  return `${initDay}-${format(endDate, 'dd MMMM yyyy', { locale: esLocale })}`
}

/**
 * Convert date strings with complete information 'yyyy-mm-dd hh:mm:ss'
 * Generally created-at date in api
 *
 * @param {Date} date Date to format
 * @returns {String} Date string formatted
 */
export function formatCompleteDate(dateString) {
  if (!dateString) return null
  const arr = dateString.split(' ')

  return `${formatDisplayDateString(arr[0])} - ${arr[1]}`
}

/**
 * Convert UNIX Timestamp to date string for data
 * Generally created_at in company show
 *
 * @param {string | number} unixTime UNIX Timestamp to format
 * @returns {string} Date string formatted ('dd/mm/yyyy')
 */
export function formatUnixDate(unixTime) {
  if (!unixTime) return null

  return format(fromUnixTime(unixTime), 'yyyy/MM/dd')
}

export function formatLocateDateString(date) {
  const dateParse = typeof date === 'string' ? parseISO(date) : date

  if (!isValid(dateParse)) return null

  return dateParse.toLocaleDateString('es-CO', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

export function getColombianCurrentDate() {
  const localDate = -(new Date().getTimezoneOffset() / 60)
  const destOffset = -5
  const offset = destOffset - localDate
  const date = new Date(new Date().getTime() + offset * 3600 * 1000)
  return date
}

/**
 * Verify if is Christmas season
 */
export function isChristmasTime() {
  // Months starts in 0
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth()

  return month === 11 || (month === 0 && day <= 6)
}

/**
 *
 * @param {Date} date First day of the last period
 * @param {string} length Payment frequency
 * @returns First and last day of the immediately preceding period
 */
export function getPreviousPeriod(date, length) {
  const currentDate = typeof date === 'string' ? parseISO(date) : date
  if (!isValid(currentDate)) return null

  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()
  const previousDate = new Date(year, month - 2, 1)
  const previousYear = previousDate.getFullYear()
  const previousDays = endOfMonth(previousDate).getDate()

  if (length === 'fortnightly') {
    return {
      start: `1-${monthShortName[month - 1 || 12]}-${previousYear} al 15-${
        monthShortName[month - 1 || 12]
      }-${previousYear}`,
      end: `16-${
        monthShortName[month - 1 || 12]
      }-${previousYear} al ${previousDays}-${
        monthShortName[month - 1 || 12]
      }-${previousYear}`,
    }
  }

  return {
    start: `1-${monthShortName[month - 1 || 12]}-${previousYear}`,
    end: `${previousDays}-${monthShortName[month - 1 || 12]}-${previousYear}`,
  }
}

/**
 * Get days of difference between current date and the given date
 */
export function getDiferenceOfDays(date) {
  if (date) {
    const currentDate = getColombianCurrentDate()
    const dateEnd = new Date(`${date.replace(/-/g, ', ')}`)

    const differenceOfDays = differenceInDays(dateEnd, currentDate)

    let remainingDays
    // For get the days include de actual day
    if (differenceOfDays >= 0) {
      remainingDays = differenceOfDays + 1
    }

    if (remainingDays === undefined || remainingDays === null) return 0

    return remainingDays
  }

  return 0
}
/**
 * Get month and year the given date
 */
export function getMonthAndYear(date) {
  const dateParse = typeof date === 'string' ? parseISO(date) : date

  if (!isValid(dateParse)) return null

  return dateParse.toLocaleDateString('es-CO', {
    month: 'long',
    year: 'numeric',
  })
}

/**
 * Add days to the given date.
 */
export function addDays(date, days) {
  const dateParse = typeof date === 'string' ? parseISO(date) : date

  if (!isValid(dateParse)) return null

  const endDate = dateParse.setDate(dateParse.getDate() + days)
  return endDate
}

/**
 * Get end month date for the given date
 */
export function getEndDate(date) {
  const dateParse = typeof date === 'string' ? parseISO(date) : date

  if (!isValid(dateParse)) return null

  return endOfMonth(dateParse)
}

export function checkExpireDays(date) {
  if (!isValid(date)) return null

  const now = Date.now()
  return date - now <= 0
}

/**
 * Get a list with the days of the month ready to be used in the Autocomplete field
 */
export const getMonthDays = (days = 31) => {
  return new Array(days).fill(0).map((_, i) => ({
    id: `${i <= 8 ? 0 : ''}${i + 1}`,
    name: `${i + 1}`,
  }))
}

/**
 * Get a list with the months of the year ready to be used in the Autocomplete field
 */
export const getMonths = () =>
  Object.entries(monthShortName).map(([key, value]) => ({
    id: `${key <= 9 ? 0 : ''}${key}`,
    name: value,
  }))

/**
 * Get a list with fullnamed months of the year ready to be used in the Autocomplete field
 */
export const getMonthsFullName = () =>
  Object.entries(monthName).map(([key, value]) => ({
    id: `${key <= 9 ? 0 : ''}${key}`,
    name: value,
  }))

/**
 *
 * @param {Number} Number of years to return
 * @returns Array of years
 */
export const generateArrayOfYears = (yearsToReturn) => {
  const currentDate = new Date().getFullYear()
  const minimumDate = currentDate - yearsToReturn
  const years = []

  for (let i = currentDate; i >= minimumDate; i -= 1) {
    years.push({ id: `${i}`, name: `${i}` })
  }

  return years
}

/**
 * Get a readable and contextual date from a timestamp to be used in notification messages
 *
 * @param {string | number} timestamp UNIX Timestamp to convert.
 * @returns {string} The date in a readable format in spanish. For instance: "Hoy a las 3:30 pm" o "Ayer a las 12:45 am".
 */
export const timestampToReadableDate = (timestamp) => {
  const date = new Date(timestamp) // Create date object from timestamp

  // Obtener componentes de la fecha
  const month = date.toLocaleString('es', { month: 'long' }) // Get month name
  const day = date.getDate()

  const now = new Date() // Get current date

  // Check if the day is today
  if (date.toDateString() === now.toDateString()) {
    let hours = date.getHours()
    const minutes = `0${date.getMinutes()}`.slice(-2)
    const period = hours >= 12 ? 'pm' : 'am'

    // Set 12 hour format
    if (hours > 12) {
      hours -= 12
    } else if (hours === 0) {
      hours = 12
    }

    return `Hoy a las ${hours}:${minutes} ${period}`
  }

  // Check if the day was yesterday
  const yesterday = new Date(now)
  yesterday.setDate(now.getDate() - 1)

  if (date.toDateString() === yesterday.toDateString()) {
    let hours = date.getHours()
    const minutes = `0${date.getMinutes()}`.slice(-2)
    const period = hours >= 12 ? 'pm' : 'am'

    // Set 12 hour format
    if (hours > 12) {
      hours -= 12
    } else if (hours === 0) {
      hours = 12
    }

    return `Ayer a las ${hours}:${minutes} ${period}`
  }

  // Format for any other day
  const dayOfWeek = date.toLocaleString('es', { weekday: 'long' })
  let hours = `0${date.getHours()}`.slice(-2)
  const minutes = `0${date.getMinutes()}`.slice(-2)
  const period = hours >= 12 ? 'pm' : 'am'

  // Set 12 hour format
  if (hours > 12) {
    hours -= 12
  } else if (hours === 0) {
    hours = 12
  }

  const capitalizedDay = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)

  return `${capitalizedDay} ${day} de ${month}, a las ${hours}:${minutes} ${period}`
}
