import { Box, Button, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import ErrorInfoCard from './ErrorInfoCard'
import NoveltyCard from './NoveltyCard'
import { getModalText, getNoveltiesData } from './helpers'

const BulkNoveltiesSummaryModal = ({
  state,
  handleClose,
  openBulkNoveltiesErrorsSummaryModal,
  openBulkNoveltiesErrorsModal,
  openBulkNoveltiesCommonInfoModal,
}) => {
  const { open, bulkUploadResult } = state || {}
  const { success } = bulkUploadResult || {}

  const {
    file_with_errors: fileWithErrors,
    failure_cases_total_count: totalFailureCases,
    success_workers_count: successWorkersCount,
  } = success || {}

  const noveltiesCards = getNoveltiesData(bulkUploadResult)

  const handleOpenErrorsSummaryModal = () => {
    handleClose()
    openBulkNoveltiesErrorsSummaryModal(bulkUploadResult)
  }

  const handleOpenErrorsModal = () => {
    handleClose()
    openBulkNoveltiesErrorsModal(bulkUploadResult)
  }

  const handleConfirmClose = () => {
    handleClose()

    if (fileWithErrors)
      openBulkNoveltiesCommonInfoModal('confirm_close_errors', {
        to: 'BulkNoveltiesSummaryModal',
        bulkUploadResult,
      })
  }

  const modalText = getModalText(
    successWorkersCount,
    fileWithErrors,
    totalFailureCases
  )

  return (
    <Modal
      open={open}
      onCancel={handleConfirmClose}
      cancelText="Cerrar"
      header={<Typography variant="h3">{modalText.header}</Typography>}
      paperSx={{ maxWidth: '50rem' }}
      adornment="spring"
      hideFooter
    >
      <Typography variant="body1" color="black.dark">
        {modalText.body}
      </Typography>
      {fileWithErrors ? (
        <ErrorInfoCard
          quantity={totalFailureCases}
          handleOpenErrorsModal={handleOpenErrorsModal}
        />
      ) : null}
      {successWorkersCount > 0 ? (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            borderRadius: '1rem',
            marginTop: theme.spacing(3),
            gap: theme.spacing(2),
            [theme.breakpoints.down('tablet')]: {
              gridTemplateColumns: '1fr',
            },
          })}
        >
          {noveltiesCards.map((noveltyCard) => (
            <NoveltyCard noveltyCard={noveltyCard} key={noveltyCard.id} />
          ))}
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(5),
          display: 'flex',
          gap: theme.spacing(4),
          [theme.breakpoints.down('tablet')]: {
            justifyContent: 'center',
          },
        })}
      >
        <Button variant="outlined" onClick={handleConfirmClose}>
          Cerrar
        </Button>
        {fileWithErrors ? (
          <Button variant="text" onClick={handleOpenErrorsSummaryModal}>
            {totalFailureCases === 1
              ? 'Ver detalle del error'
              : 'Ver detalle de errores'}
          </Button>
        ) : null}
      </Box>
    </Modal>
  )
}

export default BulkNoveltiesSummaryModal
