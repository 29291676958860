import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Link as MuiLink } from '@mui/material'

const Link = forwardRef(function LinkForwardRef({ children, ...props }, ref) {
  return (
    <MuiLink component={RouterLink} ref={ref} underline="hover" {...props}>
      {children}
    </MuiLink>
  )
})

export default Link
