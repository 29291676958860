import http, { getApiURL } from '../httpService'

export const getFreetimeRequests = ({ filter, search, page } = {}) => {
  const url = getApiURL({
    pathname: '/novelty_requests',
    searchParams: { page, search, filter },
  })

  return http.getV2(url)
}

export const updateFreetimeRequest = (noveltyId, noveltyRequest) => {
  const url = getApiURL({
    pathname: `/novelty_requests/${noveltyId}`,
  })

  return http.putV2(url, noveltyRequest)
}

export const deleteFreetimeRequest = (noveltyId) => {
  const url = getApiURL({
    pathname: `/novelty_requests/${noveltyId}`,
  })

  return http.deleteV2(url)
}

export default {
  getFreetimeRequests,
  updateFreetimeRequest,
  deleteFreetimeRequest,
}
