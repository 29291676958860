import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import ContractCategoryField from 'components/Worker/Contract/Fields/Category'
import ContributorSubtype from 'components/Worker/Contract/Fields/ContributorSubtype'
import EndDayField from 'components/Worker/Contract/Fields/EndDay'
import HighRiskPensionField from 'components/Worker/Contract/Fields/HighRiskPension'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import RiskTypeField from 'components/Worker/Contract/Fields/RiskType'
import TermField from 'components/Worker/Contract/Fields/Term'
import AreaField from 'components/Worker/Form/Fields/Personal/Area/Area'
import CostCenterField from 'components/Worker/Form/Fields/Personal/CostCenter/CostCenter'
import LocationField from 'components/Worker/Form/Fields/Personal/Location/Location'
import PositionField from 'components/Worker/Form/Fields/Personal/Position/Position'
import BaseSalaryField from 'components/Worker/Wage/Fields/BaseSalary'
import WageCategoryField from 'components/Worker/Wage/Fields/Category'
import TransportSubsidyField from 'components/Worker/Wage/Fields/TransportSubsidy'

import { weekdaysOptions } from 'utils/dateTime'

import messages from 'messages/worker'

const FormFieldsLabor = ({ isCreatingWorker = false }) => {
  const { values } = useFormikContext()

  return (
    <Box>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        })}
      >
        Información laboral
      </Typography>
      <Box sx={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr' }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(2),
            columnGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            },
          })}
        >
          <ContractCategoryField name="contract_category" optional={false} />
          <TermField
            contractCategory={values.contract_category}
            optional={false}
          />
          <InitialDayField optional={false} />
          <EndDayField optional={false} />
          <WageCategoryField
            name="wage_category"
            label="Salario base"
            contractCategory={values.contract_category}
            optional={false}
          />
          <BaseSalaryField
            isEditing={false}
            contractCategory={values.contract_category}
            optional={false}
          />
          <TransportSubsidyField
            contractCategory={values.contract_category}
            isCreatingWorker={isCreatingWorker}
          />
          <ContributorSubtype
            contractCategory={values.contract_category}
            optional={false}
          />
          <RiskTypeField
            contractCategory={values.contract_category}
            optional={false}
          />
          <HighRiskPensionField />
        </Box>
      </Box>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        })}
      >
        Datos del puesto de trabajo
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(2),
          columnGap: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <PositionField optional={false} />
        <LocationField optional={false} />
        <AreaField optional={false} />
        <CostCenterField optional={false} />
      </Box>
      {values.contract_category &&
      ['employee', 'student_law_789', 'student_decree_055'].includes(
        values.contract_category
      ) ? (
        <>
          <Typography
            variant="h6"
            color="primary.dark"
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(2),
            })}
          >
            Días de descanso
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              columnGap: theme.spacing(3),
            })}
          >
            <FormField
              name="rest_days"
              variant="checkbox-group"
              options={weekdaysOptions}
              row
            />
            <FormField
              name="enjoyed_days_since_hired"
              label="¿Cuántos días de vacaciones ha disfrutado desde que ingresó?"
              type="number"
              align="left"
              tooltipContent={messages.accumulated_holidays}
            />
          </Box>
        </>
      ) : null}
    </Box>
  )
}

export default FormFieldsLabor
