import { nanoid } from 'nanoid'
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import Modal from 'components/UI/Modal/Modal'

export const ModalsContext = createContext({
  modals: [],
  openModal: () => null,
  closeAll: () => {},
  closeModal: () => {},
  loadingModal: false,
  setLoadingModal: () => {},
})

const ModalsProvider = ({ children }) => {
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({
    id: null,
    props: null,
    type: 'content',
  })
  const [loadingModal, setLoadingModal] = useState(false)
  const modalsRef = useRef(modals)

  useEffect(() => {
    modalsRef.current = modals
  }, [modals])

  const closeAll = () => setModals([])

  const openModal = (props) => {
    const id = props.id || nanoid()
    setModals((previousModals) => [
      { id, props, type: 'content' },
      ...previousModals,
    ])
    setCurrentModal({ id, props, type: 'content' })
    return id
  }

  const closeModal = useCallback((id) => {
    if (modalsRef.current.length <= 1) {
      closeAll()
    } else {
      const modalIndex = modalsRef.current.findIndex((modal) => modal.id === id)
      if (modalIndex !== -1) {
        const newModals = [...modalsRef.current]
        newModals.splice(modalIndex, 1)
        setModals(newModals)
      }
      setCurrentModal(modalsRef.current[modalsRef.current.length - 1])
    }
  }, [])

  const context = useMemo(
    () => ({
      modals,
      closeAll,
      openModal,
      closeModal,
      setLoadingModal,
      loadingModal,
    }),
    [closeModal, loadingModal, modals]
  )

  const content = currentModal?.props?.content
  const modalProps = currentModal?.props?.modalProps

  return (
    <ModalsContext.Provider value={context}>
      <Modal
        onCloseModal={() => closeModal(currentModal?.id)}
        onCancel={() => closeModal(currentModal?.id)}
        {...modalProps}
        open={modals.length > 0}
        isLoading={loadingModal}
      >
        {content || <></>}
      </Modal>
      {children}
    </ModalsContext.Provider>
  )
}

export default ModalsProvider
