import http, { getApiURL } from '../httpService'

export const getAllAreas = () => {
  const url = getApiURL({ pathname: '/areas' })

  return http.getV2(url)
}

export const updateArea = (areas) => {
  const url = getApiURL({ pathname: '/areas' })

  const reqData = {
    areas,
  }
  return http.putV2(url, reqData)
}

export default {
  getAllAreas,
  updateArea,
}
