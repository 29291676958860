import { useState } from 'react'

const useAffiliationNoveltyModal = () => {
  const [state, setState] = useState({
    open: false,
    workerId: null,
  })

  const closeAffiliationNoveltyModal = () => {
    setState(() => ({
      open: false,
      workerId: null,
    }))
  }

  const openAffiliationNoveltyModal = (workerId) => {
    setState(() => ({
      open: true,
      workerId,
    }))
  }

  return {
    affiliationNoveltyModalState: state,
    closeAffiliationNoveltyModal,
    openAffiliationNoveltyModal,
  }
}

export default useAffiliationNoveltyModal
