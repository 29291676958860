import { Typography } from '@mui/material'

import CostCenterTable from 'components/Settings/CostCenterTable/DataTable'

import useCostCenterService from 'utils/hooks/company/costCenterService'

const SettingsAccountingCostCenters = () => {
  const { costCenterQuery } = useCostCenterService({
    serviceParams: { queryKey: 'costCenters' },
  })

  return (
    <div data-cy="cost-center-section">
      <Typography variant="h6" gutterBottom>
        Centros de Costos - PUC
      </Typography>
      <Typography>
        Estas serán las cuentas bajo las cuales comenzarán todos los conceptos
        contables que se exporten para cargar al software contable. La cuenta
        contable de cada persona cambiará de acuerdo a su Centro de Costos. Si
        deseas cambiar a una persona de centro de costos, deberás editarlo en
        sus &quot;datos básicos&quot;.
      </Typography>
      <br />
      <CostCenterTable data={costCenterQuery?.data} />
    </div>
  )
}

export default SettingsAccountingCostCenters
