import { Box, Typography } from '@mui/material'

import CitiesList from 'components/UI/Formik/CommonFields/CitiesList'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DepartmentsList from 'components/UI/Formik/CommonFields/DepartmentsList'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'

const CompanyInformation = () => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Información de la empresa
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        Por favor verifica y completa la información de tu empresa. Esta
        información debe ser la misma que tienes registrada en la <b>DIAN</b>.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: theme.spacing(3),
          marginBottom: theme.spacing(4.5),
          [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <FormField name="name" label="Razón social" optional={false} />
        <FormField
          name="government_email"
          label="Correo electrónico del RUT"
          optional={false}
        />
        <DocumentTypeField
          options={documentTypesOptions}
          data-cy="documentType"
          optional={false}
        />
        <CompanyIdentificationNumberField />
      </Box>
      <Typography variant="h5" gutterBottom>
        Datos de ubicación
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: theme.spacing(3),
          marginTop: theme.spacing(2.5),
          [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <DepartmentsList
          name="department"
          label="Departamento"
          optional={false}
        />
        <CitiesList name="city" label="Ciudad" optional={false} />
        <Box
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          <FormField name="address" label="Dirección" optional={false} />
        </Box>
      </Box>
    </div>
  )
}

export default CompanyInformation
