import FormField from 'components/UI/Formik/FormField/Index'

const EditDeductionConcept = ({ rowValues, options }) => {
  return rowValues.category === 'deductions' ? (
    <FormField name="payroll_concept_id" variant="select" options={options} />
  ) : (
    <FormField name="name" />
  )
}

export default EditDeductionConcept
