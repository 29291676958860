import { IconButton as MIconButton } from '@mui/material'

const IconButton = ({ icon, sx, ...props }) => {
  return (
    <MIconButton
      sx={[
        {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        sx,
      ]}
      size="large"
      {...props}
    >
      {icon}
    </MIconButton>
  )
}

export default IconButton
