import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import useNotifications from 'utils/hooks/useNotifications'
import useContractsService from 'utils/hooks/worker/contracts'
import { contributorSubtypeOptions } from 'utils/worker'

import HighRiskPensionField from '../Fields/HighRiskPension'
import {
  getEndDayBoundaryDates,
  getInitialDayBoundaryDates,
  getInitialValues,
  getValidationSchema,
} from './helpers'

const ContractDetailModal = ({ state, handleClose, resetModal = () => {} }) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { contractId } = useParams()
  const { companyWorkCentersQuery } = useCompanyWorkCenters({
    serviceParams: { queryKey: ['getWorkCenters', getCompanyId()] },
    queryOptions: {
      enabled: Boolean(state.open),
    },
  })
  const { contractsMutation } = useContractsService({
    queryOptions: {
      enabled: false,
    },
  })
  const workCenters = (companyWorkCentersQuery.data || [])?.map(
    (workCenter) => ({
      id: workCenter?.id,
      name: workCenter?.name,
      risk_type: workCenter?.activity_code?.risk_type_coded_name,
    })
  )
  const [isLastContractDetail, setIsLastContractDetail] = useState(
    () => state.contractDetail?.id === state.lastContractDetail?.id
  )

  const initialDayBoundary = getInitialDayBoundaryDates({
    lastContractDetail: state.lastContractDetail,
    contractDetail: state.contractDetail,
    previousContractDetail: state.previousContractDetail,
    nextContractDetail: state.nextContractDetail,
    contractInitialDay: state.worker?.initial_day,
    contractEndDay: state.worker?.end_day,
    isEditing: state.isEditing,
    isLastContractDetail,
  })
  const endDayBoundary = getEndDayBoundaryDates({
    lastContractDetail: state.lastContractDetail,
    contractDetail: state.contractDetail,
    previousContractDetail: state.previousContractDetail,
    nextContractDetail: state.nextContractDetail,
    contractInitialDay: state.worker?.initial_day,
    contractEndDay: state.worker?.end_day,
    isEditing: state.isEditing,
    isLastContractDetail,
  })

  const onSubmit = (values) => {
    if (state.isEditing) {
      contractsMutation.mutate(
        {
          mutationMethod: 'PUT',
          contractDetailId: state.contractDetail?.id,
          contractDetail: {
            contributor_subtype: values.contributor_subtype,
            initial_day: values.initial_day,
            end_day: values.end_day,
            work_center_id: values.work_center?.id,
            ...(values.contributor_subtype === 'no_subtype' &&
            values.work_center?.risk_type?.includes('5')
              ? {
                  high_risk_pension: values.high_risk_pension,
                }
              : {}),
          },
        },
        {
          onSuccess: async () => {
            if (state.isFromContractDetailIndex) {
              await queryClient.invalidateQueries([
                'getAllContractDetail',
                state.worker?.id,
              ])
            }
            await queryClient.invalidateQueries([
              'getWorkerById',
              state.worker?.id,
            ])
            showSuccessMessage(
              'El detalle de contrato ha sido actualizado correctamente'
            )
            handleClose()
            resetModal()
          },
        }
      )
    } else {
      contractsMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'createContractDetail',
          contractId: state.contractId || contractId,
          contractDetail: {
            contributor_subtype: values.contributor_subtype,
            initial_day: values.initial_day,
            // A new contract detail always takes contract's end_day as its own end_day
            end_day: state.worker.end_day,
            work_center_id: values.work_center?.id,
            ...(values.contributor_subtype === 'no_subtype' &&
            values.work_center?.risk_type?.includes('5')
              ? {
                  high_risk_pension: values.high_risk_pension,
                }
              : {}),
          },
        },
        {
          onSuccess: async () => {
            if (state.isFromContractDetailIndex) {
              await queryClient.invalidateQueries([
                'getAllContractDetail',
                state.worker?.id,
              ])
            }
            await queryClient.invalidateQueries([
              'getWorkerById',
              state.worker?.id,
            ])
            showSuccessMessage(
              'El detalle de contrato ha sido creado correctamente'
            )
            handleClose()
            resetModal()
            setIsLastContractDetail(false)
          },
        }
      )
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues({
        lastContractDetail: state.lastContractDetail,
        currentContractDetail: state.contractDetail,
        isEditing: state.isEditing,
        isLastContractDetail,
        contractEndDay: state.worker?.end_day,
      })}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(isLastContractDetail)}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <Form>
            <Modal
              open={state.open}
              header={`${
                state.isEditing ? 'Editar' : 'Crear'
              } detalle de contrato`}
              onOk={handleSubmit}
              okText="Guardar y continuar"
              onCancel={handleClose}
              isLoading={contractsMutation.isLoading}
              paperSx={{
                maxWidth: '45.5rem',
                width: '100%',
              }}
              adornment={[
                {
                  variant: 'asterisk',
                  color: 'complementary1.light',
                  width: 136,
                  height: 136,
                  sx: { bottom: '1rem', right: '2rem' },
                },
              ]}
              dialogProps={{
                TransitionProps: {
                  onExited: () => {
                    resetForm()
                    resetModal()
                  },
                },
              }}
            >
              <Typography color="black.dark">
                Podrás crear un nuevo detalle de contrato para la persona que
                comienza a aplicar desde la fecha inicial, con los datos que
                registres.
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(3),
                  marginTop: theme.spacing(4),
                })}
              >
                <FormField
                  name="initial_day"
                  variant="datepicker"
                  label="Fecha desde que aplica"
                  optional={false}
                  minDate={initialDayBoundary.minDate}
                  maxDate={initialDayBoundary.maxDate}
                />
                {state.isEditing &&
                state.contractDetail?.end_day &&
                state.isFromContractDetailIndex ? (
                  <FormField
                    name="end_day"
                    variant="datepicker"
                    label="Fecha final"
                    optional={!(isLastContractDetail && state.worker?.end_day)}
                    minDate={endDayBoundary.minDate}
                    maxDate={endDayBoundary.maxDate}
                  />
                ) : null}
                <FormField
                  name="contributor_subtype"
                  variant="select"
                  label="Subtipo de cotizante"
                  optional={false}
                  options={contributorSubtypeOptions}
                />
                <FormField
                  name="work_center"
                  variant="autocomplete"
                  label="Centro de trabajo"
                  placeholder="Seleccionar centro de trabajo"
                  optional={false}
                  options={workCenters}
                />
                <HighRiskPensionField />
              </Box>
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContractDetailModal
