import { Box, Paper, Typography } from '@mui/material'

import { formatCurrency, formatIdNumber } from 'utils/format'

const TransactionStatuSummary = ({ response }) => {
  const {
    name,
    id_number: idNumber,
    value,
    payment_date: paymentDate,
    date,
    form_key: formKey,
  } = response

  const items = [
    { label: 'Fecha de pago', value: date || paymentDate },
    { label: 'Nombre de la empresa', value: name },
  ]

  if (idNumber) items.push({ label: 'NIT', value: formatIdNumber(idNumber) })
  if (formKey) items.push({ label: 'Clave Planilla', value: formKey })

  if (value)
    items.push({ label: 'Total', value: formatCurrency(parseFloat(value)) })

  return (
    <Paper
      sx={(theme) => ({
        textAlign: 'center',
        justifySelf: 'stretch',
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
      })}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({
          color: theme.palette.black.dark,
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
        })}
      >
        Resumen de tu transacción
      </Typography>

      {items.map(({ label, value: itemValue }, index) => (
        <Box
          key={index.toString()}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            borderTop: `1px solid ${theme.palette.black.light}`,
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.black.dark,
            })}
          >
            {label}
          </Typography>
          <Typography variant="lead1">{itemValue}</Typography>
        </Box>
      ))}
    </Paper>
  )
}

export default TransactionStatuSummary
