import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Card, Link, Typography, tabClasses } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import Dropzone from 'components/UI/Dropzone/Dropzone'
import LordIcon from 'components/UI/LordIcon'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Modal from 'components/UI/Modal/Modal'

import { getUserId } from 'utils/auth'
import useNoveltiesImportService from 'utils/hooks/payroll/noveltiesImport'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { useBulkNoveltiesAlert } from '../BulkNoveltiesAlert/BulkNoveltiesAlertWrapper'
import { usePeriod } from '../helpers'
import { backgroundBulkNoveltiesSocketHandler } from './helpers'

const UploadContent = ({ file, handleAddFile, isSubmitting, setValue }) => {
  return (
    <>
      <Typography variant="h4">Carga tu archivo de novedades</Typography>
      <Typography
        variant="body1"
        color="black.dark"
        sx={(theme) => ({ margin: theme.spacing(1, 0, 3, 0) })}
      >
        Carga tu archivo con todas tus novedades. Si tienes dudas respecto al
        formato del archivo, puedes ir a la pestaña de{' '}
        <b>Descargar plantilla</b> y llenar el nuevo formato.
      </Typography>
      <Dropzone
        value={file && file.name}
        handleAdd={handleAddFile}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        disabled={isSubmitting}
        showLoadedFile
        givenFile={file}
        setValue={setValue}
      />
    </>
  )
}

const DownloadContent = ({ handleDownloadFile, isLoading }) => {
  return (
    <>
      <Typography variant="h4">Descarga la plantilla</Typography>
      <Typography
        variant="body1"
        color="black.dark"
        sx={(theme) => ({ marginTop: theme.spacing(1) })}
      >
        Descarga la nueva plantilla para subir tus novedades y recuerda llenar
        todo lo que vayas a reportar de este periodo para realizar una carga
        efectiva de tus novedades.
      </Typography>
      <Typography
        variant="body1"
        color="black.dark"
        sx={(theme) => ({ margin: theme.spacing(2, 0, 3, 0) })}
      >
        Sigue las recomentaciones y validaciones en el archivo. Además, evita
        copiar valores desde otras fuentes y pegarlas en el documento, pues esto
        podría dañar su lógica y llevar a resultados inesperados. Si tienes
        dudas sobre cómo diligenciar la plantilla, puedes visitar nuestro{' '}
        <Link
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-registrar-las-novedades-de-mis-empleados"
          target="_blank"
          color="accent4.main"
          fontWeight={600}
        >
          Centro de Ayuda
        </Link>
        .
      </Typography>

      <Card
        className="download"
        sx={{
          width: { mobile: '100%', tablet: '22rem' },
          height: '7rem',
          display: 'grid',
          gridTemplateColumns: '5.5rem 17rem',
          borderRadius: '1rem',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            paddingTop: theme.spacing(0.75),
          })}
        >
          <LordIcon name="wired-lineal-documents" target="download" />
        </Box>
        <Box
          sx={(theme) => ({
            padding: theme.spacing(0, 1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: theme.spacing(2),
          })}
        >
          <Typography variant="h6">Carga masiva total</Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={handleDownloadFile}
            sx={(theme) => ({
              width: 'fit-content',
              marginTop: theme.spacing(1.5),
            })}
            loading={isLoading}
          >
            Descargar
          </Button>
        </Box>
      </Card>
    </>
  )
}

const BulkNoveltiesModal = ({ handleClose }) => {
  const [file, setFile] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  const userId = getUserId()
  const { period } = usePeriod()
  const { listenInBackground } = useUser()
  const { handleError } = useErrorHandler()
  const { openBulkNoveltiesAlert } = useBulkNoveltiesAlert()
  const { showInfoMessage, showErrorMessage } = useNotifications()
  const { noveltiesImportMutation } = useNoveltiesImportService()
  const queryClient = useQueryClient()
  const { enqueueDownload } = useDownloadManager()

  const handleDownloadFile = () => {
    enqueueDownload({
      name: 'formato de carga de novedades',
      fileCode: 'novelties_upload_file',
      firebasePath: `periods/${period.id}/import_payroll_novelties/download_file`,
      service: () =>
        noveltiesImportMutation.mutateAsync({
          mutationMethod: 'GET_NOVELTIES_FILE',
          periodId: period.id,
        }),
      callback: () => {
        trackEvent(integrationEvent.DOWNLOAD_TEMPLATE, 'novelties')
      },
    })
  }

  const handleSubmit = async () => {
    const dataToSend = new FormData()
    dataToSend.append('file', file)

    noveltiesImportMutation.mutate(
      {
        mutationMethod: 'POST',
        periodId: period.id,
        dataToSend,
      },
      {
        onSuccess: ({ message }) => {
          if (message) {
            trackEvent(integrationEvent.NOVELTIES_FILE_UPLOAD)

            showInfoMessage(
              'Estamos verificando la información. Esto puede tardar varios minutos. Te avisaremos cuando esté listo.'
            )

            listenInBackground(
              `users/${userId}/periods/${period.id}/import_payroll_novelties`,
              (websocketResult, connectionRef) =>
                backgroundBulkNoveltiesSocketHandler(
                  websocketResult,
                  connectionRef,
                  showErrorMessage,
                  openBulkNoveltiesAlert,
                  queryClient
                )
            )

            handleClose()
          }
        },
        onError: (error) => {
          handleError(error)
        },
      }
    )
  }

  const handleAddFile = (fileToUpload) => {
    setFile(fileToUpload)
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const tabsConfig = [
    {
      label: 'Descargar plantilla',
      content: (
        <DownloadContent
          handleDownloadFile={handleDownloadFile}
          isLoading={noveltiesImportMutation.isLoading}
        />
      ),
    },
    {
      label: 'Cargar novedades',
      content: (
        <UploadContent
          file={file}
          handleAddFile={handleAddFile}
          isSubmitting={noveltiesImportMutation.isLoading}
          setValue={setFile}
        />
      ),
    },
  ]

  return (
    <Modal
      open
      okText="Cargar"
      header="Carga masiva de novedades"
      onOk={handleSubmit}
      hideOkButton={activeTab !== 1}
      onCancel={handleClose}
      isLoading={noveltiesImportMutation.isLoading}
      paperSx={{ maxWidth: '50rem' }}
      adornment="spring"
      disableOkButton={!file}
    >
      <Typography variant="body1" color="black.dark">
        Ahora puedes cargar tus novedades de una manera más rápida y sencilla.
        Si necesitas ayuda para ingresar tus novedades puedes visitar nuestro{' '}
        <Link
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-registrar-las-novedades-de-mis-empleados"
          target="_blank"
          color="accent4.main"
          fontWeight={600}
        >
          Centro de Ayuda.
        </Link>
      </Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          margin: theme.spacing(4, 0, 3, 0),
        })}
      >
        <RoundedTabs
          value={activeTab}
          tabsConfig={tabsConfig}
          onChange={handleChangeTab}
          sx={{
            width: '25.5625rem',
            [`& .${tabClasses.root}`]: {
              height: '3.5rem',
              minHeight: 'auto',
              minWidth: 'auto',
            },
          }}
        />
      </Box>

      {tabsConfig[activeTab].content}
    </Modal>
  )
}

export default BulkNoveltiesModal
