import http, { getApiURL } from '../../httpService'

export const getAllCompanies = (partnerId, { search, page } = {}) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/partners/informations/${partnerId}`,
    searchParams: { search, page },
  })

  return http.getV2(url)
}

export const updatePartnerCompany = ({ partnerId, company }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/partners/${partnerId}/companies`,
  })

  return http.putV2(url, { company })
}

export default {
  getAllCompanies,
  updatePartnerCompany,
}
