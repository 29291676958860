import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const CompanyOvertimeAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openOvertimeModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={
        payroll.details?.company_overtime?.quantity === '0.0'
          ? 0
          : payroll.details?.company_overtime?.quantity
      }
      onClick={() => openOvertimeModal('others')}
      editable={period.editable && !payroll.locked}
      dataCy="add-company-overtime"
    />
  )
}

export default CompanyOvertimeAction
