import { parseISO } from 'date-fns'

function formatPeriod(period) {
  const periodCopy = { ...period }

  if (period.initial_day) {
    periodCopy.initial_day = parseISO(periodCopy.initial_day)
  }

  if (period.end_day) {
    periodCopy.end_day = parseISO(periodCopy.end_day)
  }

  return periodCopy
}

export default formatPeriod
