import { Typography } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import DropdownButton from 'components/UI/Button/Dropdown'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'
import NoWrap from 'components/UI/NoWrap'

import { isOrganizer } from 'utils/auth'
import { formatDateRange } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

import PayrollHistoryFilter from '../PayrollHistoryFilter/Index'
import PreviousPeriodAction from './PreviousPeriodAction'

export const columnsData = [
  {
    Header: 'Período',
    accessor: 'initial_day',
    Cell: ({ row }) => (
      <Link
        to={routes.PERIOD_PAY_PREVIOUS_PAYROLL(row.original.id)}
        color="black.main"
      >
        <Typography variant="h6" color="black.main">
          {formatDateRange(row.original.initial_day, row.original.end_day)}
        </Typography>
      </Link>
    ),
    noWrapCell: true,
  },
  {
    Header: 'Número',
    accessor: 'payrolls_number',
  },
  {
    Header: 'Salarios',
    accessor: 'salaries',
    Cell: ({ row }) => formatCurrency(row.original.salaries),
    noWrapCell: true,
  },
  {
    Header: 'Otros pagos',
    accessor: 'other_payments',
    Cell: ({ row }) => formatCurrency(row.original.other_payments),
    noWrapCell: true,
  },
  {
    Header: 'Prestaciones',
    accessor: 'total_benefits',
    Cell: ({ row }) => formatCurrency(row.original.total_benefits),
  },
  {
    Header: 'Costo total',
    accessor: 'company_cost',
    Cell: ({ row }) => formatCurrency(row.original.company_cost),
    noWrapCell: true,
  },
  {
    Header: 'Deducciones y ret',
    accessor: 'deductions_retentions',
    Cell: ({ row }) => (
      <NoWrap>({formatCurrency(row.original.deductions_retentions)})</NoWrap>
    ),
  },
]

export const getActions = (
  handleOrganizerPeriodAction,
  handleOpenNewPeriodModal,
  hasAlegraIntegration,
  setSelectedMonth,
  selectedMonth,
  setSelectedYear,
  selectedYear,
  lastPeriodDate,
  setOpenExogenaModal,
  popupState,
  featureState,
  openReportFileDownloadModal
) => {
  let actions = [
    {
      id: 'dateFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <PayrollHistoryFilter
          setSelectedMonth={setSelectedMonth}
          selectedMonth={selectedMonth}
          setSelectedYear={setSelectedYear}
          selectedYear={selectedYear}
        />
      ),
    },
    {
      id: 'createPreviousPeriod',
      isFreeAction: true,
      Component: <PreviousPeriodAction lastPeriodDate={lastPeriodDate} />,
    },
    {
      id: 'generateNewPeriod',
      tooltip: 'Crear periodos SA',
      isFreeAction: true,
      buttonVariant: 'outlined',
      onClick: handleOpenNewPeriodModal,
      hidden: !isOrganizer(),
      buttonProps: {
        endIcon: <Icon name="plus" basic />,
      },
    },
    {
      id: 'consolidatedMenu',
      isFreeAction: true,
      Component: (
        <DropdownButton
          title="Descargar reportes"
          popupState={popupState}
          options={[
            {
              id: 'payReport',
              name: 'Reporte de pagos',
              icon: <PremiumFeatureIcon fontSize="1rem" />,
              onClick: () => {
                popupState.close()
                if (!featureState.showPremiumFeatures) {
                  featureState.openPremiumFeatureModal()
                } else {
                  openReportFileDownloadModal({ reportType: 'paymentsReport' })
                }
              },
            },
            {
              id: 'exogenaReport',
              name: 'Reporte para Exógena',
              icon: <PremiumFeatureIcon fontSize="1rem" />,
              onClick: () => {
                popupState.close()
                if (!featureState.showPremiumFeatures) {
                  featureState.openPremiumFeatureModal()
                } else {
                  setOpenExogenaModal(true)
                }
              },
            },
            {
              id: 'noveltiesReport',
              name: 'Reporte de novedades',
              icon: <PremiumFeatureIcon fontSize="1rem" />,
              onClick: () => {
                popupState.close()
                if (!featureState.showPremiumFeatures) {
                  featureState.openPremiumFeatureModal()
                } else {
                  openReportFileDownloadModal({ reportType: 'noveltiesReport' })
                }
              },
            },
          ]}
          endIcon={<Icon name="arrow-down" basic />}
        />
      ),
    },
  ]

  const confirm = useConfirm() // eslint-disable-line react-hooks/rules-of-hooks

  if (isOrganizer()) {
    actions = actions.concat([
      (rowData) => ({
        id: 'recalculateAllPayroll',
        icon: <Icon name="avatar-plus" basic />,
        tooltip: 'Recalcular nómina completo',
        buttonProps: {
          color: 'complementary1',
          size: 'medium',
        },
        onClick: () => {
          confirm({
            type: 'warning',
            title:
              '¿Desea generar nuevamente todas las nóminas de este periodo?',
            okText: 'Continuar',
            onOk: () =>
              handleOrganizerPeriodAction(rowData.id, 'generate_payrolls'),
          })
        },
      }),
      (rowData) => ({
        id: 'recalculatePayroll',
        icon: <Icon name="reversed-clock" basic />,
        tooltip: 'Recalcular nómina',
        buttonProps: {
          color: 'complementary1',
          size: 'medium',
        },
        onClick: () => {
          confirm({
            type: 'warning',
            title: '¿Desea recalcular la nómina de este periodo?',
            okText: 'Continuar',
            onOk: () => handleOrganizerPeriodAction(rowData.id, 'recalculate'),
          })
        },
      }),
      (rowData) => ({
        id: 'deletePeriod',
        icon: <Icon name="trash" basic />,
        tooltip: 'Eliminar período',
        buttonProps: {
          color: 'complementary1',
          size: 'medium',
        },
        onClick: () => {
          confirm({
            type: 'warning',
            title: 'Eliminar período',
            description:
              'Eliminar este período es permanente y no se podrá deshacer. ¿Estás seguro?',
            okText: 'Eliminar',
            confirmCheckbox: true,
            hidden: !isOrganizer(),
            onOk: () =>
              handleOrganizerPeriodAction(rowData.id, 'delete_period'),
          })
        },
      }),
    ])

    if (hasAlegraIntegration) {
      actions.push((rowData) => ({
        id: 'syncAlegra',
        icon: <Icon name="refresh" basic />,
        tooltip: 'Sincronizar periodo con Alegra',
        buttonProps: {
          color: 'complementary1',
          size: 'medium',
        },
        onClick: () => {
          confirm({
            type: 'warning',
            title:
              '¿Desea sincronizar la información de este periodo con Alegra?',
            okText: 'Continuar',
            onOk: () =>
              handleOrganizerPeriodAction(rowData.id, 'sync_with_alegra'),
          })
        },
      }))
    }
  }

  return actions
}
