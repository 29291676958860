import { useState } from 'react'
import { useQueryClient } from 'react-query'

import Table from 'components/UI/Table/Table'

import usePartnerCompany from 'utils/hooks/organizer/partner/company'
import useNotifications from 'utils/hooks/useNotifications'

import { getAllCompanies } from 'services/organizer/partner/companyService'

import { useHelpersPartnerCompanies } from '../helpers'
import CompanyModal from './Modal'

const initialModalState = { open: false, company: {} }

const OrganizerPartnerCompanyTab = ({ partnerId }) => {
  const [modalState, setModalState] = useState(initialModalState)
  const queryClient = useQueryClient()
  const { showInfoMessage } = useNotifications()
  const { partnerCompanyMutation } = usePartnerCompany({
    queryOptions: { enabled: false },
  })

  const fetchCompanies = (pageIndex, search) => {
    return {
      queryKey: ['organizerPartnerCompanies', partnerId, pageIndex + 1, search],
      queryFunction: () =>
        getAllCompanies(partnerId, {
          search,
          page: pageIndex + 1,
        }),
    }
  }

  const refetchPartnerCompanies = () =>
    queryClient.invalidateQueries(['organizerPartnerCompanies'], {
      exact: false,
    })

  const handleCompanyDelete = (companyId) => {
    partnerCompanyMutation.mutate(
      {
        mutationMethod: 'PUT',
        partnerId,
        company: { id: companyId },
      },
      {
        onSuccess: ({ message }) => {
          showInfoMessage(message)
          refetchPartnerCompanies()
        },
      }
    )
  }

  const handleCloseModal = () => setModalState(initialModalState)

  const { columnsData, actions } = useHelpersPartnerCompanies(
    setModalState,
    handleCompanyDelete
  )

  return (
    <>
      <Table
        data={fetchCompanies}
        columns={columnsData}
        actions={actions}
        options={{ customQueryFetch: 'companies' }}
      />
      {modalState.open ? (
        <CompanyModal
          {...modalState}
          partnerId={partnerId}
          handleClose={handleCloseModal}
          submitCallback={refetchPartnerCompanies}
        />
      ) : null}
    </>
  )
}

export default OrganizerPartnerCompanyTab
