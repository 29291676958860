import { Box, Paper, Typography } from '@mui/material'

import Dropzone from 'components/UI/Dropzone/Dropzone'

import uploadImg from 'assets/images/views/worker/uploadWorkers.png'

import getWorkersUploadMessage from 'messages/workers_file'

const WorkerImportBoxUpload = ({ onSubmitFile }) => {
  const workerFileInfo = getWorkersUploadMessage()

  const onAddFile = (file) => {
    onSubmitFile(file)
  }

  return (
    <Paper
      sx={(theme) => ({
        flex: 1,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(5),
        backgroundColor: theme.palette.white.light,
      })}
    >
      <Typography variant="h5">2. Cargar Archivo</Typography>
      <Typography paragraph>{workerFileInfo.upload}</Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          marginBottom: theme.spacing(1),
        })}
      >
        <img src={uploadImg} alt="Upload File" />
      </Box>

      <Dropzone handleAdd={onAddFile} />
    </Paper>
  )
}

export default WorkerImportBoxUpload
