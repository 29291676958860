import * as yup from 'yup'

import FormField from 'components/UI/Formik/FormField/Index'

import {
  DocumentSelector,
  getDocumentLabel as getBeneficiaryDocumentLabel,
} from '../Form/Beneficiaries/helpers'

export const getInitialValues = (invalidDocuments) => {
  return invalidDocuments?.reduce((initialValuesObject, document) => {
    initialValuesObject[document] = null
    return initialValuesObject
  }, {})
}

const baseDocumentsSchema = yup
  .mixed()
  .test('isPdf', 'El documento debe ser un PDF.', (value) => {
    if (value instanceof File) {
      return value.type === 'application/pdf'
    }
    return value ? typeof value === 'string' : true
  })
  .test(
    'fileSize',
    'El archivo que intentas subir no tiene contenido o se encuentra dañado.',
    (value) => {
      if (!value) return true

      if (value instanceof File) {
        return value.size > 0
      }

      return true
    }
  )
  .required('Debes anexar este documento.')

export const getValidationSchema = (invalidDocuments) =>
  yup.object().shape(
    invalidDocuments?.reduce((schemaFields, document) => {
      schemaFields[document] = baseDocumentsSchema
      return schemaFields
    }, {})
  )

export const getKinship = ({ beneficiaryId, beneficiaries }) =>
  beneficiaries?.find((beneficiary) => beneficiary?.id === beneficiaryId)
    ?.kinship

const getDocumentLabels = (rejectionReason) => ({
  identification_document:
    rejectionReason === 'error_in_worker_documents'
      ? 'Cédula'
      : 'Cámara de comercio',
  contract_document: 'Contrato de aprendizaje',
  tax_document: 'Rut de la empresa',
  bank_certificate_document: 'Certificación bancaria',
  legal_representative_document: 'Cédula representante legal',
})

export const renderAffiliationFilesFields = ({
  documents,
  rejectionReason,
  kinship,
  values,
}) => {
  return documents?.map((document) => (
    <FormField
      key={document}
      name={document}
      label={
        rejectionReason === 'error_in_beneficiary_documents'
          ? values?.[document]
            ? getBeneficiaryDocumentLabel({
                kinship,
                documentType: document,
              })
            : null
          : getDocumentLabels(rejectionReason)?.[document]
      }
      optional={false}
      variant="file"
      fileType="application/pdf"
      accept="application/pdf"
      selector={
        rejectionReason === 'error_in_beneficiary_documents' ? (
          <DocumentSelector>
            {getBeneficiaryDocumentLabel({
              kinship,
              documentType: document,
            })}
          </DocumentSelector>
        ) : null
      }
      withoutBorder={rejectionReason === 'error_in_beneficiary_documents'}
    />
  ))
}

export const getBeneficiaryWithInvalidDocuments = ({
  beneficiaryWithInvalidDocumentsId,
  beneficiaries,
}) => {
  return beneficiaries?.find(
    (beneficiary) => beneficiary?.id === beneficiaryWithInvalidDocumentsId
  )
}
