import { useState } from 'react'

import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Modal from 'components/UI/Modal/Modal'
import Table from 'components/UI/Table/Table'

import useSocialSecurityService from 'utils/hooks/payroll/socialSecurity'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import { ResultTabs, columnsData, formatData } from './helpers'

const ErrorsModal = ({ state, periodId, formType, handleClose, hasErrors }) => {
  const [tab, setTab] = useState(state.initialTab)
  const downloadURI = useDownloadURI()
  const {
    socialSecurityMutation,
    socialSecurityQuery,
  } = useSocialSecurityService({
    serviceParams: {
      queryKey: ['getSSFormErrors', periodId, state?.formType],
      periodId,
      formType,
      format: 'json',
    },
  })
  const errors = formatData(socialSecurityQuery.data)

  const getErrorsFile = () => {
    socialSecurityMutation.mutate(
      {
        mutationMethod: 'GET',
        periodId,
        formType,
        format: 'xlsx',
      },
      {
        onSuccess: ({ file }) => {
          downloadURI(file)
        },
      }
    )
  }

  const handleChangeTab = (_, newTab) => setTab(newTab)

  return (
    <Modal
      header={`Tabla de ${
        hasErrors ? 'errores' : 'alertas'
      } planilla seguridad social`}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
      onCancel={handleClose}
      isLoading={socialSecurityMutation.isLoading}
      footer={
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
          })}
        >
          <Button
            variant="outlined"
            onClick={getErrorsFile}
            loading={socialSecurityMutation.isLoading}
          >
            Ver archivo completo
          </Button>
        </Box>
      }
    >
      <Table
        data={errors[tab]}
        columns={columnsData}
        options={{
          pagination: false,
        }}
        components={{
          Toolbar: (props) => (
            <ResultTabs
              {...props}
              tab={tab}
              handleChangeTab={handleChangeTab}
              data={errors}
            />
          ),
        }}
      />
    </Modal>
  )
}

export default ErrorsModal
