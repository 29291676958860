import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createFreeTimeRequest,
  deleteFreeTimeRequest,
  getPayrollConcepts,
  updateFreeTimeRequest,
} from 'services/worker/freeTimeService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId } = serviceParams

  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'payrollConcepts') return getPayrollConcepts(workerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, noveltyId, freeTimeRequest } = mutationData
  if (mutationMethod === 'POST')
    return createFreeTimeRequest(workerId, freeTimeRequest)
  if (mutationMethod === 'PUT')
    return updateFreeTimeRequest(workerId, freeTimeRequest, noveltyId)
  if (mutationMethod === 'DELETE') return deleteFreeTimeRequest(freeTimeRequest)
  return null
}
const useFreeTimeService = ({
  serviceParams = {},
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restFreeTimeRequestResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const freeTimeMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    freeTimeQuery: {
      data: data?.novelty_requests,
      ...restFreeTimeRequestResponse,
    },
    freeTimeMutation,
  }
}

export default useFreeTimeService
