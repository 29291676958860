import useLocalStorage from '@rehooks/local-storage'
import { useState } from 'react'

import useSubscription from 'components/Subscription/Atoms/useSubscription'

import { isFreeCompany } from 'utils/auth'
import { isTest } from 'utils/general'

const useUpdateCompanyPhoneModal = () => {
  const { subscription } = useSubscription()
  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )
  const [toUpdateData, setToUpdateData] = useLocalStorage('to_update_data')

  const [state, setState] = useState({
    open: isAFreeCompany && toUpdateData && !isTest,
  })

  const openUpdateCompanyPhoneModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: true,
    }))
  }

  const closeUpdateCompanyPhoneModal = () => {
    setToUpdateData(false)
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    updateCompanyPhoneModalState: state,
    openUpdateCompanyPhoneModal,
    closeUpdateCompanyPhoneModal,
  }
}

export default useUpdateCompanyPhoneModal
