import { useState } from 'react'

const useBulkNoveltiesModal = () => {
  const [state, setState] = useState({
    open: false,
  })

  const openBulkNoveltiesModal = () => {
    setState({ open: true })
  }

  const closeBulkNoveltiesModal = () => {
    setState({ open: false })
  }

  return {
    bulkNoveltiesModalState: state,
    openBulkNoveltiesModal,
    closeBulkNoveltiesModal,
  }
}

export default useBulkNoveltiesModal
