import { createCalendar } from '@internationalized/date'
import { useDateField, useDateSegment } from '@react-aria/datepicker'
import { useDateFieldState } from '@react-stately/datepicker'
import { useRef } from 'react'

import { Box } from '@mui/material'

import { useDateRangePickerContext } from './indexV2'

const DateSegment = ({ segment, state }) => {
  const ref = useRef()
  const { segmentProps } = useDateSegment(segment, state, ref)

  return (
    <Box
      {...segmentProps}
      component="span"
      ref={ref}
      style={{
        ...segmentProps.style,
      }}
      sx={(theme) => ({
        boxSizing: 'content-box',
        textAlign: 'right',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        borderRadius: '0.125rem',
        userSelect: 'none',
        '&:focus': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.black.main,
          '& > span': {
            color: theme.palette.black.main,
          },
        },
        [theme.breakpoints.up(380)]: {
          textTransform: 'uppercase',
        },
        ...(!segment.isEditable
          ? {
              color: theme.palette.black.light,
            }
          : {
              color: theme.palette.black.dark,
            }),
        ...(segment.isPlaceholder && {
          color: theme.palette.black.light,
        }),
      })}
    >
      {segment.isPlaceholder ? segment.placeholder : segment.text}
    </Box>
  )
}

const DateField = ({ ...props }) => {
  const { locale } = useDateRangePickerContext()
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  })

  const ref = useRef()
  const { fieldProps } = useDateField(props, state, ref)

  return (
    <Box
      {...fieldProps}
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {state.segments.map((segment, index) => (
        <DateSegment
          key={`${segment.type}_${index.toString()}`}
          segment={segment}
          state={state}
        />
      ))}
    </Box>
  )
}

export default DateField
