import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'

import { partyPopperEmoji, rocket } from 'utils/emojis'

import usePremiumFeature from '../Premium/usePremiumFeature'
import loadChatScript from '../UserContext/loadChatScript'
import SupportActionButton from './SupportActionButton'

const CustomerSupportMenu = () => {
  const { showPremiumFeatures } = usePremiumFeature()

  const location = useLocation()

  const isCompaniesRoute =
    location.pathname === '/companies' ||
    location.pathname.includes('/organizer/companies')

  const showChat = showPremiumFeatures && !isCompaniesRoute

  useEffect(() => {
    if (showChat) loadChatScript()
  }, [showChat])

  return (
    <>
      <Typography
        variant="h5"
        sx={(theme) => ({ marginBottom: theme.spacing(0.5) })}
      >
        Tranqui, te ayudamos!
      </Typography>
      <Typography variant="body2">
        Aclaramos tus dudas más rápido que Flash! <Emoji code={rocket} />
      </Typography>
      <Typography
        variant="body2"
        sx={(theme) => ({ marginTop: theme.spacing(0.5) })}
      >
        Tómate un tintico con uno de nuestros expertos y grita: Aleluya!{' '}
        <Emoji code={partyPopperEmoji} />
      </Typography>

      <Box sx={(theme) => ({ margin: theme.spacing(1, 0, 0.5) })}>
        <Typography variant="lead2">
          Estamos disponibles para ti en este horario:
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ marginTop: theme.spacing(1) })}
        >
          Lunes a viernes 8:30am a 5pm
        </Typography>
        <Typography variant="body2">Sábados 8:30am a 12pm</Typography>
      </Box>

      <SupportActionButton variant="mail" />
      {showChat ? <SupportActionButton variant="zohoChat" /> : null}
    </>
  )
}

export default CustomerSupportMenu
