import { Box, Typography } from '@mui/material'

import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'

export const BillingInfoFields = () => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        width: '100%',
        rowGap: theme.spacing(2.5),
        gridTemplateColumns: '1fr',
      })}
    >
      <Typography>Confirma acá tus datos</Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(2),
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <DocumentTypeField
          name="company_form.document_type"
          options={documentTypesOptions}
          optional={false}
        />
        <IdentificationNumberField name="company_form.id_number" />
      </Box>
      <FormField
        name="company_form.name"
        label="Nombre o razón social"
        placeholder="Nombre o razón social"
        optional={false}
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(2),
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <FormField
          name="company_form.email"
          type="emai"
          label="Correo"
          optional={false}
          placeholder="ejemplo@aleluya.com"
        />
        <FormField
          variant="number"
          name="company_form.phone"
          label="Número de teléfono"
          placeholder="Número de teléfono"
          format="### ### ####"
          optional={false}
        />
      </Box>
    </Box>
  )
}
