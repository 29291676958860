import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'

import Icon from 'components/UI/Icon'

import useDownloadURI from 'utils/hooks/useDownloadURI'

const CertificateDownloadCard = ({
  iconName,
  backgroundColor,
  institutionName,
  completed,
  documentUrl,
}) => {
  const themeInstance = useTheme()
  const downloadURI = useDownloadURI()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        borderRadius: '0.5rem',
        boxShadow: theme.shadows[4],
        gap: theme.spacing(2),
        [theme.breakpoints.between('tablet', 'desktop')]: {
          gridTemplateColumns: '1fr 3.5fr',
          gap: theme.spacing(0.5),
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '0.5rem 0 0 0.5rem',
          backgroundColor: completed
            ? backgroundColor(theme)
            : theme.palette.black.light,
          ...(!completed && {
            backgroundColor: theme.palette.black.light,
          }),
        })}
      >
        <Icon
          name={iconName}
          basic
          color={!completed ? themeInstance.palette.black.dark : 'black'}
          sx={{ fontSize: '3.5rem' }}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          padding: theme.spacing(2, 1),
        })}
      >
        <Typography variant="h6">{institutionName}</Typography>
        <Button
          onClick={() => downloadURI(documentUrl, '_blank')}
          variant="outlined"
          disabled={!completed}
          size="small"
          sx={{ width: 'fit-content' }}
          endIcon={
            <Icon
              name="download"
              basic
              color={!completed ? themeInstance.palette.black.dark : 'black'}
            />
          }
        >
          Descargar{isMobile ? null : ' certificado'}
        </Button>
      </Box>
    </Box>
  )
}

export default CertificateDownloadCard
