import { useContext } from 'react'

import ConfirmContext from './ConfirmContext'

/**
 * Function to display the confirmation modal. 
   Important: if the function passed in the onOk argument is to make an API call, this function must be asynchronous, so the modal can use async/await to wait for the function to execute and then close. If on the other hand the function does not make any API call, it does not need to be asynchronous.
 */
const useConfirm = () => {
  const confirm = useContext(ConfirmContext)

  return confirm
}

export default useConfirm
