import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import FreeTimeAdminView from 'components/Worker/FreeTime/FreeTimeAdminView'
import FreeTimeIndex from 'components/Worker/FreeTime/Index/Index'

const FreeTimeContent = () => {
  const { isWorker } = useUser()

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(0, 3, 6, 3),
      })}
    >
      <Typography
        variant="h4"
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
      >
        Historial de solicitudes
      </Typography>
      {isWorker ? <FreeTimeIndex /> : <FreeTimeAdminView />}
    </Box>
  )
}

export default FreeTimeContent
