import Page from 'components/UI/Page/Page'

import SettingsIntegrationsAccounts from './Accounts/Accounts'
import SettingsIntegrationsCostCenters from './CostCenters/CostCenters'

const SettingsIntegrationAccounting = () => {
  return (
    <Page header="Contabilidad Alegra">
      <SettingsIntegrationsCostCenters />
      <SettingsIntegrationsAccounts />
    </Page>
  )
}

export default SettingsIntegrationAccounting
