import { useSnackbar } from 'notistack'
import { forwardRef } from 'react'

import { Box, CircularProgress, IconButton, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { alertColors } from 'styles/colors'

export const CustomSnackbarSuccess = forwardRef(
  ({ id, variant, message, iconVariant, description }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const { success: IconSuccess } = iconVariant

    return (
      <Box ref={ref}>
        <Box
          sx={(theme) => ({
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '2.5rem auto 3.25rem',
            padding: theme.spacing(2),
            backgroundColor: alertColors[variant],
            color: theme.palette[variant].dark,
          })}
        >
          {IconSuccess}
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {message}
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              marginLeft: theme.spacing(2),
            })}
          >
            <IconButton
              aria-label="close notification message"
              size="small"
              onClick={() => closeSnackbar(id)}
              sx={(theme) => ({
                marginRight: theme.spacing(1.5),
                backgroundColor: theme.palette[variant].dark,
                color: theme.palette[variant].light,
                '&:focus,&:hover': {
                  backgroundColor: theme.palette[variant].dark,
                },
              })}
            >
              <Icon basic name="close-2" sx={{ fontSize: '1rem' }} />
            </IconButton>
          </Box>
        </Box>
        {description ? (
          <Box
            sx={(theme) => ({
              padding: theme.spacing(1, 2),
              backgroundColor: theme.palette.white.main,
            })}
          >
            {description}
          </Box>
        ) : null}
      </Box>
    )
  }
)

CustomSnackbarSuccess.displayName = 'CustomSnackbarSuccess'

export const CustomSnackbarInfo = forwardRef(
  ({ id, variant, message, iconVariant, inProgress }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const { info: IconInfo } = iconVariant

    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '2.5rem auto 3.25rem',
          padding: theme.spacing(2),
          backgroundColor: alertColors[variant],
          color: theme.palette[variant].dark,
        })}
      >
        {IconInfo}
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {inProgress ? (
            <CircularProgress
              size={18}
              sx={(theme) => ({
                marginRight: theme.spacing(1),
                color: theme.palette.info.dark,
              })}
            />
          ) : null}
          {message}
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(2),
          })}
        >
          <IconButton
            aria-label="close notification message"
            size="small"
            onClick={() => closeSnackbar(id)}
            sx={(theme) => ({
              marginRight: theme.spacing(1.5),
              backgroundColor: theme.palette[variant].dark,
              color: theme.palette[variant].light,
              '&:focus,&:hover': {
                backgroundColor: theme.palette[variant].dark,
              },
            })}
          >
            <Icon basic name="close-2" sx={{ fontSize: '1rem' }} />
          </IconButton>
        </Box>
      </Box>
    )
  }
)

CustomSnackbarInfo.displayName = 'CustomSnackbarInfo'

export const CustomSnackbarWarning = forwardRef(
  ({ id, variant, message, iconVariant }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const { warning: IconWarning } = iconVariant

    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '2.5rem auto 3.25rem',
          padding: theme.spacing(2),
          backgroundColor: alertColors[variant],
          color: theme.palette[variant].dark,
        })}
      >
        {IconWarning}
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {message}
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(2),
          })}
        >
          <IconButton
            aria-label="close notification message"
            size="small"
            onClick={() => closeSnackbar(id)}
            sx={(theme) => ({
              marginRight: theme.spacing(1.5),
              backgroundColor: theme.palette[variant].dark,
              color: theme.palette[variant].light,
              '&:focus,&:hover': {
                backgroundColor: theme.palette[variant].dark,
              },
            })}
          >
            <Icon basic name="close-2" sx={{ fontSize: '1rem' }} />
          </IconButton>
        </Box>
      </Box>
    )
  }
)

CustomSnackbarWarning.displayName = 'CustomSnackbarWarning'

export const CustomSnackbarError = forwardRef(
  ({ id, variant, message, iconVariant }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const { error: IconError } = iconVariant

    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '2.5rem auto 3.25rem',
          padding: theme.spacing(2),
          backgroundColor: alertColors[variant],
          color: theme.palette[variant].dark,
        })}
      >
        {IconError}
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {message}
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(2),
          })}
        >
          <IconButton
            aria-label="close notification message"
            size="small"
            onClick={() => closeSnackbar(id)}
            sx={(theme) => ({
              marginRight: theme.spacing(1.5),
              backgroundColor: theme.palette[variant].dark,
              color: theme.palette[variant].light,
              '&:focus,&:hover': {
                backgroundColor: theme.palette[variant].dark,
              },
            })}
          >
            <Icon basic name="close-2" sx={{ fontSize: '1rem' }} />
          </IconButton>
        </Box>
      </Box>
    )
  }
)

CustomSnackbarError.displayName = 'CustomSnackbarError'
