import * as yup from 'yup'

import { Chip, alpha } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import { proportionalRecurrentPaymentOptions } from '../../Modals/RecurrentConceptsModal/helpers'
import ConceptCategory from './ConceptCategory'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  category: yup.string().nullable().required(),
  payroll_concept_id: yup.string().nullable().required(),
  monthly_value: yup.number().nullable().positive().required(),
  active: yup.bool(),
  concept_type: yup.string().nullable().required(),
})

export const getIncomesColumns = (concepts, applySalaryIncome) => {
  const categoryLookUp = {
    salary_income: 'Salarial',
    non_salary_income: 'No salarial',
  }

  const conceptTypeOptions = {
    proportional: 'Proporcional',
    fixed: 'Fijo',
  }

  return [
    {
      Header: 'Categoría',
      accessor: 'category',
      Cell: ({ row }) => categoryLookUp[row.original.category],
      editable: !!applySalaryIncome,
      Edit: (props) => (
        <ConceptCategory
          {...props}
          options={Object.keys(categoryLookUp).map((id) => ({
            label: categoryLookUp[id],
            value: id,
          }))}
        />
      ),
      editInitialValue: applySalaryIncome
        ? 'salary_income'
        : 'non_salary_income',
    },
    {
      Header: 'Concepto',
      accessor: 'name',
      field: 'select',
      name: 'payroll_concept_id',
      editOptions: (_, rowValues) => {
        const options = concepts[rowValues.category]?.map?.((option) => ({
          label: option.name,
          value: option.id,
        }))
        return options || []
      },
      editable: true,
    },
    {
      Header: 'Valor mensual',
      accessor: 'monthly_value',
      Cell: ({ row }) => formatCurrency(row.original.monthly_value),
      field: 'currency',
      name: 'monthly_value',
      editable: true,
    },
    {
      Header: 'Tipo',
      accessor: 'concept_type',
      Cell: ({ row }) => conceptTypeOptions[row.original.concept_type],
      name: 'concept_type',
      Edit: () => (
        <FormField
          name="concept_type"
          variant="select"
          options={proportionalRecurrentPaymentOptions}
        />
      ),
      editable: true,
    },
    {
      Header: 'Estatus',
      accessor: 'status',
      Cell: ({ row }) => (
        <Chip
          sx={(theme) => ({
            minWidth: '6rem',
            ...(!row.original.active && {
              backgroundColor: alpha(theme.palette.error.main, 0.2),
              color: theme.palette.error.dark,
            }),
            ...(row.original.active && {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.dark,
            }),
          })}
          label={row.original.active ? 'Activo' : 'Inactivo'}
        />
      ),
      editable: true,
      name: 'active',
      field: 'switch',
    },
  ]
}
