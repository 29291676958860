import useMeasure from 'react-use-measure'
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts'

import { createFakeDataPie } from '../helpers'

const MAX_VALUE = 100
const MIN_VALUE = 0

const getWellnessData = (enableEmptyState) => {
  return [
    {
      name: 'Presente',
      value: 100,
      fill: `rgba(var(${enableEmptyState ? '--color-black-dark' : '--color-accent-red-main'}))`,
    },
    {
      name: 'Metas',
      value: 100,
      fill: `rgba(var(${enableEmptyState ? '--color-black-dark' : '--color-accent-red-main'}))`,
    },
    {
      name: 'Futuro',
      value: 100,
      fill: `rgba(var(${enableEmptyState ? '--color-black-light' : '--color-complementary-yellow-main'}))`,
    },
    {
      name: 'Tranquilidad',
      value: 100,
      fill: `rgba(var(${enableEmptyState ? '--color-black-light' : '--color-complementary-yellow-main'}))`,
    },
    {
      name: 'Imprevistos',
      value: 100,
      fill: `rgba(var(${enableEmptyState ? '--color-black-light' : '--color-primary-main'}))`,
    },
  ]
}

export default function WellnessHalfDonutChart({
  value,
  enableEmptyState = false,
}) {
  const [ref, bounds] = useMeasure()
  const containerWidth = Math.max(bounds.width, 100)
  const fillValue = `rgba(var(${enableEmptyState ? '--color-black-dark' : '--color-complementary-blue-dark'}))`
  const data = getWellnessData(enableEmptyState)

  // Dynamic calculations based on container size
  const outerRadius = (bounds.width * 0.95) / 2
  const innerRadius = outerRadius * 0.83
  const valueFontSize = Math.max(bounds.width / 4.5, 16)

  const startAngle = 180
  const endAngle = 360
  const rangeValue = ((value - MIN_VALUE) / (MAX_VALUE - MIN_VALUE)) * 100

  const cx = bounds.width / 2
  const cy = bounds.height / 2
  const thumbRadius = outerRadius * 0.92
  const thumbSize = Math.min(bounds.width * 0.1)
  const angle = startAngle - (rangeValue / 100) * (startAngle - endAngle)
  const thumbX = cx + thumbRadius * Math.cos((angle * Math.PI) / 180)
  const thumbY = cy + thumbRadius * Math.sin((angle * Math.PI) / 180)

  return (
    <div
      style={{ height: containerWidth / 2 + (thumbSize / 2 + 2) }}
      className="w-full overflow-hidden"
    >
      <div ref={ref} style={{ width: '100%', height: containerWidth }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={createFakeDataPie(33)}
              startAngle={180}
              endAngle={0}
              innerRadius="69%"
              outerRadius="70%"
              paddingAngle={5}
              dataKey="value"
              cornerRadius={40}
              stroke="none"
              fill="rgba(var(--color-black-light))"
              animationDuration={700}
            />
            <Pie
              className="focus:outline-none"
              data={data}
              startAngle={180}
              endAngle={0}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              paddingAngle={3}
              dataKey="value"
              cornerRadius={40}
              stroke="none"
              cx="50%"
              animationDuration={700}
            >
              <Label
                style={{ fontSize: valueFontSize }}
                className="font-montserrat font-bold"
                position="centerBottom"
                value={`${value}%`}
                fill={fillValue}
              />
            </Pie>

            <circle
              cx={thumbX}
              cy={thumbY}
              r={thumbSize / 2}
              fill={fillValue}
            />
            <circle cx={thumbX} cy={thumbY} r={thumbSize / 4} fill="#FFF" />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
