import { useQueryClient } from 'react-query'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useCompanyOnboardingService from 'utils/hooks/worker/onboarding'

const useUpdateOnboarding = ({ mutationOptions = {} } = {}) => {
  const { companyOnboardingMutation } = useCompanyOnboardingService({
    mutationOptions,
  })
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()

  return {
    updateOnboarding: ({
      onboardingId,
      data,
      onSuccessCallback,
      onErrorCallback,
    }) => {
      companyOnboardingMutation.mutate(
        {
          mutationMethod: 'PUT',
          mutationKey: 'updateOnboarding',
          onboardingId,
          onboardingData: data,
        },
        {
          onSuccess: async ({ message }) => {
            await queryClient.invalidateQueries(['getCompanyOnboardings'], {
              exact: false,
            })
            showSuccessMessage(message)

            if (onSuccessCallback) {
              onSuccessCallback()
            }
          },
          onError: (error) => {
            if (onErrorCallback && error.errors[0]?.code === '3605') {
              onErrorCallback(error)
            } else {
              handleError(error)
            }
          },
        }
      )
    },
    onboardingMutation: companyOnboardingMutation,
  }
}

export default useUpdateOnboarding
