import { isSameDay, isValid } from 'date-fns'
import { useQueries } from 'react-query'

import { generateEmptyAreaOrPosition } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import { fillArray } from 'utils/general'

import { getAllAreas } from 'services/company/areaService'
import { getCompany } from 'services/company/companyService'
import { getAllWorkCenters } from 'services/company/decree2012Service'
import { getAllLocation } from 'services/company/locationService'
import { getAllPosition } from 'services/company/positionService'

export const formatCompanyData = (dataFormatted) => {
  // asign the data from parallel queries
  const { company, locations, workCenters, areas, positions } = dataFormatted

  const newCompany = {
    ...company,
  }

  if (company?.accounting_provider?.id === null) {
    newCompany.accounting_provider = null
  }
  if (company?.ss_operator?.id === null) {
    newCompany.ss_operator = null
  }
  if (company?.bank?.id === null) {
    newCompany.bank = null
  }
  if (company?.risk_provider?.id === null) {
    newCompany.risk_provider = null
  }
  // #endregion

  newCompany.locations = locations
  newCompany.workCenters = workCenters
  newCompany.areas = areas
  newCompany.positions = positions

  fillArray(newCompany.areas, 5, generateEmptyAreaOrPosition())
  fillArray(newCompany.positions, 5, generateEmptyAreaOrPosition())

  return newCompany
}

const getDirtyAreas = (initialAreas, areas) => {
  const initAreas = {}

  initialAreas.forEach((area) => {
    if (area.id) {
      initAreas[area.id] = { ...area }
    }
  })

  const areasToSend = []

  areas.forEach((area) => {
    if (area.id) {
      if (area.name !== initAreas[area.id].name) {
        areasToSend.push(area)
      }
      initAreas[area.id].checked = true
    } else if (area.name && area.name.trim()) {
      areasToSend.push(area)
    }
  })

  // find for areas to delete
  Object.values(initAreas).forEach((a) => {
    if (!a.checked) {
      // just id is deletion
      areasToSend.unshift({ id: a.id })
    }
  })

  if (areasToSend.length === 0) return undefined

  return areasToSend
}

const getDirtyPositions = (initialPositions, positions) => {
  const initPositions = {}

  initialPositions.forEach((pos) => {
    if (pos.id) {
      initPositions[pos.id] = { ...pos }
    }
  })

  const positionsToSend = []

  positions.forEach((pos) => {
    if (pos.id) {
      if (pos.name !== initPositions[pos.id].name) {
        positionsToSend.push(pos)
      }
      initPositions[pos.id].checked = true
    } else if (pos.name && pos.name.trim()) {
      positionsToSend.push(pos)
    }
  })

  // find for positions to delete
  Object.values(initPositions).forEach((pos) => {
    if (!pos.checked) {
      // just id is deletion
      positionsToSend.unshift({ id: pos.id })
    }
  })

  if (positionsToSend.length === 0) return undefined

  return positionsToSend
}

// for all company values

export const getDirtyValues = (initialValues, values) => {
  const dirtyValues = {}

  Object.keys(values).forEach((key) => {
    const value = values[key]
    const type = typeof value
    const initialValue = initialValues[key]

    if (type === 'object') {
      if (value instanceof Array) {
        if (key === 'areas') {
          const areasResult = getDirtyAreas(initialValues.areas, value)

          if (areasResult) {
            dirtyValues[key] = areasResult
          }
        } else if (key === 'positions') {
          const positionsResult = getDirtyPositions(
            initialValues.positions,
            value
          )

          if (positionsResult) {
            dirtyValues[key] = positionsResult
          }
        }
      } else if (
        isValid(value) &&
        (!initialValue || !isSameDay(initialValue, value))
      ) {
        dirtyValues[key] = formatDate(value)
      } else if (value !== initialValue) {
        if (value) {
          if (value.id) dirtyValues[`${key}_id`] = value.id
          else dirtyValues[key] = value.value
        } else {
          dirtyValues[key] = value
        }
      }
    } else if (value !== initialValue) {
      dirtyValues[key] = value
    }
  })

  return dirtyValues
}

export const useGetBasicInformation = (companyId) => {
  const response = useQueries([
    {
      queryKey: 'companyInformation',
      queryFn: () => getCompany({ companyId }),
    },
    {
      queryKey: 'companyLocation',
      queryFn: () => getAllLocation(),
    },
    {
      queryKey: ['getWorkCenters', companyId],
      queryFn: () => getAllWorkCenters(),
    },
    {
      queryKey: 'companyAreas',
      queryFn: () => getAllAreas(),
    },
    {
      queryKey: 'companyPosition',
      queryFn: () => getAllPosition(),
    },
  ])

  const isSuccess = response.every((query) => query.isSuccess)
  const isLoading = response.some((status) => status.status === 'loading')

  return {
    data: {
      company: response[0]?.data?.data,
      locations: response[1]?.data?.data,
      workCenters: response[2]?.data?.data,
      areas: response[3]?.data?.data,
      positions: response[4]?.data?.data,
    },
    isLoading,
    isSuccess,
  }
}
