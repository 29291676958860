import { getToken } from 'utils/auth'

import http, { getApiURL, handleFetchResponse } from '../httpService'

export const createSession = ({ email, password, otp_code: otpCode }) => {
  const productionUrl = getApiURL({
    pathname: '/sessions',
    withoutCompany: true,
  })
  const developmentUrl = '/v1/sessions'
  const url =
    process.env.NODE_ENV === 'development' &&
    ['false', undefined].includes(process.env.REACT_APP_CI)
      ? developmentUrl
      : productionUrl

  let data

  if (otpCode) data = { otp_code: otpCode }

  return http.postV2(url, data, {
    credentials: 'include',
    headers: {
      Authorization: `Basic ${window.btoa(`${email}:${password}`)}`,
    },
  })
}

export const refresh = async () => {
  const url = getApiURL({ pathname: '/sessions', withoutCompany: true })

  const response = await fetch(url.toString(), {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  const handledResponse = await handleFetchResponse(response)
  return handledResponse
}

export const refreshSession = () => {
  const productionUrl = getApiURL({
    pathname: '/sessions',
    withoutCompany: true,
  })
  const developmentUrl = '/v1/sessions'
  const url =
    process.env.NODE_ENV === 'development' &&
    ['false', undefined].includes(process.env.REACT_APP_CI)
      ? developmentUrl
      : productionUrl

  return http.patchV2(url, null, {
    credentials: 'include',
  })
}

export const deleteSession = () => {
  const url = getApiURL({ pathname: '/sessions', withoutCompany: true })

  return http.deleteV2(url)
}

export const googleService = ({ googleId, idToken }) => {
  const url = getApiURL({
    pathname: '/sessions',
    withoutCompany: true,
  })

  return http.postV2(url, {
    google_session: {
      google_id: googleId,
      id_token: idToken,
    },
  })
}

export default {
  refresh,
  deleteSession,
  refreshSession,
  createSession,
  googleService,
}
