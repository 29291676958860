export default {
  ELECTRONIC_PAYROLL_CONFIGURATION_ARTICLE:
    'https://ayuda.aleluya.com/portal/es/kb/articles/paso-a-paso-para-habilitarte-ante-la-dian-con-aleluya',
  ELECTRONIC_PAYROLL_TOTAL_INFORMATION:
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/todo-lo-que-tienes-que-saber-sobre-la-habilitación-de-nómina-electrónica',
  ELECTRONIC_PAYROLL_STEP_BY_STEP_ENABLING:
    'https://ayuda.aleluya.com/portal/es/kb/articles/paso-a-paso-para-habilitarte-ante-la-dian-con-aleluya',
  ELECTRONIC_PAYROLL_AUTHORIZATION_CONFIRMATION:
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/verifica-que-estás-habilitado-para-emitir-tu-nómina-electrónica-y-el-estado-de-tu-set-de-pruebas-en-el-portal-de-la-dian',
  TUTORIAL_STEP_ONE: 'https://youtube.com/embed/0sw0-D07hw8',
  TUTORIAL_STEP_TWO: 'https://youtube.com/embed/Zo8Sy1TkmuU',
  ELECTRONIC_PAYROLL_BONUS_CONCEPT:
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/reportar-en-la-nómina-electrónica-los-pagos-en-especie-que-das-a-tus-empleados',
}
