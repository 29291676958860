import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createLocation,
  getAllLocation,
} from 'services/company/locationService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'companyLocation') return getAllLocation()

  return null
}

const getMutationFunction = (mutationData) => {
  const { data, mutationMethod } = mutationData

  if (mutationMethod === 'POST') return createLocation(data)

  return null
}

const useLocationService = ({
  queryOptions = {},
  serviceParams = { queryKey: 'companyLocation' },
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restLocation } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const locationMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    locationQuery: { data, ...restLocation },
    locationMutation,
  }
}

export default useLocationService
