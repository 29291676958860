import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

import { getFileNameFromUrl, isValidUrl } from 'utils/general'

const UploadFiles = () => {
  const { values } = useFormikContext()

  const documentsToSignUrl = isValidUrl(values?.document)
  const documentsToSignName = getFileNameFromUrl(
    documentsToSignUrl ? documentsToSignUrl.search : undefined
  )
  return (
    <FormField
      name="document"
      label="Cargar documento a firmar"
      variant="file"
      fileName={documentsToSignName}
      fileType="application/pdf"
      accept="application/pdf"
      optional={false}
    />
  )
}

export default UploadFiles
