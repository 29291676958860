import { useMutation } from 'react-query'

import { updateBeneficiary } from 'services/affiliations/affiliations'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, beneficiaryData } = mutationData

  if (mutationMethod === 'PUT') {
    return updateBeneficiary({ workerId, beneficiaryData })
  }
  return null
}

const useBeneficiariesService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()
  const beneficiariesMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    beneficiariesMutation,
  }
}

export default useBeneficiariesService
