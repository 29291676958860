import http, { getApiURL } from '../httpService'

export const getAllCompanies = ({ search, page }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/companies',
    searchParams: { search, page },
  })

  return http.getV2(url)
}

export const getAllCompanyComments = ({ search, page, companyId }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments`,
    searchParams: { search, page },
  })

  return http.getV2(url)
}

export const getCompanyFromOrganizer = (companyId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/companies/${companyId}`,
  })

  return http.getV2(url)
}

export const getCompanyPaymentAdjustments = (companyId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `organizers/${companyId}/payment_adjustments`,
  })

  return http.getV2(url)
}

export const updatePaymentAdjustment = ({
  companyId,
  paymentAdjustmentId,
  paymentAdjustmentData,
}) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `organizers/${companyId}/payment_adjustments/${paymentAdjustmentId}`,
  })

  return http.patchV2(url, paymentAdjustmentData)
}

export const createPaymentAdjustment = (companyId, paymentAdjustmentData) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/payment_adjustments`,
  })

  return http.postV2(url, paymentAdjustmentData)
}

export const createCompanyComment = (companyId, comment) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments`,
  })

  return http.postV2(url, comment)
}

export const updateCompany = ({ id, ...restRequest }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/companies/${id}`,
  })

  return http.patchV2(url, restRequest)
}

export const updateCompanyComment = (companyId, comment, commentId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments/${commentId}`,
  })

  return http.patchV2(url, comment)
}

export const deleteCompany = (companyId) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/companies/${companyId}`,
  })

  return http.deleteV2(url)
}

export const deleteCompanyComment = ({ companyId, commentId }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/${companyId}/comments/${commentId}`,
  })

  return http.deleteV2(url)
}

export default {
  getAllCompanies,
  getAllCompanyComments,
  updateCompany,
  deleteCompany,
  getCompanyFromOrganizer,
  createPaymentAdjustment,
  createCompanyComment,
  updatePaymentAdjustment,
  deleteCompanyComment,
}
