import qs from 'query-string'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Link, Paper, Typography } from '@mui/material'

import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'

import { isWorker } from 'utils/auth'

import PeriodDetailsBodyNewSection from './NewSection'
import PeriodDetailsBodySection from './Section'
import { pageDescriptions, tabsData } from './helpers'

const PeriodDetailsBody = ({ details = {}, socialBenefitsId }) => {
  const location = useLocation()
  const queryParams = qs.parse(location.search)

  const [activeDetail, setActiveDetail] = useState(
    queryParams.selected_tab || 'payslip_detail'
  )

  const description = pageDescriptions[activeDetail] || {}

  const handleChangeTab = (e, newTab) => setActiveDetail(newTab)

  return (
    <Paper
      sx={(theme) => ({
        gridColumn: '1 / -1',
        marginTop: isWorker() ? 0 : theme.spacing(3),
        borderRadius: '1rem',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(4),
        })}
      >
        <RoundedTabs
          value={activeDetail}
          tabsConfig={tabsData(
            details?.header?.payroll_options,
            socialBenefitsId
          )}
          onChange={handleChangeTab}
        />
      </Box>

      <Box sx={(theme) => ({ padding: theme.spacing(3) })}>
        <Box
          sx={(theme) => ({
            marginBottom: theme.spacing(3),
          })}
        >
          <Typography
            color="primary.dark"
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(3),
            })}
          >
            {description.title}
          </Typography>
          {description.description ? (
            <Typography variant="body1">{description.description}</Typography>
          ) : null}
          {description.link ? (
            <Link
              href={description.link}
              target="_blank"
              fontWeight={600}
              color="accent4.main"
            >
              Conoce más acerca de {description.title}
            </Link>
          ) : null}
        </Box>
        {['social_benefits', 'payroll_retention', 'termination'].includes(
          activeDetail
        ) ? (
          <PeriodDetailsBodyNewSection
            details={details}
            activeDetail={activeDetail}
          />
        ) : (
          <PeriodDetailsBodySection
            details={details}
            activeDetail={activeDetail}
          />
        )}
      </Box>
    </Paper>
  )
}

export default PeriodDetailsBody
