import { createContext } from 'react'

import { Box, Paper } from '@mui/material'

export const CardGridContext = createContext()
CardGridContext.displayName = 'CardGridContext'

const CardGrid = ({ gridColumns, children }) => {
  return (
    <CardGridContext.Provider
      value={{
        gridColumns,
      }}
    >
      <Paper
        sx={(theme) => ({
          boxShadow: theme.shadows[5],
          borderRadius: '1rem',
          overflow: 'hidden',
          [theme.breakpoints.up('tablet')]: {
            paddingBottom: theme.spacing(2.5),
          },
        })}
      >
        <Box>{children}</Box>
      </Paper>
    </CardGridContext.Provider>
  )
}

export default CardGrid
