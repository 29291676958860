import { useState } from 'react'

export const useCommentModal = () => {
  const [state, setState] = useState({
    open: false,
    companyId: '',
    commentData: {
      id: '',
      note: '',
    },
    action: 'show',
  })

  const openCommentModal = ({ commentData, action, companyId }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      companyId,
      commentData,
      action,
    }))
  }

  const closeCommentModal = () => {
    setState((previousState) => ({ ...previousState, open: false }))
  }

  return {
    commentModalState: state,
    openCommentModal,
    closeCommentModal,
  }
}
