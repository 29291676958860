import http, { getApiURL } from './httpService'

export const getAllInformationService = (option) => {
  const url = getApiURL({
    pathname: '/user_informations',
    searchParams: { option },
    withoutCompany: true,
  })
  return http.getV2(url)
}

export default {
  getAllInformationService,
}
