import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getDetails,
  getPayslip,
  getPayslipOptions,
  putConnectivityAid,
  sendPayslip,
  updatePayroll,
} from 'services/payroll/payrollService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, searchParams, payrollId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getPayslipOptions')
    return getPayslipOptions(payrollId)

  if (currentQueryKey === 'getDetails')
    return getDetails(payrollId, searchParams)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    payrollId,
    data,
    payslipType,
    payrollRange,
    whatsappNotification,
    quantity,
    searchParams,
  } = mutationData

  if (mutationMethod === 'PATCH') return updatePayroll(payrollId, data)
  if (mutationMethod === 'GET_PAYSLIP')
    return getPayslip(payrollId, payslipType, payrollRange)
  if (mutationMethod === 'SEND_PAYSLIP')
    return sendPayslip(
      payrollId,
      payslipType,
      payrollRange,
      whatsappNotification
    )
  if (mutationMethod === 'GET_PAYSLIP_OPTIONS')
    return getPayslipOptions(payrollId)
  if (mutationMethod === 'PUT_CONNECTIVITY_AIDS')
    return putConnectivityAid(payrollId, quantity)
  if (mutationMethod === 'GET_DETAILS')
    return getDetails(payrollId, searchParams)

  return null
}

const usePayrollService = ({
  queryOptions = {},
  serviceParams = { queryKey: 'getDetails' },
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restPayroll } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const payrollMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    payrollQuery: { data, ...restPayroll },
    payrollMutation,
  }
}

export default usePayrollService
