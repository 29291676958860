import { getDayOfWeek, isSameDay, isSameMonth } from '@internationalized/date'
import { useCalendarCell } from '@react-aria/calendar'
import { useFocusRing } from '@react-aria/focus'
import { mergeProps } from '@react-aria/utils'
import { useRef } from 'react'

import { Box } from '@mui/material'

import Tooltip from 'components/UI/Tooltip'

import { useDateRangePickerContext } from './indexV2'

const CalendarCell = ({ state, date, startDate }) => {
  const ref = useRef()
  const {
    cellProps,
    buttonProps,
    isSelected,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref)
  const dataCy = `${date.year}-${date.month}-${date.day}`
  const showTooltip = isUnavailable && !state.isDragging
  const {
    tooltipTitle,
    tooltipDescription,
    locale,
  } = useDateRangePickerContext()

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected
  const isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  const dayOfWeek = getDayOfWeek(date, locale)
  const isRoundedLeft =
    isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1)
  const isRoundedRight =
    isSelected &&
    (isSelectionEnd ||
      dayOfWeek === 6 ||
      date.day === date.calendar.getDaysInMonth(date))

  const isOutsideMonth = !isSameMonth(startDate, date)

  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <Box
      component="td"
      {...cellProps}
      sx={(theme) => ({
        padding: theme.spacing(0.375, 0),
      })}
    >
      <Tooltip
        title={showTooltip && tooltipTitle ? tooltipTitle : undefined}
        description={
          showTooltip && tooltipDescription ? tooltipDescription : undefined
        }
        enterNextDelay={500}
      >
        <Box
          {...mergeProps(buttonProps, focusProps)}
          ref={ref}
          hidden={isOutsideMonth}
          data-cy={dataCy}
          sx={(theme) => ({
            width: '2.5rem',
            height: '2.5rem',
            outline: '2px solid transparent',
            outlineOffset: 2,
            ...(isRoundedLeft && {
              borderBottomLeftRadius: 99999,
              borderTopLeftRadius: 99999,
            }),
            ...(isRoundedRight && {
              borderBottomRightRadius: 99999,
              borderTopRightRadius: 99999,
            }),
            ...(isDisabled && {
              color: theme.palette.black.light,
            }),
            ...(isSelected && {
              backgroundColor: theme.palette.primary.light,
            }),
          })}
        >
          <Box
            sx={(theme) => ({
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'default',
              color: theme.palette.black.dark,
              ...(isDisabled && {
                color: theme.palette.black.light,
              }),
              ...((isSelectionStart || isSelectionEnd) && {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.black.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }),
              ...(isSelected &&
                !(isSelectionStart || isSelectionEnd) && {
                  color: theme.palette.black.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                  },
                }),
              ...(!isSelected &&
                !isDisabled && {
                  '&:hover': {
                    backgroundColor: theme.palette.white.main,
                  },
                }),
              ...(isFocusVisible && {
                boxShadow: `inset 0 0 0 2px ${theme.palette.white.main}, 0 0 0 2px ${theme.palette.primary.main}`,
              }),
              ...(isUnavailable && {
                color: theme.palette.black.light,
                cursor: 'not-allowed',
                '&:hover': {
                  backgroundColor: 'transparent !important',
                },
              }),
            })}
          >
            {formattedDate}
          </Box>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default CalendarCell
