import { Box, LinearProgress, Typography } from '@mui/material'

import WellnessHalfDonutChart from 'components/HolisticPayroll/Commons/WellnessHalfDonutChart'

import { getWordSingular } from 'utils/format'

import Lifebuoy from 'assets/images/three-dimensional-icons/lifebuoy.png'

import { getFinancialWellnessDimensionsInfo } from './helpers'

export default function FinancialWellnessIndicators({ fetchedData = {} }) {
  const { financial_calculator_data } = fetchedData

  const financialWellnessDimensionsInfo = getFinancialWellnessDimensionsInfo(
    financial_calculator_data?.calculator_dimension_counter || {}
  )

  return (
    <Box
      sx={({ spacing, breakpoints }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: spacing(4),
        [breakpoints.up('sm')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [breakpoints.up('md')]: {
          gridTemplateColumns: '22rem 1fr',
        },
      })}
    >
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(1),
        })}
      >
        <Box
          sx={({ spacing }) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white.main',
            borderRadius: '0.5rem',
            padding: spacing(3),
            height: '100%',
            gap: spacing(1.5),
          })}
        >
          <Typography variant="h4">
            Indicador de Bienestar financiero
          </Typography>
          <WellnessHalfDonutChart
            value={
              financial_calculator_data?.average_financial_calculator_score || 0
            }
          />
          <Typography variant="h6">Puntaje promedio</Typography>
        </Box>

        <Box
          sx={({ spacing }) => ({
            display: 'flex',
            backgroundColor: 'white.main',
            borderRadius: '0.5rem',
            padding: spacing(1.5, 2),
            gap: spacing(2),
          })}
        >
          <Box component="img" alt="Personaje corriendo" src={Lifebuoy} />
          <Box>
            <Typography color="black.light">
              Personas usando el servicio:
            </Typography>
            <Typography
              variant="h4"
              color={
                !financial_calculator_data?.total_users
                  ? 'black.dark'
                  : 'accent4.main'
              }
            >
              {financial_calculator_data?.total_users || 0}{' '}
              {getWordSingular(
                'personas',
                financial_calculator_data?.total_users === 1
              )}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white.main',
          borderRadius: '0.5rem',
          padding: spacing(3),
          gap: spacing(2),
        })}
      >
        <Box
          sx={({ spacing }) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(1.5),
          })}
        >
          <Typography variant="h5">
            Dimensiones del indicador de bienestar financiero
          </Typography>
          <Typography color="black.light" variant="body2">
            Mira cómo va cada detalle de la empresa y entérate de cada
            movimiento de la platica para alcanzar esas metas propuestas por el
            equipo.
          </Typography>
        </Box>

        <Box
          sx={({ spacing }) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(2),
          })}
        >
          {financialWellnessDimensionsInfo.map((info) => (
            <Box
              key={info.id}
              sx={({ spacing }) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(0.5),
              })}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">{info.label}</Typography>
                <Typography
                  variant="lead2"
                  color={info.percentage === 0 ? 'black.dark' : 'accent4.main'}
                >
                  {info.percentage}%
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={info.percentage}
                sx={{
                  backgroundColor: 'white.dark',
                  borderRadius: '0.125rem',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: '0.125rem',
                    backgroundColor: info.linearBackgroundColor,
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
