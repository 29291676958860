import { Box, Button, Typography } from '@mui/material'

import Pattern from 'components/UI/Pattern'

import { isArusDist } from 'utils/auth'

const UpdatePaymentMethodButton = ({
  openUpdatePaymentMethodInfoModal,
  handleCloseMobileDrawer,
}) => {
  const handleOnClick = () => {
    handleCloseMobileDrawer()
    openUpdatePaymentMethodInfoModal({ actionMessage: 'invitation_to_update' })
  }

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '4rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(7.5),
        paddingX: theme.spacing(1),
        position: 'relative',
      })}
    >
      <Pattern
        patternColor="accent2.light"
        color="accent2.main"
        transform="scale(2.5)"
        patternSx={{
          top: '1.15rem',
        }}
      />
      <Button
        onClick={handleOnClick}
        sx={(theme) => ({
          paddingX: theme.spacing(3),
          zIndex: 1000,
        })}
      >
        <Typography
          variant="lead2"
          color={isArusDist() ? 'white.main' : 'black.main'}
        >
          Actualizar método de pago
        </Typography>
      </Button>
    </Box>
  )
}

export default UpdatePaymentMethodButton
