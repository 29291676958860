import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Box, Link, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import useIntegrationService from 'utils/hooks/settings/integrationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'
import { leadingZerosOrNotDigits, notAlphanumerics } from 'utils/regex'
import { arusIntegrationDocumentType } from 'utils/worker'

import * as routes from 'config/routes'

import messagesIntegration from 'messages/integration'
import messagesNotification from 'messages/notification'

import {
  BoldName,
  getInitialValues,
  getValidationSchema,
  integrationsProviders,
} from '../helpers'

const SettingsIntegrationModal = ({ state, handleClose, submitCallback }) => {
  const { open, integration } = state
  const { provider } = integration
  const messages = messagesIntegration[provider]
  const { showSuccessMessage, showInfoMessage } = useNotifications()
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()
  const { integrationsMutation } = useIntegrationService({
    queryOptions: {
      enabled: false,
    },
  })
  const {
    name,
    logo,
    logoWidth,
    logoHeight,
    description,
    signUpLink,
    usernameTooltip,
    passwordTooltip,
    usernameLabel,
    passwordLabel,
  } = integrationsProviders[provider]

  const handleSubmit = (values, form) => {
    let credentials = {}

    if (provider === 'arus') {
      credentials = {
        username: `${values.document_type}${values.id_number}`,
        token: values.token,
      }
    } else {
      credentials = {
        username: values.username,
        token: values.token,
      }
    }

    integrationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        integration: {
          ...credentials,
          provider,
          active: true,
        },
      },
      {
        onSuccess: () => {
          if (provider === 'alegra') {
            showInfoMessage(messages.how_configure_accounts)
          }
          showSuccessMessage(messagesNotification.INTEGRATION_ACTIVATION)
          trackEvent(integrationEvent.INTEGRATION_DONE, provider)
          if (submitCallback) {
            submitCallback()
          } else {
            navigate(routes.SETTINGS_INTEGRATION_INDEX())
          }
          handleClose()
        },
        onError: (error) => {
          handleError(error, form, {
            errorsToNotificate: [{ code: '0003' }],
            notistackOptions: {
              preventDuplicate: true,
            },
          })
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues(provider)}
      validationSchema={getValidationSchema(provider)}
      enableReinitialize
    >
      {(formProps) => {
        const { handleSubmit: onSubmit, values, setFieldValue } = formProps

        return (
          <Modal
            open={open}
            header={
              <Typography variant="h5">
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'normal',
                  }}
                >
                  Vamos a integrarnos con{' '}
                </Box>
                {name}
                <Box
                  component="img"
                  src={logo}
                  alt={`Isotipo de ${name}`}
                  width={logoWidth}
                  height={logoHeight}
                  sx={(theme) => ({
                    marginLeft: theme.spacing(1),
                    marginBottom: theme.spacing(-1),
                  })}
                />
              </Typography>
            }
            onOk={onSubmit}
            onCancel={handleClose}
            okText="Realizar integración"
            isLoading={integrationsMutation.isLoading}
            dialogProps={{
              maxWidth: 'sm',
              fullWidth: true,
            }}
          >
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: theme.palette.black.light,
                marginBottom: theme.spacing(3),
              })}
            >
              {description}
            </Typography>
            <Form>
              {provider !== 'arus' ? (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                  })}
                >
                  <FormField
                    name="username"
                    label={usernameLabel}
                    tooltipContent={usernameTooltip}
                    autoComplete="off"
                    data-cy={`${provider}-username`}
                    optional={false}
                  />
                  <FormField
                    type="password"
                    name="token"
                    label={passwordLabel}
                    tooltipContent={passwordTooltip}
                    autoComplete="off"
                    data-cy={`${provider}-token`}
                    optional={false}
                  />
                </Box>
              ) : (
                <>
                  <Box
                    sx={(theme) => ({
                      display: 'grid',
                      gridTemplateColumns: '45% 1fr',
                      gap: theme.spacing(3),
                    })}
                  >
                    <FormField
                      name="document_type"
                      label="Tipo de documento"
                      options={arusIntegrationDocumentType}
                      variant="select"
                      optional={false}
                    />
                    <FormField
                      name="id_number"
                      label="Número de documento"
                      optional={false}
                      onChange={(value) => {
                        if (!value.target.value) {
                          setFieldValue('id_number', null)
                        }

                        const cleanValue = value.target.value.replace(
                          values.document_type === 'PA'
                            ? notAlphanumerics
                            : leadingZerosOrNotDigits,
                          ''
                        )

                        setFieldValue('id_number', cleanValue)
                      }}
                    />
                    <Box sx={{ gridColumn: '1 / span 2' }}>
                      <FormField
                        type="password"
                        name="token"
                        label="Contraseña"
                        optional={false}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Form>
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.palette.black.light,
                marginTop: theme.spacing(3),
              })}
            >
              ¿Sin cuenta en <BoldName>{name}</BoldName>?{' '}
              <Link
                target="_blank"
                href={signUpLink}
                fontWeight={600}
                color="accent4.main"
              >
                Crea una cuenta aquí.
              </Link>
            </Typography>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default SettingsIntegrationModal
