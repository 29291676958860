import { Fragment } from 'react'

import { Box, TextField, Typography, useMediaQuery } from '@mui/material'

import DeleteItemButton from 'components/UI/Button/DeleteIconButton'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import { formatNumber } from 'utils/format'

import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import MobileCell from '../common/CardGrid/MobileCell'
import GroupDivider from '../common/GroupDivider'
import AddNewExtraHour from './NewExtraHour'
import { getColumnsWidth } from './helpers'

const OvertimeItem = ({
  data,
  category,
  onChange,
  onDeleteItem,
  activeNewExtraHour,
  handleShowAddExtraHour,
  handleHideExtraHour,
  novelty,
}) => {
  const isCompanyOvertime = novelty === 'others'
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const isLaptopUp = useMediaQuery((theme) => theme.breakpoints.up('laptop'))
  const columnsWidth = getColumnsWidth({
    isTabletUp,
    isLaptopUp,
    withDeleteButton: isCompanyOvertime,
  })
  const columns = [
    'Concepto',
    'Valor sobre hora ordinaria',
    '# de horas',
    ...(isCompanyOvertime && isTabletUp ? [''] : []),
  ]

  return (
    <>
      {Object.values(data).map((item, index) => {
        const isLastGridRow = index === Object.values(data).length - 1

        return (
          <Fragment key={item.coded_name}>
            <GridRow
              gutterBottom={!isLastGridRow}
              id={`overtime_${item.coded_name}`}
            >
              <HeaderCell
                sx={{
                  gridColumn: 1,
                  gridRow: 1,
                }}
                mobileOnly
              >
                Concepto
              </HeaderCell>
              <HeaderCell
                sx={{
                  gridColumn: '1 / span 2',
                  gridRow: 2,
                }}
                mobileOnly
                alignY="center"
              >
                Valor sobre hora ordinaria
              </HeaderCell>
              <HeaderCell
                sx={{
                  gridColumn: 1,
                  gridRow: 3,
                }}
                mobileOnly
                alignY="center"
              >
                # de horas
              </HeaderCell>
              <MobileCell
                alignX={isTabletUp ? 'left' : 'right'}
                sx={(theme) => ({
                  gridColumn: '2 / -1',
                  [theme.breakpoints.up('tablet')]: {
                    gridColumn: 1,
                  },
                })}
              >
                <Typography
                  id={`overtime_${item.coded_name}_name`}
                  variant="h6"
                  color="black.dark"
                >
                  {item.name}
                </Typography>
              </MobileCell>
              <MobileCell
                alignX={isTabletUp ? 'left' : 'right'}
                alignY="center"
                sx={(theme) => ({
                  gridColumn: '3 / -1',
                  [theme.breakpoints.up('tablet')]: {
                    gridColumn: 2,
                  },
                })}
              >
                <Typography
                  id={`overtime_${item.coded_name}_value`}
                  variant="h6"
                  color="black.dark"
                >
                  {formatNumber(item.value)}
                </Typography>
              </MobileCell>
              <MobileCell
                alignX={isTabletUp ? 'left' : 'right'}
                alignY="center"
                sx={(theme) => ({
                  gridColumn: '2 / -1',
                  [theme.breakpoints.up('tablet')]: {
                    gridColumn: 3,
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                  })}
                >
                  <TextField
                    id={`overtime_${item.coded_name}_quantity`}
                    name={`overtime_${item.coded_name}_quantity`}
                    value={item.quantity}
                    onChange={(e) => onChange(e, item.id, category)}
                    InputProps={{
                      options: { numeralIntegerScale: 3 },
                      inputComponent: NumberFormatField,
                      sx: {
                        height: '2rem',
                        width: '6rem',
                      },
                      inputProps: {
                        style: {
                          textAlign: 'left !important',
                        },
                      },
                    }}
                    placeholder="0,0"
                  />
                  {!isTabletUp && onDeleteItem ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <DeleteItemButton
                        onClick={() => onDeleteItem(item.id)}
                        deleteable={item.deleteable}
                        color="complementary1"
                      />
                    </Box>
                  ) : null}
                </Box>
              </MobileCell>
              {/* We repeat this to achieve the responsive layout */}
              {isTabletUp && onDeleteItem ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <DeleteItemButton
                    onClick={() => onDeleteItem(item.id)}
                    deleteable={item.deleteable}
                    color="complementary1"
                  />
                </Box>
              ) : null}
            </GridRow>
            {index < data.length - 1 ? <GroupDivider /> : null}
          </Fragment>
        )
      })}

      {isCompanyOvertime ? (
        <AddNewExtraHour
          isActive={activeNewExtraHour}
          handleShow={handleShowAddExtraHour}
          handleClose={handleHideExtraHour}
          columnsWidth={columnsWidth}
          columns={columns}
        />
      ) : null}
    </>
  )
}

export default OvertimeItem
