import http, { getApiURL } from '../httpService'

export const getLoandsById = (loanId, { page } = {}) => {
  const url = getApiURL({
    pathname: `/loans/${loanId}`,
    searchParams: { page },
  })

  return http.getV2(url)
}

export const getLoans = (workerId, { page, search } = {}) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/loans`,
    searchParams: { page, search },
  })

  return http.getV2(url)
}

export const updateLoans = ({ workerId, loans }) => {
  const url = getApiURL({ pathname: `/workers/${workerId}/loans` })

  const data = { loans }

  return http.putV2(url, data)
}

export default {
  getLoans,
  getLoandsById,
  updateLoans,
}
