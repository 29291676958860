import Page from 'components/UI/Page/Page'

import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'

import SettingsAccountingAccounts from './Accounts/Accounts'
import SettingsAccountingCostCenters from './CostCenters/CostCenters'
import SettingsAccountingSocialSecurityInstitutions from './SocialSecurityInstitutions/SocialSecurityInstitutions'

const SettingsAccounting = () => {
  const { companyConfigurationQuery } = useCompanyConfigurationService({
    serviceParams: { queryKey: 'companyConfiguration' },
  })

  return (
    <Page header="Contabilidad" isLoading={companyConfigurationQuery.isLoading}>
      <SettingsAccountingCostCenters />
      <SettingsAccountingAccounts
        configuration={companyConfigurationQuery?.data}
      />
      <SettingsAccountingSocialSecurityInstitutions
        configuration={companyConfigurationQuery?.data}
      />
    </Page>
  )
}

export default SettingsAccounting
