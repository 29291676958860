import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useNavigate } from 'react-router-dom'

import { Button, Menu, MenuItem } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import fileService from 'services/payroll/fileService'

import * as routes from 'config/routes'

import payrollMessages from 'messages/payroll'

import { usePeriod } from '../../helpers'
import { ActionContent } from '../helpers'
import DecreeSocialSecurityFileModal from './Modals/DecreeSocialSecurityFileModal'
import useDecreeSocialSecurityFileModal from './Modals/useDecreeSocialSecurityFileModal'

const SocialSecurityFileMenu = ({ fixedButtonStyle }) => {
  const {
    period,
    options: {
      social_security_file: socialSecurityFile,
      students_social_security_file: studentsSocialSecurityFile,
      decree376_periods: decree376Periods = [],
    },
  } = usePeriod()

  const { enqueueDownload } = useDownloadManager()
  const { handleError } = useErrorHandler()

  const confirm = useConfirm()
  const navigate = useNavigate()
  const hasDecree376Options =
    decree376Periods &&
    decree376Periods.length > 0 &&
    decree376Periods.some((decreePeriod) => decreePeriod.social_security_file)

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const {
    decreeSocialSecurityFileModalState,
    openDecreeSocialSecurityFileModal,
    closeDecreeSocialSecurityFileModal,
  } = useDecreeSocialSecurityFileModal(period.id)

  const handleDownloadPeriodFile = async (formType, fileType = 'txt') => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    const callback = () => {
      const studentsSocialSecurity =
        formType === 'studentsSocialSecurityFile' ? 'student_decree_055' : null

      trackEvent(integrationEvent.DOWNLOAD_FILE, {
        name: 'socialSecurity',
        type:
          formType === 'socialSecurityFile'
            ? 'employee'
            : studentsSocialSecurity,
      })
    }

    const handleErrorMising = (error) => {
      if (error.status === 402) {
        handleError(error)
      } else if (error.errors && error.errors[0].code === '0607') {
        confirm({
          type: 'info',
          okText:
            payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_OK_TEXT,
          title:
            payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_TITLE,
          description:
            payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_DESCRIPTION,
          onOk: () =>
            navigate(routes.WORKER_INDEX('?tab=active'), {
              state: { filter: 'not_finished' },
            }),
        })

        return true
      }

      return false
    }

    enqueueDownload({
      name:
        fileType === 'txt'
          ? 'archivo de pago de Seguridad Social'
          : 'archivo de validación para pago de Seguridad Social',
      fileCode: 'socialSecurityFile',
      firebasePath: `periods/${period.id}/social_security_file`,
      service: () => {
        if (formType === 'socialSecurityFile' && fileType === 'xlsx') {
          return fileService.getSocialSecurityFile(
            period.id,
            'employee',
            'xlsx'
          )
        } else if (
          formType === 'studentsSocialSecurityFile' &&
          fileType === 'xlsx'
        ) {
          return fileService.getSocialSecurityFile(
            period.id,
            'student_decree_055',
            'xlsx'
          )
        } else if (formType === 'socialSecurityFile') {
          return fileService.getSocialSecurityFile(period.id, 'employee', 'txt')
        } else if (formType === 'studentsSocialSecurityFile') {
          return fileService.getSocialSecurityFile(
            period.id,
            'student_decree_055',
            'txt'
          )
        }
      },
      callback,
      handleError: handleErrorMising,
    })
  }

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'socialSecurityFileMenu',
  })
  const popupBindTrigger = bindTrigger(popupState)

  const openPopover = (event) => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    popupBindTrigger.onClick(event)
  }

  const handleDownloadDecreePeriodFile = (decreePeriodId) => {
    openDecreeSocialSecurityFileModal(decreePeriodId)
    popupState.close()
  }

  return (
    <>
      {(socialSecurityFile && studentsSocialSecurityFile) ||
      hasDecree376Options ? (
        <>
          <Button
            variant="text"
            sx={fixedButtonStyle}
            startIcon={<Icon name="document" basic />}
            endIcon={
              showPremiumFeatures ? <Icon name="arrow-down" basic /> : undefined
            }
            {...popupBindTrigger}
            onClick={openPopover}
          >
            <ActionContent label="Archivo pago seguridad social" />
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {socialSecurityFile ? (
              <>
                <MenuItem
                  onClick={() => handleDownloadPeriodFile('socialSecurityFile')}
                >
                  Empleados
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleDownloadPeriodFile('socialSecurityFile', 'xlsx')
                  }
                >
                  Valida archivo de empleados
                </MenuItem>
              </>
            ) : null}
            {studentsSocialSecurityFile ? (
              <>
                <MenuItem
                  onClick={() =>
                    handleDownloadPeriodFile('studentsSocialSecurityFile')
                  }
                >
                  Estudiantes (Decreto 055)
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleDownloadPeriodFile(
                      'studentsSocialSecurityFile',
                      'xlsx'
                    )
                  }
                >
                  Valida archivo de estudiantes (Decreto 055)
                </MenuItem>
              </>
            ) : null}
            {decree376Periods.map((item) => {
              return item.social_security_file ? (
                <MenuItem
                  key={item.id}
                  onClick={() => handleDownloadDecreePeriodFile(item.id)}
                >
                  {`${item.label} (Decreto 376)`}
                </MenuItem>
              ) : null
            })}
          </Menu>
        </>
      ) : (
        <>
          {socialSecurityFile ? (
            <>
              <Button
                variant="text"
                sx={fixedButtonStyle}
                startIcon={<Icon name="document" basic />}
                onClick={() => handleDownloadPeriodFile('socialSecurityFile')}
              >
                <ActionContent label="Archivo pago seguridad social" />
              </Button>
              <Button
                startIcon={<Icon name="download" basic />}
                variant="text"
                sx={fixedButtonStyle}
                onClick={() =>
                  handleDownloadPeriodFile('socialSecurityFile', 'xlsx')
                }
              >
                <ActionContent label="Valida resumen de seguridad social" />
              </Button>
            </>
          ) : null}
          {studentsSocialSecurityFile ? (
            <>
              <Button
                variant="text"
                sx={fixedButtonStyle}
                startIcon={<Icon name="document" basic />}
                onClick={() =>
                  handleDownloadPeriodFile('studentsSocialSecurityFile')
                }
              >
                <ActionContent label="Archivo pago seguridad social (Estudiantes)" />
              </Button>
              <Button
                startIcon={<Icon name="download" basic />}
                variant="text"
                sx={fixedButtonStyle}
                onClick={() =>
                  handleDownloadPeriodFile('studentsSocialSecurityFile', 'xlsx')
                }
              >
                <ActionContent label="Valida resumen de seguridad social (Estudiantes)" />
              </Button>
            </>
          ) : null}
        </>
      )}
      {decreeSocialSecurityFileModalState.open ? (
        <DecreeSocialSecurityFileModal
          state={decreeSocialSecurityFileModalState}
          handleClose={closeDecreeSocialSecurityFileModal}
        />
      ) : null}
    </>
  )
}

export default SocialSecurityFileMenu
