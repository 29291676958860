import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

const CardButton = ({ payrollConceptValue, onClick, editable, dataCy }) => (
  <Button
    size="small"
    endIcon={
      payrollConceptValue ? (
        <Icon name="edit-pencil" basic sx={{ fontSize: '1.5rem !important' }} />
      ) : (
        <Icon name="arrow-right" basic sx={{ fontSize: '1.5rem !important' }} />
      )
    }
    variant="outlined"
    onClick={onClick}
    disabled={!editable}
    data-cy={dataCy}
  >
    {payrollConceptValue ? 'Editar' : 'Ingresar'}
  </Button>
)

export default CardButton
