import { useSnackbar } from 'notistack'

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showSuccessMessage = (message, opts) => {
    if (message) {
      return enqueueSnackbar(message, {
        ...opts,
        variant: 'success',
      })
    }
  }

  const showErrorMessage = (message, opts) => {
    if (message) {
      return enqueueSnackbar(message, {
        ...opts,
        variant: 'error',
      })
    }
  }

  const showInfoMessage = (message, opts) => {
    if (message) {
      return enqueueSnackbar(message, {
        ...opts,
        variant: 'info',
      })
    }
  }

  const showWarningMessage = (message, opts) => {
    if (message) {
      return enqueueSnackbar(message, {
        ...opts,
        variant: 'warning',
      })
    }
  }

  return {
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
    showWarningMessage,
  }
}

export default useNotifications
