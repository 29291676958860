import { Box, Button, Card, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

const EditWageModal = ({ open, onClose, onEdit, onCreate }) => {
  return (
    <Modal
      header="Editar salarios"
      open={open}
      onCancel={onClose}
      hideFooter
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
      }}
    >
      <div>
        <Typography>
          Acá puedes editar el salario, recuerda tener presente que un cambio en
          el salario actual afectará todas las nóminas anteriores, incluso las
          que ya están cerradas.
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: theme.spacing(3),
            marginTop: theme.spacing(5),
            [theme.breakpoints.up('sm')]: {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
          })}
        >
          <Card
            sx={(theme) => ({
              minHeight: '14rem',
              display: 'flex',
              flexDirection: 'column',
              padding: theme.spacing(3),
              justifyContent: 'space-between',
            })}
          >
            <Box>
              <Typography variant="h5" gutterBottom>
                Editar los datos del salario actual
              </Typography>
              <Typography>
                En caso de que desees modificar la información actual del
                salario de la persona modificando toda la información histórica.
                Recomendado si en algún momento digitaste el salario equivocado.
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: theme.spacing(6),
              })}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  onClose()
                  onEdit()
                }}
              >
                Editar
              </Button>
            </Box>
          </Card>
          <Card
            sx={(theme) => ({
              minHeight: '14rem',
              display: 'flex',
              flexDirection: 'column',
              padding: theme.spacing(3),
              justifyContent: 'space-between',
            })}
          >
            <Box>
              <Typography variant="h5" gutterBottom>
                Crear un nuevo salario
              </Typography>
              <Typography>
                En caso de que desees crear un nuevo salario para la persona, el
                cual comenzará a aplicar desde la fecha inicial que elijas.
                Recomendado para aumentos o modificaciones de salario.
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: theme.spacing(6),
              })}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  onClose()
                  onCreate()
                }}
              >
                Crear
              </Button>
            </Box>
          </Card>
        </Box>
      </div>
    </Modal>
  )
}

export default EditWageModal
