import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useCompanyCredentialsService from 'utils/hooks/company/credentials'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import {
  getInitialValues,
  getInvalidCredentialEntities,
  getValidationSchema,
  renderAffiliationCredentialsFields,
} from './helpers'

const AffiliationCredentialsModalContent = ({
  workerId,
  handleClose,
  setLoadingModal,
}) => {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const affiliationQueryKey = ['getAffiliationById', workerId]
  const companyId = getCompanyId()

  const {
    affiliationsQuery: { data: affiliationsData, isLoading: queryIsLoading },
    affiliationsMutation,
  } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId,
    },
  })

  const {
    rejected_comment: rejectionComment,
    created_at: affiliationCreationDate,
    rejected_at: rejectionDate,
    certificates_data: certificatesData,
  } = affiliationsData || {}

  const handleAffiliationsMutation = (correctedData) => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData: correctedData,
      },
      {
        onSuccess: async () => {
          setLoadingModal(false)
          await queryClient.invalidateQueries(affiliationQueryKey)
          await queryClient.invalidateQueries([
            'getCompanyOnboardings',
            companyId,
          ])
          handleClose()
        },
        onError: (error) => {
          setLoadingModal(false)
          handleError(error)
        },
      }
    )
  }

  const { companyCredentialsMutation } = useCompanyCredentialsService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = (values) => {
    setLoadingModal(true)
    const correctedAffiliationData = new FormData()
    correctedAffiliationData.append('status', 'waiting_response')

    companyCredentialsMutation.mutate(
      {
        mutationMethod: 'PUT',
        credentialsData: {
          company_credentials: Object.values(values),
        },
      },
      {
        onSuccess: () => handleAffiliationsMutation(correctedAffiliationData),
        onError: (error) => {
          setLoadingModal(false)
          handleError(error)
        },
      }
    )
  }

  const invalidCredentials = getInvalidCredentialEntities(certificatesData)
  const moreThanOne = invalidCredentials?.length > 1

  const mutationIsLoading =
    companyCredentialsMutation.isLoading || affiliationsMutation.isLoading

  return (
    <>
      {queryIsLoading || invalidCredentials.length === 0 ? (
        <LoadingBox />
      ) : (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          })}
        >
          <Typography variant="h4" color="accent4.dark">
            Fecha de creación:{' '}
            {formatDisplayDateString(affiliationCreationDate)}
          </Typography>
          <Typography color="black.dark">
            Necesitamos las credenciales de acceso a{' '}
            {moreThanOne
              ? 'tus cuentas con las siguientes entidades para gestionar estas afiliaciones'
              : 'tu cuenta con la siguiente entidad para gestionar esta afiliación'}
            . ¡Relax! Esta información está 100% segura con nosotros.
          </Typography>
          {rejectionComment ? (
            <Typography color="black.dark">
              <b>Comentario adicional:</b> {rejectionComment}
            </Typography>
          ) : null}
          <Typography color="black.dark">
            <b>Fecha del rechazo:</b> {formatDisplayDateString(rejectionDate)}
          </Typography>
          <Formik
            initialValues={getInitialValues(invalidCredentials)}
            validationSchema={getValidationSchema(invalidCredentials)}
            onSubmit={handleSubmit}
          >
            <Form>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                })}
              >
                {renderAffiliationCredentialsFields(certificatesData)}
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: theme.spacing(2),
                  marginTop: theme.spacing(3),
                  [theme.breakpoints.down('tablet')]: {
                    flexDirection: 'column',
                  },
                })}
              >
                <Button type="submit" loading={mutationIsLoading}>
                  Enviar información
                </Button>
                <Button onClick={handleClose} variant="outlined">
                  Cancelar
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      )}
    </>
  )
}

export default AffiliationCredentialsModalContent
