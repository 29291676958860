/**
 * Used in conjunction with OvertimeModal
 */
const useOvertimeConfig = () => {
  const getOvertimeConcepts = (payrollConcepts = {}, overtimeData = []) => {
    const currentExtraHours = [...payrollConcepts?.extra_hours]
    const currentOtherConcepts = [...payrollConcepts?.others]
    const currentSurcharges = [...payrollConcepts?.surcharges]

    overtimeData.forEach((overtimeItem) => {
      currentExtraHours.forEach((extraHourItem, index) => {
        if (extraHourItem.id === overtimeItem.payroll_concept_id) {
          currentExtraHours[index] = {
            ...extraHourItem,
            quantity: overtimeItem.quantity,
            itemId: overtimeItem.id,
          }
        }
      })

      currentSurcharges.forEach((surchargeItem, index) => {
        if (surchargeItem.id === overtimeItem.payroll_concept_id) {
          currentSurcharges[index] = {
            ...surchargeItem,
            quantity: overtimeItem.quantity,
            itemId: overtimeItem.id,
          }
        }
      })

      currentOtherConcepts.forEach((otherConceptItem, index) => {
        if (otherConceptItem.id === overtimeItem.payroll_concept_id) {
          currentOtherConcepts[index] = {
            ...otherConceptItem,
            quantity: overtimeItem.quantity,
            itemId: overtimeItem.id,
          }
        }
      })
    })

    return {
      extra_hours: currentExtraHours,
      others: currentOtherConcepts,
      surcharges: currentSurcharges,
    }
  }

  const addNewOvertimeItem = (
    currentData,
    category,
    itemConceptId,
    itemConceptQuantity
  ) => {
    const newCategoryData = currentData[category].reduce(
      (currentArray, item) => {
        currentArray.push(
          item.id === itemConceptId
            ? {
                ...item,
                quantity: itemConceptQuantity,
              }
            : item
        )

        return currentArray
      },
      []
    )

    return newCategoryData
  }

  return {
    getOvertimeConcepts,
    addNewOvertimeItem,
  }
}

export default useOvertimeConfig
