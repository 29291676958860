import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

import messages from 'messages/wage'

const EndDayField = () => {
  const { values } = useFormikContext()

  return values.end_day ? (
    <FormField
      name="end_day"
      label="Fecha final salario"
      variant="datepicker"
      disabled={!values.initial_day}
      minDate={values.initial_day}
      tooltipContent={messages.end_day}
    />
  ) : null
}

export default EndDayField
