import { Box, Typography } from '@mui/material'

const SummaryTotal = ({ label, value, items = [] }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: theme.spacing(3.5),
      })}
    >
      <Typography
        variant="body1"
        sx={{ color: 'black.light' }}
        gutterBottom={items.length > 0}
      >
        {label}
      </Typography>
      {items.length > 0 &&
        items.map((item) => (
          <Box key={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ color: 'black.light' }}>
              {item.label}
            </Typography>
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: 'black.light',
                fontWeight: 'bold',
                marginLeft: theme.spacing(1.5),
              })}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      <Typography
        variant="h4"
        sx={(theme) => ({ marginTop: theme.spacing(3.5) })}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default SummaryTotal
