import { useMutation } from 'react-query'

import {
  getImportWorkersFile,
  uploadWorkersFile,
} from 'services/worker/importService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, fileData } = mutationData

  if (mutationMethod === 'GET') return getImportWorkersFile()

  if (mutationMethod === 'POST') return uploadWorkersFile(fileData)

  return null
}

const useImportWorkersFileService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()

  const importWorkersFileMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    importWorkersFileMutation,
  }
}

export default useImportWorkersFileService
