import { Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getAllCompanies } from 'services/company/companyService'

import NewCompanyModal from './Modals/NewCompanyModal'
import useNewCompanyModal from './Modals/useNewCompanyModal'
import getColumnsData from './columnsData'

const CompanyIndex = () => {
  const { newCompanyModalState, openNewCompanyModal, closeNewCompanyModal } =
    useNewCompanyModal()

  const { updateUser, changeCompany } = useUser()

  const fetchCompany = (pageIndex, search) => {
    return {
      queryKey: ['getAllCompanies', pageIndex + 1, search],
      queryFunction: () => getAllCompanies({ page: pageIndex + 1, search }),
    }
  }

  const columnsData = getColumnsData(updateUser, changeCompany)

  return (
    <Page header="Empresas">
      <Typography paragraph>
        Este listado de empresas te permite elegir a cuál quieres ingresar.
        Recuerda que en cada una tendrás acceso de acuerdo con el perfil que
        tengas y solo podrás ingresar si estás activo.
      </Typography>
      <Table
        columns={columnsData}
        data={fetchCompany}
        actions={[
          {
            icon: <Icon name="plus" />,
            tooltip: 'Crear nueva empresa',
            isFreeAction: true,
            onClick: () => openNewCompanyModal(true),
          },
        ]}
        data-cy="companies-table"
      />
      {newCompanyModalState.open ? (
        <NewCompanyModal
          state={newCompanyModalState}
          handleClose={closeNewCompanyModal}
        />
      ) : null}
    </Page>
  )
}

export default CompanyIndex
