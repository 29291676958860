import { useState } from 'react'

const useBackgroundCheckModal = () => {
  const [state, setState] = useState({
    key: '',
    open: false,
    workerId: null,
    workerName: null,
    onboardingId: null,
    backgroundCheck: {},
  })

  const closeBackgroundCheckModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openBackgroundCheckModal = ({
    workerId,
    workerName,
    onboardingId,
    backgroundCheck,
  }) => {
    setState((previousState) => ({
      ...previousState,
      key: Math.random().toString(36).substr(2, 9),
      open: true,
      workerId,
      workerName,
      onboardingId,
      backgroundCheck,
    }))
  }

  return {
    backgroundCheckModalState: state,
    openBackgroundCheckModal,
    closeBackgroundCheckModal,
  }
}

export default useBackgroundCheckModal
