import { parseISO } from 'date-fns'
import * as yup from 'yup'

import { getCompanyId } from 'utils/company'
import { eachDayOfInterval } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const accountingFileValidationSchema = yup.object({
  provider_id: yup.object().nullable().required(),
  period_option: yup.string(),
  file_options: yup
    .array(yup.string())
    .min(1, 'Selecciona al menos un tipo de archivo')
    .required(),
})

export const getOvertimeConcepts = (
  payrollConcepts = {},
  overtimeData = []
) => {
  const currentExtraHours = [...(payrollConcepts?.extra_hours || [])]
  const currentOtherConcepts = [...(payrollConcepts?.others || [])]
  const currentSurcharges = [...(payrollConcepts?.surcharges || [])]

  overtimeData.forEach((overtimeItem) => {
    currentExtraHours.forEach((extraHourItem, index) => {
      if (extraHourItem.id === overtimeItem.payroll_concept_id) {
        currentExtraHours[index] = {
          ...extraHourItem,
          quantity: overtimeItem.quantity,
          itemId: overtimeItem.id,
        }
      }
    })

    currentSurcharges.forEach((surchargeItem, index) => {
      if (surchargeItem.id === overtimeItem.payroll_concept_id) {
        currentSurcharges[index] = {
          ...surchargeItem,
          quantity: overtimeItem.quantity,
          itemId: overtimeItem.id,
        }
      }
    })

    currentOtherConcepts.forEach((otherConceptItem, index) => {
      if (otherConceptItem.id === overtimeItem.payroll_concept_id) {
        currentOtherConcepts[index] = {
          ...otherConceptItem,
          quantity: overtimeItem.quantity,
          itemId: overtimeItem.id,
        }
      }
    })
  })

  return {
    extra_hours: currentExtraHours,
    others: currentOtherConcepts,
    surcharges: currentSurcharges,
  }
}

export const addNewOvertimeItem = (
  currentData,
  category,
  itemConceptId,
  itemConceptQuantity
) => {
  const newCategoryData = currentData[category].reduce((currentArray, item) => {
    currentArray.push(
      item.id === itemConceptId
        ? {
            ...item,
            quantity: itemConceptQuantity,
          }
        : item
    )

    return currentArray
  }, [])

  return newCategoryData
}

export const getOvertimeValidationSchema = (periodType) => {
  let workerTimeValidate = {}
  if (periodType === 'monthly') {
    workerTimeValidate = yup
      .number()
      .integer()
      .min(0)
      .max(29, 'El valor debe ser menor o igual a 29')
  } else {
    workerTimeValidate = yup
      .number()
      .integer()
      .min(0)
      .max(15, 'El valor debe ser menor o igual a 15')
  }

  return yup.object({
    name: yup.string().trim().min(3).required(),
    constant_value: yup
      .number()
      .max(10, 'El valor sobre la hora ordinaria no debe ser mayor de 10')
      .moreThan(0, 'El valor debe ser mayor que 0')
      .required(),
    quantity: yup
      .number()
      .lessThan(100, 'El valor debe ser menor que 100')
      .moreThan(0, 'El valor debe ser mayor que 0'),
    worked_time: workerTimeValidate,
  })
}

export const getOvertimeDataToSend = (initData = {}, currData = {}) => {
  const dataToSend = []
  const categories = ['extra_hours', 'surcharges', 'others']

  categories.forEach((category) => {
    const initCategoryData = initData[category] || []
    const currCategoryData = currData[category] || []

    for (let i = 0; i < currCategoryData.length; i += 1) {
      const { quantity } = currCategoryData[i]
      const { quantity: initialQuantity } = initCategoryData[i] || {}

      if (quantity !== undefined) {
        if (initialQuantity === undefined && quantity !== 0) {
          dataToSend.push({
            payroll_concept_id: currCategoryData[i].id,
            quantity,
          })
        }

        if (initialQuantity !== undefined && quantity !== initialQuantity) {
          dataToSend.push({
            id: currCategoryData[i].itemId,
            quantity,
          })
        }
      }
    }
  })

  return dataToSend
}

export const getNoveltiesItems = (
  noveltiesConcepts = {},
  payrollNovelties = []
) => {
  const novelties = {} // Includes novelty concept data with items by category

  // This assigns every novelty item with the respective novelty concept
  Object.entries(noveltiesConcepts).forEach(([category, conceptsGroup]) => {
    const temporalConcepts = {}
    conceptsGroup.map((concept) => {
      // Ignore compensated days
      if (concept.coded_name !== 'compensated_days') {
        const newConcept = {
          ...concept,
          items: [],
        }

        payrollNovelties.forEach((payrollNovelty) => {
          if (payrollNovelty.payroll_concept_id === newConcept.id) {
            newConcept.items.push(payrollNovelty)
          }
        })

        if (newConcept.items.length === 0) {
          newConcept.items.push({
            initial_day: '',
            end_day: '',
          })
        }

        temporalConcepts[newConcept.coded_name] = newConcept
      }
      return null
    })

    novelties[category] = temporalConcepts
  })

  return novelties
}

// Filters only novelties items with selected dates
export const getNoveltiesSelectedDays = (novelties = {}) => {
  const currentNovelties = {}

  Object.entries(novelties).forEach(
    ([noveltyCategory, noveltyCategoryGroup]) => {
      if (Object.prototype.hasOwnProperty.call(novelties, noveltyCategory)) {
        currentNovelties[noveltyCategory] = {}

        Object.entries(noveltyCategoryGroup).forEach(
          ([noveltyCode, noveltyGroup]) => {
            if (
              Object.prototype.hasOwnProperty.call(
                noveltyCategoryGroup,
                noveltyCode
              )
            ) {
              currentNovelties[noveltyCategory][noveltyCode] = []

              noveltyGroup.items.forEach((noveltyItem) => {
                const { initial_day: initialDay, end_day: endDay } = noveltyItem

                if (initialDay && endDay) {
                  currentNovelties[noveltyCategory][noveltyCode].push(
                    eachDayOfInterval(parseISO(initialDay), parseISO(endDay))
                  )
                } else if (initialDay) {
                  currentNovelties[noveltyCategory][noveltyCode].push([
                    parseISO(initialDay),
                  ])
                } else if (endDay) {
                  currentNovelties[noveltyCategory][noveltyCode].push([
                    parseISO(endDay),
                  ])
                }
              })
            }
          }
        )
      }
    }
  )

  return currentNovelties
}

export const backgroundBulkNoveltiesSocketHandler = (
  websocketResult,
  connectionRef,
  showErrorMessage,
  openBulkNoveltiesAlert,
  queryClient
) => {
  if (websocketResult) {
    if (websocketResult.errors) {
      const { error_type: errorType } = websocketResult.errors

      if (!errorType) {
        showErrorMessage(
          'Lo sentimos. Ocurrió un error y no logramos cargar tus novedades. Inténtalo de nuevo más tarde o contacta con el equipo de soporte.',
          { preventDuplicate: true }
        )
      } else {
        openBulkNoveltiesAlert({
          websocketResult,
          showErrorModal: true,
          messageResponse: 'error',
        })
      }
    }

    if (websocketResult.success) {
      const {
        success_workers_count: successWorkers,
        file_with_errors: fileWithErrors,
        failure_cases_total_count: totalFailureCases,
        period_id: periodId,
      } = websocketResult.success

      if (!successWorkers && !fileWithErrors) {
        showErrorMessage(
          'Lo sentimos. Ocurrió un error y no logramos cargar tus novedades. Verifica que hayas agregado datos en el archivo y que estos sean correctos. Si el error persiste, contacta con el equipo de soporte.',
          { preventDuplicate: true }
        )
        return
      }

      queryClient.invalidateQueries([
        'periodPayrolls',
        getCompanyId(),
        periodId,
      ])

      const getResultStatus = () => {
        if (!successWorkers) {
          if (totalFailureCases === 1) return 'not_success_cases_single_error'
          return 'not_success_cases'
        }

        if (fileWithErrors) {
          if (totalFailureCases === 1) return 'success_with_error'
          return 'success_with_errors'
        }

        return 'success_without_errors'
      }

      const keysOrder = [
        'overtime',
        'novelties',
        'licenses',
        'incomes',
        'deductions',
        'personal_note',
        'failure_cases_total_count',
        'file_with_errors',
        'period_id',
        'success_workers_count',
        'success_workers_ids',
        'current_period',
      ]

      const orderedObject = {}

      keysOrder.forEach((key) => {
        orderedObject[key] = websocketResult.success[key]
      })

      openBulkNoveltiesAlert({
        websocketResult: { success: orderedObject },
        showSummaryModal: true,
        messageResponse: getResultStatus(),
      })
    }

    connectionRef.off('value')
  }
}

export default accountingFileValidationSchema
