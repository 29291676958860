import { forwardRef, useEffect, useState } from 'react'

import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
  linearProgressClasses,
} from '@mui/material'

const LoadedPictureContent = forwardRef(
  function LoadedPictureContentForwardRef(props, inputRef) {
    const { fileName, value, applyBorderRadius } = props

    return (
      <Box
        ref={inputRef}
        sx={(theme) => ({
          width: '100%',
          height: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: theme.spacing(1),
        })}
      >
        <Box
          component="img"
          src={value}
          sx={{
            objectFit: applyBorderRadius ? 'cover' : 'contain',
            height: '3.5rem',
            width: '3.5rem',
            borderRadius: applyBorderRadius ? '50%' : 0,
          }}
        />
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            rowGap: theme.spacing(0.3),
            flexDirection: 'column',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          })}
        >
          <Typography variant="lead1">
            {value?.name || fileName || ''}
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              columnGap: theme.spacing(1),
            })}
          >
            <LinearProgress
              variant="determinate"
              value={100}
              sx={(theme) => ({
                width: '90%',
                borderRadius: '5rem',
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: theme.palette.primary.light,
                },
              })}
            />
            <Typography variant="small" color="primary.dark">
              100%
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }
)

const LoadedPicture = ({ ...props } = {}) => {
  const {
    id,
    name,
    disabled,
    accept, // This prop controls the selection when using the file selector
  } = props.pictureProps
  const [imagePreview, setImagePreview] = useState(props.value)

  useEffect(() => {
    if (props.value instanceof File) {
      const reader = new FileReader()

      reader.onloadend = () => {
        setImagePreview(reader.result)
      }

      if (props.value) {
        reader.readAsDataURL(props.value)
      }
    }
  }, [props.value])

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        alignItems: 'center',
        [theme.breakpoints.down('tablet')]: {
          gridTemplateColumns: '1fr',
        },
      })}
    >
      <TextField
        InputProps={{
          inputComponent: forwardRef(
            function InputComponentForwardRef(innerProps, inputRef) {
              return (
                <LoadedPictureContent
                  ref={inputRef}
                  {...innerProps}
                  {...props}
                  value={imagePreview}
                />
              )
            }
          ),
          sx: {
            height: '4.5rem',
          },
        }}
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          marginLeft: theme.spacing(2),
          [theme.breakpoints.down('tablet')]: {
            marginLeft: theme.spacing(0),
          },
        })}
      >
        <Button
          variant="text"
          onClick={props.handleDeleteFile}
          sx={{
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Eliminar
        </Button>
        <Box
          component="input"
          id={id}
          name={name}
          type="file"
          ref={props.fileInputRef}
          accept={accept}
          onChange={props.onFileAdded}
          disabled={disabled}
          data-cy="file-input"
          sx={{
            display: 'none',
          }}
        />
        <Button
          variant="text"
          onClick={props.openFileDialog}
          sx={{
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Actualizar
        </Button>
      </Box>
      <Box />
    </Box>
  )
}

export default LoadedPicture
