import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import SectionCard from '../SectionCard'

const UVTConfiguration = ({ options }) => {
  return (
    <Box sx={(theme) => ({ marginTop: theme.spacing(4) })}>
      <SectionCard subtitle="Configuración de UVTs para retención en la fuente">
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            width: '100%',
          })}
        >
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(2) })}
          >
            Puedes conservar los límites mensuales de UVTs preestablecidos por
            Aleluya o puedes usar libremente los UVTs mes a mes sin superar el
            límite anual legal.
          </Typography>
          <Box
            sx={(theme) => ({
              marginBottom: theme.spacing(1),
            })}
          >
            <Typography
              variant="body1"
              fontWeight={700}
              sx={(theme) => ({ marginBottom: theme.spacing(0.5) })}
            >
              Límites preestablecidos:
            </Typography>
            <Box
              component="ul"
              sx={(theme) => ({
                listStyleType: 'square',
                marginTop: 0,
                paddingLeft: theme.spacing(3),
              })}
            >
              <li>
                <b>
                  Renta exenta de acuerdo al numeral 10, artículo 206 ET
                  (790UVTs anuales o límite mensual de 65,83333)
                </b>
              </li>
              <li>
                <b>
                  Valor sobre el límite del 40% en las deducciones y rentas
                  exentas (1.340UVTs anuales o límite mensual de 111,6667)
                </b>
              </li>
            </Box>
          </Box>
          <FormField
            name="uvt_configuration"
            variant="radio-group"
            options={options}
            row
          />
        </Box>
      </SectionCard>
    </Box>
  )
}

export default UVTConfiguration
