import { Form } from 'formik'
import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import Tooltip from 'components/UI/Tooltip'

import useCompanyService from 'utils/hooks/organizer/company'

export const getColumnsData = () => {
  return [
    {
      Header: 'Nota',
      accessor: 'note',
      Cell: ({ row }) => (
        <Tooltip description={row.original.note}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="body2"
          >
            {row.original.note}
          </Typography>
        </Tooltip>
      ),
      customWidth: '35rem',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
  ]
}

export const getActions = ({
  handleShowComment,
  handleCreateComment,
  handleUpdateComment,
}) => {
  return [
    (rowData) => ({
      id: 'showComment',
      tooltip: 'Ver Comentario',
      icon: <Icon name="eye" basic />,
      onClick: () => handleShowComment(rowData),
    }),
    (rowData) => ({
      id: 'editComment',
      tooltip: 'Editar comentario',
      icon: <Icon name="edit-pencil" basic />,
      disabled: !rowData.editable,
      onClick: () => handleUpdateComment(rowData),
    }),
    {
      id: 'createComment',
      tooltip: 'Agregar un comentario',
      isFreeAction: true,
      onClick: () => handleCreateComment(),
    },
  ]
}

export const getInitialValues = (commentData = {}) => {
  return {
    id: commentData.id || '',
    note: commentData.note || '',
  }
}

export const getValidationSchema = () => {
  return yup.object({
    note: yup.string().required(),
  })
}

const FormFieldTextarea = () => {
  return (
    <Form>
      <FormField
        name="note"
        variant="textarea"
        label="Comentario"
        rows="4"
        inputProps={{
          maxLength: 500,
        }}
      />
    </Form>
  )
}

export const useActionToRender = ({
  commentData = {},
  action,
  companyId,
  callback,
}) => {
  const { note } = commentData

  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleCreateComment = (values) => {
    companyMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'createComment',
        companyId,
        commentData: {
          note: values.note,
        },
      },
      {
        onSuccess: () => {
          callback('creado')
        },
      }
    )
  }

  const handleUpdateComment = (values) => {
    companyMutation.mutate(
      {
        mutationMethod: 'PATCH',
        mutationKey: 'updateComment',
        companyId,
        commentId: values.id,
        commentData: {
          note: values.note,
        },
      },
      {
        onSuccess: () => {
          callback('actualizado')
        },
      }
    )
  }

  const actions = {
    show: {
      headerLabel: 'Detalle del comentario',
      okLabel: '',
      cancelLabel: 'Cerrar',
      Content: () => (
        <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
          <Typography variant="body2" color="black.dark">
            {note}
          </Typography>
        </Box>
      ),
      handleClick: null,
    },
    create: {
      headerLabel: 'Crear comentario',
      okLabel: 'Crear',
      cancelLabel: 'Cancelar',
      Content: () => <FormFieldTextarea />,
      handleClick: (values) => handleCreateComment(values),
    },
    update: {
      headerLabel: 'Actualizar comentario',
      okLabel: 'Actualizar',
      cancelLabel: 'cancelar',
      Content: () => <FormFieldTextarea />,
      handleClick: (values) => handleUpdateComment(values),
    },
  }

  return { ...actions[action], isLoading: companyMutation.isLoading }
}
