import { useState } from 'react'

const useBackgroundCheckInitialConsultModal = () => {
  const [state, setState] = useState({
    open: false,
    backgroundCheck: {},
    onboardingId: null,
    workerName: null,
    workerId: null,
  })

  const closeBackgroundCheckInitialConsultModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openBackgroundCheckInitialConsultModal = ({
    backgroundCheck,
    onboardingId,
    workerName,
    workerId,
  }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      backgroundCheck,
      onboardingId,
      workerName,
      workerId,
    }))
  }

  const resetBackgroundCheckInitialConsultModalState = () => {
    setState((previousState) => ({
      ...previousState,
      backgroundCheck: {},
      onboardingId: null,
      workerName: null,
      workerId: null,
    }))
  }

  return {
    backgroundCheckInitialConsultState: state,
    closeBackgroundCheckInitialConsultModal,
    openBackgroundCheckInitialConsultModal,
    resetBackgroundCheckInitialConsultModalState,
  }
}

export default useBackgroundCheckInitialConsultModal
