/* eslint-disable no-param-reassign */
import {
  ErrorBoundary,
  browserProfilingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react'
import { useSnackbar } from 'notistack'
import { Component, createContext, useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom'

import auth from 'utils/auth'
import { isDev, isObjectEmpty } from 'utils/general'

import FetchError from 'services/util/FetchError'

import * as routes from 'config/routes'

import ErrorPage from './ErrorPage'
import evaluateError from './helpers'

export const ErrorHandlerContext = createContext({})

const initState = { error: null }

// Initialize Sentry
init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0,
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      enableInp: true,
    }),
    browserProfilingIntegration(),
    replayIntegration({
      maskAllText: false,
    }),
  ],
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.25 : 1.0,
  profilesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',
  release:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_RELEASE_VERSION
      : 'dev',
  ignoreErrors: [
    `SyntaxError: Unexpected token '<'`,
    `Uncaught SyntaxError: Unexpected token '<'`,
    `TypeError: Cannot read properties of undefined (reading '_avast_submit')`,
    `SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.`,
    'ChunkLoadError: Loading chunk',
    'UnhandledRejection: Non-Error',
    `NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
    'SyntaxError: Unexpected token <',
    `NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
    'EvalError: Possible side-effect in debug-evaluate',
    'NS_ERROR_FAILURE',
    'TypeError: Failed to fetch',
    `SyntaxError: expected expression, got '<'`,
    'Error al restablecer contraseña',
    'a: Unauthorized',
    'a: Unprocessable Entity',
    'a: No error message',
    'ReferenceError: showCsCursorNonHD is not defined',
    'ReferenceError: openTab is not defined',
    'TypeError: Load failed',
    'SnapTube',
    'a: Unprocessable',
    'TypeError: NetworkError when attempting to fetch resource.',
    `Cannot read property '0' of undefined`,
    `Identifier 'originalPrompt' has already been declared`,
    'a: Bad Request',
    `Failed to execute 'replaceState' on 'History': A history state object with URL`,
    `Cannot read properties of undefined (reading 'slice')`,
    'out of memory',
    'The object can not be found here.',
    'SecurityError: The operation is insecure.',
    'Network request failed',
    'ReferenceError: requestAnimationFrame is not defined',
    'ibPauseAllVideos is not defined',
    `null is not an object (evaluating 'this.iframe.contentWindow')`,
    `Failed to set the 'cookie' property on 'Document': Access is denied for this document.`,
    'AbortError: The operation was aborted.',
    'a: Not Found',
    `Failed to read the 'contentDocument' property from 'HTMLIFrameElement':`,
    `TypeError: Failed to set the 'src' property on 'HTMLScriptElement': This document requires 'TrustedScriptURL' assignment.`,
    'a: Payment Required',
    `TypeError: null is not an object (evaluating 'this.iframe.contentWindow')`,
    `TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers')`,
    `"undefined" is not valid JSON`,
    `TypeError: undefined is not an object (evaluating 'bg.get("LPContentScriptFeatures").icon_expanded_looks_like_username')`,
    'ResizeObserver loop limit exceeded',
    `can't access dead object`,
    'TypeError: e is not a function',
    `TypeError: Cannot read properties of null (reading 'CodeMirror')`,
    `Cannot read properties of undefined (reading 'enabledFeatures')`,
    'Java exception was raised during method invocation',
    'cancelado',
    `TypeError: undefined is not an object (evaluating '__gCrWeb.instantSearch.clearHighlight')`,
    'Internal Server Error',
    'n: No error message',
    'TypeError: g is not a function',
    'NS_ERROR_UNEXPECTED',
    `TypeError: undefined is not an object (evaluating '__gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost')`,
    'n: Unprocessable Entity',
    'n: Unauthorized',
    'n: Bad Request',
    `TypeError: Failed to execute 'contains' on 'Node': parameter 1 is not of type 'Node'.`,
    'TypeError: Symbol.iterator is not defined.',
    'TypeError: Cannot redefine property: googletag',
    'InternalError: too much recursion',
    `SecurityError: Failed to execute 'pushState' on 'History'`,
    `TypeError: Unhandled Promise Rejection: NetworkError when attempting to fetch resource.`,
    `TypeError: undefined is not an object (evaluating 'n.length')`,
    `React ErrorBoundary Error: Blocked attempt to use history.replaceState() to change session history URL`,
    `SecurityError: Blocked attempt to use history.replaceState() to change session history URL`,
    'DataCloneError: The object can not be cloned.',
    'NetworkError: Load failed',
    `Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope'`,
    'Unhandled Promise Rejection: chain is not set up',
    'd is not a function',
    'bt is not a function',
    `Cannot read properties of undefined (reading 'P')`,
    'NS_ERROR_ABORT: No error message',
    'illegal access',
    'Object is not iterable.',
    'OpenNewWindowForTransactionTypePages',
    'n: Unhandled Promise Rejection',
    'Unauthorized',
    'AbortError: The user aborted a request.',
    'n: Unprocessable',
    'ResizeObserver loop completed with undelivered notifications.',
    `Unexpected token 'export'`,
    'SecurityError: Failed to set a named property',
    'TypeError: scrollReadRandom(...) is not a function',
    `TypeError: Cannot read properties of undefined (reading 'pause')`,
    `Error: Permission denied to access property "nodeType"`,
    'Error: Should not already be working.',
    'Error: Permission denied to access property',
    `Error: Permission denied to access property "__sn"`,
    'TypeError: Illegal invocation',
    'RangeError: Maximum call stack size exceeded.',
    'WeakMap key undefined must be an object or an unregistered symbol',
    'SyntaxError: Unexpected token u in JSON at position 0',
    `NotAllowedError: play() failed because the user didn't interact with the document first`,
    'ReferenceError: Chartboost is not defined',
    'Retrieving "b5x-stateful-inline-icon" flag timed out',
    'queueMicrotask is not defined',
    `null is not an object (evaluating 'this._logicalFormForControl(e).formElement')`,
    't.jsReceiveMessages is not a function',
    'ReferenceError: $ZSIQWidget is not defined',
    `TypeError: Cannot read properties of null (reading 'contentWindow')`,
  ],
  denyUrls: [
    /\/\/js-na1\.hs-scripts\.com/i,
    /io\.innertrends\.com/i,
    /www\.googletagmanager\.com/i,
    /fast\.appcues\.com/i,
    /js\.hscollectedforms\.net/i,
    /connect\.facebook\.net/i,
    /cdn\.heapanalytics\.com/i,
    /apis\.google\.com/i,
    /chrome-extension/i,
    /dev\.visualwebsiteoptimizer\.com/i,
    /app\.vwo\.com/i,
    /tools\.luckyorange\.com/i,
    /cdn\.headwayapp\.co/i,
    /www\.clarity\.ms/i,
    /secure\.helpscout\.net/i,
    /SnapTube/i,
    /extensions\//i,
    /safari-extension/i,
    /js-agent\.newrelic\.com/i,
    /www\.google-analytics\.com/i,
    /stats\.g\.doubleclick\.net/i,
    /googleads\.g\.doubleclick\.net/i,
    /snap\.licdn\.com/i,
    /bam\.nr-data\.net/i,
    /js-na1\.hs-scripts\.com/i,
    /js\.hs-analytics\.net/i,
    /js\.usemessages\.com/i,
    /js\.hs-banner\.com/i,
    /js\.hsadspixel\.net/i,
    /accounts\.google\.com/i,
    /innertrends\.s3\.amazonaws\.com/i,
    /td\.doubleclick\.net/i,
    /js-na1\.hs-scripts\.com/i,
    /www\.facebook\.com/i,
    /pagead2\.googlesyndication\.com/i,
    /z\.clarity\.ms/i,
    /o\.clarity\.ms/i,
    /api\.hubapi\.com/i,
    /forms\.hscollectedforms\.net/i,
    /sdk\.split\.io/i,
    /api\.nominapp\.com/i,
    /serve\.albacross\.com\/track\.js/i,
  ],
  beforeSend(event, hint) {
    const error = hint.originalException

    if (evaluateError(error)) {
      return null
    }

    if (error && error.errors) {
      event.tags = {
        api_error: 'yes',
      }

      event.breadcrumbs.push({
        type: error.name,
        category: 'original_error',
        level: 'log',
        message: error.message,
        data: {
          errors: error.errors,
          status: error.status,
        },
      })
    }

    return event
  },
})

class ErrorBoundaries extends Component {
  constructor(props) {
    super(props)
    this.state = initState
    this.handleError = this.handleError.bind(this)
    this.showErrorMessage = this.showErrorMessage.bind(this)
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  /**
   * If the formik object is send, it will automatically set errors and set submitting to false
   * @param {FetchError} error
   * @param {*} formik
   * @param {Object} options
   * @param {Object[]} options.errorsToNotificate Includes there error object with its object and code that will be show as notification message even if it has an associated formik field
   * @param {Object} options.errorFieldMap Used when the server returns different error field names than those sent in the request
   * @param {Function} options.errorsCallback
   * @param {Object} options.notistackOptions Options for notistack component
   */
  handleError(
    error,
    formik,
    {
      redirect = true,
      errorsToNotificate = [],
      errorFieldMap = {},
      errorsCallback,
      notistackOptions = {},
    } = {}
  ) {
    const { showErrorMessage } = this

    if (!(error instanceof FetchError)) {
      // eslint-disable-next-line no-console
      if (isDev) console.error(error)

      showErrorMessage(
        'Lo sentimos. Ha ocurrido un error inesperado.',
        notistackOptions
      )
      return
    }
    const { router } = this.props
    const { navigate, location } = router

    const { status, errors } = error

    if (status === 500) {
      // Internal Server Error
      showErrorMessage(
        errors?.[0]?.message || (typeof errors === 'string' ? errors : null),
        notistackOptions
      )
    } else if (status === 401) {
      // Unauthorized
      if (redirect) {
        navigate(routes.LOGOUT)
      }
    } else if (status === 402) {
      // Payment Required
      navigate(routes.PAYMENT_REQUIRED(), {
        state: {
          error: errors[0],
        },
      })
    } else if (status === 403) {
      // Forbidden
      showErrorMessage(errors[0].message, notistackOptions)
      if (location.pathname === '/dashboard') {
        auth.logOut()
      }
      navigate('/')
    } else if (status === 404) {
      // Not found
      // TODO: pending specific handling
      showErrorMessage(errors[0].message, notistackOptions)
    } else if (status === 422) {
      // Unprocessable Entity

      const formikErrors = {}

      errors.forEach((err) => {
        const { code, message, object } = err

        switch (code) {
          // following error codes are realted to body and/or query params data
          case '0001':
          case '0002':
          case '0003':
          case '0101':
          case '0102':
          case '0104':
          case '0105':
          case '0201':
          case '0202':
          case '0203':
          case '0204':
          case '0301':
          case '0302':
          case '0303':
          case '0304':
          case '0305':
          case '0306':
          case '0307':
          case '0308':
          case '0401':
          case '0402':
          case '0403':
          case '0405':
          case '0406':
          case '0708':
            if (formik) {
              const mappedObject = errorFieldMap[object] || object
              formikErrors[mappedObject] = message

              if (errorsToNotificate) {
                errorsToNotificate.forEach((errorNotificate) => {
                  if (
                    (errorNotificate.object === mappedObject &&
                      !errorNotificate.code) ||
                    (errorNotificate.code === code &&
                      !errorNotificate.mappedObject) ||
                    (errorNotificate.object === mappedObject &&
                      errorNotificate.code === code)
                  ) {
                    showErrorMessage(message, notistackOptions)
                  }
                })
              }
            } else {
              showErrorMessage(message, notistackOptions)
            }
            break
          default:
            showErrorMessage(message, notistackOptions)
            break
        }
      })

      if (!isObjectEmpty(formikErrors)) {
        formik.setErrors(formikErrors)
        formik.setSubmitting(false)

        if (errorsCallback) errorsCallback(formikErrors)
      }
    } else {
      // eslint-disable-next-line no-console
      if (isDev) console.error(error)

      showErrorMessage(
        'Lo sentimos. Ha ocurrido un error inesperado.',
        notistackOptions
      )
      throw error
    }
  }

  showErrorMessage(message, opts) {
    const { enqueueSnackbar } = this.props

    if (message) {
      enqueueSnackbar(message, {
        variant: 'error',
        preventDuplicate: true,
        ...opts,
      })
    }
  }

  render() {
    const { children } = this.props

    return (
      <ErrorBoundary
        fallback={({ error, resetError }) => {
          return <ErrorPage error={error} resetError={resetError} />
        }}
      >
        <ErrorHandlerContext.Provider value={{ handleError: this.handleError }}>
          {children}
        </ErrorHandlerContext.Provider>
      </ErrorBoundary>
    )
  }
}

const withRouter = (ClassComponent) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation()
    const navigate = useNavigate()

    return <ClassComponent {...props} router={{ location, navigate }} />
  }

  return ComponentWithRouterProp
}

const withSnackbar = (ClassComponent) => {
  const ComponentWithSnackbarProp = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    return (
      <ClassComponent
        {...props}
        enqueueSnackbar={enqueueSnackbar}
        closeSnackbar={closeSnackbar}
      />
    )
  }

  return ComponentWithSnackbarProp
}

export default withRouter(withSnackbar(ErrorBoundaries))
