import { parseISO } from 'date-fns'
import { useFormikContext } from 'formik'

import { Box, Link, Paper, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import useWorker from 'components/Worker/useWorker'

import useAutoScroll from 'utils/hooks/useAutoScroll'

import * as routes from 'config/routes'

import messages from 'messages/termination'

import Summary from './Summary'

const booleanOptions = [
  { value: true, label: 'Si' },
  { value: false, label: 'No' },
]

const LinkViewDetails = ({ name, isEditing }) => {
  const { values } = useFormikContext()

  return isEditing ? (
    <Link
      variant="lead2"
      href={`${routes.PERIOD_PAYROLL_VIEW_DETAILS(
        values.payroll_id
      )}?selected_tab=termination`}
      target="_blank"
      style={{
        display: 'inline-flex',
        marginLeft: 'auto',
        alignItems: 'center',
      }}
      underline="hover"
    >
      <Icon name="eye" basic />
      Ver cálculos de {name}
    </Link>
  ) : null
}

const ContractTerminationFields = ({
  dirtyTermination,
  handleCancel,
  isEditing,
  isSaving,
  termination,
}) => {
  const { values, setFieldValue } = useFormikContext()
  const { worker } = useWorker({ useCache: true })
  const [causeInputId, scrollToCauseInput] = useAutoScroll({
    elementId: 'termination-cause',
  })

  const dateMonth = parseISO(values?.date).getMonth()
  const dateYear = parseISO(values?.date).getFullYear()

  return (
    <Box>
      <Paper
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(3),
          padding: theme.spacing(5),
          marginBottom: theme.spacing(4),
          borderRadius: '1rem',
        })}
      >
        <Typography variant="h2" color="primary.dark" id={causeInputId}>
          Causas liquidación
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              flexDirection: 'row',
              columnGap: theme.spacing(3),
              rowGap: 0,
            },
          })}
        >
          <FormField
            name="cause"
            label="¿Motivo de liquidación?"
            placeholder="Ejm: Retiro voluntario"
            onChange={({ target }) =>
              setFieldValue('cause', target.value.trimStart())
            }
            optional={false}
          />
          <FormField
            name="with_just_cause"
            label="¿Liquidación con justa causa?"
            variant="radio-group"
            options={booleanOptions}
            tooltipContent={messages.with_just_cause}
            disabled={values.trial_period}
            row
            optional={false}
          />
          {values.possible_trial_period ? (
            <FormField
              name="trial_period"
              label="Periodo de prueba"
              variant="radio-group"
              options={booleanOptions}
              tooltipContent={messages.trial_period}
              row
              optional={false}
            />
          ) : null}
        </Box>
        <Typography variant="h2" color="primary.dark">
          Fechas liquidación
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateRows: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: '1fr 1fr',
              columnGap: theme.spacing(3),
            },
          })}
        >
          <FormField
            name="hire_date"
            label="Fecha de contratación"
            variant="datepicker"
            tooltipContent={messages.hire_date}
            disabled
          />
          <FormField
            name="date"
            label="Fecha de terminación"
            variant="datepicker"
            minDate={values.min_date}
            maxDate={values.max_date}
            tooltipContent={messages.date}
            optional={false}
          />
          <FormField
            name="worked_days"
            label="Días a liquidar"
            type="number"
            align="left"
            disabled
            tooltipContent={messages.worked_days}
          />
          <FormField
            name="holidays"
            label="Días de vacaciones"
            type="number"
            optional={false}
            tooltipContent={messages.holidays}
          />
        </Box>
        <Typography variant="h2" color="primary.dark">
          Bases cálculo liquidación
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateRows: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: '1fr 1fr',
              columnGap: theme.spacing(3),
            },
          })}
        >
          <FormField
            name="termination_base"
            label="Salario Base"
            variant="currency"
            align="left"
            tooltipContent={messages.termination_base}
            additionalLabelComponent={
              <LinkViewDetails name="salario base" isEditing={isEditing} />
            }
            optional={false}
          />
          <FormField
            name="holidays_base"
            label="Vacaciones"
            variant="currency"
            align="left"
            tooltipContent={messages.holidays_base}
            additionalLabelComponent={
              <LinkViewDetails name="vacaciones" isEditing={isEditing} />
            }
            optional={false}
          />
          <FormField
            name="severance_base"
            label="Cesantías"
            variant="currency"
            align="left"
            tooltipContent={messages.severance_base}
            additionalLabelComponent={
              <LinkViewDetails name="cesantías" isEditing={isEditing} />
            }
            optional={false}
          />
          {values.pay_service_bonus ? (
            <FormField
              name="service_bonus_base"
              label="Prima"
              variant="currency"
              align="left"
              tooltipContent={messages.service_bonus_base}
              additionalLabelComponent={
                <LinkViewDetails name="prima" isEditing={isEditing} />
              }
              optional={false}
            />
          ) : null}
          {values.pay_previous_service_bonus ? (
            <FormField
              name="previous_service_bonus_base"
              label="Prima semestre anterior"
              variant="currency"
              align="left"
              optional={false}
            />
          ) : null}
          {values.pay_previous_severance ||
          values.pay_previous_severance_interests ? (
            <FormField
              name="previous_severance_base"
              label="Cesantías año anterior"
              variant="currency"
              align="left"
              optional={false}
            />
          ) : null}
        </Box>
        <Typography variant="h2" color="primary.dark">
          Opciones adicionales
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateRows: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: '1fr 1fr',
              columnGap: theme.spacing(3),
            },
          })}
        >
          {worker?.contract_category !== 'part_time_contract' ? (
            <FormField
              name="pay_rest_days"
              label="¿Incluir dominicales?"
              variant="radio-group"
              options={booleanOptions}
              tooltipContent={messages.pay_rest_days}
              row
              optional={false}
            />
          ) : null}
          {values.show_pay_service_bonus ? (
            <FormField
              name="pay_service_bonus"
              label="¿Incluir la prima del semestre actual?"
              variant="radio-group"
              options={booleanOptions}
              row
              optional={false}
            />
          ) : null}
          {dateMonth === 0 ||
          dateMonth === 6 ||
          (dateMonth > 6 && dateYear === 2020) ? ( // january or july
            <FormField
              name="pay_previous_service_bonus"
              label="¿Incluir prima semestre anterior?"
              variant="radio-group"
              options={booleanOptions}
              tooltipContent={messages.pay_previous_service_bonus}
              row
              optional={false}
            />
          ) : null}
          {values.show_pay_previous_severance ? (
            <FormField
              name="pay_previous_severance"
              label="¿Incluir cesantías año anterior?"
              variant="radio-group"
              options={booleanOptions}
              row
              optional={false}
            />
          ) : null}
          {values.show_pay_previous_severance_interests ? ( // january
            <FormField
              name="pay_previous_severance_interests"
              label="¿Incluir intereses a las cesantías año anterior?"
              variant="radio-group"
              options={booleanOptions}
              row
              optional={false}
            />
          ) : null}
          {values.show_decree376 ? (
            <FormField
              name="pay_decree376"
              label="¿Incluir retención por Decreto 376?"
              variant="radio-group"
              options={booleanOptions}
              row
              optional={false}
            />
          ) : null}
        </Box>
        <Typography variant="h2" color="primary.dark">
          Otros ingresos
        </Typography>
        <FormField
          name="non_salary_income"
          label="Ingresos no salariales"
          variant="currency"
          align="left"
          placeholder="0"
          optional={false}
        />
        <Typography variant="h2" color="primary.dark">
          Otros egresos
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateRows: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: '1fr 1fr',
              columnGap: theme.spacing(3),
            },
          })}
        >
          {!values.with_just_cause ? (
            <FormField
              name="compensation_retention"
              label="Valor retención por indemnización"
              variant="currency"
              align="left"
              placeholder="0"
              optional={false}
            />
          ) : null}
          {termination.total_paid_service_bonus > 0 ? (
            <FormField
              name="service_bonus_retention"
              label="Valor retención por prima"
              variant="currency"
              align="left"
              placeholder="0"
              optional={false}
            />
          ) : null}
          {values.show_decree376 ? (
            <FormField
              name="decree376"
              label="Valor retención por Decreto 376"
              variant="currency"
              align="left"
              placeholder="0"
              disabled
            />
          ) : null}
          <FormField
            name="loans"
            label="Valor préstamos"
            variant="currency"
            align="left"
            placeholder="0"
          />
          {values.show_payroll_credit ? (
            <FormField
              name="payroll_credit_value"
              label="Crédito de libranza"
              variant="currency"
              align="left"
              placeholder="0"
            />
          ) : null}
          <FormField
            name="deductions"
            label="Otras deducciones"
            variant="currency"
            align="left"
            placeholder="0"
          />
        </Box>
        <Typography variant="h2" color="primary.dark">
          Notas
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
              gridColumn: 'span 2',
            },
          })}
        >
          <FormField
            name="notes"
            label="Notas"
            variant="textarea"
            placeholder="Agrega cualquier detalle adicional"
            rows={3}
            tooltipContent={messages.notes}
            inputProps={{ style: { resize: 'none' } }}
          />
        </Box>
      </Paper>
      <Summary
        dirtyTermination={dirtyTermination}
        handleCancel={handleCancel}
        isEditing={isEditing}
        isSaving={isSaving}
        scrollToCauseInput={scrollToCauseInput}
      />
    </Box>
  )
}

export default ContractTerminationFields
