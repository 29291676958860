import { Box } from '@mui/material'

import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'

import { getWorkCenterHistory } from 'services/company/decree2012Service'

import { historyColumns } from './helpers'

const WorkCenterHistory = ({ workCenterId }) => {
  const companyId = getCompanyId()

  const fetchHistory = (pageIndex) => {
    return {
      queryKey: ['getWorkCenterHistory', companyId, workCenterId, pageIndex],
      queryFunction: () =>
        getWorkCenterHistory({ page: pageIndex + 1, workCenterId }),
    }
  }

  return (
    <Box
      sx={(theme) => ({
        margin: theme.spacing(2, 0, 5, 0),
      })}
    >
      <Table
        columns={historyColumns}
        data={fetchHistory}
        options={{
          pagination: false,
          search: false,
          customQueryFetch: 'work_center_histories',
        }}
      />
    </Box>
  )
}

export default WorkCenterHistory
