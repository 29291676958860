import { useState } from 'react'

import { Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Modal from 'components/UI/Modal/Modal'

import { technologistWoman } from 'utils/emojis'

import DiscardAction from '../SSAffiliation/DiscardAction'
import DiscardProcessContent from '../SSAffiliation/DiscardProcessContent'
import { discardText, discardTitles } from '../SSAffiliation/helpers'
import useUpdateOnboarding from '../useUpdateOnboarding'
import ElectronicSignatureContent from './ElectronicSignatureContent'

const ElectronicSignatureModal = ({
  handleClose,
  state,
  openConfirmationModal,
  resetElectronicSignatureState,
}) => {
  const { updateOnboarding, onboardingMutation } = useUpdateOnboarding()

  const [currentStep, setCurrentStep] = useState(0)
  const [discard, setDiscard] = useState(
    state?.electronic_signature?.status === 'not_apply'
  )

  const handleUpdateOnboarding = () => {
    updateOnboarding({
      onboardingId: state.onboardingId,
      data: {
        action_to_discard: 'electronic_signature',
      },
      onSuccessCallback: () => {
        handleClose()
      },
    })
  }

  const resetModalState = () => {
    setDiscard(false)
    setCurrentStep(0)
    resetElectronicSignatureState()
  }

  return (
    <Modal
      open={state.open}
      hideFooter
      isLoading={onboardingMutation.isLoading}
      header={
        currentStep === 0 ? (
          <DiscardAction
            discard={discard}
            setDiscard={setDiscard}
            discardTitles={discardTitles.electronicSignature}
            electronicSignature
          />
        ) : (
          <Typography variant="h3">
            Firma electrónica de documentos <Emoji code={technologistWoman} />
          </Typography>
        )
      }
      onCancel={handleClose}
      adornment={[
        {
          variant: 'explosion',
          color: 'complementary2.light',
          width: 193,
          height: 213,
          sx: { bottom: '-3.5rem', right: '-2rem' },
        },
      ]}
      paperSx={{
        maxWidth: '46rem',
      }}
      dialogProps={{
        TransitionProps: {
          onExited: resetModalState,
        },
      }}
    >
      {!discard ? (
        <ElectronicSignatureContent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleClose={handleClose}
          workerId={state.workerId}
          workerName={state.workerName}
          workerEmail={state.workerEmail}
          openConfirmationModal={openConfirmationModal}
        />
      ) : null}
      {discard ? (
        <DiscardProcessContent
          discardText={discardText.discardElectronicSignature}
          handleClose={handleClose}
          handleUpdateOnboarding={handleUpdateOnboarding}
          isLoading={onboardingMutation.isLoading}
          workerName={state.workerName}
        />
      ) : null}
    </Modal>
  )
}

export default ElectronicSignatureModal
