import { Chip } from '@mui/material'

import { formatCurrency, formatIdNumber } from 'utils/format'
import { getNameWorkerType } from 'utils/worker'

const columnsData = [
  {
    Header: 'Nombre Completo',
    accessor: 'full_name',
  },
  {
    Header: 'Cédula',
    accessor: 'id_number',
    Cell: ({ row }) => formatIdNumber(row.original.id_number),
  },
  {
    Header: 'Cargo',
    accessor: 'position',
    Cell: ({ row }) => {
      if (row.original.position) return row.original.position
      return '-'
    },
  },
  {
    Header: 'Tipo de Contrato',
    accessor: 'contract_category',
    Cell: ({ row }) => {
      if (row.original.contract_category)
        return (
          <Chip label={getNameWorkerType(row.original.contract_category)} />
        )
      return '-'
    },
  },
  {
    Header: 'Salario',
    accessor: 'base_salary',
    Cell: ({ row }) => {
      if (row.original.base_salary)
        return <b>{formatCurrency(row.original.base_salary)}</b>
      return '-'
    },
  },
]

export default columnsData
