import { Box, CircularProgress } from '@mui/material'

const LoadingBox = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center" p={4}>
      <CircularProgress size={46} color="primary" />
    </Box>
  )
}

export default LoadingBox
