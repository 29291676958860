import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { getWordSingular } from 'utils/format'

yup.setLocale(yupLocaleES)

export const getValidationSchema = (initialExtraWorkerNumber) => {
  return yup.object({
    extra_workers_number: yup
      .number()
      .nullable()
      .min(
        initialExtraWorkerNumber,
        `Mínimo ${initialExtraWorkerNumber} ${getWordSingular('personas', initialExtraWorkerNumber === 1)}.`
      )
      .required('Ingresa este campo.'),
  })
}

export const getInitialValues = (additionalWorkersInfo) => ({
  extra_workers_number: additionalWorkersInfo?.extra_workers_number || 1,
  pricing_value: additionalWorkersInfo?.extra_workers_value || 0,
})

export const getInitialCopy = (paidWorkers, payrollsSize) => {
  if (paidWorkers < payrollsSize) return 'Has superado'

  return 'Vas a superar'
}
