import { Slider } from '@mui/material'

const SliderField = ({ id, name, value, ...props } = {}) => {
  const { setValue, ...restProps } = props

  const getAriaValueText = (originalValue) => `${originalValue}`

  return (
    <Slider
      id={id}
      name={name}
      value={value}
      getAriaValueText={getAriaValueText}
      onChange={(_, newValue) => {
        setValue(newValue)
      }}
      {...restProps}
    />
  )
}

export default SliderField
