import { useState } from 'react'

import ContractDetailModal from 'components/Worker/Contract/Detail/ContractDetailModal'
import useContractDetailModal from 'components/Worker/Contract/Detail/useContractDetailModal'
import { formatContracts } from 'components/Worker/Contract/Index/Index'
import EditContractModal from 'components/Worker/Contract/Modal/EditContractModal'
import EditWageModal from 'components/Worker/Wage/Modal/EditWageModal'
import WageModal from 'components/Worker/Wage/Modal/WageModal'
import useWorker from 'components/Worker/useWorker'

import { useWorkerShowContext } from '../Body'
import SectionContainer from '../SectionContainer'
import { getSectionData } from './helpers'

const LaboralDataContent = ({ handleFinishPersonRegistration }) => {
  const [isWageModalOpen, setIsWageModalOpen] = useState(false)
  const [editWageModal, setEditWageModal] = useState({
    open: false,
    mode: null,
  })
  const { worker, refreshWorker, isFromAffiliationView } = useWorker({
    useCache: true,
  })
  const {
    contract_category: contractCategory,
    contract_detail: contractDetail = {},
  } = worker
  const contractData = {
    id: worker.contract_id,
    category: worker.contract_category,
    initial_day: worker.initial_day,
    end_day: worker.end_day,
    period_end_day: worker.period_end_day,
    term: worker.term,
    terminated: worker.terminated,
    termination: worker.termination,
    base_salary: worker.base_salary,
    wage_id: worker.wage_id,
    wage_category: worker.wage_category,
    transport_subsidy: worker.transport_subsidy,
    work_center: worker.work_center,
    risk_type: worker.risk_type,
    high_risk_pension: worker.high_risk_pension,
    health_provider: worker.health_provider,
    pension_provider: worker.pension_provider,
    severance_provider: worker.severance_provider,
  }
  const [formattedContract] = formatContracts([contractData])
  const isRegistrationFinished = Boolean(worker.contract_id)

  const {
    openGeneralDataModal,
    closeGeneralDataModal,
    editContractModalState,
    closeEditContractModal,
  } = useWorkerShowContext()
  const {
    contractDetailModalState,
    openContractDetailModal,
    closeContractDetailModal,
    resetContractDetailModal,
  } = useContractDetailModal()

  const wageSubmitCallback = () => {
    refreshWorker()
  }

  const sectionData = getSectionData({
    worker,
    contractDetail,
    contractCategory,
    isFromAffiliationView,
    setEditWageModal,
    openGeneralDataModal,
    isRegistrationFinished,
    handleFinishPersonRegistration,
  })

  return (
    <>
      {sectionData?.map((item) => {
        if (!item?.hidden) {
          return (
            <SectionContainer
              item={item}
              key={item.id}
              containerCyLabel={item.containerCyLabel}
              isRegistrationFinished={isRegistrationFinished}
            />
          )
        }

        return null
      })}
      {isWageModalOpen ? (
        <WageModal
          contract={formattedContract}
          isEdit={editWageModal.mode === 'edit'}
          onCancel={() => setIsWageModalOpen(false)}
          submitCallback={wageSubmitCallback}
        />
      ) : null}
      <EditWageModal
        open={editWageModal.open}
        onClose={() =>
          setEditWageModal((previousState) => ({
            ...previousState,
            open: false,
          }))
        }
        onEdit={() => {
          setEditWageModal((previousState) => ({
            ...previousState,
            mode: 'edit',
          }))
          setIsWageModalOpen(true)
        }}
        onCreate={() => {
          setEditWageModal((previousState) => ({
            ...previousState,
            mode: 'create',
          }))
          setIsWageModalOpen(true)
        }}
      />
      <EditContractModal
        state={editContractModalState}
        handleClose={closeEditContractModal}
        onEdit={() => {
          closeGeneralDataModal()
          openContractDetailModal({
            isEditing: true,
            worker,
            lastContractDetail: {
              id: worker?.contract_detail?.id,
              contributor_subtype: worker?.contract_detail?.contributor_subtype,
              initial_day: worker?.contract_detail?.initial_day,
              end_day: worker?.contract_detail?.end_day,
              high_risk_pension: worker?.high_risk_pension,
              work_center: {
                ...worker?.work_center,
                risk_type_coded_name: worker?.risk_type,
              },
            },
            contractDetail: {
              id: worker?.contract_detail?.id,
              contributor_subtype: worker?.contract_detail?.contributor_subtype,
              initial_day: worker?.contract_detail?.initial_day,
              end_day: worker?.contract_detail?.end_day,
              high_risk_pension: worker?.high_risk_pension,
              work_center: {
                ...worker?.work_center,
                risk_type_coded_name: worker?.risk_type,
              },
            },
            contractId: worker?.contract_id,
            isFromContractDetailIndex: false,
          })
        }}
        onCreate={() => {
          closeGeneralDataModal()
          openContractDetailModal({
            isEditing: false,
            worker,
            lastContractDetail: {
              id: worker?.contract_detail?.id,
              contributor_subtype: worker?.contract_detail?.contributor_subtype,
              initial_day: worker?.contract_detail?.initial_day,
              end_day: worker?.contract_detail?.end_day,
              high_risk_pension: worker?.high_risk_pension,
              work_center: {
                ...worker?.work_center,
                risk_type_coded_name: worker?.risk_type,
              },
            },
            contractId: worker?.contract_id,
            isFromContractDetailIndex: false,
          })
        }}
      />
      <ContractDetailModal
        state={contractDetailModalState}
        handleClose={closeContractDetailModal}
        resetModal={resetContractDetailModal}
      />
    </>
  )
}

export default LaboralDataContent
