import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Emoji from 'components/UI/Emoji'

import { partyPopperEmoji } from 'utils/emojis'

import { ONBOARDING_FIRST_STEPS } from 'config/routes'

import useOnboardingMutation from '../useOnboardingMutation'

const OnboardingCongratulations = () => {
  const navigate = useNavigate()
  const { company, refreshCompany } = useUser()
  const { handleUpdateOnboarding } = useOnboardingMutation()
  const valuesToSend = { onboarding_step: 'finished' }
  const userName = company.onboarding?.user_name

  const handleDiscoverAleluya = () => {
    handleUpdateOnboarding({
      data: { ...valuesToSend, selected_view: 'discover_nominapp' },
      callback: async () => {
        await refreshCompany()

        navigate(ONBOARDING_FIRST_STEPS(), {
          replace: true,
        })
      },
    })
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(14),
        },
      })}
    >
      <Typography
        variant="h3"
        sx={(theme) => ({
          marginBottom: theme.spacing(7.5),
        })}
      >
        ¡Aleluya, {userName}! <br />
        Has configurado tu cuenta. <Emoji code={partyPopperEmoji} />
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Button onClick={handleDiscoverAleluya}>Ir a descubrir Aleluya</Button>
      </Box>
    </Box>
  )
}

export default OnboardingCongratulations
