import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'

const EmptyStateDocuments = ({ showFolderState, showFilesState }) => {
  const { isWorker } = useUser()

  const getContent = () => {
    if (isWorker) {
      const workerContent = {}

      if (showFolderState) {
        workerContent.icon = (
          <Icon name="folder-open" twoTone sx={{ fontSize: '5rem' }} />
        )
        workerContent.message = 'No tienes carpetas compartidas'
      }

      if (showFilesState) {
        workerContent.icon = (
          <Icon name="document" twoTone sx={{ fontSize: '5rem' }} />
        )
        workerContent.message = 'No tienes archivos compartidos'
      }

      return workerContent
    }

    const adminContent = {}

    if (showFolderState) {
      adminContent.icon = (
        <Icon name="folder-open" twoTone sx={{ fontSize: '5rem' }} />
      )
      adminContent.message = 'No has compartido carpetas con esta persona'
    }

    if (showFilesState) {
      adminContent.icon = (
        <Icon name="document" twoTone sx={{ fontSize: '5rem' }} />
      )
      adminContent.message = 'No has agregado archivos a esta carpeta'
    }

    return adminContent
  }

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '17.5rem',
        gap: theme.spacing(2),
        gridColumn: '1 / -1',
      })}
    >
      {getContent().icon}
      <Typography variant="h4" align="center">
        {getContent().message}
      </Typography>
    </Box>
  )
}

export default EmptyStateDocuments
