import { useEffect, useMemo, useRef, useState } from 'react'
import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import {
  Box,
  CircularProgress,
  Table as MTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  alpha,
} from '@mui/material'

import useDebouncedState from 'utils/hooks/useDebouncedState'

import LoadingBackdrop from '../Loading/LoadingBackdrop'
import TooltipInfoIcon from '../MaterialUI/TooltipInfoIcon'
import EmptyState from './EmptyState'
import TablePagination from './Pagination'
import DeleteRow from './Row/DeleteRow'
import EditRow from './Row/EditRow'
import RowActions from './Row/RowActions'
import RowCell from './Row/RowCell'
import RowCheckbox from './Row/RowSelectionCheckbox'
import TableToolbar from './Toolbar/Toolbar'
import {
  getConditionSelectHeaderCheckbox,
  getCustomHeaderProps,
  getGlobalFilterConfig,
  getHeaderWrapperProps,
  getPaginationConfig,
  getSortingConfig,
  useAddRow,
  useAsyncData,
  useEditableTable,
  useSortTypes,
  useTableStyles,
} from './helpers'

// For docs and examples see: https://github.com/Nominapp/technology_handbook/blob/master/desarrollo/frontend/inhouse_table.md

const Table = ({
  data = [],
  columns,
  actions,
  components = {},
  options = {},
  initialConfig = {},
  filterChanged,
  editable = {},
  sx = {},
  ...props
}) => {
  const { getHeaderStyles } = useTableStyles()
  const customSortTypes = useSortTypes()
  const asyncData = typeof data === 'function'
  const { tableContainerSx } = sx
  const [asyncSearchFilter, setAsyncSearchFilter] = useDebouncedState('', 300)

  // Destructuring custom components and replace the default ones if they exist
  const { Toolbar: ProvidedToolbar, ActionButton: ProvidedActionButton } =
    components
  const Toolbar = ProvidedToolbar || TableToolbar

  // Destructuring table options
  const {
    customActionsWidth,
    toolbar: showToolbar = true,
    search: showSearch = true,
    sorting: showSorting = true,
    pagination: showPagination = true,
    selection: showSelection = false,
    selectionId,
    rowStyle = () => {},
    alignActionsHeader = 'center',
    alignActionsCell = 'left',
    hiddenColumns = [],
    emptyState,
    emptyStateComponent = null,
    flatStyle = false,
    rowsSize = 20,
    expandableRows = false,
    dataCyPrefix,
    customQueryFetch = null,
    customFilterFetch = null,
    expandedInitialState, // If passed, must be memoized
    stickyHeader = false,
    minWidth, // TODO: replace tableMinWidth by minWidth in tables that use it
    version = {},
    searchProps = {},
    hasData = false,
    tableRefs = {},
  } = options

  const isNewToolbar = version.toolbar === 'v2'

  // Destructuring async function
  const {
    tableQuery,
    dispatchAsyncDataAction,
    currentPageIndex,
    currentTotalCount,
  } = useAsyncData(data, asyncData, customFilterFetch, asyncSearchFilter)

  const withInitialData = useRef(false)

  // Set custom query when some data comes into data
  const tableQueryCustom =
    customQueryFetch !== null && asyncData && tableQuery.isSuccess
      ? tableQuery?.data[customQueryFetch]
      : tableQuery?.data
  const derivedData = useMemo(
    () => (asyncData ? tableQueryCustom || [] : data),
    [asyncData, data, tableQueryCustom]
  )
  const [currentData, setCurrentData] = useState(() => derivedData || [])

  // Set custom filter when come into data
  const tableFiltersCustom =
    customFilterFetch !== null && asyncData && tableQuery.isSuccess
      ? tableQuery?.data[customFilterFetch]
      : tableQuery?.filters

  useEffect(() => {
    if (derivedData.length >= 0) {
      setCurrentData([...derivedData])
    }

    if (derivedData.length > 0) {
      withInitialData.current = true
    }
  }, [derivedData])

  // Memoize table data
  const dataMemoized = useMemo(() => currentData || [], [currentData])

  // Memoize table columns
  const columnsMemoized = useMemo(() => columns, [columns])

  // Editable row
  const {
    isTableEditable,
    tableMinWidth,
    handleDeleteRow,
    handleEditRow,
    onCancelEdit,
    onConfirmEdit,
    isDeletable,
    isEditable,
    isEditingRow,
    isDeletingRow,
    editionLoading,
    deleteRowColSpan,
    validationSchema,
    isEditionActive,
    editMode,
    onChangeEditMode,
    hideEdit,
    hideDelete,
  } = useEditableTable({
    configuration: editable,
    columnsLength: columns.length,
    setCurrentData,
  })

  // Table actions
  let generalActions = []
  let rowActions = []
  if (actions) {
    generalActions = actions?.filter((action) => action?.isFreeAction)
    rowActions = actions?.filter((action) => !action?.isFreeAction)
  }

  const paginationConfig = getPaginationConfig(
    showPagination,
    asyncData,
    currentPageIndex,
    currentTotalCount,
    rowsSize
  )
  const globalFilterConfig = getGlobalFilterConfig(
    showSearch,
    showToolbar,
    asyncData
  )
  const sortingConfig = getSortingConfig(showSorting)

  const table = useTable(
    {
      columns: columnsMemoized,
      data: dataMemoized,
      sortTypes: customSortTypes,
      ...paginationConfig,
      initialState: {
        ...paginationConfig?.initialState,
        globalFilter: '',
        hiddenColumns,
        expanded: expandedInitialState || {},
      },
      ...globalFilterConfig,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      ...sortingConfig,
      ...initialConfig,
      handleDeleteRow,
      handleEditRow,
      isTableEditable,
      isDeletable,
      isEditable,
      hideEdit,
      hideDelete,
    },
    ...(showSearch && showToolbar ? [useGlobalFilter] : []),
    ...(showSorting ? [useSortBy] : []),
    ...(expandableRows ? [useExpanded] : []),
    ...(showPagination ? [usePagination] : []),
    ...(showSelection ? [useRowSelect] : []),
    (hooks) => {
      hooks.allColumns.push((allColumns) => [
        ...(showSelection
          ? [
              {
                id: 'selection',
                Header: (column) => {
                  const { rows } = column
                  const checkboxProps = getConditionSelectHeaderCheckbox({
                    headerProps: column,
                    showPagination,
                  })

                  rows.forEach(({ isSelected, original }) => {
                    const rowCopy = original

                    rowCopy.selection = isSelected
                  })

                  return (
                    <div>
                      <RowCheckbox
                        sx={{
                          backgroundColor: 'initial',
                          border: '0 !important',
                        }}
                        {...checkboxProps}
                      />
                    </div>
                  )
                },
                Cell: ({ row }) => (
                  <div>
                    <RowCheckbox
                      disabled={row.original.disableCheck}
                      sx={{
                        backgroundColor: 'initial',
                        border: '0 !important',
                      }}
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                ),
                excludeOnEdit: true,
              },
            ]
          : []),
        ...allColumns,
        ...(rowActions.length > 0 || isTableEditable
          ? [
              {
                id: 'actions',
                Header: 'Acciones',
                Cell: ({
                  row,
                  handleDeleteRow: deleteRow,
                  handleEditRow: editRow,
                  isTableEditable: editableTable,
                  isDeletable: isRowDeletable,
                  isEditable: isRowEditable,
                  hideEdit: hideEditRow,
                  hideDelete: hideDeleteRow,
                }) => (
                  <RowActions
                    actions={rowActions}
                    row={row}
                    ProvidedActionButton={ProvidedActionButton}
                    deleteRow={deleteRow}
                    editRow={editRow}
                    editableTable={editableTable}
                    isDeletable={isRowDeletable}
                    isEditable={isRowEditable}
                    hideEdit={hideEditRow}
                    hideDelete={hideDeleteRow}
                    dataCyPrefix={dataCyPrefix}
                  />
                ),
                customWidth: customActionsWidth,
                excludeOnEdit: true,
                alignActionsHeader,
                alignActionsCell,
              },
            ]
          : []),
      ])
    }
  )

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setGlobalFilter,
    toggleRowSelected,
    isAllRowsSelected,
    state: { pageIndex, pageSize, globalFilter },
    allColumns,
  } = table

  if (tableRefs.pagination) {
    tableRefs.pagination.current = {
      resetPagination: () => {
        gotoPage?.(0)

        dispatchAsyncDataAction({
          type: 'page_changed',
          payload: 0,
        })
      },
    }
  }

  const isTableEmpty =
    dataMemoized?.length === 0 || (showPagination ? page : rows)?.length === 0
  const emptyTableColSpan = allColumns.length
  const isTableLoading =
    tableQuery.isLoading ||
    (asyncData && tableQueryCustom?.length !== 0 && currentData.length === 0) ||
    editionLoading ||
    false
  const showEmptyStateComponent =
    isTableEmpty && emptyStateComponent && !(withInitialData.current || hasData)
  const showEmptySearchState =
    isTableEmpty &&
    (globalFilter || asyncSearchFilter) &&
    withInitialData.current

  // Add new row action if table is editable and add new row is enabled
  const { enableAddRow, addRowAction } = useAddRow({
    generalActions,
    editableConfig: editable,
    isEditionActive,
    setCurrentData,
    allColumns,
    handleEditRow,
    dataLength: dataMemoized.length,
    onChangeEditMode,
  })

  if (enableAddRow) {
    generalActions.push(addRowAction)
  }

  const handleChangePage = (newPage) => {
    gotoPage?.(newPage)
  }

  // To fix bug when selection is enabled
  useEffect(() => {
    rows.forEach(({ id, original }) => {
      if (selectionId && selectionId in original) {
        toggleRowSelected(id, original[selectionId])
      }
    })
  }, [rows, selectionId, toggleRowSelected])

  // Update currentPageIndex
  useEffect(() => {
    dispatchAsyncDataAction({ type: 'page_changed', payload: pageIndex })
  }, [dispatchAsyncDataAction, pageIndex])

  // Reset currentPageIndex when external filter changes
  useEffect(() => {
    dispatchAsyncDataAction({ type: 'page_changed', payload: 0 })
  }, [dispatchAsyncDataAction, filterChanged])

  // Update currentSearch and reset currentPageIndex when globalFilter changes
  useEffect(() => {
    dispatchAsyncDataAction({
      type: 'search_changed',
      payload: globalFilter || '',
    })
    if (globalFilter !== '') {
      gotoPage?.(0)
    }
  }, [dispatchAsyncDataAction, globalFilter, gotoPage])

  useEffect(() => {
    if (asyncSearchFilter !== '') {
      gotoPage?.(0)
    }
  }, [asyncSearchFilter, gotoPage])

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        '& ::-webkit-scrollbar': {
          WebkitAppearance: 'none',
        },
        '& ::-webkit-scrollbar:horizontal': {
          height: '0.5rem',
        },
        /* Firefox */
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.white.dark} ${theme.palette.white.main}`,
        // /* Chrome, Edge, and Safari */
        '& ::-webkit-scrollbar-track': {
          background: theme.palette.white.main,
        },
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.white.dark,
          borderRadius: '0.1875rem',
          border: 'none',
        },
      })}
      {...props}
    >
      {isNewToolbar && showToolbar ? (
        <Toolbar
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          freeActions={generalActions}
          showSearch={showSearch}
          isAllRowsSelected={isAllRowsSelected}
          rows={showPagination ? page : rows}
          isNewToolbar={isNewToolbar}
          searchProps={searchProps}
          isAsyncData={asyncData}
          asyncSearchFilter={asyncSearchFilter}
          setAsyncSearchFilter={setAsyncSearchFilter}
          gotoPage={gotoPage}
        />
      ) : null}
      <TableContainer
        sx={[
          (theme) => ({
            ...(flatStyle && {
              boxShadow: 'none',
              border: `1px solid ${theme.palette.white.dark}`,
            }),
          }),
          tableContainerSx,
        ]}
      >
        {!isNewToolbar && showToolbar ? (
          <Toolbar
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            freeActions={generalActions}
            showSearch={showSearch}
            isAllRowsSelected={isAllRowsSelected}
            rows={showPagination ? page : rows}
            isAsyncData={asyncData}
            asyncSearchFilter={asyncSearchFilter}
            setAsyncSearchFilter={setAsyncSearchFilter}
            gotoPage={gotoPage}
          />
        ) : null}
        <Box
          sx={{
            position: 'relative',
            overflowX: 'auto',
            ...(stickyHeader && {
              overflow: 'initial',
            }),
          }}
        >
          <MTable
            aria-label="Aleluya table"
            {...getTableProps()}
            sx={{
              minWidth: tableMinWidth || minWidth,
              ...(isTableEditable && {
                tableLayout: 'fixed',
                width: '100%',
              }),
              ...(stickyHeader && {
                borderCollapse: 'collapse',
              }),
            }}
            stickyHeader={stickyHeader}
          >
            <>
              {showEmptyStateComponent ? (
                <TableBody>
                  {isTableLoading ? (
                    <Box
                      component="tr"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '13.375rem',
                      }}
                    >
                      <TableCell>
                        <CircularProgress />
                      </TableCell>
                    </Box>
                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: 0,
                        }}
                      >
                        {emptyStateComponent}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : null}
              {!showEmptyStateComponent ? (
                <>
                  <TableHead>
                    {headerGroups.map((headerGroup, index) => (
                      <TableRow
                        {...headerGroup.getHeaderGroupProps()}
                        key={`headerGruop + ${index}`}
                      >
                        {headerGroup.headers.map((column) => {
                          const isSortingEnabled =
                            column.id !== 'selection' &&
                            column.canSort &&
                            showSorting
                          return (
                            <TableCell
                              {...getCustomHeaderProps(
                                column,
                                customActionsWidth,
                                isTableEditable,
                                showSorting
                              )}
                              title={undefined}
                              key={column.id}
                            >
                              <Box
                                sx={getHeaderStyles(column)}
                                {...getHeaderWrapperProps(column)}
                              >
                                {isSortingEnabled ? (
                                  <TableSortLabel
                                    active={column.isSorted}
                                    direction={
                                      column.isSortedDesc ? 'desc' : 'asc'
                                    }
                                    title="Ordenar"
                                  >
                                    {column.render('Header')}
                                  </TableSortLabel>
                                ) : (
                                  column.render('Header')
                                )}
                                {column.tooltip ? (
                                  <TooltipInfoIcon
                                    title={column.tooltip}
                                    enterDelay={300}
                                    tooltipProps={{
                                      placement: 'bottom',
                                      tooltipSx: column.tooltipSx,
                                      disableInteractive: false,
                                    }}
                                  />
                                ) : null}
                              </Box>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {isTableEmpty &&
                    !showEmptyStateComponent &&
                    !showEmptySearchState ? (
                      <EmptyState
                        colSpan={emptyTableColSpan}
                        configuration={emptyState}
                        loading={isTableLoading}
                      />
                    ) : null}
                    {showEmptySearchState ? (
                      <EmptyState
                        colSpan={emptyTableColSpan}
                        configuration={{
                          description: `No se encontraron registros para la búsqueda "${
                            globalFilter || asyncSearchFilter
                          }"`,
                        }}
                        loading={isTableLoading}
                      />
                    ) : null}
                    {!isTableEmpty &&
                      (showPagination ? page : rows).map((row) => {
                        const providedRowStyle = rowStyle(row.original, row)
                        prepareRow(row)

                        if (isEditingRow(row.index)) {
                          return (
                            <EditRow
                              {...row.getRowProps()}
                              rowIndex={row.index}
                              cancelEditRow={onCancelEdit}
                              confirmEditRow={onConfirmEdit}
                              cells={row.cells}
                              rowValues={row.original}
                              validationSchema={validationSchema}
                              editMode={editMode}
                              setCurrentData={setCurrentData}
                              customActionsWidth={customActionsWidth}
                              alignActionsCell={alignActionsCell}
                              key={`editingRow + ${row.id}`}
                            />
                          )
                        }

                        if (isDeletingRow(row.index)) {
                          return (
                            <DeleteRow
                              {...row.getRowProps()}
                              rowIndex={row.index}
                              colSpan={deleteRowColSpan}
                              cancelEditRow={onCancelEdit}
                              confirmEditRow={onConfirmEdit}
                              rowValues={row.original}
                              customActionsWidth={customActionsWidth}
                              alignActionsCell={alignActionsCell}
                              key={`deletingRow + ${row.id}`}
                            />
                          )
                        }

                        return (
                          <TableRow
                            {...row.getRowProps()}
                            style={providedRowStyle}
                            sx={(theme) => ({
                              '&:hover': {
                                '& > td': {
                                  backgroundColor: alpha(
                                    theme.palette.complementary1.light,
                                    0.2
                                  ),
                                },
                              },
                              ...(isEditionActive
                                ? {
                                    transition: 'all 300ms ease 0s',
                                    opacity: 0.2,
                                    pointerEvents: 'none',
                                  }
                                : {
                                    transition: 'all 300ms ease 0s',
                                  }),
                            })}
                            key={row.id}
                          >
                            {row.cells.map((cell, index) => {
                              return (
                                <TableCell
                                  {...cell.getCellProps()}
                                  key={`tableCell + ${index}`}
                                >
                                  <RowCell cell={cell} />
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                    {/* Disable table when is loading */}
                    {isTableLoading ? (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: 0,
                          }}
                        >
                          <LoadingBackdrop
                            open={isTableLoading}
                            showLoader={!isTableEmpty}
                            sx={(theme) => ({
                              zIndex: theme.zIndex.drawer - 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            })}
                          />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </>
              ) : null}
            </>
          </MTable>
        </Box>
      </TableContainer>
      {/* Disable table when is loading */}
      {isTableLoading ? (
        <Box
          sx={(theme) => ({
            position: 'absolute',
            background: 'transparent',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: theme.zIndex.drawer + 2,
          })}
        />
      ) : null}
      {/* Pagination is outside of TableContainer to follow styles of original table */}
      {showPagination ? (
        <TablePagination
          count={
            tableQuery.isSuccess
              ? tableFiltersCustom?.total_records
              : dataMemoized.length
          }
          page={pageIndex}
          pageSize={pageSize}
          onPageChange={handleChangePage}
        />
      ) : null}
    </Box>
  )
}

export default Table
