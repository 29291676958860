import { useMutation } from 'react-query'

import { deleteWage } from 'services/organizer/worker/wageService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, wageId } = mutationData

  if (mutationMethod === 'DELETE') return deleteWage(wageId)

  return null
}

const useWageOrganizerService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()
  const wageOrganizerMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return { wageOrganizerMutation }
}

export default useWageOrganizerService
