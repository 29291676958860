import { Box, InputAdornment, TextField, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import { formatCurrency } from 'utils/format'

import messages from 'messages/payroll'

import Tooltip from '../common/Tooltip'

const Header = ({
  payroll,
  holidaysAverageSalary,
  noveltiesAverageSalary,
  licensesAverageSalary,
  activeNoveltyEditing = [],
  onEnableEditing,
  onDisableEditing,
  onChangeData,
  onOK,
  noveltyConcept,
  compensatedDaysAverageSalary,
}) => {
  const { contract_category: contractCategory } = payroll

  const noveltyData = {
    holidays: [
      {
        title: 'Base vacaciones disfrutadas',
        tooltip: 'average_salary',
        noveltyKey: 'holidays_average_salary',
        salaryValue: holidaysAverageSalary,
        salaryType: 'holidaysAverageSalary',
        editable: true,
      },
      {
        title: 'Base vacaciones compensadas',
        tooltip: 'compensated_days_average_salary',
        noveltyKey: 'compensated_days_average_salary',
        salaryValue: compensatedDaysAverageSalary,
        salaryType: 'compensatedDaysAverageSalary',
        editable: true,
      },
    ],
    incapacities: [
      {
        title: 'Salario base incapacidades',
        tooltip: 'novelties_average_salary',
        noveltyKey: 'novelties_average_salary',
        salaryValue: noveltiesAverageSalary,
        salaryType: 'noveltiesAverageSalary',
        editable: true,
      },
    ],
    licenses: [
      {
        title: 'Salario base licencias',
        tooltip: 'licenses_average_salary',
        noveltyKey: 'licenses_average_salary',
        salaryValue: licensesAverageSalary,
        salaryType: 'licensesAverageSalary',
        editable: true,
      },
      {
        title: 'Salario base licencias de maternidad/paternidad',
        tooltip: 'parentship_licenses_average_salary',
        noveltyKey: 'parentship_licenses_average_salary',
        salaryValue: 'IBC mes anterior',
        salaryType: 'parentshipLicensesAverageSalary',
        editable: false,
      },
    ],
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          marginBottom: theme.spacing(5),
        })}
      >
        {([
          'employee',
          'student_law_789',
          'terminated',
          'part_time_contract',
        ].includes(contractCategory) &&
          noveltyConcept === 'holidays') ||
        noveltyConcept !== 'holidays' ? (
          <Box
            sx={(theme) => ({
              display: 'grid',
              gap: theme.spacing(2),
              gridTemplateColumns: '1fr',
              width: '100%',
              [theme.breakpoints.up('tablet')]: {
                gridTemplateColumns:
                  'minmax(16.125rem, 1fr) minmax(16.125rem, 1fr)',
              },
            })}
          >
            {noveltyData[noveltyConcept].map((novelty) => {
              return (
                <SalaryBase
                  isEditing={activeNoveltyEditing.includes(novelty.noveltyKey)}
                  title={novelty.title}
                  contentTooltip={messages[novelty.tooltip]}
                  onChangeData={(e) => onChangeData(e, novelty.noveltyKey)}
                  onEditing={() => onEnableEditing(novelty.noveltyKey)}
                  salaryValue={novelty.salaryValue}
                  salaryType={novelty.salaryType}
                  concept={noveltyConcept === 'holidays' ? 'holidays' : 'all'}
                  onDisableEditing={() => onDisableEditing(novelty.noveltyKey)}
                  onOK={() => onOK(novelty.noveltyKey)}
                  isEditable={novelty.editable}
                  key={novelty.title}
                />
              )
            })}
          </Box>
        ) : null}
      </Box>
    </>
  )
}

export default Header

const SalaryBase = ({
  isEditing,
  title,
  contentTooltip,
  onChangeData,
  onEditing,
  salaryValue,
  salaryType,
  concept,
  onDisableEditing,
  onOK,
  isEditable,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '9.75rem',
        boxShadow: theme.shadows[4],
        padding: theme.spacing(2),
        borderRadius: '0.5rem',
        borderBottom: `0.25rem solid ${
          concept === 'holidays'
            ? theme.palette.primary.light
            : theme.palette.complementary2.light
        }`,
        [theme.breakpoints.up('tablet')]: {
          width: '100%',
          minWidth: '16.125rem',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
        })}
      >
        <Typography variant="body1" htmlFor={`input_${salaryType}`}>
          {title}
        </Typography>
        <Tooltip title={contentTooltip} disableInteractive={false} />
      </Box>
      {isEditing ? (
        <TextField
          id={`input_${salaryType}`}
          value={salaryValue}
          onChange={onChangeData}
          InputProps={{
            inputComponent: NumberFormatField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          data-cy={`input-${salaryType}`}
          sx={{
            maxWidth: '14rem',
          }}
        />
      ) : (
        <>
          <Typography
            component="b"
            variant="h1"
            display="block"
            data-cy={`${salaryType}-value`}
          >
            {typeof salaryValue === 'number'
              ? formatCurrency(salaryValue)
              : salaryValue}
          </Typography>

          {isEditable ? (
            <Button
              onClick={onEditing}
              data-cy={`edit-${salaryType}`}
              variant="text"
              sx={(theme) => ({
                textDecoration: 'underline',
                color: theme.palette.accent4.main,
                padding: 0,
                height: 'auto',
                minWidth: 'max-content',
                '&:hover': {
                  color: theme.palette.accent4.main,
                },
              })}
            >
              Editar
            </Button>
          ) : null}
        </>
      )}
      {isEditing ? (
        <HeaderActions onCancel={onDisableEditing} onOK={onOK} />
      ) : null}
    </Box>
  )
}

const HeaderActions = ({ onCancel, onOK }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing(1),
      })}
    >
      <Button onClick={onOK} size="small" data-cy="ok_button_average_salaries">
        Guardar
      </Button>
      <Button variant="outlined" onClick={onCancel} size="small">
        Cancelar
      </Button>
    </Box>
  )
}
