import { Box, Card, Typography } from '@mui/material'

import EventCard from './EventCard'
import EventDetailModal from './EventDetailModal'
import useEventDetailModal from './useEventDetailModal'

const ComingSoonEvents = ({
  anniversaries,
  birthdays,
  contractsToExpire,
  isLoading,
}) => {
  const { eventDetailModalState, openEventDetailModal, closeEventDetailModal } =
    useEventDetailModal()

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridColumn: '1 / -1',
        [theme.breakpoints.up('laptop')]: {
          gridColumn: '1 / -1',
          gridRow: '2',
        },
      })}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({ marginBottom: theme.spacing(3) })}
      >
        Fechas importantes
      </Typography>
      <Card
        sx={(theme) => ({
          borderRadius: '1rem',
          padding: theme.spacing(2, 3),
          minHeight: 'fit-content',
          [theme.breakpoints.up('tablet')]: {
            maxHeight: '17.5rem',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            rowGap: theme.spacing(2),
            columnGap: theme.spacing(4),
            gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            },
          })}
        >
          <EventCard
            eventType="birthdays"
            isLoading={isLoading}
            data={birthdays}
            handleShowDetail={openEventDetailModal}
          />
          <EventCard
            eventType="anniversaries"
            isLoading={isLoading}
            data={anniversaries}
            handleShowDetail={openEventDetailModal}
          />
          <EventCard
            eventType="contracts_to_expire"
            isLoading={isLoading}
            data={contractsToExpire}
            handleShowDetail={openEventDetailModal}
          />
        </Box>
      </Card>
      {eventDetailModalState.open ? (
        <EventDetailModal
          state={eventDetailModalState}
          handleClose={closeEventDetailModal}
        />
      ) : null}
    </Box>
  )
}

export default ComingSoonEvents
