import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  addCreditCard,
  addPSEPayment,
  getPaymentResponsePSE,
  updateAutomaticDebit,
} from 'services/subscription/subscriptionService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getPaymentPSE') return getPaymentResponsePSE()

  return null
}

const getMutationFunction = (mutationData) => {
  const { paymentMethod, paymentMethodData } = mutationData

  if (paymentMethod === 'automatic_debit')
    return updateAutomaticDebit(paymentMethodData)

  if (paymentMethod === 'credit_card') return addCreditCard(paymentMethodData)

  if (['bank_transfer', 'pse'].includes(paymentMethod))
    return addPSEPayment(paymentMethodData)

  return null
}

const usePaymentMethodService = ({
  serviceParams = { queryKey: 'getPaymentPSE' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data, ...restPaymentMethodResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const paymentMethodMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    paymentMethodQuery: { data, ...restPaymentMethodResponse },
    paymentMethodMutation,
  }
}

export default usePaymentMethodService
