import { Formik } from 'formik'

import Modal from 'components/UI/Modal/Modal'

import {
  getInitialValues,
  getValidationSchema,
  useActionToRender,
} from './helpers'

export default function CommentModal({ state = {}, handleClose, callback }) {
  const { open, commentData, action, companyId } = state

  const { headerLabel, okLabel, cancelLabel, Content, handleClick, isLoading } =
    useActionToRender({ commentData, action, companyId, callback })

  const validationSchema = getValidationSchema()
  const initialValues = getInitialValues(commentData)

  return (
    <Formik
      onSubmit={handleClick}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit: onSubmit }) => (
        <Modal
          open={open}
          header={headerLabel}
          onCancel={handleClose}
          onCloseModal={handleClose}
          hideOkButton={action === 'show'}
          onOk={onSubmit}
          okText={okLabel}
          cancelText={cancelLabel}
          isLoading={isLoading}
          dialogProps={{
            maxWidth: 'sm',
            fullWidth: true,
          }}
        >
          <Content />
        </Modal>
      )}
    </Formik>
  )
}
