import http, { getApiURL } from '../httpService'

export const getBackgroundCheckResults = (workerId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/background_checks`,
  })

  return http.getV2(url)
}

export const createBackgroundCheck = (workerId) => {
  const url = getApiURL({
    pathname: `/workers/${workerId}/background_checks`,
  })

  return http.postV2(url)
}

export default {
  getBackgroundCheckResults,
  createBackgroundCheck,
}
