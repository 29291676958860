import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'
import AccountTypeField from 'components/UI/Formik/CommonFields/AccountTypeField'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import FormField from 'components/UI/Formik/FormField/Index'

import { isArusDist } from 'utils/auth'
import { paymentMethodsOptions, payrollFrequenciesOptions } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import { booleanLookupOptions } from 'utils/table'

import messages from 'messages/company_form'

const CompanyFormFieldsPaymentPreferences = ({
  ssOperators,
  riskProviders,
}) => {
  const form = useFormikContext()

  const { values } = form
  const companyPaymentMethodsOptions = paymentMethodsOptions.filter(
    (paymentOption) => paymentOption.value !== 'check'
  )

  return (
    <Box
      sx={{
        maxWidth: '43.75rem',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <FormField
          name="payroll_frequency"
          label="Frecuencia de Pago"
          variant="select"
          options={payrollFrequenciesOptions}
          optional={false}
        />
        <FormField
          name="payment_method"
          label="Medio de pago"
          variant="select"
          options={companyPaymentMethodsOptions}
          optional={false}
        />
        {values.payment_method === 'wire_transfer' ? (
          <>
            <BankField
              label="Banco del cual realizarás los pagos"
              optional={false}
            />
            <AccountTypeField optional={false} />
            <AccountNumberField
              tooltipContent={messages.account_number}
              optional={false}
            />
          </>
        ) : null}
      </Box>
      <Typography
        variant="h6"
        color="primary"
        sx={(theme) => ({
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(4),
        })}
      >
        Configura la seguridad social
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        {!isArusDist() ? (
          <FormField
            name="ss_operator"
            label="Operador de pago PILA"
            variant="autocomplete"
            options={ssOperators}
            optional={false}
          />
        ) : null}
        <FormField
          name="risk_provider"
          label="Administradora de riesgos ARL"
          variant="autocomplete"
          options={riskProviders}
          optional={false}
        />
        <FormField
          name="law_1607"
          label="Aportante exonerado de pago - Ley 1607 de 2012"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.law_1607}
          row
        />
        <FormField
          name="law_590"
          label="Aplica para la Ley 590 de 2000"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.law_590}
          onChange={({ target: { value } }) => {
            let newValue
            if (value === 'true') newValue = true
            else if (value === 'false') newValue = false
            else newValue = value

            form.setFieldValue('law_590', newValue)
            if (newValue === true && values.law_1429 === true) {
              form.setFieldValue('law_1429', false)
            }
          }}
          row
        />
        <FormField
          name="constitution_date"
          label="Fecha de Constitución"
          variant="datepicker"
          maxDate={formatDate(new Date())}
          tooltipContent={messages.constitution_date}
          optional={false}
        />
        <FormField
          name="law_1429"
          label="Aplica para la Ley 1429 de 2010"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.law_1429}
          onChange={({ target: { value } }) => {
            let newValue
            if (value === 'true') newValue = true
            else if (value === 'false') newValue = false
            else newValue = value

            form.setFieldValue('law_1429', newValue)
            if (newValue === true && values.law_590 === true) {
              form.setFieldValue('law_590', false)
            }
          }}
          row
        />
        <FormField
          name="decree_558"
          label="Aplica para Decreto 558 de 2020"
          variant="radio-group"
          options={booleanLookupOptions}
          tooltipContent={messages.decree_558}
          row
        />
      </Box>
    </Box>
  )
}

export default CompanyFormFieldsPaymentPreferences
