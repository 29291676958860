import { useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  accordionClasses,
  accordionSummaryClasses,
  alpha,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import LordIcon from 'components/UI/LordIcon'

import { capitalize } from 'utils/general'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import {
  getIcon,
  getStatus,
  getStatusBackgroundColor,
  statusColor,
} from './helpers'

const BackgroundCheckElement = ({ result, handleCreateBackgroundCheck }) => {
  const [expanded, setExpanded] = useState(false)
  const { label, response, status, file, coded_name: codedName } = result
  const isDisabled = ['in_progress', 'pending'].includes(status)

  const handleChange = (panel) => (_, newExpanded) => {
    if (!isDisabled) {
      setExpanded(newExpanded ? panel : false)
    }
  }

  const defaultResponse =
    !response && !file && status === 'not_apply'
      ? 'El tipo de documento no es soportado para esta consulta'
      : null

  const notFoundResponse =
    !response && !file && status !== 'not_apply'
      ? 'No se encontró la respuesta de esta institución'
      : null

  const downloadURI = useDownloadURI()

  const downloadFile = () => {
    downloadURI(file)
  }

  const icon = getIcon[codedName]

  return (
    <Accordion
      expanded={expanded === label && !isDisabled}
      onChange={handleChange(label)}
      disableGutters
      sx={{
        [`&.${accordionClasses.root}`]: {
          borderTop: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<Icon name="arrow-down" basic />}
        aria-controls={`${label}-content`}
        id={label}
        sx={(theme) => ({
          height: '3rem',
          borderBottom: `1px solid ${theme.palette.white.light}`,
          [`& .${accordionSummaryClasses.content}`]: {
            margin: theme.spacing(0),
          },
          [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
            color: theme.palette.black.light,
            [`&.${accordionSummaryClasses.expanded}`]: {
              color: `${theme.palette.black.main} !important`,
            },
            ...(isDisabled && {
              opacity: 0.2,
            }),
          },
          [`& .${accordionSummaryClasses.expanded}`]: {
            color: theme.palette.black.main,
          },
          '&:hover': {
            backgroundColor: 'transparent',
            cursor: isDisabled ? 'not-allowed !important' : 'pointer',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            columnGap: theme.spacing(1.5),
            gridTemplateColumns: 'auto 9rem 1fr',
            alignItems: 'center',
            width: '100%',
          })}
        >
          <Icon
            name={icon}
            basic
            sx={(theme) => ({
              color: theme.palette.black.main,
              fontSize: '2rem',
            })}
          />
          <Typography variant="body1">{capitalize(label)}</Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(1),
            })}
          >
            <Chip
              size="small"
              label={
                <Typography
                  variant="tiny"
                  fontWeight={700}
                  sx={(theme) => ({
                    color: `${
                      theme.palette[statusColor[status]]?.dark
                    } !important`,
                  })}
                >
                  {getStatus[status]}
                </Typography>
              }
              sx={(theme) => ({
                width: '100%',
                maxWidth: '7.5rem',
                backgroundColor: alpha(
                  getStatusBackgroundColor(theme, status),
                  0.4
                ),
              })}
            />
            {status === 'pending' ? (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  handleCreateBackgroundCheck()
                }}
              >
                <Icon name="refresh" basic />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          backgroundColor: theme.palette.white.light,
          padding: theme.spacing(3, 0, 3, 2),
        })}
      >
        {defaultResponse ? (
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.black.dark,
            })}
          >
            {defaultResponse}
          </Typography>
        ) : null}
        {notFoundResponse ? (
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.black.dark,
            })}
          >
            {notFoundResponse}
          </Typography>
        ) : null}
        {response ? (
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.black.dark,
            })}
          >
            {response}
          </Typography>
        ) : null}
        {file ? (
          <Box
            className="animated_icon_box"
            sx={(theme) => ({
              display: 'grid',
              maxWidth: '55%',
              width: '100%',
              height: '5rem',
              gridTemplateColumns: '5rem 1fr',
              backgroundColor: theme.palette.white.main,
              borderRadius: '0.5rem',
              color: theme.palette.black.dark,
              boxShadow: theme.shadows[2],
              overflow: 'hidden',
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.complementary1.light,
              })}
            >
              <LordIcon name="approved-document" target="animated_icon_box" />
            </Box>
            <Box
              sx={(theme) => ({
                padding: theme.spacing(0, 0, 0, 2),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: theme.spacing(0.5),
              })}
            >
              <Typography variant="h6" color="black.main">
                Documento {label}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  width: '6.5rem',
                }}
                onClick={downloadFile}
              >
                Ver
              </Button>
            </Box>
          </Box>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

export default BackgroundCheckElement
