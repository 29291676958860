import { useLocation } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import usePartnerSubscriptionModal from 'components/Subscription/Index/Plan/PartnerSubscription/usePartnerSubscriptionModal'
import useHolisticPayrollMVPModal from 'components/Subscription/Index/Plan/useHolisticPayrollMVPModal'

import { isArusDist } from 'utils/auth'
import { isTest } from 'utils/general'

import { COMPANY_INDEX } from 'config/routes'

import { useUser } from '../UserContext/useUser'
import { getSubscriptionAlertCopy, useButtonConfig } from './helpers'

const SubscriptionAlertCard = ({ handleCloseMobileDrawer }) => {
  const location = useLocation()
  const { openPartnerSubscriptionModal } = usePartnerSubscriptionModal()
  const { openHolisticPayrollMVPModal } = useHolisticPayrollMVPModal()
  const { isAPartnerChild, subscription } = useUser()
  const { isHolisticPayrollMVPCompany } = useSubscription()
  const { status: subscriptionStatus } = subscription || {}
  const { label, action } = useButtonConfig(subscriptionStatus)
  const hasExpiredSubscription = [
    'premium_expired',
    'premium_canceled',
  ].includes(subscriptionStatus)

  const handleUpdateSubscription = () => {
    if (isHolisticPayrollMVPCompany && !isTest) {
      openHolisticPayrollMVPModal()
      handleCloseMobileDrawer()
      return
    }

    if (isAPartnerChild) {
      openPartnerSubscriptionModal()
    } else {
      action()
    }

    handleCloseMobileDrawer()
  }

  if (
    location.pathname === COMPANY_INDEX() ||
    location.pathname.includes('/organizer')
  )
    return null

  return (
    <Box
      sx={(theme) => ({
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(1),
        alignItems: 'center',
        marginTop: theme.spacing(7.5),
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.white.dark,
        borderRadius: '1rem',
        margin: theme.spacing(3),
        ...(hasExpiredSubscription && {
          backgroundColor: theme.palette.complementary2.light,
        }),
      })}
    >
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        ¡¡¡Ohh, Yisus!!!
      </Typography>
      <Box
        sx={(theme) => ({
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(1),
        })}
      >
        {getSubscriptionAlertCopy(subscription)}
      </Box>
      <Button
        onClick={handleUpdateSubscription}
        sx={(theme) => ({
          paddingX: theme.spacing(1),
          zIndex: 1000,
        })}
        variant={hasExpiredSubscription ? 'contained' : 'outlined'}
      >
        <Typography
          variant="lead2"
          color={
            isArusDist() && hasExpiredSubscription ? 'white.main' : 'black.main'
          }
        >
          {label}
        </Typography>
      </Button>
    </Box>
  )
}

export default SubscriptionAlertCard
