import { useFormikContext } from 'formik'

import FormField from '../FormField/Index'

const AccountNumberField = ({ optional, name, ...props }) => {
  const { values } = useFormikContext()

  const accountNumberLength =
    values?.automatic_debit_form?.account_number?.length ||
    values?.account_number?.length

  return (
    <FormField
      name={name || 'account_number'}
      label="Número de cuenta"
      variant="number"
      decimalScale={0}
      isNumericString
      allowLeadingZeros
      inputProps={{ maxLength: 20 }}
      style={{ fontFamily: 'Roboto Mono, monospace' }}
      format={accountNumberLength >= 9 ? '###-######-########' : '###-######'}
      optional={optional}
      {...props}
    />
  )
}

export default AccountNumberField
