import http, { getApiURL } from '../httpService'

export const getCompanyOnboardings = ({ filter, search, page }) => {
  const url = getApiURL({
    pathname: `/worker_onboardings`,
    searchParams: { page, search, filter },
  })

  return http.getV2(url)
}

export const updateOnboarding = ({ onboardingId, onboardingData }) => {
  const url = getApiURL({ pathname: `/worker_onboardings/${onboardingId}` })

  return http.putV2(url, onboardingData)
}

export default { getCompanyOnboardings, updateOnboarding }
