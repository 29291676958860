import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

const EndDayField = ({ optional }) => {
  const { values } = useFormikContext()

  return values.term === 'fixed' || values.term === 'project' ? (
    <FormField
      name="end_day"
      label={
        values.term === 'fixed'
          ? 'Fecha de finalización'
          : 'Fecha estimada de finalización de la obra o labor'
      }
      variant="datepicker"
      disabled={!values.initial_day}
      minDate={values.initial_day}
      optional={optional}
    />
  ) : null
}

export default EndDayField
