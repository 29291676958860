import { Box } from '@mui/material'

const LordIcon = ({
  id,
  name,
  trigger = 'loop-on-hover',
  target = undefined,
  sx = {},
}) => {
  return (
    <Box
      id={id}
      sx={[
        {
          '& > lord-icon': {
            width: '4rem',
            height: '4rem',
          },
        },
        sx,
      ]}
    >
      <lord-icon trigger={trigger} target={`.${target}`} icon={name} />
    </Box>
  )
}

export default LordIcon
