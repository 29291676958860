import { Box, Typography, useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'

import sitYellowWoman from 'assets/images/views/common/sit_yellow_woman.png'

import useModals from '../../../../App/ModalsManager/useModals'

export default function PartnerChildPremiumExpiredModal() {
  const modals = useModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box sx={{ display: 'flex' }}>
      {!isMobile ? (
        <Box
          sx={(theme) => ({
            margin: theme.spacing(-3, 0, -5, -3),
            minWidth: '20rem',
            minHeight: '20rem',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('tablet')]: {
              margin: theme.spacing(-3, 0, -5, -3),
              paddingLeft: theme.spacing(2),
              overflow: 'hidden',
              position: 'relative',
            },
            [theme.breakpoints.up('laptop')]: {
              margin: theme.spacing(-4, 0, -5, -4),
            },
          })}
        >
          <Box
            sx={{
              width: '28rem',
              height: '28rem',
              borderRadius: '100%',
              backgroundColor: 'accent1.main',
              position: 'absolute',
              right: 0,
              top: '-3.21rem',
            }}
          />
          <Box
            component="img"
            src={sitYellowWoman}
            sx={{ width: '14.37rem', height: '13.35rem', zIndex: 2 }}
          />
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          marginLeft: theme.spacing(2.5),
          justifyContent: 'space-between',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4">!Ohhh, Yisus!</Typography>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginTop: theme.spacing(3) })}
          >
            Has perdido los superpoderes incluidos en tu plan. Comunícate con
            soporte o con el encargado del pago de tu suscripción para poder
            recuperarlos.
          </Typography>
        </Box>
        <Button
          sx={(theme) => ({
            width: { mobile: '100%', tablet: '12.5rem' },
            marginTop: theme.spacing(7),
          })}
          onClick={() => modals.closeAll()}
        >
          Cerrar
        </Button>
      </Box>
    </Box>
  )
}
