import PayrollContent from './Content'
import PayrollDataTable from './DataTable/DataTable'
import GeneratedPayroll from './GeneratedPayroll'

const Payroll = ({ payPreviousPayroll }) => {
  return (
    <PayrollContent
      payPreviousPayroll={payPreviousPayroll}
      generatedPayrollContent={<GeneratedPayroll />}
      payPayrollContent={<PayrollDataTable />}
    />
  )
}

export default Payroll
