import { Box } from '@mui/material'

import messages from 'messages/payroll'

import AddButtonWrapper from '../common/AddButtonWrapper'
import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import GroupDivider from '../common/GroupDivider'
import DateRangeContent from './DateRangeContent'

const Licenses = ({
  payroll,
  licenses,
  onAddNovelty,
  onChangeNovelty,
  onDeleteNovelty,
  selectedDays,
  contract_category: contractCategory,
  activeTab,
}) => {
  // Paid Leave
  const { items: paidLeaveItems } = licenses.paid_leave
  const { items: familyDayLeaveItems } = licenses.family_day_leave
  const { items: bereavementLeaveItems } = licenses.bereavement_leave
  const { items: maternityLeaveItems } = licenses.maternity_leave
  const { items: paternityLeaveItems } = licenses.paternity_leave
  // Unpaid Leave
  const { items: unpaidLeaveItems } = licenses.unpaid_leave
  const { items: jobAbandonmentItems } = licenses.job_abandonment
  const { items: suspensionItems } = licenses.suspension

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('tablet')]: {
          gap: theme.spacing(2),
        },
      })}
    >
      {/* Paid Leave */}
      {activeTab === 0 ? (
        <Box
          sx={{
            animation: 'fadeIn 700ms',
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
              },
              '100%': {
                opacity: 1,
              },
            },
          }}
        >
          {[
            'employee',
            'student_law_789',
            'terminated',
            'part_time_contract',
          ].includes(contractCategory) ? (
            <>
              <GridRow>
                <HeaderCell
                  sx={{
                    gridColumn: '1',
                  }}
                  mobileOnly
                >
                  Concepto
                </HeaderCell>
                <AddButtonWrapper
                  showButton={
                    paidLeaveItems.length >= 1 &&
                    paidLeaveItems[paidLeaveItems.length - 1].initial_day &&
                    paidLeaveItems[paidLeaveItems.length - 1].end_day
                  }
                  noveltyType="licenses"
                  noveltyCode="paid_leave"
                  onAddNovelty={onAddNovelty}
                >
                  <DateRangeContent
                    title="Licencia remunerada"
                    data={licenses.paid_leave}
                    onAddNovelty={onAddNovelty}
                    onChangeNovelty={onChangeNovelty}
                    onDeleteNovelty={onDeleteNovelty}
                    noveltyType="licenses"
                    noveltyCode="paid_leave"
                    selectedDays={selectedDays}
                    payroll={payroll}
                  />
                </AddButtonWrapper>
              </GridRow>
              <GroupDivider />
            </>
          ) : null}
          {[
            'employee',
            'student_law_789',
            'terminated',
            'part_time_contract',
          ].includes(contractCategory) ? (
            <GridRow>
              <HeaderCell
                sx={{
                  gridColumn: '1',
                }}
                mobileOnly
              >
                Concepto
              </HeaderCell>
              <AddButtonWrapper
                showButton={
                  familyDayLeaveItems.length >= 1 &&
                  familyDayLeaveItems[familyDayLeaveItems.length - 1]
                    .initial_day &&
                  familyDayLeaveItems[familyDayLeaveItems.length - 1].end_day
                }
                noveltyType="licenses"
                noveltyCode="family_day_leave"
                onAddNovelty={onAddNovelty}
              >
                <DateRangeContent
                  title="Día de la familia"
                  data={licenses.family_day_leave}
                  onAddNovelty={onAddNovelty}
                  onChangeNovelty={onChangeNovelty}
                  onDeleteNovelty={onDeleteNovelty}
                  noveltyType="licenses"
                  noveltyCode="family_day_leave"
                  selectedDays={selectedDays}
                  payroll={payroll}
                />
              </AddButtonWrapper>
            </GridRow>
          ) : null}
          {[
            'employee',
            'student_law_789',
            'terminated',
            'part_time_contract',
          ].includes(contractCategory) ? (
            <GridRow>
              <HeaderCell
                sx={{
                  gridColumn: '1',
                }}
                mobileOnly
              >
                Concepto
              </HeaderCell>
              <AddButtonWrapper
                showButton={
                  bereavementLeaveItems.length >= 1 &&
                  bereavementLeaveItems[bereavementLeaveItems.length - 1]
                    .initial_day &&
                  bereavementLeaveItems[bereavementLeaveItems.length - 1]
                    .end_day
                }
                noveltyType="licenses"
                noveltyCode="bereavement_leave"
                onAddNovelty={onAddNovelty}
              >
                <DateRangeContent
                  title="Licencia de luto"
                  data={licenses.bereavement_leave}
                  onAddNovelty={onAddNovelty}
                  onChangeNovelty={onChangeNovelty}
                  onDeleteNovelty={onDeleteNovelty}
                  noveltyType="licenses"
                  noveltyCode="bereavement_leave"
                  selectedDays={selectedDays}
                  payroll={payroll}
                />
              </AddButtonWrapper>
            </GridRow>
          ) : null}
          {contractCategory !== 'part_time_contract' ? (
            <>
              <GridRow>
                <HeaderCell
                  sx={{
                    gridColumn: '1',
                  }}
                  mobileOnly
                >
                  Concepto
                </HeaderCell>
                <AddButtonWrapper
                  showButton={
                    maternityLeaveItems.length >= 1 &&
                    maternityLeaveItems[maternityLeaveItems.length - 1]
                      .initial_day &&
                    maternityLeaveItems[maternityLeaveItems.length - 1].end_day
                  }
                  noveltyType="licenses"
                  noveltyCode="maternity_leave"
                  onAddNovelty={onAddNovelty}
                >
                  <DateRangeContent
                    title="Licencia de maternidad"
                    data={licenses.maternity_leave}
                    onAddNovelty={onAddNovelty}
                    onChangeNovelty={onChangeNovelty}
                    onDeleteNovelty={onDeleteNovelty}
                    noveltyType="licenses"
                    noveltyCode="maternity_leave"
                    contentTooltip={messages.maternity_leave}
                    selectedDays={selectedDays}
                    payroll={payroll}
                  />
                </AddButtonWrapper>
              </GridRow>
              <GroupDivider />
            </>
          ) : null}
          {contractCategory !== 'part_time_contract' ? (
            <GridRow>
              <HeaderCell
                sx={{
                  gridColumn: '1',
                }}
                mobileOnly
              >
                Concepto
              </HeaderCell>
              <AddButtonWrapper
                showButton={
                  paternityLeaveItems.length >= 1 &&
                  paternityLeaveItems[paternityLeaveItems.length - 1]
                    .initial_day &&
                  paternityLeaveItems[paternityLeaveItems.length - 1].end_day
                }
                noveltyType="licenses"
                noveltyCode="paternity_leave"
                onAddNovelty={onAddNovelty}
              >
                <DateRangeContent
                  title="Licencia de paternidad"
                  data={licenses.paternity_leave}
                  onAddNovelty={onAddNovelty}
                  onChangeNovelty={onChangeNovelty}
                  onDeleteNovelty={onDeleteNovelty}
                  noveltyType="licenses"
                  noveltyCode="paternity_leave"
                  contentTooltip={messages.paternity_leave}
                  selectedDays={selectedDays}
                  payroll={payroll}
                />
              </AddButtonWrapper>
            </GridRow>
          ) : null}
        </Box>
      ) : null}
      {/* Unpaid Leave */}
      {activeTab === 1 ? (
        <Box
          sx={{
            animation: 'fadeIn 700ms',
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
              },
              '100%': {
                opacity: 1,
              },
            },
          }}
        >
          {[
            'employee',
            'student_law_789',
            'terminated',
            'part_time_contract',
          ].includes(contractCategory) ? (
            <>
              <GridRow>
                <HeaderCell
                  sx={{
                    gridColumn: '1',
                  }}
                  mobileOnly
                >
                  Concepto
                </HeaderCell>
                <AddButtonWrapper
                  showButton={
                    unpaidLeaveItems.length >= 1 &&
                    unpaidLeaveItems[unpaidLeaveItems.length - 1].initial_day &&
                    unpaidLeaveItems[unpaidLeaveItems.length - 1].end_day
                  }
                  noveltyType="licenses"
                  noveltyCode="unpaid_leave"
                  onAddNovelty={onAddNovelty}
                >
                  <DateRangeContent
                    title="Licencia no remunerada"
                    data={licenses.unpaid_leave}
                    onAddNovelty={onAddNovelty}
                    onChangeNovelty={onChangeNovelty}
                    onDeleteNovelty={onDeleteNovelty}
                    noveltyType="licenses"
                    noveltyCode="unpaid_leave"
                    contentTooltip={messages.unpaid_leave}
                    selectedDays={selectedDays}
                    payroll={payroll}
                  />
                </AddButtonWrapper>
              </GridRow>
            </>
          ) : null}
          {[
            'employee',
            'student_law_789',
            'schooling_trainee',
            'productive_trainee',
            'intern',
            'terminated',
            'part_time_contract',
          ].includes(contractCategory) ? (
            <>
              <GroupDivider />
              <GridRow>
                <HeaderCell
                  sx={{
                    gridColumn: '1',
                  }}
                  mobileOnly
                >
                  Concepto
                </HeaderCell>
                <AddButtonWrapper
                  showButton={
                    suspensionItems.length >= 1 &&
                    suspensionItems[suspensionItems.length - 1].initial_day &&
                    suspensionItems[suspensionItems.length - 1].end_day
                  }
                  noveltyType="licenses"
                  noveltyCode="suspension"
                  onAddNovelty={onAddNovelty}
                >
                  <DateRangeContent
                    title="Suspensión"
                    data={licenses.suspension}
                    onAddNovelty={onAddNovelty}
                    onChangeNovelty={onChangeNovelty}
                    onDeleteNovelty={onDeleteNovelty}
                    noveltyType="licenses"
                    noveltyCode="suspension"
                    contentTooltip={messages.suspention}
                    selectedDays={selectedDays}
                    payroll={payroll}
                  />
                </AddButtonWrapper>
              </GridRow>
            </>
          ) : null}
          {[
            'employee',
            'student_law_789',
            'terminated',
            'part_time_contract',
          ].includes(contractCategory) ? (
            <>
              <GroupDivider />
              <GridRow>
                <HeaderCell
                  sx={{
                    gridColumn: '1',
                  }}
                  mobileOnly
                >
                  Concepto
                </HeaderCell>
                <AddButtonWrapper
                  showButton={
                    jobAbandonmentItems.length >= 1 &&
                    jobAbandonmentItems[jobAbandonmentItems.length - 1]
                      .initial_day &&
                    jobAbandonmentItems[jobAbandonmentItems.length - 1].end_day
                  }
                  noveltyType="licenses"
                  noveltyCode="job_abandonment"
                  onAddNovelty={onAddNovelty}
                >
                  <DateRangeContent
                    title="Ausencia injustificada"
                    data={licenses.job_abandonment}
                    onAddNovelty={onAddNovelty}
                    onChangeNovelty={onChangeNovelty}
                    onDeleteNovelty={onDeleteNovelty}
                    noveltyType="licenses"
                    noveltyCode="job_abandonment"
                    contentTooltip={messages.job_abandonment}
                    selectedDays={selectedDays}
                    payroll={payroll}
                  />
                </AddButtonWrapper>
              </GridRow>
            </>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}

export default Licenses
