import { Box, Divider, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'

const EmployerData = ({ workerId }) => {
  const user = useUser()

  const affiliationQueryKey = ['getAffiliationById', workerId]
  const { affiliationsQuery } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId,
    },
  })
  const currentAffiliationComment = affiliationsQuery?.data?.comment

  const employerData = [
    {
      id: 'company_name',
      label: 'Nombre de la empresa',
      value: user.company.name,
      icon: 'id-business-card',
    },
    {
      id: 'company_phone',
      label: 'Celular',
      value: user.company.phone,
      icon: 'hand-phone',
    },
  ]

  return (
    <>
      <Divider
        sx={(theme) => ({
          margin: theme.spacing(0, 4, 6),
          backgroundColor: theme.palette.black.light,
        })}
      />
      <Box
        sx={(theme) => ({
          margin: theme.spacing(0, 4, 6),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            margin: theme.spacing(0, 0, 4),
          })}
        >
          {employerData.map((item) => {
            const { icon } = item
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
                key={item.id}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    backgroundColor: theme.palette.complementary1.light,
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: theme.spacing(1),
                    minWidth: '3.125rem',
                    maxWidth: '3.125rem',
                    minHeight: '3.125rem',
                    maxHeight: '3.125rem',
                  })}
                >
                  <Icon name={icon} sx={{ fontSize: '2rem' }} />
                </Box>
                <Box>
                  <Typography variant="h5">{item.value}</Typography>
                  <Typography variant="body1" color="black.dark">
                    {item.label}
                  </Typography>
                </Box>
              </Box>
            )
          })}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              backgroundColor: theme.palette.complementary1.light,
              borderRadius: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: theme.spacing(1),
              minWidth: '3.125rem',
              maxWidth: '3.125rem',
              minHeight: '3.125rem',
              maxHeight: '3.125rem',
            })}
          >
            <Icon name="edit-document" sx={{ fontSize: '2rem' }} />
          </Box>
          <Typography variant="h5">Comentario</Typography>
        </Box>
        <Typography
          variant="body1"
          sx={(theme) => ({ margin: theme.spacing(1, 0, 0, 1) })}
        >
          {currentAffiliationComment !== null
            ? currentAffiliationComment
            : 'No hay ningún comentario'}
        </Typography>
      </Box>
      <Divider
        sx={(theme) => ({
          margin: theme.spacing(6, 4),
          backgroundColor: theme.palette.black.light,
        })}
      />
    </>
  )
}

export default EmployerData
