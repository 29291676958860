import { Slider as MSlider, SliderThumb, alpha } from '@mui/material'

import Emoji from '../Emoji'

/**
 *
 * @param {string} backgroundColor - MuiSlider-track color
 * @param {number} maxValue - The maximum allowed value of the slider and form field
 * @param {number} minValue - Optional - The minimum allowed value of the slider and form field
 * @param {number} stepValue - The granularity with which the slider can step through values
 *
 * @returns
 */

const CustomThumb = ({ children, thumbIcon, ...props }) => (
  <SliderThumb {...props}>
    {children}
    <Emoji size="2.5rem" code={thumbIcon} />
  </SliderThumb>
)

export default function Slider({
  backgroundColor,
  maxValue,
  minValue,
  stepValue,
  ...props
}) {
  return (
    <MSlider
      aria-labelledby="input-slider"
      step={stepValue}
      min={minValue}
      max={maxValue}
      valueLabelDisplay="auto"
      components={{
        Thumb: CustomThumb,
      }}
      sx={(theme) => ({
        zIndex: 1,
        '&.MuiSlider-root': {
          height: '1.25rem',
        },
        '& .MuiSlider-rail': {
          backgroundColor: theme.palette.black.light,
          borderRadius: '1.5rem',
        },
        '& .MuiSlider-track': {
          background: backgroundColor,
          borderRadius: '1.5rem',
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          height: 'auto',
          width: 'auto',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transition: 'none',
          borderRadius: 0,
          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'none',
          },
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: theme.palette.black.dark,
            borderRadius: '0.5rem',
            boxShadow: theme.shadows[3],
            color: theme.palette.white.main,
            border: `1px solid ${alpha(theme.palette.white.main, 0.5)}`,
          },
        },
      })}
      {...props}
    />
  )
}
