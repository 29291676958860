const getSeverance = (socialBenefits) => {
  const severance = socialBenefits.filter(
    (socialBenefit) =>
      socialBenefit.category === 'severance' &&
      socialBenefit.action === 'update'
  )
  const hasSeverance = severance.length > 0

  const socialBenefitPeriodId = hasSeverance
    ? severance[0].social_benefits_period_id
    : null

  return { hasSeverance, socialBenefitPeriodId }
}

export default getSeverance
