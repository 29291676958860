import { Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useQueries, useQueryClient } from 'react-query'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { Box, Paper, Typography } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'
import NewSteps from 'components/UI/Steps/NewSteps'
import Header from 'components/Worker/Form/Header'

import { isObjectEmpty } from 'utils/general'
import useAreaService from 'utils/hooks/company/areaService'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useCompanyFilesService from 'utils/hooks/company/files'
import useLocationService from 'utils/hooks/company/locationService'
import usePositionService from 'utils/hooks/company/positionService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { getInstitutionsByCategory } from 'services/institutionService'

import * as routes from 'config/routes'

import messages from 'messages/company_form'

import { switchRenderFields } from './Fields/fieldsHelpers'
import {
  formatCompanyData,
  getDirtyValues,
  useGetBasicInformation,
} from './helpers'
import stepsData from './stepsData'

const CompanyForm = () => {
  const { state } = useLocation()
  const { companyId } = useParams()
  const [currentStep, setCurrentStep] = useState(0)
  const [progressStep, setProgressStep] = useState(0)
  const [company, setCompany] = useState({})
  const [institutions, setInstitutions] = useState({
    compensationFunds: [],
    riskProviders: [],
    ssOperators: [],
  })
  const [toCompanyShow, setToCompanyShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [anySubmitted, setAnySubmitted] = useState(null)
  const [stepSubmitted, setStepSubmitted] = useState(false)
  const needCredentials = currentStep === 6
  const firstMount = useRef(false)
  const { showHRFeatures } = usePremiumFeature()

  const stepsDataWithHRFeatures = showHRFeatures
    ? stepsData
    : stepsData.slice(0, 6)

  const { handleError } = useErrorHandler()
  const { showInfoMessage, showErrorMessage } = useNotifications()
  const queryClient = useQueryClient()
  const { contentTitle, contentText, contentTextExample } =
    stepsDataWithHRFeatures[currentStep] || {}

  const { locationMutation } = useLocationService({
    queryOptions: { enabled: false },
  })
  const { areaMutation } = useAreaService({ queryOptions: { enabled: false } })
  const { positionMutation } = usePositionService({
    queryOptions: { enabled: false },
  })
  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })
  const { companyFilesMutation } = useCompanyFilesService()
  const {
    isLoading: loadingFetch,
    isSuccess: successFetch,
    data: dataFetch,
  } = useGetBasicInformation(companyId)

  const institutionsQuery = useQueries(
    ['risk_provider', 'ss_operator'].map((category) => {
      return {
        queryKey: ['getInstitutionsByCategory', category],
        queryFn: () => getInstitutionsByCategory(category, needCredentials),
      }
    })
  )

  const institutionsSuccess = institutionsQuery.every(
    (query) => query?.status === 'success'
  )
  const institutionsLoading = institutionsQuery.some(
    (query) => query?.status === 'loading'
  )

  useEffect(() => {
    if (institutionsSuccess)
      setInstitutions({
        riskProviders: institutionsQuery[0].data?.data,
        ssOperators: institutionsQuery[1].data?.data,
      })
  }, [institutionsSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  const isMutating =
    areaMutation?.isLoading ||
    positionMutation?.isLoading ||
    locationMutation?.isLoading

  const {
    company: companyQuery,
    areas: areasQuery,
    positions: positionsQuery,
  } = dataFetch
  useEffect(() => {
    const fetchCompany = (data) => {
      const formatted = formatCompanyData(data)

      setCompany(formatted)
    }

    if (successFetch || stepSubmitted) {
      fetchCompany(dataFetch)
    }
    // disable because is the best way for do parallel queries
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successFetch, stepSubmitted, companyQuery, areasQuery, positionsQuery])

  useEffect(() => {
    const getProgressStep = async () => {
      let step = 0
      let localProgressStep = 0

      while (step < stepsDataWithHRFeatures.length) {
        const { schemaValidation } = stepsDataWithHRFeatures[step]
        try {
          // eslint-disable-next-line no-await-in-loop
          await schemaValidation.validate(company)

          step += 1
          localProgressStep += 1
        } catch (_) {
          break
        }
      }

      return {
        step,
        localProgressStep,
      }
    }

    const fetchAllCompanyData = async () => {
      try {
        const { step, localProgressStep } = await getProgressStep()

        if (
          state &&
          state.initialStep !== undefined &&
          state.initialStep <= step // must not go further from step that needs to be completed
        ) {
          setCurrentStep(state.initialStep)
        } else {
          setCurrentStep(step)
        }
        setProgressStep(localProgressStep)
        firstMount.current = true
      } catch (error) {
        handleError(error)
      }
    }

    if (!firstMount.current && Object.keys(company).length > 0) {
      fetchAllCompanyData()
    }
  }, [anySubmitted, company, handleError, state, stepsDataWithHRFeatures])

  useEffect(() => {
    if (currentStep === 6) {
      queryClient.removeQueries([
        'getInstitutionsByCategory',
        'compensation_fund',
      ])
      queryClient.removeQueries(['getInstitutionsByCategory', 'risk_provider'])
    }
  }, [currentStep, queryClient])

  const onSubmit = async (values, form) => {
    let submitOk = true
    setStepSubmitted(false)

    const dirtyValues = getDirtyValues(company, values)

    if (currentStep === 3) {
      if (dirtyValues?.areas) {
        if (dirtyValues.areas.length > 0)
          await areaMutation.mutateAsync(
            { mutationMethod: 'PUT', data: dirtyValues.areas },
            {
              onSuccess: () => {
                queryClient.invalidateQueries('companyAreas')
                setStepSubmitted(true)
                trackEvent(integrationEvent.COMPANY_AREA_ADD)
              },
              onError: () => {
                submitOk = false
              },
            }
          )
        delete dirtyValues.areas
      }
    }

    if (currentStep === 4) {
      if (dirtyValues?.positions) {
        if (dirtyValues.positions.length > 0) {
          await positionMutation.mutateAsync(
            { mutationMethod: 'PUT', data: dirtyValues.positions },
            {
              onSuccess: async () => {
                setStepSubmitted(true)

                trackEvent(integrationEvent.COMPANY_POSITION_ADD)
                await queryClient.invalidateQueries('companyPosition')
              },
              onError: () => {
                submitOk = false
              },
            }
          )
        }
        delete dirtyValues.positions
      }
    }

    const {
      tax_document: taxDocument,
      identification_document: identificationDocument,
      legal_representative_document: legalRepresentativeDocument,
      bank_certificate_document: bankCertificateDocument,
    } = values.files

    if (
      values.logo !== company?.logo ||
      taxDocument !== company?.files?.tax_document ||
      identificationDocument !== company?.files?.identification_document ||
      legalRepresentativeDocument !==
        company?.files?.legal_representative_document ||
      bankCertificateDocument !== company?.files?.bank_certificate_document
    ) {
      const formData = new FormData()

      if (values.logo !== company?.logo) {
        formData.append('logo', values.logo || '')
      }

      if (taxDocument !== company?.files.tax_document) {
        formData.append('tax_document', taxDocument || '')
      }

      if (identificationDocument !== company?.files.identification_document) {
        formData.append('identification_document', identificationDocument || '')
      }

      if (
        legalRepresentativeDocument !==
        company?.files.legal_representative_document
      ) {
        formData.append(
          'legal_representative_document',
          legalRepresentativeDocument || ''
        )
      }

      if (
        bankCertificateDocument !== company?.files.bank_certificate_document
      ) {
        formData.append(
          'bank_certificate_document',
          bankCertificateDocument || ''
        )
      }

      await companyFilesMutation.mutateAsync(
        {
          mutationMethod: 'PATCH',
          files: formData,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('companyInformation')
            if (formData.get('logo')) {
              trackEvent(integrationEvent.EDIT_SETTINGS, 'Update Logo')
            }
            setStepSubmitted(true)
          },
          onError: () => {
            submitOk = false
          },
        }
      )
    }

    delete dirtyValues.logo
    delete dirtyValues.files

    if (!isObjectEmpty(dirtyValues)) {
      await companyMutation.mutateAsync(
        {
          mutationMethod: 'PATCH',
          company: dirtyValues,
        },
        {
          onSuccess: ({ data }) => {
            trackEvent(integrationEvent.COMPANY_UPDATE, data)
            if (currentStep === 5) {
              showInfoMessage(messages.payment_preferences_notification)
            }
            queryClient.invalidateQueries('companyInformation')
            setStepSubmitted(true)
          },
          onError: () => {
            submitOk = false
          },
        }
      )
    }

    if (submitOk) {
      setIsLoading(true)
      if (currentStep < stepsDataWithHRFeatures.length - 1) {
        if (stepSubmitted) {
          setAnySubmitted(true)
        }
        setCurrentStep((prevStep) => prevStep + 1)
        if (progressStep === currentStep) setProgressStep(currentStep + 1)
        setIsLoading(false)
        setAnySubmitted(false)
      } else {
        setToCompanyShow(true)
      }
    } else {
      setIsLoading(false)
      form.setSubmitting(false)
    }
  }

  const handleClickStep = (index) => {
    setCurrentStep(index)
  }

  const handlePreviousStep = () => {
    setCurrentStep((previous) => previous - 1)
  }

  if (toCompanyShow) return <Navigate to={routes.COMPANY_SHOW(companyId)} />

  const initialValues = {
    logo: null,
    files: {
      tax_document: null,
      indentification_document: null,
      legal_representative_document: null,
      bank_certificate_document: null,
    },
    ...company,
    document_type: company.document_type || 'ni',
    account_type: company.account_type || 'savings_account',
  }

  const handleMainSubmit = (validateForm, onHandleSubmit) => {
    if (currentStep === 1) {
      validateForm().then((errors) => {
        if (errors?.locations?.length > 0) {
          showErrorMessage(
            'Crea por lo menos un sede para tu empresa. Por ejemplo: Administrativa'
          )
          return
        }

        onHandleSubmit()
      })
    } else {
      onHandleSubmit()
    }
  }

  return (
    <Page
      documentTitle="Editar empresa"
      header={
        <Header
          title="Editar empresa"
          description="Acá puedes editar la información de la empresa. Recuerda que siempre podrás regresar a editar cualquier dato."
        />
      }
      isLoading={institutionsLoading || isLoading || loadingFetch}
    >
      <NewSteps
        stepsData={stepsDataWithHRFeatures}
        current={currentStep}
        progress={currentStep}
        onChangeStep={handleClickStep}
      />
      <Paper
        sx={(theme) => ({
          marginTop: theme.spacing(4),
          padding: theme.spacing(4),
          borderRadius: '1rem',
        })}
      >
        <Box
          sx={{
            ...(currentStep !== 1 && {
              margin: '0 auto',
            }),
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom>
            {contentTitle}
          </Typography>
          {contentText ? <p>{contentText}</p> : null}
          {contentTextExample ? (
            <>
              <Typography variant="lead1"> Ejemplo: </Typography>
              <Typography>{contentTextExample}</Typography>
              <br />
            </>
          ) : null}
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={
              stepsDataWithHRFeatures[currentStep]?.schemaValidation || {}
            }
            enableReinitialize
          >
            {(form) => {
              const {
                values,
                handleSubmit: handleSubmitLocal,
                isSubmitting,
                setFieldValue,
                validateForm,
              } = form

              // set default ss_operator
              if (!values.ss_operator && institutions.ssOperators.length > 0) {
                const initSssOp = institutions.ssOperators.find(
                  (ssOp) => ssOp.name === 'Aportes en Linea'
                )

                setFieldValue('ss_operator', initSssOp)
              }

              return isObjectEmpty(values) ? null : (
                <>
                  <Form>
                    {switchRenderFields(
                      currentStep,
                      institutions,
                      values?.document_type
                    )}
                  </Form>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      gap: theme.spacing(2),
                      justifyContent: 'flex-end',
                      marginTop: theme.spacing(4),
                      [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        justifyContent: 'center',
                        '& button:nth-of-type(1)': {
                          order: 2,
                        },
                        '& button:nth-of-type(2)': {
                          order: 1,
                        },
                      },
                    })}
                  >
                    {currentStep !== 0 ? (
                      <Button variant="outlined" onClick={handlePreviousStep}>
                        Volver al paso anterior
                      </Button>
                    ) : null}
                    <Button
                      onClick={() =>
                        handleMainSubmit(validateForm, handleSubmitLocal)
                      }
                      disabled={isSubmitting || isMutating}
                    >
                      Guardar y continuar
                    </Button>
                  </Box>
                </>
              )
            }}
          </Formik>
        </Box>
      </Paper>
    </Page>
  )
}

export default CompanyForm
