import http, { getApiURL } from 'services/httpService'

export const getDecree376Payments = ({ periodId, decree376Id }) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/decree376/periods/${decree376Id}`,
  })

  return http.getV2(url)
}

export const getDecree376File = ({ periodId, decreePeriodId }) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/decree376/period_payment_files/${decreePeriodId}`,
    searchParams: { async: true },
  })
  return http.getV2(url)
}

export const updateDecree376Payments = ({
  periodId,
  decree376Id,
  dataToSend,
}) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/decree376/periods/${decree376Id}`,
  })

  return http.putV2(url, dataToSend)
}

export const getDecree376SocialSecurityFile = ({
  periodId,
  decreePeriodId,
  searchParams,
}) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/decree376/social_security_files/${decreePeriodId}`,
    searchParams: { ...searchParams, async: true },
  })
  return http.getV2(url)
}

export default {
  getDecree376Payments,
  updateDecree376Payments,
  getDecree376SocialSecurityFile,
  getDecree376File,
}
