import { useQueryClient } from 'react-query'

import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'
import useNotifications from 'utils/hooks/useNotifications'

import { downloadFileWebSocket } from 'services/httpService'

import { useElectronicPayrollAlert } from './ElectronicPayrollAlert/ElectronicPayrollAlertWrapper'

const useSendElectronicPayrolls = ({ invalidateQueries }) => {
  const queryClient = useQueryClient()
  const { openElectronicPayrollAlert, webSocketState, setWebSocketState } =
    useElectronicPayrollAlert()
  const { showErrorMessage, showInfoMessage } = useNotifications()

  const { electronicPayrollMutation } = useElectronicPayrollPeriodService({
    queryOptions: {
      enabled: false,
    },
  })

  const payrollsToSend = (electronicPayrolls) => {
    const valuesToSend = []
    let workers = []
    if (electronicPayrolls.length) {
      workers = electronicPayrolls.filter(
        (worker) => worker.selection === true && worker.status !== 'issued'
      )

      workers.map((electronicPayroll) =>
        valuesToSend.push(electronicPayroll.id)
      )
    } else {
      valuesToSend.push(electronicPayrolls.id)
    }

    return {
      electronic_payroll_ids: valuesToSend,
      electronicPayrollToEmit: valuesToSend.length > 0,
    }
  }

  const handleElectronicPayrollWebSocket = async (periodId) => {
    try {
      const { data } = await downloadFileWebSocket(
        `/electronic_payroll/periods/${periodId}/payrolls_to_issue`
      )

      const successSocket =
        !data.errors ||
        (data.errors &&
          data.errors.every((error) => {
            return !!error.message?.details
          }))

      const errorSocket = data.errors && data.errors.length > 0

      const messageError =
        data.errors && data.errors.find((error) => !error.message?.details)

      queryClient.invalidateQueries(invalidateQueries, {
        exact: false,
      })

      if (successSocket) {
        if (!data.errors) {
          openElectronicPayrollAlert({
            messageResponse: 'issued',
            periodId,
          })
        } else {
          openElectronicPayrollAlert({
            messageResponse: 'error',
            periodId,
          })
        }
      } else {
        if (
          errorSocket &&
          messageError !== null &&
          messageError !== undefined
        ) {
          openElectronicPayrollAlert({
            messageResponse: 'error',
            periodId,
          })
        } else {
          showErrorMessage(
            'Ocurrió un error al intentar emitir tu nómina electrónica, por favor inténtalo nuevamente. Si el error persiste, escríbenos a soporte.'
          )
        }
      }
    } catch (_) {
      showErrorMessage(
        'Ocurrió un error al intentar emitir tu nómina electrónica, por favor inténtalo nuevamente. Si el error persiste, escríbenos a soporte.'
      )
    }
    setWebSocketState('finished')
  }

  const handleSendElectronicPayroll = (periodId, electronicPayrollsSend) => {
    const { electronic_payroll_ids: electronicPayrollIds } = payrollsToSend(
      electronicPayrollsSend
    )

    electronicPayrollMutation.mutate(
      {
        mutationMethod: 'PUT',
        periodId,
        electronicPayrolls: electronicPayrollIds,
      },
      {
        onSuccess: () => {
          handleElectronicPayrollWebSocket(periodId)
          showInfoMessage(
            'Estamos verificando la información. Esto puede tardar varios minutos. Te avisaremos cuando esté listo.'
          )
          setWebSocketState('inProgress')
        },
      }
    )
  }

  return {
    handleElectronicPayrollWebSocket,
    handleSendElectronicPayroll,
    payrollsToSend,
    webSocketState,
  }
}

export default useSendElectronicPayrolls
