import { useState } from 'react'

const useAffiliationModal = () => {
  const [state, setState] = useState({
    open: false,
    affiliation: {},
    onboardingId: null,
    workerName: null,
    workerId: null,
  })

  const closeAffiliationModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openAffiliationModal = ({
    affiliation,
    onboardingId,
    workerName,
    workerId,
  }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      affiliation,
      onboardingId,
      workerName,
      workerId,
    }))
  }

  const resetAffiliationModalState = () => {
    setState((previousState) => ({
      ...previousState,
      affiliation: {},
      onboardingId: null,
      workerName: null,
      workerId: null,
    }))
  }

  return {
    affiliationModalState: state,
    closeAffiliationModal,
    openAffiliationModal,
    resetAffiliationModalState,
  }
}

export default useAffiliationModal
