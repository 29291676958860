import { useMediaQuery } from '@mui/material'

import BtnAddNovelty from '../Novelties/BtnAddNovelty'
import MobileCell from './CardGrid/MobileCell'

const AddButtonWrapper = ({
  children,
  showButton = false,
  onAddNovelty,
  noveltyType,
  noveltyCode,
}) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <>
      {children}
      {showButton ? (
        <MobileCell
          sx={{
            gridColumn: '2 / -1',
          }}
          alignX={isTabletUp ? 'left' : 'right'}
        >
          <BtnAddNovelty
            onAddNovelty={onAddNovelty}
            noveltyType={noveltyType}
            noveltyCode={noveltyCode}
          />
        </MobileCell>
      ) : null}
    </>
  )
}

export default AddButtonWrapper
