import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  deleteWorkCenter,
  getAllWorkCenters,
  getCIIU,
  saveWorkCenter,
  updateWorkCenter,
} from 'services/company/decree2012Service'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, searchParams } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getWorkCenters') return getAllWorkCenters()

  if (currentQueryKey === 'getCIIU') return getCIIU(searchParams)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workCenterData, workCenterId } = mutationData

  if (mutationMethod === 'POST') return saveWorkCenter(workCenterData)

  if (mutationMethod === 'DELETE') return deleteWorkCenter(workCenterId)

  if (mutationMethod === 'PUT')
    return updateWorkCenter(workCenterId, workCenterData)

  return null
}

const useCompanyWorkCenters = ({
  serviceParams = { queryKey: 'getWorkCenters' },
  queryOptions = {},
  mutationOptions = {},
}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restWorkCentersResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  const companyWorkCentersMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    companyWorkCentersQuery: {
      data,
      ...restWorkCentersResponse,
    },
    companyWorkCentersMutation,
  }
}

export default useCompanyWorkCenters
