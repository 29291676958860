import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadedFile from 'components/UI/Formik/CommonFields/LoadedFile'

import useDownloadURI from 'utils/hooks/useDownloadURI'

import { moreThanOnePending, useDocumentDeletion } from './helpers'

const InProgressContent = ({
  children,
  electronicSignatureQueryData,
  workerId,
  handleClose,
}) => {
  const handleConfirmFileDeletion = useDocumentDeletion()
  const downloadURI = useDownloadURI()

  const handleDeleteFile = () => {
    handleConfirmFileDeletion(workerId, handleClose)
  }

  const unsignedFile = electronicSignatureQueryData[0]?.unsigned_file

  const moreThanOneSignaturePending = moreThanOnePending(
    electronicSignatureQueryData[0]?.signers
  )

  return (
    <Box>
      <Typography
        variant="body1"
        sx={(theme) => ({ margin: theme.spacing(0, 0, 4) })}
        color="black.dark"
      >
        Al parecer,{' '}
        {moreThanOneSignaturePending
          ? 'aún hay firmas pendientes'
          : 'hay una firma pendiente'}{' '}
        por realizar. Nada de nervios que desde acá estamos trabajando para
        sacar {moreThanOneSignaturePending ? 'esas firmas' : 'esa firma'}{' '}
        adelante.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          margin: theme.spacing(0, 0, 3),
        })}
      >
        <Typography variant="lead2">Documento adjunto</Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
          })}
        >
          <LoadedFile
            fileName="Documento adjunto"
            hideRemoveButton={false}
            handleDeleteFile={handleDeleteFile}
          />
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
              [theme.breakpoints.up('tablet')]: {
                columnGap: theme.spacing(2),
                flexDirection: 'row',
              },
            })}
          >
            <Button
              variant="text"
              onClick={handleDeleteFile}
              sx={{
                margin: 0,
                padding: 0,
                width: 'fit-content',
                textDecoration: 'underline',
                minWidth: 0,
              }}
            >
              Eliminar
            </Button>
            <Button
              variant="text"
              onClick={() => downloadURI(unsignedFile)}
              sx={{
                margin: 0,
                padding: 0,
                width: 'fit-content',
                textDecoration: 'underline',
                minWidth: 0,
              }}
            >
              Ver
            </Button>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        Personas que firman este documento
      </Typography>
      <Box sx={(theme) => ({ margin: theme.spacing(0, 0, 4) })}>{children}</Box>
    </Box>
  )
}

export default InProgressContent
