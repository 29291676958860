import { forwardRef, useRef } from 'react'

import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import LoadedPicture from '../CommonFields/LoadedPicture'

const PictureFieldContent = forwardRef(
  function PictureFieldContentForwardRef(props, inputRef) {
    const {
      id,
      name,
      disabled,
      accept, // This prop controls the selection when using the file selector
      fileType, // This prop controls the selection when dragging the file
      setValue,
      selector,
      disableDropzone,
    } = props.fileFieldProps

    const { stopEvent, fileInputRef, onFileAdded, openFileDialog } = props

    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    if (!accept || !fileType) {
      throw new Error(
        `accept and fileType props are required and you provided: accept: ${accept} and fileType: ${fileType}`
      )
    }

    const onDrop = (event) => {
      if (disabled) return
      stopEvent(event)

      const file = event.dataTransfer.files[0]

      if (fileType) {
        if (typeof fileType === 'string' && file?.type === fileType) {
          setValue(file)
        } else if (Array.isArray(fileType) && fileType.includes(file?.type)) {
          setValue(file)
        }
      }
    }

    const onDragOver = (event) => stopEvent(event)

    return (
      <Box
        ref={inputRef}
        role="none"
        onDrop={!disableDropzone ? onDrop : undefined}
        onDragOver={!disableDropzone ? onDragOver : undefined}
        onClick={openFileDialog}
        sx={{
          width: '100%',
          display: 'flex',
          cursor: 'pointer',
        }}
      >
        <Box
          component="input"
          id={id}
          name={name}
          type="file"
          ref={fileInputRef}
          accept={accept}
          onChange={onFileAdded}
          disabled={disabled}
          data-cy="file-input"
          sx={{
            display: 'none',
          }}
        />
        {selector || (
          <Box
            sx={(theme) => ({
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'space-between',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              gap: theme.spacing(1),
            })}
          >
            <Typography variant="body1" color="black.light" noWrap>
              {smUp ? 'Arrastra aquí' : 'Arrastrar'}
            </Typography>
            <Button
              color="primary"
              disabled={disabled}
              size="small"
              sx={{
                height: '1.5rem',
                boxShadow: 'none',
                '&:focus': {
                  boxShadow: 'none',
                },
              }}
            >
              {smUp ? 'Adjuntar foto' : 'Adjuntar'}
            </Button>
          </Box>
        )}
      </Box>
    )
  }
)

const PictureField = ({ ...props } = {}) => {
  const fileInputRef = useRef(null)
  const stopEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onFileAdded = (event) => {
    if (props.disabled) return
    stopEvent(event)

    const file = event.target.files[0]
    props.setValue(file)
  }

  const handleDeleteFile = (event) => {
    stopEvent(event)

    props.setValue(null)
  }

  const openFileDialog = () => {
    if (props.disabled) return

    fileInputRef.current.click()
  }

  if (props.value) {
    return (
      <LoadedPicture
        fileName={props.fileName}
        handleDeleteFile={handleDeleteFile}
        value={props.value}
        fileInputRef={fileInputRef}
        onFileAdded={onFileAdded}
        openFileDialog={openFileDialog}
        pictureProps={props}
      />
    )
  }

  return (
    <TextField
      disabled={props.disabled}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: forwardRef(
          function InputComponentForwardRef(innerProps, inputRef) {
            return (
              <PictureFieldContent
                ref={inputRef}
                {...innerProps}
                fileFieldProps={props}
                stopEvent={stopEvent}
                fileInputRef={fileInputRef}
                onFileAdded={onFileAdded}
                openFileDialog={openFileDialog}
              />
            )
          }
        ),
      }}
    />
  )
}

export default PictureField
