import { useState } from 'react'

const useEditContractModal = () => {
  const [state, setState] = useState({
    open: false,
  })

  const openEditContractModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: true,
    }))
  }

  const closeEditContractModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    editContractModalState: state,
    openEditContractModal,
    closeEditContractModal,
  }
}

export default useEditContractModal
