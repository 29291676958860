import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { Button, Typography } from '@mui/material'

import FilterButton from 'components/UI/Button/FilterButton'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import { contractCategories } from 'utils/worker'

import { ORGANIZER_WORKER_SHOW } from 'config/routes'

yup.setLocale(yupLocaleES)

const affiliationsStatus = {
  waiting_response: 'Pendientes',
  approved: 'Aprobadas',
  rejected: 'Rechazadas',
  received: 'Recibidas',
  occurrence: 'Novedades',
}

const getOptions = (affiliations) =>
  Object.keys(affiliations).map((affiliation) => {
    return {
      label: `${affiliationsStatus[affiliation.split('_total')[0]]} (${
        affiliations[affiliation]
      })`,
      value: affiliation.split('_total')[0],
    }
  })

export const getColumns = (handleOpenAffiliation) => {
  const columns = [
    {
      accessor: 'status_change_date',
      Header: 'Fecha',
      Cell: ({ row }) =>
        formatDisplayDateString(row.original.status_change_date),
    },
    {
      accessor: 'worker_name',
      Header: 'Persona',
      Cell: ({ row }) => (
        <Link
          to={ORGANIZER_WORKER_SHOW(row.original.id)}
          state={{
            fromAffiliation: true,
            workerId: row.original.worker_id,
          }}
          onClick={() => handleOpenAffiliation(row.original.company_id)}
          color="black.main"
        >
          {row.original.worker_name}
        </Link>
      ),
    },
    {
      accessor: 'company_name',
      Header: 'Empresa',
    },
    {
      accessor: 'worker_location',
      Header: 'Sede',
    },
    {
      accessor: 'contract_category',
      Header: 'Contrato',
      Cell: ({ row }) => contractCategories[row.original.contract_category],
    },
  ]

  return columns
}

export const getActions = ({
  handleOpenAffiliation,
  filter,
  handleChange,
  affiliations = {},
}) => {
  return [
    (rowData) => ({
      id: 'view_profile',
      tooltip: 'Ver perfil',
      icon: <Icon name="eye" basic />,
      onClick: () => handleOpenAffiliation(rowData.company_id),
      buttonProps: {
        component: RouterLink,
        to: ORGANIZER_WORKER_SHOW(rowData.id),
        state: {
          fromAffiliation: true,
          workerId: rowData.worker_id,
        },
      },
    }),
    {
      id: 'filterAffiliations',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Pendientes"
          options={getOptions(affiliations)}
          value={filter}
          onChange={handleChange}
        />
      ),
    },
  ]
}

const documentValidation = () =>
  yup
    .mixed()
    .test('isPdf', 'El documento debe ser un PDF.', (value) => {
      if (value instanceof File) {
        return value.type === 'application/pdf'
      }
      return value ? typeof value === 'string' : true
    })
    .test(
      'fileSize',
      'El archivo que intentas subir no tiene contenido o se encuentra dañado.',
      (value) => {
        if (!value) return true

        if (value instanceof File) {
          return value.size > 0
        }

        return true
      }
    )
    .required('Debes proporcionar al menos uno de estos documentos')

export const getValidationSchema = (isRejectAction) => {
  if (isRejectAction) {
    return yup.object({
      rejection_reason: yup.string().nullable().required(),
      invalid_documents: yup
        .array()
        .nullable()
        .when('rejection_reason', {
          is: (reason) =>
            [
              'error_in_company_documents',
              'error_in_worker_documents',
              'error_in_beneficiary_documents',
            ].includes(reason),
          then: yup
            .array()
            .required('Debes seleccionar al menos un documento.'),
          otherwise: yup.array().nullable(),
        }),
      invalid_credentials: yup
        .array()
        .nullable()
        .when('rejection_reason', {
          is: (reason) => reason === 'error_in_credentials',
          then: yup.array().required('Debes seleccionar al menos una entidad.'),
          otherwise: yup.array().nullable(),
        }),
      novelty_type: yup
        .string()
        .nullable()
        .when('rejection_reason', {
          is: 'novelty',
          then: yup
            .string()
            .nullable()
            .required('El tipo de novedad es requerido.'),
          otherwise: yup.string().nullable(),
        }),
      beneficiary: yup
        .string()
        .nullable()
        .when('rejection_reason', {
          is: 'error_in_beneficiary_documents',
          then: yup
            .string()
            .nullable()
            .required('Debes seleccionar el nombre del beneficiario.'),
          otherwise: yup.string().nullable(),
        }),
      comment: yup
        .string()
        .nullable()
        .when('rejection_reason', {
          is: (reason) => reason === 'novelty',
          then: yup
            .string()
            .nullable()
            .required(
              'El comentario es obligatorio para la creación de novedades.'
            ),
          otherwise: yup.string().nullable(),
        }),
    })
  }

  return yup.object().shape(
    {
      approved_compensation_fund_document: yup
        .mixed()
        .when(
          [
            'approved_health_document',
            'approved_pension_document',
            'approved_risk_document',
          ],
          {
            is: (healthDoc, pensionDoc, riskDoc) =>
              !healthDoc && !pensionDoc && !riskDoc,
            then: documentValidation(),
            otherwise: yup.mixed().nullable(),
          }
        ),
      approved_health_document: yup
        .mixed()
        .when(
          [
            'approved_compensation_fund_document',
            'approved_pension_document',
            'approved_risk_document',
          ],
          {
            is: (compensationFundDoc, pensionDoc, riskDoc) =>
              !compensationFundDoc && !pensionDoc && !riskDoc,
            then: documentValidation(),
            otherwise: yup.mixed().nullable(),
          }
        ),
      approved_pension_document: yup
        .mixed()
        .when(
          [
            'approved_compensation_fund_document',
            'approved_health_document',
            'approved_risk_document',
          ],
          {
            is: (compensationFundDoc, healthDoc, riskDoc) =>
              !compensationFundDoc && !healthDoc && !riskDoc,
            then: documentValidation(),
            otherwise: yup.mixed().nullable(),
          }
        ),
      approved_risk_document: yup
        .mixed()
        .when(
          [
            'approved_compensation_fund_document',
            'approved_health_document',
            'approved_pension_document',
          ],
          {
            is: (compensationFundDoc, healthDoc, pensionDoc) =>
              !compensationFundDoc && !healthDoc && !pensionDoc,
            then: documentValidation(),
            otherwise: yup.mixed().nullable(),
          }
        ),
    },
    [
      ['approved_health_document', 'approved_compensation_fund_document'],
      ['approved_health_document', 'approved_pension_document'],
      ['approved_health_document', 'approved_risk_document'],
      ['approved_compensation_fund_document', 'approved_health_document'],
      ['approved_compensation_fund_document', 'approved_pension_document'],
      ['approved_compensation_fund_document', 'approved_risk_document'],
      ['approved_pension_document', 'approved_risk_document'],
      ['approved_pension_document', 'approved_health_document'],
      ['approved_pension_document', 'approved_compensation_fund_document'],
      ['approved_risk_document', 'approved_health_document'],
      ['approved_risk_document', 'approved_compensation_fund_document'],
      ['approved_risk_document', 'approved_pension_document'],
    ]
  )
}

export const getInitialValues = (certificates, isRejectAction) => {
  if (isRejectAction) {
    return {
      rejection_reason: null,
      rejection_errors: null,
      novelty_type: null,
      comment: null,
    }
  }

  return {
    approved_compensation_fund_document: certificates[0]?.document_url || null,
    approved_health_document: certificates[1]?.document_url || null,
    approved_pension_document: certificates[2]?.document_url || null,
    approved_risk_document: certificates[3]?.document_url || null,
  }
}

export const getProviderInfo = (providerType, providersArray) =>
  providersArray?.find((provider) => provider.provider_type === providerType)

export const areRequiredDocumentsCompleted = (providersArray) => {
  let completedTrueCount = 0
  let hiddenFalseCount = 0

  providersArray?.forEach((item) => {
    if (item.completed === true) completedTrueCount++
    if (item.hidden === false) hiddenFalseCount++
  })

  return completedTrueCount === hiddenFalseCount
}

export const getAffiliationEntities = (providersArray) =>
  providersArray?.filter((provider) => provider.hidden === false)

export const hasErrorsInFilledValues = ({ errors, values }) => {
  for (const key in values) {
    const error = errors[key]
    const value = values[key]

    if (value && error && error !== 'Debes proporcionar este documento') {
      return true
    }
  }

  return false
}

export const getDescription = (affiliationStatus) => ({
  rejected: `Desde acá puedes rechazar una afiliación${affiliationStatus === 'received' ? ' o reportar una novedad' : ''}. Para esto, es necesario que agregues la razón y el nombre del afiliado (si aplica). También puedes agregar comentarios adicionales para especificar a detalle el motivo y evitar reprocesos.`,
  approved:
    'Desde acá podrás ingresar cada certificado de afiliación de la persona. Una vez cargues los certificados, estos estarán disponibles para ser descargados por el admin, incluso si el proceso de afiliación no ha sido completado.',
})

export const DownloadButton = ({ file }) => {
  const downloadURI = useDownloadURI()

  if (!file) return null

  return (
    <Button
      onClick={() => downloadURI(file, '_blank')}
      sx={(theme) => ({
        marginTop: theme.spacing(3),
        padding: 0,
        minWidth: 'auto',
        width: 'fit-content',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      })}
    >
      <Typography
        variant="lead2"
        sx={(theme) => ({ color: theme.palette.black.dark })}
      >
        Ver
      </Typography>
    </Button>
  )
}

export const getRejectionOptions = ({ beneficiaries, affiliationStatus }) => {
  const rejectionOptions = [
    {
      value: 'error_in_credentials',
      label: 'Credenciales incompletas o erróneas',
    },
    {
      value: 'error_in_company_documents',
      label: 'Documentos incompletos o erróneos (empresa)',
    },
    {
      value: 'error_in_worker_documents',
      label: 'Documentos incompletos o erróneos (persona)',
    },
  ]

  if (affiliationStatus === 'received') {
    rejectionOptions.unshift({
      value: 'novelty',
      label: 'Novedad',
    })
  }

  if (beneficiaries?.length > 0) {
    rejectionOptions.push({
      value: 'error_in_beneficiary_documents',
      label: 'Documentos incompletos o erróneos (beneficiario)',
    })
  }

  return rejectionOptions
}

const getBeneficiariesArray = (beneficiaries) =>
  beneficiaries?.map((beneficiary) => ({
    value: beneficiary.id,
    label: beneficiary.name,
  }))

export const RejectionBody = ({
  rejectionReason,
  beneficiaries,
  affiliationEntities,
}) => {
  let errorOptions = []
  const beneficiariesArray = getBeneficiariesArray(beneficiaries)

  if (rejectionReason === 'error_in_worker_documents') {
    errorOptions = [
      { value: 'identification_document', label: 'Cédula' },
      {
        value: 'contract_document',
        label: 'Contrato de aprendizaje',
      },
    ]
  }

  if (rejectionReason === 'error_in_company_documents') {
    errorOptions = [
      { value: 'tax_document', label: 'Rut de la empresa' },
      { value: 'bank_certificate_document', label: 'Certificación bancaria' },
      {
        value: 'legal_representative_document',
        label: 'Cédula representante legal',
      },
      { value: 'identification_document', label: 'Cámara de comercio' },
    ]
  }

  if (rejectionReason === 'error_in_beneficiary_documents') {
    errorOptions = [
      {
        value: 'marriage_certificate',
        label: 'Certificado matrimonio / unión libre',
      },
      {
        value: 'identification_document',
        label: 'Documento de identificación',
      },
      {
        value: 'labor_certificate',
        label: 'Certificado laboral',
      },
      {
        value: 'dependency_declaration',
        label: 'Certificado de dependencia económica',
      },
      { value: 'civil_registration', label: 'Registro civil' },
      { value: 'study_document', label: 'Certificado de estudios' },
      { value: 'disability_certificate', label: 'Certificado de discapacidad' },
      { value: 'adoption_certificate', label: 'Certificado de adopción' },
      {
        value: 'health_affiliation_certificate',
        label: 'Certificado afiliación EPS grupo familiar',
      },
      {
        value: 'parents_death_certificate',
        label: 'Registro defunción padres biológicos',
      },
    ]
  }

  const entityLabels = {
    health_provider: 'EPS',
    risk_provider: 'ARL',
    compensation_fund: 'Caja de compensación',
    pension_provider: 'Fondo de pensiones',
  }

  if (rejectionReason === 'error_in_credentials') {
    errorOptions = affiliationEntities
      ?.filter((entity) => entity.credential_id)
      .map((entity) => ({
        value: entity.provider_type,
        label: `${entityLabels[entity.provider_type]} (${
          entity.institution_name
        })`,
      }))
  }

  if (rejectionReason === 'novelty') {
    return (
      <FormField
        name="novelty_type"
        label="Tipo de novedad"
        variant="select"
        optional={false}
        options={[
          {
            value: 'slow_response',
            label: 'Tiempo de respuesta mayor al habitual',
          },
          {
            value: 'auth_required',
            label: 'Se requiere segundo factor de autenticación',
          },
          {
            value: 'in_person_signup',
            label: 'Entidad solicita afiliación presencial',
          },
          { value: 'other', label: 'Otro' },
        ]}
      />
    )
  }

  if (rejectionReason === 'error_in_credentials') {
    return (
      <FormField
        name="invalid_credentials"
        label="Seleccione las credenciales incompletas o con errores"
        variant="checkbox-group"
        optional={false}
        options={errorOptions}
      />
    )
  }

  return [
    'error_in_company_documents',
    'error_in_worker_documents',
    'error_in_beneficiary_documents',
  ].includes(rejectionReason) ? (
    <>
      {rejectionReason === 'error_in_beneficiary_documents' ? (
        <FormField
          name="beneficiary"
          label="Nombre del beneficiario"
          variant="select"
          optional={false}
          options={beneficiariesArray}
        />
      ) : null}
      <FormField
        name="invalid_documents"
        label="Seleccione los documentos incompletos o con errores"
        variant="checkbox-group"
        optional={false}
        options={errorOptions}
      />
    </>
  ) : null
}

export const fieldsToReset = [
  'invalid_documents',
  'beneficiary',
  'novelty_type',
]

export const getEntitiesWithMissingCredentialsKey = ({
  entitiesWithErrors,
  providersArray,
}) => {
  const entitiesArray = getAffiliationEntities(providersArray)

  return entitiesArray?.reduce((result, entity) => {
    result[entity.provider_type] = {
      missing_credentials: entitiesWithErrors?.includes(entity.provider_type),
    }
    return result
  }, {})
}
