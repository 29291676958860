import { useDateFormatter } from '@react-aria/i18n'
import { isSameDay, isWithinInterval, parseISO } from 'date-fns'
import { createRef, useMemo } from 'react'

const spanishDays = {
  L: 'Lun',
  M: 'Mar',
  X: 'Mié',
  J: 'Jue',
  V: 'Vie',
  S: 'Sáb',
  D: 'Dom',
}

export const getDayAbbreviation = (locale, day) => {
  if (locale === 'es-ES') {
    return spanishDays[day]
  }

  return day
}

export const isBetweenDates = (date, initialDay, endDay) => {
  const parsedDate = new Date(`${date.year},${date.month},${date.day}`)
  const isDayBetween =
    initialDay && endDay
      ? isWithinInterval(parsedDate, {
          start: parseISO(initialDay),
          end: parseISO(endDay),
        })
      : false
  const isFirstDay = initialDay
    ? isSameDay(parsedDate, parseISO(initialDay))
    : false
  const isLastDay = endDay ? isSameDay(parsedDate, parseISO(endDay)) : false

  return [isDayBetween, isFirstDay, isLastDay]
}

export const parseDateSegment = (value) => {
  return `${Number(value) < 10 ? '0' : ''}${value}`
}

export const useCalendarYears = ({ state, minValue, maxValue }) => {
  const { focusedDate, timeZone } = state
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone,
  })
  const years = useMemo(() => {
    let nextYears = []
    // Format 20 years on each side of the current year according
    // to the current locale and calendar system.
    for (let i = -20; i <= 20; i += 1) {
      const date = focusedDate.add({ years: i })

      nextYears.push({
        value: date,
        label: formatter.format(date.toDate(timeZone)),
      })
    }

    // Add only years between minValue and maxValue

    if (minValue?.year) {
      nextYears = nextYears.filter(
        (yearItem) => yearItem?.value?.year >= minValue?.year
      )
    }

    if (maxValue?.year) {
      nextYears = nextYears.filter(
        (yearItem) => yearItem?.value?.year <= maxValue?.year
      )
    }

    return nextYears
  }, [focusedDate, formatter, maxValue?.year, minValue?.year, timeZone])
  const yearsRefs = useMemo(
    () =>
      years.reduce((acc, value) => {
        acc[value.label] = createRef()
        return acc
      }, {}),
    [years]
  )

  return {
    years,
    yearsRefs,
    hasAvailableYears: years.length > 1,
  }
}

export const getFocusedValue = (currentValue, periodDate) => {
  if (currentValue) return currentValue

  if (periodDate) return periodDate

  return undefined
}
