import { Box, ButtonBase, Paper, Typography } from '@mui/material'

const InfoCard = ({ card }) => {
  const { icon, title, number, description, onClick } = card

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '19rem',
        minWidth: '9.375rem',
      }}
    >
      <ButtonBase
        onClick={onClick}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          borderRadius: '0.5rem',
          width: '100%',
          justifyContent: 'flex-start',
          padding: theme.spacing(4, 0, 3, 3),
        })}
      >
        {icon}
        <Box
          sx={(theme) => ({
            marginLeft: theme.spacing(1.5),
            textAlign: 'start',
          })}
        >
          <Typography variant="lead1">{title}</Typography>
          <Typography variant="h5" color="primary">
            {String(number)}
          </Typography>
        </Box>
      </ButtonBase>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.light,
          height: '100%',
          padding: theme.spacing(1, 3, 1, 3),
        })}
      >
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Paper>
  )
}

export default InfoCard
