import http, { getApiURL } from '../httpService'

export const getElectronicPayrollConfiguration = () => {
  const url = getApiURL({
    pathname: '/electronic_payroll/configuration',
  })

  return http.getV2(url)
}

export const getConfigurationOptions = () => {
  const url = getApiURL({
    pathname: '/electronic_payroll/configuration_options',
  })

  return http.getV2(url)
}

export const updateElectronicPayrollConfiguration = (configuration) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/configuration`,
  })

  const data = { configuration }

  return http.patchV2(url, data)
}

export default {
  getElectronicPayrollConfiguration,
  updateElectronicPayrollConfiguration,
  getConfigurationOptions,
}
