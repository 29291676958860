import { useFormikContext } from 'formik'
import { useContext } from 'react'

import { Box } from '@mui/material'

import { SubscriptionViewContext } from '../Index'
import MethodAccordion from './MethodAccordion'
import { getPaymentFields } from './helpers'

const PaymentMethodSection = ({
  showCreditCard,
  showAutomaticDebitMethod,
  showPseMethod,
}) => {
  const { setFieldValue, errors } = useFormikContext()

  const {
    activePayment,
    setActivePayment,
    setExpandedAccordion,
    expandedAccordion,
    setselectedPaymentMethod,
    selectedPaymentMethod,
  } = useContext(SubscriptionViewContext)

  const onExpandAccordion = (panel) => {
    if (panel !== 'billing_info') {
      setFieldValue('payment_method', panel)
      setActivePayment(panel)
    }

    setExpandedAccordion(panel)
  }

  const paymentFields = getPaymentFields({
    showCreditCard,
    showAutomaticDebitMethod,
    showPseMethod,
    expandedAccordion,
  })

  const handleSelectPaymentMethod = (panel) => {
    setExpandedAccordion('billing_info')
    setselectedPaymentMethod(panel)
  }

  const paymentOption = {
    pse: 'pse_form',
    credit_card: 'credit_card_form',
    automatic_debit: 'automatic_debit_form',
  }

  const disableButton = Object.keys(errors || {}).includes(
    paymentOption[expandedAccordion]
  )

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        rowGap: theme.spacing(3),
        marginBottom: theme.spacing(2),
        overflow: 'hidden',
      })}
    >
      {paymentFields.map((paymentField) => {
        const { id, title, logos, Fields } = paymentField

        return (
          <MethodAccordion
            key={id}
            id={id}
            logos={logos}
            title={title}
            expanded={expandedAccordion}
            onChange={onExpandAccordion}
            handleContinue={handleSelectPaymentMethod}
            disableButton={disableButton}
            setselectedPaymentMethod={setselectedPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                width: '100%',
                rowGap: theme.spacing(2.5),
                gridTemplateColumns: '1fr',
              })}
            >
              <Fields activePayment={activePayment} />
            </Box>
          </MethodAccordion>
        )
      })}
    </Box>
  )
}

export default PaymentMethodSection
