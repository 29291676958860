import { useReducer } from 'react'

export const PAYROLL_ACTION_TYPES = {
  SHOW_MODAL: 'SHOW_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_MODAL_CONFIGURATION: 'SET_MODAL_CONFIGURATION',
  UPDATE_MODAL_CONFIGURATION: 'UPDATE_MODAL_CONFIGURATION',
}

const payrollInitialState = {
  noveltyTypeModal: false,
  overtimeModal: false,
  surchargesModal: false,
  companyOvertimeModal: false,
  noveltiesModal: false,
  incomeModal: false,
  deductionsModal: false,
  workedDaysModal: false,

  workerName: '',
  workerContractCategory: '',
  workerPayroll: {},
  noveltyConcept: '',
}

const payrollReducer = (state, action) => {
  switch (action.type) {
    case PAYROLL_ACTION_TYPES.SHOW_MODAL:
      return {
        ...state,
        ...action.modalKeys,
      }

    case PAYROLL_ACTION_TYPES.CLOSE_MODAL:
      return {
        ...state,
        ...action.modalKeys,
      }

    case PAYROLL_ACTION_TYPES.SET_MODAL_CONFIGURATION:
      return {
        ...state,
        ...action.config,
      }

    case PAYROLL_ACTION_TYPES.UPDATE_MODAL_CONFIGURATION:
      return {
        ...state,
        ...action.config,
        workerPayroll: {
          ...state.workerPayroll,
          ...action.config.updatedData.payroll,
          details: {
            ...action.config.updatedData.details,
          },
        },
      }

    default:
      throw Error(`${action.type} is not a valid action type`)
  }
}

const usePayrollConfiguration = () => {
  return useReducer(payrollReducer, payrollInitialState)
}

export default usePayrollConfiguration
