import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'
import AccountTypeField from 'components/UI/Formik/CommonFields/AccountTypeField'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import FormField from 'components/UI/Formik/FormField/Index'

import { paymentMethodsOptions } from 'utils/company'

import tooltipInfo from 'messages/worker'

const PaymentDataSection = () => {
  const { values } = useFormikContext()

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
      })}
    >
      <FormField
        name="payment_method"
        label="Método de Pago"
        variant="select"
        options={paymentMethodsOptions}
        optional={false}
      />
      {values.payment_method === 'wire_transfer' ? (
        <>
          <BankField optional={false} />
          <AccountTypeField optional={false} />
          <AccountNumberField
            tooltipContent={tooltipInfo.account_number}
            optional={false}
          />
        </>
      ) : null}
    </Box>
  )
}

export default PaymentDataSection
