import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'
import LordIcon from 'components/UI/LordIcon'

import coupleInLake from 'assets/images/views/payroll/coupleInLake.png'

import { WHATSAPP_SALES } from 'config/organization'

import { endFreeElectronicPayrollModalInfo } from './helpers'

const EndFreeElectronicPayrollModal = ({ handleClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { mobile: '1fr', tablet: '1fr 1.5fr' },
      }}
    >
      <Box
        onClick={handleClose}
        sx={(theme) => ({
          cursor: 'pointer',
          position: 'absolute',
          right: '-0.5rem',
          [theme.breakpoints.down('tablet')]: {
            right: '1rem',
          },
        })}
      >
        <Icon name="close" sx={{ fontSize: '2rem' }} />
      </Box>
      {!isMobile ? (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.white.main,
            margin: theme.spacing(-4, 0, -5, -3),
            backgroundImage: `url(${coupleInLake})`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '110% 100%',
            [theme.breakpoints.up('laptop')]: {
              marginLeft: theme.spacing(-4),
            },
          })}
        />
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(2),
          [theme.breakpoints.up('tablet')]: {
            paddingLeft: theme.spacing(4),
          },
        })}
      >
        <Typography variant="h4" color="black.main">
          Emite nómina electrónica y sálvate de la DIAN
        </Typography>
        <Typography color="black.dark">
          <b>Esta es una funcionalidad premium de Aleluya.</b>
          <br /> Cambia tu plan y emite nómina electrónica en un 2 por 3
        </Typography>

        <Box sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
          {endFreeElectronicPayrollModalInfo.map((info) => (
            <Box
              key={info.id}
              className={info.id}
              sx={(theme) => ({
                backgroundColor: info.backgroundColor,
                display: 'flex',
                marginBottom:
                  info.id !== 'socialSecurityPayment' ? theme.spacing(2) : null,
                columnGap: theme.spacing(1.5),
                borderRadius: '1rem',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingX: theme.spacing(2),
                paddingY: theme.spacing(0.5),
                boxShadow: theme.shadows[5],
              })}
            >
              <LordIcon
                sx={{ display: 'flex', alignItems: 'center' }}
                target={info.id}
                name={info.icon}
              />
              {info.text}
            </Box>
          ))}
        </Box>
        <Button
          onClick={handleClose}
          href={WHATSAPP_SALES}
          target="_blank"
          sx={(theme) => ({ paddingX: theme.spacing(2.5) })}
          endIcon={<Icon name="whatsapp" />}
        >
          Habla con ventas
        </Button>
      </Box>
    </Box>
  )
}

export default EndFreeElectronicPayrollModal
