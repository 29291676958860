import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { certificateYearOptions } from 'utils/worker'

yup.setLocale(yupLocaleES)

export const laborModalInitialValues = {
  salary_type: null,
}

export const laborValidationSchema = yup.object({
  salary_type: yup.string().nullable().required(),
})

export const getSignatureData = (data) => [
  {
    id: 'signee_name',
    key: 'Nombre',
    value: data?.signee_name,
  },
  {
    id: 'signee_position',
    key: 'Cargo',
    value: data?.signee_position,
  },
  {
    id: 'signee_id_number',
    key: 'Documento',
    value:
      data?.signee_id_number && data?.signee_document_type
        ? `${data?.signee_document_type} ${data?.signee_id_number}`
        : data?.signee_id_number || null,
  },
  {
    id: 'signee_email',
    key: 'Correo',
    value: data?.signee_email,
  },
  {
    id: 'signee_phone',
    key: 'Celular',
    value: data?.signee_phone,
  },
]

export const incomeCertificateFileTypeOptions = [
  { value: 'pdf', label: 'Certificado en PDF' },
  { value: 'xlsx', label: 'Certificado en Excel' },
]

export const incomeCertificateInitialValues = {
  certificate_year: certificateYearOptions[0].value,
  file_format: 'pdf',
}

export const incomeCertificateValidationSchema = yup.object({
  certificate_year: yup.string().nullable().required(),
  file_format: yup.string().nullable().required(),
})
