import { useMutation } from 'react-query'

import { getExogenaReport } from 'services/payroll/fileService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod } = mutationData

  if (mutationMethod === 'GET') {
    return getExogenaReport()
  }
  return null
}

const useExogenaReportService = ({ mutationOptions = {} } = {}) => {
  const { handleError } = useErrorHandler()
  const exogenaReportMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    exogenaReportMutation,
  }
}

export default useExogenaReportService
