import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const initialValues = {
  email: '',
}

export const validationSchema = yup.object({
  email: yup.string().email('Debe ser un correo electrónico válido').required(),
})
