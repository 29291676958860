import { Form, Formik } from 'formik'

import { Link, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import fileService from 'services/payroll/fileService'

import { accountingFileValidationSchema } from './helpers'

const AccountingFileModal = ({ state, handleClose }) => {
  const { enqueueDownload } = useDownloadManager()
  const { open, period, providerOptions, periodOptions, fileOptions } = state

  const fortnightlyOptions = fileOptions.filter(
    (e) => e.value !== 'social_security_payment'
  )

  const handleSubmitForm = async (values, form) => {
    const callback = () => {
      trackEvent(integrationEvent.DOWNLOAD_FILE, {
        name: 'accounting',
        type: 'PDF',
      })
    }

    form.setSubmitting(false)
    handleClose()
    enqueueDownload({
      name: 'archivo de Interfaz Contable',
      fileCode: 'accounting_file',
      firebasePath: `periods/${period.id}/accounting_file`,
      service: () =>
        fileService.getAccountingFile(period.id, {
          ...values,
          provider_id: values.provider_id.id,
        }),
      callback,
    })
  }
  const options = providerOptions?.map((provider) => ({
    selected: provider.selected,
    id: provider.value,
    name: provider.label,
  }))
  const selectedProvider = options.find((provider) => provider.selected)

  const initialValues = {
    provider_id: selectedProvider,
    file_options: [],
    // if there are periodOptions, set first value as initial value
    period_option: periodOptions[0]?.value || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={accountingFileValidationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, values }) => {
        return (
          <Modal
            open={open}
            onOk={handleSubmit}
            onCancel={handleClose}
            isLoading={isSubmitting}
            okText="Generar"
            header="Generar Interfaz Contable"
            loadingText="Generando Archivo..."
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            adornment="spring"
          >
            <Form>
              <Typography gutterBottom>
                Selecciona el software contable para el cual deseas generar el
                archivo:
              </Typography>
              {providerOptions.length > 0 ? (
                <FormField
                  name="provider_id"
                  label="Software Contable"
                  variant="autocomplete"
                  options={options}
                  disableClearable
                />
              ) : null}
              {periodOptions.length > 0 ? (
                <FormField
                  name="period_option"
                  label="Período"
                  variant="select"
                  options={periodOptions}
                />
              ) : null}
              <Typography style={{ marginTop: 15 }} gutterBottom>
                Selecciona el tipo de archivo que deseas generar. Puedes
                seleccionar varios tipos. Los archivos de `(provisión)`
                contienen la provisión de los diferentes conceptos y los
                archivos de causación contienen los pagos de estos, haz{' '}
                <Link
                  href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cuál-es-la-diferencia-entre-los-archivos-contables-provisión-y-egreso-que-genera-aleluya"
                  target="_blank"
                  underline="hover"
                >
                  click aquí
                </Link>{' '}
                para conocer las diferencias.
              </Typography>
              {fileOptions.length > 0 ? (
                <FormField
                  name="file_options"
                  label="Tipo de archivo"
                  variant="checkbox-group"
                  options={
                    values.period_option === 'fortnightly'
                      ? fortnightlyOptions
                      : fileOptions
                  }
                />
              ) : null}
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default AccountingFileModal
