import useLocalStorage from '@rehooks/local-storage'
import { setUser } from '@sentry/react'
import firebase from 'firebase/app'
import 'firebase/database'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { useQueryClient } from 'react-query'
import { Navigate } from 'react-router-dom'

import { isAuthenticated, isExcludedPath, isInsideAppSignUp } from 'utils/auth'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useSessionRefresh from 'utils/hooks/useSessionRefresh'
import loadIntegrationsData from 'utils/integration'

import { useSupportMenuContext } from '../SupportMenuContext'
import { ThemeContext } from '../ThemeProvider'
import UserContext from './UserContext'
import getFormattedSubscription from './helpers'
import { removeCurrentChat } from './loadChatScript'

const UserProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const { resetTheme } = useContext(ThemeContext)
  const { handleError } = useErrorHandler()
  const [userId, setUserId, deleteUserId] = useLocalStorage('user_id')
  const [userPicture, setUserPicture, deleteUserPicture] =
    useLocalStorage('user_picture')
  const [userName, setUserName, deleteUserName] = useLocalStorage('user_name')
  const [userEmail, setUserEmail, deleteUserEmail] =
    useLocalStorage('user_email')
  const [role, setRole, deleteRole] = useLocalStorage('role')
  const [workerId, setWorkerId, deleteWorkerId] = useLocalStorage('worker_id')
  const [companyId, setCompanyId, deleteCompanyId] =
    useLocalStorage('company_id')
  const [hasOneCompany, setHasOneCompany, deleteHasOneCompany] =
    useLocalStorage('has_one_company')
  const [authToken, setAuthToken, deleteAuthToken] =
    useLocalStorage('auth_token')
  const [expires, setExpires, deleteExpires] = useLocalStorage('expires')
  const [distributor, setDistributor, deleteDistributor] = useLocalStorage(
    'distributor',
    'nominapp'
  )
  const [active, setActive, deleteActive] = useLocalStorage('active')
  const [
    workersCertificateAccess,
    setWorkersCertificateAccess,
    deleteWorkersCertificateAccess,
  ] = useLocalStorage('workers_certificate_access')
  const [
    workersHandleNovelties,
    setWorkersHandleNovelties,
    deleteWorkersHandleNovelties,
  ] = useLocalStorage('workers_handle_novelties')
  const [multicompanyUser, setMulticompanyUser, deleteMulticompanyUser] =
    useLocalStorage('multicompany_user')
  const [companyCreatedAt, setCompanyCreatedAt, deleteCompanyCreatedAt] =
    useLocalStorage('company_created_at')
  const [toUpdateData, setToUpdateData, deleteToUpdateData] =
    useLocalStorage('to_update_data')

  const { showChat, setShowChat } = useSupportMenuContext()

  const [isArusUrl, setIsArusUrl] = useState(false)
  const companyQueryKey = useMemo(
    () => ['companyInformation', companyId],
    [companyId]
  )
  const subscriptionQueryKey = useMemo(
    () => ['getSubscription', companyId],
    [companyId]
  )
  const enableQuery =
    Boolean(companyId) &&
    !isExcludedPath(window.location.pathname) &&
    role !== 'worker'
  const { companyQuery } = useCompanyService({
    serviceParams: {
      queryKey: companyQueryKey,
    },
    queryOptions: {
      enabled: enableQuery,
      cacheTime: Infinity,
    },
  })
  const { subscriptionQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: subscriptionQueryKey,
    },
    queryOptions: {
      enabled: enableQuery,
      cacheTime: Infinity,
    },
  })
  useSessionRefresh()
  const company = useMemo(() => companyQuery.data || {}, [companyQuery.data])
  const subscription = getFormattedSubscription(subscriptionQuery.data)

  const isDataAvailable =
    companyQuery.isSuccess &&
    !!companyQuery.data &&
    subscriptionQuery.isSuccess &&
    !!subscriptionQuery.data

  // When the user is not authenticated and have inside actions
  const isLoggedIn = !!authToken || isInsideAppSignUp()

  const listenInBackground = useCallback(
    (path, serviceHandler) => {
      const listenerRef = firebase.database().ref().child(path)

      return listenerRef.on(
        'value',
        (snapshot) => {
          const value = snapshot.val()
          serviceHandler(value, listenerRef)
        },
        (error) => {
          handleError(error)
        }
      )
    },
    [handleError]
  )

  const downgradeInfoQueryKey = useMemo(
    () => ['getDowngradeInfo', companyId],
    [companyId]
  )

  const refreshOneBySessionElements = useCallback(() => {
    localStorage.removeItem('one_by_session_info_modal')
    localStorage.removeItem('upcoming_downgrade_alert_modal')
    queryClient.invalidateQueries(downgradeInfoQueryKey)
  }, [downgradeInfoQueryKey, queryClient])

  const clearFetchCompany = useCallback(() => {
    deleteCompanyId()
    deleteActive()
    deleteWorkersCertificateAccess()
    deleteWorkersHandleNovelties()
    deleteCompanyCreatedAt()
    if (showChat) setShowChat(false)
    if (window?.$zoho?.salesiq) {
      window.$zoho.salesiq.reset()
      removeCurrentChat()
    }
    refreshOneBySessionElements()
  }, [
    deleteCompanyId,
    deleteActive,
    deleteWorkersCertificateAccess,
    deleteWorkersHandleNovelties,
    deleteCompanyCreatedAt,
    showChat,
    setShowChat,
    refreshOneBySessionElements,
  ])

  const logOut = useCallback(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      deleteUserId()
      deleteUserPicture()
      deleteUserName()
      deleteUserEmail()
      deleteRole()
      deleteWorkerId()
      deleteCompanyId()
      deleteAuthToken()
      deleteExpires()
      deleteDistributor()
      deleteActive()
      deleteWorkersCertificateAccess()
      deleteWorkersHandleNovelties()
      deleteMulticompanyUser()
      deleteCompanyCreatedAt()
      deleteToUpdateData()
      localStorage.setItem('logout', Date.now())
    })
  }, [
    deleteUserId,
    deleteUserPicture,
    deleteUserName,
    deleteUserEmail,
    deleteRole,
    deleteWorkerId,
    deleteCompanyId,
    deleteAuthToken,
    deleteExpires,
    deleteDistributor,
    deleteActive,
    deleteWorkersCertificateAccess,
    deleteWorkersHandleNovelties,
    deleteMulticompanyUser,
    deleteCompanyCreatedAt,
    deleteToUpdateData,
  ])

  const logIn = useCallback(
    ({ token, expires: sessionExpiresIn, user } = {}) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setAuthToken(token)
        setExpires(sessionExpiresIn)

        if (user) {
          if (
            user.distributor !== 'arus' &&
            window.location.hostname === 'suaporte.aleluya.com'
          ) {
            window.location.replace('https://tranqui.aleluya.com')
            logOut()
          }
          if (user.company_id && user.role !== 'organizer') {
            setCompanyId(user.company_id)
            setHasOneCompany(true)
          } else if (user.company_id === null) {
            // added to fix very unlikely strange issue about
            // a login to a different alien company when a user
            // that has many companies tries to loggin
            deleteCompanyId()
            deleteHasOneCompany()
          }

          if (user.id) setUserId(user.id)
          if (user.picture) setUserPicture(user.picture)
          if (user.name) setUserName(user.name)
          if (user.email) setUserEmail(user.email)
          if (user.role) setRole(user.role)
          if (user.worker_id) setWorkerId(user.worker_id)
          if (typeof user.workers_certificate_access === 'boolean')
            setWorkersCertificateAccess(user.workers_certificate_access)
          if (typeof user.workers_handle_novelties === 'boolean')
            setWorkersHandleNovelties(user.workers_handle_novelties)
          if (typeof user.active === 'boolean') setActive(user.active)
          if (typeof user.multicompany === 'boolean')
            setMulticompanyUser(user.multicompany)
          if (user.company_created_at)
            setCompanyCreatedAt(user.company_created_at)
          if (typeof user.to_update_data === 'boolean') {
            setToUpdateData(user.to_update_data)
          }

          setDistributor(isArusUrl ? 'arus' : user.distributor || 'nominapp')
        }
      })
    },
    [
      setAuthToken,
      setExpires,
      setUserId,
      setUserPicture,
      setUserName,
      setUserEmail,
      setRole,
      setWorkerId,
      setWorkersCertificateAccess,
      setWorkersHandleNovelties,
      setActive,
      setMulticompanyUser,
      setCompanyCreatedAt,
      setDistributor,
      isArusUrl,
      logOut,
      setCompanyId,
      setHasOneCompany,
      deleteCompanyId,
      deleteHasOneCompany,
      setToUpdateData,
    ]
  )

  const updateUser = useCallback(
    ({
      name,
      email,
      role: updatedRole,
      workerId: updatedWorkerId,
      active: updatedActive,
    }) => {
      if (name) setUserName(name)
      if (email) setUserEmail(email)
      if (updatedRole) setRole(updatedRole)
      if (updatedWorkerId) setWorkerId(updatedWorkerId)
      if (typeof updatedActive === 'boolean') setActive(updatedActive)
    },
    [setActive, setRole, setUserEmail, setUserName, setWorkerId]
  )

  const clearUser = useCallback(() => {
    deleteWorkerId()
    deleteActive()
    deleteWorkersCertificateAccess()
    deleteWorkersHandleNovelties()
  }, [
    deleteActive,
    deleteWorkerId,
    deleteWorkersCertificateAccess,
    deleteWorkersHandleNovelties,
  ])

  const changeCompany = useCallback(
    (companyToChange) => {
      ReactDOM.unstable_batchedUpdates(() => {
        if (companyId !== companyToChange.id) {
          setCompanyId(companyToChange.id)
          setDistributor(
            isArusUrl ? 'arus' : companyToChange.distributor || 'nominapp'
          )
        }
      })
    },

    [companyId, isArusUrl, setCompanyId, setDistributor]
  )

  const refreshCompany = useCallback(async () => {
    await queryClient.invalidateQueries(companyQueryKey)
    await queryClient.invalidateQueries(subscriptionQueryKey)
  }, [companyQueryKey, queryClient, subscriptionQueryKey])

  const refreshIntegrationData = useCallback(async () => {
    await refreshCompany()
    loadIntegrationsData(company, subscription)
  }, [company, refreshCompany, subscription])

  const clearCompany = useCallback(() => {
    deleteCompanyId()
    setDistributor(isArusUrl ? 'arus' : 'nominapp')
  }, [deleteCompanyId, isArusUrl, setDistributor])

  useEffect(() => {
    const handleLocalStorageChange = (event) => {
      const { key } = event

      // window.location to be precise
      if (key === 'logout' && !isExcludedPath(window.location.pathname)) {
        // redirect to login if has logged out
        return <Navigate to="/login" />
      }
    }

    // logout on all tabs and redirect to login
    window.addEventListener('storage', handleLocalStorageChange)

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange)
    }
  }, [])

  useEffect(() => {
    if (userId) {
      setUser({
        id: userId,
        username: userName,
        email: userEmail,
        picture: userPicture,
      })
    }
  }, [userId, userName, userEmail, userPicture])

  useEffect(() => {
    if (isDataAvailable) {
      if (companyId && role !== 'worker') {
        loadIntegrationsData(company, subscription)
      }
    }
  }, [company, companyId, isDataAvailable, role, subscription])

  useEffect(() => {
    resetTheme(distributor)
  }, [distributor, resetTheme])

  useEffect(() => {
    // Look if is arus distributor
    if (window.location.hostname === 'suaporte.aleluya.com') {
      setIsArusUrl(true)
      setDistributor('arus')
    }
  }, [setDistributor])

  const isAPartnerChild =
    company?.partner_company_freemium && !subscription?.partner

  const user = useMemo(
    () => ({
      id: userId,
      picture: userPicture,
      name: userName,
      email: userEmail,
      role,
      workerId,
      workersCertificateAccess,
      workersHandleNovelties,
      multicompanyUser,
      companyCreatedAt,
      toUpdateData,
    }),
    [
      userId,
      userPicture,
      userName,
      userEmail,
      role,
      workerId,
      workersCertificateAccess,
      workersHandleNovelties,
      multicompanyUser,
      companyCreatedAt,
      toUpdateData,
    ]
  )

  const isQueryLoading = companyQuery.isLoading || subscriptionQuery.isLoading

  const value = useMemo(
    () => ({
      user,
      company,
      hasOneCompany,
      subscription,
      distributor,
      active,
      logIn,
      logOut,
      updateUser,
      clearUser,
      clearCompany,
      changeCompany,
      refreshCompany,
      clearFetchCompany,
      refreshIntegrationData,
      authToken,
      expires,
      isAuthenticated,
      isWorker: role === 'worker',
      isOrganizer: role === 'organizer',
      isLoading: isQueryLoading,
      listenInBackground,
      isLoggedIn,
      isAPartnerChild,
    }),
    [
      user,
      company,
      hasOneCompany,
      subscription,
      distributor,
      active,
      logIn,
      logOut,
      updateUser,
      clearUser,
      clearCompany,
      changeCompany,
      refreshCompany,
      clearFetchCompany,
      refreshIntegrationData,
      authToken,
      expires,
      role,
      isQueryLoading,
      listenInBackground,
      isLoggedIn,
      isAPartnerChild,
    ]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserProvider
