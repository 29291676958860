import Table from 'components/UI/Table/Table'

import { columnsDataDetail } from './helpers'

const ErrorDetail = ({ errors }) => {
  const details = errors?.map((detail, index) => ({
    item: index + 1,
    detail,
  }))

  return (
    <Table
      columns={columnsDataDetail}
      data={details}
      options={{ toolbar: false, pagination: false }}
      sx={{
        tableContainerSx: (theme) => ({
          backgroundColor: theme.palette.accent1.main,
        }),
      }}
    />
  )
}

export default ErrorDetail
