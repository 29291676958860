import { Box, Button, Card, Typography } from '@mui/material'

const EditOptions = ({
  handleCloseModal,
  handleNewWorkCenter,
  workCenterToUpdate,
}) => {
  return (
    <div>
      <Typography>
        Acá puedes editar un centro de trabajo, recuerda tener presente la fecha
        de cambio ya que este cambio afecta el centro de trabajo.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: theme.spacing(3),
          marginTop: theme.spacing(5),
          marginBottom: theme.spacing(4),
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        })}
      >
        <Card
          sx={(theme) => ({
            minHeight: '16.5rem',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 3, 2, 3),
          })}
        >
          <Typography variant="h5">
            Editar los datos del centro de trabajo
          </Typography>
          <Typography>
            En caso de que desees modificar la información actual de tu centro
            de trabajo modificando la información histórica.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignSelf: 'end',
              flexGrow: 1,
              flexDirection: 'column-reverse',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleCloseModal()
                handleNewWorkCenter(workCenterToUpdate, false)
              }}
            >
              Editar
            </Button>
          </Box>
        </Card>
        <Card
          sx={(theme) => ({
            minHeight: '16.5rem',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 3, 2, 3),
          })}
        >
          <Typography variant="h5">
            Crear información nueva al centro de trabajo
          </Typography>
          <Typography>
            En caso de que desees modificar la información de este centro de
            trabajo desde la fecha de definas.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignSelf: 'end',
              flexGrow: 1,
              flexDirection: 'column-reverse',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleCloseModal()
                handleNewWorkCenter(workCenterToUpdate, true)
              }}
            >
              Crear
            </Button>
          </Box>
        </Card>
      </Box>
    </div>
  )
}

export default EditOptions
