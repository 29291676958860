import { Backdrop, Paper, Typography } from '@mui/material'

import SvgLoading from './SvgLoading'

const LoadingModal = ({
  header = 'Estamos procesando la información...',
  text = 'En unos instantes estaremos listos.',
}) => {
  return (
    <Backdrop
      open
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 2000, // arbitrary higher number to be in top of modals
      })}
    >
      <Paper
        sx={(theme) => ({
          minWidth: '25rem',
          width: '25rem',
          padding: theme.spacing(4),
          textAlign: 'center',
        })}
        data-cy="loading_modal"
      >
        <Typography variant="h5">{header}</Typography>
        <SvgLoading />
        <Typography>{text}</Typography>
      </Paper>
    </Backdrop>
  )
}

export default LoadingModal
