import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import useElectronicSignatureService from 'utils/hooks/electronicSignature/electronicSignature'

import DocumentStatus from './DocumentStatus'
import FinishedContent from './FinishedContent'
import InProgressContent from './InProgressContent'

const FinalSigningsStatusModalContent = ({
  status,
  workerId,
  workerName,
  handleClose,
}) => {
  const electronicSignatureQueryKey = [
    'getElectronicSignatureResults',
    workerId,
  ]

  const { electronicSignatureQuery } = useElectronicSignatureService({
    serviceParams: {
      queryKey: electronicSignatureQueryKey,
      workerId,
    },
  })

  const electronicSignatureQueryData = electronicSignatureQuery?.data || []

  return (
    <>
      {electronicSignatureQuery.isLoading ? (
        <LoadingBox />
      ) : (
        <>
          {status === 'in_progress' && electronicSignatureQueryData ? (
            <InProgressContent
              electronicSignatureQueryData={electronicSignatureQueryData}
              workerId={workerId}
              handleClose={handleClose}
            >
              <DocumentStatus
                workerId={workerId}
                electronicSignatureQueryData={electronicSignatureQueryData}
              />
            </InProgressContent>
          ) : null}

          {status === 'finished' && electronicSignatureQueryData ? (
            <FinishedContent
              workerName={workerName}
              workerId={workerId}
              electronicSignatureQueryData={electronicSignatureQueryData}
              handleClose={handleClose}
            >
              <DocumentStatus
                workerId={workerId}
                electronicSignatureQueryData={electronicSignatureQueryData}
              />
            </FinishedContent>
          ) : null}
        </>
      )}
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(7),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          onClick={handleClose}
          sx={(theme) => ({ padding: theme.spacing(0, 8) })}
        >
          Regresar
        </Button>
      </Box>
    </>
  )
}

export default FinalSigningsStatusModalContent
