import { Box, Paper, Typography, useMediaQuery } from '@mui/material'

import AleluyaPerson from 'components/UI/AleluyaPerson'
import Emoji from 'components/UI/Emoji'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { wrench } from 'utils/emojis'

const EmptyState = ({ children, title, maintenance = false }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Paper
      sx={(theme) => ({
        maxWidth: '67.5rem',
        padding: theme.spacing(4),
      })}
    >
      <Typography variant="h6" color="primary">
        {title}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gap: theme.spacing(2),
          gridTemplateColumns: '1fr',
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr auto',
          },
        })}
      >
        <div>
          {children}
          {maintenance ? (
            <Alert
              severity="info"
              header="Conexión estará deshabilitada temporalmente por mantenimiento en la DIAN."
              icon={false}
            >
              <Emoji code={wrench} />
              Entre {maintenance}.
            </Alert>
          ) : null}
        </div>
        <div>
          {isDesktop ? (
            <AleluyaPerson
              color="primary.main"
              width={280}
              height={280}
              viewBox="0 0 330 400"
            />
          ) : null}
        </div>
      </Box>
    </Paper>
  )
}

export default EmptyState
