import { createContext, useContext, useState } from 'react'

export const SupportMenuContext = createContext()
SupportMenuContext.displayName = 'SupportMenuContext'

export const useSupportMenuContext = () => {
  const context = useContext(SupportMenuContext)
  if (!context)
    throw new Error(
      'useSupportMenuContext must be used within SupportMenuContext'
    )
  return context
}

const SupportMenuProvider = ({ children }) => {
  const [emailOpen, setEmailOpen] = useState(false)
  const [showSupportActionGroup, setShowSupportActionGroup] = useState(false)
  const [showChat, setShowChat] = useState(false)

  const [state, setState] = useState({
    supportMenuOpenedFromOutside: false,
  })

  const openSupportMenuFromOutside = () => {
    setState((previousState) => ({
      ...previousState,
      supportMenuOpenedFromOutside: true,
    }))
  }

  const closeSupportMenuFromOutside = () => {
    setState((previousState) => ({
      ...previousState,
      supportMenuOpenedFromOutside: false,
    }))
  }

  const supportMenuContextValue = {
    showChat,
    emailOpen,
    showSupportActionGroup,
    supportMenuState: state,
    setShowChat,
    setEmailOpen,
    setShowSupportActionGroup,
    openSupportMenuFromOutside,
    closeSupportMenuFromOutside,
  }

  return (
    <SupportMenuContext.Provider value={supportMenuContextValue}>
      {children}
    </SupportMenuContext.Provider>
  )
}

export default SupportMenuProvider
