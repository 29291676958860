import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'
import useCompanyCredentialsService from 'utils/hooks/company/credentials'
import useNotifications from 'utils/hooks/useNotifications'

import { updateCredentials } from 'services/company/companyService'

import {
  CompanyDocumentField,
  getCredentialsColumns,
  validationSchema,
} from './helpers'

const Credentials = () => {
  const { values } = useFormikContext()
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()

  const companyId = getCompanyId()

  const taxDocumentUrl = isValidUrl(values?.files?.tax_document)
  const identificationDocumentUrl = isValidUrl(
    values?.files?.identification_document
  )
  const legalRepresentativeDocumentUrl = isValidUrl(
    values?.files?.legal_representative_document
  )
  const bankCertificateDocumentUrl = isValidUrl(
    values?.files?.bank_certificate_document
  )

  const textDocumentName = getFileNameFromUrl(
    taxDocumentUrl ? taxDocumentUrl.search : undefined
  )
  const identificationDocumentName = getFileNameFromUrl(
    identificationDocumentUrl ? identificationDocumentUrl.search : undefined
  )
  const legalRepresentativeDocumentName = getFileNameFromUrl(
    legalRepresentativeDocumentUrl
      ? legalRepresentativeDocumentUrl.search
      : undefined
  )
  const bankCertificateDocumentName = getFileNameFromUrl(
    bankCertificateDocumentUrl ? bankCertificateDocumentUrl.search : undefined
  )

  const { companyCredentialsQuery } = useCompanyCredentialsService({
    queryParams: {
      queryKey: ['getCredentials', companyId],
    },
  })

  const credentialsColumns = getCredentialsColumns()

  const callback = (action) => {
    queryClient.invalidateQueries(['getCredentials', companyId])
    showSuccessMessage(`La credencial ha sido ${action}.`)
  }

  const handleAddCredentials = (credentialToCreate) => {
    const valuesToSend = credentialToCreate
    delete valuesToSend.institution_category

    return {
      data: {
        company_credentials: [valuesToSend],
      },
      mutationFunction: updateCredentials,
      mutateOptions: {
        onSuccess: () => callback('creada'),
      },
    }
  }

  const handleUpdateCredential = (oldCredentials, newCredential) => {
    const dirtyValues = getDirtyValues(
      oldCredentials,
      newCredential,
      validationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      return {
        data: {
          company_credentials: [
            {
              id: newCredential.id,
              institution_id: dirtyValues.provider_id,
              ...dirtyValues,
            },
          ],
        },
        mutationFunction: updateCredentials,
        mutateOptions: {
          onSuccess: () => callback('actualizada'),
        },
      }
    }

    return {}
  }

  const handleDeleteCredential = (credentialToRemove) => {
    return {
      data: {
        company_credentials: [{ id: credentialToRemove.id }],
      },
      mutationFunction: updateCredentials,
      mutateOptions: {
        onSuccess: () => callback('eliminada'),
      },
    }
  }

  return (
    <Box>
      <Typography variant="h6" color="primary" gutterBottom>
        Documentación
      </Typography>
      <Box
        sx={(theme) => ({
          marginBottom: theme.spacing(7),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'end',
          },
        })}
      >
        <CompanyDocumentField
          fieldName="tax_document"
          documentName={textDocumentName}
          documentUrl={taxDocumentUrl}
          label="RUT"
          tooltipContent="
              Adjunta el RUT de la empresa para
              realizar la correcta Facturación
              Electrónica de tu suscripción."
        />
        <CompanyDocumentField
          fieldName="identification_document"
          documentName={identificationDocumentName}
          label="Cámara de comercio"
          documentUrl={identificationDocumentUrl}
        />
        <CompanyDocumentField
          fieldName="legal_representative_document"
          documentName={legalRepresentativeDocumentName}
          label="Cédula del representante legal"
          documentUrl={legalRepresentativeDocumentUrl}
        />
        <CompanyDocumentField
          fieldName="bank_certificate_document"
          documentName={bankCertificateDocumentName}
          label="Certificación bancaria"
          documentUrl={bankCertificateDocumentUrl}
        />
      </Box>
      <div>
        <Typography variant="h6" color="primary" gutterBottom>
          Credenciales
        </Typography>
      </div>
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(3),
        })}
      >
        <Table
          data={companyCredentialsQuery?.data || []}
          columns={credentialsColumns}
          options={{
            pagination: false,
            search: false,
            alignActionsCell: 'center',
            version: {
              toolbar: 'v2',
            },
          }}
          editable={{
            validationSchema,
            onAddRow: handleAddCredentials,
            onUpdateRow: handleUpdateCredential,
            onDeleteRow: handleDeleteCredential,
            enableAddRow: true,
            addRowPosition: 'top',
            addRowActionProps: {
              tooltip: 'Añadir entidad',
            },
            tableMinWidth: 720,
          }}
        />
      </Box>
    </Box>
  )
}

export default Credentials
