import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { formatDisplayDateString } from 'utils/dateTime'

import { useUser } from '../UserContext/useUser'
import { getStatusMessages } from './helpers'

const AppSubscriptionStatusAlert = ({ isInSubscriptionStatusView }) => {
  const { isAPartnerChild } = useUser()
  const { subscription } = useSubscription()

  const {
    status,
    active_until: activeUntil,
    payment_status: paymentStatus,
    company_access: companyAccess,
  } = subscription

  const activeUntilFormated = (
    <strong>{formatDisplayDateString(activeUntil)}</strong>
  )

  const { message, severity } = getStatusMessages({
    status,
    activeUntilFormated,
    isInSubscriptionStatusView,
    isAPartnerChild,
    paymentStatus,
    companyAccess,
  })

  return message ? (
    <Alert severity={severity} closable={!isInSubscriptionStatusView}>
      {message}
    </Alert>
  ) : null
}

export default AppSubscriptionStatusAlert
