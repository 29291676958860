import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useRef } from 'react'

import {
  Box,
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import Icon from '../Icon'

const FilterButton = ({
  defaultLabel,
  options = [],
  value = null,
  onChange = () => {},
  onCleanFilter,
  dataCy,
}) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filter',
  })
  const selectedOption = options.find((option) => option?.value === value)
  const defaultValue = useRef(value)

  const onClick = (selectedValue) => {
    onChange(selectedValue)
    popupState.close()
  }

  const handleCleanFilter = () => {
    if (onCleanFilter) {
      onCleanFilter()
    }
    if (!onCleanFilter && onChange && value !== defaultValue.current) {
      onChange(defaultValue.current)
    }

    popupState.close()
  }

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        variant="outlined"
        endIcon={<Icon name="funnel" />}
        data-cy={dataCy}
      >
        {selectedOption?.label || defaultLabel}
      </Button>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock
      >
        {options && options.length > 0 ? (
          <div>
            <Box
              sx={(theme) => ({
                display: 'flex',
                padding: theme.spacing(0, 2),
              })}
            >
              <Button
                variant="text"
                sx={(theme) => ({
                  color: theme.palette.accent4.dark,
                  '&:focus, &:hover': {
                    color: theme.palette.accent4.dark,
                  },
                })}
                onClick={handleCleanFilter}
              >
                Limpiar filtro
              </Button>
            </Box>
            {options.map((option, index) => {
              return (
                <MenuItem
                  onClick={() => onClick(option.value)}
                  key={`option_${index.toString()}`}
                >
                  <ListItemText>{option.label}</ListItemText>
                </MenuItem>
              )
            })}
          </div>
        ) : (
          <Box
            sx={(theme) => ({
              padding: theme.spacing(1, 2),
            })}
          >
            <Typography color="black.light">Sin opciones</Typography>
          </Box>
        )}
      </Menu>
    </>
  )
}

export default FilterButton
