import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import { setCompanyId } from 'utils/company'
import { getDirtyValues, yupLocaleES } from 'utils/form'
import { removeSuffix } from 'utils/general'
import useCompanyPaymentService from 'utils/hooks/organizer/companyPaymentService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { billingFrencuencyOptions } from 'utils/payment'

import * as routes from 'config/routes'

import { bankTransferInitialValues } from '../helpers'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  payment_date: yup.string().nullable().required(),
  payment_type: yup.string().required(),
  value: yup.number().required(),
})

const OrganizerCompanyPaymentBankTransferForm = ({ companyId }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()

  const companyQuery =
    queryClient.getQueryData(['getCompanyFromOrganizer', companyId]) || {}

  const isPerWorkerPlan =
    removeSuffix(companyQuery?.data?.plan_category, -8) === 'per_worker_plan'

  const { companyPaymentMutation } = useCompanyPaymentService({
    queryOptions: {
      enabled: false,
    },
  })

  const plansQuery = queryClient.getQueryData(['getPlans', companyId]) || {}

  const plans = (plansQuery?.data?.plans || []).map((plan) => {
    return {
      value: plan.id,
      label: isPerWorkerPlan ? plan.name : plan.name.split(' ')[2],
    }
  })

  const onSubmit = (values, form) => {
    const dirtyValues = getDirtyValues({}, values)

    companyPaymentMutation.mutate(
      {
        mutationMethod: 'POST',
        companyId,
        payment: dirtyValues,
      },
      {
        onSuccess: () => {
          setCompanyId(companyId)
          navigate(routes.SUBSCRIPTION_STATUS())
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={bankTransferInitialValues}
    >
      {(form) => {
        const { handleSubmit } = form

        return (
          <Form>
            <Box
              sx={(theme) => ({
                marginTop: theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(3),
              })}
            >
              <FormField
                name="payment_date"
                label="Fecha del Pago"
                variant="datepicker"
              />
              <FormField
                name="value"
                label="Valor del Pago"
                variant="currency"
                align="left"
              />
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  gridGap: theme.spacing(2),
                  gridTemplateColumns: '1fr 1fr',
                })}
              >
                <FormField
                  name="plan_id"
                  label="Plan"
                  variant="select"
                  disabled={plans.length === 0}
                  options={plans}
                />

                <FormField
                  name="paid_extra_workers"
                  label={`Número de personas ${isPerWorkerPlan ? '' : 'extra'}`}
                  type="number"
                  align="left"
                  autoComplete="off"
                />
              </Box>
              <FormField
                name="payment_type"
                label="Frecuencia"
                variant="select"
                options={billingFrencuencyOptions}
              />

              <FormField name="invoice_id" label="ID Factura" align="left" />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  color="primary"
                  loading={companyPaymentMutation.isLoading}
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OrganizerCompanyPaymentBankTransferForm
