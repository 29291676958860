/**
 *
 * @param {number} percent Between 0-100
 */
export function getAlphaHexValue(percent) {
  if (percent > 100 || percent < 0) throw new Error('Value out of range')

  const alpha = Math.round((percent / 100) * 255)
  const hex = (alpha + 0x10000).toString(16).substr(-2).toUpperCase()

  return hex
}

export default getAlphaHexValue
