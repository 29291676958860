import { useFormikContext } from 'formik'

import { Box, Link as MuiLink, Paper, Typography } from '@mui/material'

import useSubscription from '../Atoms/useSubscription'
import { getInitialCopy, paymentModality } from './helpers'

const TermsAcceptanceCard = ({ paymentVariant, newSubscriptionType }) => {
  const { values } = useFormikContext()

  const {
    subscription: {
      type,
      subscription_payment_methods_info: paymentMethods,
    } = {},
  } = useSubscription()

  const initialCopies = getInitialCopy(paymentVariant, paymentMethods)

  return (
    <Paper
      elevation={2}
      sx={(theme) => ({
        padding: theme.spacing(2),
        borderRadius: '1rem',
      })}
    >
      <Typography
        variant="body2"
        color="black.dark"
        sx={{ cursor: 'default', textAlign: 'justify' }}
      >
        {initialCopies}, autorizas a Aleluya a renovar tu plan de forma{' '}
        <Box component="span" sx={{ color: 'primary.main' }}>
          <b>
            {paymentModality[values.modality || newSubscriptionType || type]}
          </b>
        </Box>{' '}
        y aceptas nuestros{' '}
        <MuiLink
          color="primary.main"
          target="_blank"
          href="https://aleluya.com/terminos-y-condiciones"
          sx={{
            fontWeight: 600,
            cursor: 'pointer',
          }}
        >
          Términos y Condiciones
        </MuiLink>
        . Puedes cancelar o modificar tu plan en cualquier momento.
      </Typography>
    </Paper>
  )
}

export default TermsAcceptanceCard
