import CardButton from '../../Modals/NoveltyType/NoveltyCard/CardButton'
import { usePeriod, usePeriodAPI } from '../../helpers'

const LicensesAction = ({ payroll }) => {
  const { period } = usePeriod()
  const { openNoveltiesModal } = usePeriodAPI()

  return (
    <CardButton
      payrollConceptValue={payroll.details?.licenses?.quantity}
      onClick={() => openNoveltiesModal('licenses')}
      editable={period.editable && !payroll.locked}
      dataCy="add-licenses"
    />
  )
}

export default LicensesAction
