import { useState } from 'react'

import Table from 'components/UI/Table/Table'

import usePeriodService from 'utils/hooks/organizer/periodService'
import useNotifications from 'utils/hooks/useNotifications'

import { getAllElectronicPayrolls } from 'services/payroll/electronicPayrollPeriodService'

import NewPeriodModal from '../Modals/NewPeriodModal'
import { columnsData, useGetActions } from './helpers'

const ElectronicPayrollHistory = ({ activeTab }) => {
  const { periodMutation } = usePeriodService()
  const { showInfoMessage } = useNotifications()
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  const [
    newElectronicPayrollPeriodModalOpen,
    setNewElectronicPayrollPeriodModalOpen,
  ] = useState(false)

  const fetchElectronicPayrolls = (pageIndex) => {
    return {
      queryKey: [
        'getAllElectronicPayrolls',
        pageIndex + 1,
        selectedMonth?.id,
        selectedYear?.id,
      ],
      queryFunction: () =>
        getAllElectronicPayrolls({
          page: pageIndex + 1,
          month: selectedMonth?.id,
          year: selectedYear?.id,
        }),
    }
  }

  const handleOrganizerElectronicPeriodAction = (
    electronicPayrollPeriodId,
    perform
  ) => {
    periodMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationAction: perform,
        electronicPayrollPeriodId,
      },
      {
        onSuccess: ({ message }) => showInfoMessage(message),
      }
    )
  }

  const actions = useGetActions(
    handleOrganizerElectronicPeriodAction,
    setNewElectronicPayrollPeriodModalOpen,
    setSelectedMonth,
    selectedMonth,
    setSelectedYear,
    selectedYear
  )

  return (
    <>
      <Table
        columns={columnsData}
        data={fetchElectronicPayrolls}
        actions={actions}
        data-cy="electronic_payrolls-table"
        options={{
          search: false,
          alignActionsCell: 'center',
          version: {
            toolbar: 'v2',
          },
        }}
      />
      {newElectronicPayrollPeriodModalOpen ? (
        <NewPeriodModal
          visible={newElectronicPayrollPeriodModalOpen}
          handleClose={() => setNewElectronicPayrollPeriodModalOpen(false)}
          activeTab={activeTab}
        />
      ) : null}
    </>
  )
}

export default ElectronicPayrollHistory
