import { useFormikContext } from 'formik'

import { useTheme } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'

import { contractCategoriesOptions, getMinimumSalary } from 'utils/worker'

import messages from 'messages/contract'

const getWageCategory = (contractCategory, wageCategory) => {
  if (wageCategory) {
    if (contractCategory !== 'contractor' && wageCategory === 'per_hour')
      return null
    return wageCategory
  }

  return null
}

const CategoryField = ({ name = 'category', optional, isEditing = false }) => {
  const form = useFormikContext()
  const confirm = useConfirm()
  const { values, setFieldValue } = form
  const contractTypes = ['employee']
  const themeInstance = useTheme()

  const emptyCategory = (value, infoContract, newTransportSubsidy) => {
    if (contractTypes.includes(value)) {
      Object.assign(infoContract, {
        term: null,
      })
    } else {
      Object.assign(infoContract, {
        term: 'fixed',
        wage_category:
          value !== 'contractor' ? 'monthly' : values.wage_category,
        transport_subsidy: newTransportSubsidy,
      })
    }

    form.setValues(infoContract)
  }

  const handleOnChangeCategory = (value) => {
    const { term, wage_category: wageCategory } = values
    const category = values[name]
    const infoContract = {
      ...values,
      [name]: value,
    }

    const newTransportSubsidy =
      values.transport_subsidy ??
      (values.base_salary < getMinimumSalary({ multiplier: 2 }) ? true : null)

    // if is the first time that contract_category is selected
    if (!category)
      return emptyCategory(value, infoContract, newTransportSubsidy)

    if (contractTypes.includes(category) && value !== 'employee') {
      let isTerm = 'fixed'
      if (term && term !== 'indefinite') isTerm = term

      const salaryCategories = ['integral_salary', 'part_time'].includes(
        wageCategory
      )
        ? null
        : wageCategory

      Object.assign(infoContract, {
        term: isTerm,
        wage_category: salaryCategories,
        transport_subsidy: newTransportSubsidy,
      })
    } else {
      Object.assign(infoContract, {
        wage_category: getWageCategory(value, wageCategory),
        transport_subsidy: newTransportSubsidy,
      })
    }

    return form.setValues(infoContract)
  }

  const handleChange = ({ target: { value } }) => {
    if (!isEditing) {
      handleOnChangeCategory(value)
    } else {
      confirm({
        title: 'Cambio de tipo de contrato',
        description:
          'Vas a realizar un cambio en el Tipo de Contrato de la persona y por ende en el Subtipo de Cotizante que se registrará en el Pago de su seguridad social. ¿Estás seguro de continuar?',
        okText: 'Sí',
        cancelText: 'No',
        okButtonProps: {
          sx: { padding: themeInstance.spacing(0, 10) },
        },
        cancelButtonProps: {
          sx: { padding: themeInstance.spacing(0, 10) },
        },
        adornment: {
          variant: 'asterisk',
          color: 'complementary1.light',
          width: 100,
          height: 100,
          sx: { bottom: '1rem', right: '2rem' },
        },
        onOk: () => handleOnChangeCategory(value),
      })
    }

    if (!['employee', 'part_time_contract'].includes(value))
      setFieldValue('contributor_subtype', null)
  }

  return (
    <FormField
      name={name}
      label="Tipo de contrato"
      variant="select"
      options={contractCategoriesOptions}
      tooltipContent={messages.category}
      onChange={handleChange}
      optional={optional}
    />
  )
}

export default CategoryField
