import qs from 'query-string'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Typography } from '@mui/material'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import Page from 'components/UI/Page/Page'

import usePartnerCompany from 'utils/hooks/organizer/partner/company'

import PartnerSummary from './Summary'
import CompanyTab from './Tab/Company'
import PaymentTab from './Tab/Payment'
import { formatSummaryData } from './helpers'

const OrganizerPartnerShow = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const queryParams = qs.parse(location.search)
  const partnerId = params?.partnerId
  const [activeTab, setActiveTab] = useState(queryParams.tab || 'companies')
  const { partnerCompanyQuery } = usePartnerCompany({
    serviceParams: {
      queryKey: ['getPartnerCompanies', partnerId],
      partnerId,
    },
  })
  const negotiationDetails = partnerCompanyQuery.data?.negotiation_details || {}

  const handleChangeTab = (_, newTab) => {
    setActiveTab(newTab)
    navigate(`?tab=${newTab}`, {
      replace: true,
    })
  }

  const summaryFormatted = formatSummaryData(negotiationDetails)

  const partnerName = negotiationDetails.partner_name

  return (
    <Page
      documentTitle={`${partnerName ? `${partnerName} - ` : ''}Partner`}
      header={
        <>
          <Typography variant="h5" gutterBottom>
            Partner
            {partnerName ? (
              <>
                {' - '}
                <Typography component="span" variant="h5" color="primary">
                  {partnerName}
                </Typography>
              </>
            ) : null}
          </Typography>
        </>
      }
      isLoading={partnerCompanyQuery.isLoading}
    >
      <PartnerSummary data={summaryFormatted} />
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        tabs={[
          { key: 'companies', label: 'Empresas' },
          { key: 'payments', label: 'Pagos' },
        ]}
        backgroundColor="white"
      />
      {activeTab === 'companies' ? <CompanyTab partnerId={partnerId} /> : null}

      {activeTab === 'payments' ? <PaymentTab partnerId={partnerId} /> : null}
    </Page>
  )
}

export default OrganizerPartnerShow
