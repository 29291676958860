import { useState } from 'react'

/**
 * Used in conjunction with ContractModal
 */
const useContractModal = (workerId) => {
  const [state, setState] = useState({
    open: false,
    contract: {},
    workerId,
  })

  const openModal = async (contract = {}) => {
    setState({ open: true, contract, workerId })
  }

  const closeModal = () => {
    setState({
      open: false,
      contract: {},
      workerId: undefined,
    })
  }

  return {
    contractModalState: state,
    openContractModal: openModal,
    closeContractModal: closeModal,
  }
}

export default useContractModal
