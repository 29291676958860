import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { Link, useNavigate } from 'react-router-dom'

import { ListItemText, Menu, MenuItem } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import useExtraWorkersModal from 'components/Subscription/Atoms/ExtraWorkersModal/useExtraWorkersModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'

import * as routes from 'config/routes'

import Icon from '../Icon'

const ButtonAddWorker = ({
  variant,
  handleAddWorkerManually = () => {},
  enableFileOption = true,
  openNewWorkerModal = () => {},
}) => {
  const { additionalWorkersInfo } = useSubscription()
  const extraWorkersModal = useExtraWorkersModal()
  const { company } = useUser()
  const navigate = useNavigate()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'addWorkersMenu',
  })

  const { onboarding_first_steps: onboardingFirstSteps = {} } = company

  const hasToPayNewWorkers = !additionalWorkersInfo?.add_workers

  const handleAddWorker = () => {
    popupState.close()

    if (hasToPayNewWorkers) {
      extraWorkersModal.openModal()
    } else if (!onboardingFirstSteps?.first_worker) {
      navigate(routes.WORKER_NEW())
    } else if (handleAddWorkerManually) {
      handleAddWorkerManually()
    } else {
      openNewWorkerModal()
    }
  }

  return (
    <>
      <Button
        endIcon={
          enableFileOption ? (
            <Icon name="arrow-down" basic />
          ) : (
            <Icon name="avatar-plus" basic />
          )
        }
        {...(enableFileOption
          ? bindTrigger(popupState)
          : {
              onClick: handleAddWorker,
            })}
        variant={variant}
      >
        Agregar persona
      </Button>
      {enableFileOption ? (
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleAddWorker}>
            <ListItemText>Ingresar manualmente</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to={routes.WORKER_IMPORT()}>
            <ListItemText> Subir archivo</ListItemText>
          </MenuItem>
        </Menu>
      ) : null}
    </>
  )
}

export default ButtonAddWorker
