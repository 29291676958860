import { Navigate } from 'react-router-dom'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import WagesUpdate from 'components/Worker/Wage/Update/Index'

const View = (props) => {
  const {
    subscription: { payrolls_size: payrollsSize },
  } = useSubscription()

  if (!payrollsSize) {
    return <Navigate to="/workers?tab=active" />
  }

  return <WagesUpdate {...props} />
}

export default View
