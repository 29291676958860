import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'

import { wageCategoriesOptions } from 'utils/worker'

import messages from 'messages/wage'

const CategoryField = ({
  contractCategory,
  name = 'category',
  optional,
  label,
}) => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    // default value
    if (contractCategory && !values[name])
      setFieldValue(name, wageCategoriesOptions[0].value)
  }, [contractCategory, name, setFieldValue, values])

  const getOptions = () => {
    if (contractCategory) {
      if (contractCategory === 'employee') {
        return wageCategoriesOptions.filter((c) => c.value !== 'per_hour')
      }
      if (contractCategory === 'part_time_contract') {
        return wageCategoriesOptions.filter(
          (c) => !['per_hour', 'integral_salary'].includes(c.value)
        )
      }
      if (contractCategory === 'contractor') {
        return wageCategoriesOptions.filter(
          (c) => c.value === 'per_hour' || c.value === 'monthly'
        )
      }
      return wageCategoriesOptions.filter((c) => c.value === 'monthly')
    }
    return []
  }

  return (
    <FormField
      data-cy="category_id"
      name={name}
      label={label}
      variant="select"
      tooltipContent={messages.category}
      disabled={!contractCategory}
      options={getOptions()}
      optional={optional}
    />
  )
}

export default CategoryField
