import { Link } from 'react-router-dom'

import { Box, Button, Link as MUILink, Typography, alpha } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import Icon from 'components/UI/Icon'
import Tooltip from 'components/UI/Tooltip'

import { isArusDist } from 'utils/auth'

import * as routes from 'config/routes'

import { Decree376Menu } from './Novelties'
import PaySocialSecurityMenu from './Payments/PaySocialSecurityMenu'
import SocialBenefitsMenu from './Payments/SocialBenefitsMenu'
import SocialSecurityFileMenu from './Payments/SocialSecurityFileMenu'
import getSeverance from './common/helpers'

export const CLAIMS_LINK = 'https://form.typeform.com/to/ou2aHOip'

const icons = {
  service_bonus: <Icon name="refund" basic />,
  severance_interests: <Icon name="coin" basic />,
  severance: <Icon name="document-coin" basic />,
}

export const ActionContent = ({ label, tag }) => {
  return (
    <Typography
      variant="lead2"
      sx={{
        textWrap: 'balance',
      }}
    >
      <Box
        component="span"
        sx={{
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {label}
      </Box>
      {tag ? (
        <>
          {' '}
          <Box
            component="span"
            sx={(theme) => ({
              padding: theme.spacing(0, 0.6, 0.2, 0.6),
              color: theme.palette.primary.dark,
              backgroundColor: alpha(theme.palette.primary.light, 0.2),
              borderRadius: '0.5rem',
              position: 'relative',
              top: -3,
              ...theme.typography.tiny,
            })}
          >
            {tag}
          </Box>
        </>
      ) : null}
      <PremiumFeatureIcon
        fontSize="1rem"
        sx={(theme) => ({
          marginLeft: theme.spacing(1),
          position: 'relative',
          bottom: -1,
        })}
      />
    </Typography>
  )
}

const getActions = ({
  periodData,
  periodAPIData,
  electronicPeriodId,
  maintenance,
  distributor,
  themeInstance,
  showPremiumFeatures,
  openPremiumFeatureModal,
  navigate,
  endFreeFuntionalitiesModals,
  disabilityClaimsV2,
  flagsReady,
}) => {
  const {
    payPreviousPayroll,
    period: {
      id: periodId,
      editable: periodEditable,
      claimable_novelties: claimableNovelties,
    },
    options: {
      bank_file: bankFile,
      payslips_pdf: payslipsPdf,
      payslips_zip: payslipsZip,
      social_benefits: socialBenefits = [],
      payslips_emails: payslipsEmails,
      novelties_report: noveltiesReport,
      decree376_periods: decree376Periods = [],
      social_security_file: socialSecurityFile,
      students_social_security_file: studentsSocialSecurityFile,
      electronic_payroll: electronicPayroll = {},
    },
  } = periodData

  const {
    openPayslipModal,
    openBankFileModal,
    handleToggleLoadingModal,
    openAccountingFileModal,
    openPayrollSummaryFileModal,
    openSeveranceFileModal,
  } = periodAPIData

  const notAportesEnLinea = distributor !== 'aportes_en_linea'
  const severance = getSeverance(socialBenefits)

  const fixedButtonStyle = {
    justifyContent: 'flex-start',
    padding: 0,
    height: 'auto',
    textAlign: 'start',
  }

  const handleClickBankFile = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    openBankFileModal()
  }

  const handleClickPayrollSummaryFile = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    openPayrollSummaryFileModal()
  }

  const handleClickGeneratePayslips = async ({
    file_format: fileFormat,
    sendPaymentStubs,
    whatsappNotification = false,
  }) => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    handleToggleLoadingModal(true)
    await openPayslipModal({
      file_format: fileFormat,
      sendPaymentStubs,
      whatsappNotification,
    })
    handleToggleLoadingModal(false)
  }

  const handleAccoutingFileModal = () => {
    if (!showPremiumFeatures && distributor !== 'qenta') {
      openPremiumFeatureModal()
      return
    }
    openAccountingFileModal()
  }

  const handleClickSeveranceFile = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    openSeveranceFileModal()
  }

  const handleClickSSAffiliation = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    navigate(routes.WORKER_INDEX('?tab=new'))
  }

  const hasDecree376Options =
    decree376Periods &&
    decree376Periods.length > 0 &&
    decree376Periods.some((decreePeriod) => decreePeriod.social_security_file)

  const socialBenefitsToDownload =
    !periodEditable &&
    socialBenefits?.findIndex((sb) => sb.action === 'update') !== -1

  const sendElectronicPayroll =
    window.Cypress ||
    (electronicPayroll?.authorized &&
      electronicPayroll?.transmission_authorized &&
      electronicPayroll?.transmission_in_range)

  const hideDisabilitiesClaimsOption =
    !flagsReady || disabilityClaimsV2?.value !== 'on' || !claimableNovelties

  const actions = [
    {
      id: 'reports',
      label: 'Reportes',
      color: themeInstance.palette.accent3.light,
      icon: 'document',
      className: 'reports',
      options: [
        {
          Component: endFreeFuntionalitiesModals.isNotValidCompany ? (
            <Button
              variant="text"
              startIcon={<Icon name="eye" basic />}
              onClick={() =>
                endFreeFuntionalitiesModals.openEndFreePayrollModal()
              }
              sx={fixedButtonStyle}
            >
              Ver resumen de nómina
            </Button>
          ) : (
            <Button
              variant="text"
              startIcon={<Icon name="eye" basic />}
              component={Link}
              to={{
                pathname: routes.PERIOD_PREVIOUS_GENERATED_PAYROLL(periodId),
              }}
              sx={fixedButtonStyle}
            >
              Ver resumen de nómina
            </Button>
          ),
        },
        {
          hidden: !noveltiesReport,
          Component: endFreeFuntionalitiesModals.isNotValidCompany ? (
            <Button
              variant="text"
              startIcon={<Icon name="edit-document" basic />}
              onClick={() =>
                endFreeFuntionalitiesModals.openEndFreePayrollModal()
              }
              sx={fixedButtonStyle}
            >
              Reporte de novedades
            </Button>
          ) : (
            <Button
              variant="text"
              startIcon={<Icon name="edit-document" basic />}
              component={Link}
              to={{
                pathname: payPreviousPayroll
                  ? routes.PERIOD_PREVIOUS_PAYROLL_NOVELTIES_HISTORY(periodId)
                  : routes.PERIOD_NOVELTIES_HISTORY(periodId),
              }}
              state={{ payPreviousPayroll }}
              sx={fixedButtonStyle}
            >
              Reporte de novedades
            </Button>
          ),
        },
        {
          Component: (
            <Button
              variant="text"
              startIcon={<Icon name="download" basic />}
              sx={fixedButtonStyle}
              onClick={handleClickPayrollSummaryFile}
            >
              <ActionContent label="Descargar resumen nómina" />
            </Button>
          ),
        },
      ],
    },
    {
      id: 'payments',
      label: 'Pagos',
      color: themeInstance.palette.accent2.main,
      icon: 'museum',
      className: 'payments',
      options: [
        {
          hidden: !bankFile,
          Component: (
            <Button
              variant="text"
              startIcon={<Icon name="document" basic />}
              onClick={handleClickBankFile}
              sx={fixedButtonStyle}
            >
              <ActionContent label="Archivo pago en bancos" />
            </Button>
          ),
        },
        {
          hidden: !(severance.hasSeverance && notAportesEnLinea),
          Component: (
            <Button
              variant="text"
              startIcon={<Icon name="document" basic />}
              onClick={handleClickSeveranceFile}
              sx={fixedButtonStyle}
            >
              <ActionContent label="Archivo pago de cesantías" />
            </Button>
          ),
        },
        {
          hidden: !socialBenefitsToDownload,
          Component: <SocialBenefitsMenu />,
        },
      ],
    },
    {
      id: 'payslips',
      label: 'Colillas de pago',
      color: themeInstance.palette.complementary1.light,
      icon: 'send-mail',
      className: 'payslips',
      options: [
        {
          hidden: !payslipsEmails || !showPremiumFeatures,
          Component: (
            <Button
              sx={[
                fixedButtonStyle,
                {
                  ':hover': {
                    textDecoration: 'none',
                  },
                },
              ]}
              variant="text"
              startIcon={<Icon name="whatsapp" basic />}
              onClick={() =>
                handleClickGeneratePayslips({
                  sendPaymentStubs: true,
                  whatsappNotification: true,
                })
              }
            >
              <ActionContent label="Notifica vía WhatsApp" tag="Nuevo" />
            </Button>
          ),
        },
        {
          hidden: !payslipsEmails,
          Component: (
            <Button
              sx={fixedButtonStyle}
              variant="text"
              startIcon={<Icon name="mail-notification" basic />}
              onClick={() =>
                handleClickGeneratePayslips({ sendPaymentStubs: true })
              }
            >
              <ActionContent label="Notifica vía email" />
            </Button>
          ),
        },
        {
          hidden: !payslipsZip,
          Component: (
            <Button
              sx={fixedButtonStyle}
              variant="text"
              startIcon={<Icon name="send-backward" basic />}
              onClick={() =>
                handleClickGeneratePayslips({ file_format: 'pdf' })
              }
            >
              <ActionContent label="Descargar agrupadas" />
            </Button>
          ),
        },
        {
          hidden: !payslipsPdf,
          Component: (
            <Button
              sx={fixedButtonStyle}
              variant="text"
              startIcon={<Icon name="rounded" basic />}
              onClick={() =>
                handleClickGeneratePayslips({ file_format: 'zip' })
              }
            >
              <ActionContent label="Descargar individuales" />
            </Button>
          ),
        },
      ],
    },
    {
      id: 'social-security',
      label: 'Seguridad social',
      color: themeInstance.palette.primary.light,
      icon: 'stock-share',
      className: 'payments',
      options: [
        {
          hidden: !socialSecurityFile,
          Component: (
            <PaySocialSecurityMenu
              fixedButtonStyle={fixedButtonStyle}
              label={
                !isArusDist()
                  ? 'Pagar a un clic con Aleluya'
                  : 'Pagar a un clic con SUAPORTE'
              }
            />
          ),
        },
        {
          hidden: !(
            socialSecurityFile ||
            studentsSocialSecurityFile ||
            hasDecree376Options
          ),
          Component: (
            <SocialSecurityFileMenu fixedButtonStyle={fixedButtonStyle} />
          ),
        },
        {
          Component: (
            <Button
              variant="text"
              underline="none"
              startIcon={<Icon name="edit-document" basic />}
              onClick={handleClickSSAffiliation}
              sx={fixedButtonStyle}
            >
              <ActionContent label="Afilia las personas a SS" />
            </Button>
          ),
        },
      ],
    },
    {
      id: 'accounting',
      label: 'Contabilidad',
      color: themeInstance.palette.complementary2.light,
      icon: 'consultation',
      className: 'accounting',
      options: [
        {
          Component: (
            <Button
              component={Link}
              variant="text"
              underline="none"
              startIcon={<Icon name="mouse" basic />}
              onClick={handleAccoutingFileModal}
              sx={fixedButtonStyle}
            >
              <ActionContent label="Genera tu interfaz contable" />
            </Button>
          ),
        },
      ],
    },
    {
      id: 'social_benefits',
      label: 'Prestaciones sociales',
      color: themeInstance.palette.accent4.light,
      icon: 'investment',
      className: 'social_benefits',
      options: socialBenefits.map(
        ({
          label,
          category,
          social_benefits_period_id: socialBenefitsPeriodId,
        }) => ({
          hidden: !payPreviousPayroll,
          Component: endFreeFuntionalitiesModals.isNotValidCompany ? (
            <Button
              variant="text"
              startIcon={icons[category]}
              state={{ payPreviousPayroll }}
              onClick={() =>
                endFreeFuntionalitiesModals.openEndFreeSocialBenefitsModal()
              }
              sx={fixedButtonStyle}
            >
              {label}
            </Button>
          ) : (
            <Button
              variant="text"
              startIcon={icons[category]}
              component={Link}
              state={{ payPreviousPayroll }}
              to={
                payPreviousPayroll
                  ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS(
                      periodId,
                      socialBenefitsPeriodId
                    )
                  : routes.PERIOD_SOCIAL_BENEFIT_PERIODS(
                      periodId,
                      socialBenefitsPeriodId
                    )
              }
              sx={fixedButtonStyle}
            >
              {label}
            </Button>
          ),
        })
      ),
    },
    {
      id: 'others',
      label: 'Otros',
      color: themeInstance.palette.info.light,
      icon: 'computer',
      className: 'others',
      options: [
        {
          hidden:
            !payPreviousPayroll ||
            (decree376Periods && !(decree376Periods.length > 0)),
          Component: <Decree376Menu fixedButtonStyle={fixedButtonStyle} />,
        },
      ],
    },
    {
      id: 'electronic-payroll',
      label: 'Nómina electrónica',
      color: themeInstance.palette.primary.main,
      icon: 'computer',
      className: 'electronic-payroll',
      options: [
        {
          hidden: electronicPayroll.authorized || payPreviousPayroll,
          Component: (
            <Button
              variant="text"
              startIcon={<Icon name="document-signature" basic />}
              component={Link}
              to={{ pathname: routes.SETTINGS_ELECTRONIC_PAYROLL() }}
              sx={fixedButtonStyle}
            >
              Habilitar Nómina Electrónica
            </Button>
          ),
        },
        {
          hidden: !sendElectronicPayroll || payPreviousPayroll,
          Component: (
            <Tooltip
              disableHoverListener={!maintenance}
              title="DIAN en mantenimiento"
              description="En este momento no puedes emitir NE. La DIAN se encuentra en mantenimiento."
            >
              <span>
                <Button
                  startIcon={
                    <Icon
                      name="invoice-receipt"
                      basic
                      color={
                        !maintenance
                          ? themeInstance.palette.black.main
                          : themeInstance.palette.black.light
                      }
                    />
                  }
                  disabled={maintenance}
                  component={Link}
                  variant="text"
                  to={routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPeriodId)}
                  sx={fixedButtonStyle}
                >
                  Emitir Nómina Electrónica
                </Button>
              </span>
            </Tooltip>
          ),
        },
        {
          hidden: !electronicPayroll.authorized || payPreviousPayroll,
          Component: endFreeFuntionalitiesModals.isNotValidCompany ? (
            <Button
              variant="text"
              startIcon={<Icon name="document" basic />}
              onClick={() =>
                endFreeFuntionalitiesModals.openEndFreeElectronicPayrollModal()
              }
              sx={fixedButtonStyle}
            >
              Resumen Nómina Electrónica
            </Button>
          ) : (
            <Button
              variant="text"
              startIcon={<Icon name="document" basic />}
              component={Link}
              to={routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPeriodId)}
              sx={fixedButtonStyle}
            >
              Resumen Nómina Electrónica
            </Button>
          ),
        },
      ],
    },
    {
      id: 'disabilities_claims',
      label: 'Reclamaciones',
      color: themeInstance.palette.accent2.light,
      icon: 'refund',
      className: 'disabilities_claims',
      options: [
        {
          hidden: hideDisabilitiesClaimsOption,
          Component: (
            <Button
              component={MUILink}
              variant="text"
              startIcon={<Icon name="refund" basic />}
              sx={fixedButtonStyle}
              target="_blank"
              href={CLAIMS_LINK}
            >
              <ActionContent label="Iniciar una nueva reclamación" />
            </Button>
          ),
        },
      ],
    },
  ]

  return actions
}

export default getActions
