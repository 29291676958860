import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'
import useWorker from 'components/Worker/useWorker'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWageService from 'utils/hooks/worker/wageService'
import { filterContractFields, getMinimumSalary } from 'utils/worker'

import BaseSalaryField from '../Fields/BaseSalary'
import CategoryField from '../Fields/Category'
import EndDayField from '../Fields/EndDay'
import InitialDayField from '../Fields/InitialDay'
import TransportSubsidyField from '../Fields/TransportSubsidy'
import validationSchema from './validationSchema'

const getWageValue = (defaultValue, fetchedWage = {}) => {
  if (!isObjectEmpty(fetchedWage)) return fetchedWage

  if (!isObjectEmpty(defaultValue)) return defaultValue

  return {}
}

const getInitialValues = (wage, fetchedWage = {}, contract, isEdit) => {
  const initialValues = {
    ...wage,
    ...(isEdit ? fetchedWage : {}),
    contract_category: contract?.category,
  }

  // set the base_salary for the new wage with the current wage base_salary
  if (initialValues.base_salary === undefined && wage && wage.base_salary) {
    initialValues.base_salary = wage.base_salary
    if (initialValues.base_salary < getMinimumSalary({ multiplier: 2 }))
      initialValues.transport_subsidy = true
  }

  // set true transport_subsidy by default for new salaries
  if (!isEdit) initialValues.transport_subsidy = true

  return initialValues
}

const WageModal = ({
  contract, // contract that the wage to edit belongs to
  wage = {},
  isEdit = false,
  onCancel,
  submitCallback,
}) => {
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { worker } = useWorker({ useCache: true })
  const wageQueryKey = ['currentWage', worker.id, contract?.id]
  const { wageQuery, wageMutation } = useWageService({
    serviceParams: {
      queryKey: wageQueryKey,
      contractId: contract?.id,
    },
    queryOptions: {
      enabled: isObjectEmpty(wage) && isEdit,
    },
  })
  const fetchedWage = wageQuery.data || {}

  const onSuccessActions = async (message) => {
    await queryClient.invalidateQueries(wageQueryKey)
    showSuccessMessage(message)
    onCancel() // to close modal
    if (submitCallback) await submitCallback()
  }

  const catchError = (error, form) => {
    handleError(error, form, {
      errorsToNotificate: [
        { code: '0305' },
        { object: 'end_day', code: '0101' },
      ],
    })
  }

  const handleSubmit = (values, form) => {
    const dirtyValues = getDirtyValues(wage, values, validationSchema.fields)

    if (!isObjectEmpty(dirtyValues)) {
      dirtyValues.id = values.id

      const filteredDirtyValues = filterContractFields(
        dirtyValues,
        contract.category,
        values.category,
        worker?.contract_detail?.contributor_subtype
      )

      if (filteredDirtyValues.id && isEdit) {
        wageMutation.mutate(
          {
            mutationMethod: 'PATCH',
            wage: filteredDirtyValues,
          },
          {
            onSuccess: async () => {
              await onSuccessActions('La persona fue actualizada exitosamente.')
            },
          },
          {
            onError: (error) => {
              catchError(error, form)
            },
          }
        )
      } else {
        wageMutation.mutate(
          {
            mutationMethod: 'POST',
            contractId: contract.id,
            wage: filteredDirtyValues,
          },
          {
            onSuccess: async () => {
              await onSuccessActions('Se ha creado el salario exitosamente')
            },
          },
          {
            onError: (error) => {
              catchError(error, form)
            },
          }
        )
      }
    }
  }

  return (
    <Modal
      header={isEdit ? 'Editar salario actual' : 'Crear salario'}
      open
      hideFooter
      onCancel={onCancel}
      okText="Guardar"
      isLoading={wageMutation.isLoading}
      paperSx={{
        width: '100%',
        maxWidth: '40rem',
      }}
    >
      {!isEdit ? (
        <Typography
          sx={(theme) => ({
            marginBottom: theme.spacing(3),
          })}
        >
          Podrás crear un nuevo salario para la persona que comienza a aplicar
          desde la fecha inicial, con el valor que registres. Una vez guardes
          este nuevo salario, Aleluya actualiza las nóminas en las que aplique
          según las fechas seleccionadas.
        </Typography>
      ) : null}
      {isEdit && wageQuery.isLoading ? (
        <LoadingBox />
      ) : (
        <Formik
          initialValues={getInitialValues(wage, fetchedWage, contract, isEdit)}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit: onSubmitForm }) => {
            return (
              <Form>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                  })}
                >
                  <BaseSalaryField
                    contractCategory={contract?.category}
                    isEditing={isEdit}
                    optional={false}
                  />
                  <CategoryField
                    contractCategory={contract?.category}
                    label="Salario base"
                    optional={false}
                  />
                  <InitialDayField
                    currentWage={isEdit ? getWageValue(wage, fetchedWage) : {}}
                    contractInitialDay={contract?.initial_day}
                    optional={false}
                  />
                  <EndDayField />
                  <TransportSubsidyField
                    contractCategory={contract?.category}
                  />
                </Box>
                <Box
                  sx={(theme) => ({
                    marginTop: theme.spacing(8),
                    display: 'flex',
                    gap: theme.spacing(2),
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    [theme.breakpoints.up('tablet')]: {
                      justifyContent: 'flex-start',
                    },
                  })}
                >
                  <Button
                    onClick={onSubmitForm}
                    loading={wageMutation.isLoading}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={onCancel}
                    disabled={wageMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}

export default WageModal

/**

 */
