import { useMutation, useQuery } from 'react-query'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import { getQueryKey } from 'utils/reactQuery'

import {
  createPayment,
  getPartnerInformation,
} from 'services/organizer/partner/paymentService'

const getQueryFunction = (serviceParams) => {
  const { queryKey, partnerId } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getPartnerInformation')
    return getPartnerInformation(partnerId)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, partnerId, company } = mutationData

  if (mutationMethod === 'PUT') return createPayment(partnerId, company)

  return null
}

const usePartnerPayment = ({
  serviceParams = { queryKey: 'getPartnerInformation' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const {
    data: { data, partner_info: partnerInfo } = {},
    ...restPartnerPaymentResponse
  } = useQuery(serviceParams.queryKey, () => getQueryFunction(serviceParams), {
    onError: (error) => handleError(error),
    ...queryOptions,
  })

  const partnerPaymentMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    partnerPaymentQuery: {
      data,
      partnerInformation: partnerInfo,
      ...restPartnerPaymentResponse,
    },
    partnerPaymentMutation,
  }
}

export default usePartnerPayment
