import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import AccountNumberField from 'components/UI/Formik/CommonFields/AccountNumberField'
import AccountTypeField from 'components/UI/Formik/CommonFields/AccountTypeField'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import FormField from 'components/UI/Formik/FormField/Index'

import { paymentMethodsOptions } from 'utils/company'

import tooltipInfo from 'messages/worker'

const FormFieldsPayment = () => {
  const { values } = useFormikContext()

  return (
    <>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        })}
      >
        Datos de pago
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2),
          columnGap: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <FormField
          name="payment_method"
          label="Método de pago"
          variant="select"
          options={paymentMethodsOptions}
          optional={false}
        />

        {values.payment_method === 'wire_transfer' ? (
          <>
            <BankField optional={false} />
            <AccountTypeField optional={false} />
            <AccountNumberField
              tooltipContent={tooltipInfo.account_number}
              optional={false}
            />
          </>
        ) : null}
      </Box>
    </>
  )
}

export default FormFieldsPayment
