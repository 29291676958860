import { Link as RouterLink } from 'react-router-dom'

import { Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getNameWorkerType } from 'utils/worker'

import * as routes from 'config/routes'

import columnsData from './columnsData'

const WorkerFileFormDone = ({ workers }) => {
  const { refreshCompany } = useUser()

  const hasWorkers = workers.length >= 1
  const peopleTitle = getNameWorkerType('person', hasWorkers)

  const handleRedirect = () => {
    refreshCompany()
  }

  return (
    <Page
      header={`¡Felicitaciones! Has subido ${workers.length} ${peopleTitle} de forma exitosa`}
      contentSx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom>
        Si quieres editar algo, puedes hacerlo desde la lista de personas.
      </Typography>

      <Table
        columns={columnsData}
        data={workers}
        options={{ toolbar: false, pagination: false }}
      />

      <Button
        component={RouterLink}
        to={routes.WORKER_INDEX('?tab=active')}
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          width: 'fit-content',
          alignSelf: 'center',
        })}
        onClick={handleRedirect}
      >
        Ver Lista Personas
      </Button>
    </Page>
  )
}

export default WorkerFileFormDone
