/* eslint-disable */
export const googleOptimizeScript = {
  src: 'https://www.googletagmanager.com/gtag/js?id=UA-69162258-1',
  child: () => googleOptimizeSriptCode(),
  header: true,
  async: true,
}

const googleOptimizeSriptCode = () => {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())

  gtag('config', 'UA-69162258-1', { optimize_id: 'GTM-P3GW5P8' })
}
