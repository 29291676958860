import { Box } from '@mui/material'

import messages from 'messages/payroll'

import AddButtonWrapper from '../common/AddButtonWrapper'
import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import GroupDivider from '../common/GroupDivider'
import DateRangeContent from './DateRangeContent'

const Incapacities = ({
  payroll,
  incapacities,
  onAddNovelty,
  onChangeNovelty,
  onDeleteNovelty,
  selectedDays,
  contract_category: contractCategory,
}) => {
  const { items: generalIncapacityItems } = incapacities.general_incapacity
  const { items: laborIncapacityItems } = incapacities.labor_incapacity

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('tablet')]: {
          gap: theme.spacing(2),
        },
      })}
    >
      {!['student_decree_055', 'part_time_contract'].includes(
        contractCategory
      ) ? (
        <GridRow>
          <HeaderCell
            sx={{
              gridColumn: '1',
            }}
            mobileOnly
          >
            Concepto
          </HeaderCell>
          <AddButtonWrapper
            showButton={
              generalIncapacityItems.length >= 1 &&
              generalIncapacityItems[generalIncapacityItems.length - 1]
                .initial_day &&
              generalIncapacityItems[generalIncapacityItems.length - 1].end_day
            }
            noveltyType="incapacities"
            noveltyCode="general_incapacity"
            onAddNovelty={onAddNovelty}
          >
            <DateRangeContent
              title="Incapacidad por enfermedad general"
              data={incapacities.general_incapacity}
              onAddNovelty={onAddNovelty}
              onChangeNovelty={onChangeNovelty}
              onDeleteNovelty={onDeleteNovelty}
              noveltyType="incapacities"
              noveltyCode="general_incapacity"
              contentTooltip={messages.general_incapacity}
              selectedDays={selectedDays}
              payroll={payroll}
            />
          </AddButtonWrapper>
        </GridRow>
      ) : null}
      {contractCategory !== 'schooling_trainee' &&
      contractCategory !== 'student_decree_055' ? (
        <GroupDivider />
      ) : null}
      {contractCategory !== 'schooling_trainee' ? (
        <GridRow>
          <HeaderCell
            sx={{
              gridColumn: '1',
            }}
            mobileOnly
          >
            Concepto
          </HeaderCell>
          <AddButtonWrapper
            showButton={
              laborIncapacityItems.length >= 1 &&
              laborIncapacityItems[laborIncapacityItems.length - 1]
                .initial_day &&
              laborIncapacityItems[laborIncapacityItems.length - 1].end_day
            }
            noveltyType="incapacities"
            noveltyCode="labor_incapacity"
            onAddNovelty={onAddNovelty}
          >
            <DateRangeContent
              title="Incapacidad por enfermedad profesional o accidente de trabajo"
              data={incapacities.labor_incapacity}
              onAddNovelty={onAddNovelty}
              onChangeNovelty={onChangeNovelty}
              onDeleteNovelty={onDeleteNovelty}
              noveltyType="incapacities"
              noveltyCode="labor_incapacity"
              selectedDays={selectedDays}
              payroll={payroll}
            />
          </AddButtonWrapper>
        </GridRow>
      ) : null}
    </Box>
  )
}

export default Incapacities
