import { useQueryClient } from 'react-query'

import { getCompanyId } from 'utils/company'
import { useCompanyService } from 'utils/hooks/company/companyService'

const useOnboardingMutation = () => {
  const queryClient = useQueryClient()

  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })

  const handleUpdateOnboarding = ({ data, callback }) => {
    companyMutation.mutate(
      {
        mutationMethod: 'PATCH',
        company: data,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'companyInformation',
            getCompanyId(),
          ])

          if (callback) {
            await callback()
          }
        },
      }
    )
  }

  return {
    handleUpdateOnboarding,
    isLoading: companyMutation.isLoading,
  }
}

export default useOnboardingMutation
