import { Box, Paper, Typography } from '@mui/material'

import { getCardsContent } from './helpers'

export default function GeneralDiagnosis({ fetchedData = {} }) {
  const { financial_calculator_data, coaching_sessions_data } = fetchedData

  const cardsContent = getCardsContent(
    financial_calculator_data?.average_financial_calculator_score,
    coaching_sessions_data?.coaching_sessions_count
  )

  return (
    <Paper
      sx={(theme) => ({
        display: ' grid',
        gridTemplateColumns: {
          mobile: '1fr',
          tablet: 'repeat(2, 1fr)',
          desktop: 'repeat(3, 1fr)',
        },
        gridTemplateRows: {
          mobile: 'repeat(3, 1fr)',
          tablet: 'repeat(2, 1fr)',
          desktop: '1fr',
        },
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(4),
        width: '100%',
        minHeight: '23.375rem',
        padding: theme.spacing(4, 4, 6),
        backgroundColor: 'white.main',
        borderRadius: '0.5rem',
        boxShadow: theme.shadows[6],
      })}
    >
      {cardsContent.map(({ id, title, mainContent }, index) => {
        const addSpanColumnClass = index === cardsContent.length - 1

        return (
          <Box
            key={id}
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.accent1.light,
              borderRadius: '0.6563rem',
              padding: theme.spacing(2),
              ...(addSpanColumnClass && {
                [theme.breakpoints.between('tablet', 'laptop')]: {
                  gridColumn: 'span 2',
                  justifySelf: 'center',
                },
              }),
            })}
          >
            <Typography variant="h5">{title}</Typography>

            {mainContent}
          </Box>
        )
      })}
    </Paper>
  )
}
