import { getToken } from 'utils/auth'

import http, {
  downloadFileWebSocket,
  getApiURL,
  handleFetchResponse,
} from '../httpService'

export const getAllErrors = async (periodId, fileFormat, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/validation_report`,
    searchParams: { file_format: fileFormat, form_type: formType },
  })

  const response = await http.get(url)

  return response
}

export const getSSFormErrors = (periodId, fileFormat, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/validation_report`,
    searchParams: { file_format: fileFormat, form_type: formType },
  })

  return http.getV2(url)
}

export const getFormInfo = async (periodId, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/form`,
    searchParams: { form_type: formType },
  })

  const response = await http.get(url)

  return response
}

export const getSSForm = (periodId, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/form`,
    searchParams: { form_type: formType },
  })

  return http.getV2(url)
}

export const deleteForm = async (periodId, formKey, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/form`,
    searchParams: { form_key: formKey, form_type: formType },
  })

  const response = await http.delete(url)

  return response
}

export const deleteSSForm = (periodId, formKey, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/form`,
    searchParams: { form_key: formKey, form_type: formType },
  })

  return http.deleteV2(url)
}

export const getGenerateConfirmation = async (
  periodId,
  formType,
  provider,
  async = true
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/payment_file`,
    searchParams: { form_type: formType, async, provider },
  })

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

  await handleFetchResponse(response)

  const downloadFile = await downloadFileWebSocket(
    `/periods/${periodId}/social_security_integration/payment_file`
  )

  return async ? response : downloadFile
}

export const getValidateConfirmation = async (periodId, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/payment_file`,
    searchParams: { form_type: formType },
  })

  let res
  let isSuccessful = false

  while (!isSuccessful) {
    // eslint-disable-next-line no-await-in-loop
    res = await http.get(url)
    isSuccessful = res.file_validated
  }

  return res
}

export const getAllForms = ({ page, provider }) => {
  const url = getApiURL({
    pathname: `/social_security_integration/forms`,
    searchParams: { page, provider },
  })

  return http.getV2(url)
}

export const checkPaymentStatus = async (periodId, formKey) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/payment`,
    searchParams: { form_key: formKey },
  })

  const { data } = await http.get(url)

  return data
}

export const payForm = async (periodId, formKey) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/payment`,
    searchParams: { form_key: formKey },
  })

  const { data } = await http.post(url)

  return data
}

export const paySSForm = (periodId, formKey) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_integration/payment`,
    searchParams: { form_key: formKey },
  })

  return http.postV2(url)
}

export default {
  getAllErrors,
  getFormInfo,
  getGenerateConfirmation,
  getValidateConfirmation,
  getAllForms,
  deleteForm,
  checkPaymentStatus,
  payForm,
  deleteSSForm,
  paySSForm,
  getSSForm,
  getSSFormErrors,
}
