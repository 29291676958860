import { isAfter, isBefore, isValid, parseISO } from 'date-fns'
import { useField, useFormikContext } from 'formik'

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Tooltip,
  Typography,
} from '@mui/material'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import AutocompleteField from './AutocompleteField'
import CheckboxField from './CheckboxField'
import CheckboxGroupField from './CheckboxGroupField'
import CurrencyField from './CurrencyField'
import CustomFormatField from './CustomFormatField'
import DatePickerField from './DatePickerField'
import DateRangePickerField from './DateRangePickerField'
import FileField from './FileField'
import NumberField from './NumberField'
import PictureField from './PictureField'
import RadioGroupField from './RadioGroupField'
import SelectField from './SelectField'
import SliderField from './SliderField'
import SwitchField from './SwitchField'
import TextField from './TextField'
import TextareaField from './TextareaField'

const getCustomShowError = ({
  variant,
  showError,
  fieldValue,
  minDate,
  maxDate,
}) => {
  if (variant === 'datepicker') {
    let isInvalid = showError && !isValid(parseISO(fieldValue))

    if (minDate && isBefore(parseISO(fieldValue), parseISO(minDate))) {
      isInvalid = true
    }

    if (maxDate && isAfter(parseISO(fieldValue), parseISO(maxDate))) {
      isInvalid = true
    }

    return isInvalid
  }

  if (variant === 'daterangepicker') {
    return (
      showError &&
      (!isValid(parseISO(fieldValue?.start)) ||
        !isValid(parseISO(fieldValue?.end)))
    )
  }

  return false
}

const getFormControlError = ({
  variant,
  datePickerError,
  dateRangePickerError,
  showError,
}) => {
  if (variant === 'datepicker') return datePickerError

  if (variant === 'daterangepicker') return dateRangePickerError

  return showError
}

const FormField = ({
  // Props for FormControl
  disabled,
  margin,
  fullWidth = true,
  optional = true,

  // Content for FormControl
  label,
  tooltipTextContent,
  tooltipContent,
  additionalLabelComponent,

  // Props for field variant
  variant = 'textfield',
  name, // This prop is mandatory for any variant of the FormField, because it must be related to a value defined in the Formik context
  type,
  value,
  multiple,
  validate,
  options,
  row,
  justifiSpaceBetween, // This prop is only for the variant 'checkbox-group'

  // Aditional props
  render,
  formControlSx,
  ...props
}) => {
  const form = useFormikContext()
  const [field, meta, helpers] = useField({
    name,
    validate,
    type,
    multiple,
    value,
  })

  const { value: fieldValue, onBlur, onChange } = field
  const { touched, error } = meta
  const { setValue, setTouched } = helpers
  const { submitCount } = form

  const id = `${name}_id`
  const showError = (touched || submitCount > 0) && !!error

  const datePickerError = getCustomShowError({
    variant,
    showError,
    fieldValue,
    minDate: props.minDate,
    maxDate: props.maxDate,
  })
  const dateRangePickerError = getCustomShowError({
    variant,
    showError,
    fieldValue,
  })

  const renderFieldVariant = () => {
    if (render) return render(field, meta, helpers, form)

    if (variant === 'currency') {
      return (
        <CurrencyField
          id={id}
          name={name}
          value={fieldValue}
          setValue={setValue}
          touched={touched}
          onBlur={onBlur}
          {...props}
        />
      )
    }

    if (variant === 'checkbox') {
      return <CheckboxField id={id} field={field} {...props} />
    }

    if (variant === 'slider') {
      return (
        <SliderField
          id={id}
          name={name}
          value={fieldValue}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'checkbox-group') {
      return (
        <CheckboxGroupField
          row={row}
          options={options}
          value={fieldValue}
          setValue={setValue}
          justifiSpaceBetween={justifiSpaceBetween}
        />
      )
    }

    if (variant === 'number') {
      return (
        <NumberField
          id={id}
          name={name}
          value={fieldValue}
          onBlur={onBlur}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'custom-format') {
      return (
        <CustomFormatField
          id={id}
          name={name}
          onBlur={onBlur}
          value={fieldValue}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'select') {
      return (
        <SelectField
          id={id}
          field={field}
          name={name}
          value={fieldValue}
          options={options}
          {...props}
        />
      )
    }

    if (variant === 'radio-group') {
      return (
        <RadioGroupField
          id={id}
          name={name}
          value={fieldValue}
          options={options}
          setValue={setValue}
          setTouched={setTouched}
          row={row}
          {...props}
        />
      )
    }

    if (variant === 'autocomplete') {
      return (
        <AutocompleteField
          id={id}
          name={name}
          value={fieldValue}
          options={options}
          setValue={setValue}
          setTouched={setTouched}
          disabled={disabled}
          {...props}
        />
      )
    }

    if (variant === 'switch') {
      return (
        <SwitchField
          id={id}
          name={name}
          value={fieldValue}
          setValue={setValue}
          {...props}
        />
      )
    }

    if (variant === 'textarea') {
      return (
        <TextareaField
          id={id}
          name={name}
          value={fieldValue}
          onBlur={onBlur}
          onChange={onChange}
          {...props}
        />
      )
    }

    if (variant === 'datepicker') {
      return (
        <DatePickerField
          id={id}
          name={name}
          value={fieldValue}
          disabled={disabled}
          touched={touched}
          setValue={setValue}
          showError={datePickerError}
          setTouched={setTouched}
          {...props}
        />
      )
    }

    if (variant === 'daterangepicker') {
      return (
        <DateRangePickerField
          name={name}
          value={fieldValue}
          disabled={disabled}
          setValue={setValue}
          showError={dateRangePickerError}
          setTouched={setTouched}
          touched={touched}
          {...props}
        />
      )
    }

    if (variant === 'file') {
      return (
        <FileField
          id={id}
          name={name}
          value={fieldValue}
          disabled={disabled}
          setValue={setValue}
          hideRemoveButton={false}
          setTouched={setTouched}
          {...props}
        />
      )
    }

    if (variant === 'picture') {
      return (
        <PictureField
          id={id}
          name={name}
          value={fieldValue}
          disabled={disabled}
          setValue={setValue}
          hideRemoveButton={false}
          {...props}
        />
      )
    }

    return (
      <TextField
        id={id}
        field={field}
        value={fieldValue}
        type={type}
        {...props}
      />
    )
  }

  return (
    <FormControl
      error={getFormControlError({
        variant,
        datePickerError,
        dateRangePickerError,
        showError,
      })}
      disabled={disabled}
      margin={margin}
      sx={[
        (theme) => ({
          ...(variant === 'switch' && {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
          }),
        }),
        formControlSx,
      ]}
      fullWidth={fullWidth}
      required={!optional}
    >
      {label ? (
        <Box sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
          <FormLabel
            htmlFor={id}
            sx={{
              ...(variant === 'switch'
                ? {
                    alignSelf: 'center',
                    marginBottom: 0,
                  }
                : {
                    alignSelf: 'flex-end',
                  }),
            }}
          >
            {label}
            {tooltipTextContent ? (
              <Tooltip title={tooltipTextContent} placement="right">
                <Typography
                  variant="small"
                  sx={(theme) => ({
                    alignSelf: 'flex-end',
                    marginLeft: theme.spacing(0.5),
                    color: theme.palette.primary.main,
                    cursor: 'help',
                  })}
                >
                  ¿Qué es esto?
                </Typography>
              </Tooltip>
            ) : null}
          </FormLabel>
          {tooltipContent ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                marginLeft: theme.spacing(0.5),
                marginBottom: theme.spacing(0.5),
              })}
            >
              <TooltipInfoIcon
                title={tooltipContent}
                tooltipProps={{
                  placement: 'right',
                  disableInteractive: typeof tooltipContent === 'string',
                }}
              />
            </Box>
          ) : null}
          {additionalLabelComponent}
        </Box>
      ) : null}
      {renderFieldVariant()}
      {showError && !['datepicker', 'daterangepicker'].includes(variant) ? (
        <FormHelperText data-cy={`custom_field_error_${name}`}>
          {error}
        </FormHelperText>
      ) : null}
      {dateRangePickerError && variant === 'daterangepicker' ? (
        <FormHelperText data-cy={`custom_field_error_${name}`}>
          {error?.start || error?.end}
        </FormHelperText>
      ) : null}
      {datePickerError && variant === 'datepicker' ? (
        <FormHelperText data-cy={`custom_field_error_${name}`}>
          {error}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default FormField
