import {
  Box,
  Tooltip as MTooltip,
  Typography,
  tooltipClasses,
} from '@mui/material'

const Tooltip = ({
  children,
  title,
  description,
  placement = 'bottom-end',
  tooltipSx,
  ...props
}) => {
  return (
    <MTooltip
      title={
        description ? (
          <Box>
            {title ? (
              <Box
                sx={(theme) => ({
                  marginBottom: theme.spacing(1),
                })}
              >
                <Typography variant="h5" component="span" color="black.main">
                  {title}
                </Typography>
              </Box>
            ) : null}
            {description ? (
              <Box>
                <Typography component="span" variant="body1" color="black.main">
                  {description}
                </Typography>
              </Box>
            ) : null}
          </Box>
        ) : (
          ''
        )
      }
      arrow
      placement={placement}
      enterDelay={300}
      {...props}
      componentsProps={{
        tooltip: {
          onClick: (event) => event.stopPropagation(),
          sx: [
            (theme) => ({
              [`&.${tooltipClasses.tooltip}`]: {
                borderRadius: '0.75rem',
                padding: theme.spacing(3, 4),
                maxWidth: '22rem',
                backgroundColor: theme.palette.white.dark,
              },
            }),
            tooltipSx,
          ],
        },
        arrow: {
          sx: (theme) => ({
            [`&.${tooltipClasses.arrow}`]: {
              marginTop: '-0.64em !important',
              '&::before': {
                backgroundColor: theme.palette.white.dark,
              },
            },
          }),
        },
      }}
    >
      {children}
    </MTooltip>
  )
}

export default Tooltip
