import * as yup from 'yup'

export const stepsData = [
  {
    title: 'Paso 1',
    description: 'Cargar documentos',
    pageTitle: 'Cargar documento a firmar',
  },
  {
    title: 'Paso 2',
    description: 'Método de validación',
    pageTitle: 'Selecciona el método de validación',
  },
  {
    title: 'Paso 3',
    description: 'Quiénes firman',
    pageTitle: 'Quiénes firman este documento',
  },
]

export const initialValues = {
  document: false,
  require_selfie_photo: false,
  signers: [],
  date_limit_to_sign: null,
  with_attachments: false,
  with_date_limit: false,
}

export const validationSchema = {
  0: yup.object({
    document: yup
      .mixed()
      .test('document', null, (value) => {
        if (!(typeof value === 'string')) {
          return value instanceof File
        }
        return true
      })
      .required(),
  }),
  1: yup.object({
    require_selfie_photo: yup.boolean().nullable(),
  }),
  2: yup.object({
    with_date_limit: yup.boolean().nullable(),
    date_limit_to_sign: yup.string().nullable().when('with_date_limit', {
      is: true,
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable(),
    }),
  }),
}
