import { Card } from '@mui/material'

import ImageCard from './ImageCard'

const NoveltyCard = ({ id, name, icon, value, button, dataCy }) => {
  return (
    <Card
      data-cy={dataCy}
      className="novelty_card" // This is just to target the card from Lordicon's element and trigger the icon animation
      sx={(theme) => ({
        height: '11rem',
        minWidth: '12.5rem',
        borderRadius: theme.spacing(3),
        justifySelf: 'center',
        boxShadow: theme.shadows[4],
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1.5, 1.5, 0, 1.5),
        gap: theme.spacing(2),
        ...(!value && {
          '&:hover': {
            '& #iconContainer': {
              opacity: 1,
            },
          },
        }),
      })}
    >
      <ImageCard id={id} name={name} value={value} icon={icon} />
      {button}
    </Card>
  )
}

export default NoveltyCard
