import { createContext, useCallback, useState } from 'react'

import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material'

import generateTheme from 'styles/theme'

export const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(generateTheme)

  const resetTheme = useCallback(
    (distributor) => {
      let themeName

      switch (distributor) {
        case 'arus':
          themeName = 'arus'
          break
        default:
          themeName = 'nominapp'
          break
      }

      // change theme only if the new is different
      if (theme.name !== themeName) setTheme(generateTheme(themeName))
    },
    [theme.name]
  )

  return (
    <ThemeContext.Provider value={{ resetTheme }}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
