import { Button } from '@mui/material'

import Icon from 'components/UI/Icon'

const BtnAddNovelty = ({ onAddNovelty, noveltyType, noveltyCode }) => {
  let noveltyName

  switch (noveltyType) {
    case 'holidays':
      noveltyName = 'vacaciones'
      break
    case 'incapacities':
      noveltyName = 'incapacidad'
      break
    default:
      noveltyName = 'licencia'
  }

  return (
    <Button
      onClick={() => onAddNovelty(noveltyType, noveltyCode)}
      size="small"
      data-cy={`add-${noveltyCode}-item`}
      startIcon={<Icon name="plus" basic />}
      variant="text"
      sx={{
        width: 'max-content',
      }}
    >
      Agregar {noveltyName}
    </Button>
  )
}

export default BtnAddNovelty
