import http, { getApiURL } from '../httpService'

export const getRecurrentConcepts = (workerId) => {
  const url = getApiURL({ pathname: `/workers/${workerId}/recurrent_concepts` })

  return http.getV2(url)
}

export const updateRecurrentConcept = ({ workerId, recurrentConcepts }) => {
  const url = getApiURL({ pathname: `/workers/${workerId}/recurrent_concepts` })

  const data = { recurrent_concepts: recurrentConcepts }

  return http.putV2(url, data)
}

export default {
  getRecurrentConcepts,
  updateRecurrentConcept,
}
