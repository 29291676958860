import { Link } from 'react-router-dom'

import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'
import WorkerPicture from 'components/UI/WorkerPicture'

import { formatCurrency } from 'utils/format'

import { WORKER_SHOW } from 'config/routes'

const AffiliateInfoCard = ({ worker }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.complementary1.light,
        height: '8rem',
        borderRadius: '0.5rem',
        padding: theme.spacing(1, 2, 0, 2),
        boxShadow: theme.shadows[4],
        [theme.breakpoints.down('tablet')]: {
          padding: theme.spacing(1),
          height: 'fit-content',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          alignItems: 'center',
          gap: theme.spacing(1),
          zIndex: 2,
          gridTemplateColumns: '1fr 3fr',
          [theme.breakpoints.down('tablet')]: {
            gridTemplateColumns: '1fr 4fr',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'flex-end',
          }}
        >
          <WorkerPicture
            avatarId={1}
            picture={worker.picture}
            avatarsSx={(theme) => ({
              fontSize: '3.8rem',
              marginBottom: theme.spacing(-0.75),
            })}
            pictureSx={{
              width: '3.5rem',
              height: '3.5rem',
            }}
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
            [theme.breakpoints.down('laptop')]: {
              gap: theme.spacing(1),
              width: '90%',
            },
          })}
        >
          <Typography
            variant={isMobile ? 'lead2' : 'lead1'}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(0.5),
            })}
          >
            {
              <Icon
                name="avatar-approved"
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 400,
                }}
              />
            }{' '}
            Datos del afiliado
          </Typography>
          <Typography
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              gap: theme.spacing(1),
            })}
          >
            <Typography component="span" variant="body2">
              Nombre:
            </Typography>
            <Typography
              component="span"
              variant="small"
              sx={{ fontWeight: 700, textAlign: 'end' }}
            >
              {worker.name} {worker.last_name}
            </Typography>
          </Typography>
          <Typography
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              gap: theme.spacing(1),
            })}
          >
            <Typography component="span" variant="body2">
              Salario:
            </Typography>
            <Typography
              component="span"
              variant="small"
              sx={{ fontWeight: 700 }}
            >
              {formatCurrency(worker.base_salary)}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Button
        component={Link}
        to={WORKER_SHOW(worker.id)}
        target="_blank"
        variant="text"
        startIcon={<Icon name="plus" sx={{ fontSize: '1rem !important' }} />}
        size="small"
        sx={(theme) => ({
          fontSize: '12px',
          marginTop: theme.spacing(0.5),
        })}
      >
        Ver más detalle
      </Button>
    </Box>
  )
}

export default AffiliateInfoCard
