import { useLocation, useNavigate } from 'react-router-dom'

import {
  getUserRoleSection,
  isAdminAndWorker,
  isOrganizer,
  isWorker,
} from 'utils/auth'

import {
  ACCOUNT_SETTINGS,
  COMPANY_INDEX,
  DASHBOARD,
  LOGOUT,
  WORKER_PROFILE_SHOW,
} from 'config/routes'

import { useSupportMenuContext } from '../SupportMenuContext'
import { removeCurrentChat } from '../UserContext/loadChatScript'
import { useUser } from '../UserContext/useUser'

const useUserMenuOptions = (onClosePopup, xsDown, userHashCompanyName) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { showChat, setShowChat } = useSupportMenuContext()

  const { updateUser, clearFetchCompany } = useUser()

  const getSwitchMenuItemConf = () => {
    const role = getUserRoleSection(pathname)

    const [message, url] =
      role === 'worker'
        ? ['empresa', DASHBOARD]
        : ['personal', WORKER_PROFILE_SHOW()]

    return { message, url }
  }
  const { message, url } = getSwitchMenuItemConf()

  const openCompaniesView = () => {
    onClosePopup()
    clearFetchCompany()
    navigate(COMPANY_INDEX())
  }

  const handleAdminAndWorker = () => {
    if (showChat) setShowChat(false)
    if (window?.$zoho?.salesiq) {
      window.$zoho.salesiq.reset()
      removeCurrentChat()
    }
    onClosePopup()
    updateUser({
      role: isWorker() ? 'admin' : 'worker',
    })
    localStorage.setItem('comeFromCompany', true)
    navigate(url, { replace: true })
  }

  const logOut = () => {
    onClosePopup()
    navigate(LOGOUT, { replace: true })
  }

  const openProfileView = () => {
    onClosePopup()
    navigate(ACCOUNT_SETTINGS, {
      state: {
        previousPath: pathname,
      },
    })
  }

  const options = [
    {
      id: 'account_settings',
      name: 'Cuenta',
      onClick: openProfileView,
    },
  ]

  if (!isWorker() && !isOrganizer()) {
    options.push({
      id: 'show_companies',
      name: 'Ver empresas',
      onClick: openCompaniesView,
    })
  }

  if (isAdminAndWorker()) {
    options.push({
      id: 'admin_worker',
      name: `Perfil ${message}`,
      onClick: handleAdminAndWorker,
    })
  }

  options.push({
    id: 'logout',
    name: 'Cerrar sesión',
    onClick: logOut,
  })

  if (xsDown) {
    options.unshift({
      id: 'show_company_name',
      name: <b>{userHashCompanyName()}</b>,
      onClick: () => {},
    })
  }

  return options
}

export default useUserMenuOptions
