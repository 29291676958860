import { useState } from 'react'

const useHelpModal = () => {
  const [state, setState] = useState({
    open: false,
  })

  const openHelpModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: true,
    }))
  }

  const closeHelpModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    openHelpModal,
    closeHelpModal,
    helpModalState: state,
  }
}

export default useHelpModal
