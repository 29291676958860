import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getPayrollConceptsByCategory,
  removePayrollConcept,
  updatePayrollConcepts,
} from 'services/payroll/payrollConceptService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, conceptsCategory } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'payrollConcepts')
    return getPayrollConceptsByCategory(conceptsCategory)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, payrollConceptId, concepts } = mutationData

  if (mutationMethod === 'DELETE') return removePayrollConcept(payrollConceptId)

  if (mutationMethod === 'PUT') return updatePayrollConcepts(concepts)

  return null
}

const usePayrollConceptsService = ({
  serviceParams = { queryKey: 'payrollConcepts' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restPayrollConceptsResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const payrollConceptsMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    payrollConceptsQuery: {
      data,
      ...restPayrollConceptsResponse,
    },
    payrollConceptsMutation,
  }
}

export default usePayrollConceptsService
