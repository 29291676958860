import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  getBillingInformation,
  updateBillingInformation,
} from 'services/subscription/billingService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getBillingInformation')
    return getBillingInformation()

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, billingInformation } = mutationData
  if (mutationMethod === 'PATCH')
    return updateBillingInformation(billingInformation)

  return null
}

const useBillingService = ({
  serviceParams = {},
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restBillingResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const billingMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    billingQuery: {
      data,
      ...restBillingResponse,
    },
    billingMutation,
  }
}

export default useBillingService
