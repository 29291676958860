import { Link as MuiLink, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { formatDisplayDateString } from 'utils/dateTime'

import isotipoAEL from 'assets/images/logos/aportes_en_linea/isotipo_ael.png'
import isotipoArus from 'assets/images/logos/arus/isotipo_arus.png'

const integrationsProviders = {
  aportes_en_linea: {
    name: 'Aportes en línea',
    logo: isotipoAEL,
    logoWidth: 39,
    logoHeight: 38,
  },
  arus: {
    name: 'SuAporte',
    logo: isotipoArus,
    logoWidth: 41,
    logoHeight: 32,
  },
}

export const getResultContent = (hasErrors, formState, provider) => {
  if (hasErrors) {
    return {
      title: (
        <Typography
          variant="h5"
          sx={(theme) => ({
            paddingRight: theme.spacing(2),
            fontWeight: 'normal',
            '& > span': {
              fontWeight: 'bold',
            },
            '& > img': {
              marginLeft: theme.spacing(1),
            },
          })}
        >
          Planilla de <span>{integrationsProviders[provider]?.name}</span> con
          errores
          <img
            src={integrationsProviders[provider]?.logo}
            alt={`Isotipo de ${integrationsProviders[provider]?.name}`}
            width={integrationsProviders[provider]?.logoWidth}
            height={integrationsProviders[provider]?.logoHeight}
          />
        </Typography>
      ),
      subtitle: (
        <>
          <br />
          No hemos podido realizar la carga de tu planilla al operador. Para
          continuar sigue los siguientes pasos:
          <br />
          1. Revisa los errores en el informe de validación.
          <br />
          2. Corrige los errores dentro de Aleluya.
          <br />
          3. Cuando estés listo, realiza nuevamente el proceso de pago.
          <br />
          <br />
          Conoce más sobre cómo manejar los errores haciendo clic{' '}
          <MuiLink
            target="_blank"
            href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-las-posibles-alertas-y-errores-que-pueden-generarse-al-pagar-la-seguridad-social-desde-aleluya"
            underline="hover"
          >
            aquí
          </MuiLink>
          .
        </>
      ),
    }
  }
  if (formState === 'created') {
    return {
      title: (
        <Typography
          variant="h5"
          sx={(theme) => ({
            paddingRight: theme.spacing(2),
            fontWeight: 'normal',
            '& > span': {
              fontWeight: 'bold',
            },
            '& > img': {
              marginLeft: theme.spacing(1),
            },
          })}
        >
          ¡Planilla de <span>{integrationsProviders[provider]?.name}</span>{' '}
          cargada con éxito!
          <img
            src={integrationsProviders[provider]?.logo}
            alt={`Isotipo de ${integrationsProviders[provider]?.name}`}
            width={integrationsProviders[provider]?.logoWidth}
            height={integrationsProviders[provider]?.logoHeight}
          />
        </Typography>
      ),
      subtitle:
        'Estamos listos para procesar tu pago, antes de realizarlo revisa las alertas y posibles correcciones de forma que puedas corregirlas de ser necesario.',
    }
  }
  if (formState === 'pending') {
    return {
      title: (
        <Typography
          variant="h5"
          sx={(theme) => ({
            paddingRight: theme.spacing(2),
            fontWeight: 'normal',
            '& > span': {
              fontWeight: 'bold',
            },
            '& > img': {
              marginLeft: theme.spacing(1),
            },
          })}
        >
          El pago de tu planilla de{' '}
          <span>{integrationsProviders[provider]?.name}</span> está pendiente
          <img
            src={integrationsProviders[provider]?.logo}
            alt={`Isotipo de ${integrationsProviders[provider]?.name}`}
            width={integrationsProviders[provider]?.logoWidth}
            height={integrationsProviders[provider]?.logoHeight}
          />
        </Typography>
      ),
      subtitle:
        'Tu institución financiera se encuentra validando el pago. En unos minutos sabremos si fue aprobado o rechazado y recibirás el comprobante o podrás reintentar el pago según corresponda.',
    }
  }
  if (formState === 'paid') {
    return {
      title: (
        <Typography
          variant="h5"
          sx={(theme) => ({
            paddingRight: theme.spacing(2),
            fontWeight: 'normal',
            '& > span': {
              fontWeight: 'bold',
            },
            '& > img': {
              marginLeft: theme.spacing(1),
            },
          })}
        >
          ¡El pago de tu planilla de{' '}
          <span>{integrationsProviders[provider]?.name}</span> se procesó con
          éxito!
          <img
            src={integrationsProviders[provider]?.logo}
            alt={`Isotipo de ${integrationsProviders[provider]?.name}`}
            width={integrationsProviders[provider]?.logoWidth}
            height={integrationsProviders[provider]?.logoHeight}
          />
        </Typography>
      ),
      subtitle: `En esta sección encuentras el resumen de la planilla que pagaste. El certificado del pago ha sido enviado a tu correo directamente desde ${integrationsProviders[provider]?.name}.`,
    }
  }
  if (formState === 'declined') {
    return {
      title: (
        <Typography
          variant="h5"
          sx={(theme) => ({
            paddingRight: theme.spacing(2),
            fontWeight: 'normal',
            '& > span': {
              fontWeight: 'bold',
            },
            '& > img': {
              marginLeft: theme.spacing(1),
            },
          })}
        >
          El pago de tu planilla de{' '}
          <span>{integrationsProviders[provider]?.name}</span> se rechazó desde
          la entidad bancaria
          <img
            src={integrationsProviders[provider]?.logo}
            alt={`Isotipo de ${integrationsProviders[provider]?.name}`}
            width={integrationsProviders[provider]?.logoWidth}
            height={integrationsProviders[provider]?.logoHeight}
          />
        </Typography>
      ),
      subtitle:
        'Ocurrió un error al intentar realizar tu pago. Por favor inténtalo nuevamente.',
    }
  }

  return null
}

export const getInfoCards = (
  totals = {},
  formType,
  openErrorsModal,
  openWorkersModal,
  provider
) => {
  const totalWarnings = totals.alerts + totals.possible_fixes

  return [
    {
      icon: <Icon name="female-and-male" twoTone sx={{ fontSize: '4rem' }} />,
      title: 'Cotizantes',
      number: totals.workers,
      description:
        'Total de personas a las cuales les realizarás el pago de la planilla PILA.',
      color: 'primary',
      onClick: () => openWorkersModal({ formType, provider }),
    },
    {
      icon: <Icon name="close" twoTone sx={{ fontSize: '4rem' }} />,
      title: 'Errores',
      number: totals.errors,
      description:
        totals.errors > 0
          ? 'Son los problemas que nos impiden cargar tu planilla al operador PILA. Es necesario corregirlos antes de realizar un nuevo intento de pago.'
          : 'Son los problemas que nos impiden cargar correctamente tu planilla al operador PILA. ¡No te preocupes! en tu caso no se encontró ningún error.',
      color: 'error',
      onClick: () => (totals.errors > 0 ? openErrorsModal('errors') : null),
    },
    {
      icon: <Icon name="info" twoTone sx={{ fontSize: '4rem' }} />,
      title: 'Alertas',
      number: totalWarnings,
      description:
        'Son diferencias entre la información registrada en Aleluya y la que se encuentra en el operador. Revisa estas alertas antes de continuar.',
      color: 'warning',
      onClick: () =>
        totalWarnings > 0
          ? openErrorsModal(totals.alerts > 0 ? 'alerts' : 'possible_fixes')
          : null,
    },
  ]
}

export const getSummaryItems = (formData = {}) => {
  return [
    {
      label: 'Clave planilla',
      value: formData.key,
    },
    {
      label: 'Periodo salud',
      value: formData.health_period,
    },
    {
      label: 'Periodo pensión',
      value: formData.pension_period,
    },
    {
      label: 'Fecha límite de pago',
      value: formatDisplayDateString(formData.due_date),
    },
    {
      label: 'Fecha de pago',
      value: formatDisplayDateString(formData.payment_date),
      hidden: !formData.payment_date,
    },
  ]
}
