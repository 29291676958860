import { Box, Typography } from '@mui/material'

import NoWrap from 'components/UI/NoWrap'

import { formatCurrency } from 'utils/format'

import WorkerExpander from '../Summary/WorkerExpander'

export const getDetailCard = (
  electronicPayrollData = {},
  openTotalRejectedModal
) => {
  const electronicPayrollCounter =
    electronicPayrollData?.electronic_payrolls_counter

  return [
    {
      id: 'total',
      value: electronicPayrollCounter?.total,
      icon: 'document',
      title: 'Total de nóminas electrónicas',
      isWhiteText: true,
      backgroundColor: 'accent4.main',
      adornment: {
        name: 'spring',
        backgroundColor: 'accent4.dark',
      },
      tooltipInfo: null,
    },
    {
      id: 'rejected',
      value: electronicPayrollCounter?.rejected,
      icon: 'close-2',
      title: 'Rechazadas',
      isWhiteText: false,
      chip: 'Ver detalles',
      adornment: {
        name: 'explosion',
        backgroundColor: 'complementary2.dark',
      },
      showDetailButton: {
        title: 'Ver detalle',
        onClick: () => openTotalRejectedModal(electronicPayrollData),
      },
      backgroundColor: 'complementary2.main',
      tooltipInfo: (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="lead1">Total Rechazadas:</Typography>
            <Typography variant="body1">
              {electronicPayrollCounter?.rejected}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="lead1">Anuladas:</Typography>
            <Typography variant="body1">
              {electronicPayrollCounter?.canceled}
            </Typography>
          </Box>
        </>
      ),
    },
    {
      id: 'issued',
      value: electronicPayrollCounter?.issued,
      icon: 'approve-checked',
      title: 'Emitidas',
      isWhiteText: false,
      backgroundColor: 'primary.main',
      adornment: {
        name: 'signature',
        backgroundColor: 'primary.dark',
      },
      tooltipInfo: null,
    },
    {
      id: 'pending',
      value:
        electronicPayrollCounter?.pending +
        electronicPayrollCounter?.processing +
        electronicPayrollCounter?.modified,
      icon: 'help-center',
      title: 'Sin emitir',
      isWhiteText: false,
      backgroundColor: 'accent2.main',
      adornment: {
        name: 'asterisk',
        backgroundColor: 'accent2.dark',
      },
      tooltipInfo: (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="lead1">Total Pendientes:</Typography>
            <Typography variant="body1">
              {electronicPayrollCounter?.pending}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="lead1">Procesadas:</Typography>
            <Typography variant="body1">
              {electronicPayrollCounter?.processing}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="lead1">Modificadas:</Typography>
            <Typography variant="body1">
              {electronicPayrollCounter?.modified}
            </Typography>
          </Box>
        </>
      ),
    },
  ]
}

export const getConfigurationCopy = (electronicPayrollSocialBenefitValue) => {
  const electronicPayrollSocialBenefitLabel = {
    provision_per_month:
      'Actualmente en tu nómina electrónica las prestaciones sociales se están incluyendo mensualmente de acuerdo al concepto de devengo, conoce más acerca de esta normatividad.',
    at_the_time_of_payment:
      'Actualmente en tu nómina electrónica las prestaciones sociales se están incluyendo al momento del pago, conoce más acerca de esta normatividad.',
    payment_in_year:
      'Actualmente en tu nómina electrónica las cesantías e intereses se reportan en diciembre en caso de que no se hayan pagado durante el año, y la prima se reporta al momento del pago, conoce más acerca de esta normatividad.',
  }

  return electronicPayrollSocialBenefitLabel[
    electronicPayrollSocialBenefitValue
  ]
}

const TotalItem = ({ topValue, bottomValue, expanded }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      })}
    >
      <Box sx={(theme) => ({ marginBottom: theme.spacing(0.5) })}>
        <ItemText expanded={expanded}>{topValue}</ItemText>
      </Box>
      <ItemText expanded={expanded}>{bottomValue}</ItemText>
    </Box>
  )
}

const ItemText = ({ expanded = false, children }) => {
  return (
    <NoWrap>
      <Typography
        sx={
          !expanded
            ? (theme) => ({ color: theme.palette.black.main })
            : (theme) => ({
                fontWeight: 'bold',
                color: theme.palette.black.main,
              })
        }
        variant="small"
      >
        {children}
      </Typography>
    </NoWrap>
  )
}

const ColumnHeader = ({ children }) => {
  return (
    <Typography
      variant="lead2"
      sx={(theme) => ({
        fontSize: theme.typography.small.fontSize,
      })}
    >
      {children}
    </Typography>
  )
}

export const getSummaryColumns = (electronicPayrollId) => {
  const columns = [
    {
      id: 'expander',
      excludeOnEdit: true,
      customMinWidth: '15.7rem',
      Header: <ColumnHeader>Información del empleado</ColumnHeader>,
      Cell: ({ row, toggleRowExpanded, state }) => {
        const toggleExpandedProps = row.getToggleRowExpandedProps()

        const onExpand = () => {
          if (!row.isExpanded) {
            Object.keys(state.expanded || {}).forEach((key) => {
              toggleRowExpanded(key, false)
            })
          }
          toggleExpandedProps?.onClick?.()
        }

        if (row.canExpand) {
          return (
            <WorkerExpander
              workerId={row.original.worker_id}
              payrollId={row.original.electronic_payroll_id}
              expanded={row.isExpanded}
              onExpand={onExpand}
              workerName={row.original.worker_name}
              periodId={electronicPayrollId}
              showSummary
            />
          )
        }

        return null
      },
    },
    {
      id: 'incomes',
      Header: <ColumnHeader>Ingresos</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '15.7rem',
      columns: [
        {
          accessor: 'income.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.income.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'income.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.income.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.income.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      id: 'deductions',
      Header: <ColumnHeader>Deducciones</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '15.7rem',
      columns: [
        {
          accessor: 'deductions.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.deductions.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'deductions.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.deductions.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.deductions.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      id: 'total',
      customMinWidth: '15.7rem',
      Header: <ColumnHeader>Total</ColumnHeader>,
      alignHeader: 'center',
      columns: [
        {
          accessor: 'total.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            if (!row.canExpand) return null

            return (
              <TotalItem
                expanded={row.isExpanded && !row.depth}
                topValue={row.original.worker_payment.label}
              />
            )
          },
        },
        {
          accessor: 'total.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (!row.canExpand) return null

            return (
              <TotalItem
                expanded={row.isExpanded && !row.depth}
                topValue={formatCurrency(row.original.worker_payment.value)}
              />
            )
          },
        },
      ],
    },
  ]

  return columns
}
