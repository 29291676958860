import { Box } from '@mui/material'

import aleluyaPoweredBy from 'assets/images/logos/aleluya_poweredby_positive.webp'

const PoweredBy = () => {
  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(2),
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
      })}
    >
      <Box
        component="img"
        loading="lazy"
        src={aleluyaPoweredBy}
        alt="Powered by Aleluya logo"
        sx={(theme) => ({
          width: '11.25rem',
          height: '1.625rem',
          [theme.breakpoints.up('sm')]: {
            width: '13.5rem',
            height: '2rem',
          },
          [theme.breakpoints.up('laptop')]: {
            width: '11.25rem',
            height: '1.625rem',
          },
        })}
      />
    </Box>
  )
}

export default PoweredBy
