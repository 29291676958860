import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { RejectionBody, fieldsToReset, getRejectionOptions } from './helpers'

const AffiliationRejectionContent = ({
  beneficiaries,
  affiliationEntities,
  affiliationStatus,
}) => {
  const { values, setFieldValue } = useFormikContext()

  const handleReasonChange = ({ target: { value } }) => {
    setFieldValue('rejection_reason', value)

    fieldsToReset.forEach((field) => {
      if (values[field]) {
        setFieldValue(field, null)
      }
    })
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      })}
    >
      <FormField
        name="rejection_reason"
        variant="select"
        label={`Razón de rechazo${affiliationStatus === 'received' ? ' o novedad' : ''}`}
        optional={false}
        options={getRejectionOptions({
          beneficiaries,
          affiliationStatus,
        })}
        onChange={handleReasonChange}
      />
      {values.rejection_reason ? (
        <RejectionBody
          rejectionReason={values.rejection_reason}
          beneficiaries={beneficiaries}
          affiliationEntities={affiliationEntities}
        />
      ) : null}
      <FormField
        name="comment"
        variant="textarea"
        label="Agregar comentarios adicionales"
        rows="4"
        inputProps={{
          maxLength: 1000,
        }}
        optional={values.rejection_reason !== 'novelty'}
      />
    </Box>
  )
}

export default AffiliationRejectionContent
