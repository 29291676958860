import { SnackbarProvider } from 'notistack'
import { useRef } from 'react'

import Icon from 'components/UI/Icon'

import {
  CustomSnackbarError,
  CustomSnackbarInfo,
  CustomSnackbarSuccess,
  CustomSnackbarWarning,
} from './helpers'

const NotistackProvider = ({ children }) => {
  const notistackRef = useRef()

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      Components={{
        success: CustomSnackbarSuccess,
        error: CustomSnackbarError,
        warning: CustomSnackbarWarning,
        info: CustomSnackbarInfo,
      }}
      iconVariant={{
        error: <Icon name="close" basic />,
        success: <Icon name="approved-checked" basic />,
        info: <Icon name="info" basic />,
        warning: <Icon name="info" basic />,
      }}
      SnackbarProps={{
        'data-cy': 'notification-snack',
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default NotistackProvider
