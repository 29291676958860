import { wait } from 'utils/general'

export const initHeadway = async () => {
  const config = {
    selector: '#headway', // CSS selector where to inject the badge
    trigger: '#headway',
    account: process.env.REACT_APP_HEADWAY_ID,
    translations: {
      title: 'Últimos anuncios',
      readMore: 'Ver todos los anuncios',
      footer: 'Ver todos los anuncios',
    },
    position: {
      x: 'left',
    },
  }

  await wait(1000)
  if (window.Headway) window.Headway.init(config)
}

const headwayScript = {
  src: 'https://cdn.headwayapp.co/widget.js',
  async: true,
  data: {
    name: 'nscript',
    value: 'afterInteractive',
  },
}

export default headwayScript
