import { useContext } from 'react'
import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'

import { getCompanyId } from 'utils/company'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import useWorker from '../useWorker'
import EmptyState from './EmptyState'
import FileCard from './FileCard'
import FolderCard from './FolderCard'
import { FoldersContainerContext } from './Index'

const Content = ({ filteredData, isAffiliationFolder }) => {
  const queryClient = useQueryClient()
  const { isWorker } = useUser()
  const { worker } = useWorker({ useCache: true })
  const companyId = getCompanyId()
  const workerId = worker.id
  const foldersCache = queryClient.getQueryData(
    isWorker
      ? ['getAllWorkerFolders', worker.id]
      : ['getAllFolders', companyId, workerId]
  )
  const { showAsGrid } = useContext(FoldersContainerContext)
  const { openFolder } = useContext(DocumentsContext)
  const withoutFiles =
    foldersCache?.data?.folders?.find((folder) => folder.id === openFolder?.id)
      ?.total_files === 0
  const withoutFolders = foldersCache?.data?.folders?.length === 0

  if (!openFolder && withoutFolders)
    return (
      <EmptyState showFolderState={!openFolder} showFilesState={!!openFolder} />
    )

  if (openFolder && withoutFiles) {
    return (
      <EmptyState showFolderState={!openFolder} showFilesState={!!openFolder} />
    )
  }

  return filteredData.map((card) => {
    if (openFolder)
      return (
        <FileCard
          key={card.id}
          showAsCard={showAsGrid}
          card={card}
          isAffiliationFolder={isAffiliationFolder}
        />
      )

    return <FolderCard key={card.id} showAsCard={showAsGrid} card={card} />
  })
}

export default Content
