import { Box, Button, Paper, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Adornment from 'components/UI/Adornment'

import SubscriptionConfirmationModal from '../SubscriptionConfirmationModal'

const PartnerSubscriptionCardsModalContent = ({
  planData,
  codedName,
  cardsStyles,
  selectedPlanIndex,
  currentPlanIndex,
}) => {
  const modals = useModals()
  const { subscription } = useSubscription()

  const isPremiumHRPlan = codedName === 'premium_hr_per_worker_plan'
  const { current_plan: isCurrentPlan, name } = planData
  const { active_until: activeUntil } = subscription || {}
  const planUpgrade = selectedPlanIndex > currentPlanIndex
  const { adminDescription, color, description, icon, wrapperColor } =
    cardsStyles

  const handleSubscriptionChange = () => {
    modals.closeModal('partnerSubscriptionModal')
    modals.openModal({
      id: 'subscriptionConfirmationModal',
      content: (
        <SubscriptionConfirmationModal
          selectedPlan={planData}
          selectedModality="month"
          isAnUpgrade={planUpgrade}
          isCurrentPlan={isCurrentPlan}
          activeUntil={activeUntil}
          planUpgrade={planUpgrade}
          isChangingModality={false}
        />
      ),
      modalProps: {
        header: '¿Estás seguro de realizar este cambio?',
        hideFooter: true,
        adornment: [
          {
            variant: 'signature',
            color: 'accent4.light',
            width: 120,
            height: 140,
            sx: {
              bottom: 15,
              right: 30,
            },
          },
        ],
        paperSx: {
          maxWidth: '45rem',
          width: '100%',
        },
      },
    })
  }

  return (
    <Box
      sx={(theme) => ({
        width: 'fit-content',
        position: 'relative',
        height: isCurrentPlan && !isPremiumHRPlan ? '20rem' : '22rem',
        backgroundColor:
          isCurrentPlan || isPremiumHRPlan ? wrapperColor : 'none',
        borderBottomLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',
        [theme.breakpoints.up('tablet')]: {
          alignSelf: isCurrentPlan ? 'flex-end' : 'center',
          justifySelf: selectedPlanIndex === 0 ? 'flex-end' : 'flex-start',
          minWidth: '16rem',
          maxWidth: '16rem',
        },
        ...(isPremiumHRPlan && { height: '22.5rem' }),
        [theme.breakpoints.down('tablet')]: {
          ...((isPremiumHRPlan || isCurrentPlan) && {
            marginTop: theme.spacing(5),
          }),
        },
      })}
    >
      {isCurrentPlan || isPremiumHRPlan ? (
        <Box
          sx={(theme) => ({
            borderTopRightRadius: '1.25rem',
            borderTopLeftRadius: '1.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: wrapperColor,
            columnGap: theme.spacing(0.5),
            position: 'absolute',
            width: '100%',
            top: '-7%',
            height: '1.7rem',
          })}
        >
          {isPremiumHRPlan ? (
            <Box sx={(theme) => ({ marginTop: theme.spacing(1) })}>
              <Adornment
                variant="explosion"
                height={20}
                width={20}
                color="black.main"
                sx={{
                  position: 'initial',
                  height: 'min-content',
                  alignSelf: 'center',
                }}
              />
            </Box>
          ) : null}
          <Typography variant="lead1">
            {isCurrentPlan ? 'Plan actual' : 'Recomendado'}
          </Typography>
        </Box>
      ) : null}
      <Paper
        sx={(theme) => ({
          borderRadius: '1.25rem',
          boxShadow: theme.shadows[7],
          minWidth: '16rem',
          padding:
            isCurrentPlan && !isPremiumHRPlan
              ? theme.spacing(1, 1)
              : theme.spacing(1.9, 1),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifySelf: 'center',
          height: '100%',
          border:
            isCurrentPlan || isPremiumHRPlan
              ? `0.25rem solid ${wrapperColor}`
              : 'none',
          [theme.breakpoints.up('tablet')]: {
            minWidth: '16rem',
            maxWidth: '16rem',
          },
          ...(isPremiumHRPlan && { paddingTop: theme.spacing(1.9) }),
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: theme.spacing(3.5),
          })}
        >
          <Typography
            color={color}
            variant="h4"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          >
            {name}
          </Typography>
          {icon}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(7),
            minHeight: '12rem',
          })}
        >
          <Typography
            variant="body2"
            color="black.dark"
            sx={(theme) => ({
              position: 'absolute',
              top: isCurrentPlan && !isPremiumHRPlan ? 60 : 92,
              left: 0,
              width: '100%',
              padding:
                isPremiumHRPlan || isCurrentPlan
                  ? theme.spacing(0, 1.5)
                  : theme.spacing(0, 1),
              ...(isPremiumHRPlan && { top: 95.5 }),
              [theme.breakpoints.up('tablet')]: {
                ...(isCurrentPlan && !isPremiumHRPlan && { top: 57 }),
              },
            })}
          >
            {description}
          </Typography>
          <Typography
            variant="small"
            color="black.main"
            sx={(theme) => ({
              position: 'absolute',
              top: isCurrentPlan && !isPremiumHRPlan ? 168 : 200,
              left: 0,
              padding:
                isPremiumHRPlan || isCurrentPlan
                  ? theme.spacing(0, 1.5)
                  : theme.spacing(0, 1),
              ...(isPremiumHRPlan && { top: 204 }),
              [theme.breakpoints.up('tablet')]: {
                ...(isCurrentPlan && !isPremiumHRPlan && { top: 165 }),
              },
            })}
          >
            <b>Usuarios administradores:</b>{' '}
            <Typography variant="small" color="black.dark">
              {adminDescription}
            </Typography>
          </Typography>
        </Box>
        {!isCurrentPlan ? (
          <Box
            sx={(theme) => ({
              width: '100%',
              position: 'absolute',
              top: isCurrentPlan && !isPremiumHRPlan ? 258 : 290,
              padding: theme.spacing(0, 2),
              ...(isPremiumHRPlan && { top: 294 }),
              [theme.breakpoints.up('tablet')]: {
                ...(isCurrentPlan && !isPremiumHRPlan && { top: 255 }),
              },
            })}
          >
            <Button
              fullWidth
              onClick={handleSubscriptionChange}
              variant={!isPremiumHRPlan ? 'outlined' : 'contained'}
            >
              Activar plan
            </Button>
          </Box>
        ) : null}
      </Paper>
    </Box>
  )
}

export default PartnerSubscriptionCardsModalContent
