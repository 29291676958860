import { Fragment, isValidElement } from 'react'

import { Box, Button, Tooltip } from '@mui/material'

const renderActionIcon = (Icon) => {
  if (Icon) {
    if (isValidElement(Icon)) return Icon

    return <Icon />
  }

  return undefined
}

const ToolbarActions = ({ freeActions }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        width: '100%',
        '& > *': {
          width: '100%',
        },
        [theme.breakpoints.up(768)]: {
          width: 'auto',
          '& > *': {
            maxWidth: 'max-content',
          },
        },
      })}
    >
      {freeActions.map((action) => {
        if (!action.hidden && action.Component) {
          return (
            <Fragment key={action.id || action.tooltip}>
              {action.Component}
            </Fragment>
          )
        }

        return !action.hidden ? (
          <Tooltip
            title={action.tooltipTitle || ''}
            key={action.id || action.tooltip}
            arrow
            enterDelay={300}
          >
            <Box
              component="span"
              sx={{
                display: 'inline-flex',
                '& > *': {
                  width: '100%',
                },
              }}
            >
              <Button
                onClick={action.onClick}
                startIcon={renderActionIcon(action.icon)}
                endIcon={renderActionIcon(action.endIcon)}
                variant={action.buttonVariant}
                disabled={action.disabled}
                {...action.buttonProps}
              >
                {action.tooltip}
              </Button>
            </Box>
          </Tooltip>
        ) : null
      })}
    </Box>
  )
}

export default ToolbarActions
