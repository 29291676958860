import { Box, Card, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { isArusDist, isFreeCompany } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatCurrency } from 'utils/format'
import useDashboardService from 'utils/hooks/dashboardService'

import NextPaymentCard from './NextPaymentCard'
import { getNextPaymentType } from './helpers'

const DashboardNextPayments = () => {
  const { subscription } = useSubscription()
  const isArusDistributor = isArusDist()
  const queryKey = [
    'dashboardByMetrics',
    'next_payments,period_information',
    getCompanyId(),
  ]

  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'next_payments,period_information',
    },
  })

  const {
    next_payments: nextPayments = {},
    period_information: periodInformation = {},
  } = dashboardQuery.data || {}
  const nextPaymentType = getNextPaymentType(periodInformation, nextPayments)

  return (
    <Box
      sx={(theme) => ({
        gridColumn: '1 / -1',
        [theme.breakpoints.up('tablet')]: {
          gridColumn: '1 / 3',
        },
        [theme.breakpoints.up('laptop')]: {
          gridColumn: '1 / 5',
        },
      })}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({ marginBottom: theme.spacing(3) })}
      >
        Datos del periodo actual
      </Typography>
      {dashboardQuery.isLoading ? (
        <LoadingBox />
      ) : (
        <Card sx={{ borderRadius: '1rem' }}>
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridTemplateRows: 'repeat(3, 1fr)',
              padding: theme.spacing(2, 3),
              rowGap: theme.spacing(3),
            })}
          >
            <>
              {nextPayments &&
                Object.keys(nextPayments).map((paymentItem) => (
                  <NextPaymentCard
                    key={paymentItem}
                    title={nextPaymentType[paymentItem]?.title}
                    value={formatCurrency(
                      typeof nextPayments[paymentItem]?.payment === 'number'
                        ? nextPayments[paymentItem]?.payment
                        : nextPayments[paymentItem]
                    )}
                    backgroundColor={
                      nextPaymentType[paymentItem]?.backgroundColor
                    }
                    isWhiteText={
                      isArusDistributor &&
                      paymentItem === 'payroll_payments' &&
                      !isAFreeCompany
                    }
                    icon={nextPaymentType[paymentItem]?.icon}
                    tooltipTitle={nextPaymentType[paymentItem]?.tooltipTitle}
                    tooltipDescription={
                      nextPaymentType[paymentItem]?.tooltipDescription
                    }
                    descriptionDate={
                      nextPaymentType[paymentItem]?.descriptionDate
                    }
                  />
                ))}
            </>
          </Box>
        </Card>
      )}
    </Box>
  )
}

export default DashboardNextPayments
