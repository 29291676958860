import { Button } from '@mui/material'

import Icon from 'components/UI/Icon'

import { helpOnClick } from 'utils/chat'

import { EMAIL_SUPPORT } from 'config/organization'

import { useSupportMenuContext } from '../SupportMenuContext'
import useNotWorkingChatModal from './useNotWorkingChatModal'

const SupportActionButton = ({ variant }) => {
  const { setEmailOpen, setShowSupportActionGroup, setShowChat } =
    useSupportMenuContext()

  const { openNotWorkingChatModal } = useNotWorkingChatModal()

  const actionData = {
    zohoChat: {
      text: 'Chat en línea',
      icon: 'chat-online',
      action: () => {
        helpOnClick({ openNotWorkingChatModal, setShowChat })
      },
    },
    mail: {
      text: 'Correo electrónico',
      icon: 'mail-notification',
      action: () => {
        setEmailOpen(true)
        window.location.href = `mailto:${EMAIL_SUPPORT}`
      },
    },
  }

  const data = actionData[variant]

  return (
    <Button
      variant="outlined"
      sx={(theme) => ({
        width: '100%',
        backgroundColor: theme.palette.white.main,
        marginTop: theme.spacing(2),
      })}
      onClick={() => {
        setShowSupportActionGroup(false)
        data.action()
      }}
      endIcon={<Icon name={data.icon} />}
    >
      {data.text}
    </Button>
  )
}

export default SupportActionButton
