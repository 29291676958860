import http, { getApiURL } from '../httpService'

export const getAllHistoryActions = ({ search, page }) => {
  const url = getApiURL({
    pathname: '/history_logs',
    searchParams: { search, page },
  })

  return http.getV2(url)
}

export default {
  getAllHistoryActions,
}
