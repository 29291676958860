import { useState } from 'react'

import { Box, IconButton, Tooltip, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

const Password = ({ value }) => {
  const [visibility, setVisibility] = useState(false)
  const [clipboard, setClipboard] = useState(false)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value)
    setClipboard(true)
    setTimeout(() => {
      setClipboard(false)
    }, 1000)
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {visibility ? (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="body2"
        >
          {value}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          sx={{
            fontWeight: 'bold',
          }}
        >
          **********
        </Typography>
      )}
      <Box
        sx={(theme) => ({
          marginLeft: theme.spacing(0.5),
          display: 'flex',
          gap: theme.spacing(0.5),
        })}
      >
        <Tooltip title="Acabas de copiar la contraseña" open={clipboard} arrow>
          <IconButton onClick={handleCopyToClipboard}>
            <Icon basic name="doc-copy" />
          </IconButton>
        </Tooltip>
        <IconButton onClick={() => setVisibility(!visibility)}>
          {visibility ? (
            <Icon basic name="eye-close" />
          ) : (
            <Icon basic name="eye" />
          )}
        </IconButton>
      </Box>
    </Box>
  )
}

export default Password
