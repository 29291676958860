import { Paper } from '@mui/material'

const FormBox = ({ children, sx, ...props }) => {
  return (
    <Paper
      sx={[
        (theme) => ({
          padding: theme.spacing(3),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5),
          },
        }),
        sx,
      ]}
      {...props}
    >
      {children}
    </Paper>
  )
}

export default FormBox
