import { useFormikContext } from 'formik'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'

import { closedQuestionOptions, getMinimumSalary } from 'utils/worker'

const TransportSubsidyField = ({ contractCategory, isCreatingWorker }) => {
  const confirm = useConfirm()
  const form = useFormikContext()

  const { values } = form

  const handleChange = ({ target }) => {
    const value = target.value === 'true'

    if (values.id && !isCreatingWorker) {
      // is is editing
      confirm({
        type: 'warning',
        title: 'Cambio en subsidio de transporte',
        okText: 'Continuar',
        description: (
          <>
            ¿Estás seguro de este cambio? Activar o eliminar el subsidio de
            transporte <b>afectará todas las nóminas</b> desde la fecha inicial
            del contrato activo.
          </>
        ),
        onOk: () => form.setFieldValue('transport_subsidy', value),
      })
    } else {
      form.setFieldValue('transport_subsidy', value)
    }
  }

  const twoSMLMV = getMinimumSalary({
    multiplier: 2,
  })

  return contractCategory &&
    contractCategory !== 'contractor' &&
    values.wage_category !== 'integral_salary' &&
    (!values.base_salary || Number(values.base_salary) <= twoSMLMV) ? (
    <FormField
      name="transport_subsidy"
      row
      label="¿Subsidio de transporte?"
      variant="radio-group"
      options={closedQuestionOptions}
      onChange={handleChange}
      optional={false}
    />
  ) : null
}

export default TransportSubsidyField
