import { Box, Paper, Typography } from '@mui/material'

import Adornment from 'components/UI/Adornment'

const PaymentExoneratedSubscriptionModalCard = ({
  planData,
  codedName,
  cardsStyles,
}) => {
  const isPremiumHRPlan = codedName === 'premium_hr_per_worker_plan'
  const { name } = planData || {}
  const { adminDescription, color, description, icon, wrapperColor } =
    cardsStyles || {}

  return (
    <Box
      sx={(theme) => ({
        width: 'fit-content',
        position: 'relative',
        backgroundColor: wrapperColor,
        borderBottomLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',
        marginTop: theme.spacing(3),
        [theme.breakpoints.up('tablet')]: {
          alignSelf: 'flex-end',
          justifySelf: 'flex-end',
          minWidth: '16rem',
          maxWidth: '16rem',
        },
        ...(isPremiumHRPlan && { height: '22.5rem' }),
      })}
    >
      <Box
        sx={(theme) => ({
          borderTopRightRadius: '1.25rem',
          borderTopLeftRadius: '1.25rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: wrapperColor,
          columnGap: theme.spacing(0.5),
          position: 'absolute',
          width: '100%',
          top: '-7%',
          height: '1.7rem',
        })}
      >
        {isPremiumHRPlan ? (
          <Box sx={(theme) => ({ marginTop: theme.spacing(1) })}>
            <Adornment
              variant="explosion"
              height={20}
              width={20}
              color="black.main"
              sx={{
                position: 'initial',
                height: 'min-content',
                alignSelf: 'center',
              }}
            />
          </Box>
        ) : null}
        <Typography variant="lead1">Plan actual</Typography>
      </Box>
      <Paper
        sx={(theme) => ({
          borderRadius: '1.25rem',
          boxShadow: theme.shadows[7],
          minWidth: '16rem',
          padding: !isPremiumHRPlan
            ? theme.spacing(1, 1)
            : theme.spacing(1.9, 1),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifySelf: 'center',
          height: '100%',
          border: `0.25rem solid ${wrapperColor}`,
          [theme.breakpoints.up('tablet')]: {
            minWidth: '16rem',
            maxWidth: '16rem',
          },
          ...(isPremiumHRPlan && { paddingTop: theme.spacing(1.9) }),
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: theme.spacing(3.5),
          })}
        >
          <Typography
            color={color}
            variant="h4"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          >
            {name}
          </Typography>
          {icon}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(7),
            minHeight: '12rem',
          })}
        >
          <Typography
            variant="body2"
            color="black.dark"
            sx={(theme) => ({
              position: 'absolute',
              top: !isPremiumHRPlan ? 60 : 92,
              left: 0,
              width: '100%',
              padding: theme.spacing(0, 1.5),
              ...(isPremiumHRPlan && { top: 95.5 }),
              [theme.breakpoints.up('tablet')]: {
                ...(!isPremiumHRPlan && { top: 57 }),
              },
            })}
          >
            {description}
          </Typography>
          <Typography
            variant="small"
            color="black.main"
            sx={(theme) => ({
              position: 'absolute',
              top: !isPremiumHRPlan ? 168 : 200,
              left: 0,
              padding: theme.spacing(0, 1.5),
              ...(isPremiumHRPlan && { top: 204 }),
              [theme.breakpoints.up('tablet')]: {
                ...(!isPremiumHRPlan && { top: 165 }),
              },
            })}
          >
            <b>Usuarios administradores:</b>{' '}
            <Typography variant="small" color="black.dark">
              {adminDescription}
            </Typography>
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default PaymentExoneratedSubscriptionModalCard
