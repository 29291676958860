import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const initialValues = {
  name: '',
  document_type: 'ni',
  id_number: '',
  email: '',
}

export const getValidationSchema = (hasDistributors, hasPartners) => {
  let schema = yup.object({
    name: yup.string().trim().nullable().required(),
    document_type: yup.string().trim().nullable().required(),
    id_number: yup.string().trim().nullable().required(),
    email: yup.string().email().nullable().required(),
  })

  if (hasDistributors) {
    schema = schema.concat(
      yup.object({
        distributor: yup.object().nullable().required(),
      })
    )
  }

  if (hasPartners) {
    schema = schema.concat(
      yup.object({
        partner: yup.object().nullable().required(),
      })
    )
  }

  return schema
}
