import { useState } from 'react'

const useElectronicSignatureConfirmationModal = () => {
  const [state, setState] = useState({
    open: false,
    signers: [],
  })

  const openElectronicSignatureConfirmationModal = (signers) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      signers,
    }))
  }

  const closeElectronicSignatureConfirmationModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    openElectronicSignatureConfirmationModal,
    closeElectronicSignatureConfirmationModal,
    electronicSignatureConfirmationModalState: state,
  }
}

export default useElectronicSignatureConfirmationModal
