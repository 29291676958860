import { useLocation } from 'react-router-dom'

import { Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'

import * as routes from 'config/routes'

const LinkToSignup = () => {
  const location = useLocation()

  return (
    <Typography variant="body1" color="black.main">
      ¿Aún no tienes cuenta?{' '}
      <Link
        to={{
          pathname: routes.SIGN_UP,
          search: location.search,
        }}
        fontWeight={600}
        color="accent4.main"
      >
        Crear cuenta
      </Link>
    </Typography>
  )
}

export default LinkToSignup
