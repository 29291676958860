import { createCalendar } from '@internationalized/date'
import { useRangeCalendar } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { useRangeCalendarState } from '@react-stately/calendar'
import { useCallback, useRef, useState } from 'react'

import { Box, Divider } from '@mui/material'

import CalendarActions from './CalendarActions'
import CalendarGrid from './CalendarGrid'
import CalendarHeader from './CalendarHeader'
import CalendarYearGrid from './CalendarYearGrid'

const RangeCalendar = ({ onCloseCalendar, isDesktop, ...restProps }) => {
  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...restProps,
    visibleDuration: isDesktop ? { months: 2 } : undefined,
    locale,
    createCalendar,
  })
  const ref = useRef()
  const {
    calendarProps,
    prevButtonProps,
    nextButtonProps,
    title,
  } = useRangeCalendar(restProps, state, ref)
  const [gridTransition, setGridTransition] = useState({
    key: 0,
    direction: null,
  })
  const [showYearGrid, setShowYearGrid] = useState(false)

  const onCloseYearGrid = useCallback(() => {
    setShowYearGrid(false)
  }, [])

  return (
    <Box
      {...calendarProps}
      ref={ref}
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
        })}
      >
        <CalendarHeader
          title={title}
          showYearGrid={showYearGrid}
          setShowYearGrid={setShowYearGrid}
          prevButtonProps={{
            ...prevButtonProps,
            onPress: (event) => {
              prevButtonProps.onPress(event)
              setGridTransition((previousValue) => ({
                key: previousValue.key - 1,
                direction: 'left',
              }))
            },
          }}
          nextButtonProps={{
            ...nextButtonProps,
            onPress: (event) => {
              nextButtonProps.onPress(event)
              setGridTransition((previousValue) => ({
                key: previousValue.key + 1,
                direction: 'right',
              }))
            },
          }}
        />
        <CalendarYearGrid
          open={showYearGrid}
          state={state}
          onCloseYearGrid={onCloseYearGrid}
        />
        <Box
          sx={(theme) => ({
            display: 'flex',
            height: '100%',
            columnGap: theme.spacing(2),
          })}
        >
          <CalendarGrid
            state={state}
            gridTransition={gridTransition}
            isDesktop={isDesktop}
          />
          {isDesktop ? (
            <>
              <Divider
                orientation="vertical"
                sx={(theme) => ({
                  marginTop: theme.spacing(3),
                })}
                flexItem
              />
              <CalendarGrid
                state={state}
                offset={{ months: 1 }}
                gridTransition={gridTransition}
                isDesktop={isDesktop}
              />
            </>
          ) : null}
        </Box>
        <CalendarActions onCloseCalendar={onCloseCalendar} />
      </Box>
    </Box>
  )
}

export default RangeCalendar
